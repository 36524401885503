import React from "react";
import { Hidden, Card } from "@material-ui/core";
import OfficeHours from "../components/OfficeHours";
import RightRailPosts from "../components/RightRailPosts";
import { RootState } from "../../../store/root-reducer";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as constants from "../constants/constants";

interface MyProps {}

interface PropsFromState {
  user: UserInfoItem;
}
type AllProps = PropsFromState & MyProps & any;

class RightRail extends React.Component<AllProps> {
  detectFormerResidentState = () => {
    const residentStatus = this.props.user ? this.props.user.residentStatus : "";

    if (residentStatus !== "") {
      if (residentStatus === constants.FORMER && this.props.location.pathname === "/") {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };
  render() {
    if (this.detectFormerResidentState()) {
      return <React.Fragment />;
    }

    const myUrl = window.location.href;
    const myHost = window.location.pathname;
    const rightRailSwitch =
      myHost === "/" || myUrl.includes("/groups") || myUrl.includes("/events") || myUrl.includes("/groupdetails/")
        ? true
        : false;

    return (
      <Hidden smDown>
        {/* <div style={{ width: "280px", marginTop: "144px", marginRight: "20px" }}> */}
        <div style={{ width: "280px", marginRight: "20px" }}>
          {!rightRailSwitch && (
            <Card style={{ padding: "1.5rem" }}>
              <OfficeHours />
            </Card>
          )}
          {rightRailSwitch && (
            <Card style={{ padding: "1.5rem 1.5rem 0 1.5rem" }}>
              <RightRailPosts />
            </Card>
          )}
        </div>
      </Hidden>
    );
  }
}

const mapStateToProps = ({ selectAccount, profile }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
});

export default connect(mapStateToProps)(withRouter(RightRail));
