import { List, ListItem, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { RootState } from "../../../store/root-reducer";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { fetchRequestAsync } from "../actions";
import { DashboardNotification } from "../models";
import styles from "../styles";
import NotificationBox from "./NotificationBox";

interface Props extends WithStyles<typeof styles> {
  fromDashboard: boolean;
}

interface PropsFromState {
  user?: UserInfoItem;
  notifications: DashboardNotification[];
  //loading: boolean;
}

interface PropsFromDispatch {
  notificationFetchRequest: typeof fetchRequestAsync.request;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

class Feed extends React.Component<AllProps> {
  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.notificationFetchRequest(nameId);
  }

  render() {
    const { notifications } = this.props;

    return (
      <div>
        <List>
          {notifications.map((x) => (
            <ListItem key={x.id} style={{ paddingLeft: "0", paddingRight: "0" }}>
              <NotificationBox item={x} fromDashboard={this.props.fromDashboard} />
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

const mapStateToProps = ({ selectAccount, notifications }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  notifications: notifications.notifications as DashboardNotification[],
  //loading: notifications.isLoadingNotifications
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  notificationFetchRequest: (nameid: string) => dispatch(fetchRequestAsync.request(nameid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Feed));
