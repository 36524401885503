import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { ContactItem, ContactUsItem } from "./models";
import * as contact from "./actions";

export type ContactAction = ActionType<typeof contact>;

export type ContactState = Readonly<{
  loading: boolean;
  data: ContactItem[];
  contactUsItems: ContactUsItem[];
  errors?: string;
}>;

const initialState: ContactState = {
  data: [],
  contactUsItems: [],
  errors: undefined,
  loading: false,
};

const reducer: Reducer<ContactState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(contact.fetchRequest): {
      return { ...state, loading: true };
    }
    case getType(contact.fetchSuccess): {
      return { ...state, loading: false, data: action.payload };
    }
    case getType(contact.residentRelationsPostRequest): {
      return { ...state, loading: true };
    }
    case getType(contact.residentRelationsPostSuccess): {
      return { ...state, loading: false };
    }
    case getType(contact.residentRelationsPostError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(contact.contactUsRequest): {
      return { ...state, loading: true };
    }
    case getType(contact.contactUSSuccess): {
      return { ...state, loading: false, contactUsItems: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
