import * as React from "react";
import { Typography, Paper, withStyles, WithStyles, Grid } from "@material-ui/core";

import paymentstyles from "../styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(paymentstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  notifMantenance: string;
  notifHidePayment: string;
  notifPendingPayment: number | undefined;
  notifCertifiesAndFull: string;
  notifCertifiedFund: string;
  notifRequiredFullPayment: string;
  applicantPaymentNotification:string;
}

const PaymentNotification: React.SFC<Props> = (props) => {
  return (
    <Paper className={props.classes.paper}>
      {props.applicantPaymentNotification === "Y" && (
        <Grid container alignItems="center" spacing={24}>
          <Grid item>
            <img
              alt="Alert"
              className="header-icon"
              src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/paymentoptions/alert.png"
            />
          </Grid>
          <Grid item xs>
            <Typography variant="body1">
              Please submit payment for pending charges on your account. 
              If you have any questions, please contact&nbsp;
                <a href="mailto:ApplicationProcessing@eqr.com" target="_blank" rel="noreferrer">
                  ApplicationProcessing@eqr.com
                </a>.
            </Typography>
          </Grid>
        </Grid>
      )}
      {props.notifMantenance! === "N" && (
        <Grid container alignItems="center" spacing={24}>
          <Grid item>
            <img
              alt="Alert"
              className="header-icon"
              src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/paymentoptions/alert.png"
            />
          </Grid>
          <Grid item xs>
            <Typography variant="body1">Our system is updating. Please check back soon.</Typography>
          </Grid>
        </Grid>
      )}
      {props.notifHidePayment! === "Y" && (
        <Grid container alignItems="center" spacing={24}>
          <Grid item>
            <img
              alt="Alert"
              className="header-icon"
              src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/paymentoptions/alert.png"
            />
          </Grid>
          <Grid item xs>
            <Typography variant="body1">Electronic payments are not available.</Typography>
          </Grid>
        </Grid>
      )}
      {props.notifPendingPayment! > 0 && (
        <Grid container alignItems="center" spacing={24}>
          <Grid item>
            <img
              alt="Alert"
              className="header-icon"
              src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/paymentoptions/alert.png"
            />
          </Grid>
          <Grid item xs>
            <Typography variant="body1">
              A payment of ${props.notifPendingPayment} was submitted for authorization. It will be reflected on your
              statement shortly.
            </Typography>
          </Grid>
        </Grid>
      )}
      {props.notifCertifiesAndFull! === "Y" ? (
        <Grid container alignItems="center" spacing={24}>
          <Grid item>
            <img
              alt="Alert"
              className="header-icon"
              src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/paymentoptions/alert.png"
            />
          </Grid>
          <Grid item xs>
            <Typography variant="body1">
              Payment methods available are credit card and debit card and payment of the full balance is required.
            </Typography>
          </Grid>
        </Grid>
      ) : props.notifRequiredFullPayment! === "Y" ? (
        <Grid container alignItems="center" spacing={24}>
          <Grid item>
            <img
              alt="Alert"
              className="header-icon"
              src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/paymentoptions/alert.png"
            />
          </Grid>
          <Grid item xs>
            <Typography variant="body1">Payment of full balance required</Typography>
          </Grid>
        </Grid>
      ) : props.notifCertifiedFund! === "Y" ? (
        <Grid container alignItems="center" spacing={24}>
          <Grid item>
            <img
              alt="Alert"
              className="header-icon"
              src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/paymentoptions/alert.png"
            />
          </Grid>
          <Grid item xs>
            <Typography variant="body1">Payment methods available are credit card and debit card only.</Typography>
          </Grid>
        </Grid>
      ) : null}
    </Paper>
  );
};

export default withStyles(styles)(PaymentNotification);
