import { Theme, createStyles } from "@material-ui/core";

const styles = ({ spacing, zIndex, breakpoints, mixins, palette, shadows }: Theme) =>
  createStyles({
    root: {
      width: `100%`,
      height: `100%`,
      zIndex: 1,
      overflow: `hidden`,
    },
    headerBackground: {
      backgroundImage: `url('https://media.equityapartments.com/image/upload/f_auto/Content/portal/uielements/background-header.svg')`,
      backgroundPositionY: `-8rem`,
      backgroundRepeat: `repeat-x`,
      [breakpoints.up(`sm`)]: {
        backgroundPositionY: `-7.5rem`,
      },
      [breakpoints.up(`md`)]: {
        backgroundPositionY: `-5.5rem`,
      },
      width: `100%`,
      height: `100%`,
      zIndex: 1,
      overflow: `hidden`,
    },
    headerGridItem: {
      display: `flex`,
      width: `100%`,
      maxWidth: `79.5rem`,
    },
    appFrame: {
      position: `relative`,
      display: `flex`,
      width: `100%`,
      height: `100%`,
    },
    appBar: {
      zIndex: zIndex.drawer + 1,
      position: `absolute`,
      minHeight: `7.5rem`,
      backgroundImage: `url('https://media.equityapartments.com/image/upload/b_rgb:133951,co_rgb:0d2a3d,e_colorize:100,f_auto/Content/portal/uielements/background-pattern.svg')`,
      [breakpoints.up(`sm`)]: {
        minHeight: `6rem`,
      },
      [breakpoints.up(`md`)]: {
        minHeight: `7.5rem`,
      },
    },
    toolBar: {
      minHeight: spacing.unit * 8,
    },
    logoContainer: {
      marginTop: `0`,
      [breakpoints.up(`sm`)]: {
        marginTop: `1rem`,
      },
      [breakpoints.up(`md`)]: {
        marginTop: `0`,
      },
    },
    logo: {
      maxHeight: spacing.unit * 3,

      [breakpoints.up(`md`)]: {
        maxHeight: spacing.unit * 5,
        marginTop: (spacing.unit * 15) / 2 - (spacing.unit * 5) / 2,
      },
    },
    navIconHide: {
      [breakpoints.up(`md`)]: {
        display: `none`,
      },
    },
    drawerHeader: mixins.toolbar,
    drawerPaper: {
      width: `60vw`,
      [breakpoints.up(`md`)]: {
        backgroundColor: `transparent`,
        borderRight: `none`,
        width: spacing.unit * 35,
        position: `relative`,
        height: `100%`,
        paddingRight: `.5rem`,
        paddingBottom: spacing.unit,
        // marginTop: spacing.unit * 18,
        marginLeft: spacing.unit * 3,
      },
    },
    navDivider: {
      marginLeft: spacing.unit * 2,
      marginRight: spacing.unit * 2,
      [breakpoints.up(`sm`)]: {
        marginLeft: spacing.unit * 3,
        marginRight: spacing.unit * 3,
      },
    },
    content: {
      backgroundColor: palette.background.default,
      width: `100%`,
      height: `calc(100% - 56px)`,
      marginTop: spacing.unit * 7,
      [breakpoints.up(`sm`)]: {
        height: `calc(100% - 120px)`,
        marginTop: spacing.unit * 15,
      },
    },
    link: {
      fontFamily: `'Roboto',sans-serif`,
      fontWeight: 300,
      color: `#2e79b6`,
      fontSize: `1.125rem`,
      textDecoration: `none`,
    },
    quickLink: {
      fontFamily: `'Roboto',sans-serif`,
      fontWeight: 500,
      color: `#384A77`,
      fontSize: `0.725rem`,
      textDecoration: `none`,
    },
    logoutContainer: {
      marginLeft: spacing.unit * 2,
      marginRight: spacing.unit * 2,
      marginTop: spacing.unit * 5,
      marginBottom: spacing.unit * 2,
      [breakpoints.up(`sm`)]: {
        marginLeft: spacing.unit * 3,
        marginRight: spacing.unit * 3,
      },
    },
    logoutDivider: {
      marginTop: spacing.unit * 2,
      marginBottom: spacing.unit * 2,
    },
    logout: {
      color: `#2c3335`,
      fontSize: `1rem`,
      fontWeight: 400,
      textDecoration: `none`,
      [breakpoints.up(`sm`)]: {
        marginBottom: spacing.unit * 5,
      },
    },
    propInfo: {
      color: `#2c3335`,
      fontSize: `0.75rem`,
      marginBottom: `0.2rem`,
    },
    userInfoLink: {
      textDecoration: `none`,
    },
    userInfoHeaderContainer: {
      [breakpoints.up(`sm`)]: {
        marginTop: `1rem`,
      },
      [breakpoints.up(`md`)]: {
        marginTop: spacing.unit * 3,
        marginLeft: spacing.unit * 10,
      },
    },
    userInfoHeaderAvatar: {
      height: 60,
      width: 60,
      marginRight: 20,
    },
    userInfoHeaderName: {
      color: `white`,
      fontSize: `1.50rem`,
    },
    userInfoHeaderProperty: {
      color: `white`,
      fontSize: `0.85rem`,
    },
    errorModal: {
      position: `absolute`,
      width: spacing.unit * 50,
      backgroundColor: palette.background.paper,
      boxShadow: shadows[5],
      padding: spacing.unit * 4,
      outline: `none`,
      top: `50%`,
      left: `50%`,
      textAlign: "center",
      transform: `translate(-50%, -50%)`,
    },
    icon: {
      margin: spacing.unit,
      fontSize: 32,
    },
  });

export default styles;
