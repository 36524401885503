import * as React from "react";
import { Dialog, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";
import DialogHeader from "./DialogHeader";
interface Props {
  open: boolean;
  handleClose: any;
}

type AllProps = Props;

class MoveoutTips extends React.Component<AllProps> {
  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.handleClose} scroll="paper">
        <DialogHeader title="Tips for your upcoming move" handleClose={this.props.handleClose} />
        <DialogContent>
          <Typography variant="h3" gutterBottom style={{ marginBottom: "1rem" }}>
            Before you leave
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1rem" }}>
            Be sure to schedule a pre-move out inspection appointment within the last two weeks before you move out.
            This inspection will give you the opportunity to identify and correct items that could result in charges for
            cleaning or damage after you move out.
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1rem" }}>
            Even if you do not schedule a pre-move out inspection appointment, we may still need to arrange a
            maintenance inspection of your apartment.
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1rem" }}>
            As per the terms of your Lease, when you move out, you must leave your apartment in the same clean,
            undamaged and ready-to-rent condition as it was in when you moved in, taking ordinary wear and tear into
            account.
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1rem" }}>
            You will be charged for any cleaning or damage, in excess of ordinary wear and tear, that exists in your
            apartment after you move-out.
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1.5rem" }}>
            All utilities must remain in your name up to and through your move-out date. If your utilities are
            disconnected prior to your move-out date, you will be charged for any utility costs incurred on the days you
            retained possession of your apartment. You will also be charged an administrative billing fee for every
            utility bill that we have to process on your behalf.
          </Typography>
          <Typography variant="h3" gutterBottom style={{ marginBottom: "1rem" }}>
            Move-Out Day
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1rem" }}>
            Please return all keys (and remotes if applicable) to the management office. Do not leave your keys (and
            remotes) in the apartment as you will not be considered to have vacated the apartment until your keys are
            returned to us.
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1.5rem" }}>
            Provide us your apartment number, forwarding address, telephone numbers and email address.
          </Typography>
          <Typography variant="h3" gutterBottom style={{ marginBottom: "1rem" }}>
            Final Move-out Inspection and Return of Security Deposit:
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1rem" }}>
            Once you move out, we will complete a final move-out inspection of your apartment to determine whether any
            excessive cleaning or repairs are needed.
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1rem" }}>
            Once we complete the final move-out inspection, we will prepare a Statement of Deposit Account (SODA), which
            will identify the charges, if any, made to your account after you moved out.
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1rem" }}>
            These charges may include such things as your final utility bills, any outstanding rent, fees or other
            charges, and charges for excessive cleaning or damages.
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1rem" }}>
            If, after applying such post move-out charges, you are entitled to a refund of any portion of your security
            deposit, our accounting center will send the refund check directly to you. We may provide an option for you
            to elect to receive your refund, if any, via ECH transfer into the account you select.
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1rem" }}>
            It is important that we have your forwarding address. If you have not yet provided it to us, please do so
            now.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default MoveoutTips;
