import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
  Switch,
  Typography,
  WithStyles,
  withStyles 
} from "@material-ui/core";
import { Field, Form,  Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as Yup from "yup";

import contactstyles from "../../../features/myprofile/components/styles";
import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import DialogHeader from "../../shared/components/DialogHeader";
import FormikTextField from "../../shared/components/FormikTextField";
import { UserInfoItem } from "../../userInfo/models";
import { specialAssistanceFetchRequest, specialAssistanceUpdateRequest } from "../actions";
import { RpActivity, SpecialAssistance } from "../models";

const styles = combinestyles(contactstyles, globalstyles);
interface Props extends WithStyles<typeof styles> {
  tileLocked: boolean;
}
interface PropsFromState {
  user?: UserInfoItem;
  specialAssistance?: SpecialAssistance;
}
interface PropsFromDispatch {
  logActivity: typeof logActivity;
  specialAssistanceUpdateRequest: typeof specialAssistanceUpdateRequest;
  specialAssistanceFetchRequest: typeof specialAssistanceFetchRequest;
}

type SpecialAssistState = {
  modalVisible: boolean;
};

const SignupSchema = Yup.object().shape({
  description: Yup.string() 
    .max(500, "500 character limit") 
    .required("Description is required") 
});

type AllProps = PropsFromState & PropsFromDispatch & Props;

class ProfileSpecialAssistTile extends Component<AllProps> {
  state: Readonly<SpecialAssistState> = {
    modalVisible: false 
  };

  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.specialAssistanceFetchRequest({ nameId });
  }

  handleOpenModal = () => {
    this.setState({ modalVisible: true });
  };

  handleCloseModal = () => {
    this.setState({ modalVisible: false });
  };

  turnSpecialAssistanceOff = () => {
    const payload: SpecialAssistance = {
      nameId: this.props.user!.nameId,
      specialAssistanceRequested: false,
      description: ""
    };
    this.props.specialAssistanceUpdateRequest(payload);
    this.props.logActivity({
      actcode: "SN",
      note: "Resident updated special assistance to no."
    });
  };

  turnSpecialAssistanceOn = (description: string) => {
    const payload: SpecialAssistance = {
      nameId: this.props.user!.nameId,
      specialAssistanceRequested: true,
      description: description
    };
    this.props.specialAssistanceUpdateRequest(payload);
    this.props.logActivity({
      actcode: "SY",
      note: "Resident updated special assistance to yes.  Reason: " + description
    });
  };

  render() {
    const { specialAssistance } = this.props;
    const { tileLocked, classes } = this.props;
    const lockedCssClass = tileLocked ? classes.lockedTile : "";

    if (specialAssistance !== undefined) {
      const toggleSwitch = specialAssistance ? (
        <Switch checked={true} onClick={this.turnSpecialAssistanceOff} />
      ) : (
        <Switch checked={false} onClick={this.handleOpenModal} />
      );
      return (
        <Paper className={[classes.paper, lockedCssClass].join(" ")}>
          <Typography variant="h3" style={{ marginBottom: "1rem" }}>
            Special Assistance
          </Typography>
          <div />
          <Typography style={{ display: "inline-block" }}>No</Typography>
          {toggleSwitch}
          <Typography style={{ display: "inline-block" }}>Yes</Typography>
          <Dialog open={this.state.modalVisible} onClose={this.handleCloseModal}>
            <DialogHeader title="Special Assistance Request" handleClose={this.handleCloseModal} />
            <hr />
            <DialogContent>
              <DialogContentText>Please describe your need for special assistance*.</DialogContentText>
              <Formik
                initialValues={{
                  description: ""
                }}
                validationSchema={SignupSchema}
                onSubmit={values => {
                  this.turnSpecialAssistanceOn(values.description);
                  this.handleCloseModal();
                }}
              >
                <Form>
                  <Field
                    name="description"
                    inputProps={{ maxLength: 500 }}
                    component={FormikTextField}
                    style={{ width: "100%" }}
                    multiline
                  />
                  <DialogContentText>
                    <small>All requests are subject to approval.</small>
                  </DialogContentText>
                  <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </DialogActions>
                </Form>
              </Formik>
            </DialogContent>
          </Dialog>
          <hr />
          <Typography gutterBottom>
            If you or any of your occupants will require special assistance in the event of an emergency, including
            evacuation of the building or community, please select yes to request assistance.
          </Typography>
          <Typography>
            If yes, someone from your community will be contacting you to follow up with your request.
          </Typography>
        </Paper>
      );
    } else {
      return <React.Fragment />;
    }
  }
}
const mapStateToProps = ({ selectAccount, profile }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  specialAssistance: profile.specialAssistance 
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  logActivity: (activity: RpActivity) => dispatch(logActivity(activity)), 
  specialAssistanceUpdateRequest: (payload: SpecialAssistance) => dispatch(specialAssistanceUpdateRequest(payload)), 
  specialAssistanceFetchRequest: (payload: { nameId: string }) => dispatch(specialAssistanceFetchRequest(payload))
});

export default connect(
  mapStateToProps, 
  mapDispatchToProps 
)(withStyles(styles)(ProfileSpecialAssistTile)); 
