import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { downloadDocument, fetchAccountActivity } from "../../utils/api";
import * as utils from "../shared/utils/utils";
import { downloadDocumentError, downloadDocumentSuccess, getDocumentsError, getDocumentsSuccess } from "./actions";
import * as actions from "./actions";

import { showModalAction } from "../errorhandling/actions";

function* handleFetchDocuments(action: ReturnType<typeof actions.getDocumentsRequest>) {
  try {
    const nameId = action.payload;

    const response = yield call(fetchAccountActivity, nameId);

    if (response.error) {
      yield put(getDocumentsError(response.error));
    } else {
      yield put(getDocumentsSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(getDocumentsError(err.stack!));
    } else {
      yield put(getDocumentsError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleDocumentDownload(action: ReturnType<typeof actions.downloadDocumentRequest>) {
  try {
    const payload = action.payload;
    const response = yield call(downloadDocument, payload);

    if (response.error) {
      yield put(downloadDocumentError(response.error));
    } else {
      utils.openDownloadedDocument(response, payload.fileName, payload.newWindow);
      yield put(downloadDocumentSuccess());
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(downloadDocumentError(err.stack!));
    } else {
      yield put(downloadDocumentError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(getType(actions.getDocumentsRequest), handleFetchDocuments);
  yield takeEvery(getType(actions.downloadDocumentRequest), handleDocumentDownload);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* formerResidentSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default formerResidentSaga;
