import * as React from "react";
import { fetchAsyncAction } from "../features/creditreport/actions";
import { CreditReportData } from "../features/creditreport/models";
import { RootState } from "../store/root-reducer";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import {
  Typography,
  withStyles,
  WithStyles,
  Paper,
  Link,
  Dialog,
  DialogContent,
  CircularProgress
} from "@material-ui/core";
import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import statementstyles from "../features/creditreport/components/styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(statementstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {}

interface PropsFromState {
  user?: UserInfoItem;
  loading: boolean;
  data: CreditReportData;
  errors: string;
}

interface PropsFromDispatch {
  fetchRequest: typeof fetchAsyncAction.request;
}

type AllProps = Props & PropsFromState & PropsFromDispatch & RouteComponentProps<{ startDateIndex: string }>;

class CreditReport extends React.Component<AllProps> {
  public componentDidMount() {
    this.props.fetchRequest(this.props.user!.nameId);
  }

  handleDownload = () => {
    const binaryString = window.atob(this.props.data.creditReportPDF);
    const byteNumbers = new Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteNumbers[i] = binaryString.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    const file = new Blob([byteArray], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  };

  public render() {
    const { data } = this.props;

    if (!this.props.loading && this.props.data) {
      // this.handleDownload();  // (uncomment this if you want to show the pdf on load)
    }

    return (
      <div className={this.props.classes.root}>
        <Dialog open={this.props.loading}>
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog>
        <main className={this.props.classes.contentRightRail}>
          <Typography variant="h1" className={this.props.classes.header}>
            Credit Report
          </Typography>
          {data && (
            <Paper className={this.props.classes.paper}>
              <Typography component="div" style={{ whiteSpace: "pre-wrap" }}>
                <Link style={{ cursor: "pointer" }} onClick={this.handleDownload}>
                  View Report
                </Link>
              </Typography>
            </Paper>
          )}
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ creditReport, selectAccount }: RootState) => ({
  user: getActiveUserInfo(selectAccount),
  loading: creditReport.loading,
  errors: creditReport.errors,
  data: creditReport.data
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRequest: (payload: string) => dispatch(fetchAsyncAction.request(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreditReport));
