import * as React from "react";
import { Paper, Grid, Avatar, Typography, Button } from "@material-ui/core";
interface Props {
  downloadSoda: any;
  classes: any;
}

type AllProps = Props;

class SodaTile extends React.Component<AllProps> {
  render() {
    return (
      <React.Fragment>
        <Paper
          className={this.props.classes.paper}
          style={{
            width: "100%",
            borderLeftWidth: "6px",
            borderLeftStyle: "solid",
            borderLeftColor: "#3d90d4"
          }}
        >
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Grid container alignItems="center" spacing={16}>
                <Grid item>
                  <Avatar style={{ color: "#FFF", width: "2rem", backgroundColor: "#3d90d4", height: "2rem" }}>
                    i
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="h4">Security Deposit Details (SODA)</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs style={{ textAlign: "right" }}>
              <Button onClick={() => this.props.downloadSoda()} variant="outlined" color="primary" aria-label="Add">
                View Security Deposit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

export default SodaTile;
