import * as React from "react";
import Dropzone from "react-dropzone";
import * as constants from "../../constants";
import { Grid, Typography } from "@material-ui/core";

interface Props {
  getFilesToUpload: (files: File[]) => void;
}

type AllProps = Props;

type MyState = {
  files: File[];
  previews: any;
};

type State = MyState;

const thumbsContainer = {
  display: "flex",
};

const thumb = {
  display: "inline-flex",
  marginRight: "1rem",
  width: "5rem",
  height: "5rem",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  justifyContent: "center",
};

const img = {
  display: "block",
  height: "5rem",
};

class FileUploader extends React.Component<AllProps, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      files: [],
      previews: [],
    };
  }

  onDrop = (files: File[]) => {
    this.setState({ files: this.state.files.concat(files) });
    files.map((file: any) =>
      this.setState({
        previews: this.state.previews.concat(
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      })
    );

    this.props.getFilesToUpload(this.state.files);
  };

  removeFile = (key: any) => {
    this.setState({
      files: this.state.files.filter((obj) => obj.name !== key),
      previews: this.state.previews.filter((obj: any) => obj.name !== key),
    });

    this.props.getFilesToUpload(this.state.files.filter((obj) => obj.name !== key));
  };

  render() {
    const thumbs = this.state.previews.map((file: any) => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img alt="Preview" src={file.preview} style={img} />
        </div>
        <div onClick={() => this.removeFile(file.name)} style={{ marginLeft: "-2rem" }}>
          <img
            alt="Remove"
            src={constants.REMOVE_ICON}
            style={{ position: "relative", top: "4rem", right: "1.5rem", width: "2rem", height: "2rem" }}
          />
        </div>
      </div>
    ));

    return (
      <React.Fragment>
        <Typography>Add Photos</Typography>
        <Dropzone onDrop={this.onDrop} multiple accept="image/*">
          {({ getRootProps, getInputProps }) => (
            <Grid container alignItems="center">
              <Grid item>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <img alt="Camera" src={constants.CAMERA_ICON} style={{ height: "5rem", width: "5rem" }} />
                </div>
              </Grid>
              <Grid item xs>
                <aside style={thumbsContainer}>{thumbs}</aside>
              </Grid>
            </Grid>
          )}
        </Dropzone>
      </React.Fragment>
    );
  }
}

export default FileUploader;
