import * as React from "react";
import { Typography, Button } from "@material-ui/core";
import MoveoutTips from "../../shared/components/MoveoutTips";
import SecurityDepositFaq from "../../shared/components/SecurityDepositFaq";
import ChevronRight from "@material-ui/icons/ChevronRight";
interface Props {
  goToRentWithEquity: any;
}

type AllProps = Props;

class MoveoutResources extends React.Component<AllProps> {
  state = {
    openMoveoutTipsDialog: false,
    openFaqs: false
  };

  displayMoveoutTips = () => {
    this.setState({ openMoveoutTipsDialog: true });
  };

  closeMoveoutTips = () => {
    this.setState({ openMoveoutTipsDialog: false });
  };

  displayFAQ = () => {
    this.setState({ openFaqs: true });
  };

  closeFAQ = () => {
    this.setState({ openFaqs: false });
  };

  render() {
    return (
      <React.Fragment>
        <Typography>
          <strong>Moveout Resources</strong>
        </Typography>
        {/* <Button onClick={() => this.props.goToRentWithEquity()} variant="outlined" style={{ marginTop: "10px", marginBottom: "10px", textAlign:"left" }}>
          <Typography>Redeem your Rent With Equity home builder credits</Typography>
          <ChevronRight/>
        </Button> */}
        <Button
          onClick={this.displayMoveoutTips}
          variant="outlined"
          style={{ marginTop: "10px", marginBottom: "10px", textAlign: "left" }}
        >
          <Typography>Tips for your upcoming move</Typography>
          <ChevronRight />
        </Button>
        <Button
          onClick={this.displayFAQ}
          variant="outlined"
          style={{ marginTop: "10px", marginBottom: "10px", textAlign: "left" }}
        >
          <Typography>FAQs on your final statement and security deposit</Typography>
          <ChevronRight />
        </Button>

        {/* Dialogs */}
        <MoveoutTips open={this.state.openMoveoutTipsDialog} handleClose={this.closeMoveoutTips} />
        <SecurityDepositFaq open={this.state.openFaqs} handleClose={this.closeFAQ} />
      </React.Fragment>
    );
  }
}

export default MoveoutResources;
