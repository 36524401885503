import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { loadReferFriendAmountPropertyId, postNewReferFriend } from "../../utils/api";
import { showModalAction } from "../errorhandling/actions";
import * as actions from "./actions";

function* handleFetchAmountByProp(action: ReturnType<typeof actions.referAmountFetchRequest>): any {
  try {
    const propId = action.payload;

    const response = yield call(loadReferFriendAmountPropertyId, propId);

    if (response.error) {
      yield put(actions.referAmountFetchError(response.error));
    } else {
      yield put(actions.referAmountFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.referAmountFetchError(err.stack!));
    } else {
      yield put(actions.referAmountFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleInsertReferFriend(action: ReturnType<typeof actions.requestReferFriendRequest>): any {
  try {
    const payload = action.payload;
    const response = yield call(postNewReferFriend, payload);
    if (response.error) {
      yield put(actions.requestReferFriendError(response.error));
    } else {
      yield put(actions.requestReferFriendError(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.requestReferFriendError(err.stack!));
    } else {
      yield put(actions.requestReferFriendError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* watchFetchRequest() {
  yield takeEvery(getType(actions.referAmountFetchRequest), handleFetchAmountByProp);
  yield takeEvery(getType(actions.requestReferFriendRequest), handleInsertReferFriend);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* referFriendSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default referFriendSaga;
