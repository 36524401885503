import { Grid, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import renewalstyles from "./../styles";

const styles = combinestyles(renewalstyles, globalstyles);

export interface REnewalHeaderTilesProps extends WithStyles<typeof styles> {
  user?: UserInfoItem;
}

interface PropsFromState {
  userData: UserInfoItem;
}

type AllProps = PropsFromState & REnewalHeaderTilesProps;

class RenewalHeaderTiles extends React.Component<AllProps> {
  render() {
    const headerTiles = this.props.user!.propertyInfo.renewalTiles;
    const officePhoneNumber = this.GetFormatPhone(this.props.user!.propertyInfo.propertyPhone);
    let headerTilesArray = [];
    for (let i = 0; i < headerTiles.length; i++) {
      headerTilesArray.push(
        `${headerTiles[i].title.toString()} ${headerTiles[i].copy} ${headerTiles[i].iconFile} 
        ${headerTiles[i].copyColor} ${headerTiles[i].titleColor}`
      );
    }

    return headerTiles.map((ht) => (
      <Paper className={this.props.classes.paper}>
        <Grid container spacing={24}>
          <Grid item sm={12}>
            <Grid container alignItems="center" spacing={24}>
              <Grid item xs={2}>
                <img alt="null" className="header-icon" src={ht.iconFile} />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h5" gutterBottom style={{ color: ht.titleColor }}>
                  {ht.title}
                </Typography>
                <Typography variant="body2">{ht.copy.replace("[PHONE]", officePhoneNumber)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    ));
  }

  private GetFormatPhone = (phone: string) => {
    let res = "";

    if (phone.length < 10) {
      res = "";
    } else {
      var prefix = phone.substring(0, 3);
      var dns = phone.substring(3, 6);
      var suffix = phone.substring(6, 10);

      var ext = "";
      if (phone.length > 10) {
        ext = " x" + phone.substring(10, phone.length - 10);
        res = "(" + prefix + ") " + dns + "-" + suffix + ext;
      } else {
        res = "(" + prefix + ") " + dns + "-" + suffix;
      }
    }

    return res;
  };
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  userData: getActiveUserInfo(selectAccount),
});

export default connect(mapStateToProps)(withStyles(styles)(RenewalHeaderTiles));
