import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { avatarUpdateSuccess } from "../myprofile/actions";
import { UserInfoItem } from "../userInfo/models";
import * as selectAccount from "./actions";

export type SelectAccountAction = ActionType<typeof selectAccount>;

export type SelectAccountState = Readonly<{
  loading: boolean;
  dialogOpen: boolean;
  userInfoArray: UserInfoItem[];
  selectedNameId: string;
  errors?: string;
}>;

const initialState: SelectAccountState = {
  userInfoArray: [],
  errors: undefined,
  loading: false,
  dialogOpen: false,
  selectedNameId: ""
};

const reducer: Reducer<SelectAccountState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(selectAccount.fetchRequest): {
      return { ...state, loading: true };
    }
    case getType(selectAccount.fetchSuccess): {

      // call another api to get multiple nid

      const userData = [...state.userInfoArray, action.payload.data];
      let dialogOpen = false;
      if (userData.length > 1) {
        dialogOpen = true;
        if (sessionStorage.getItem("selectedNameId")) {
          const selectedNameId = sessionStorage.getItem("selectedNameId")!;
          if (userData.filter(x => x.nameId === selectedNameId).length === 1) {
            dialogOpen = false;
          }
        }
      }
      return { ...state, loading: false, userInfoArray: userData, dialogOpen: dialogOpen };
    }
    case getType(selectAccount.fetchError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(selectAccount.selectAccount): {
      const selectedNameId = action.payload;
      sessionStorage.setItem("selectedNameId", action.payload);
      return {
        ...state,
        selectedNameId: selectedNameId,
        dialogOpen: false
      };
    }
    case getType(selectAccount.openSelectAccount): {
      return { ...state, dialogOpen: true };
    }
    case getType(avatarUpdateSuccess): {
      const nameId = action.payload.nameId;
      const userData = state.userInfoArray.map(c => (c.nameId === nameId ? action.payload : c));
      return { ...state, userInfoArray: userData };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
