import { CircularProgress, Dialog, DialogContent, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { UserInfoItem } from "../../userInfo/models";
import { postDateRequest, requestENTVRequest } from "../actions";
import ENTVHeader from "../components/entv-header";
import { NoticeToVacate, RpActivity, SaveExpectedMoveOutDate } from "../models";
import entvstyles from "../styles";
import ENTVMoveOutDate from "./entv-moveOutDate";

const styles = combinestyles(entvstyles, globalstyles);

interface State {
  waiting: boolean;
}

interface IENTVProps extends WithStyles<typeof styles> {
  // theme?: any;
  // children?: any;
  // confirmMessageDisplay?: any;
}

interface PropsFromState {
  user?: UserInfoItem;
  myNoticeToVacate?: NoticeToVacate;
  loading: boolean;
  errors: string;
}

interface PropsFromDispatch {
  postDateRequest: typeof postDateRequest;
  requestENTVRequest: typeof requestENTVRequest;
  logActivity: typeof logActivity;
}

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps & IENTVProps;

class ENTVDashboard extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    waiting: false
  };

  handleContinueToReasons = (date: Date) => {
    const nameId = this.props.user!.nameId;
    const uploadDateRequest: SaveExpectedMoveOutDate = {
      nameId: nameId,
      date: date
    };
    this.props.postDateRequest(uploadDateRequest);
    this.setState({
      waiting: true
    });
    setTimeout(this.handlePageMove, 2000);
  };

  handlePageMove = () => {
    this.props.history.push("/myaccount/entv/entvform");
  };

  handleCloseModal = () => {
    this.setState({ waiting: false });
  };

  render() {
    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <ENTVHeader />
          <ENTVMoveOutDate onContinue={(e: Date) => this.handleContinueToReasons(e)} user={this.props.user!} />
          <Dialog open={this.state.waiting} onClose={this.handleCloseModal}>
            <DialogContent>
              <CircularProgress />
            </DialogContent>
          </Dialog>
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ selectAccount, noticeToVacate }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  myNoticeToVacate: noticeToVacate.myNoticeToVacate.data,
  loading: noticeToVacate.myNoticeToVacate.loading,
  errors: noticeToVacate.myNoticeToVacate.errors
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  requestENTVRequest: (nameId: string) => dispatch(requestENTVRequest(nameId)),
  postDateRequest: (req: SaveExpectedMoveOutDate) => dispatch(postDateRequest(req)),
  logActivity: (act: RpActivity) => dispatch(logActivity(act))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ENTVDashboard));
