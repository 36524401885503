import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import {
  postForwardingAddressRequest,
  postNewNoticeRequest,
  postRefundRequest,
  postTempENTVRequest,
  putCancelNoticeRequest,
  requestNoticeSummary,
  requestNoticeToVacate,
  postRequestToChangeMoveOut
} from "../../utils/api";
import { showModalAction } from "../errorhandling/actions";
import * as actions from "./actions";

function* handleFetchNoticeToVacateNameId(action: ReturnType<typeof actions.requestENTVRequest>) {
  try {
    const nameid = action.payload;
    const response = yield call(requestNoticeToVacate, nameid);
    if (response.error) {
      yield put(actions.requestENTVError(response.error));
    } else {
      yield put(actions.requestENTVSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.requestENTVError(err.stack!));
    } else {
      yield put(actions.requestENTVError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handlePostDateRequest(action: ReturnType<typeof actions.postDateRequest>) {
  try {
    const payload = action.payload;
    const response = yield call(postTempENTVRequest, payload);

    if (response.error) {
      yield put(actions.postDateError(response.error));
    } else {
      yield put(actions.postDateSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.postDateError(err.stack!));
    } else {
      yield put(actions.postDateError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleForwardingAddressRequest(action: ReturnType<typeof actions.postAddressRequest>) {
  try {
    const payload = action.payload;
    const response = yield call(postForwardingAddressRequest, payload);

    if (response.error) {
      yield put(actions.postAddressError(response.error));
    } else {
      yield put(actions.postAddressSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.postAddressError(err.stack!));
    } else {
      yield put(actions.postAddressError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchNoticeSummary(action: ReturnType<typeof actions.requestENTVSummaryRequest>) {
  try {
    const nameid = action.payload;
    const response = yield call(requestNoticeSummary, nameid);
    if (response.error) {
      yield put(actions.requestENTVSummaryError(response.error));
    } else {
      yield put(actions.requestENTVSummarySuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.requestENTVSummaryError(err.stack!));
    } else {
      yield put(actions.requestENTVSummaryError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handlePostNoticeRequest(action: ReturnType<typeof actions.postNoticeRequest>) {
  try {
    const payload = action.payload;
    const response = yield call(postNewNoticeRequest, payload);

    if (response.error) {
      yield put(actions.postNoticeError(response.error));
    } else {
      yield put(actions.postNoticeSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.postNoticeError(err.stack!));
    } else {
      yield put(actions.postNoticeError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handlePostRefundRequest(action: ReturnType<typeof actions.postRefundRequest>) {
  try {
    const payload = action.payload;
    const response = yield call(postRefundRequest, payload);

    if (response.error) {
      yield put(actions.postRefundError(response.error));
    } else {
      yield put(actions.postRefundSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.postRefundError(err.stack!));
    } else {
      yield put(actions.postRefundError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleCancelNoticeRequest(action: ReturnType<typeof actions.putCancelRequest>) {
  try {
    const payload = action.payload;
    const response = yield call(putCancelNoticeRequest, payload);

    if (response.error) {
      yield put(actions.putCancelError(response.error));
    } else {
      yield put(actions.putCancelSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.putCancelError(err.stack!));
    } else {
      yield put(actions.putCancelError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handlePostMORequestDateRequest(action: ReturnType<typeof actions.moPostDateRequest>) {
  try {
    const payload = action.payload;
    const response = yield call(postRequestToChangeMoveOut, payload);

    if (response.error) {
      yield put(actions.moPostDateError(response.error));
    } else {
      yield put(actions.moPostDateSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.moPostDateError(err.stack!));
    } else {
      yield put(actions.moPostDateError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* watchFetchRequest() {
  yield takeEvery(getType(actions.requestENTVRequest), handleFetchNoticeToVacateNameId);
  yield takeEvery(getType(actions.postDateRequest), handlePostDateRequest);
  yield takeEvery(getType(actions.postAddressRequest), handleForwardingAddressRequest);
  yield takeEvery(getType(actions.requestENTVSummaryRequest), handleFetchNoticeSummary);
  yield takeEvery(getType(actions.postNoticeRequest), handlePostNoticeRequest);
  yield takeEvery(getType(actions.postRefundRequest), handlePostRefundRequest);
  yield takeEvery(getType(actions.putCancelRequest), handleCancelNoticeRequest);
  yield takeEvery(getType(actions.moPostDateRequest), handlePostMORequestDateRequest);
}

function* eNTVSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default eNTVSaga;
