import { Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import { LocalShipping, SettingsBackupRestore } from "@material-ui/icons";
import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import globalstyles from "../../../../global-styles";
import combinestyles from "../../../../utils/combinestyles";
import { thousands_separators } from "../../../shared/utils/utils";
import { NoticeToVacate } from "../../models";
import entvstyles from "../../styles";

//import NumberFormat from "react-number-format";
const styles = combinestyles(entvstyles, globalstyles);

export interface SufficientLeaseEndProps extends WithStyles<typeof styles> {
  noticeInfo?: NoticeToVacate;
}

type State = {};

type AllProps = SufficientLeaseEndProps & RouteComponentProps;

class SufficientLeaseEnd extends React.Component<AllProps, State> {
  state: Readonly<State> = {};

  render() {
    if (this.props.noticeInfo) {
      var title = this.props.noticeInfo!.copyLanguage.find(e => {
        return e.languageId === "A2";
      });
    }
    const monthCharges = this.props.noticeInfo!.leaseCharges;
    let monthlyCharges = [];
    if (monthCharges) {
      for (let i = 0; i < monthCharges.length; i++) {
        monthlyCharges.push(
          <Grid container className={this.props.classes.grouping}>
            <Grid xs={6} item style={{ marginBottom: "1rem" }}>
              <Typography gutterBottom style={{ display: "inline-block" }}>
                {monthCharges[i].description}
              </Typography>
            </Grid>
            <Grid xs={6} item style={{ textAlign: "right", marginBottom: "1rem" }}>
              <Typography className={this.props.classes.chargeslabel}>
                ${thousands_separators(monthCharges[i].amount.toFixed(2))}
              </Typography>
            </Grid>
          </Grid>
        );
      }
    }

    return (
      <form>
        <Grid container className={this.props.classes.grouping}>
          <Grid xs={12} item>
            <Typography
              className={this.props.classes.tablepadding}
              style={{ paddingTop: "1rem", paddingBottom: "1.5rem" }}
            >
              {title!.copyLanguage}
            </Typography>
          </Grid>
          <Grid container className={this.props.classes.grouping}>
            <Grid xs={4} item>
              <Grid container className={this.props.classes.grouping}>
                <Grid xs={12} item>
                  {" "}
                  <LocalShipping color="disabled" style={{ verticalAlign: "middle" }} />
                  <Typography
                    style={{
                      display: "inline-block",
                      fontSize: ".875rem",
                      paddingBottom: "1rem",
                      paddingLeft: ".5rem"
                    }}
                  >
                    Vacate Date <Moment format="MM/DD/YY" date={this.props.noticeInfo!.vacateDate} />
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <SettingsBackupRestore color="disabled" style={{ verticalAlign: "middle" }} />
                  <Typography
                    style={{
                      display: "inline-block",
                      fontSize: ".875rem",
                      paddingBottom: ".5rem",
                      paddingLeft: ".5rem"
                    }}
                  >
                    Lease End {this.props.noticeInfo!.leaseEnd}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={8} item>
              <Grid container className={this.props.classes.grouping}>
                <Grid xs={6} item>
                  <Typography
                    style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold", marginBottom: "1rem" }}
                  >
                    Charges
                  </Typography>
                </Grid>
                <Grid xs={6} item style={{ textAlign: "right" }}>
                  <Typography
                    style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold", marginBottom: "1rem" }}
                  >
                    Amount
                  </Typography>
                </Grid>
                <Grid xs={10} item>
                  <Typography className={this.props.classes.chargesdesclabel} style={{ marginBottom: "1rem" }}>
                    Monthly Apartment Rent {this.props.noticeInfo!.proratedLabel}
                    {/* <Moment
                      format="MM/DD/YY"
                      date={
                        new Date(
                          new Date(this.props.noticeInfo!.leaseEnd).getFullYear(),
                          new Date(this.props.noticeInfo!.leaseEnd).getMonth(),
                          1
                        )
                      }
                    />{" "}
                    - <Moment format="MM/DD/YY" date={this.props.noticeInfo!.leaseEnd} /> */}
                  </Typography>
                </Grid>
                <Grid xs={2} item style={{ textAlign: "right" }}>
                  <Typography className={this.props.classes.chargeslabel} style={{ fontWeight: 500 }}>
                    ${thousands_separators(this.props.noticeInfo!.proratedRent.toFixed(2))}
                  </Typography>
                </Grid>

                {monthlyCharges.length > 0 && (
                  <Grid container className={this.props.classes.grouping} style={{ marginBottom: "1rem" }}>
                    <Grid xs={12} item>
                      <Typography
                        gutterBottom
                        className={this.props.classes.chargesheadinglabel}
                        style={{ marginBottom: "1rem" }}
                      >
                        Recurring Monthly Charges{" "}
                        <Moment
                          format="MM/DD/YY"
                          date={
                            new Date(
                              new Date(this.props.noticeInfo!.leaseEnd).getFullYear(),
                              new Date(this.props.noticeInfo!.leaseEnd).getMonth(),
                              1
                            )
                          }
                        />{" "}
                        - <Moment format="MM/DD/YY" date={this.props.noticeInfo!.leaseEnd} />
                      </Typography>
                    </Grid>
                    <Grid container className={this.props.classes.grouping}>
                      {monthlyCharges}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withRouter(connect()(withStyles(styles)(SufficientLeaseEnd)));
