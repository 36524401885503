import { Button, Grid, Paper, TextField, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { RpActivity } from "../../shared/models";
import { UserInfoItem } from "../../userInfo/models";
import { putCancelRequest, requestENTVSummaryRequest } from "../actions";
import { CancelRequestData, NoticeSummary, NoticeToVacate } from "../models";
import entvstyles from "../styles";
import CancelModal from "./cancelModal";
import ENTVHeader from "./entv-header";
import SummaryElections from "./summaryElections";
import SummaryInfo from "./summaryInfo";
import SummaryText from "./summaryText";

const styles = combinestyles(entvstyles, globalstyles);

export interface ENTVSummaryProps extends WithStyles<typeof styles> {
  //onContinue: any;
  noticeInfo: NoticeToVacate;
}

interface PropsFromState {
  user?: UserInfoItem;
  myNoticeSummary?: NoticeSummary;
  loading: boolean;
  errors: string;
}

interface PropsFromDispatch {
  requestENTVSummaryRequest: typeof requestENTVSummaryRequest;
  putCancelRequest: typeof putCancelRequest;
  logActivity: typeof logActivity;
}

type State = {
  cancelOpen: boolean;
  displayCancelBtn: boolean;
  cancelReasonText: string;
};

type AllProps = ENTVSummaryProps & RouteComponentProps & PropsFromDispatch & PropsFromState;

class ENTVSummary extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    cancelOpen: false,
    displayCancelBtn: false,
    cancelReasonText: ""
  };

  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.requestENTVSummaryRequest(nameId);
    setTimeout(() => window.scrollTo(0, 0), 400);
  }

  change = (e: any) => {
    if (!e.target) {
      return;
    }

    //console.log(e.target.value);
    this.setState({ cancelReasonText: e.target.value });

    if (e.target.value.length > 0) this.setState({ displayCancelBtn: true });
    else this.setState({ displayCancelBtn: false });
  };

  handleCancel = () => {
    const nameId = this.props.user!.nameId;
    const cancelRequestUpdate: CancelRequestData = {
      nameID: nameId,
      cancelReason: this.state.cancelReasonText
    };

    //console.log(cancelRequestUpdate);
    this.props.putCancelRequest(cancelRequestUpdate);

    const newActivity: RpActivity = {
      nameid: nameId,
      actcode: "NC",
      note: "eNTV Cancellation Requested",
      appsource: "WEBRP"
    };
    this.props.logActivity(newActivity);
    this.setState({ cancelOpen: true });
  };

  handleCloseCancel = () => {
    this.setState({ cancelOpen: false });
    window.location.reload();
  };

  render() {
    if (!this.props.myNoticeSummary) {
      return <div />;
    }
    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <ENTVHeader />
          <SummaryText noticeSummary={this.props.myNoticeSummary} />
          <SummaryElections noticeSummary={this.props.myNoticeSummary} />
          <SummaryInfo />
          {this.props.myNoticeSummary!.displayCancelBtn.toString() === "Y" && (
            <Paper className={this.props.classes.paper}>
              <Grid container alignItems="center" className={this.props.classes.grouping}>
                <Grid item xs={12} style={{ marginBottom: ".5rem", marginTop: ".5rem" }}>
                  <Typography variant="h3" gutterBottom style={{ display: "inline-block", marginRight: "100px" }}>
                    Cancelling Your Notice to Vacate
                  </Typography>
                  <Typography gutterBottom style={{ display: "inline-block" }}>
                    Please provide a reason for your cancellation request.*
                  </Typography>
                  <TextField
                    name="cancelReason"
                    id="standard-required"
                    label="Cancel Reason"
                    value={this.state.cancelReasonText}
                    onChange={(e: any) => this.change(e)}
                    margin="normal"
                    fullWidth
                  />
                  <Button
                    onClick={this.handleCancel}
                    disabled={!this.state.displayCancelBtn}
                    color="secondary"
                    variant="contained"
                    autoFocus
                  >
                    Request to Cancel
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          )}
          <CancelModal onContinue={this.handleCloseCancel} open={this.state.cancelOpen} />
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ selectAccount, noticeToVacate }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  myNoticeSummary: noticeToVacate.myNoticeSummary.data,
  loading: noticeToVacate.myNoticeSummary.loading,
  errors: noticeToVacate.myNoticeSummary.errors
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  requestENTVSummaryRequest: (nameId: string) => dispatch(requestENTVSummaryRequest(nameId)),
  putCancelRequest: (cncl: CancelRequestData) => dispatch(putCancelRequest(cncl)),
  logActivity: (act: RpActivity) => dispatch(logActivity(act))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ENTVSummary))
);
