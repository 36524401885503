import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as Yup from "yup";

import { RootState } from "../../../store/root-reducer";
import { getActiveUserInfo } from "../../selectAccount/selector";
import FormikInputError from "../../shared/components/FormikInputError";
import { UserInfoItem } from "../../userInfo/models";
import { residentRelationsPostRequest } from "../actions";
import { ResidentRelationDto } from "../models";
import ResidentRelationsFileUpload from "./ResidentRelationsFileUpload";
import styles from "./styles";
import DialogHeader from "../../shared/components/DialogHeader";

const ValidationSchema = Yup.object().shape({
  description: Yup.string().max(8000, "8000 character limit").required("Description is required"),
});

interface Props extends WithStyles<typeof styles> {}

interface PropsFromState {
  user?: UserInfoItem;
}

interface PropsFromDispatch {
  residentRelationsPostRequest: typeof residentRelationsPostRequest;
}

type MyState = {
  modalVisibility: boolean;
  fileToUpload: File | null;
  fileInputError: boolean;
};

type AllProps = Props & PropsFromState & PropsFromDispatch;

class ResidentRelationsForm extends Component<AllProps, MyState> {
  state: Readonly<MyState> = {
    modalVisibility: false,
    fileToUpload: null,
    fileInputError: false
  };

  handleOpenModal = () => {
    this.setState({ modalVisibility: true });
  };
  handleCloseModal = () => {
    this.setState({ modalVisibility: false });
  };

  handleSubmit = (values: any) => {
    if (this.state.fileInputError) {
      return;
    }
    const payload: ResidentRelationDto = {
      nameId: this.props.user!.nameId,
      description: values.description,
      file: this.state.fileToUpload
    };
    this.props.residentRelationsPostRequest(payload);
    this.handleCloseModal();
  };

  handleSaveFile = (file: File | null) => {
    this.setState({ fileToUpload: file });
  };

  handleFileInputError = (hasError: boolean) => {
    this.setState({ fileInputError: hasError, fileToUpload: null });
  };

  render() {
    return (
      <React.Fragment>
        <Button variant="contained" color="primary" onClick={this.handleOpenModal}>
          Contact
        </Button>
        <Dialog open={this.state.modalVisibility} onClose={this.handleCloseModal} fullWidth>
          <DialogHeader title="Resident Relations" handleClose={this.handleCloseModal} />
          <hr />
          <DialogContent>
            <Formik
              initialValues={{
                description: "",
              }}
              validationSchema={ValidationSchema}
              onSubmit={(values) => {
                this.handleSubmit(values);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <DialogContentText style={{ marginBottom: "1rem" }}>Your message</DialogContentText>
                  <Field
                    component="textarea"
                    name="description"
                    rows="10"
                    className={this.props.classes.residentRelationsTextArea}
                  />
                  <FormikInputError>
                    {errors.description && touched.description ? <div>{errors.description}</div> : null}
                  </FormikInputError>
                  <ResidentRelationsFileUpload
                    handleFileInputError={this.handleFileInputError}
                    handleSaveFile={this.handleSaveFile}
                  />
                  <DialogActions>
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography variant="caption">
                          Do Not Upload Any Documents Containing Personal Information Such As Bank Account Numbers,
                          Social Security, Driver License, Etc...
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ textAlign: "right" }}>
                        <Button variant="contained" color="primary" size="large" type="submit">
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  residentRelationsPostRequest: (payload: ResidentRelationDto) => dispatch(residentRelationsPostRequest(payload)),
});

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(withStyles(styles)(ResidentRelationsForm));
