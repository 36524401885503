import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { loadUserInfo } from "../../utils/api";
import { showModalAction } from "../errorhandling/actions";
import { fetchError, fetchSuccess } from "./actions";
import * as selectAccount from "./actions";

function* handleFetch(action: ReturnType<typeof selectAccount.fetchRequest>) {
  try {
    const nameId = action.payload;

    const response = yield call(loadUserInfo, nameId);

    if (response.error) {
      yield put(fetchError(response.error));
    } else {
      yield put(fetchSuccess({ nameId: nameId, data: response }));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(fetchError(err.stack!));
    } else {
      yield put(fetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// function* handleLogSelectAccount(action: ReturnType<typeof selectAccount.selectAccount>) {
//   try {
//     const nameid = action.payload;
//     const activity = { nameid: nameid, appsource: "WEBRP", actcode: "LI", note: "Logged in " } as RpActivity;
//     yield call(postActivity, activity);
//   } catch (err) {
//     return;
//   }
// }

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(getType(selectAccount.fetchRequest), handleFetch);
  //yield takeEvery(getType(selectAccount.selectAccount), handleLogSelectAccount);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* userInfoSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default userInfoSaga;
