import { Card, Divider, Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import React, { Component } from "react";

import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import { SvcProvider } from "../models";
import serviceproviderstyles from "./styles";
import SvcProviderTileContact from "./SvcProviderTileContact";
import SvcProviderTileSvcs from "./SvcProviderTileSvcs";

const styles = combinestyles(serviceproviderstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  serviceProviders: SvcProvider[];
}

class SvcProviderTile extends Component<Props> {
  render() {
    const { serviceProviders } = this.props;
    serviceProviders.sort((a: SvcProvider, b: SvcProvider) => {
      return a.sortOrder - b.sortOrder;
    });
    return serviceProviders.map(provider => (
      <Grid item xs={12} sm={6}>
        <Card key={provider.id} className={this.props.classes.card}>
          <Typography variant="h5" gutterBottom>
            {provider.name}
          </Typography>
          <SvcProviderTileContact
            contactName={provider.contactName}
            contactEmail={provider.contactEmail}
            contactPhone={provider.contactPhone}
            phone={provider.phone}
            website={provider.website}
          />
          <Divider style={{ marginBottom: "1rem" }} />
          <SvcProviderTileSvcs services={provider.serviceTypes} />
        </Card>
      </Grid>
    ));
  }
}

export default withStyles(styles)(SvcProviderTile);
