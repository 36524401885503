import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchError, fetchSuccess } from "./actions";
import { loadContact, loadContactUsList, residentRelationsContact, residentRelationsAttachFile } from "../../utils/api";
import { getType } from "typesafe-actions";
import * as contact from "./actions";

import { showModalAction } from "../errorhandling/actions";

function* handleFetch(action: ReturnType<typeof contact.fetchRequest>): any {
  try {
    const rmpropid = action.payload.rmpropId;
    const nameId = action.payload.nameId;
    const response = yield call(loadContact, rmpropid, nameId);

    if (response.error) {
      yield put(fetchError(response.error));
    } else {
      yield put(fetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(fetchError(err.stack!));
    } else {
      yield put(fetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleContactUsFetch(action: ReturnType<typeof contact.contactUsRequest>): any {
  try {
    const nameId = action.payload.nameId;
    const response = yield call(loadContactUsList, nameId);

    if (response.error) {
      yield put(contact.contactUSError(response.error));
    } else {
      yield put(contact.contactUSSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(contact.contactUSError(err.stack!));
    } else {
      yield put(contact.contactUSError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleResidentRelationsPostRequest(action: ReturnType<typeof contact.residentRelationsPostRequest>): any {
  try {
    const response = yield call(residentRelationsContact, action.payload);

    if (response.error) {
      yield put(contact.residentRelationsPostError(response.error));
    } else {
      if (action.payload.file) {
        yield call(residentRelationsAttachFile, { postId: response.id, resRelationFile: action.payload.file });
      }
      yield put(contact.residentRelationsPostSuccess());
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(contact.residentRelationsPostError(err.stack!));
    } else {
      yield put(contact.residentRelationsPostError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(getType(contact.fetchRequest), handleFetch);
  yield takeEvery(getType(contact.residentRelationsPostRequest), handleResidentRelationsPostRequest);
  yield takeEvery(getType(contact.contactUsRequest), handleContactUsFetch);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* contactSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default contactSaga;
