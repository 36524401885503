import React from "react";
import { ModalModel } from "../../errorhandling/models";
import { Modal, Typography } from "@material-ui/core";
import ReportProblem from "@material-ui/icons/ReportProblem";

interface Props {
  errorDetails?: ModalModel;
  showError: boolean;
  hideError: any;
  classes: any;
}

const ErrorModal = (props: Props) => {
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.showError}
      onClose={props.hideError}
    >
      <div className={props.classes.errorModal}>
        <ReportProblem className={props.classes.icon} color="error" />
        <Typography variant="h6" id="modal-title">
          Connection Error
        </Typography>
        <Typography variant="subtitle1" id="simple-modal-description">
          We are unable to connect to the network. Please try again shortly
        </Typography>
        <Typography variant="body2" id="simple-modal-detail">
          {props.errorDetails ? props.errorDetails.modalProps.message : ""}
        </Typography>
      </div>
    </Modal>
  );
};

export default ErrorModal;
