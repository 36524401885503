import { createAction } from "typesafe-actions";

import { NewsItem } from "./models";

const FETCH_REQUEST = "news/FETCH_REQUEST";
const FETCH_SUCCESS = "news/FETCH_SUCCESS";
const FETCH_ERROR = "news/FETCH_ERROR";

export const fetchRequest = createAction(FETCH_REQUEST)<string>();
export const fetchSuccess = createAction(FETCH_SUCCESS)<{ data: NewsItem[] }>();
export const fetchError = createAction(FETCH_ERROR)<string>();
