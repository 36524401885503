import * as React from "react";
import { Button, DialogActions, TextField, Typography, DialogContent } from "@material-ui/core";
import { FormikProps } from "formik";
import { InputFormState } from "./ReportAbuseMenuItem";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

interface MyProps {
  descriptionText: string;
  submitButtonText: string;
}

type AllProps = MyProps & WithStyles<typeof styles> & FormikProps<InputFormState>;

const ReportAbuseMenuForm = (props: AllProps) => {
  const {
    values: { description },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    classes
  } = props;

  const change = (field: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(field, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Typography>{props.descriptionText}</Typography>
        <TextField
          id="description"
          name="description"
          label=""
          multiline
          fullWidth
          value={description}
          helperText={touched.description ? errors.description : ""}
          error={touched.description && Boolean(errors.description)}
          onChange={change.bind(null, "description")}
          className={classes.textField}
        />
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="contained" color="primary" disabled={!isValid}>
          {props.submitButtonText}
        </Button>
      </DialogActions>
    </form>
  );
};

export default withStyles(styles)(ReportAbuseMenuForm);
