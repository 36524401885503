import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  MuiThemeProvider,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import * as React from "react";
import { Provider } from "react-redux";
import { OidcProvider, processSilentRenew } from "redux-oidc";

import App from "./App";
import configureStore from "./configureStore";
import LoginThingy from "./LoginThingy";
import { RootState } from "./store/root-reducer";
import styles from "./styles";
import theme from "./theme";
import userManager from "./utils/userManager";

import IdleTimer from "react-idle-timer";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href")!;
const history = createBrowserHistory({ basename: baseUrl });
const initialState = (window as any).initialReduxState as RootState;
export const store = configureStore(history, initialState);

type AllProps = WithStyles<typeof styles>;

interface MyState {
  visible: boolean;
}

class AppContainer extends React.Component<AllProps, MyState> {
  idleTimer: any;
  onAction: (e: any) => void;
  onActive: (e: any) => void;
  onIdle: (e: any) => void;
  handleClose: (e: any) => void;
  constructor(props: AllProps) {
    super(props);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleClose = this._handleClose.bind(this);
    this.state = {
      visible: false
    };
  }
  public render() {
    return (
      <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
          <ConnectedRouter history={history}>
            <MuiThemeProvider theme={theme}>
              <LoginThingy>
                <IdleTimer
                  ref={(ref: any) => {
                    this.idleTimer = ref;
                  }}
                  element={document}
                  onActive={this.onActive}
                  onIdle={this.onIdle}
                  onAction={this.onAction}
                  debounce={250}
                  timeout={1000 * 60 * 30} // 30 minutes
                />

                <Dialog open={this.state.visible} onClose={this.handleClose} maxWidth="sm">
                  <DialogContent>
                    <Typography>You have been logged out due to inactivity. Please log in again.</Typography>
                    <DialogActions>
                      <Button onClick={this.handleClose}>Close</Button>
                    </DialogActions>
                  </DialogContent>
                </Dialog>

                <App />
              </LoginThingy>
            </MuiThemeProvider>
          </ConnectedRouter>
        </OidcProvider>
      </Provider>
    );
  }

  _handleClose(e: any) {
    this.setState({
      visible: false
    });
    history.push("/logout");
  }

  _onAction(e: any) {
    processSilentRenew();
  }

  _onActive(e: any) {
    processSilentRenew();
  }

  _onIdle(e: any) {
    this.setState({
      visible: true
    });
  }
}

export default withStyles(styles)(AppContainer);
