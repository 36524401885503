import * as React from "react";

import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { InjectedProps } from "@material-ui/core/withMobileDialog";

interface Props {
  onContinue: any;
  onCancel: any;
  quickFix: string;
  open: boolean;
}
type AllProps = Props & InjectedProps;

class ServiceReqFormQuickfix extends React.Component<AllProps> {
  render() {
    const { fullScreen, open, quickFix } = this.props;

    return (
      <Dialog fullScreen={fullScreen} open={open}>
        <DialogTitle>Try this quick fix...</DialogTitle>
        <DialogContent>
          {/* {quickFix.map(x =><Typography>{x}</Typography> )}           */}
          <Typography>{quickFix}</Typography>
        </DialogContent>
        <DialogContent>
          <Typography>Did this solve the issue?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="default" variant="contained" autoFocus>
            Yes, Cancel Request
          </Button>
          <Button onClick={this.props.onContinue.bind(this, this.state)} color="primary" variant="contained" autoFocus>
            No, Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ServiceReqFormQuickfix;
