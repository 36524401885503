import React, { Component } from "react";
import { WithStyles, withStyles, Typography, Grid } from "@material-ui/core";
import { SvcProvider, SvcProviderCategory } from "../models";
import SvcProviderTile from "./SvcProviderTile";

import serviceproviderstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(serviceproviderstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  serviceProviders: SvcProvider[];
}

class SvcProviderCat extends Component<Props> {
  render() {
    const { serviceProviders } = this.props;

    if (!serviceProviders) {
      return <div />;
    }

    const cats = [...new Set(serviceProviders.map(y => y.category))];
    const uniCats: SvcProviderCategory[] = [];

    cats.forEach(c => {
      const found = uniCats.find(e => {
        return e.id === c.id;
      });
      if (!found) {
        uniCats.push(c);
      }
    });

    uniCats.sort((a: SvcProviderCategory, b: SvcProviderCategory) => {
      return a.sortOrder - b.sortOrder;
    });

    return uniCats.map(cat => (
      <div>
        <Typography key={cat.id} variant="h3" className={this.props.classes.headerGroup}>
          {cat.name}
        </Typography>
        <div>
          <Grid container spacing={16} style={{ marginBottom: ".5rem" }}>
            <SvcProviderTile serviceProviders={serviceProviders.filter(p => p.category.id === cat.id)} />{" "}
          </Grid>
        </div>
      </div>
    ));
  }
}

export default withStyles(styles)(SvcProviderCat);
