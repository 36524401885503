import { push } from "connected-react-router";
import { User } from "oidc-client";
import * as React from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";

import { fetchRequest as userInfoFetchRequest, selectAccount } from "../features/selectAccount/actions";
import userManager from "../utils/userManager";

interface PropsFromState {}
interface PropsFromDispatch {
  selectAccount: typeof selectAccount;
  userInfoFetchRequest: typeof userInfoFetchRequest;
  push: typeof push;
}

type AllProps = PropsFromState & PropsFromDispatch;
class CallbackPage extends React.Component<AllProps> {
  successCallback = (user: User) => {
    // get the user's previous location, passed during signinRedirect()
    if (Array.isArray(user.profile.nid)) {
    } else {
      this.props.selectAccount(user.profile.nid);
      this.props.userInfoFetchRequest(user.profile.nid);
    }

    const redirectPath = user.state.path as string;
    this.props.push(redirectPath);
  };

  errorCallback = (error: Error) => {
    this.props.push("/");
  };

  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}
      >
        <div>Loading...</div>
      </CallbackComponent>
    );
  }
}

const mapStateToProps = (): PropsFromState => ({});

const mapDispatchToProps: PropsFromDispatch = {
  selectAccount: (nameId: string) => selectAccount(nameId),
  userInfoFetchRequest: (nameid: string) => userInfoFetchRequest(nameid),
  push: push,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallbackPage);
