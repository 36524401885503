import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  statementFetchSuccess,
  statementFetchError,
  maxStatementFetchSuccess,
  maxStatementFetchError
} from "./actions";
import { loadStatement, loadMaxNumStatements } from "../../utils/api";
import { getType } from "typesafe-actions";
import * as actions from "./actions";

import { showModalAction } from "../errorhandling/actions";

function* handleFetchStatement(action: ReturnType<typeof actions.statementFetchRequest>) {
  try {
    const nameId = action.payload.nameId;
    const numStatements = action.payload.numStatements;

    const response = yield call(loadStatement, nameId, numStatements);

    if (response.error) {
      yield put(statementFetchError(response.error));
    } else {
      yield put(statementFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(statementFetchError(err.stack!));
    } else {
      yield put(statementFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchMaxNumStatements(action: ReturnType<typeof actions.maxStatementFetchRequest>) {
  try {
    const nameId = action.payload.nameId;

    const response = yield call(loadMaxNumStatements, nameId);

    if (response.error) {
      yield put(maxStatementFetchError(response.error));
    } else {
      yield put(maxStatementFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(maxStatementFetchError(err.stack!));
    } else {
      yield put(maxStatementFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(getType(actions.statementFetchRequest), handleFetchStatement);
  yield takeEvery(getType(actions.maxStatementFetchRequest), handleFetchMaxNumStatements);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* statementSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default statementSaga;
