import * as React from "react";
import { Typography, Paper, Grid } from "@material-ui/core";
import { AccountActivity } from "../models";
import { UserInfoItem } from "../../../features/userInfo/models";
import * as constants from "../constants";
import Moment from "react-moment";

interface Props {
  activityList: AccountActivity[];
  downloadDocument: any;
  getActivityIcon: any;
  classes: any;
  user: UserInfoItem;
}

type AllProps = Props;

class AccountActivityTile extends React.Component<AllProps> {
  getStyle = (activity: string) => {
    if (activity === "Statement of Deposit Account" || activity === "SODA") {
      return { color: "gray" };
    }
    return {};
  };

  render() {
    return (
      <Paper className={this.props.classes.paper}>
        <Typography variant="h2" style={{ marginBottom: "1.5rem" }}>
          Account Activity Since Moveout
        </Typography>
        <Grid container direction="row" spacing={16}>
          {this.props.activityList.map((a) => (
            <Grid item key={a.id} style={{ width: "100%" }}>
              <Grid container direction="row" alignItems="stretch" wrap="nowrap" spacing={16}>
                <Grid item>
                  <Typography>
                    <Moment date={a.uploadDate} format="MM/DD/YYYY" />
                  </Typography>
                </Grid>
                <Grid item>
                  {a.description === "Move Out" || a.name === "" ? (
                    <img
                      alt={a.description === "Move Out" ? "Move out" : "Mail"}
                      src={a.description === "Move Out" ? constants.MOVE_OUT : constants.MAIL}
                      style={{ width: "20px", height: "20px" }}
                    />
                  ) : (
                    <img
                      alt="Document Icon"
                      onClick={() => this.props.downloadDocument(a.id, a.name)}
                      style={{ width: "20px", height: "20px", cursor: "pointer" }}
                      src={this.props.getActivityIcon(a.description)}
                    />
                  )}
                </Grid>
                <Grid item xs>
                  {a.description === "Move Out" || a.name === "" ? (
                    <div>
                      <Typography gutterBottom>{a.description.length > 0 ? a.description : a.name}</Typography>
                    </div>
                  ) : (
                    <div style={{ cursor: "pointer" }} onClick={() => this.props.downloadDocument(a.id, a.name)}>
                      <Typography gutterBottom>{a.description.length > 0 ? a.description : a.name}</Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  }
}

export default AccountActivityTile;
