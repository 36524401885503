import * as React from "react";
import { withStyles, WithStyles } from "@material-ui/core";
import { fetchRequest } from "../features/guest/actions";

import GuestHeader from "../features/guest/components/GuestHeader";
import GuestList from "../features/guest/components/guest-list";
import GuestDetails from "../features/guest/components/guest-details";
import GuestForm from "../features/guest/components/guest-form";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import { RootState } from "../store/root-reducer";
import { GuestItem } from "../features/guest/models";

import gueststyles from "../features/mylease/components/styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(gueststyles, globalstyles);

interface IGuestProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
}

interface IPageState {}

interface PropsFromState {
  user?: UserInfoItem;
  data: GuestItem[];
  // errors: string;
  // loading: boolean;
}

interface PropsFromDispatch {
  fetchRequest: typeof fetchRequest;
}

type AllProps = IGuestProps & PropsFromState & PropsFromDispatch & RouteComponentProps<{ startDateIndex: string }>;

class Guest extends React.Component<AllProps> {
  state: IPageState = {};

  componentDidMount() {
    const user = this.props.user;
    const nameid = user!.nameId;
    this.props.fetchRequest(nameid);
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <GuestHeader />
          <GuestList />
          <GuestDetails />
          <GuestForm />
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ guest, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  data: guest.guestList.data,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  fetchRequest: (nameid: string) => dispatch(fetchRequest(nameid)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Guest)));
