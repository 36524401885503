import { Paper, Switch, Typography, WithStyles, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import contactstyles from "./styles";
import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { UserInfoItem } from "../../userInfo/models";
import { transUnionSuppressFetchRequest, transUnionSuppressUpdateRequest } from "../actions";
import { RpActivity, TransUnionPaymentReporting } from "../models";

const styles = combinestyles(contactstyles, globalstyles);
interface Props extends WithStyles<typeof styles> {
  tileLocked: boolean;
}
interface PropsFromState {
  user?: UserInfoItem;
  transunionSuppress?: TransUnionPaymentReporting;
}
interface PropsFromDispatch {
  logActivity: typeof logActivity;
  transUnionSuppressUpdateRequest: typeof transUnionSuppressUpdateRequest;
  transUnionSuppressFetchRequest: typeof transUnionSuppressFetchRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

class ProfileTransUnionSuppressTile extends Component<AllProps> {
  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.transUnionSuppressFetchRequest({ nameId });
  }

  turnTransUnionSuppressOff = () => {
    const payload: TransUnionPaymentReporting = {
      nameId: this.props.user!.nameId,
      tuSuppress: false,
      description: ""
    };
    this.props.transUnionSuppressUpdateRequest(payload);

    this.props.logActivity({
      actcode: "TI",
      note: "Resident opted into TransUnion Payment Reporting."
    });
  };

  turnTransUnionSuppressOn = () => {
    const payload: TransUnionPaymentReporting = {
      nameId: this.props.user!.nameId,
      tuSuppress: true,
      description: ""
    };
    this.props.transUnionSuppressUpdateRequest(payload);

    this.props.logActivity({
      actcode: "TO",
      note: "Resident opted out TransUnion Payment Reporting."
    });
  };

  render() {
    const { transunionSuppress } = this.props;
    const { tileLocked, classes } = this.props;
    const lockedCssClass = tileLocked ? classes.lockedTile : "";

    // const textTU = this.props.transunionSuppress!.description?.toString();
    if (transunionSuppress !== undefined) {
      const toggleSwitch = transunionSuppress.tuSuppress ? (
        <Switch checked={false} onClick={this.turnTransUnionSuppressOff} />
      ) : (
        <Switch checked={true} onClick={this.turnTransUnionSuppressOn} />
      );
      return (
        <Paper className={[classes.paper, lockedCssClass].join(" ")}>
          <Typography variant="h3" style={{ marginBottom: "1rem" }}>
            TransUnion Payment Reporting
          </Typography>
          <div />
          <Typography style={{ display: "inline-block" }}>No</Typography>
          {toggleSwitch}
          <Typography style={{ display: "inline-block" }}>Yes</Typography>
          <hr />
          <Typography gutterBottom>{transunionSuppress.description}</Typography>
        </Paper>
      );
    } else {
      return <React.Fragment />;
    }
  }
}
const mapStateToProps = ({ selectAccount, profile }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  transunionSuppress: profile.transunionSuppress
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  logActivity: (activity: RpActivity) => dispatch(logActivity(activity)),
  transUnionSuppressUpdateRequest: (payload: TransUnionPaymentReporting) =>
    dispatch(transUnionSuppressUpdateRequest(payload)),
  transUnionSuppressFetchRequest: (payload: { nameId: string }) => dispatch(transUnionSuppressFetchRequest(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProfileTransUnionSuppressTile));
