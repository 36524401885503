import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { AdverseActionLetterData } from "./models";
import * as actions from "./actions";

export type AdverseActionLetterAction = ActionType<typeof actions>;

export type AdverseActionLetterState = Readonly<{
  data?: AdverseActionLetterData;
  errors?: string;
  loading: boolean;
}>;

const initialState: AdverseActionLetterState = {
  data: undefined,
  errors: undefined,
  loading: false,
};

const reducer: Reducer<AdverseActionLetterState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.fetchAsyncAction.request): {
      return { ...state, loading: true };
    }
    case getType(actions.fetchAsyncAction.success): {
      return { ...state, loading: false, data: action.payload };
    }
    case getType(actions.fetchAsyncAction.failure): {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
