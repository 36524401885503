import * as React from "react";
import { Paper, Grid, Typography, Button, Avatar } from "@material-ui/core";
import PaymentPlanDialog from "./PaymentPlanDialog";

interface Props {
  balance: number;
  userNameId: string;
  paymentPlanRequest: any;
  classes: any;
}

type AllProps = Props;

class PaymentPlanTile extends React.Component<AllProps> {
  state = {
    openPaymentPlanDialog: false
  };

  openPaymentPlanDialog = () => {
    this.setState({ openPaymentPlanDialog: true });
  };

  closePaymentPlanDialog = () => {
    this.setState({ openPaymentPlanDialog: false });
  };

  render() {
    return (
      <React.Fragment>
        <Paper
          className={this.props.classes.paper}
          style={{
            width: "100%",
            borderLeftWidth: "6px",
            borderLeftStyle: "solid",
            borderLeftColor: "#3d90d4"
          }}
        >
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Grid container alignItems="center" spacing={16}>
                <Grid item>
                  <Avatar style={{ color: "#FFF", width: "2rem", backgroundColor: "#3d90d4", height: "2rem" }}>
                    i
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="h4">Payment Plan Available</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs style={{ textAlign: "right" }}>
              <Button onClick={this.openPaymentPlanDialog} variant="outlined" color="primary" aria-label="Add">
                Request Plan
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <PaymentPlanDialog
          balance={this.props.balance}
          open={this.state.openPaymentPlanDialog}
          handleClose={this.closePaymentPlanDialog}
          userNameId={this.props.userNameId}
          paymentPlanRequest={this.props.paymentPlanRequest}
        />
      </React.Fragment>
    );
  }
}

export default PaymentPlanTile;
