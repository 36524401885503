import { Button, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import contactstyles from "../../../features/myprofile/components/styles";
import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { insuranceFetchRequest } from "../actions";
import { RentersInsurance } from "../models";

const styles = combinestyles(contactstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  tileLocked: boolean;
}

interface PropsFromState {
  user?: UserInfoItem;
  insurance?: RentersInsurance;
}
interface PropsFromDispatch {
  insuranceFetchRequest: typeof insuranceFetchRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

class ProviderRentersInsuranceTile extends Component<AllProps> {
  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.insuranceFetchRequest({ nameId });
  }
  render() {
    const { insurance, tileLocked, classes } = this.props;
    const lockedCssClass = tileLocked ? classes.lockedTile : "";
    if (insurance) {
      return (
        <Paper className={[classes.paper, lockedCssClass].join(" ")}>
          <Typography variant="h3" style={{ marginBottom: "1rem" }}>
            Renter's Insurance Plan
          </Typography>
          {insurance.policyNumber && (
            <div>
              <Typography variant="h5">Policy Number</Typography>
              <Typography style={{ marginBottom: "1rem" }}>{insurance.policyNumber}</Typography>
            </div>
          )}
          {insurance.expirationDate && (
            <div>
              <Typography variant="h5">Expiration Date</Typography>
              <Typography style={{ marginBottom: "1rem" }}>{formatDate(insurance.expirationDate)}</Typography>
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            aria-label="View Plan Options"
            href="http://www.rentersdirect.com"
            target="_blank"
          >
            View Plan Options
          </Button>
        </Paper>
      );
    } else {
      return <React.Fragment />;
    }
  }
}

function formatDate(rawDate?: Date) {
  let result = "";

  try {
    if (rawDate !== undefined) {
      try {
        const utcDate = new Date(rawDate);
        const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000).toLocaleString();
        result = new Intl.DateTimeFormat("en-US").format(Date.parse(localDate));
      } catch (err) {
        const utcDate = new Date(rawDate);
        result = new Intl.DateTimeFormat("en-US").format(Date.parse(utcDate.toUTCString()));
      }
    }
  } catch (err) {}
  return result;
}

const mapStateToProps = ({ profile, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  insurance: profile.insurance
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  insuranceFetchRequest: (payload: { nameId: any }) => dispatch(insuranceFetchRequest(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProviderRentersInsuranceTile));
