import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { UserInfoItem } from "./models";
import * as userInfo from "./actions";

export type UserInfoAction = ActionType<typeof userInfo>;

export type UserInfoState = Readonly<{
  loading: boolean;
  data?: UserInfoItem;
  errors?: string;
}>;

const initialState: UserInfoState = {
  data: undefined,
  errors: undefined,
  loading: false
};

const reducer: Reducer<UserInfoState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(userInfo.fetchRequest): {
      return { ...state, loading: true };
    }
    case getType(userInfo.fetchSuccess): {
      return { ...state, loading: false, data: action.payload };
    }
    case getType(userInfo.fetchError): {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
