import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { svcFetchSuccess, svcFetchError } from "./actions";
import { loadSvcProviders } from "../../utils/api";
import { getType } from "typesafe-actions";
import * as actions from "./actions";

import { showModalAction } from "../errorhandling/actions";

function* handleFetch(action: ReturnType<typeof actions.svcFetchRequest>) {
  try {
    const rmpropid = action.payload.rmpropid;

    const response = yield call(loadSvcProviders, rmpropid);

    if (response.error) {
      yield put(svcFetchError(response.error));
    } else {
      yield put(svcFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(svcFetchError(err.stack!));
    } else {
      yield put(svcFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(getType(actions.svcFetchRequest), handleFetch);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* statementSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default statementSaga;
