import { Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

import contactstyles from "../features/contact/components/styles";
import { ContactItem } from "../features/contact/models";
import { downloadDocumentRequest, getDocumentsRequest, resetDownloadError } from "../features/documents/actions";
import DigitalDocumentTile from "../features/documents/components/DigitalDocsTile";
import * as constants from "../features/documents/constants";
import { DigitalDocument } from "../features/documents/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import { compareDates } from "../features/shared/utils/utils";
import { UserInfoItem } from "../features/userInfo/models";
import globalstyles from "../global-styles";
import { RootState } from "../store/root-reducer";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(contactstyles, globalstyles);

interface PropsFromState extends WithStyles<typeof styles> {
  user: UserInfoItem;
  loading: boolean;
  documentList: DigitalDocument[];
  data: ContactItem[];
  errors: string;
}

interface PropsFromDispatch {
  downloadDocumentRequest: typeof downloadDocumentRequest;
  resetDownloadError: typeof resetDownloadError;
  getDocumentsRequest: typeof getDocumentsRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps<{ startDateIndex: string }>;

class DocumentsPage extends React.Component<AllProps> {
  componentDidMount() {
    this.props.resetDownloadError();
    const nameId = this.props.user.nameId;
    this.props.getDocumentsRequest(nameId);
    window.scrollTo(0, 0);
  }

  downloadDocument = (documentId: number, fileName: string) => {
    let nameId = this.props.user.nameId;

    var ua = navigator.userAgent; // /*@cc_on!@*/false || !!document.documentMode;
    var isIE = ua.indexOf("MSIE") > -1 || ua.indexOf("Trident/") > -1;
    let newWindow = null;
    if (!isIE) {
      newWindow = window.open();
    }

    this.props.downloadDocumentRequest({
      nameId: nameId,
      documentId: documentId,
      fileName: fileName,
      newWindow: newWindow
    });
  };

  getActivtiyList = (): DigitalDocument[] => {
    let documentList = this.props.documentList;

    documentList = documentList.sort(function(a, b) {
      return compareDates(a.uploadDate, b.uploadDate);
    });
    let soda = documentList.filter(a => a.description === "Statement of Deposit Account" || a.description === "SODA");

    if (soda.length > 1) {
      for (let i = 0; i < documentList.length; i++) {
        if (documentList[i].description.indexOf("(current)") > -1) {
          break;
        } else if (documentList[i].description === "Statement of Deposit Account") {
          documentList[i].description = "Statement of Deposit Account (current)";
          break;
        } else if (documentList[i].description === "SODA") {
          documentList[i].description = "SODA (current)";
          break;
        }
      }
    }

    return documentList;
  };

  getDocumentIcon = (activity: string): string => {
    if (activity.toLowerCase().indexOf("letter") !== -1) {
      return constants.MAIL;
    }

    return constants.DOC;
  };

  downloadSoda = () => {
    let nameId = this.props.user.nameId;
    let activityList = this.props.documentList;

    activityList = activityList.sort(function(a, b) {
      return compareDates(a.uploadDate, b.uploadDate);
    });

    var ua = navigator.userAgent; // /*@cc_on!@*/false || !!document.documentMode;
    var isIE = ua.indexOf("MSIE") > -1 || ua.indexOf("Trident/") > -1;
    let newWindow = null;
    if (!isIE) {
      newWindow = window.open();
    }

    for (let i = 0; i < activityList.length; i++) {
      if (activityList[i].description === "Statement of Deposit Account (current)") {
        this.props.downloadDocumentRequest({
          nameId: nameId,
          documentId: activityList[i].id,
          fileName: activityList[i].name,
          newWindow: newWindow
        });
        break;
      } else if (activityList[i].description === "SODA (current)") {
        this.props.downloadDocumentRequest({
          nameId: nameId,
          documentId: activityList[i].id,
          fileName: activityList[i].name,
          newWindow: newWindow
        });
        break;
      }
    }
  };

  public render() {
    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Typography variant="h1" className={this.props.classes.header}>
            Digital Docs
          </Typography>
          <DigitalDocumentTile
            documentList={this.props.documentList}
            downloadDocument={this.downloadDocument}
            getDocumentIcon={this.getDocumentIcon}
            classes={this.props.classes}
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ profile, documents, selectAccount }: RootState) => ({
  user: getActiveUserInfo(selectAccount),
  userInfo: profile.userInfo,
  documentList: documents.documentList,
  downloadError: documents.downloadError
});

const mapDispatchToProps: PropsFromDispatch = {
  getDocumentsRequest: (payload: string) => getDocumentsRequest(payload),
  downloadDocumentRequest: (payload: {
    nameId: string;
    documentId: number;
    fileName: string;
    newWindow: Window | null;
  }) => downloadDocumentRequest(payload),
  resetDownloadError: () => resetDownloadError()
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocumentsPage));
