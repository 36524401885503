import { Divider, Grid, Hidden, Link, List, Typography, WithStyles, withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import ArrowUp from "@material-ui/icons/ArrowDropUp";
import CommentIcon from "@material-ui/icons/Comment";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as Yup from "yup";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import FormikTextField from "../../shared/components/FormikTextField";
import UserAvatar from "../../userInfo/components/UserAvatar";
import { UserInfoItem } from "../../userInfo/models";
import { addCommentRequest } from "../actions";
import { addCommentGroupRequest } from "../actionsGroup";
import { GROUP_FEED } from "../constants";
import { AddCommentDto, DashboardFeedItem } from "../models";
import Comment from "./Comment";
import socialstyles from "./styles";

const styles = combinestyles(socialstyles, globalstyles);

const ValidationSchema = Yup.object().shape({
  comment: Yup.string().required("Please enter a comment"),
});
interface Props extends WithStyles<typeof styles> {
  item: DashboardFeedItem;
  feedType?: string;
  groupId?: number;
}

interface PropsFromState {
  user?: UserInfoItem;
}

interface PropsFromDispatch {
  addCommentRequest: typeof addCommentRequest;
  addCommentGroupRequest: typeof addCommentGroupRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

interface State {
  expanded: boolean;
  itemsToShow: number;
}

class CommentsContainer extends React.Component<AllProps, State> {
  state: Readonly<State> = { expanded: false, itemsToShow: 2 };

  handleSubmit = (values: any) => {
    const newComment: AddCommentDto = {
      authorNameId: this.props.user!.nameId,
      postId: this.props.item.id,
      message: values.comment,
      feedType: this.props.feedType,
      groupId: this.props.groupId
    };
    if (this.props.feedType === GROUP_FEED) {
      this.props.addCommentGroupRequest(newComment);
    } else {
      this.props.addCommentRequest(newComment);
    }
  };

  showMore = () => {
    let commentsLength = 0;
    if (this.props.item.comments !== undefined) {
      commentsLength = this.props.item.comments.length;
    }
    this.state.itemsToShow === 2
      ? this.setState({ itemsToShow: commentsLength, expanded: true })
      : this.setState({ itemsToShow: 2, expanded: false });
  };

  render() {
    const { item, classes } = this.props;

    if (item.comments) {
      return (
        <div>
          <Grid container direction="row">
            <Hidden xsDown>
              <Grid item className={classes.leftIconGutter} />
            </Hidden>
            <Grid item xs>
              <List>
                {item.comments.slice(0, this.state.itemsToShow).map((x) => (
                  <React.Fragment key={x.id}>
                    <Comment comment={x} groupId={item.groupId} />
                    <Divider variant="middle" />
                  </React.Fragment>
                ))}
              </List>
              {item.comments.length > 2 &&
                (this.state.expanded ? (
                  <Link
                    onClick={() => this.showMore()}
                    style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                  >
                    <Typography className={classes.morelink} style={{ display: "inline-block" }}>
                      Less Replies
                    </Typography>
                    <ArrowUp style={{ display: "inline-block" }} />
                  </Link>
                ) : (
                  <Link
                    onClick={() => this.showMore()}
                    style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                  >
                    <Typography className={classes.morelink}>More Replies</Typography>
                    <ArrowDown />
                  </Link>
                ))}
              <Grid container alignItems="center" alignContent="center" spacing={16}>
                <Grid item>
                  <UserAvatar />
                </Grid>
                <Grid item xs>
                  <Formik
                    initialValues={{
                      comment: ""
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={(values, { resetForm }) => {
                      this.handleSubmit(values);
                      resetForm();
                    }}
                    render={props => (
                      <Form
                        className={classes.commentForm}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && e.shiftKey === false && props.values.comment.trim() !== "") {
                            this.handleSubmit(props.values);
                            props.resetForm();
                            e.preventDefault();
                          }
                        }}
                      >
                        <Grid container alignItems="center">
                          <Grid item xs>
                            <Field
                              component={FormikTextField}
                              name="comment"
                              placeholder="Join the discussion"
                              margin="normal"
                              fullWidth
                              variant="outlined"
                              style={{ width: "100%" }}
                              multiline
                            />
                          </Grid>
                          <Grid item>
                            <IconButton aria-label="Comments" type="submit">
                              <CommentIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount)
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  addCommentRequest: (payload: AddCommentDto) => dispatch(addCommentRequest(payload)),
  addCommentGroupRequest: (payload: AddCommentDto) => dispatch(addCommentGroupRequest(payload))
});

export default connect(
  mapStateToProps, 
  mapDispatchToProps)(withStyles(styles)(CommentsContainer));
