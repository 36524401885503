import * as React from "react";
import { fetchAsyncAction } from "../features/docusignlease/actions";
import { NewResidentMoveInDocuSignData } from "../features/docusignlease/models";
import { RootState } from "../store/root-reducer";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Typography, withStyles, WithStyles, Dialog, DialogContent, CircularProgress } from "@material-ui/core";
import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import statementstyles from "../features/docusignlease/components/styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(statementstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {}

interface PropsFromState {
  user?: UserInfoItem;
  loading: boolean;
  data: NewResidentMoveInDocuSignData;
  errors: string;
}

interface PropsFromDispatch {
  fetchRequest: typeof fetchAsyncAction.request;
}

type AllProps = Props & PropsFromState & PropsFromDispatch & RouteComponentProps<{ startDateIndex: string }>;

class DocuSignLease extends React.Component<AllProps> {
  public componentDidMount() {
    this.props.fetchRequest(this.props.user!.nameId);
  }

  displayDocuSignLease = () => {
    const docSignString = this.props.data.docuSignURL;
    window.open(docSignString, "_blank");
    this.props.history.push("/moveinchecklist");
  };

  public render() {
    if (!this.props.loading && this.props.data) {
      this.displayDocuSignLease();
    }

    return (
      <div className={this.props.classes.root}>
        <Dialog open={this.props.loading}>
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog>
        <main className={this.props.classes.contentRightRail}>
          <Typography variant="h1" className={this.props.classes.header}>
            Your Lease
          </Typography>
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ docuSignLease, selectAccount }: RootState) => ({
  user: getActiveUserInfo(selectAccount),
  loading: docuSignLease.loading,
  errors: docuSignLease.errors,
  data: docuSignLease.data
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRequest: (payload: string) => dispatch(fetchAsyncAction.request(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocuSignLease));
