import { Grid, List, ListItem, ListItemText, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { propertyDocsRequest } from "../actions";
import { PropertyDocumentsItems } from "../models";
import propertydocumentsstyles from "../styles";

const styles = combinestyles(propertydocumentsstyles, globalstyles);

export interface PropsFromState {
  user?: UserInfoItem;
  propertyDocumentsList: PropertyDocumentsItems[];
}

interface PropsFromDispatch {
  propertyDocsRequest: typeof propertyDocsRequest;
}

interface PropertyDocumentsListProps extends WithStyles<typeof styles> {}

type AllProps = PropsFromState & PropsFromDispatch & PropertyDocumentsListProps;

class PropertyDocList extends React.Component<AllProps> {
  renderItem(propertydocuments: PropertyDocumentsItems) {
    return (
      <ListItem key={propertydocuments.id} divider disableGutters>
        <Grid container direction="row" alignItems="center" justify="flex-start" spacing={8}>
          <Grid item>
            <a href={propertydocuments.url} target="_new" style={{ textDecoration: "none" }}>
              <img
                alt="Property Info"
                className="step-icon"
                src="https://media.equityapartments.com/image/upload/co_rgb:394b76,e_colorize:100,f_auto/v1544721640/Content/portal/myaccount/propertyinfo.svg"
              />
            </a>
          </Grid>
          <Grid item>
            <a href={propertydocuments.url} target="_new" style={{ textDecoration: "none" }}>
              <ListItemText primary={propertydocuments.name} />
            </a>
          </Grid>
          <Grid item>
            <Typography>{propertydocuments.description}</Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  }

  render() {
    const { propertyDocumentsList } = this.props;
    this.renderItem = this.renderItem.bind(this);
    return (
      <Paper className={this.props.classes.paper}>
        <List>{propertyDocumentsList.map(this.renderItem)}</List>
      </Paper>
    );
  }
}

const mapStateToProps = ({ propertydocuments, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  propertyDocumentsList: propertydocuments.propertyDocumentsList.data,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  propertyDocsRequest: (propid: string) => dispatch(propertyDocsRequest(propid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PropertyDocList));
