import { Button, Dialog, DialogActions, DialogContent, Typography, WithStyles, withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Field, Form,  Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as Yup from "yup";

import contactstyles from "../../../features/myprofile/components/styles";
import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import DialogHeader from "../../shared/components/DialogHeader";
import FormikInputError from "../../shared/components/FormikInputError";
import FormikTextField from "../../shared/components/FormikTextField";
import RegexConstants from "../../shared/constants/regex";
import { UserInfoItem } from "../../userInfo/models";
import { vehiclesAddRequest, vehiclesDeleteRequest, vehiclesUpdateRequest } from "../actions";
import { RpActivity, VehicleInfo } from "../models";
import UsaStateDropDown from "./UsaStateDropDown";

const styles = combinestyles(contactstyles, globalstyles);

const ValidationSchema = Yup.object().shape({
  make: Yup.string().required("Make is required").max(15, "15 character max"),
  model: Yup.string().required("Model is required").max(15, "15 character max"),
  year: Yup.string()
    .required("Year is required")
    .matches(RegexConstants.YEAR, "Please enter a 4-digit year")
    .length(4, "Please enter a 4-digit year"),
  state: Yup.string().required("State is required"),
  license: Yup.string().required("License is required").max(15, "15 character max")
});
interface Props extends WithStyles<typeof styles> {
  modalTitle: string;
  editingExistingVehicle: boolean;
  vehicleInfo?: VehicleInfo;
}
interface PropsFromState {
  user?: UserInfoItem;
}
interface PropsFromDispatch {
  logActivity: typeof logActivity;
  vehiclesAddRequest: typeof vehiclesAddRequest;
  vehiclesDeleteRequest: typeof vehiclesDeleteRequest;
  vehiclesUpdateRequest: typeof vehiclesUpdateRequest;
}

type MyState = {
  modalVisibility: boolean;
  currentState: string;
};

type AllProps = PropsFromDispatch & PropsFromState & Props;
class ProfileVehicleModal extends Component<AllProps> {
  state: Readonly<MyState> = {
    currentState:
      this.props.vehicleInfo !== null && this.props.vehicleInfo !== undefined ? this.props.vehicleInfo.state : "",
    modalVisibility: false
  };

  handleChange = (event: any) => {
    this.setState({ currentState: event.target.value });
  };
  handleOpenModal = () => {
    this.setState({ modalVisibility: true });
  };
  handleCloseModal = () => {
    this.setState({ modalVisibility: false });
  };
  handleSubmit = (values: any) => {
    const newVehicle: VehicleInfo = {
      nameId: this.props.user!.nameId,
      make: values.make,
      model: values.model,
      year: values.year,
      state: values.state,
      license: values.license
    };
    if (this.props.editingExistingVehicle) {
      if (this.props.vehicleInfo) {
        this.props.logActivity({
          actcode: "VU",
          note: "Resident updated a vehicle"
        });
        this.props.vehiclesUpdateRequest({
          originalLicense: this.props.vehicleInfo.license,
          originalState: this.props.vehicleInfo.state,
          updatedVehicle: newVehicle
        });
      }
    } else {
      this.props.logActivity({
        actcode: "VA",
        note: "Resident added a vehicle"
      });
      this.props.vehiclesAddRequest(newVehicle);
    }

    this.setState({ modalVisibility: false });
  };
  handleDeleteVehicle = () => {
    if (this.props.vehicleInfo) {
      this.props.logActivity({
        actcode: "VD",
        note: "Resident deleted a vehicle"
      });
      this.props.vehiclesDeleteRequest({
        nameId: this.props.user!.nameId,
        license: this.props.vehicleInfo.license,
        state: this.props.vehicleInfo.state
      });
    }
    this.setState({ modalVisibility: false });
  };

  render() {
    const { vehicleInfo } = this.props;
    const openModalButton = this.props.editingExistingVehicle ? (
      <Button onClick={this.handleOpenModal} color="primary">
        <EditIcon />
        Edit
      </Button>
    ) : (
      <Button fullWidth variant="contained" color="primary" onClick={this.handleOpenModal}>
        <AddIcon />
        Add New
      </Button>
    );
    const initialValues = vehicleInfo
      ? {
          make: vehicleInfo.make,
          model: vehicleInfo.model,
          year: vehicleInfo.year,
          state: vehicleInfo.state,
          license: vehicleInfo.license
        }
      : {
          make: "",
          model: "",
          year: "",
          state: "",
          license: ""
        };
    return (
      <React.Fragment>
        {openModalButton}
        <Dialog open={this.state.modalVisibility} onClose={this.handleCloseModal}>
          <DialogHeader title={this.props.modalTitle} handleClose={this.handleCloseModal} />
          <hr />
          <DialogContent>
            <Formik
              initialValues={initialValues}
              validationSchema={ValidationSchema}
              onSubmit={(values) => {
                this.handleSubmit(values);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <Field
                    name="make"
                    label="Make*"
                    inputProps={{ maxLength: 15 }}
                    component={FormikTextField}
                    style={{ marginBottom: "1rem" }}
                    fullWidth
                  />
                  <Field
                    name="model"
                    label="Model*"
                    inputProps={{ maxLength: 15 }}
                    component={FormikTextField}
                    style={{ marginBottom: "1rem" }}
                    fullWidth
                  />
                  <Field
                    name="year"
                    label="Year*"
                    inputProps={{ maxLength: 4 }}
                    component={FormikTextField}
                    style={{ marginBottom: "2rem" }}
                    fullWidth
                  />
                  <UsaStateDropDown id="state" value={this.state.currentState} onChange={this.handleChange} />
                  <FormikInputError>
                    {errors.state && touched.state ? <div>{errors.state}</div> : null}
                  </FormikInputError>
                  <Field
                    name="license"
                    label="Plate Number*"
                    inputProps={{ maxLength: 15 }}
                    component={FormikTextField}
                    style={{ marginBottom: "1rem" }}
                    fullWidth
                  />
                  {this.props.editingExistingVehicle && (
                    <DialogActions>
                      <Button onClick={this.handleDeleteVehicle}>
                        <Typography>
                          <DeleteIcon />
                          Delete
                        </Typography>
                      </Button>
                    </DialogActions>
                  )}
                  <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ profile, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount)
});
const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  logActivity: (activity: RpActivity) => dispatch(logActivity(activity)),
  vehiclesAddRequest: (payload: VehicleInfo) => dispatch(vehiclesAddRequest(payload)),
  vehiclesDeleteRequest: (payload: { nameId: string; license: string; state: string }) => dispatch(vehiclesDeleteRequest(payload)),
  vehiclesUpdateRequest: (payload: { originalLicense: string; originalState: string; updatedVehicle: VehicleInfo }) => dispatch(vehiclesUpdateRequest(payload)),
});

export default connect(
  mapStateToProps, 
  mapDispatchToProps)(withStyles(styles)(ProfileVehicleModal));
