import * as React from "react";
import { contactUsRequest, fetchRequest } from "../features/contact/actions";
import { ContactItem, ContactUsItem } from "../features/contact/models";
import { RootState } from "../store/root-reducer";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Typography, withStyles, WithStyles } from "@material-ui/core";
import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import ContactYourContacts from "../features/contact/components/ContactYourContacts";
import ContactAllteamMembers from "../features/contact/components/ContactAllTeamMembers";
import ContactResidentRelations from "../features/contact/components/ContactResidentRelations";

import contactstyles from "../features/contact/components/styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";
import ContactUsList from "../features/contact/components/ContactUsList";

const styles = combinestyles(contactstyles, globalstyles);

interface PropsFromState extends WithStyles<typeof styles> {
  user?: UserInfoItem;
  loading: boolean;
  data: ContactItem[];
  contactUsItems: ContactUsItem[];
  errors: string;
}

interface PropsFromDispatch {
  fetchRequest: typeof fetchRequest;
  contactUsRequest: typeof contactUsRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps<{ startDateIndex: string }>;

class Contact extends React.Component<AllProps> {
  public componentDidMount() {
    const user = this.props.user;
    const rmpropId = user!.propertyId;
    const nameId = user!.nameId;
    const payload = { rmpropId, nameId };

    this.props.fetchRequest(payload);
    this.props.contactUsRequest({ nameId: nameId });

    window.scrollTo(0, 0);
  }

  public render() {
    const { data, contactUsItems } = this.props;
    const userStatus = this.props.user!.status;

    const yourContactData = data.filter((contact) => contact.groupId === 1 || contact.groupId === 2);
    const leasingTeamData = data.filter((contact) => contact.groupId === 3);
    const serviceTeamData = data.filter((contact) => contact.groupId === 4);

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Typography variant="h1" className={this.props.classes.header}>
            Get Help
          </Typography>
          <ContactUsList items={contactUsItems} />
          {yourContactData.length > 0 && userStatus !== "A" ? (
            <ContactYourContacts yourContact={yourContactData} />
          ) : null}
          {(leasingTeamData.length > 0 || serviceTeamData.length > 0) && userStatus !== "A" ? (
            <ContactAllteamMembers leasingTeam={leasingTeamData} serviceTeam={serviceTeamData} />
          ) : null}
          {userStatus !== "A" && <ContactResidentRelations />}
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ contact, selectAccount }: RootState) => ({
  user: getActiveUserInfo(selectAccount),
  loading: contact.loading,
  errors: contact.errors,
  data: contact.data,
  contactUsItems: contact.contactUsItems,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRequest: (payload: { rmpropId: string; nameId: string }) => dispatch(fetchRequest(payload)),
  contactUsRequest: (payload: { nameId: string }) => dispatch(contactUsRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Contact));
