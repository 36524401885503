import { Button, Grid, TextField, Typography } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import EventDateSelect from "./EventDateSelect";
import { FormikProps } from "formik";
import * as React from "react";
import { EventFormInputState } from "./NewEvent";
import styles from "../styles";
import FileUploader from "../common/FileUploader";
import GroupSelector from "../common/GroupSelector";
import { Group } from "../../models";

interface MyProps {
  getFilesToUpload: any;
  handleGroupSelection: (value: string) => void;
  groups: Group[];
}

type AllProps = MyProps & WithStyles<typeof styles> & FormikProps<EventFormInputState>;

const EventForm = (props: AllProps) => {
  const {
    values: { title, content, location },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    classes,
    getFilesToUpload
  } = props;

  const change = (field: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(field, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="row" spacing={24}>
        <Grid item xs={12}>
          <Typography style={{ float: "right" }}>
            <small>*Required fields</small>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField
            id="title"
            name="title"
            label="Title*"
            value={title}
            helperText={touched.title ? errors.title : ""}
            error={touched.title && Boolean(errors.title)}
            onChange={change.bind(null, "title")}
            fullWidth={true}
            className={classes.textField}
            inputProps={{
              maxLength: 250
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            id="location"
            name="location"
            label="Location*"
            value={location}
            helperText={touched.location ? errors.location : ""}
            error={touched.location && Boolean(errors.location)}
            onChange={change.bind(null, "location")}
            className={classes.textField}
            fullWidth
            inputProps={{
              maxLength: 200
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="content"
            name="content"
            label="Content*"
            value={content}
            helperText={touched.content ? errors.content : ""}
            error={touched.content && Boolean(errors.content)}
            fullWidth
            onChange={change.bind(null, "content")}
            className={classes.textField}
            multiline
          />
        </Grid>
        <EventDateSelect {...props} />
        <Grid item xs={12}>
          <FileUploader getFilesToUpload={getFilesToUpload} />
        </Grid>
        <Grid item xs={12}>
          <GroupSelector handleGroupSelection={props.handleGroupSelection} groups={props.groups} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button type="submit" size="large" color="primary" variant="contained" disabled={!isValid}>
            Post
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default withStyles(styles)(EventForm);
