import { Theme, createStyles } from "@material-ui/core";

const styles = ({ spacing, breakpoints, mixins, transitions }: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginBottom: 24
      //paddingTop: spacing.unit * 7
    },
    tableRow: {
      backgroundColor: "lightgrey"
    },
    textField: {
      marginLeft: spacing.unit,
      marginRight: spacing.unit
    },
    lockedTile: {
      backgroundColor: "lightgrey",
      pointerEvents: "none",
      touchAction: "none"
    }
  });
export default styles;
