import * as React from "react";

import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { InjectedProps } from "@material-ui/core/withMobileDialog";

interface Props {
  onContinue: any;
  onCancel: any;
  officePhone: string;
  open: boolean;
}
type AllProps = Props & InjectedProps;

class ServiceReqFormEmergency extends React.Component<AllProps> {
  render() {
    const { fullScreen, open, officePhone } = this.props;

    return (
      <Dialog fullScreen={fullScreen} open={open}>
        <DialogTitle>Is this an emergency?</DialogTitle>
        <DialogContent>
          <Typography>
            Service requests submitted here may not be received for up to 24 hours or on weekends.
          </Typography>
        </DialogContent>
        <DialogContent>
          <Typography>This request sometimes qualifies as a service emergency.</Typography>
          <Typography>If it is, please call the leasing office directly.</Typography>
          <Typography>{officePhone}</Typography>
          <Typography>Please continue with the request if this is not an emergency</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="default" variant="contained" autoFocus>
            Cancel, this is an emergency.
          </Button>
          <Button onClick={this.props.onContinue.bind(this, this.state)} color="primary" variant="contained" autoFocus>
            Continue with request
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ServiceReqFormEmergency;
