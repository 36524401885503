import { createAction } from "typesafe-actions";

import { Statement, MaxStatements } from "./models";

const STATEMENT_FETCH_REQUEST = "statement/FETCH_REQUEST";
const STATEMENT_FETCH_SUCCESS = "statement/FETCH_SUCCESS";
const STATEMENT_FETCH_ERROR = "statement/FETCH_ERROR";

const MAXSTATEMENT_FETCH_REQUEST = "maxstatement/FETCH_REQUEST";
const MAXSTATEMENT_FETCH_SUCCESS = "maxstatement/FETCH_SUCCESS";
const MAXSTATEMENT_FETCH_ERROR = "maxstatement/FETCH_ERROR";

export const statementFetchRequest = createAction(STATEMENT_FETCH_REQUEST)<{
  nameId: string;
  numStatements: number;
}>();
export const statementFetchSuccess = createAction(STATEMENT_FETCH_SUCCESS)<{ data: Statement[] }>();
export const statementFetchError = createAction(STATEMENT_FETCH_ERROR)<string>();

export const maxStatementFetchRequest = createAction(MAXSTATEMENT_FETCH_REQUEST)<{ nameId: string }>();
export const maxStatementFetchSuccess = createAction(MAXSTATEMENT_FETCH_SUCCESS)<{ data: MaxStatements[] }>();
export const maxStatementFetchError = createAction(MAXSTATEMENT_FETCH_ERROR)<string>();

const LOAD_MORE_STATEMENTS = "statement/LOAD_MORE_STATEMENTS";
export const loadMoreStatements = createAction(LOAD_MORE_STATEMENTS)();
