import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  accountActivitySuccess,
  accountActivityError,
  downloadDocumentError,
  downloadDocumentSuccess,
  paymentPlanError
} from "./actions";
import { getType } from "typesafe-actions";
import * as actions from "./actions";
import { fetchAccountActivity, downloadDocument, requestPaymentPlan } from "../../utils/api";
import * as utils from "../shared/utils/utils";

import { showModalAction } from "../errorhandling/actions";

function* handleFetchLeaseDetails(action: ReturnType<typeof actions.accountActivityRequest>) {
  try {
    const nameId = action.payload;

    const response = yield call(fetchAccountActivity, nameId);

    if (response.error) {
      yield put(accountActivityError(response.error));
    } else {
      yield put(accountActivitySuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(accountActivityError(err.stack!));
    } else {
      yield put(accountActivityError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleDocumentDownload(action: ReturnType<typeof actions.downloadDocumentRequest>) {
  try {
    const payload = action.payload;
    const response = yield call(downloadDocument, payload);

    if (response.error) {
      yield put(downloadDocumentError(response.error));
    } else {
      utils.openDownloadedDocument(response, payload.fileName, payload.newWindow);
      yield put(downloadDocumentSuccess());
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(downloadDocumentError(err.stack!));
    } else {
      yield put(downloadDocumentError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handlePaymentPlanRequest(action: ReturnType<typeof actions.paymentPlanRequest>) {
  try {
    const payload = action.payload;
    const response = yield call(requestPaymentPlan, payload);

    if (response.error) {
      yield put(actions.paymentPlanError(response.error));
    } else {
      yield put(actions.paymentPlanSuccess());
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(paymentPlanError(err.stack!));
    } else {
      yield put(paymentPlanError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(getType(actions.accountActivityRequest), handleFetchLeaseDetails);
  yield takeEvery(getType(actions.downloadDocumentRequest), handleDocumentDownload);
  yield takeEvery(getType(actions.paymentPlanRequest), handlePaymentPlanRequest);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* formerResidentSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default formerResidentSaga;
