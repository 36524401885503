import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../store/root-reducer";
import { LinearProgress, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import residentstyle from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { MoveInChecklistModel } from "../models";
import { fetchRequestAsync, markCompleteRequest } from "../actions";
import classNames from "classnames";
import MoveInChecklistRow from "./MoveInChecklistRow";
import { History, Location } from "history";
import { RouteComponentProps } from "react-router";

const styles = combinestyles(residentstyle, globalstyles);

interface Props extends WithStyles<typeof styles> {
  history: History;
  location: Location;
}

interface PropsFromState {
  user?: UserInfoItem;
  moveinChecklist?: MoveInChecklistModel;
  loading: boolean;
}

interface PropsFromDispatch {
  fetchRequest: typeof fetchRequestAsync.request;
  markComplete: typeof markCompleteRequest;
}

type MyState = {
  scrollY: number;
};

type AllProps = PropsFromState & PropsFromDispatch & Props & RouteComponentProps;

class MoveInChecklist extends React.Component<AllProps, MyState> {
  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.fetchRequest(nameId);
  }
  state: Readonly<MyState> = {
    scrollY: 0,
  };

  async markComplete(itemType: string) {
    var payload = {
      nameId: this.props.user!.nameId,
      checkListType: itemType,
    };

    this.props.markComplete(payload);
    this.setState({ scrollY: window.scrollY });
  }

  componentDidUpdate() {
    if (!this.props.loading) {
      window.scrollTo({
        top: this.state.scrollY,
        behavior: "auto",
      });
    }
  }

  render() {
    if (this.props.loading || !this.props.moveinChecklist) {
      return null;
    }
    const checklist = this.props.moveinChecklist;

    const { classes } = this.props;

    const leaseDate = new Date(checklist.leaseStartDate);

    const propEmail = this.props.user!.propertyInfo.propertyEmail;

    const mailLink = "mailto:" + propEmail;

    return (
      <Paper className={classNames(this.props.classes.paper)}>
        <Typography variant="h2">Move-in Checklist</Typography>

        <Typography gutterBottom style={{ marginTop: "0.5rem" }}>
          Complete each step below before {leaseDate.toLocaleDateString()}. Feel free to reach out to us at{" "}
          <a href={mailLink} target="_blank" rel="noreferrer">
            {propEmail}
          </a>{" "}
          with any questions.
        </Typography>
        <LinearProgress
          color="primary"
          classes={{
            colorPrimary: classes.linearColorPrimary,
            barColorPrimary: classes.linearBarColorPrimary,
          }}
          style={{ margin: "1rem 0" }}
          variant="determinate"
          value={(checklist.completedSteps / checklist.allSteps) * 100}
        />
        <Typography variant="subtitle1">
          {checklist.completedSteps}/{checklist.allSteps} Complete - {checklist.progressBarText}
        </Typography>
        <div style={{ marginTop: "2rem" }}>
          {checklist.overrideFlag === "N" && (
            <MoveInChecklistRow
              {...this.props}
              itemType="signlease"
              title={checklist.signYourLeaseTitle}
              description={checklist.signYourLeaseDesc}
              disclaimer={checklist.disclaimer}
              buttonText={checklist.signYourLeaseButton}
              complete={checklist.leaseSignedHousehold === "Y"}
              showMarkComplete={false}
              documentUpload={false}
              documentUploadCTA=""
              disabled={checklist.leaseSignedResident === "Y"}
              leaseDate={leaseDate}
              path={checklist.urlToOpenLease}
              chargesSummary={checklist.newResidentMoveInChargesLists}
            />
          )}
          {checklist.newResidentMoveInCheckLists.map((x) => (
            <MoveInChecklistRow
              {...this.props}
              key={x.type}
              itemType={x.type}
              title={x.title}
              description={x.description}
              disclaimer={x.disclaimer}
              buttonText={x.buttonText}
              complete={x.complete === "Y"}
              showMarkComplete={x.showMarkComplete === "Y"}
              markComplete={x.showMarkComplete === "Y" ? this.markComplete.bind(this) : undefined}
              disabled={checklist.overrideFlag === "N" ? checklist.leaseSignedResident !== "Y" : false}
              documentUpload={x.documentUpload === "Y"}
              documentUploadCTA={x.documentUploadCTA}
              path={x.buttonLink}
              leaseDate={leaseDate}
              chargesSummary={checklist.newResidentMoveInChargesLists}
            />
          ))}
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = ({ selectAccount, newresident }: RootState): PropsFromState => ({
  loading: selectAccount.loading,
  user: getActiveUserInfo(selectAccount),
  moveinChecklist: newresident.checkList,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  fetchRequest: (nameid: string) => dispatch(fetchRequestAsync.request(nameid)),
  markComplete: (payload: { nameId: string; checkListType: string }) => dispatch(markCompleteRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MoveInChecklist));
