import { Typography, WithStyles, withStyles } from "@material-ui/core";
import React, { Component } from "react";

import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import serviceproviderstyles from "./styles";

const styles = combinestyles(serviceproviderstyles, globalstyles);

const boldText = {
  fontWeight: "bold" as "bold"
};

interface Props extends WithStyles<typeof styles> {
  phone: string;
  website: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
}

class SvcProviderTileContact extends Component<Props> {
  render() {
    const { contactName, contactEmail, contactPhone, phone, website } = this.props;

    return (
      <div>
        <Typography style={{ marginBottom: "1rem" }}>
          <td style={boldText}>{contactName}</td>
          <div>{contactEmail}</div>
          <div>{contactPhone}</div>
          {phone} {website !== "" && phone !== "" && "|"}{" "}
          {website !== "" && (
            <a href={website} target="_new" className={this.props.classes.link}>
              Visit Website
            </a>
          )}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(SvcProviderTileContact);
