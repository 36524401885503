import { combineReducers } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { ReferFriendAmount, NewReferFriend } from "./models";
import * as referfriend from "./actions";

export type ReferFriendAction = ActionType<typeof referfriend>;

export type ReferFriendState = Readonly<{
  referFriendAmountData: referFriendAmountDataState;
  referFriendNew: referFriendNewState;
}>;

export type referFriendAmountDataState = Readonly<{
  loading: boolean;
  data: ReferFriendAmount | undefined;
  errors?: string;
}>;

const initialreferFriendAmountDataState: referFriendAmountDataState = {
  data: undefined,
  errors: undefined,
  loading: false,
};

export type referFriendNewState = Readonly<{
  loading: boolean;
  referFriendNew?: NewReferFriend;
  errors?: string;
}>;

const initialInsertAmenityState: referFriendNewState = {
  referFriendNew: undefined,
  errors: undefined,
  loading: false,
};

// tslint:disable-next-line:no-unused-expression

export default combineReducers<ReferFriendState, ReferFriendAction>({
  referFriendAmountData: (state = initialreferFriendAmountDataState, action) => {
    switch (action.type) {
      case getType(referfriend.referAmountFetchRequest): {
        return { ...state, loading: true };
      }
      case getType(referfriend.referAmountFetchSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(referfriend.referAmountFetchError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  referFriendNew: (state = initialInsertAmenityState, action) => {
    switch (action.type) {
      case getType(referfriend.requestReferFriendRequest): {
        return { ...state, loading: true };
      }
      case getType(referfriend.requestReferFriendSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(referfriend.requestReferFriendError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
});
