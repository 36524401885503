import * as React from "react";
import { connect } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import { getActiveUserInfo } from "../features/selectAccount/selector";
import { categoriesFetchRequest, listFetchRequest, selectTopLevelCategory } from "../features/servicereqs/actions";
import ServiceReqDashboard from "../features/servicereqs/components/servicereq-dashboard";
import ServiceReqForm from "../features/servicereqs/components/servicereq-form";
import { ServiceRequest, ServiceRequestTopLevelCategory } from "../features/servicereqs/models";
import { UserInfoItem } from "../features/userInfo/models";
import { RootState } from "../store/root-reducer";

//const classNames from 'classnames';
interface IServiceReqProps {
  theme?: any;
  children?: any;
}

interface IPageState {}

interface PropsFromState {
  user?: UserInfoItem;
  topLevelCategories: ServiceRequestTopLevelCategory[];
  serviceRequests: ServiceRequest[];
  //loading: boolean,
  //errors: string,
}

interface PropsFromDispatch {
  listFetchRequest: typeof listFetchRequest;
  categoriesFetchRequest: typeof categoriesFetchRequest;
  selectTopLevelCategory: typeof selectTopLevelCategory;
}

type AllProps = IServiceReqProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

class ServiceReqPage extends React.Component<AllProps, IPageState> {
  state: IPageState = {};

  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.categoriesFetchRequest();
    this.props.listFetchRequest(nameId);
    window.scrollTo(0, 0);
  }

  handleSelectCategory = (topLevelCategory: ServiceRequestTopLevelCategory) => {
    this.props.selectTopLevelCategory(topLevelCategory);
    this.props.history.push("/servicereqs/cat");
  };

  render() {
    const params = new URLSearchParams(this.props.location.search);
    const confirmMessage = params.get("confirmMessage") === null ? "" : params.get("confirmMessage");

    return (
      // <div className={this.props.classes.root}>
      //   <main className={this.props.classes.contentRightRail}>
      // <Typography variant="body2">{confirMessage}</Typography>
      // </main>
      // </div>

      <Switch>
        <Route path="/servicereqs/new" component={ServiceReqForm} />

        <Route
          path="/servicereqs"
          render={props => <ServiceReqDashboard {...props} confirmMessageDisplay={confirmMessage} />}
        />

        {/* <Route component={ServiceReqDashboard} /> */}
      </Switch>
    );
  }
}

const mapStateToProps = ({ servicereqs, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  topLevelCategories: servicereqs.serviceRequestCategories.hierarchicalData,
  serviceRequests: servicereqs.serviceRequestList.data
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  categoriesFetchRequest: () => dispatch(categoriesFetchRequest()),
  listFetchRequest: (nameId: string) => dispatch(listFetchRequest(nameId)),
  selectTopLevelCategory: (category: ServiceRequestTopLevelCategory) => dispatch(selectTopLevelCategory(category))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceReqPage));
