import { call, fork, all, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { getApplicantCheckList } from "../../utils/api";
import { showModalAction } from "../errorhandling/actions";
import * as actions from "./actions";

function* handleFetchApplicantChecklist(action: ReturnType<typeof actions.fetchRequestAsync.request>) {
  try {
    const nameId = action.payload;

    const response = yield call(getApplicantCheckList, nameId);

    if (response.error) {
      yield put(actions.fetchRequestAsync.failure(response.error));
    } else {
      yield put(actions.fetchRequestAsync.success(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.fetchRequestAsync.failure(err));
    } else {
      yield put(actions.fetchRequestAsync.failure(new Error("An unknown error occured.")));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(getType(actions.fetchRequestAsync.request), handleFetchApplicantChecklist);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* applicantChecklistSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default applicantChecklistSaga; // must be registered in ../../store/root-saga.ts
