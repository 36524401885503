import * as React from "react";

import { Typography, WithStyles, withStyles, Grid } from "@material-ui/core";

//import queryString from 'query-string';

import globalstyles from "../../../global-styles";
import paymentstyles from "../styles";
import combinestyles from "../../../utils/combinestyles";
import { RouteComponentProps } from "react-router";

const styles = combinestyles(paymentstyles, globalstyles);

interface PaymentProps extends WithStyles<typeof styles> {
  displayBalance: number | undefined;
}

type AllProps = PaymentProps & RouteComponentProps; // & PropsFromState;

class PaymentResult extends React.Component<AllProps> {
  render() {
    const params = new URLSearchParams(this.props.location.search);
    const callFromProvider = params.get("p");
    var vId = undefined;
    if (callFromProvider === "d") {
      vId = params.get("p2");
    }

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Grid container spacing={24}>
            <Grid item sm={6}>
              <Grid container alignItems="center" spacing={24}>
                <Grid item xs={12}>
                  <Typography variant="h1" className={this.props.classes.header}>
                    Make a Payment
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={24}>
            <Grid item sm={6}>
              <Grid container alignItems="center" spacing={24}>
                <Grid item xs={12}>
                  <img
                    alt="Alert"
                    className="header-icon"
                    src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/paymentoptions/alert.svg"
                  />
                  <Typography variant="body2">p: {callFromProvider}</Typography>
                  <Typography variant="body2">p2: {vId}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(PaymentResult);
