import { Avatar, Button, Card, CardContent, Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import ListAltIcon from "@material-ui/icons/ListAlt";
import * as React from "react";

import { DashboardNotification } from "../models";
import styles from "../../social/components/styles";
import { UserInfoItem } from "../../userInfo/models";
import { RootState } from "../../../store/root-reducer";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { connect } from "react-redux";

interface Props extends WithStyles<typeof styles> {
  item: DashboardNotification;
  feedType?: string;
  fromDashboard: boolean;
}

interface PropsFromState {
  user?: UserInfoItem;
}

type AllProps = Props & PropsFromState;

class NotificationComponent extends React.Component<AllProps> {
  render() {
    const { item, classes } = this.props;

    return (
      <div style={{ width: "100%" }}>
        {(() => {
          switch (item.notificationType) {
            case "NewResidentCheckList":
              return (
                this.props.user!.moveinCheckListActive === "Y" && (
                  <Card
                    style={{
                      width: "100%",
                      borderLeftWidth: "6px",
                      borderLeftStyle: "solid",
                      borderLeftColor: "#ec5715"
                    }}
                  >
                    <CardContent style={{ paddingBottom: "16px" }}>
                      <Grid container>
                        <Grid item xs={1} sm={1}>
                          <ListAltIcon />
                        </Grid>
                        <Grid item xs={11} sm={8}>
                          <Typography variant="h4" className={classes.WelcomeTileHeading}>
                            {item.title}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ paddingRight: "1rem" }}
                            className={classes.WelcomeTileBody}
                          >
                            {item.message}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            variant="contained"
                            color="primary"
                            href="/moveinchecklist"
                            style={{ float: "right" }}
                          >
                            View Checklist
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                )
              );
            case "OpenBalance":
              return (
                <Card
                  style={{
                    width: "100%",
                    borderLeftWidth: "6px",
                    borderLeftStyle: "solid",
                    borderLeftColor: "#ec5715"
                  }}
                >
                  <CardContent style={{ paddingBottom: "16px" }}>
                    <Grid container direction="row" alignItems="center" justify="space-between">
                      <Grid item>
                        <Grid container alignItems="center" spacing={16}>
                          <Grid item>
                            <Avatar
                              src="https://media.equityapartments.com/image/upload/v1516214930/Content/portal/alerts.png"
                              style={{ width: "2rem", backgroundColor: "#ec5715", height: "2rem" }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="h4"
                              gutterBottom
                              style={{ overflowWrap: "break-word", fontWeight: 300 }}
                            >
                              {item.title}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" color="primary" href="/payment" style={{ textAlign: "right" }}>
                          Make a Payment
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              );
            case "SurveyAvailable":
              return (
                <Card
                  style={{
                    width: "100%",
                    borderLeftWidth: "6px",
                    borderLeftStyle: "solid",
                    borderLeftColor: "#ec5715"
                  }}
                >
                  <CardContent style={{ paddingBottom: "16px" }}>
                    <Grid container direction="row" alignItems="center" justify="space-between">
                      <Grid item>
                        <Grid container alignItems="center" spacing={16}>
                          <Grid item>
                            <Avatar
                              src="https://media.equityapartments.com/image/upload/v1516214930/Content/portal/alerts.png"
                              style={{ width: "2rem", backgroundColor: "#ec5715", height: "2rem" }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="h4"
                              gutterBottom
                              style={{ overflowWrap: "break-word", fontWeight: 300 }}
                            >
                              {item.title}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" color="primary" onClick={() => window.open(item.surveyLink)}>
                          Take Survey
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              );
            case "RenewalAvailable":
              return (
                <Card
                  style={{
                    width: "100%",
                    borderLeftWidth: "6px",
                    borderLeftStyle: "solid",
                    borderLeftColor: "#ec5715"
                  }}
                >
                  <CardContent style={{ paddingBottom: "16px" }}>
                    <Grid container direction="row" alignItems="center" justify="space-between">
                      <Grid item>
                        <Grid container alignItems="center" spacing={16}>
                          <Grid item>
                            <Avatar
                              src="https://media.equityapartments.com/image/upload/v1516214930/Content/portal/alerts.png"
                              style={{ width: "2rem", backgroundColor: "#ec5715", height: "2rem" }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="h4"
                              gutterBottom
                              style={{ overflowWrap: "break-word", fontWeight: 300 }}
                            >
                              {item.title}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" color="primary" href="/renewal">
                          View
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              );
            case "eNTVAddressUpdate": {
              if (this.props.fromDashboard === false) {
                return null;
              }
              return (
                <Card
                  style={{
                    width: "100%",
                    borderLeftWidth: "6px",
                    borderLeftStyle: "solid",
                    borderLeftColor: "#ec5715"
                  }}
                >
                  <CardContent style={{ paddingBottom: "16px" }}>
                    <Grid container direction="row" alignItems="center" justify="space-between">
                      <Grid item>
                        <Grid container alignItems="center" spacing={16}>
                          <Grid item>
                            <Avatar
                              src="https://media.equityapartments.com/image/upload/co_rgb:ffffff,e_colorize:100,c_scale,w_50,bo_5px_solid_rgb:ec5715/v1605275973/Content/portal/uielements/mail.png"
                              style={{ width: "2rem", backgroundColor: "#ec5715", height: "2rem" }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="h4"
                              gutterBottom
                              style={{ overflowWrap: "break-word", fontWeight: 300 }}
                            >
                              {item.title}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" color="primary" href="/myaccount/entv/entvsummary">
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              );
            }
            case "eNTVSubmissionSummary": {
              if (this.props.fromDashboard === false) {
                return null;
              }
              return (
                <Card
                  style={{
                    width: "100%",
                    borderLeftWidth: "6px",
                    borderLeftStyle: "solid",
                    borderLeftColor: "#ec5715"
                  }}
                >
                  <CardContent style={{ paddingBottom: "16px" }}>
                    <Grid container direction="row" alignItems="center" justify="space-between">
                      <Grid item>
                        <Grid container alignItems="center" spacing={16}>
                          <Grid item>
                            <Avatar
                              src="https://media.equityapartments.com/image/upload/co_rgb:ffffff,e_colorize:100,c_scale,w_50,bo_5px_solid_rgb:ec5715/v1605275973/Content/portal/formerresident/move.png"
                              style={{ width: "2rem", backgroundColor: "#ec5715", height: "2rem" }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="h4"
                              gutterBottom
                              style={{ overflowWrap: "break-word", fontWeight: 300 }}
                            >
                              {item.title}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" color="primary" href="/myaccount/entv/entvsummary">
                          View
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              );
            }
            default:
              return null;
          }
        })()}
      </div>
    );
  }
}

const mapStateToProps = ({ selectAccount, notifications }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount)
  //loading: notifications.isLoadingNotifications
});

export default connect(mapStateToProps)(withStyles(styles)(NotificationComponent));
