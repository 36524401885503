import { createAction } from "typesafe-actions";

import { ContactItem, ContactUsItem, ResidentRelationDto } from "./models";

const FETCH_REQUEST = "contact/FETCH_REQUEST";
const FETCH_SUCCESS = "contact/FETCH_SUCCESS";
const FETCH_ERROR = "contact/FETCH_ERROR";

export const fetchRequest = createAction(FETCH_REQUEST)<{ rmpropId: string; nameId: string }>();
export const fetchSuccess = createAction(FETCH_SUCCESS)<{ data: ContactItem[] }>();
export const fetchError = createAction(FETCH_ERROR)<string>();

const RESIDENT_RELATIONS_POST_REQUEST = "contact/resident/POST_REQUEST";
const RESIDENT_RELATIONS_POST_SUCCESS = "contact/resident/POST_SUCCESS";
const RESIDENT_RELATIONS_POST_ERROR = "contact/resident/POST_ERROR";

export const residentRelationsPostRequest = createAction(RESIDENT_RELATIONS_POST_REQUEST)<ResidentRelationDto>();
export const residentRelationsPostSuccess = createAction(RESIDENT_RELATIONS_POST_SUCCESS)();
export const residentRelationsPostError = createAction(RESIDENT_RELATIONS_POST_ERROR)<string>();

const CONTACTUS_FETCH_REQUEST = "contactus/FETCH_REQUEST";
const CONTACTUS_FETCH_SUCCESS = "contactus/FETCH_SUCCESS";
const CONTACTUS_FETCH_ERROR = "contactus/FETCH_ERROR";

export const contactUsRequest = createAction(CONTACTUS_FETCH_REQUEST)<{ nameId: string }>();
export const contactUSSuccess = createAction(CONTACTUS_FETCH_SUCCESS)<{ contactUsItems: ContactUsItem[] }>();
export const contactUSError = createAction(CONTACTUS_FETCH_ERROR)<string>();
