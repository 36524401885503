import { Button, Grid, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as Yup from "yup";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import RegexConstants from "../../shared/constants/regex";
import { UserInfoItem } from "../../userInfo/models";
import { contactFetchRequest, contactUpdateRequest } from "../actions";
import { ContactInfo, RpActivity } from "../models";
import LabelledEditField from "./LabelledEditField";
import contactstyles from "./styles";

const styles = combinestyles(contactstyles, globalstyles);

const ValidationSchema = Yup.object().shape({
  contactEmail: Yup.string()
    .max(100, "100 character limit") 
    .required("Email is required") 
    .email("Email is not valid"), 
  contactWorkPhone: Yup.string()
    .length(10, "Please enter a 10-digit phone number, area code first")
    .matches(RegexConstants.PHONE, "Please enter a 10-digit phone number, area code first"),
  contactHomePhone: Yup.string()
    .length(10, "Please enter a 10-digit phone number, area code first")
    .matches(RegexConstants.PHONE, "Please enter a 10-digit phone number, area code first")
    .required("Home Phone is required"),
  contactCellPhone: Yup.string()
    .length(10, "Please enter a 10-digit phone number, area code first")
    .matches(RegexConstants.PHONE, "Please enter a 10-digit phone number, area code first")
});
interface Props extends WithStyles<typeof styles> {
  tileLocked: boolean;
  lockAllTiles(): void;
  unlockAllTiles(): void;
}
interface PropsFromState {
  user?: UserInfoItem;
  contactInfo?: ContactInfo;
}
interface PropsFromDispatch {
  logActivity: typeof logActivity;
  contactFetchRequest: typeof contactFetchRequest;
  contactUpdateRequest: typeof contactUpdateRequest;
}
type ContactTileState = {
  editingTile: boolean;
};
type AllProps = PropsFromState & PropsFromDispatch & Props;

class ProfileContactTile extends Component<AllProps> {
  state: Readonly<ContactTileState> = {
    editingTile: false
  };
  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.contactFetchRequest({ nameId });
  }

  handleEditTile = () => {
    this.props.lockAllTiles();
    this.setState({ editingTile: true });
  };

  handleSaveTile = (values: any) => {
    // Make Appropriate RPActivity Logs
    if (this.props.contactInfo) {
      if (values.contactEmail !== this.props.contactInfo.email) {
        this.props.logActivity({
          actcode: "CE",
          note: "Resident updated email",
          prevvalue: this.props.contactInfo.email,
          newvalue: values.contactEmail
        });
      }
      if (values.contactWorkPhone !== this.props.contactInfo.workPhone) {
        this.props.logActivity({
          actcode: "CW",
          note: "Resident updated work phone",
          prevvalue: this.props.contactInfo.workPhone,
          newvalue: values.contactWorkPhone
        });
      }
      if (values.contactHomePhone !== this.props.contactInfo.homePhone) {
        this.props.logActivity({
          actcode: "CH",
          note: "Resident updated home phone",
          prevvalue: this.props.contactInfo.homePhone,
          newvalue: values.contactHomePhone
        });
      }
      if (values.contactCellPhone !== this.props.contactInfo.cellPhone) {
        this.props.logActivity({
          actcode: "CC",
          note: "Resident updated cell phone",
          prevvalue: this.props.contactInfo.cellPhone,
          newvalue: values.contactCellPhone
        });
      }
      // Make API Call To PUT data
      const payload: ContactInfo = {
        nameId: this.props.user!.nameId,
        email: values.contactEmail,
        workPhone: values.contactWorkPhone,
        homePhone: values.contactHomePhone,
        cellPhone: values.contactCellPhone
      };
      this.props.contactUpdateRequest(payload);
    }
    this.props.unlockAllTiles();
    this.setState({ editingTile: false });
  };

  render() {
    const { contactInfo, tileLocked, classes } = this.props;
    const { editingTile } = this.state;

    const lockedCssClass = editingTile ? "" : tileLocked ? classes.lockedTile : "";
    if (contactInfo) {
      return (
        <Paper className={[classes.paper, lockedCssClass].join(" ")}>
          <Formik
            initialValues={{
              contactEmail: contactInfo.email,
              contactWorkPhone: contactInfo.workPhone,
              contactHomePhone: contactInfo.homePhone,
              contactCellPhone: contactInfo.cellPhone
            }}
            validationSchema={ValidationSchema}
            onSubmit={values => {
              this.handleSaveTile(values);
            }}
          >
            <Form>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                style={{ marginBottom: "1rem" }}
              >
                <Grid item>
                  <Typography variant="h3">My Contact Information</Typography>
                </Grid>
                <Grid item>
                  {!editingTile && (
                    <Button onClick={this.handleEditTile} color="primary">
                      <EditIcon />
                      Edit
                    </Button>
                  )}
                  {editingTile && (
                    <Button type="submit" color="primary">
                      <EditIcon />
                      Save
                    </Button>
                  )}
                </Grid>
              </Grid>
              <LabelledEditField
                id="contactEmail"
                label="Email*"
                value={contactInfo.email}
                editing={editingTile}
                maxLength="100"
              />
              <LabelledEditField
                id="contactWorkPhone"
                label="Office or Work Phone"
                value={contactInfo.workPhone}
                editing={editingTile}
                maxLength="10"
              />
              <LabelledEditField
                id="contactHomePhone"
                label="Home Phone*"
                value={contactInfo.homePhone}
                editing={editingTile}
                maxLength="10"
              />
              <LabelledEditField
                id="contactCellPhone"
                label="Cell Phone"
                value={contactInfo.cellPhone}
                editing={editingTile}
                maxLength="10"
              />
            </Form>
          </Formik>
        </Paper>
      );
    } else {
      return <React.Fragment />;
    }
  }
}

const mapStateToProps = ({ profile, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount), 
  contactInfo: profile.contactInfo
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  logActivity: (activity: RpActivity) => dispatch(logActivity(activity)),
  contactFetchRequest: (payload: { nameId: any }) => dispatch(contactFetchRequest(payload)),
  contactUpdateRequest: (payload: ContactInfo) => dispatch(contactUpdateRequest(payload))
});

export default connect(
  mapStateToProps, 
  mapDispatchToProps 
)(withStyles(styles)(ProfileContactTile)); 
