export interface Person {
  id: number;
  name: string;
  avatar: string;
  isEmployee: boolean;
}

export interface AddCommentDto {
  postId?: number;
  authorNameId: string;
  message: string;
  feedType?: string;
  groupId?: number;
}

export interface CreatePostDto {
  itemType: string;
  authorNameId: string;
  title: string;
  message: string;
  price?: string;
  location: string;
  date?: Date;
  eDate?: Date;
  rsvpEnabled: boolean;
  startDate: Date | null;
  endDate: Date | null;
  photos: File[];
  groupId: number | null;
  communityPost: boolean;
  guestsAllowed: boolean;
  maxAttendees: number | null;
  maxGuests: number | null;
}

export interface UpdatePostDto {
  itemType: string;
  authorNameId: string;
  title: string;
  message: string;
  price?: string;
  location: string;
  date?: Date;
  eDate?: Date;
  rsvpEnabled: boolean;
  startDate: Date | null;
  endDate: Date | null;
  photos: File[];
  photosToRemove: number[];
  groupId: number | null;
}

export interface DashboardFeedItem {
  id: number;
  title: string;
  author: Person;
  datePosted: Date;
  message: string;
  itemType: string;
  images: Image[];
  comments?: Comment[];
  groupId: number;
  groupName: string;
  buttonName: string;
  website: string;
}

export interface ForSaleItem extends DashboardFeedItem {
  price: string;
}

export interface NewsItem extends DashboardFeedItem {
  attachments: string[];
  pinned: boolean;
}

export interface EventItem extends DashboardFeedItem {
  location: string;
  date: Date;
  guestCount: number;
  guestList: RsvpItem[];
  startDate: Date;
  endDate: Date;
  rsvpCount: number;
  attending: boolean;
  rsvpEnabled: boolean;
  maxAttendees: number;
  maxGuests: number;
  guestsAllowed: boolean;
}

export interface MarketingItem extends DashboardFeedItem {
  attachments: string[];
  pinned: boolean;
}

export interface RsvpItem {
  person: Person;
  going: boolean;
  lastModified: Date;
}

export interface DiscussionItem extends DashboardFeedItem {}

export interface Comment {
  id: number;
  datePosted: Date;
  author: Person;
  message: string;
  isItalic: boolean;
}

export interface Group {
  id: number;
  rmPropId: string;
  name: string;
  description: string;
  status: string;
  memberCount: number;
  declineReason: null;
  dateCreated: string;
  lastPostDate: null | string;
}

export interface JoinLeaveGroupDto {
  userNameId: string;
  groupId: number;
}
export interface CreateGroupDto {
  creatorNameId: string;
  rmPropId: string;
  name: string;
  description: string;
}

export type RpActivity = {
  nameid?: string;
  actcode: string;
  note: string;
  prevvalue?: string;
  newvalue?: string;
  deviceid?: string;
  errortype?: string;
  errorlocation?: string;
  errortext?: string;
  iP_ADDRESS?: string;
  appsource?: string;
};

export interface ReportAbuseRequest {
  reporterNameId: string;
  offensiveCommentId?: number;
  offensivePostId?: number;
  offensiveGroupId?: number;
  description: string;
}

export interface Image {
  id: number;
  imageFile: string;
}

export interface PhotoGallaryItem {
  original: string;
  thumbnail: string;
}

export interface RsvpDto {
  nameId: string;
  eventId: number;
  groupId: number;
  totalAttendees: number;
}
export interface BlockUserDto {
  blockerNameId: string;
  blockedUserId: number;
  selectedGroupId: number;
}
export interface RsvpResponse {
  id: number;
  postId: number;
  userId: number;
  totalAttendees: number;
}

export interface PhotosToUpload {
  postId: number;
  files: FormData;
}

export enum FeedDataFilter {
  All = "all",
  ForSaleItem = "ForSaleItem",
  DiscussionItem = "DiscussionItem",
  NewsItem = "NewsItem",
  EventItem = "EventItem"

  // TODO groups
}

export interface UserInfo {
  socialUserId: number;
}
