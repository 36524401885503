import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";

import {
  ContactInfo,
  ContactEmergency,
  SpecialAssistance,
  TransUnionPaymentReporting,
  VehicleInfo,
  RentersInsurance,
  UserInfo
} from "./models";
import * as actions from "./actions";

export type ProfileAction = ActionType<typeof actions>;

export type ProfileState = Readonly<{
  loading: boolean;
  userInfo?: UserInfo;
  contactInfo?: ContactInfo;
  emergencyInfo?: ContactEmergency;
  specialAssistance?: SpecialAssistance;
  transunionSuppress?: TransUnionPaymentReporting;
  vehicles?: VehicleInfo[];
  insurance?: RentersInsurance;
  errors?: string;
  passwordError?: string;
}>;

const initialState: ProfileState = {
  userInfo: undefined,
  contactInfo: undefined,
  emergencyInfo: undefined,
  specialAssistance: undefined,
  transunionSuppress: undefined,
  vehicles: undefined,
  insurance: undefined,
  errors: undefined,
  passwordError: undefined,
  loading: false
};

const reducer: Reducer<ProfileState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.userInfoFetchRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.userInfoFetchSuccess): {
      return { ...state, loading: false, userInfo: action.payload };
    }
    case getType(actions.userInfoFetchError): {
      return { ...state, loading: false, errors: action.payload };
    }
    // Contact Information Tile
    case getType(actions.contactFetchRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.contactFetchSuccess): {
      return { ...state, loading: false, contactInfo: action.payload };
    }
    case getType(actions.contactFetchError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.contactUpdateRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.contactUpdateSuccess): {
      return { ...state, loading: false, contactInfo: action.payload };
    }
    case getType(actions.contactUpdateError): {
      return { ...state, loading: false, errors: action.payload };
    }
    // Contact Emergenct Tile
    case getType(actions.emergencyFetchRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.emergencyFetchSuccess): {
      return { ...state, loading: false, emergencyInfo: action.payload };
    }
    case getType(actions.emergencyFetchError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.emergencyUpdateRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.emergencyUpdateSuccess): {
      return { ...state, loading: false, emergencyInfo: action.payload };
    }
    case getType(actions.emergencyUpdateError): {
      return { ...state, loading: false, errors: action.payload };
    }
    // Special Assistance Tile
    case getType(actions.specialAssistanceFetchRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.specialAssistanceFetchSuccess): {
      return { ...state, loading: false, specialAssistance: action.payload };
    }
    case getType(actions.specialAssistanceFetchError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.specialAssistanceUpdateRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.specialAssistanceUpdateSuccess): {
      return { ...state, loading: false, specialAssistance: action.payload };
    }
    case getType(actions.specialAssistanceUpdateError): {
      return { ...state, loading: false, errors: action.payload };
    }

    // TransUnion Payment Reporting
    case getType(actions.transUnionSuppressFetchRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.transUnionSuppressFetchSuccess): {
      return { ...state, loading: false, transunionSuppress: action.payload };
    }
    case getType(actions.transUnionSuppressFetchError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.transUnionSuppressUpdateRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.transUnionSuppressUpdateSuccess): {
      return { ...state, loading: false, transunionSuppress: action.payload };
    }
    case getType(actions.transUnionSuppressUpdateError): {
      return { ...state, loading: false, errors: action.payload };
    }

    // Vehicles Tile
    case getType(actions.vehiclesFetchRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.vehiclesFetchSuccess): {
      return { ...state, loading: false, vehicles: action.payload };
    }
    case getType(actions.vehiclesFetchError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.vehiclesAddRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.vehiclesAddSuccess): {
      return { ...state, loading: false, vehicles: action.payload };
    }
    case getType(actions.vehiclesAddError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.vehiclesDeleteRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.vehiclesDeleteSuccess): {
      return { ...state, loading: false, vehicles: action.payload };
    }
    case getType(actions.vehiclesDeleteError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.vehiclesUpdateRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.vehiclesUpdateSuccess): {
      return { ...state, loading: false, vehicles: action.payload };
    }
    case getType(actions.vehiclesUpdateError): {
      return { ...state, loading: false, errors: action.payload };
    }
    // Insurance Tile
    case getType(actions.insuranceFetchRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.insuranceFetchSuccess): {
      return { ...state, loading: false, insurance: action.payload };
    }
    case getType(actions.insuranceFetchError): {
      return { ...state, loading: false, errors: action.payload };
    }
    // Avatar
    case getType(actions.avatarUpdateRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.avatarUpdateSuccess): {
      return { ...state, loading: false, userInfo: action.payload };
    }
    case getType(actions.avatarUpdateError): {
      return { ...state, loading: false, errors: action.payload };
    }
    // Password
    case getType(actions.passwordUpdateRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.passwordUpdateSuccess): {
      return { ...state, loading: false, passwordError: undefined };
    }
    case getType(actions.passwordUpdateError): {
      return { ...state, loading: false, passwordError: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
