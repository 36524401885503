import { Dialog, MenuItem } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import { RootState } from "../../../store/root-reducer";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import DialogHeader from "../../shared/components/DialogHeader";
import { UserInfoItem } from "../../userInfo/models";
import { reportAbuseRequest } from "../actionsGroup";
import { Group, ReportAbuseRequest, RpActivity } from "../models";
import ReportAbuseMenuForm from "./ReportAbuseMenuForm";
import styles from "./styles";

const ValidationSchema = Yup.object().shape({
  description: Yup.string().required("Description is required")
});

interface Props extends WithStyles<typeof styles> {
  reportText: string;
  descriptionText: string;
  submitButtonText: string;
  reportAbuseGroup?: Group;
  reportAbusePostId?: number;
  closeMenu: () => void;
}

interface PropsFromState {
  user?: UserInfoItem;
}

interface PropsFromDispatch {
  reportAbuseRequest: typeof reportAbuseRequest;
  logActivity: typeof logActivity;
}
type AllProps = PropsFromState & PropsFromDispatch & Props;

type MyState = {
  open: boolean;
};

export type InputFormState = {
  reporterNameId: string;
  offensiveCommentId: 0;
  offensivePostId: 0;
  offensiveGroupId: 0;
  description: string;
};

type State = MyState & InputFormState;

class ReportAbuseMenuItem extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    open: false,
    reporterNameId: "",
    offensiveCommentId: 0,
    offensivePostId: 0,
    offensiveGroupId: 0,
    description: ""
  };

  submitValues = (values: any) => {
    if (this.props.reportAbuseGroup) {
      const groupToReport = this.props.reportAbuseGroup;
      const newAbuseReport: ReportAbuseRequest = {
        reporterNameId: this.props.user!.nameId,
        offensiveGroupId: groupToReport.id,
        description: values.description
      };
      this.props.reportAbuseRequest(newAbuseReport);
      this.props.logActivity({
        actcode: "RG",
        note: `Resident reported group: ${groupToReport.name} - ${values.description}`
      });
    } else if (this.props.reportAbusePostId) {
      const newAbuseReport: ReportAbuseRequest = {
        reporterNameId: this.props.user!.nameId,
        offensivePostId: this.props.reportAbusePostId,
        description: values.description
      };
      this.props.reportAbuseRequest(newAbuseReport);
      this.props.logActivity({
        actcode: "RP",
        note: `Resident reported a social post`
      });
    }

    this.setState({ open: false });
    this.props.closeMenu();
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  public render() {
    const { open, reporterNameId, offensiveCommentId, offensivePostId, offensiveGroupId, description } = this.state;
    const values = {
      open: open,
      reporterNameId: reporterNameId,
      offensiveCommentId: offensiveCommentId,
      offensivePostId: offensivePostId,
      offensiveGroupId: offensiveGroupId,
      description: description
    };

    return (
      <React.Fragment>
        <MenuItem onClick={this.handleClickOpen}>{this.props.reportText}</MenuItem>
        <Dialog open={open} onClose={this.handleClose}>
          <DialogHeader title={this.props.reportText} handleClose={this.handleClose} />
          <Formik
            render={props => (
              <ReportAbuseMenuForm
                descriptionText={this.props.descriptionText}
                submitButtonText={this.props.submitButtonText}
                {...props}
              />
            )}
            initialValues={values}
            validationSchema={ValidationSchema}
            onSubmit={this.submitValues}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount)
});

const mapDispatchToProps: PropsFromDispatch = {
  reportAbuseRequest: (payload: ReportAbuseRequest) => reportAbuseRequest(payload),
  logActivity: (act: RpActivity) => logActivity(act)
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReportAbuseMenuItem));
