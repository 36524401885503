import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import ElectronicRefund from "../components/electronicRefund";
import MoveOutInspection from "../components/moveOutInspection";
import { NoticeToVacate } from "../models";
import entvstyles from "../styles";

const styles = combinestyles(entvstyles, globalstyles);

export interface ENTVWalkProps extends WithStyles<typeof styles> {
  onContinue: any;
  noticeInfo?: NoticeToVacate;
  handleSodaOptOut: (value: boolean) => void;
  handleERefundFlag: (value: boolean) => void;
  handleERefundEmail: (value: string) => void;
  handlePresentForInspectionFlag: (value: string) => void;
  handle48HourWaiverFlag: (value: boolean) => void;
  handlePetFlag: (value: boolean) => void;
  handlePTEFlag: (value: boolean) => void;
  handleAlarmFlag: (value: boolean) => void;
  handleForwardAddressChange: (textName: string, value: string) => void;
  handleAlarmDetails: (value: string) => void;
  handleInspectionDate: (value: Date) => void;
}

type State = {
  electronicRefund: boolean;
  sodaOptOut: boolean;
  address1: string;
  address2: string;
  city: string;
  stateFA: string;
  zip: string;
  presentForInspection: boolean;
  permissionToEnter: boolean;
  havePet: boolean;
  haveAlarm: boolean;
  inspectionDate?: Date;
  waiver48Hour: boolean;
  alarmDetails: string;
  eRefundClearFlag: boolean;
  moveOutClearFlag: boolean;
  continueEnabled: boolean;
  modalVisibility: boolean;
};

type AllProps = ENTVWalkProps & RouteComponentProps;

class ENTVWalk extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    electronicRefund: false,
    sodaOptOut: false,
    address1: "",
    address2: "",
    city: "",
    stateFA: "",
    zip: "",
    presentForInspection: false,
    permissionToEnter: false,
    havePet: false,
    haveAlarm: false,
    inspectionDate: undefined,
    waiver48Hour: false,
    alarmDetails: "",
    eRefundClearFlag: false,
    moveOutClearFlag: false,
    continueEnabled: false,
    modalVisibility: false
  };

  handleWalkContinueButton = () => {
    if (this.state.eRefundClearFlag && this.state.moveOutClearFlag) {
      this.setState({ continueEnabled: true });
    } else {
      this.setState({ continueEnabled: false });
    }
  };

  handleERefundClearFlag = (value: boolean) => {
    this.setState({ eRefundClearFlag: value }, () => {
      this.handleWalkContinueButton();
    });
  };

  handleMoveOutClearFlag = (value: boolean) => {
    this.setState({ moveOutClearFlag: value }, () => {
      this.handleWalkContinueButton();
    });
  };

  handleMoveOutText = () => {
    this.setState({ modalVisibility: true });
  };

  handleOpenModal = () => {
    this.setState({ modalVisibility: true });
  };

  handleCloseModal = () => {
    this.setState({ modalVisibility: false });
  };

  componentDidMount() {}

  render() {
    const waiverAgreement = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A29";
    });

    const preMoveOutBottomCopy = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A19";
    });

    let buttonLabel = "";

    if (this.props.noticeInfo!.isEasyMove.toString() === "Y") {
      buttonLabel = "Continue";
    } else {
      buttonLabel = "Agree & Submit Notice to Vacate";
    }

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <ElectronicRefund
            tileLocked={true}
            noticeInfo={this.props.noticeInfo}
            handleSodaOptOut={this.props.handleSodaOptOut}
            handleERefundFlag={this.props.handleERefundFlag}
            handleERefundEmail={this.props.handleERefundEmail}
            handleForwardAddressChange={this.props.handleForwardAddressChange}
            handleERefundClearFlag={this.handleERefundClearFlag}
          />
          <MoveOutInspection
            noticeInfo={this.props.noticeInfo}
            handlePresentForInspectionFlag={this.props.handlePresentForInspectionFlag}
            handle48HourWaiverFlag={this.props.handle48HourWaiverFlag}
            handlePetFlag={this.props.handlePetFlag}
            handlePTEFlag={this.props.handlePTEFlag}
            handleAlarmFlag={this.props.handleAlarmFlag}
            handleAlarmDetails={this.props.handleAlarmDetails}
            handleInspectionDate={this.props.handleInspectionDate}
            handleMoveOutClearFlag={this.handleMoveOutClearFlag}
            handleMoveOutText={this.handleMoveOutText}
          />
          <Grid container className={this.props.classes.grouping}>
            <Grid item xs={12} style={{ display: "inline-block" }}>
              {String(this.props.noticeInfo!.isEasyMove) === "N" && (
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ display: "inline-block", fontSize: ".875rem", marginRight: "100px", paddingTop: "1rem" }}
                >
                  {waiverAgreement!.copyLanguage}
                </Typography>
              )}

              <Button
                onClick={this.props.onContinue.bind(this, this.state)}
                color="primary"
                variant="contained"
                autoFocus
                disabled={!this.state.continueEnabled}
                style={{ float: "right" }}
              >
                {buttonLabel}
              </Button>
            </Grid>
          </Grid>
          <Dialog
            fullScreen={false}
            open={this.state.modalVisibility}
            onClose={this.handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description" component={"span"}>
                <Typography style={{ display: "inline-block", paddingTop: "2rem" }}>Pre Move-Out Inspection</Typography>
                <Typography
                  style={{ display: "inline-block", fontSize: ".875rem", whiteSpace: "pre-wrap", paddingTop: "1rem" }}
                >
                  {preMoveOutBottomCopy!.copyLanguage}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseModal} color="primary" variant="contained" autoFocus>
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </main>
      </div>
    );
  }
}

export default withRouter(connect()(withStyles(styles)(ENTVWalk)));
