import { Divider, List, ListItem, ListItemIcon, ListItemText, Typography, WithStyles } from "@material-ui/core";
import SwitchAccountIcon from "@material-ui/icons/Autorenew";
import ChevronRight from "@material-ui/icons/ChevronRight";
import * as History from "history";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link, NavLink, withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import {
  bannedResidentRoutesArray,
  currentResidentRoutesArray,
  formerResidentRoutesArray,
  MyRouteProps,
  newResidentNoMICheckListRoutesArray,
  newResidentRoutesArray,
  newResidentNoSocialRoutesArray,
  applicantRoutesArray,
  applicantwithPaymentRoutesArray,
} from "../../../routes";
import { RootState } from "../../../store/root-reducer";
import styles from "../../../styles";
import { openSelectAccount } from "../../selectAccount/actions";
import SelectAccountDisplay from "../../selectAccount/components/SelectAccountDisplay";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import * as constants from "../constants/constants";

interface MyProps {
  classes: any;
  toggleDrawerCallback: any;
}

interface PropsFromState {
  user: UserInfoItem;
  availableUsers: UserInfoItem[];
}

interface PropsFromDispatch {
  osa: typeof openSelectAccount;
}

type AllProps = PropsFromState & MyProps & PropsFromDispatch & WithStyles<typeof styles> & RouteComponentProps;

class LeftNavigation extends React.Component<AllProps> {
  getRoutes = (): MyRouteProps[] => {
    let residentStatus = this.props.user ? this.props.user.residentStatus : "";
    let bannedFromSocial = this.props.user ? this.props.user.bannedFromSocial : false;
    let miCheckListStatus =
      this.props.user === null || this.props.user === undefined ? "" : this.props.user.moveinCheckListActive;
    let displayPaymentMenuForApplicant =
      this.props.user === null || this.props.user === undefined ? "" : this.props.user.displayPaymentMenu;
    const renewalAvailable = this.props.user ? this.props.user.renewalAvailable : false;

    if (residentStatus !== "") {
      if (residentStatus === constants.FORMER && !bannedFromSocial) {
        return formerResidentRoutesArray;
      } else if (residentStatus === constants.NEW && miCheckListStatus === "N") {
        return newResidentNoMICheckListRoutesArray;
      } else if (residentStatus === constants.NEW && !bannedFromSocial) {
        return newResidentRoutesArray;
      } else if (residentStatus === constants.NEW && bannedFromSocial) {
        return newResidentNoSocialRoutesArray;
      } else if (residentStatus === constants.APPLICANT && displayPaymentMenuForApplicant === "Y") {
        return applicantwithPaymentRoutesArray;
      } else if (residentStatus === constants.APPLICANT) {
        return applicantRoutesArray;
      } else if (bannedFromSocial) {
        return bannedResidentRoutesArray;
      } else {
        if (!renewalAvailable) {
          return currentResidentRoutesArray.filter((x) => x.sidebarName !== "Renew Lease");
        }
        return currentResidentRoutesArray;
      }
    }
    return [];
  };

  render() {
    const { classes, user, toggleDrawerCallback } = this.props;
    let routes: MyRouteProps[] = this.getRoutes();
    let residentOfferFlag = true;
    let residentOffer = this.props.user.residentOffersUrl;
    if (residentOffer) {
      if (residentOffer.length > 0) {
        residentOfferFlag = true;
      } else {
        residentOfferFlag = false;
      }
    } else {
      residentOfferFlag = false;
    }

    return (
      <div>
        <List>
          {routes.map((prop: MyRouteProps, key) => {
            let showNavItem = prop.sidebarName ? true : false;

            // allows nav to be replaced with an external link opens in _blank target
            let hasDirectLink = false;
            let directLink = "";

            console.log("Test Side Bar");
            console.log(prop.sidebarName);
            console.log(this.props.user.applicantCreditReportUrl);
            console.log(this.props.user.displayCriminalReport);
            // for now this is only applicable to View Credit Report for Applicants
            if (prop.sidebarName && prop.sidebarName === "View Credit Report") {
              if (user.applicantCreditReportUrl.length === 0) {
                showNavItem = false; // skip this nav item if it there is no credit report url
              }
              // else {
              //   hasDirectLink = true;
              //   directLink = user.applicantCreditReportUrl;
              // }
            }

            // View Criminal Report
            if (prop.sidebarName && prop.sidebarName === "View Criminal Report") {
              if (user.displayCriminalReport.length === 0) {
                showNavItem = false; // skip this nav item if it there is no credit report url
              }
              // else {
              //   hasDirectLink = true;
              //   directLink = user.applicantCreditReportUrl;
              // }
            }
            console.log(showNavItem);

            if (showNavItem) {
              //console.log("quicklinks", prop.quickLinks);
              const quickLinks = prop.quickLinks
                ? prop.quickLinks.map((quickLink: { sidebarName: string; path: string }, key) => (
                    <div key={key}>
                      {quickLink.sidebarName === "MAKE A PAYMENT" ? (
                        <NavLink
                          to={quickLink.path! as History.LocationDescriptor}
                          activeClassName="active"
                          className={classes.quickLink}
                          activeStyle={{
                            display: "block",
                            width: "100%",
                            fontWeight: 500,
                            backgroundImage:
                              "url('https://media.equityapartments.com/image/upload/v1561600477/Content/portal/uielements/arrowright.png')",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: ".5rem",
                            backgroundPositionX: ".5rem",
                            backgroundPositionY: "1.1rem",
                          }}
                        >
                          <ListItem style={{ paddingTop: 0, paddingLeft: "38px", paddingRight: "24px" }}>
                            <ListItemText
                              primary={quickLink.sidebarName}
                              disableTypography={true}
                              style={{ padding: "0", flex: "none" }}
                            />
                            <ListItemIcon style={{ marginRight: "0" }}>
                              <ChevronRight />
                            </ListItemIcon>
                          </ListItem>
                        </NavLink>
                      ) : (
                        <NavLink
                          to={quickLink.path! as History.LocationDescriptor}
                          activeClassName="active"
                          className={classes.quickLink}
                        >
                          <ListItem style={{ paddingTop: 0, paddingLeft: "38px", paddingRight: "24px" }}>
                            <ListItemText
                              primary={quickLink.sidebarName}
                              disableTypography={true}
                              style={{ padding: "0", flex: "none" }}
                            />
                            <ListItemIcon style={{ marginRight: "0" }}>
                              <ChevronRight />
                            </ListItemIcon>
                          </ListItem>
                        </NavLink>
                      )}
                    </div>
                  ))
                : null;

              return (
                <div key={key}>
                  {prop.sidebarName === "Home" ? (
                    <div>
                      {window.location.pathname.length > 2 ? (
                        <div>
                          <NavLink
                            to={prop.path! as History.LocationDescriptor}
                            activeClassName="active"
                            className={classes.link + " homeClassGreater"}
                            key={key}
                            onClick={toggleDrawerCallback}
                            activeStyle={{
                              backgroundImage: "none",
                            }}
                          >
                            <ListItem style={{ paddingLeft: "24px", paddingRight: "24px" }}>
                              <ListItemText primary={prop.sidebarName} disableTypography={true} />
                            </ListItem>
                          </NavLink>
                          {quickLinks}
                          <Divider className={classes.navDivider} />
                        </div>
                      ) : (
                        <div>
                          <NavLink
                            to={prop.path! as History.LocationDescriptor}
                            activeClassName="active"
                            className={classes.link + " homeClassLess"}
                            key={key}
                            onClick={toggleDrawerCallback}
                            activeStyle={{
                              display: "block",
                              width: "100%",
                              fontWeight: 500,
                              backgroundImage:
                                "url('https://media.equityapartments.com/image/upload/v1561600477/Content/portal/uielements/arrowright.png')",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: ".5rem",
                              backgroundPositionX: ".5rem",
                              backgroundPositionY: "1.1rem",
                            }}
                          >
                            <ListItem style={{ paddingLeft: "24px", paddingRight: "24px" }}>
                              <ListItemText primary={prop.sidebarName} disableTypography={true} />
                            </ListItem>
                          </NavLink>
                          {quickLinks}
                          <Divider className={classes.navDivider} />
                        </div>
                      )}
                    </div>
                  ) : hasDirectLink ? (
                    <Link className={classes.link} to={{ pathname: directLink }} target="_blank">
                      <ListItem style={{ paddingLeft: "24px", paddingRight: "24px" }}>
                        <ListItemText primary={prop.sidebarName} disableTypography={true} />
                      </ListItem>
                    </Link>
                  ) : (
                    <div>
                      <NavLink
                        to={prop.path! as History.LocationDescriptor}
                        activeClassName="active"
                        className={classes.link}
                        key={key}
                        onClick={toggleDrawerCallback}
                        activeStyle={{
                          display: "block",
                          width: "100%",
                          fontWeight: 500,
                          backgroundImage:
                            "url('https://media.equityapartments.com/image/upload/v1561600477/Content/portal/uielements/arrowright.png')",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: ".5rem",
                          backgroundPositionX: ".5rem",
                          backgroundPositionY: "1.1rem",
                        }}
                      >
                        <ListItem style={{ paddingLeft: "24px", paddingRight: "24px" }}>
                          <ListItemText primary={prop.sidebarName} disableTypography={true} />
                        </ListItem>
                      </NavLink>
                      {quickLinks}
                      <Divider className={classes.navDivider} />
                    </div>
                  )}
                </div>
              );
            } else {
              return null;
            }
          })}
        </List>
        {residentOfferFlag === true && (
          <a
            href={this.props.user.residentOffersUrl!}
            target="_blank"
            rel="noreferrer"
            className={classes.link + " homeClassGreater"}
          >
            <ListItem style={{ paddingLeft: "24px", paddingRight: "24px" }}>
              <ListItemText primary="Resident Offers" disableTypography={true} />
            </ListItem>
            <Divider className={classes.navDivider} />
          </a>
        )}

        <div className={classes.logoutContainer}>
          <div style={{ marginBottom: "15px" }}>
            <Link to={"/logout"} className={classes.logout}>
              <Typography>Logout</Typography>
            </Link>
          </div>
          {this.props.availableUsers.length > 1 && (
            <>
              <Divider style={{ marginLeft: "0", marginRight: "0", marginBottom: "15px" }} />
              <Link
                to={""}
                className={classes.logout}
                onClick={() => this.props.osa()}
                style={{ display: "flex", alignItems: "center" }}
              >
                <SwitchAccountIcon />
                <Typography>Switch Accounts</Typography>
              </Link>
              <SelectAccountDisplay />
            </>
          )}
          <Divider className={classes.logoutDivider} />
          {user && (
            <React.Fragment>
              <Typography className={classes.propInfo} style={{ fontWeight: "bold", paddingBottom: "10px" }}>
                {user.propertyInfo.propertyName}
              </Typography>
              <Typography className={classes.propInfo} style={{ fontWeight: "bold" }}>
                Your Address
              </Typography>
              <Typography className={classes.propInfo}>{user.propertyInfo.propertyAddress1}</Typography>
              <Typography className={classes.propInfo}>
                {user.propertyInfo.propertyCity}, {user.propertyInfo.propertyState} {user.propertyInfo.propertyZipcode}
              </Typography>
            </React.Fragment>
          )}
          <div style={{ float: "left", marginTop: "0.5em", marginBottom: "1em" }}>
            <Typography className={classes.propInfo} style={{ display: "inline-block" }}>
              <a href="https://www.equityapartments.com/privacy" target="_new" style={{ color: "#2e79b6" }}>
                Privacy Policy
              </a>
            </Typography>
            <Typography
              className={classes.propInfo}
              style={{ display: "inline-block", marginLeft: "0.25em", marginRight: "0.25em" }}
            >
              {" "}
              |{" "}
            </Typography>
            <Typography className={classes.propInfo} style={{ display: "inline-block" }}>
              <a href="https://www.equityapartments.com/terms" target="_new" style={{ color: "#2e79b6" }}>
                Terms of Use
              </a>
            </Typography>
            <Typography
              className={classes.propInfo}
              style={{ display: "inline-block", marginLeft: "0.25em", marginRight: "0.25em" }}
            >
              {" "}
              |{" "}
            </Typography>
            <Typography className={classes.propInfo} style={{ display: "inline-block" }}>
              <a href="https://privacy.equityapartments.com/#DoNotSell" target="_new" style={{ color: "#2e79b6" }}>
                Your Privacy Choices&nbsp;
                <img
                  src="https://media.equityapartments.com/image/upload/v1675201401/Content/privacyoptions.svg"
                  style={{ width: "2em" }}
                  alt="Privacy Options"
                />
              </a>
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ selectAccount, profile }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  availableUsers: selectAccount.userInfoArray,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  osa: () => dispatch(openSelectAccount()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftNavigation));
