import { UserManagerSettings } from "oidc-client";
import { createUserManager } from "redux-oidc";

const baseUri = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ""
}`;

const userManagerConfig: UserManagerSettings = {
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: `${baseUri}/callback`,
  response_type: "token id_token",
  scope: process.env.REACT_APP_SCOPE,
  authority: process.env.REACT_APP_RESAUTH_URL,
  silent_redirect_uri: `${baseUri}/silent-renew`,
  post_logout_redirect_uri: `${baseUri}/logoutcallback`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: false,
  monitorSession: true,
};

const userManagerConfigAlternative: UserManagerSettings = {
  client_id: process.env.REACT_APP_CLIENT_ID_ALT,
  redirect_uri: `${baseUri}/callback`, // 'https://jwt.ms/', //
  response_type: "token id_token",
  scope: process.env.REACT_APP_SCOPE_ALT,
  authority: process.env.REACT_APP_RESAUTH_URL_ALT,
  silent_redirect_uri: `${baseUri}/silent-renew`,
  post_logout_redirect_uri: `${baseUri}/logoutcallback`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
};

// if Azure flag is true, send to B2C.  Otherwise, ResAuth

let isAzure = process.env.REACT_APP_AZURE_FLAG;

const userManager = createUserManager(isAzure === "true" ? userManagerConfig : userManagerConfigAlternative);

export default userManager;
