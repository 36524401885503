import * as React from "react";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { RootState } from "../../../store/root-reducer";
import { UserInfoItem } from "../../userInfo/models";
import { getActiveUserInfo } from "../../../features/selectAccount/selector";

interface PropsFromState {
  user: UserInfoItem;
}

type AllProps = PropsFromState;

class OfficeHours extends React.Component<AllProps> {
  getDayOfWeek = (day: string): string => {
    switch (day) {
      case "0": {
        return "Sun: ";
      }
      case "1": {
        return "Mon: ";
      }
      case "2": {
        return "Tue: ";
      }
      case "3": {
        return "Wed: ";
      }
      case "4": {
        return "Thu: ";
      }
      case "5": {
        return "Fri: ";
      }
      case "6": {
        return "Sat: ";
      }
      default: {
        return " ";
      }
    }
  };

  render() {
    const officeHours = this.props.user.propertyInfo.officeHours;
    let officeHoursArray = [];
    for (let i = 0; i < officeHours.length; i++) {
      if (!officeHours[i].closed) {
        officeHoursArray.push(
          `${this.getDayOfWeek(officeHours[i].day.toString())} ${officeHours[i].openTime} - ${officeHours[i].closeTime}`
        );
      } else {
        officeHoursArray.push(`${this.getDayOfWeek(officeHours[i].day.toString())} Closed`);
      }
    }

    return (
      <div>
        <Typography>
          <strong>Office Hours</strong>
        </Typography>
        <br />
        {officeHoursArray.map((item, key) => (
          <Typography key={key}>{item}</Typography>
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount)
});

export default connect(mapStateToProps)(OfficeHours);
