import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store/root-reducer";
import styles from "../../../styles";
import { WithStyles, Avatar, Typography, withStyles, Grid } from "@material-ui/core";
import { GenerateCloudinaryThumbnailUrl } from "../../../utils/cloudinaryImageFormatter";
import SharedImagesConstants from "../constants/sharedImages";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import * as constants from "../constants/constants";
// import ReactModal from "react-modal";
import { Dispatch } from "redux";
import { hideModalAction } from "../../errorhandling/actions";
import { ModalModel } from "../../errorhandling/models";
import ErrorModal from "./ErrorModal";
import ReactGA from "react-ga";

const trackingId = "UA-148138134-1"; //process.env.GA_TRACKING_ID!; //"UA-148138134-1" - Use for DEV
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

interface MyProps extends WithStyles<typeof styles> {}

interface PropsFromState {
  user: UserInfoItem;
  showError: boolean;
  errorDetails?: ModalModel;
}

interface PropsFromDispatch {
  hideError: typeof hideModalAction;
}

type AllProps = PropsFromState & MyProps & PropsFromDispatch;

class UserInfoHeader extends Component<AllProps> {
  isFormerResident = () => {
    const residentStatus = this.props.user ? this.props.user.residentStatus : "";
    if (residentStatus !== "") {
      if (residentStatus === constants.FORMER || residentStatus === constants.APPLICANT) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  render() {
    const { user, classes } = this.props;
    if (!user) {
      return <React.Fragment />;
    }
    const avatar = user.profilePhotoUrl ? (
      <Avatar className={classes.userInfoHeaderAvatar} src={GenerateCloudinaryThumbnailUrl(user.profilePhotoUrl)} />
    ) : (
      <Avatar className={classes.userInfoHeaderAvatar} src={SharedImagesConstants.EMPTYAVATAR_FILLED} />
    );
    const userInfoContent = (
      <React.Fragment>
        <ErrorModal
          errorDetails={this.props.errorDetails}
          showError={this.props.showError}
          hideError={this.props.hideError}
          classes={classes}
        />

        <Grid container className={classes.userInfoHeaderContainer}>
          <Grid item>{avatar}</Grid>
          <Grid item>
            <Typography className={classes.userInfoHeaderName}>{user.fullName}</Typography>
            <Typography className={classes.userInfoHeaderProperty}>
              Building: {user.buildingId} | Apartment: {user.unitId}
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
    if (this.isFormerResident()) {
      return <React.Fragment>{userInfoContent}</React.Fragment>;
    } else {
      return (
        <a className={classes.userInfoLink} href="/myaccount/myprofile">
          {userInfoContent}
        </a>
      );
    }
  }
}

const mapStateToProps = ({ selectAccount, errorHandling }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  showError: errorHandling.errors,
  errorDetails: errorHandling.data
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  hideError: () => dispatch(hideModalAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserInfoHeader));
