import { createAsyncAction } from "typesafe-actions";
import { AdverseActionLetterData } from "./models";

const FETCH_REQUEST = "adverseactionletter/FETCH_REQUEST";
const FETCH_SUCCESS = "adverseactionletter/FETCH_SUCCESS";
const FETCH_ERROR = "adverseactionletter/FETCH_ERROR";

export const fetchAsyncAction = createAsyncAction(FETCH_REQUEST, FETCH_SUCCESS, FETCH_ERROR)<
  string,
  AdverseActionLetterData,
  Error
>();
