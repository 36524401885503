import * as React from "react";
import { withStyles, WithStyles } from "@material-ui/core";
import { renewalFetchRequest } from "../features/renewal/actions";

import RenewalDashboard from "../features/renewal/components/RenewalDashboard";
import RenewalConfirm from "../features/renewal/components/RenewalConfirm";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps, Route, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import { RootState } from "../store/root-reducer";
import { RenewalData } from "../features/renewal/models";

import renewalstyles from "../styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(renewalstyles, globalstyles);

interface IRenewalProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
}

interface IPageState {}

interface PropsFromState {
  user?: UserInfoItem;
  loading: boolean;
  renewalData: RenewalData | undefined;
  errors?: string;
}

interface PropsFromDispatch {
  renewalFetchRequest: typeof renewalFetchRequest;
}

type AllProps = IRenewalProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

class RenewalPage extends React.Component<AllProps, IPageState> {
  state: IPageState = {};

  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.renewalFetchRequest(nameId);
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Switch>
        <Route path="/renewal/RenewalConfirm" component={RenewalConfirm} />
        <Route component={RenewalDashboard} />
      </Switch>
    );
  }
}

const mapStateToProps = ({ renewal, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  renewalData: renewal.renewalData.data,
  loading: renewal.renewalData.loading,
  errors: renewal.renewalData.errors
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  renewalFetchRequest: (nameid: string) => dispatch(renewalFetchRequest(nameid))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RenewalPage)));
