import { createAsyncAction } from "typesafe-actions";
import { NewResidentMoveInDocuSignData } from "./models";

const FETCH_REQUEST = "docusignlease/FETCH_REQUEST";
const FETCH_SUCCESS = "docusignlease/FETCH_SUCCESS";
const FETCH_ERROR = "docusignlease/FETCH_ERROR";

export const fetchAsyncAction = createAsyncAction(FETCH_REQUEST, FETCH_SUCCESS, FETCH_ERROR)<
  string,
  NewResidentMoveInDocuSignData,
  Error
>();
