import { Paper, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { renewalFetchRequest } from "../actions";
import { RenewalData, RenewalOfferTerm } from "../models";
import eventstyles from "../styles";
import RenewalBalance from "./RenewalBalance";
import RenewalCaption from "./RenewalCaption";
import RenewalHeader from "./RenewalHeader";
import RenewalHeaderTiles from "./RenewalHeaderTiles";
import RenewalLeaseStart from "./RenewalLeaseStart";

const styles = combinestyles(eventstyles, globalstyles);

interface IRenewalDashboardProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
}

interface IPageState {
  selectedTerm?: RenewalOfferTerm;
}

interface PropsFromState {
  user?: UserInfoItem;
  loading: boolean;
  renewalDataInfo?: RenewalData;
  errors?: string;
}

interface PropsFromDispatch {
  renewalFetchRequest: typeof renewalFetchRequest;
}

type AllProps = IRenewalDashboardProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

class RenewalDashboardPage extends React.Component<AllProps, IPageState> {
  state: IPageState = {};

  handleSelectTerm = (event: any) => {
    if (!this.props.renewalDataInfo || !this.props.renewalDataInfo!.renewalOfferTermss) {
      return;
    }
    const selectedTerm = this.props.renewalDataInfo!.renewalOfferTermss.filter(x => x.term === event.target.value)[0];

    this.setState({ selectedTerm: selectedTerm });
  };

  render() {
    const { classes } = this.props;

    if (!this.props.renewalDataInfo) {
      return <div />;
    }

    if (
      !this.state.selectedTerm &&
      this.props.renewalDataInfo.renewalOfferTermss &&
      this.props.renewalDataInfo.renewalOfferTermss.length > 0
    ) {
      this.setState({ selectedTerm: this.props.renewalDataInfo.renewalOfferTermss[0] });
    }

    if (!this.state.selectedTerm) {
      return <div />;
    }
    setTimeout(() => window.scrollTo(0, 0), 0);
    return (
      <div className={classes.root}>
        <main className={classes.contentRightRail}>
          <RenewalHeader />
          <RenewalHeaderTiles user={this.props.user!} />
          <Paper className={classes.paper}>
            <RenewalCaption />
            <RenewalLeaseStart selectedTerm={this.state.selectedTerm!} />
            <RenewalBalance
              optionTerm={this.props.renewalDataInfo.renewalOfferTermss!}
              renewalOtherCharges={this.props.renewalDataInfo.renewalOtherChargess!}
              legalText={this.props.renewalDataInfo.renewalLegalTexts!}
              oneTimeDiscount={this.props.renewalDataInfo.renewalOneTimeFeesAndDiscounts}
            />
            {/* <Select
              value={this.state.selectedTerm ? this.state.selectedTerm!.term : undefined}
              onChange={this.handleSelectTerm}
              inputProps={{
                name: "age",
                id: "age-simple"
              }}
            >
              {this.props.renewalDataInfo.renewalOfferTermss &&
                this.props.renewalDataInfo.renewalOfferTermss.map(x => (
                  <MenuItem value={x.term} key={x.term}>
                    {x.term}
                  </MenuItem>
                ))}
            </Select> */}
          </Paper>
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ renewal, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  renewalDataInfo: renewal.renewalData.data,
  loading: renewal.renewalData.loading,
  errors: renewal.renewalData.errors
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  renewalFetchRequest: (nameid: string) => dispatch(renewalFetchRequest(nameid))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RenewalDashboardPage)));
