import axios from "axios";

import { PaymentInputMppData } from "../../src/features/payment/models";
import { NewRenewal } from "../../src/features/renewal/models";
import {
  BlockUserDto,
  CreateGroupDto,
  JoinLeaveGroupDto,
  PhotosToUpload,
  ReportAbuseRequest,
  UpdatePostDto,
} from "../../src/features/social/models";
import { store } from "../AppContainer";
import { ResidentRelationDto } from "../features/contact/models";
import {
  CancelRequestData,
  ERefundValue,
  ForwardingAddress,
  NewNoticeToVacate,
  SaveExpectedMoveOutDate,
} from "../features/entv/models";
import { RequestPaymentPlan } from "../features/formerresident/models";
import {
  ContactEmergency,
  ContactInfo,
  SpecialAssistance,
  TransUnionPaymentReporting,
  VehicleInfo,
  VehicleInfoUpdate,
} from "../features/myprofile/models";
import { NewReferFriend } from "../features/referfriend/models";
import { UploadDocument } from "../features/uploads/models";

// import { RweInput } from "../../src/features/rentwithequity/models";
function setDefaults() {
  const state = store.getState();
  const user = state.auth.user;
  // Prevents IE caching calls
  axios.defaults.headers.get["Pragma"] = "no-cache";
  axios.defaults.headers.common.Authorization = "Bearer " + user!.access_token;
  axios.defaults.baseURL = process.env.REACT_APP_PAPI_BASE_URL;
}

export async function getUploadDocumentsList(nameid: string) {
  setDefaults();
  const response = await axios.get(`UserInfo/UploadDocumentsList/${nameid}`);

  return response.data;
}

export async function uploadFile(data: UploadDocument) {
  setDefaults();
  var formData = new FormData();
  formData.append("file", data.file!);
  const response = await axios.post(
    `UserInfo/UploadFile/${data.nameid}/${data.rmpropid}/${data.calltoaction}/${data.description}`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response.data;
}

export async function getAdverseActionLetter(nameid: string) {
  setDefaults();
  const response = await axios.get(`UserInfo/AdverseLetter/${nameid}`);
  return response.data;
}

export async function getMoveInChecklist(nameid: string) {
  setDefaults();
  const response = await axios.get(`UserInfo/MoveInCheckList/${nameid}`);
  return response.data;
}

export async function getCommunityGuideList(nameid: string) {
  setDefaults();
  const response = await axios.get(`UserInfo/CommunityGuideList/${nameid}`);
  return response.data;
}

export async function saveMoveInChecklistComplete(payload: { nameId: string; checkListType: string }) {
  setDefaults();
  const response = await axios.post(`UserInfo/SaveMoveInCheckListComplete`, payload);
  return response.data;
}

export async function saveWelcomeHomeOrientationRequest(payload: {
  nameId: string;
  requestedDate: string;
  requestedTime: string;
}) {
  setDefaults();
  const response = await axios.post(`UserInfo/SaveWelcomeHomeOrientationRequest`, payload);
  return response.data;
}

export async function updateAvatarImage(payload: { nameId: string; file: File }) {
  setDefaults();
  const formData = new FormData();
  formData.append("file", payload.file);
  const response = await axios.post(`Social/UpdateAvatarImage/${payload.nameId}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return response.data;
}

export async function updatePassword(payload: { nameId: string; oldPassword: string; newPassword: string }) {
  setDefaults();
  const response = await axios.put(`Auth/UpdatePassword/${payload.nameId}`, payload);
  return response.data;
}

export async function getApplicantCheckList(nameid: string) {
  setDefaults();
  const response = await axios.get(`UserInfo/ApplicantCheckList/${nameid}`);
  return response.data;
}

export async function saveRentGuaranteeOptions(payload: { nameId: string; guaranteeOption: string }) {
  setDefaults();
  const response = await axios.post(`UserInfo/SaveRentGuaranteeOptions`, payload);
  return response.data;
}

export async function loadNews(rmpropid: string) {
  setDefaults();
  const response = await axios.get(`News/${rmpropid}`);
  return response.data;
}

export async function getAvaliableServiceQuestions() {
  setDefaults();
  const response = await axios.get(`ServiceRequest/ServiceQuestions`);
  return response.data;
}

export async function loadServiceRequestCategories() {
  setDefaults();
  const response = await axios.get("ServiceRequest/Categories");
  return response.data;
}

export async function loadServiceRequestQuickFixes() {
  setDefaults();
  const response = await axios.get("ServiceRequest/QuickFixes");
  return response.data;
}

export async function loadServiceRequestLocations() {
  setDefaults();
  const response = await axios.get("ServiceRequest/Rooms");
  return response.data;
}

export async function loadServiceRequestTypes(propId: string) {
  setDefaults();
  const response = await axios.get(`ServiceRequest/Types/${propId}`);
  return response.data;
}

export async function getMyServiceRequests(nameId: string) {
  setDefaults();
  const response = await axios.get(`ServiceRequest/MyRequests/${nameId}`);
  return response.data;
}

export async function postServiceRequest(serviceRequest: any) {
  setDefaults();
  const response = await axios.post("ServiceRequest", serviceRequest);
  return response.data;
}

export async function postServiceRequestPhoto(serviceRequestId: number, serviceRequestPhoto: File) {
  setDefaults();
  const appSource = "WEBRP";
  const bodyFormData = new FormData();
  bodyFormData.append("image", serviceRequestPhoto);
  const response = await axios.post(`ServiceRequest/UploadPhoto/${serviceRequestId}/${appSource}`, bodyFormData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
}

export async function postActivity(activity: any) {
  setDefaults();
  const response = await axios.post("RPActivity", activity);
  return response.data;
}

export async function loadContact(rmpropid: string, nameId: string) {
  setDefaults();
  //return axios.get(`Contacts/${rmpropid}`).then(response => response.data);

  // TODO change the following to dynamic
  const response = await axios.get(`Contacts/${rmpropid}/${nameId}`);
  return response.data;
}

export async function loadContactUsList(nameId: string) {
  setDefaults();

  const response = await axios.get(`UserInfo/ContactUsList/${nameId}`);
  return response.data;
}

export async function loadGuest(nameId: string) {
  setDefaults();
  const response = await axios.get(`GuestAccess/MyGuests/${nameId}`);
  return response.data;
}

export async function loadGuestTypes() {
  setDefaults();

  const response = await axios.get(`GuestAccess/guesttypes`);
  return response.data;
}

export function postGuestAccess(guestAccess: any) {
  setDefaults();
  return axios.post("GuestAccess", guestAccess).then((response) => response.data);
}

export function putGuestAccess(guestAccess: any) {
  setDefaults();
  return axios.put(`GuestAccess/${guestAccess.id}`, guestAccess).then((response) => response.data);
}

export async function postGuestAccessPhoto(guestAccessId: number, guestAccessPhoto: File) {
  setDefaults();
  const bodyFormData = new FormData();
  bodyFormData.append("image", guestAccessPhoto);
  const response = await axios.post(`GuestAccess/UploadPhoto/${guestAccessId}`, bodyFormData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
}

export async function loadSocialFeed(nameId: string, postId?: number) {
  setDefaults();
  const params = postId !== null && postId !== undefined ? `?nextPostId=${postId}` : ``;
  const response = await axios.get(`Social/Posts/forNameId/${nameId}${params}`);
  return response.data;
}

export async function createSocialPost(post: any) {
  setDefaults();
  return axios.post(`Social/Posts/${post.itemType}`, post).then((response) => response.data);
}

export async function updatePost(postId: number, updatePostDto: UpdatePostDto) {
  setDefaults();
  const response = await axios.put(`Social/Posts/${postId}`, updatePostDto);
  return response.data;
}

export async function loadSocialEvents(nameId: string) {
  setDefaults();
  const response = await axios.get(`Social/Events/${nameId}`);
  return response.data;
}

export async function addComment(comment: any) {
  setDefaults();
  return axios.post(`Social/AddComment`, comment).then((response) => response.data);
}

export async function loadStatement(nameId: string, numStatements: number) {
  setDefaults();
  const response = await axios.get(`statement/${nameId}/${numStatements}`);
  return response.data;
}

export async function loadMaxNumStatements(nameId: string) {
  setDefaults();
  const response = await axios.get(`statement/count/${nameId}`);
  return response.data;
}

export async function loadSvcProviders(rmpropid: string) {
  setDefaults();
  const response = await axios.get(`ServiceProvider/ForProperty/${rmpropid}`);
  return response.data;
}

export async function loadLeaseDetails(nameId: string) {
  setDefaults();
  const response = await axios.get(`LeaseDetails/${nameId}`);
  return response.data;
}

// export async function loadLeasePDFURL(nameId: string) {
//   setDefaults();
//   const response = await axios.get(`Documents/MyLease/${nameId}`);
//   return response.data;
// }

export async function downloadLeaseDocument(payload: any) {
  setDefaults();
  const response = await axios.get(`Documents/Lease/${payload.nameId}`, {
    responseType: "blob",
  });
  return response;
}

export async function loadAmenityByDeepLink(rmpropId: string, amenId: string) {
  setDefaults();
  const response = await axios.get(`AmenityReservation/AmenityDeepLink/${rmpropId}/${amenId}`);
  return response.data;
}

export async function loadAmenityByProperty(rmpropid: string) {
  setDefaults();
  const response = await axios.get(`AmenityReservation/ReservableAmenities/${rmpropid}`);
  return response.data;
}

export async function loadAmenityByNameId(nameId: string) {
  setDefaults();
  const response = await axios.get(`AmenityReservation/MyReservations/${nameId}`);
  return response.data;
}

export async function loadAvailableSlotsByDateAndGroup(
  rmpropId: string,
  amenid: string,
  reservationDate: string,
  groupSize: string
) {
  setDefaults();
  const response = await axios.get(
    `AmenityReservation/ReservableTimeSlots/${rmpropId}/${amenid}/${reservationDate}/${groupSize}`
    //`AmenityReservation/ReservableTimeSlots/${'19161'}/${'562'}/${'2020-08-18'}/${2}`
  );
  return response.data;
}

export async function loadAvailableDurationSlots(
  rmpropId: string,
  amenid: string,
  reservationDate: string,
  groupSize: string,
  startTime: string
) {
  setDefaults();
  const response = await axios.get(
    `AmenityReservation/ReservableDuration/${rmpropId}/${amenid}/${reservationDate}/${groupSize}/${startTime}`
  );
  return response.data;
}

export async function cancelAmenityReservation(payload: any) {
  setDefaults();
  const response = await axios.post(`AmenityReservation/Cancel/`, payload);
  return response.data;
}

export async function insertAmenityReservation(payload: any) {
  setDefaults();
  const response = await axios.post(`AmenityReservation/`, payload);
  return response.data;
}

export async function loadRentableItemsByNameId(nameId: string) {
  setDefaults();
  const response = await axios.get(`RentableItems/MyRentableItems/${nameId}`);
  return response.data;
}

export async function loadRentableItemsByPropertyId(rmpropId: string) {
  setDefaults();
  const response = await axios.get(`RentableItems/RentableItems/${rmpropId}`);
  return response.data;
}

export async function requestRentableItem(requestRentItem: any) {
  setDefaults();
  const response = await axios.post(`RentableItems/RequestRentableItem/`, requestRentItem);
  return response.data;
}

// groups start
export async function loadGroupProperty(rmpropid: string) {
  setDefaults();
  const response = await axios.get(`Social/Groups/ForProperty/${rmpropid}`);
  return response.data;
}

export async function loadGroupUser(nameid: string) {
  setDefaults();
  const response = await axios.get(`Social/Groups/MyGroups/${nameid}`);
  return response.data;
}

export async function postGroupsJoin(joinLeaveGroupDto: JoinLeaveGroupDto) {
  setDefaults();
  const response = await axios.post("Social/Groups/Join", joinLeaveGroupDto);
  return response.data;
}

export async function postGroupsLeave(joinLeaveGroupDto: JoinLeaveGroupDto) {
  setDefaults();
  const response = await axios.post("Social/Groups/Leave", joinLeaveGroupDto);
  return response.data;
}

export async function postGroupsCreate(createGroupDto: CreateGroupDto) {
  setDefaults();
  const response = await axios.post("Social/Groups/Create", createGroupDto);
  return response.data;
}

export async function loadGroupFeed(payload: { groupId: string; nameId: string }) {
  setDefaults();
  const response = await axios.get(`Social/Posts/ForGroup/${payload.groupId}/${payload.nameId}`);
  return response.data;
}
// groups end
export async function loadUserInfo(nameid: string) {
  setDefaults();
  const response = await axios.get(`UserInfo/${nameid}`);
  return response.data;
}

export async function loadContactInfo(nameid: string) {
  setDefaults();
  const response = await axios.get(`UserInfo/ContactInfo/${nameid}`);
  return response.data;
}

export async function updateContactInfo(contactInfo: ContactInfo) {
  setDefaults();
  const response = await axios.put(`UserInfo/ContactInfo/${contactInfo.nameId}`, contactInfo);
  return response.data;
}

export async function loadEmergencyInfo(nameid: string) {
  setDefaults();
  const response = await axios.get(`UserInfo/EmergencyContact/${nameid}`);
  const responseTest = response.data;
  return responseTest;
}

export async function updateEmergencyInfo(contactEmergency: ContactEmergency) {
  setDefaults();
  const response = await axios.put(`UserInfo/EmergencyContact/${contactEmergency.nameId}`, contactEmergency);
  return response.data;
}

export async function loadSpecialAssistance(nameid: string) {
  setDefaults();
  const response = await axios.get(`UserInfo/SpecialAssistance/${nameid}`);
  return response.data;
}

export async function putSpecialAssistance(specialAssistance: SpecialAssistance) {
  setDefaults();
  const payload = {
    specialAssistanceRequested: specialAssistance.specialAssistanceRequested,
    specialAssistanceDescription: specialAssistance.description,
  };
  const response = await axios.put(`UserInfo/SpecialAssistance/${specialAssistance.nameId}`, payload);
  return response.data;
}

export async function loadTransUnionPaymentReporting(nameid: string) {
  setDefaults();
  const response = await axios.get(`UserInfo/TransUnionPaymentReporting/${nameid}`);
  return response.data;
}

export async function putTransUnionPaymentReporting(transunionSuppress: TransUnionPaymentReporting) {
  setDefaults();
  const payload = {
    transUnionSuppress: transunionSuppress.tuSuppress,
    transUnionSuppressDescription: transunionSuppress.description,
  };
  const response = await axios.put(`UserInfo/TransUnionPaymentReporting/${transunionSuppress.nameId}`, payload);
  return response.data;
}

export async function loadVehicles(nameid: string) {
  setDefaults();
  const response = await axios.get(`UserInfo/Vehicles/${nameid}`);
  return response.data;
}

export async function updateVehicle(payload: {
  originalLicense: string;
  originalState: string;
  updatedVehicle: VehicleInfo;
}) {
  setDefaults();
  payload.updatedVehicle.license = payload.updatedVehicle.license.replace('"', "");

  var updateVehicle: VehicleInfoUpdate = {
    nameId: payload.updatedVehicle.nameId,
    year: payload.updatedVehicle.year,
    make: payload.updatedVehicle.make,
    model: payload.updatedVehicle.model,
    state: payload.updatedVehicle.state,
    license: payload.updatedVehicle.license,
    stateOriginal: payload.originalState,
    licenseOriginal: payload.originalLicense,
  };

  const response = await axios.put(`UserInfo/Vehicles/${payload.updatedVehicle.nameId}`, updateVehicle);
  return response.data;
}

export async function addVehicle(vehicle: VehicleInfo) {
  setDefaults();
  vehicle.license = vehicle.license.replace('"', "");
  const response = await axios.post(`UserInfo/Vehicles/${vehicle.nameId}`, vehicle);
  return response.data;
}

export async function deleteVehicle(vehicle: { nameId: string; license: string; state: string }) {
  setDefaults();

  const vehicleDelete: VehicleInfo = {
    nameId: vehicle.nameId,
    year: "",
    make: "",
    model: "",
    state: vehicle.state,
    license: vehicle.license,
  };
  const response = await axios.post(`UserInfo/VehicleDelete/${vehicle.nameId}`, vehicleDelete);
  return response.data;
}

export async function loadInsurance(nameid: string) {
  setDefaults();
  const response = await axios.get(`UserInfo/InsurancePlan/${nameid}`);
  return response.data;
}

export async function loadRenewalByNameId(nameId: string) {
  setDefaults();
  const response = await axios.get(`Renewal/${nameId}`);
  return response.data;
}

export async function postRenewalCreate(renewalData: NewRenewal) {
  setDefaults();
  const response = await axios.post("Renewal", renewalData);
  return response.data;
}

export async function postReportAbuse(abuseData: ReportAbuseRequest) {
  setDefaults();
  const response = await axios.post("Social/ReportAbuse", abuseData);
  return response.data;
}

export async function postRsvp(rsvpData: any) {
  setDefaults();
  return await axios.post("Social/Events/Rsvp", rsvpData).then((response) => response.data);
}

export async function cancelRsvp(rsvpData: any) {
  setDefaults();
  return await axios.post("Social/Events/CancelRsvp", rsvpData).then((response) => response.data);
}

export async function blockUser(blockUserData: BlockUserDto) {
  setDefaults();
  return await axios.post("Social/BlockUser", blockUserData).then((response) => response.data);
}

export async function hidePost(postId: number) {
  setDefaults();
  return await axios.delete(`Social/Posts/${postId}`).then((response) => response.data);
}

export async function hideComment(commentId: number) {
  setDefaults();
  return await axios.delete(`Social/Comments/${commentId}`).then((response) => response.data);
}

export async function loadPaymentByNameId(nameId: string) {
  setDefaults();
  const response = await axios.get(`Payment/PaymentData/${nameId}`);
  return response.data;
}

export async function postPaymentCreate(paymentInputData: PaymentInputMppData) {
  setDefaults();
  const response = await axios.post(`Payment/MakePayment`, paymentInputData);
  return response.data;
}

export async function postAutoPaymentCreate(paymentInputData: PaymentInputMppData) {
  setDefaults();
  const response = await axios.post(`Payment/SetAutoPay`, paymentInputData);
  return response.data;
}

export async function attachPhotosToPost(data: PhotosToUpload) {
  setDefaults();
  return await axios.post(`Social/AttachPhotosToPost/${data.postId}`, data.files).then((response) => response.data);
}

export async function residentRelationsContact(data: ResidentRelationDto) {
  setDefaults();
  const response = await axios.post(`ResidentRelations/`, data);
  return response.data;
}

export async function residentRelationsAttachFile(data: { postId: number; resRelationFile: File }) {
  setDefaults();
  var formData = new FormData();
  formData.append("file", data.resRelationFile);
  const response = await axios.post(`ResidentRelations/AttachFiles/${data.postId}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
}

export async function loadPropertyDocumentsByPropertyId(rmpropid: string) {
  setDefaults();
  const response = await axios.get(`Documents/CommunityDocuments/${rmpropid}`);
  return response.data;
}

// export async function loadRentWithEquityByNameId(nameId: string) {
//   setDefaults();
//   const response = await axios.get(`RentWithEquity/MyRewards/${nameId}`);
//   return response.data;
// }

// export async function postRentWithEquityPdf(rweData: RweInput) {
//   setDefaults();
//   const response = await axios.post("RentWithEquity/PrintMyRewards", rweData);
//   return response.data;
// }

export async function fetchAccountActivity(nameId: string) {
  setDefaults();
  const response = await axios.get(`Documents/MyDocuments/${nameId}`);
  return response.data;
}

export async function downloadDocument(payload: any) {
  setDefaults();
  const response = await axios.get(`Documents/${payload.nameId}/${payload.documentId}`, {
    responseType: "blob",
  });
  return response;
}

export async function loadMyAccountDashboard(nameId: string) {
  setDefaults();
  const response = await axios.get(`MyAccountDashboard/${nameId}`);
  return response.data;
}

export async function getNotifications(nameId: string) {
  setDefaults();
  const response = await axios.get(`UserInfo/Notifications/${nameId}`);
  return response.data;
}

export async function requestPaymentPlan(payPlan: RequestPaymentPlan) {
  setDefaults();
  const response = await axios.post(`UserInfo/RequestPaymentPlan/${payPlan.nameId}`, payPlan);
  return response.data;
}

export async function loadReferFriendAmountPropertyId(rmpropid: string) {
  setDefaults();
  const response = await axios.get(`ReferFriend/ReferralAmount/${rmpropid}`);
  return response.data;
}

export async function postNewReferFriend(newReferFriend: NewReferFriend) {
  setDefaults();
  const response = await axios.post("ReferFriend", newReferFriend);
  return response.data;
}

export async function requestNoticeToVacate(nameId: string) {
  setDefaults();
  const response = await axios.get(`NoticeToVacate/${nameId}`);
  return response.data;
}

export async function requestNoticeSummary(nameId: string) {
  setDefaults();
  const response = await axios.get(`NoticeToVacate/NoticeToVacateSummary/${nameId}`);
  return response.data;
}

export async function postTempENTVRequest(data: SaveExpectedMoveOutDate) {
  setDefaults();
  const response = await axios.post(`NoticeToVacate/SaveExpectedMoveOutDate`, data);
  return response.data;
}

export async function postForwardingAddressRequest(data: ForwardingAddress) {
  setDefaults();
  const response = await axios.post(`NoticeToVacate/SaveNewForwardingAddress`, data);
  return response.data;
}

export async function postNewNoticeRequest(data: NewNoticeToVacate) {
  setDefaults();
  const response = await axios.post(`NoticeToVacate/SaveNoticeToVacate`, data);
  return response.data;
}

export async function postRefundRequest(data: ERefundValue) {
  setDefaults();
  const response = await axios.post(`NoticeToVacate/SaveNewERefundValue`, data);
  return response.data;
}

export async function putCancelNoticeRequest(canceleNTV: CancelRequestData) {
  setDefaults();
  const response = await axios.put(`NoticeToVacate/Cancel`, canceleNTV);
  return response.data;
}

export async function postRequestToChangeMoveOut(data: SaveExpectedMoveOutDate) {
  setDefaults();
  const response = await axios.post(`NoticeToVacate/SaveRequestChangeMoveOutDate`, data);
  return response.data;
}

export async function getCreditReport(nameid: string) {
  setDefaults();
  const response = await axios.get(`CreditReport/${nameid}`);
  return response.data;
}

export async function getCreditReportTest(nameid: string) {
  setDefaults();
  const response = await axios.get(`CreditReport/CreditReportTest/${nameid}`);
  return response.data;
}

export async function getCriminalReport(nameid: string) {
  setDefaults();
  const response = await axios.get(`CreditReport/CriminalReport/${nameid}`);
  return response.data;
}

export async function getDocuSignUrl(nameid: string) {
  setDefaults();
  const response = await axios.get(`UserInfo/DocuSignLink/${nameid}`);
  return response.data;
}
