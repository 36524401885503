import * as React from "react";
import { Typography, WithStyles, withStyles, Grid } from "@material-ui/core";

import referfriendstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(referfriendstyles, globalstyles);

interface ReferFriendProps extends WithStyles<typeof styles> {}

type AllProps = ReferFriendProps;

class ReferFriendHeader extends React.Component<AllProps> {
  render() {
    return (
      <Grid container alignItems="center" style={{ width: "100%" }}>
        <Grid item xs>
          <Typography variant="h1" className={this.props.classes.header}>
            Refer A Friend
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ReferFriendHeader);
