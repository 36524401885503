import { Theme, createStyles } from "@material-ui/core";

const styles = ({ spacing, breakpoints, mixins, transitions }: Theme) =>
  createStyles({
    divider: {
      marginTop: spacing.unit * 1,
      marginBottom: spacing.unit * 1,
      [breakpoints.up("sm")]: {
        marginTop: spacing.unit * 2,
        marginBottom: spacing.unit * 2
      }
    },
    headerlist: {
      [breakpoints.up("sm")]: {
        marginBottom: spacing.unit * 3
      }
    },
    icon: {
      width: "50px"
    },
    row: {
      [breakpoints.up("sm")]: {
        marginBottom: spacing.unit * 2.25
      }
    },
    rowcontents: {
      [breakpoints.up("sm")]: {
        paddingBottom: spacing.unit * 2.25
      }
    }
  });
export default styles;
