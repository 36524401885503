import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import {
  addVehicle,
  deleteVehicle,
  loadContactInfo,
  loadEmergencyInfo,
  loadInsurance,
  loadSpecialAssistance,
  loadTransUnionPaymentReporting,
  loadUserInfo,
  loadVehicles,
  putSpecialAssistance,
  putTransUnionPaymentReporting,
  updateAvatarImage,
  updateContactInfo,
  updateEmergencyInfo,
  updatePassword,
  updateVehicle
} from "../../utils/api";
import { showModalAction } from "../errorhandling/actions";
import * as actions from "./actions";

function* handleFetchUserInfo(action: ReturnType<typeof actions.userInfoFetchRequest>) {
  try {
    const nameId = action.payload.nameId;

    const response = yield call(loadUserInfo, nameId);

    if (response.error) {
      yield put(actions.userInfoFetchError(response.error));
    } else {
      yield put(actions.userInfoFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.userInfoFetchError(err.stack!));
    } else {
      yield put(actions.userInfoFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleUpdateAvatar(action: ReturnType<typeof actions.avatarUpdateRequest>) {
  try {
    if (action.payload.nameId) {
      const response = yield call(updateAvatarImage, action.payload);
      if (response.error) {
        yield put(actions.avatarUpdateError(response.error));
      } else {
        let fetchResp = yield call(loadUserInfo, action.payload.nameId);
        if (fetchResp.error) {
          yield put(actions.userInfoFetchError(fetchResp.error));
        } else {
          yield put(actions.avatarUpdateSuccess(fetchResp));
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.avatarUpdateError(err.stack!));
    } else {
      yield put(actions.avatarUpdateError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleUpdatePassword(action: ReturnType<typeof actions.passwordUpdateRequest>) {
  try {
    if (action.payload.nameId) {
      const response = yield call(updatePassword, action.payload);
      if (response.error) {
        yield put(actions.passwordUpdateError(response.error));
      } else {
        yield put(actions.passwordUpdateSuccess());
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.passwordUpdateError(err.stack!));
    } else {
      //yield put(actions.passwordUpdateError("An unknown error occured."));
      yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
    }
  }
}

function* handleFetchContactInfo(action: ReturnType<typeof actions.contactFetchRequest>) {
  try {
    const nameId = action.payload.nameId;
    const response = yield call(loadContactInfo, nameId);
    if (response.error) {
      yield put(actions.contactFetchError(response.error));
    } else {
      yield put(actions.contactFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.contactFetchError(err.stack!));
    } else {
      yield put(actions.contactFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleUpdateContactInfo(action: ReturnType<typeof actions.contactUpdateRequest>) {
  try {
    if (action.payload.nameId) {
      const response = yield call(updateContactInfo, action.payload);
      if (response.error) {
        yield put(actions.contactUpdateError(response.error));
      } else {
        let fetchResponse = yield call(loadContactInfo, action.payload.nameId);
        if (fetchResponse.error) {
          yield put(actions.contactFetchError(fetchResponse.error));
        } else {
          yield put(actions.contactUpdateSuccess(fetchResponse));
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.contactUpdateError(err.stack!));
    } else {
      yield put(actions.contactUpdateError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchEmergencyInfo(action: ReturnType<typeof actions.emergencyFetchRequest>) {
  try {
    const nameId = action.payload.nameId;

    const response = yield call(loadEmergencyInfo, nameId);

    if (response.error) {
      yield put(actions.emergencyFetchError(response.error));
    } else {
      yield put(actions.emergencyFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.emergencyFetchError(err.stack!));
    } else {
      yield put(actions.emergencyFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleUpdateEmergencyInfo(action: ReturnType<typeof actions.emergencyUpdateRequest>) {
  try {
    if (action.payload.nameId) {
      const response = yield call(updateEmergencyInfo, action.payload);
      if (response.error) {
        yield put(actions.emergencyUpdateError(response.error));
      } else {
        let fetchResponse = yield call(loadEmergencyInfo, action.payload.nameId);
        if (fetchResponse.error) {
          yield put(actions.emergencyFetchError(fetchResponse.error));
        } else {
          yield put(actions.emergencyUpdateSuccess(fetchResponse));
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.contactUpdateError(err.stack!));
    } else {
      yield put(actions.contactUpdateError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchSpecialAssistance(action: ReturnType<typeof actions.specialAssistanceFetchRequest>) {
  try {
    const nameId = action.payload.nameId;

    const response = yield call(loadSpecialAssistance, nameId);

    if (response.error) {
      yield put(actions.specialAssistanceFetchError(response.error));
    } else {
      yield put(actions.specialAssistanceFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.specialAssistanceFetchError(err.stack!));
    } else {
      yield put(actions.specialAssistanceFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleUpdateSpecialAssistance(action: ReturnType<typeof actions.specialAssistanceUpdateRequest>) {
  try {
    const newPost = action.payload;
    if (action.payload.nameId) {
      const response = yield call(putSpecialAssistance, newPost);
      if (response.error) {
        yield put(actions.specialAssistanceUpdateError(response.error));
      } else {
        let fetchResp = yield call(loadSpecialAssistance, action.payload.nameId);
        if (fetchResp.error) {
          yield put(actions.specialAssistanceFetchError(fetchResp.error));
        } else {
          yield put(actions.specialAssistanceUpdateSuccess(fetchResp));
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.specialAssistanceUpdateError(err.stack!));
    } else {
      yield put(actions.specialAssistanceUpdateError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchTransUnionSuppress(action: ReturnType<typeof actions.transUnionSuppressFetchRequest>) {
  try {
    const nameId = action.payload.nameId;

    const response = yield call(loadTransUnionPaymentReporting, nameId);

    if (response.error) {
      yield put(actions.transUnionSuppressFetchError(response.error));
    } else {
      yield put(actions.transUnionSuppressFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.transUnionSuppressFetchError(err.stack!));
    } else {
      yield put(actions.transUnionSuppressFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleUpdateTransUnionSuppress(action: ReturnType<typeof actions.transUnionSuppressUpdateRequest>) {
  try {
    const newPost = action.payload;
    if (action.payload.nameId) {
      const response = yield call(putTransUnionPaymentReporting, newPost);
      if (response.error) {
        yield put(actions.transUnionSuppressUpdateError(response.error));
      } else {
        let fetchResp = yield call(loadTransUnionPaymentReporting, action.payload.nameId);
        if (fetchResp.error) {
          yield put(actions.transUnionSuppressFetchError(fetchResp.error));
        } else {
          yield put(actions.transUnionSuppressUpdateSuccess(fetchResp));
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.transUnionSuppressUpdateError(err.stack!));
    } else {
      yield put(actions.transUnionSuppressUpdateError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchVehicles(action: ReturnType<typeof actions.vehiclesFetchRequest>) {
  try {
    const nameId = action.payload.nameId;

    const response = yield call(loadVehicles, nameId);

    if (response.error) {
      yield put(actions.vehiclesFetchError(response.error));
    } else {
      yield put(actions.vehiclesFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.vehiclesFetchError(err.stack!));
    } else {
      yield put(actions.vehiclesFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}
function* handleAddVehicle(action: ReturnType<typeof actions.vehiclesAddRequest>) {
  try {
    const newPost = action.payload;
    if (action.payload.nameId) {
      const response = yield call(addVehicle, newPost);
      if (response.error) {
        yield put(actions.vehiclesAddError(response.error));
      } else {
        let fetchResp = yield call(loadVehicles, action.payload.nameId);
        if (fetchResp.error) {
          yield put(actions.vehiclesFetchError(fetchResp.error));
        } else {
          yield put(actions.vehiclesAddSuccess(fetchResp));
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.vehiclesAddError(err.stack!));
    } else {
      yield put(actions.vehiclesAddError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}
function* handleDeleteVehicle(action: ReturnType<typeof actions.vehiclesDeleteRequest>) {
  try {
    const newPost = action.payload;
    if (action.payload.nameId) {
      const response = yield call(deleteVehicle, newPost);
      if (response.error) {
        yield put(actions.vehiclesDeleteError(response.error));
      } else {
        let fetchResp = yield call(loadVehicles, action.payload.nameId);
        if (fetchResp.error) {
          yield put(actions.vehiclesFetchError(fetchResp.error));
        } else {
          yield put(actions.vehiclesDeleteSuccess(fetchResp));
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.vehiclesDeleteError(err.stack!));
    } else {
      yield put(actions.vehiclesDeleteError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}
function* handleUpdateVehicle(action: ReturnType<typeof actions.vehiclesUpdateRequest>) {
  try {
    const newPost = action.payload;
    if (action.payload.updatedVehicle.nameId) {
      const response = yield call(updateVehicle, newPost);
      if (response.error) {
        yield put(actions.vehiclesUpdateError(response.error));
      } else {
        let fetchResp = yield call(loadVehicles, action.payload.updatedVehicle.nameId);
        if (fetchResp.error) {
          yield put(actions.vehiclesFetchError(fetchResp.error));
        } else {
          yield put(actions.vehiclesUpdateSuccess(fetchResp));
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.vehiclesUpdateError(err.stack!));
    } else {
      yield put(actions.vehiclesUpdateError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchInsurance(action: ReturnType<typeof actions.insuranceFetchRequest>) {
  try {
    const nameId = action.payload.nameId;

    const response = yield call(loadInsurance, nameId);

    if (response.error) {
      yield put(actions.insuranceFetchError(response.error));
    } else {
      yield put(actions.insuranceFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.insuranceFetchError(err.stack!));
    } else {
      yield put(actions.insuranceFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(getType(actions.userInfoFetchRequest), handleFetchUserInfo);
  yield takeEvery(getType(actions.avatarUpdateRequest), handleUpdateAvatar);
  yield takeEvery(getType(actions.passwordUpdateRequest), handleUpdatePassword);

  yield takeEvery(getType(actions.contactFetchRequest), handleFetchContactInfo);
  yield takeEvery(getType(actions.contactUpdateRequest), handleUpdateContactInfo);

  yield takeEvery(getType(actions.emergencyFetchRequest), handleFetchEmergencyInfo);
  yield takeEvery(getType(actions.emergencyUpdateRequest), handleUpdateEmergencyInfo);

  yield takeEvery(getType(actions.specialAssistanceFetchRequest), handleFetchSpecialAssistance);
  yield takeEvery(getType(actions.specialAssistanceUpdateRequest), handleUpdateSpecialAssistance);

  yield takeEvery(getType(actions.transUnionSuppressFetchRequest), handleFetchTransUnionSuppress);
  yield takeEvery(getType(actions.transUnionSuppressUpdateRequest), handleUpdateTransUnionSuppress);

  yield takeEvery(getType(actions.vehiclesFetchRequest), handleFetchVehicles);
  yield takeEvery(getType(actions.vehiclesUpdateRequest), handleUpdateVehicle);
  yield takeEvery(getType(actions.vehiclesAddRequest), handleAddVehicle);
  yield takeEvery(getType(actions.vehiclesDeleteRequest), handleDeleteVehicle);

  yield takeEvery(getType(actions.insuranceFetchRequest), handleFetchInsurance);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* statementSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default statementSaga;
