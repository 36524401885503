import * as React from "react";
import { Paper } from "@material-ui/core";

export interface ServiceReqFormBreadcrumbsProps {}

const ServiceReqFormBreadcrumbs: React.SFC<ServiceReqFormBreadcrumbsProps> = props => {
  return <Paper />;
};

export default ServiceReqFormBreadcrumbs;
