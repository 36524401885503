import * as React from "react";
import {
  Button,
  Typography,
  Divider,
  withStyles,
  WithStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";

import serviceproviderstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import { Check, ExpandMore } from "@material-ui/icons";
import WelcomHomeOrientation from "./WelcomeHomeOrientation";
import Parser from "html-react-parser";
import { RouteComponentProps } from "react-router";
import { MoveInChecklistChargesItem } from "../models";

const styles = combinestyles(serviceproviderstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  markComplete?: Function;
  itemType?: string;
  disabled: boolean;
  complete: boolean;
  title: string;
  description: string;
  disclaimer?: string;
  buttonText: string;
  path: string;
  showMarkComplete: boolean;
  leaseDate: Date;
  documentUpload: boolean;
  documentUploadCTA: string;
  chargesSummary?: MoveInChecklistChargesItem[];
}

type AllProps = Props & RouteComponentProps<any>;

class MoveInChecklistRow extends React.Component<AllProps> {
  render() {
    const {
      markComplete,
      itemType,
      disabled,
      complete,
      title,
      description,
      disclaimer,
      buttonText,
      path,
      showMarkComplete,
      leaseDate,
      documentUpload,
      documentUploadCTA,
      chargesSummary,
    } = this.props;

    const debug = false;

    let buttonRender = (
      <Button
        variant="contained"
        disabled={debug ? false : disabled}
        color="primary"
        href={path} // "/docusignlease"
        target={path && path.indexOf("http") > -1 ? "_blank" : "_self"}
      >
        {buttonText}
      </Button>
    );

    switch (itemType) {
      case "signlease":
        if (path === "") {
          buttonRender = <></>;
        }
        break;
      case "P9": // welcome home orientation item type
        buttonRender = <WelcomHomeOrientation disabled={debug ? false : disabled} leaseDate={leaseDate} />;
        break;
    }

    // Use for Insurance only (P4)
    let UrlInsuranceUpload = "/uploads?cta=" + documentUploadCTA;

    return (
      <React.Fragment>
        <Divider />
        <ExpansionPanel
          defaultExpanded={!complete}
          style={{ boxShadow: "none", paddingTop: "1rem", paddingBottom: "1rem" }}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMore />} style={{ paddingLeft: "0" }}>
            <Typography variant="h4" style={complete ? { color: "#60b837" } : {}}>
              <Check style={{ marginRight: "10px", color: complete ? "#60b837" : "#d9d9d9" }} />
              {title}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ display: "block", paddingLeft: "34px", paddingTop: "0" }}>
            <Typography gutterBottom style={{ paddingBottom: "1rem" }}>
              {Parser(description)}
            </Typography>
            {chargesSummary !== undefined && itemType === "P2" && (
              <React.Fragment>
                <table style={{ paddingBottom: "1.5em" }}>
                  <tr>
                    <td>
                      <Typography>Description</Typography>
                    </td>
                    <td>
                      <Typography>Amount</Typography>
                    </td>
                  </tr>
                  {chargesSummary.map((x) => (
                    <tr className={this.props.classes.chargesRow}>
                      <td style={{ paddingRight: "1.5em" }}>
                        <Typography>{x.chargesDescription}</Typography>
                      </td>
                      <td>
                        <Typography>
                          {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(x.amount)}
                        </Typography>
                      </td>
                    </tr>
                  ))}
                </table>
              </React.Fragment>
            )}
            {(itemType !== "P9" || (!complete && itemType === "P9")) && (
              <React.Fragment>
                {buttonRender}
                {documentUpload && (
                  <Button
                    variant="contained"
                    style={{ marginLeft: "10px" }}
                    disabled={debug ? false : disabled}
                    onClick={() => {
                      //this.props.history.push("/uploads", { cta: documentUploadCTA });
                      this.props.history.push(UrlInsuranceUpload);
                    }}
                  >
                    Document Upload
                  </Button>
                )}
                {showMarkComplete && (
                  <Button
                    disabled={debug ? false : disabled}
                    variant="contained"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      if (markComplete) {
                        markComplete(itemType);
                      }
                    }}
                  >
                    {complete ? "Reopen" : "Mark Complete"}
                  </Button>
                )}
                <Typography style={{ marginTop: "1rem", lineHeight: "1.1rem" }}>
                  <small>{disclaimer}</small>
                </Typography>
              </React.Fragment>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MoveInChecklistRow);
