import "react-datepicker/dist/react-datepicker.css";

import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";

import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { RpActivity } from "../../shared/models";
import { UserInfoItem } from "../../userInfo/models";
import { NoticeToVacate } from "../models";
import style from "../styles";
import moment from "moment";

const styles = combinestyles(style, globalstyles);

interface Props extends WithStyles<typeof styles> {
  noticeInfo?: NoticeToVacate;
  handlePresentForInspectionFlag: (value: string) => void;
  handle48HourWaiverFlag: (value: boolean) => void;
  handlePetFlag: (value: boolean) => void;
  handlePTEFlag: (value: boolean) => void;
  handleAlarmFlag: (value: boolean) => void;
  handleAlarmDetails: (value: string) => void;
  handleInspectionDate: (value: Date) => void;
  handleMoveOutClearFlag: (value: boolean) => void;
  handleMoveOutText: () => void;
}
interface PropsFromState {
  user?: UserInfoItem;
}
interface PropsFromDispatch {
  logActivity: typeof logActivity;
}
type MoveOutState = {
  presentForInspection: string;
  presentForInspectionSelected: boolean;
  permissionToEnter: boolean;
  havePet: boolean;
  haveAlarm: boolean;
  inspectionDate?: Date;
  waiver48Hour: boolean;
  waiver48HourSelected: boolean;
  alarmDetails: string;
};
type AllProps = PropsFromState & PropsFromDispatch & Props;

class MoveOutTile extends Component<AllProps> {
  state: Readonly<MoveOutState> = {
    presentForInspection: "",
    presentForInspectionSelected: false,
    permissionToEnter: false,
    havePet: false,
    haveAlarm: false,
    inspectionDate: undefined,
    waiver48Hour: false,
    waiver48HourSelected: false,
    alarmDetails: ""
  };

  componentDidMount() {
    const petExist = this.props.noticeInfo!.petExist;
    if (petExist === true) {
      this.setState({ havePet: true });
    }
    this.props.handlePetFlag(petExist);
    this.props.handlePTEFlag(false);
    this.props.handleAlarmFlag(false);
    if (String(this.props.noticeInfo!.isCalifornia) === "N") {
      this.props.handlePresentForInspectionFlag("N");
      this.props.handleMoveOutClearFlag(true);
    }
  }

  handleDateChange = (date: Date) => {
    date.setHours(0, 0, 0, 0);
    this.setState({ inspectionDate: date });
    this.props.handleInspectionDate(date);
  };

  handleDateChangeRaw = (e: any) => {
    //prevent keyboard entry.
    e.preventDefault();
  };

  handleRadioChange = (e: any) => {
    //prevent keyboard entry.
    e.preventDefault();
    switch (e.target.name) {
      case "radioPresentForInspection":
        switch (e.target.value) {
          case "Yes":
            this.setState({ presentForInspection: "Y" });
            this.props.handlePresentForInspectionFlag("Y");
            break;
          case "No":
            this.setState({ presentForInspection: "N" });
            this.props.handlePresentForInspectionFlag("N");
            break;
          case "X":
            this.setState({ presentForInspection: "X" });
            this.props.handlePresentForInspectionFlag("X");
            break;
        }
        this.props.handleMoveOutText();
        this.setState({ presentForInspectionSelected: true }, () => {
          if (this.state.presentForInspectionSelected && this.state.waiver48HourSelected) {
            this.props.handleMoveOutClearFlag(true);
          }
        });
        if (this.state.presentForInspectionSelected && this.state.waiver48HourSelected) {
          this.props.handleMoveOutClearFlag(true);
        }
        break;
      case "radio48HourWaiver":
        if (e.target.value === "Yes") {
          this.setState({ waiver48Hour: true });
        } else {
          this.setState({ waiver48Hour: false });
        }
        this.setState({ waiver48HourSelected: true }, () => {
          if (this.state.presentForInspectionSelected && this.state.waiver48HourSelected) {
            this.props.handleMoveOutClearFlag(true);
          }
        });
        this.props.handle48HourWaiverFlag(e.target.value);

        break;
    }
  };
  toggleChange = (e: any) => {
    if (!e.target) {
      return;
    }

    switch (e.target.name) {
      case "togglePresentForInspection":
        if (e.target.checked) {
          this.setState({ presentForInspection: "Y" });
          this.props.handlePresentForInspectionFlag("Y");
        } else {
          this.setState({ presentForInspection: "N" });
          this.props.handlePresentForInspectionFlag("N");
        }
        break;
      case "togglePTE":
        this.setState({ permissionToEnter: e.target.checked });
        this.props.handlePTEFlag(e.target.checked);
        break;
      case "togglePet":
        this.setState({ havePet: e.target.checked });
        this.props.handlePetFlag(e.target.checked);
        break;
      case "toggleAlarm":
        this.setState({ haveAlarm: e.target.checked });
        this.props.handleAlarmFlag(e.target.checked);
        break;
    }
  };

  changeText = (e: any) => {
    if (!e.target) {
      return;
    }

    this.setState({ alarmDetails: e.target.value });

    this.props.handleAlarmDetails(e.target.value);
  };

  isWeekday(date: Date) {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  }

  render() {
    const { classes } = this.props;
    const preMoveOutOpeningText = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A16";
    });
    const preMoveOutOpening1 = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A17";
    });

    const preMoveOutOpening2 = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A18";
    });

    const preMoveOutOpening3 = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A30";
    });

    const preMoveOutAccessQuestion = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A20";
    });

    const permissionText = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A21";
    });

    const petText = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A22";
    });

    const alarmText = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A23";
    });

    const preferredDateCopy = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A24";
    });

    const preferredDateAddlCopy = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A25";
    });

    const waiverCopy = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A26";
    });

    const waiverOption1 = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A27";
    });

    const waiverOption2 = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A28";
    });

    const ifPresent = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A32";
    });

    return (
      <Paper className={[classes.paper].join(" ")}>
        <form>
          <Grid container direction="row" alignItems="center" justify="space-between" style={{ marginBottom: "1rem" }}>
            <Grid item xs={12}>
              <Typography variant="h3" style={{ marginBottom: "1rem" }} gutterBottom>
                Pre Move-Out Inspection
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "1rem" }} gutterBottom>
                {preMoveOutOpeningText!.copyLanguage}
              </Typography>
              <Grid item xs={12}>
                {String(this.props.noticeInfo!.isCalifornia) === "N" && (
                  <Grid item xs={12} style={{ marginBottom: "1.5rem" }}>
                    <div style={{ marginLeft: "-14px" }}>
                      <Switch
                        id="togglePresentForInspection"
                        name="togglePresentForInspection"
                        color="primary"
                        checked={this.state.presentForInspection}
                        onChange={(e) => this.toggleChange(e)}
                      />
                      <Typography style={{ display: "inline-block" }}>{ifPresent!.copyLanguage}</Typography>
                    </div>
                  </Grid>
                )}
                {String(this.props.noticeInfo!.isCalifornia) === "Y" && (
                  <Grid item xs={12} style={{ marginBottom: "1.5rem" }}>
                    <Typography style={{ display: "inline-block" }}>
                      Would you like to be present during the pre move-out inspection?{" "}
                      <Typography style={{ display: "inline-block", color: "red" }}>*</Typography>
                    </Typography>
                    <RadioGroup
                      aria-label="radioPresentForInspection"
                      name="radioPresentForInspection"
                      onChange={this.handleRadioChange}
                    >
                      <FormControlLabel
                        value="X"
                        control={<Radio color="primary" />}
                        label={preMoveOutOpening3!.copyLanguage}
                      />
                      <FormControlLabel
                        value="Yes"
                        control={<Radio color="primary" />}
                        label={preMoveOutOpening1!.copyLanguage}
                        color="primary"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio color="primary" />}
                        label={preMoveOutOpening2!.copyLanguage}
                      />
                    </RadioGroup>
                  </Grid>
                )}
                <Grid item xs={12} style={{ marginBottom: "1.5rem" }}>
                  <Typography style={{ marginBottom: "1rem" }}>{preferredDateCopy!.copyLanguage}</Typography>
                  <Grid container direction="row" alignItems="center" spacing={8}>
                    <Grid item>
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        placeholderText="--/--/----"
                        selected={moment(this.state.inspectionDate).toDate()}
                        onChange={this.handleDateChange}
                        onChangeRaw={this.handleDateChangeRaw}
                        minDate={new Date(Date.now())}
                        filterDate={this.isWeekday}
                        name="moveOutDate"
                        autoComplete="off"
                        popperPlacement="bottom-start"
                        popperModifiers={{
                          flip: {
                            enabled: false,
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                          },
                        }}
                        className={this.props.classes.datepicker}
                      />
                      <EventIcon style={{ color: "rgba(0, 0, 0, 0.42)" }} />
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{
                          display: "inline-block",
                          fontSize: ".875rem",
                          fontStyle: "italic",
                          marginBottom: "1em",
                        }}
                      >
                        {preferredDateAddlCopy!.copyLanguage}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {String(this.props.noticeInfo!.isCalifornia) === "Y" && (
                <Grid item xs={12} style={{ marginBottom: "1.5rem" }}>
                  <Typography style={{ display: "inline-block", marginTop: "1em", marginBottom: "1em" }}>
                    {waiverCopy!.copyLanguage}{" "}
                    <Typography style={{ display: "inline-block", color: "red" }}>*</Typography>
                  </Typography>
                  <Grid item xs={12}>
                    <RadioGroup
                      aria-label="radio48HourWaiver"
                      name="radio48HourWaiver"
                      onChange={this.handleRadioChange}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio color="primary" />}
                        style={{ marginBottom: "1em" }}
                        label={waiverOption1!.copyLanguage}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio color="primary" />}
                        style={{ marginBottom: "1em" }}
                        label={waiverOption2!.copyLanguage}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              )}
              <Typography style={{ display: "inline-block", marginBottom: "1em" }}>
                <br />
                {preMoveOutAccessQuestion!.copyLanguage}
              </Typography>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      id="togglePTE"
                      name="togglePTE"
                      color="primary"
                      checked={this.state.permissionToEnter}
                      onChange={(e) => this.toggleChange(e)}
                    />
                  }
                  label={permissionText!.copyLanguage}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      id="togglePet"
                      name="togglePet"
                      color="primary"
                      checked={this.state.havePet}
                      onChange={(e) => this.toggleChange(e)}
                    />
                  }
                  label={petText!.copyLanguage}
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "1.5rem" }}>
                <FormControlLabel
                  control={
                    <Switch
                      id="toggleAlarm"
                      name="toggleAlarm"
                      color="primary"
                      checked={this.state.haveAlarm}
                      onChange={(e) => this.toggleChange(e)}
                    />
                  }
                  label={alarmText!.copyLanguage}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ display: "inline-block", fontSize: ".875rem" }}>
                  Please provide additional details about your alarm
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="alarmDetails"
                  name="alarmDetails"
                  fullWidth
                  disabled={!this.state.haveAlarm}
                  onChange={(e) => this.changeText(e)}
                  className={classes.textField}
                  multiline
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  logActivity: (activity: RpActivity) => dispatch(logActivity(activity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MoveOutTile));
