import { call, fork, all, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { getCriminalReport } from "../../utils/api";
import { showModalAction } from "../errorhandling/actions";
import * as actions from "./actions";

function* handleFetchCriminalReport(action: ReturnType<typeof actions.fetchAsyncAction.request>): any {
  try {
    const nameId = action.payload;

    const response = yield call(getCriminalReport, nameId);

    if (response.error) {
      yield put(actions.fetchAsyncAction.failure(response.error));
    } else {
      yield put(actions.fetchAsyncAction.success(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.fetchAsyncAction.failure(err));
    } else {
      yield put(actions.fetchAsyncAction.failure(new Error("An unknown error occured.")));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(getType(actions.fetchAsyncAction.request), handleFetchCriminalReport);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* criminalReportSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default criminalReportSaga; // must be registered in ../../store/root-saga.ts
