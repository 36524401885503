import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { UserInfoItem } from "../../userInfo/models";
import { rentablePropertyRequest, requestRentableRequest } from "../actions";
import { RentableItemRequest, RentableItems, RpActivity } from "../models";
import rentableitemstyles from "../styles";

const styles = combinestyles(rentableitemstyles, globalstyles);

export interface PropsFromState {
  user?: UserInfoItem;
  propRentableList: RentableItems[];
}

interface PropsFromDispatch {
  rentablePropertyRequest: typeof rentablePropertyRequest;
  requestRentableRequest: typeof requestRentableRequest;
  logActivity: typeof logActivity;
}

interface RentableItemsListProps extends WithStyles<typeof styles> {}

type State = {
  decription: string;
  rate: number;
  open: boolean;
};

type AllProps = PropsFromState & PropsFromDispatch & RentableItemsListProps;

class RentableList extends React.Component<AllProps, State> {
  state: State = {
    decription: "",
    rate: 0,
    open: false,
  };

  handleClickOpen = (rentableItem: RentableItems) => {
    const valuesIn: RentableItemRequest = {
      nameId: this.props.user!.nameId,
      description: rentableItem.description,
    };

    //Send Email to property
    this.props.requestRentableRequest(valuesIn);

    const newActivity: RpActivity = {
      actcode: "RR",
      note: "Resident requested: " + rentableItem.description + "-" + rentableItem.rate + " Per Month",
      appsource: "WEBRP",
    };
    this.props.logActivity(newActivity);

    this.setState({
      decription: rentableItem.description,
      rate: rentableItem.rate,
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  renderItem(rentable: RentableItems) {
    return (
      <Grid item xs={6} sm={4} spacing={16} key={rentable.description}>
        <Card className={this.props.classes.card}>
          <Grid container direction="row" justify="space-around" spacing={16}>
            <Grid item>
              <img
                alt={`${rentable.description}`}
                src={this.GetRentableImageUrl(rentable.description)}
                className={this.props.classes.centerElement}
              />
            </Grid>
          </Grid>
          <Grid item style={{ minHeight: "4rem" }}>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              {rentable.description}
            </Typography>
            {Number(rentable.rate) > 0 && (
              <Typography style={{ textAlign: "center" }}>${rentable.rate} Per Month</Typography>
            )}
          </Grid>
          <Grid container direction="row" justify="center" spacing={16}>
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px", marginTop: "10px" }}
                onClick={(e: any) => this.handleClickOpen(rentable)}
              >
                Request
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }

  render() {
    const { propRentableList } = this.props;
    this.renderItem = this.renderItem.bind(this);
    return (
      <div>
        <Grid container spacing={16}>
          {propRentableList.map(this.renderItem)}
        </Grid>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="h2" style={{ textAlign: "center", marginBottom: "10px" }}>
                <img
                  alt="Check icon"
                  src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_30/Content/portal/uielements/check.png"
                />
              </Typography>
              <Typography variant="h2" style={{ textAlign: "center", marginBottom: "10px" }}>
                Request Received!
              </Typography>
              <Typography variant="h5" style={{ textAlign: "center" }}>
                {this.state.decription}
              </Typography>
              <Typography variant="h5" style={{ textAlign: "center", marginBottom: "20px" }}>
                ${this.state.rate} Per month
              </Typography>
              <Typography style={{ textAlign: "center", marginBottom: "10px" }}>
                A member of our management team will contact you soon.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" variant="contained" autoFocus>
              Got it!
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  private GetRentableImageUrl = (desc: string) => {
    let res = "";

    if (desc.toLowerCase().indexOf("park") > -1 || desc.toLowerCase().indexOf("car") > -1) {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/amenityreservation/guestparking.png`;
    } else if (desc.toLowerCase().indexOf("garage") > -1) {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/rentableitems/garageparking.png`;
    } else if (desc.toLowerCase().indexOf("stor") > -1) {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/rentableitems/smallstorage.png`;
    } else {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/amenityreservation/businesscenter.png`;
    }

    return res;
  };
}

const mapStateToProps = ({ rentable, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  propRentableList: rentable.rentablePropertyList.data,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  rentablePropertyRequest: (propid: string) => dispatch(rentablePropertyRequest(propid)),
  requestRentableRequest: (req: RentableItemRequest) => dispatch(requestRentableRequest(req)),
  logActivity: (act: RpActivity) => dispatch(logActivity(act)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RentableList));
