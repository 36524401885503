import {
  Typography,
  WithStyles,
  withStyles,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
  Button,
} from "@material-ui/core";
import * as React from "react";

import { StatementDetails } from "../models";
import BillingCycle from "./StatementBillingCycle";

import statementstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(statementstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  charges: StatementDetails[];
  vacateDate: Date;
  onMore: any;
  numStatements: number;
  maxNumStatements: number;
}
type AllProps = Props;

class StatementDetail extends React.Component<AllProps> {
  render() {
    const { onMore, charges, vacateDate, numStatements, maxNumStatements } = this.props;

    const dates = [...new Set(charges.map((item) => item.tranDate))];

    if (dates.length > 0) {
      if (vacateDate !== null && vacateDate !== undefined) {
        if (dates.indexOf(vacateDate) === -1) {
          dates.push(vacateDate);
        }
      }

      dates.sort((a: Date, b: Date) => {
        return new Date(b).getTime() - new Date(a).getTime();
      });
    }

    return (
      <Paper className={this.props.classes.paper}>
        <Typography variant="h3" gutterBottom>
          Statement Detail
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={this.props.classes.tableCell}>Activity</TableCell>
              <Hidden xsDown>
                <TableCell>Description</TableCell>
              </Hidden>
              <TableCell className={this.props.classes.tableCell}>Amount</TableCell>
              <TableCell className={this.props.classes.tableCell}>Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dates.map((dt) => (
              <React.Fragment key={dt === undefined ? "0" : dt.toLocaleString()}>
                <TableRow className={this.props.classes.tableRow}>
                  <TableCell className={this.props.classes.tableCell} colSpan={4}>
                    {formatDate(dt)}
                  </TableCell>
                </TableRow>
                {dt === vacateDate ? (
                  <TableRow>
                    <TableCell className={this.props.classes.tableCell} />
                    <TableCell className={this.props.classes.tableCell}>MOVE OUT</TableCell>
                  </TableRow>
                ) : undefined}
                {charges
                  .filter((c) => c.tranDate === dt)
                  .map((charge) => (
                    <TableRow key={charge.tranId}>
                      <TableCell className={this.props.classes.tableCell}>
                        {charge.tranType}
                        {charge.tranType.substring(0, 4) === "RUBS" &&
                          charge.serviceFrom !== null &&
                          charge.serviceFrom !== undefined && (
                            <BillingCycle
                              startDate={formatDate(charge.serviceFrom)}
                              endDate={formatDate(charge.serviceTo)}
                            />
                          )}
                        <Hidden smUp>
                          <Typography variant="caption">{charge.tranDesc}</Typography>
                        </Hidden>
                      </TableCell>
                      <Hidden xsDown>
                        <TableCell className={this.props.classes.tableCell}>{charge.tranDesc}</TableCell>
                      </Hidden>
                      <TableCell className={this.props.classes.tableCell}>
                        {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                          charge.tranAmountDisplay!
                        )}
                      </TableCell>
                      <TableCell className={this.props.classes.tableCell}>
                        {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(charge.tranBlnc)}
                      </TableCell>
                    </TableRow>
                  ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        <div style={{ textAlign: "center" }}>
          {numStatements < maxNumStatements && (
            <Button onClick={() => onMore()} color="primary" aria-label="More">
              MORE
            </Button>
          )}
        </div>
      </Paper>
    );
  }
}

function formatDate(rawDate?: Date) {
  let result = "";

  if (rawDate !== undefined) {
    var ua = navigator.userAgent;
    var isIE = ua.indexOf("MSIE") > -1 || ua.indexOf("Trident/") > -1;

    if (isIE) {
      const strDateY = rawDate.toString().substring(0, 4);
      const strDateM = rawDate.toString().substring(5, 7);
      const strDateD = rawDate.toString().substring(8, 10);
      result = strDateM + "/" + strDateD + "/" + strDateY;
    } else {
      const utcDate = new Date(rawDate);
      const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000).toLocaleString();
      result = new Intl.DateTimeFormat("en-US").format(Date.parse(localDate));
    }
  }

  return result;
}

export default withStyles(styles)(StatementDetail);
