import { Button, DialogActions, DialogContent, TextField, Typography } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { FormikProps } from "formik";
import * as React from "react";
import Moment from "react-moment";
import { InputFormState } from "./AmenityHistoryList";

import rentableitemstyles from "../styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(rentableitemstyles, globalstyles);

interface MyProps {
  onClose: any;
}

type AllProps = MyProps & WithStyles<typeof styles> & FormikProps<InputFormState>;

const AmenityCancel = (props: AllProps) => {
  const {
    values: { id, nameId, name, reserveDate, timeStart, timeEnd, cancelReason },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched,
    classes,
    onClose
  } = props;

  const change = (field: any, e: any) => {
    e.persist();
    handleChange(e);
    // tslint:disable-next-line:no-console
    setFieldTouched(field, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Typography>Are you sure you want to cancel this amenity reservation?</Typography>
        <br />
        <Typography>{name}</Typography>
        <Typography>
          Date: <Moment format="MM/DD/YY">{reserveDate}</Moment>
        </Typography>
        <Typography>
          Time: {timeStart} - {timeEnd}
        </Typography>

        <TextField
          id="cancelReason"
          name="cancelReason"
          label="Cancel Reason*"
          value={cancelReason}
          helperText={touched.cancelReason ? errors.cancelReason : ""}
          error={touched.cancelReason && Boolean(errors.cancelReason)}
          onChange={change.bind(null, "cancelReason")}
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            step: 300 // 5 min
          }}
          className={classes.textField}
          fullWidth
        />
      </DialogContent>

      <input id="reservId" name="reservId" type="hidden" value={id} />
      <input id="nameId" name="nameId" type="hidden" value={nameId} />
      <DialogActions>
        <Button type="submit" color="primary" variant="contained" disabled={!isValid}>
          Yes
        </Button>
        <Button type="reset" color="default" variant="contained" onClick={onClose}>
          No
        </Button>
      </DialogActions>
    </form>
  );
};

export default withStyles(styles)(AmenityCancel);
