import * as React from "react";
import { connect } from "react-redux";

import { RootState } from "../../../store/root-reducer";

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  withMobileDialog,
  Grid,
  Typography,
  withStyles,
  WithStyles,
  Divider
} from "@material-ui/core";
import { InjectedProps } from "@material-ui/core/withMobileDialog";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import CheckmarkIcon from "@material-ui/icons/CheckCircle";
import MarkUnreadIcon from "@material-ui/icons/MarkunreadMailbox";

import { ServiceRequest, ServiceRequestType, ServiceRequestCategory } from "../models";
import { hideDetails } from "../actions";
import { getServiceRequestType, getServiceRequestCategory } from "../selectors";
import Moment from "react-moment";

import servicestyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(servicestyles, globalstyles);

interface ServiceReqDetailsProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
}

interface PropsFromState {
  item: ServiceRequest;
  open: boolean;
  serviceRequestType: ServiceRequestType;
  serviceRequestCategory: ServiceRequestCategory;
}

interface PropsFromDispatch {
  hideDetails: () => any;
}

type AllProps = PropsFromState & PropsFromDispatch & InjectedProps & ServiceReqDetailsProps;

class ServiceReqDetails extends React.Component<AllProps> {
  render() {
    const { fullScreen, open, item, serviceRequestType, serviceRequestCategory } = this.props;

    if (!item) {
      return <div />;
    }

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={this.props.hideDetails}
          aria-labelledby="responsive-dialog-title"
          fullWidth
          maxWidth="md"
          classes={{ paper: this.props.classes.paper }}
        >
          <Grid container spacing={24} alignItems="center">
            <Grid item xs={10}>
              <DialogTitle id="responsive-dialog-title">Your Service Request</DialogTitle>
            </Grid>
            <Grid item xs={2}>
              <DialogActions>
                <Button onClick={this.props.hideDetails} color="primary" autoFocus>
                  <CancelIcon />
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container spacing={24}>
              <Grid item sm={5}>
                <Typography variant="h3" className={this.props.classes.request}>
                  {serviceRequestType.description}
                </Typography>
                <Divider className={this.props.classes.divider} />
                <Typography variant="subtitle2">Category</Typography>
                <Typography className={this.props.classes.category}>
                  {serviceRequestCategory.category}: {serviceRequestCategory.subCategory}
                </Typography>
                <Typography variant="subtitle2">Special Instructions</Typography>
                <Typography className={this.props.classes.category}>{item.comments}</Typography>
                {/* <Divider className={this.props.classes.divider} />
                <Typography>Do you have any pets? {item.anyPets}</Typography>
                <Divider className={this.props.classes.divider} />
                <Typography>Do you have an alarm? {item.alarm}</Typography> */}
              </Grid>
              <Grid item sm={7}>
                <Typography variant="subtitle2" className={this.props.classes.request}>
                  Timeline
                </Typography>

                <Grid container direction="row" spacing={24}>
                  {item.status === "Closed" && (
                    <Grid item xs={12}>
                      <Grid container direction="row">
                        <Grid item>
                          <CheckmarkIcon />
                          &nbsp;
                        </Grid>
                        <Grid item>
                          <Typography>
                            <Moment date={item.dateCompleted} format="MM/DD/YYYY" /> - Completed
                          </Typography>
                          <Typography>{item.contractorName} marked this project as complete.</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid container direction="row">
                      <Grid item>
                        <MarkUnreadIcon />
                        &nbsp;
                      </Grid>
                      <Grid item>
                        <Typography>
                          <Moment date={item.dateReported} format="MM/DD/YYYY" /> - Received
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ servicereqs }: RootState) => ({
  item: servicereqs.serviceRequestDetails.selectedItem,
  open: servicereqs.serviceRequestDetails.detailsVisible,
  serviceRequestType: getServiceRequestType(servicereqs),
  serviceRequestCategory: getServiceRequestCategory(servicereqs)
});

const mapDispatchToProps = {
  hideDetails: () => hideDetails()
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withMobileDialog()(ServiceReqDetails)));
