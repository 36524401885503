import { createAction } from "typesafe-actions";

import { PaymentData, PaymentInputMppData, PaymentUrlDataResult } from "./models";

const PAYMENT_FETCH_REQUEST = "payment/FETCH_REQUEST";
const PAYMENT_FETCH_SUCCESS = "payment/FETCH_SUCCESS";
const PAYMENT_FETCH_ERROR = "payment/FETCH_ERROR";
export const paymentFetchRequest = createAction(PAYMENT_FETCH_REQUEST)<string>();
export const paymentFetchSuccess = createAction(PAYMENT_FETCH_SUCCESS)<PaymentData>();
export const paymentFetchError = createAction(PAYMENT_FETCH_ERROR)<string>();

const INSERT_PAYMENT_REQUEST = "payment/insert/INSERT_PAYMENT_REQUEST";
const INSERT_PAYMENT_SUCCESS = "payment/insert/INSERT_PAYMENT_SUCCESS";
const INSERT_PAYMENT_ERROR = "payment/insert/INSERT_PAYMENT_ERROR";
export const insertPaymentRequest = createAction(INSERT_PAYMENT_REQUEST)<PaymentInputMppData>();
export const insertPaymentSuccess = createAction(INSERT_PAYMENT_SUCCESS)<PaymentUrlDataResult>();
export const insertPaymentError = createAction(INSERT_PAYMENT_ERROR)<string>();

const INSERT_AUTOPAYMENT_REQUEST = "payment/insert/INSERT_AUTOPAYMENT_REQUEST";
const INSERT_AUTOPAYMENT_SUCCESS = "payment/insert/INSERT_AUTOPAYMENT_SUCCESS";
const INSERT_AUTOPAYMENT_ERROR = "payment/insert/INSERT_AUTOPAYMENT_ERROR";
export const insertAutoPaymentRequest = createAction(INSERT_AUTOPAYMENT_REQUEST)<PaymentInputMppData>();
export const insertAutoPaymentSuccess = createAction(INSERT_AUTOPAYMENT_SUCCESS)<PaymentUrlDataResult>();
export const insertAutoPaymentError = createAction(INSERT_AUTOPAYMENT_ERROR)<string>();
