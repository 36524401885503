import * as React from "react";
import { Typography, Divider, withStyles, WithStyles, ListItem, ListItemIcon } from "@material-ui/core";

import serviceproviderstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import { ChevronRight } from "@material-ui/icons";
import { ContactUsSubItem } from "../models";

const styles = combinestyles(serviceproviderstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  description: string;
  clickHandler: Function;
  contactType: string;
  destination?: string;
  subItems: ContactUsSubItem[];
  icon: string;
  index: number;
  email: string;
}

class ContactUsRow extends React.Component<Props> {
  render() {
    const { clickHandler, contactType, description, destination, subItems, icon, index, email } = this.props;

    return (
      <div>
        <ListItem
          key={index}
          style={{ padding: "1rem 3rem 1rem 0", cursor: "pointer" }}
          onClick={() => {
            clickHandler(destination, contactType, email, description, subItems);
          }}
        >
          <img alt="null" src={icon} width={30} style={{ marginRight: "15px" }} />
          <Typography style={{ lineHeight: "1.2rem" }}>{description}</Typography>
          <ListItemIcon style={{ position: "absolute", right: 0, color: "#2978b8" }}>
            <ChevronRight />
          </ListItemIcon>
        </ListItem>
        <Divider />
      </div>
    );
  }
}

export default withStyles(styles)(ContactUsRow);
