import { all, fork } from "redux-saga/effects";

import amenitySaga from "../features/amenity/saga";
import contactSaga from "../features/contact/saga";
import documentsSaga from "../features/documents/saga";
import noticeToVacateSaga from "../features/entv/saga";
import formerResidentSaga from "../features/formerresident/saga";
import guestSaga from "../features/guest/saga";
import myaccountdashboardSaga from "../features/myaccount/saga";
import leaseSaga from "../features/mylease/saga";
import profileSaga from "../features/myprofile/saga";
import statementSaga from "../features/mystatement/saga";
import newsSaga from "../features/news/saga";
import notificationsSaga from "../features/notifications/saga";
import paymentSaga from "../features/payment/saga";
import propertyDocumentsSaga from "../features/propertydocuments/saga";
import referFriendSaga from "../features/referfriend/saga";
import renewalSaga from "../features/renewal/saga";
import rentableSaga from "../features/rentable/saga";
import selectAccountSaga from "../features/selectAccount/saga";
import svcProviderSaga from "../features/serviceproviders/saga";
import servicereqsSaga from "../features/servicereqs/saga";
import sharedSaga from "../features/shared/saga";
import socialSaga from "../features/social/saga";
import userInfoSaga from "../features/userInfo/saga";
import applicantSaga from "../features/applicant/saga";
import newresidentSaga from "../features/newresident/saga";
import uploadsSaga from "../features/uploads/saga";
import communityGuideSaga from "../features/communityguide/saga";
import adverseActionLetterSaga from "../features/adverseactionletter/saga";
import creditReportSaga from "../features/creditreport/saga";
import criminalReportSaga from "../features/criminalreport/saga";
import docuSignSaga from "../features/docusignlease/saga";

// Add For Rent With Equity
// fork(rentWithEquitySaga),

function* rootSaga() {
  yield all([
    fork(amenitySaga),
    fork(contactSaga),
    fork(formerResidentSaga),
    fork(guestSaga),
    fork(myaccountdashboardSaga),
    fork(leaseSaga),
    fork(profileSaga),
    fork(statementSaga),
    fork(newsSaga),
    fork(renewalSaga),
    fork(rentableSaga),
    fork(svcProviderSaga),
    fork(servicereqsSaga),
    fork(socialSaga),
    fork(userInfoSaga),
    fork(selectAccountSaga),
    fork(notificationsSaga),
    fork(paymentSaga),
    fork(propertyDocumentsSaga),
    fork(documentsSaga),
    fork(referFriendSaga),
    fork(noticeToVacateSaga),
    fork(sharedSaga),
    fork(newresidentSaga),
    fork(uploadsSaga),
    fork(communityGuideSaga),
    fork(adverseActionLetterSaga),
    fork(applicantSaga),
    fork(creditReportSaga),
    fork(criminalReportSaga),
    fork(docuSignSaga),
  ]);
}

export default rootSaga;
