import * as React from "react";
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import moment from "moment";
import { currencyFormatter } from "../../shared/utils/utils";

interface Props {
  balance: number;
  sodaPrelim: string;
  vacateDate?: Date;
  classes: any;
  goToPayments: any;
}

type AllProps = Props;

class BalanceDueTile extends React.Component<AllProps> {
  render() {
    let date = null;
    let text = null;
    if (this.props.sodaPrelim !== "F" && this.props.vacateDate) {
      date = new Date(
        moment(this.props.vacateDate)
          .add(14, "days")
          .toString()
      ).toLocaleDateString();
      text = `Please check back on ${date}. We are in the process of finalizing your account.`;
    }

    let balanceLabel = this.props.sodaPrelim === "F" ? "BALANCE DUE" : "CURRENT BALANCE";

    return (
      <React.Fragment>
        {this.props.balance > 0 && (
          <Paper className={this.props.classes.paper}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography>{balanceLabel}</Typography>
                <Typography variant="h1">
                  <strong>{currencyFormatter(this.props.balance)}</strong>
                </Typography>
                <Typography>{text}</Typography>
              </Grid>
              <Grid item xs style={{ textAlign: "right" }}>
                <Button onClick={() => this.props.goToPayments()} variant="contained" color="primary" aria-label="Add">
                  Pay Balance
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
      </React.Fragment>
    );
  }
}

export default BalanceDueTile;
