import { push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import { RootState } from "../../../store/root-reducer";
import { getAvaliableServiceQuestions } from "../../../utils/api";
import { LeaseDetails } from "../../mylease/models";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { UserInfoItem } from "../../userInfo/models";
import {
  categoriesFetchRequest,
  locationsFetchRequest,
  postRequest,
  quickFixesFetchRequest,
  selectLocation,
  selectSubCategory,
  selectTopLevelCategory,
  selectType,
  typesFetchRequest,
} from "../actions";
import {
  RpActivity,
  ServiceQuestions,
  ServiceRequest,
  ServiceRequestLocation,
  ServiceRequestQuickFix,
  ServiceRequestSubCategory,
  ServiceRequestTopLevelCategory,
  ServiceRequestType,
  ServiceRequestUpload,
} from "../models";
import ServicereqFormBreadcrumbs from "./servicereq-form-breadcrumbs";
import EmergencyDialog from "./servicereq-form-emergency";
import DetailsDialog, { FormValues as DetailsState } from "./servicereq-form-enterDetails";
import PermissionToEnterDialog from "./servicereq-form-permissionToEnter";
import QuestionnaireDialog from "./servicereq-form-questionnaire";
import QuestionnaireCancelDialog from "./servicereq-form-questionnaire-cancel";
import QuickFixDialog from "./servicereq-form-quickfix";
import ServicereqFormSelectLocation from "./servicereq-form-selectLocation";
import ServicereqFormSelectProblem from "./servicereq-form-selectProblem";
import ServicereqFormSelectSubCategory from "./servicereq-form-selectSubCategory";

export interface ServiceRequestFormProps {}

interface PropsFromState {
  user?: UserInfoItem;
  topLevelCategories: ServiceRequestTopLevelCategory[];
  requestTypes: ServiceRequestType[];
  locations: ServiceRequestLocation[];
  selectedTopLevelCategory?: ServiceRequestTopLevelCategory;
  selectedSubCategory?: ServiceRequestSubCategory;
  selectedProblem?: ServiceRequestType;
  selectedLocation?: ServiceRequestLocation;
  serviceReqQuickFixes: ServiceRequestQuickFix[];
  leaseInfo: LeaseDetails | undefined;
}

interface PropsFromDispatch {
  categoriesFetchRequest: typeof categoriesFetchRequest;
  typesFetchRequest: typeof typesFetchRequest;
  quickFixesFetchRequest: typeof quickFixesFetchRequest;
  locationsFetchRequest: typeof locationsFetchRequest;
  selectTopLevelCategory: typeof selectTopLevelCategory;
  selectSubCategory: typeof selectSubCategory;
  selectProblem: typeof selectType;
  selectLocation: typeof selectLocation;
  logActivity: typeof logActivity;
  push: typeof push;
  postRequest: typeof postRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps & ServiceRequestFormProps;

type State = {
  emergencyOpen: boolean;
  quickFixOpen: boolean;
  enterDetailsOpen: boolean;
  permissionToEnterOpen: boolean;
  questionnaireOpen: boolean;
  questionnaireCancelOpen: boolean;
  formState: DetailsState;
  activeQuestions: ServiceQuestions[];
};

class ServiceRequestForm extends React.Component<AllProps, State> {
  readonly state: Readonly<State> = {
    emergencyOpen: false,
    quickFixOpen: false,
    enterDetailsOpen: false,
    permissionToEnterOpen: false,
    questionnaireOpen: false,
    questionnaireCancelOpen: false,
    formState: {
      phoneNumber: "12345",
      additionalDetails: "",
      havePet: false,
      haveAlarm: false,
      image: undefined,
    },
    activeQuestions: [],
  };

  componentDidMount() {
    const propId = this.props.user!.propertyId;
    this.props.typesFetchRequest(propId);
    this.props.quickFixesFetchRequest();
    this.props.locationsFetchRequest();
    this.getActiveQuestions();
  }

  handleSelectCategory = (topLevelCategory: ServiceRequestTopLevelCategory) => {
    this.props.selectTopLevelCategory(topLevelCategory);
    this.props.history.push("/servicereqs/new/selectSubCategory");
  };

  handleSelectSubCategory = (subCategory: ServiceRequestSubCategory) => {
    this.props.selectSubCategory(subCategory);
    this.props.history.push("/servicereqs/new/selectSubType");
  };

  getActiveQuestions = () => {
    getAvaliableServiceQuestions().then((response) => {
      this.setState({
        activeQuestions: response,
      });
    });
  };

  getDefaultLocationCode(categoryId: number) {
    switch (categoryId) {
      case 63:
        return "KN";
      case 64:
        return "LA";
      case 65:
        return "KN";
      case 66:
        return "KN";
      case 67:
        return "KN";
      case 68:
        return "LA";
      case 80:
        return "GR";
      case 95:
        return "KN";
      case 102:
        return "GR";
      case 104:
        return "KN";
      case 105:
        return "KN";
      case 160:
        return "KN";
      default:
        return undefined;
    }
  }

  handleSelectProblem = (problem: ServiceRequestType) => {
    this.props.selectProblem(problem);
    const locationCode = this.getDefaultLocationCode(this.props.selectedSubCategory!.categories[0].categoryId);
    if (locationCode) {
      const selectedLocation = this.props.locations.find((loc) => loc.roomCode.trim() === locationCode);
      this.props.selectLocation(selectedLocation!);

      if (problem.quickFixId) {
        this.setState({ quickFixOpen: true });
      } else if (problem.emergency) {
        this.setState({ emergencyOpen: true });
      } else if (this.state.activeQuestions.length !== 0) {
        this.setState({ questionnaireOpen: true });
      } else {
        this.setState({ enterDetailsOpen: true });
      }
    } else {
      this.props.history.push("/servicereqs/new/selectLocation");
    }
  };

  handleSelectLocation = (location: ServiceRequestLocation) => {
    this.props.selectLocation(location);
    if (this.props.selectedProblem!.quickFixId) {
      this.setState({ quickFixOpen: true });
    } else if (this.props.selectedProblem!.emergency) {
      this.setState({ emergencyOpen: true });
    } else if (this.state.activeQuestions.length !== 0) {
      this.setState({ questionnaireOpen: true });
    } else {
      this.setState({ enterDetailsOpen: true });
    }
  };

  handleContinue = (s: DetailsState) => {
    this.setState({
      enterDetailsOpen: false,
      permissionToEnterOpen: true,
      formState: s,
    });
    window.scrollTo(0, 0);
  };

  handleCancel = () => {
    this.props.history.push("/servicereqs");
  };

  handleCancelNoPermission = () => {
    this.props.logActivity({
      actcode: "SX",
      note: "Service request cancelled: No permission to enter",
    });
    this.props.history.push("/servicereqs");
  };

  handleCancelEmergency = () => {
    this.props.logActivity({
      actcode: "SE",
      note: "Service request cancelled: Emergency request",
    });
    this.props.history.push("/servicereqs");
  };

  // handleContinueEmergency = () => {
  //   this.setState({ emergencyOpen: false, enterDetailsOpen: true });
  // };

  handleContinueEmergency = () => {
    if (this.state.activeQuestions.length !== 0) {
      this.setState({ emergencyOpen: false, questionnaireOpen: true });
    } else {
      this.setState({ emergencyOpen: false, enterDetailsOpen: true });
    }
  };

  handleContinueQuestionnaire = () => {
    this.setState({ questionnaireOpen: false, enterDetailsOpen: true });
  };

  handleCancelQuestionnaire = () => {
    this.setState({ questionnaireOpen: false, questionnaireCancelOpen: true });
  };

  handleContinue2Questionnaire = () => {
    this.props.logActivity({
      actcode: "SA",
      note: "Service request cancelled: health and safety",
      appsource: "WEBRP",
    });
    this.props.history.push("/servicereqs");
  };

  // handleCancelQuickFix = () => {
  //   this.setState({ questionnaireOpen: false, questionnaireCancelOpen: true })
  // };

  handleCancelQuickFix = () => {
    this.props.logActivity({
      actcode: "SQ",
      note: "Service request resolved with Quick Fix",
    });

    this.props.history.push("/servicereqs");
  };

  handleContinueQuickFix = () => {
    if (this.props.selectedProblem!.emergency) {
      this.setState({ quickFixOpen: false, emergencyOpen: true });
    } else if (this.state.activeQuestions.length !== 0) {
      this.setState({ quickFixOpen: false, questionnaireOpen: true });
    } else {
      this.setState({ quickFixOpen: false, enterDetailsOpen: true });
    }
  };

  handleSubmit = () => {
    const submissionData: ServiceRequest = {
      creatorNameId: this.props.user!.nameId,
      creatorPortalUserId: this.props.user!.portalUserId,
      workOrderId: "",
      dateReported: new Date(),
      comments: this.state.formState.additionalDetails,
      anyPets: this.state.formState.havePet,
      alarm: this.state.formState.haveAlarm,
      phoneNumber: this.state.formState.phoneNumber,
      topLevelCategoryName: this.props.selectedTopLevelCategory!.categoryName,
      categoryId: this.props.selectedSubCategory!.categories[0].categoryId,
      requestTypeId: this.props.selectedProblem!.id,
      roomCode: this.props.selectedLocation!.roomCode,
      contractorName: "",
    };

    const uploadRequest: ServiceRequestUpload = {
      serviceRequest: submissionData,
      photo: this.state.formState.image,
    };

    this.props.postRequest(uploadRequest);
    this.props.history.push("/servicereqs?confirmMessage=y");
  };

  private GetFormatPhone = (phone: string) => {
    let res = "";

    if (phone.length < 10) {
      res = "";
    } else {
      var prefix = phone.substring(0, 3);
      var dns = phone.substring(3, 6);
      var suffix = phone.substring(6, 10);

      var ext = "";
      if (phone.length > 10) {
        ext = " x" + phone.substring(10, phone.length - 10);
        res = "(" + prefix + ") " + dns + "-" + suffix + ext; // String.Format("({0}) {1}-{2} x{3}", prefix, dns, suffix, ext);
      } else {
        res = "(" + prefix + ") " + dns + "-" + suffix;
      }
    }

    return res;
  };

  private GetQuickFixText = (id: number) => {
    let res = "";

    if (id > 0) {
      if (this.props.selectedProblem !== undefined) {
        if (this.props.serviceReqQuickFixes.filter((x) => x.id === id)[0].quickFixSuggestions[0]! !== undefined) {
          res = this.props.serviceReqQuickFixes.filter((x) => x.id === id)[0].quickFixSuggestions[0]!;
        }
      }
    }
    return res;
  };

  render() {
    return (
      <React.Fragment>
        <ServicereqFormBreadcrumbs />
        <Switch>
          <Route
            path="/servicereqs/new/selectSubCategory"
            render={(props) => (
              <ServicereqFormSelectSubCategory
                selectSubCategory={this.handleSelectSubCategory}
                subCategories={this.props.selectedTopLevelCategory!.subCategories}
              />
            )}
          />

          <Route
            path="/servicereqs/new/selectSubType"
            render={(props) => (
              <ServicereqFormSelectProblem
                selectProblem={this.handleSelectProblem}
                problems={this.props.requestTypes}
                currentlySelectedCategory={this.props.selectedSubCategory!.categories[0]}
              />
            )}
          />

          <Route
            path="/servicereqs/new/selectLocation"
            render={(props) => (
              <ServicereqFormSelectLocation
                selectLocation={this.handleSelectLocation}
                locations={this.props.locations}
              />
            )}
          />
          <Route path="/servicereqs/new/questionnaire" render={(props) => <div />} />
          {/* <Route path="/servicereqs/new/enterDetails" render={props => <div />} /> */}
        </Switch>

        <EmergencyDialog
          onCancel={this.handleCancelEmergency}
          onContinue={this.handleContinueEmergency}
          open={this.state.emergencyOpen}
          officePhone={this.GetFormatPhone(this.props.user!.propertyInfo.propertyPhone.toString())}
        />

        <QuestionnaireDialog
          onCancel={this.handleCancelQuestionnaire}
          onContinue={this.handleContinueQuestionnaire}
          open={this.state.questionnaireOpen}
          questions={this.state.activeQuestions}
        />

        <QuestionnaireCancelDialog
          onContinue={this.handleContinue2Questionnaire}
          open={this.state.questionnaireCancelOpen}
          officePhone={this.GetFormatPhone(this.props.user!.propertyInfo.propertyPhone.toString())}
        />

        <QuickFixDialog
          onCancel={this.handleCancelQuickFix}
          onContinue={this.handleContinueQuickFix}
          open={this.state.quickFixOpen}
          quickFix={this.GetQuickFixText(
            (
              this.props.selectedProblem !== undefined
                ? this.props.selectedProblem!.quickFixId !== null &&
                  this.props.selectedProblem!.quickFixId !== undefined
                : false
            )
              ? this.props.selectedProblem!.quickFixId!
              : -1
          )}
        />

        <DetailsDialog
          problemDesc={this.props.selectedProblem ? this.props.selectedProblem.description : ""}
          onCancel={this.handleCancel}
          onContinue={this.handleContinue}
          open={this.state.enterDetailsOpen}
          defaultPhone={this.props.user!.phoneNumber}
          isCommonArea={false}
          leaseInfo={this.props.leaseInfo}
        />

        <PermissionToEnterDialog
          onCancel={this.handleCancelNoPermission}
          onContinue={this.handleSubmit}
          open={this.state.permissionToEnterOpen}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ servicereqs, selectAccount, lease }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  topLevelCategories: servicereqs.serviceRequestCategories.hierarchicalData,
  requestTypes: servicereqs.serviceReqTypes.data,
  locations: servicereqs.serviceReqLocations.data,
  selectedTopLevelCategory: servicereqs.serviceRequestForm.selectedTopLevelCategory,
  selectedSubCategory: servicereqs.serviceRequestForm.selectedSubCategory,
  selectedProblem: servicereqs.serviceRequestForm.selectedType,
  selectedLocation: servicereqs.serviceRequestForm.selectedLocation,
  serviceReqQuickFixes: servicereqs.serviceReqQuickFixes.data,
  leaseInfo: lease.data,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  categoriesFetchRequest: () => dispatch(categoriesFetchRequest()),
  quickFixesFetchRequest: () => dispatch(quickFixesFetchRequest()),
  locationsFetchRequest: () => dispatch(locationsFetchRequest()),
  typesFetchRequest: (propId: string) => dispatch(typesFetchRequest(propId)),
  selectTopLevelCategory: (category: ServiceRequestTopLevelCategory) => dispatch(selectTopLevelCategory(category)),
  selectSubCategory: (category: ServiceRequestSubCategory) => dispatch(selectSubCategory(category)),
  selectProblem: (problem: ServiceRequestType) => dispatch(selectType(problem)),
  selectLocation: (loc: ServiceRequestLocation) => dispatch(selectLocation(loc)),
  push: push,
  postRequest: (req: ServiceRequestUpload) => dispatch(postRequest(req)),
  logActivity: (act: RpActivity) => dispatch(logActivity(act)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceRequestForm));
