export const EVENT_TIME_ICON =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/uielements/time.png";
export const EVENT_LOCATION_ICON =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/uielements/location.png";
export const EVENT_ATTENDANCE_ICON =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/uielements/person.png";
export const EVENT_RSVP_ICON =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/uielements/confirmation.png";
export const EVENT_RSVP_ICON_REVERSED =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/uielements/confirmationreverse.png";
export const DEFAULT_AVATAR_ICON =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/uielements/time.png";

export const CALENDAR_ICON =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/socialfeed/event.png";

export const FORSALE_ICON =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/socialfeed/forsale.png";

export const DISCUSSION_ICON =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/socialfeed/discussion.png";

export const NEWS_ICON =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/socialfeed/news.png";

export const ALERT_ICON =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/socialfeed/alert.png";

export const CAMERA_ICON =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/uielements/camera.png";

export const FILTER_ICON =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/uielements/filter2.png";

export const REMOVE_ICON =
  "https://media.equityapartments.com/image/upload/v1555015771/Content/portal/uielements/remove.png";

export const FILTER_ICON_DISCUSSION =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/socialfeed/discussion2.png";

export const FILTER_ICON_EVENTS =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/socialfeed/event2.png";

export const FILTER_ICON_FORSALE =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/socialfeed/forsale2.png";

export const FILTER_ICON_NEWS =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/socialfeed/news2.png";

export const FILTER_ICON_GROUPS =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/socialfeed/groups2.png";

export const UNFILTER_ICON =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/uielements/unfilter.png";

export const DISCUSSION_ITEM = "Discussion";

export const FOR_SALE_ITEM = "ForSale";

export const EVENT_ITEM = "Event";

export const GROUP_FEED = "groupFeed";

export const HOME_FEED = "homeFeed";

export const GROUPS_BASE_URL = "/groupdetails/";
