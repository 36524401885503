import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import Events from "../features/social/components/EventsCalendar/Events";

import eventstyles from "../styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(eventstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {}

type AllProps = Props;

class EventsDashboard extends React.Component<AllProps> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  public render() {
    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Events />
          <hr />
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(EventsDashboard);
