import { List, ListItem, ListItemText, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { rentablePropertyRequest } from "../actions";
import { ResidentRentableItems } from "../models";
import rentableitemstyles from "../styles";

const styles = combinestyles(rentableitemstyles, globalstyles);

export interface PropsFromState {
  user?: UserInfoItem;
  propResidentRentableList: ResidentRentableItems[];
}

interface PropsFromDispatch {
  rentablePropertyRequest: typeof rentablePropertyRequest;
}

interface RentableItemsListProps extends WithStyles<typeof styles> {}

type AllProps = PropsFromState & PropsFromDispatch & RentableItemsListProps;

class ResidentList extends React.Component<AllProps> {
  renderItem(rentable: ResidentRentableItems) {
    return (
      <ListItem key={rentable.rIType} divider disableGutters>
        <ListItemText primary={rentable.description} />
        <Typography>${rentable.rate} Per Month</Typography>
      </ListItem>
    );
  }

  render() {
    const { propResidentRentableList } = this.props;
    this.renderItem = this.renderItem.bind(this);
    return (
      <Paper className={this.props.classes.paper}>
        <List>{propResidentRentableList.map(this.renderItem)}</List>
      </Paper>
    );
  }
}

const mapStateToProps = ({ rentable, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  propResidentRentableList: rentable.rentableResidentList.data
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  rentablePropertyRequest: (propid: string) => dispatch(rentablePropertyRequest(propid))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResidentList));
