import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { svcFetchRequest } from "../features/serviceproviders/actions";
import { RootState } from "../store/root-reducer";
import { SvcProvider } from "../features/serviceproviders/models";
import { Typography, WithStyles, withStyles } from "@material-ui/core";
import SvcProviderList from "../features/serviceproviders/components/SvcProviderList";
import serviceproviderstyles from "../features/serviceproviders/components/styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(serviceproviderstyles, globalstyles);

interface ServiceProvidersProps extends WithStyles<typeof styles> {}

interface PropsFromState {
  user?: UserInfoItem;
  data?: SvcProvider[];
}

interface PropsFromDispatch {
  svcFetchRequest: typeof svcFetchRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & ServiceProvidersProps;

class ServiceProviders extends React.Component<AllProps> {
  componentDidMount() {
    const user = this.props.user;
    const rmpropid = user!.propertyId;

    this.props.svcFetchRequest({ rmpropid });
    window.scrollTo(0, 0);
  }

  render() {
    const { data } = this.props;

    let svcProviders: SvcProvider[];

    if (data !== undefined) {
      svcProviders = data;
    } else {
      svcProviders = [];
    }

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Typography variant="h1" className={this.props.classes.header}>
            Service Providers
          </Typography>
          <SvcProviderList serviceProviders={svcProviders} />
        </main>
      </div>
    );
  }
}

//const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
const mapStateToProps = ({ serviceproviders, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  data: serviceproviders.data
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  svcFetchRequest: (payload: { rmpropid: any }) => dispatch(svcFetchRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ServiceProviders));
