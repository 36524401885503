import * as React from "react";
import {
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  InputLabel,
  withStyles,
  WithStyles,
  Avatar,
} from "@material-ui/core";
import { InjectedProps } from "@material-ui/core/withMobileDialog";
// import ImageIcon from "@material-ui/icons/Image";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import { Field, Form, Formik } from "formik";
import FormikTextField from "../../shared/components/FormikTextField";
import * as Yup from "yup";
import { Switch } from "formik-material-ui";
import { LeaseDetails } from "../../mylease/models";
import ImageCropperInput from "../../shared/components/ImageCropperInput";
import servicestyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import RegexConstants from "../../shared/constants/regex";
//import DeleteIcon from "@material-ui/icons/Delete";
import * as constants from "../constants";

const styles = combinestyles(servicestyles, globalstyles);

const validationSchema = Yup.object().shape({
  // All Forms
  additionalDetails: Yup.string().required("Additional Details is required").max(500, "Character limit 500"),
  phoneNumber: Yup.string()
    .max(15, "15 character limit")
    .min(10, "Please enter a 10-digit phone number, area code first1")
    // .length(10, "Please enter a 10-digit phone number, area code first")
    // .matches(
    //   /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/,
    //   "Please enter a 10-digit phone number, area code first2"
    // )
    .matches(RegexConstants.PHONE, "Please enter a 10-digit phone number, area code first")
    .required("Please enter a 10-digit phone number, area code first")
});

interface Props extends WithStyles<typeof styles> {
  problemDesc: string;
  onContinue: any;
  onCancel: any;
  open: boolean;
  defaultPhone: string;
  isCommonArea: boolean;
  leaseInfo?: LeaseDetails;
}

type AllProps = Props & InjectedProps;

export interface FormValues {
  phoneNumber: string;
  additionalDetails: string;
  havePet: boolean;
  haveAlarm: boolean;
  image: any;
}

class ServiceReqFormEnterDetails extends React.PureComponent<AllProps> {
  removeFile = () => {
    this.setState({ image: null });
  };

  render() {
    const { fullScreen, open, problemDesc, onCancel, onContinue, defaultPhone, isCommonArea, leaseInfo } = this.props;
    const initialValues: Readonly<FormValues> = {
      phoneNumber: defaultPhone,
      additionalDetails: "",
      havePet: leaseInfo && leaseInfo.pets.length > 0 ? true : false,
      haveAlarm: false,
      image: undefined
    };
    return (
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onContinue}>
        {({ submitForm, setFieldValue, values }) => (
          <Form>
            <Dialog
              fullScreen={fullScreen}
              open={open}
              fullWidth
              maxWidth="sm"
              classes={{ paper: this.props.classes.paper }}
            >
              <Grid container spacing={24} alignItems="center">
                <Grid item xs={10}>
                  <DialogTitle>New Service Request</DialogTitle>
                </Grid>
                <Grid item xs={2}>
                  <DialogActions>
                    <Button onClick={onCancel} color="primary" autoFocus>
                      <CancelIcon />
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
              <DialogContent>
                <Typography style={{ marginBottom: "1.5rem" }}>{problemDesc}</Typography>
                <div>
                  <InputLabel>What is the best phone number to reach you at with any questions?</InputLabel>
                </div>
                <Field
                  name="phoneNumber"
                  component={FormikTextField}
                  margin="normal"
                  style={{ marginBottom: "2rem" }}
                />
                <div>
                  <InputLabel>Please provide additional details</InputLabel>
                </div>
                <Field
                  name="additionalDetails"
                  component={FormikTextField}
                  multiline
                  rowsMax="4"
                  margin="normal"
                  fullWidth
                />
                <Grid container direction="column" hidden={isCommonArea} style={{ marginBottom: "1rem" }}>
                  <Grid item>
                    <Grid container alignItems="center" direction="row">
                      <Grid item>
                        <Typography>Do you have a pet?</Typography>
                      </Grid>
                      <Grid item>
                        <Field name="havePet" component={Switch} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center" direction="row">
                      <Grid item>
                        <Typography>Do you have an alarm?</Typography>
                      </Grid>
                      <Grid item>
                        <Field name="haveAlarm" component={Switch} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {values.image && (
                  <Grid item>
                    <Avatar src={URL.createObjectURL(values.image)} />

                    <div onClick={(image: any) => setFieldValue("image", null)} style={{ marginLeft: "-2rem" }}>
                      <img
                        alt="Remove"
                        src={constants.REMOVE_ICON}
                        style={{ position: "relative", top: "-1rem", right: "-4rem", width: "2rem", height: "2rem" }}
                      />
                    </div>
                  </Grid>
                )}
                <ImageCropperInput
                  inputButtonText={values.image ? "Update Image" : "Add Image"}
                  dialogTitle="Upload Image"
                  onChange={(image: any) => setFieldValue("image", image)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={submitForm} color="primary" variant="contained" autoFocus>
                  Continue
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(ServiceReqFormEnterDetails);
