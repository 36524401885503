import { Grid, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";

import contactstyles from "../features/myprofile/components/styles";
import NotificationsContainer from "../features/notifications/components/NotificationsContainer";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import * as constants from "../features/shared/constants/constants";
import Feed from "../features/social/components/Feed";
import FeedFilter from "../features/social/components/FeedFilter";
import NewPost from "../features/social/components/NewPost";
import WelcomeTile from "../features/social/components/WelcomeTile";
import { UserInfoItem } from "../features/userInfo/models";
import globalstyles from "../global-styles";
import { RootState } from "../store/root-reducer";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(contactstyles, globalstyles);

interface PropsFromState {
  user?: UserInfoItem;
}

interface PropsFromDispatch {}

interface IServiceReqProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
}

type AllProps = IServiceReqProps & PropsFromState & PropsFromDispatch & RouteComponentProps<{ startDateIndex: string }>;

class Dashboard extends React.Component<AllProps> {
  isNewResident = () => {
    const residentStatus = this.props.user ? this.props.user.residentStatus : "";
    const residentmiCheckListActive = this.props.user ? this.props.user.moveinCheckListActive : "";
    const residentMoveinGuide = this.props.user ? this.props.user.displayMoveinGuide : "";
    if (residentStatus !== "") {
      if (residentStatus === constants.NEW && residentmiCheckListActive === "Y" && residentMoveinGuide === "Y") {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  public render() {
    const newResident = this.isNewResident();
    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          {this.props.user!.bannedFromSocial !== true && (
            <Grid container className={this.props.classes.header}>
              <Grid item xs sm>
                <NewPost />
              </Grid>
              <Grid item xs={3} sm={2}>
                <FeedFilter />
              </Grid>
            </Grid>
          )}

          <NotificationsContainer fromDashboard={true} />
          {newResident && <WelcomeTile />}
          <Feed />
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ selectAccount }: RootState) => ({
  user: getActiveUserInfo(selectAccount)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard));
