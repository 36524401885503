import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { UploadDocument } from "./models";
import * as uploads from "./actions";

export type UploadsAction = ActionType<typeof uploads>;

export type UploadsState = Readonly<{
  loading: boolean;
  data: UploadDocument[];
  errors?: string;
}>;

const initialState: UploadsState = {
  data: [],
  errors: undefined,
  loading: false
};

const reducer: Reducer<UploadsState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(uploads.uploadsPostRequest): {
      return { ...state, loading: true };
    }
    case getType(uploads.uploadsPostSuccess): {
      return { ...state, loading: false };
    }
    case getType(uploads.uploadsPostError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(uploads.uploadsListGetRequest): {
      return { ...state, loading: true };
    }
    case getType(uploads.uploadsListGetSuccess): {
      return { ...state, loading: false, data: action.payload };
    }
    case getType(uploads.uploadsListGetError): {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
