import { createAction } from "typesafe-actions";

import {
  ContactEmergency,
  ContactInfo,
  RentersInsurance,
  SpecialAssistance,
  UserInfo,
  VehicleInfo,
  TransUnionPaymentReporting,
} from "./models";

const USER_INFO_FETCH_REQUEST = "profile/userInfo/FETCH_REQUEST";
const USER_INFO_FETCH_SUCCESS = "profile/userInfo/FETCH_SUCCESS";
const USER_INFO_FETCH_ERROR = "profile/userInfo/FETCH_ERROR";

export const userInfoFetchRequest = createAction(USER_INFO_FETCH_REQUEST)<{ nameId: string }>();
export const userInfoFetchSuccess = createAction(USER_INFO_FETCH_SUCCESS)<{ data: UserInfo }>();
export const userInfoFetchError = createAction(USER_INFO_FETCH_ERROR)<string>();

// Profile Contact Information Tile
const CONTACT_FETCH_REQUEST = "profile/contact/FETCH_REQUEST";
const CONTACT_FETCH_SUCCESS = "profile/contact/FETCH_SUCCESS";
const CONTACT_FETCH_ERROR = "profile/contact/FETCH_ERROR";
export const contactFetchRequest = createAction(CONTACT_FETCH_REQUEST)<{ nameId: string }>();
export const contactFetchSuccess = createAction(CONTACT_FETCH_SUCCESS)<{ data: ContactInfo }>();
export const contactFetchError = createAction(CONTACT_FETCH_ERROR)<string>();
const CONTACT_UPDATE_REQUEST = "profile/contact/UPDATE_REQUEST";
const CONTACT_UPDATE_SUCCESS = "profile/contact/UPDATE_SUCCESS";
const CONTACT_UPDATE_ERROR = "profile/contact/UPDATE_ERROR";
export const contactUpdateRequest = createAction(CONTACT_UPDATE_REQUEST)<ContactInfo>();
export const contactUpdateSuccess = createAction(CONTACT_UPDATE_SUCCESS)<ContactInfo>();
export const contactUpdateError = createAction(CONTACT_UPDATE_ERROR)<string>();

// Profile Emergency Information Tile
const EMERGENCY_FETCH_REQUEST = "emergency/FETCH_REQUEST";
const EMERGENCY_FETCH_SUCCESS = "emergency/FETCH_SUCCESS";
const EMERGENCY_FETCH_ERROR = "emergency/FETCH_ERROR";
export const emergencyFetchRequest = createAction(EMERGENCY_FETCH_REQUEST)<{ nameId: string }>();
export const emergencyFetchSuccess = createAction(EMERGENCY_FETCH_SUCCESS)<{ data: ContactEmergency }>();
export const emergencyFetchError = createAction(EMERGENCY_FETCH_ERROR)<string>();
const EMERGENCY_UPDATE_REQUEST = "emergency/UPDATE_REQUEST";
const EMERGENCY_UPDATE_SUCCESS = "emergency/UPDATE_SUCCESS";
const EMERGENCY_UPDATE_ERROR = "emergency/UPDATE_ERROR";
export const emergencyUpdateRequest = createAction(EMERGENCY_UPDATE_REQUEST)<ContactEmergency>();
export const emergencyUpdateSuccess = createAction(EMERGENCY_UPDATE_SUCCESS)<ContactEmergency>();
export const emergencyUpdateError = createAction(EMERGENCY_UPDATE_ERROR)<string>();

// Profile Vehicles Tile
const VEHICLES_FETCH_REQUEST = "vehicles/FETCH_REQUEST";
const VEHICLES_FETCH_SUCCESS = "vehicles/FETCH_SUCCESS";
const VEHICLES_FETCH_ERROR = "vehicles/FETCH_ERROR";
export const vehiclesFetchRequest = createAction(VEHICLES_FETCH_REQUEST)<{ nameId: string }>();
export const vehiclesFetchSuccess = createAction(VEHICLES_FETCH_SUCCESS)<{ data: VehicleInfo }>();
export const vehiclesFetchError = createAction(VEHICLES_FETCH_ERROR)<string>();
const VEHICLES_UPDATE_REQUEST = "vehicles/UPDATE_REQUEST";
const VEHICLES_UPDATE_SUCCESS = "vehicles/UPDATE_SUCCESS";
const VEHICLES_UPDATE_ERROR = "vehicles/UPDATE_ERROR";
export const vehiclesUpdateRequest = createAction(VEHICLES_UPDATE_REQUEST)<{
  originalLicense: string;
  originalState: string;
  updatedVehicle: VehicleInfo;
}>();
export const vehiclesUpdateSuccess = createAction(VEHICLES_UPDATE_SUCCESS)<VehicleInfo>();
export const vehiclesUpdateError = createAction(VEHICLES_UPDATE_ERROR)<string>();
const VEHICLES_ADD_REQUEST = "vehicles/ADD_REQUEST";
const VEHICLES_ADD_SUCCESS = "vehicles/ADD_SUCCESS";
const VEHICLES_ADD_ERROR = "vehicles/ADD_ERROR";
export const vehiclesAddRequest = createAction(VEHICLES_ADD_REQUEST)<VehicleInfo>();
export const vehiclesAddSuccess = createAction(VEHICLES_ADD_SUCCESS)<VehicleInfo>();
export const vehiclesAddError = createAction(VEHICLES_ADD_ERROR)<string>();
const VEHICLES_DELETE_REQUEST = "vehicles/DELETE_REQUEST";
const VEHICLES_DELETE_SUCCESS = "vehicles/DELETE_SUCCESS";
const VEHICLES_DELETE_ERROR = "vehicles/DELETE_ERROR";
export const vehiclesDeleteRequest = createAction(VEHICLES_DELETE_REQUEST)<{
  nameId: string;
  license: string;
  state: string;
}>();
export const vehiclesDeleteSuccess = createAction(VEHICLES_DELETE_SUCCESS)<VehicleInfo>();
export const vehiclesDeleteError = createAction(VEHICLES_DELETE_ERROR)<string>();

// Profile Renters Insurance Tile
const INSURANCE_FETCH_REQUEST = "insurance/FETCH_REQUEST";
const INSURANCE_FETCH_SUCCESS = "insurance/FETCH_SUCCESS";
const INSURANCE_FETCH_ERROR = "insurance/FETCH_ERROR";
export const insuranceFetchRequest = createAction(INSURANCE_FETCH_REQUEST)<{ nameId: string }>();
export const insuranceFetchSuccess = createAction(INSURANCE_FETCH_SUCCESS)<{ data: RentersInsurance }>();
export const insuranceFetchError = createAction(INSURANCE_FETCH_ERROR)<string>();

// Profile Special Assistance Tile
const SPECIAL_ASSISTANCE_FETCH_REQUEST = "specialAssistance/FETCH_REQUEST";
const SPECIAL_ASSISTANCE_FETCH_SUCCESS = "specialAssistance/FETCH_SUCCESS";
const SPECIAL_ASSISTANCE_FETCH_ERROR = "specialAssistance/FETCH_ERROR";
export const specialAssistanceFetchRequest = createAction(SPECIAL_ASSISTANCE_FETCH_REQUEST)<{
  nameId: string;
}>();
export const specialAssistanceFetchSuccess = createAction(SPECIAL_ASSISTANCE_FETCH_SUCCESS)<SpecialAssistance>();
export const specialAssistanceFetchError = createAction(SPECIAL_ASSISTANCE_FETCH_ERROR)<string>();

const SPECIAL_ASSISTANCE_UPDATE_REQUEST = "specialAssistance/UPDATE_REQUEST";
const SPECIAL_ASSISTANCE_UPDATE_SUCCESS = "specialAssistance/UPDATE_SUCCESS";
const SPECIAL_ASSISTANCE_UPDATE_ERROR = "specialAssistance/UPDATE_ERROR";
export const specialAssistanceUpdateRequest = createAction(SPECIAL_ASSISTANCE_UPDATE_REQUEST)<SpecialAssistance>();
export const specialAssistanceUpdateSuccess = createAction(SPECIAL_ASSISTANCE_UPDATE_SUCCESS)<SpecialAssistance>();
export const specialAssistanceUpdateError = createAction(SPECIAL_ASSISTANCE_UPDATE_ERROR)<string>();

// Profile TransUnion Payment Reporting Tile
const TU_SUPPRESS_FETCH_REQUEST = "transUnionSuppress/FETCH_REQUEST";
const TU_SUPPRESS_FETCH_SUCCESS = "transUnionSuppress/FETCH_SUCCESS";
const TU_SUPPRESS_FETCH_ERROR = "transUnionSuppress/FETCH_ERROR";
export const transUnionSuppressFetchRequest = createAction(TU_SUPPRESS_FETCH_REQUEST)<{ nameId: string }>();
export const transUnionSuppressFetchSuccess = createAction(TU_SUPPRESS_FETCH_SUCCESS)<TransUnionPaymentReporting>();
export const transUnionSuppressFetchError = createAction(TU_SUPPRESS_FETCH_ERROR)<string>();

const TU_SUPPRESS_UPDATE_REQUEST = "transUnionSuppress/UPDATE_REQUEST";
const TU_SUPPRESS_UPDATE_SUCCESS = "transUnionSuppress/UPDATE_SUCCESS";
const TU_SUPPRESS_UPDATE_ERROR = "transUnionSuppress/UPDATE_ERROR";
export const transUnionSuppressUpdateRequest = createAction(TU_SUPPRESS_UPDATE_REQUEST)<TransUnionPaymentReporting>();
export const transUnionSuppressUpdateSuccess = createAction(TU_SUPPRESS_UPDATE_SUCCESS)<TransUnionPaymentReporting>();
export const transUnionSuppressUpdateError = createAction(TU_SUPPRESS_UPDATE_ERROR)<string>();

// Profile PASSWORD
const AVATAR_UPDATE_REQUEST = "AVATAR/UPDATE_REQUEST";
const AVATAR_UPDATE_SUCCESS = "AVATAR/UPDATE_SUCCESS";
const AVATAR_UPDATE_ERROR = "AVATAR/UPDATE_ERROR";
export const avatarUpdateRequest = createAction(AVATAR_UPDATE_REQUEST)<{ nameId: string; file: File }>();
export const avatarUpdateSuccess = createAction(AVATAR_UPDATE_SUCCESS)<UserInfo>();
export const avatarUpdateError = createAction(AVATAR_UPDATE_ERROR)<string>();

// Update Password
const PASSWORD_UPDATE_REQUEST = "PASSWORD/UPDATE_REQUEST";
const PASSWORD_UPDATE_SUCCESS = "PASSWORD/UPDATE_SUCCESS";
const PASSWORD_UPDATE_ERROR = "PASSWORD/UPDATE_ERROR";
export const passwordUpdateRequest = createAction(PASSWORD_UPDATE_REQUEST)<{
  nameId: string;
  oldPassword: string;
  newPassword: string;
}>();
export const passwordUpdateSuccess = createAction(PASSWORD_UPDATE_SUCCESS)();
export const passwordUpdateError = createAction(PASSWORD_UPDATE_ERROR)<string>();
