import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { InjectedProps } from "@material-ui/core/withMobileDialog";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import { RootState } from "../../../store/root-reducer";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { UserInfoItem } from "../../userInfo/models";
import { guesttypesFetchRequest, putInactivateRequest, viewDetails, viewForm } from "../actions";
import { GuestItem, RpActivity } from "../models";
import styles from "./styles";

const guestAccessDomain =
  "https://media.equityapartments.com/image/upload/fl_lossy,q_auto,dpr_auto,w_50,h_50,r_max,c_fill/v1/GuestAccess";

export interface PropsFromState {
  user?: UserInfoItem;
  items: GuestItem[];
  guestTypes: Map<string, string>;
}

interface PropsFromDispatch {
  viewDetails: (item: GuestItem) => any;
  viewForm: (item: GuestItem) => any;
  getGuestTypes: () => any;
  updateGuest: (item: GuestItem) => any;
  logActivity: typeof logActivity;
}

interface GuestListProps extends WithStyles<typeof styles> {}

type State = {
  id: number;
  guestInformation: string;
  guestPhotoUrl: string;
  notes: string;
  startDate: string;
  startDateError: string;
  endDate: string;
  endDateError: string;
  guestType: string;
  guestTypeError: string;
  permanent: boolean;
  allowKeys: boolean;
  inactive: boolean;

  openRemove: boolean;

  currentGuest: GuestItem;
};

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps & GuestListProps & InjectedProps;

class GuestList extends React.Component<AllProps, State> {
  state: State = {
    id: 0,
    guestInformation: "",
    guestPhotoUrl: "",
    notes: "",
    startDate: "",
    startDateError: "",
    endDate: "",
    endDateError: "",
    guestType: "",
    guestTypeError: "",
    permanent: false,
    allowKeys: false,
    inactive: false,

    openRemove: false,

    currentGuest: {
      id: 0,
      guestInformation: "",
      guestPhotoUrl: "",
      notes: "",
      startDate: new Date(""),
      endDate: new Date(""),
      type: "",
      permanent: false,
      allowKeys: false,
      inactive: false,
      deletePhoto: false,
      guestTitle: "",
      nameId: "",
      userId: "WEBRP",
      authorizedBy: "",
      authorizedDate: new Date(Date.now()),
      lastModifiedDate: new Date(Date.now()),
    },
  };

  setCurrentGuest = (guest: GuestItem) => {
    this.setState({ currentGuest: guest });
  };

  handleRemoveDialogToggle = (guest: GuestItem) => {
    this.setCurrentGuest(guest);
    this.setState({ openRemove: !this.state.openRemove });
  };

  handleContinueRemoveGuest = () => {
    const tempGuest = { ...this.state.currentGuest };
    tempGuest.inactive = true;

    tempGuest.authorizedBy = this.props.user!.nameId;
    this.props.updateGuest(tempGuest);

    this.props.logActivity({
      actcode: "GI",
      note: `Guest inactivated: ${tempGuest.guestInformation}`,
    });

    this.setState({ openRemove: false });
  };

  handleCancelRemoveGuest = () => {
    this.setState({ openRemove: false });
  };

  handleRemoveClose = () => {
    this.setState({ openRemove: false });
  };

  renderItem(guest: GuestItem) {
    return (
      <Grid item xs={6} sm={4} key={guest.id}>
        <Card style={{ minHeight: "14rem" }}>
          <Grid container direction="row" justify="flex-end" spacing={16}>
            <Grid item>
              {guest.allowKeys && (
                <img
                  alt="Key"
                  src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_30/Content/portal/guestaccess/keyaccess.png"
                  style={{ marginTop: ".25rem", marginRight: ".25rem" }}
                />
              )}
              {!guest.allowKeys && <div style={{ height: "38px" }} />}
            </Grid>
          </Grid>
          <Grid container direction="row" justify="space-around" spacing={16}>
            <Grid item>
              <img
                alt={`${guest.guestInformation}`}
                src={this.GetGuestPhotoUrl(guest.guestPhotoUrl)}
                // className={this.props.classes.centerElement}
              />
            </Grid>
          </Grid>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            {guest.guestInformation}
          </Typography>
          <Typography style={{ textAlign: "center", marginBottom: "1rem" }}>
            {this.GetGuestTypeDescription(guest.type)}
          </Typography>
          {!guest.inactive && (
            <div>
              <Grid container direction="row" justify="center" spacing={16}>
                <Grid item>
                  <Button onClick={() => this.props.viewDetails(guest)}>
                    <img
                      alt="Edit"
                      src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_30/Content/portal/uielements/edit.svg"
                    />
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={() => this.handleRemoveDialogToggle(guest)}>
                    <img
                      alt="Trash"
                      src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_30/Content/portal/uielements/trash.svg"
                    />
                  </Button>
                </Grid>
              </Grid>
            </div>
          )}
          {guest.inactive && (
            <Grid container direction="row" justify="center" spacing={16}>
              <Grid item>
                <Button onClick={() => this.props.viewDetails(guest)}>
                  <AutorenewIcon />
                  <Typography>Reactivate</Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
    );
  }

  render() {
    const { items } = this.props;
    this.renderItem = this.renderItem.bind(this);
    return (
      <div>
        <Grid container spacing={16}>
          {items.map(this.renderItem)}
        </Grid>
        <Dialog
          open={this.state.openRemove}
          onClose={this.handleRemoveClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove guest access for {this.state.currentGuest.guestInformation}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleContinueRemoveGuest} color="primary" variant="contained">
              Yes
            </Button>
            <Button onClick={this.handleRemoveClose} color="default" variant="contained" autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  private GetGuestPhotoUrl = (guestPhotoUrl: string) => {
    let res = "";
    if (guestPhotoUrl !== null && guestPhotoUrl !== undefined) {
      res = `${guestAccessDomain}/${guestPhotoUrl}`;
    } else {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_50/Content/portal/uielements/defaultprofile.png`;
    }
    return res;
  };

  private GetGuestTypeDescription = (guestTypeValue: string) => {
    let guestTypeDescription = "";
    const types = [...this.props.guestTypes];

    for (const type of types) {
      if (guestTypeValue === type[0]) {
        guestTypeDescription = type[1];
        break;
      }
    }
    return guestTypeDescription;
  };
}

const mapStateToProps = ({ selectAccount, guest }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  items: guest.guestList.data,
  guestTypes: guest.guestForm.guestTypes,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  viewDetails: (item: GuestItem) => dispatch(viewDetails(item)),
  viewForm: (item: GuestItem) => dispatch(viewForm(item)),
  getGuestTypes: () => dispatch(guesttypesFetchRequest()),
  updateGuest: (item: GuestItem) => dispatch(putInactivateRequest(item)),
  logActivity: (act: RpActivity) => dispatch(logActivity(act)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GuestList)));
