import { Button, Divider, Grid, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { UserInfoItem } from "../../userInfo/models";
import { joinGroupPostRequest } from "../actionsGroup";
import { Group, JoinLeaveGroupDto, RpActivity } from "../models";
import socialstyles from "./styles";

const styles = combinestyles(socialstyles, globalstyles);

interface PropsFromState {
  user?: UserInfoItem;
}

interface PropsFromDispatch {
  joinGroup: (request: JoinLeaveGroupDto, group: Group) => any;
  logActivity: typeof logActivity;
}

interface MyGroupsProps extends WithStyles<typeof styles> {
  propertyGroup: Group[];
}

type State = {
  groupToJoin: JoinLeaveGroupDto;
};

type AllProps = PropsFromState & PropsFromDispatch & MyGroupsProps;

class GroupsOther extends React.Component<AllProps, State> {
  state: State = {
    groupToJoin: {
      groupId: 0,
      userNameId: ""
    }
  };

  handleJoinGroup = (groupToJoin: Group) => {
    const joinGroupRequest: JoinLeaveGroupDto = {
      groupId: groupToJoin.id,
      userNameId: this.props.user!.nameId
    };
    this.props.joinGroup(joinGroupRequest, groupToJoin);
    this.props.logActivity({
      actcode: "GJ",
      note: `Resident joined group - ${groupToJoin.name}`
    });
  };

  renderItem(groupItem: Group) {
    return (
      <Grid item xs={12} lg={6} key={groupItem.id}>
        <Grid container spacing={8}>
          <Grid item xs={8}>
            <Typography variant="h4">
              <NavLink className={this.props.classes.link} to={"/groupdetails/" + groupItem.id}>
                {groupItem.name}
              </NavLink>
            </Typography>
            <Typography className={this.props.classes.myGroupSubHead}>{`${groupItem.memberCount} Members`}</Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Button
              onClick={() => this.handleJoinGroup(groupItem)}
              variant="outlined"
              size="large"
              color="primary"
              style={{ marginRight: "10px" }}
            >
              Join
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    this.renderItem = this.renderItem.bind(this);

    return (
      <Paper className={this.props.classes.paper}>
        <Typography variant="h3" style={{ marginBottom: "1rem" }}>
          Explore Groups
        </Typography>
        <Divider style={{ marginBottom: "1rem" }} />
        <Grid container spacing={24}>
          {this.props.propertyGroup.map(this.renderItem)}
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount)
});

const mapDispatchToProps: PropsFromDispatch = {
  joinGroup: (request: JoinLeaveGroupDto, group: Group) => joinGroupPostRequest({ request: request, group: group }),
  logActivity: (act: RpActivity) => logActivity(act)
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(GroupsOther));
