import { createAction } from "typesafe-actions";

import { SvcProvider } from "./models";

const SVCPROVIDERS_FETCH_REQUEST = "svcproviders/FETCH_REQUEST";
const SVCPROVIDERS_FETCH_SUCCESS = "svcproviders/FETCH_SUCCESS";
const SVCPROVIDERS_FETCH_ERROR = "svcproviders/FETCH_ERROR";

export const svcFetchRequest = createAction(SVCPROVIDERS_FETCH_REQUEST)<{ rmpropid: any }>();
export const svcFetchSuccess = createAction(SVCPROVIDERS_FETCH_SUCCESS)<{ data: SvcProvider[] }>();
export const svcFetchError = createAction(SVCPROVIDERS_FETCH_ERROR)<string>();
