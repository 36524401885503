import { combineReducers } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { GuestItem } from "./models";
import * as guest from "./actions";

export type GuestAction = ActionType<typeof guest>;

export type GuestState = Readonly<{
  guestList: GuestListState;
  guestDetails: GuestDetailsState;
  guestForm: GuestFormState;
}>;

export type GuestListState = Readonly<{
  loading: boolean;
  data: GuestItem[];
  errors?: string;
}>;

export type GuestDetailsState = Readonly<{
  selectedItem?: GuestItem;
  detailsVisible: boolean;
}>;

export type GuestFormState = Readonly<{
  selectedItem?: GuestItem;
  formVisible: boolean;
  guestTypes: Map<string, string>;
}>;

const initialGuestFormState: GuestFormState = { selectedItem: undefined, formVisible: false, guestTypes: new Map() };

const initialGuestListState: GuestListState = {
  data: [],
  errors: "",
  loading: false,
};

export default combineReducers<GuestState, GuestAction>({
  guestList: (state = initialGuestListState, action) => {
    switch (action.type) {
      case getType(guest.fetchRequest): {
        return { ...state, loading: true };
      }
      case getType(guest.fetchSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(guest.fetchError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default:
        return state;
    }
  },
  guestDetails: (state = { selectedItem: undefined, detailsVisible: false }, action) => {
    switch (action.type) {
      case getType(guest.viewDetails): {
        return { ...state, selectedItem: action.payload, detailsVisible: true };
      }

      case getType(guest.hideDetails): {
        return { ...state, detailsVisible: false };
      }

      default:
        return state;
    }
  },
  guestForm: (state = initialGuestFormState, action) => {
    switch (action.type) {
      case getType(guest.viewForm): {
        return { ...state, selectedItem: action.payload, formVisible: true };
      }

      case getType(guest.hideForm): {
        return { ...state, formVisible: false };
      }

      case getType(guest.guesttypesFetchSuccess): {
        return { ...state, guestTypes: action.payload };
      }

      default:
        return state;
    }
  },
});
