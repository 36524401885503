import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store/root-reducer";
import { UserInfoItem } from "../../userInfo/models";
import { getActiveUserInfo } from "../../../features/selectAccount/selector";
import Parser from "html-react-parser";
import globalstyles from "../../../global-styles";
import rootstyles from "../../../styles";
import { withStyles, WithStyles } from "@material-ui/core";
import combineStyles from "../../../utils/combinestyles";

interface PropsFromState {
  user: UserInfoItem;
}
const styles = combineStyles(rootstyles, globalstyles);

interface RightRailPostsProps extends WithStyles<typeof styles> {}

type AllProps = PropsFromState & RightRailPostsProps;

class RightRailPosts extends React.Component<AllProps> {
  render() {
    const rightRailPosts = this.props.user.propertyInfo.rightRailPosts;
    let rightRailPostsArray = [];
    for (let i = 0; i < rightRailPosts.length; i++) {
      rightRailPostsArray.push(`${rightRailPosts[i].name.toString()} ${rightRailPosts[i].htmlBody}`);
    }

    return rightRailPosts.map((rr, i) => (
      <div style={{ padding: "0rem 0rem .5rem 0rem" }} key={i}>
        <div className={this.props.classes.textStyle} style={{ color: "#84898a", marginBottom: "1.5rem" }}>
          {Parser(rr.htmlBody)}
        </div>
        <hr style={{ height: "1px", border: "0", backgroundColor: "rgba(0, 0, 0, 0.12)" }} />
      </div>
    ));
  }
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
});

export default connect(mapStateToProps)(withStyles(styles)(RightRailPosts));
