import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import {
  cancelAmenityReservation,
  insertAmenityReservation,
  loadAmenityByNameId,
  loadAmenityByProperty,
  loadAmenityByDeepLink,
} from "../../utils/api";
import { showModalAction } from "../errorhandling/actions";
import * as actions from "./actions";

function* handleFetchAmenityByDeepLink(action: ReturnType<typeof actions.deeplinkFetchRequest>): any {
  try {
    const rmpropId = action.payload;

    const amenId = action.meta;

    const response = yield call(loadAmenityByDeepLink, rmpropId, amenId);

    if (response.error) {
      yield put(actions.categoriesFetchError(response.error));
    } else {
      yield put(actions.categoriesFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.categoriesFetchError(err.stack!));
    } else {
      yield put(actions.categoriesFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchAmenityByProp(action: ReturnType<typeof actions.categoriesFetchRequest>): any {
  try {
    const rmpropid = action.payload;

    const response = yield call(loadAmenityByProperty, rmpropid);

    if (response.error) {
      yield put(actions.categoriesFetchError(response.error));
    } else {
      yield put(actions.categoriesFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.categoriesFetchError(err.stack!));
    } else {
      yield put(actions.categoriesFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchAmenityNameId(action: ReturnType<typeof actions.listFetchRequest>): any {
  try {
    const nameid = action.payload;

    const response = yield call(loadAmenityByNameId, nameid);

    if (response.error) {
      yield put(actions.listFetchError(response.error));
    } else {
      yield put(actions.listFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.listFetchError(err.stack!));
    } else {
      yield put(actions.listFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleCancelAmenityReservation(action: ReturnType<typeof actions.cancelAmenityRequest>): any {
  try {
    const payload = action.payload;
    const response = yield call(cancelAmenityReservation, payload);

    if (response.error) {
      yield put(actions.cancelAmenityError(response.error));
    } else {
      yield put(actions.cancelAmenitySuccess(response));

      let responseList = yield call(loadAmenityByNameId, payload.nameId);

      if (responseList.error) {
        yield put(actions.listFetchError(responseList.error));
      } else {
        yield put(actions.listFetchSuccess(responseList));
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.cancelAmenityError(err.stack!));
    } else {
      yield put(actions.cancelAmenityError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleInsertAmenityReservation(action: ReturnType<typeof actions.insertAmenityRequest>): any {
  try {
    const payload = action.payload;
    const response = yield call(insertAmenityReservation, payload);
    if (response.error) {
      yield put(actions.insertAmenityError(response.error));
    } else {
      yield put(actions.insertAmenitySuccess(response));

      let responseList = yield call(loadAmenityByNameId, payload.nameId);

      if (responseList.error) {
        yield put(actions.listFetchError(responseList.error));
      } else {
        yield put(actions.listFetchSuccess(responseList));
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.insertAmenityError(err.stack!));
    } else {
      yield put(actions.insertAmenityError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* watchFetchRequest() {
  yield takeEvery(getType(actions.deeplinkFetchRequest), handleFetchAmenityByDeepLink);
  yield takeEvery(getType(actions.categoriesFetchRequest), handleFetchAmenityByProp);
  yield takeEvery(getType(actions.listFetchRequest), handleFetchAmenityNameId);
  yield takeEvery(getType(actions.cancelAmenityRequest), handleCancelAmenityReservation);
  yield takeEvery(getType(actions.insertAmenityRequest), handleInsertAmenityReservation);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* amenitySaga() {
  yield all([fork(watchFetchRequest)]);
}

export default amenitySaga;
