import { Theme, createStyles } from "@material-ui/core";

const styles = ({ spacing, breakpoints, mixins, transitions }: Theme) =>
  createStyles({
    centerElement: {},
    headerlist: {
      [breakpoints.up("sm")]: {
        marginBottom: spacing.unit * 3
      }
    },
    icon: {
      [breakpoints.up("sm")]: {
        marginRight: spacing.unit * 3.125
      }
    },
    row: {
      [breakpoints.up("sm")]: {
        marginBottom: spacing.unit * 2.25
      }
    },
    rowcontents: {
      [breakpoints.up("sm")]: {
        paddingBottom: spacing.unit * 2.25
      }
    },
    textField: {
      marginLeft: spacing.unit,
      marginRight: spacing.unit
    }
  });
export default styles;
