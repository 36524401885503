import "cropperjs/dist/cropper.css";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  WithStyles,
  withStyles
} from "@material-ui/core";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import React, { Component } from "react";
import ReactCropper from "react-cropper";

import styles from "../../../global-styles";
import DragAndDropImage from "../components/DragAndDropImage";
import DialogHeader from "./DialogHeader";

interface Props extends WithWidth, WithStyles<typeof styles> {
  inputButtonText: string;
  dialogTitle: string;
  onChange?: any;
  aspectRatio?: number;
}

type AllProps = Props;

type MyState = {
  modalVisibility: boolean;
  showStep1: boolean;
  showStep2: boolean;
  avatar: File | undefined;
  modalSpinnerVisibility: boolean;
};

class ImageCropperInput extends Component<AllProps, MyState> {
  private cropper: ReactCropper | null;

  constructor(props: any) {
    super(props);
    this.state = {
      modalVisibility: false,
      showStep1: false,
      showStep2: false,
      avatar: undefined,
      modalSpinnerVisibility: false
    };
    this.cropper = null;
  }

  handleOpenSpinnerModal = () => {
    this.setState({ modalSpinnerVisibility: true });
  };
  handleCloseSpinnerModal = () => {
    this.setState({ modalSpinnerVisibility: false });
  };

  handleOpenModal = () => {
    this.setState({ modalVisibility: true, showStep1: true });
  };
  handleCloseModal = () => {
    this.setState({ modalVisibility: false, showStep1: false, showStep2: false });
  };
  handleNavigateToStep2 = (values: any) => {
    if (this.state.avatar !== undefined) {
      this.setState({ modalVisibility: true, showStep1: false, showStep2: true });
    }
  };
  handleCrop = () => {};
  handleUpdateAvatar = (values: any) => {
    if (this.cropper) {
      this.handleOpenSpinnerModal();
      this.cropper.getCroppedCanvas().toBlob(blob => {
        this.props.onChange(blob);
        this.handleCloseModal();
      });
    } else {
      this.handleOpenSpinnerModal();
      this.props.onChange(values.avatar);
      this.handleCloseModal();
    }
  };
  render() {
    return (
      <React.Fragment>
        <Button onClick={this.handleOpenModal} color="primary">
          {this.props.inputButtonText}
        </Button>
        <Dialog
          open={this.state.modalVisibility}
          onClose={this.handleCloseModal}
          fullScreen={this.props.width === "xs" ? true : false}
          fullWidth
          maxWidth="md"
        >
          <DialogHeader title={this.props.dialogTitle} handleClose={this.handleCloseModal} />
          <div>
            {this.state.showStep1 && (
              <DialogContent>
                <DragAndDropImage
                  name="avatar"
                  onDrop={(droppedFile: File) => {
                    this.setState({ avatar: droppedFile });
                  }}
                />
                <DialogActions>
                  <Button onClick={this.handleCloseModal}>Cancel</Button>
                  <Button onClick={this.handleNavigateToStep2}>Continue</Button>
                </DialogActions>
              </DialogContent>
            )}
            {this.state.showStep2 && (
              <DialogContent>
                <ReactCropper
                  ref={(cropper: any) => {
                    this.cropper = cropper;
                  }}
                  src={URL.createObjectURL(this.state.avatar)}
                  style={{ height: 400, width: "100%" }}
                  aspectRatio={this.props.aspectRatio}
                  guides={false}
                  crop={this.handleCrop}
                />
                <DialogActions>
                  <Button onClick={this.handleCloseModal}>Cancel</Button>
                  <Button onClick={() => this.handleUpdateAvatar(this.state.avatar)}>Submit</Button>
                </DialogActions>
              </DialogContent>
            )}
            <Dialog open={this.state.modalSpinnerVisibility} onClose={this.handleCloseSpinnerModal}>
              <DialogContent>
                <CircularProgress />
              </DialogContent>
            </Dialog>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withWidth()(withStyles(styles)(ImageCropperInput));
