import { Button, Divider, Grid, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import InsufficientLeaseEnd from "../components/charges/insufficientLeaseEnd";
import InsufficientMTM from "../components/charges/insufficientMTM";
import InsufficientTermination from "../components/charges/insufficientTermination";
import SufficientLeaseEnd from "../components/charges/sufficientLeaseEnd";
import SufficientMTM from "../components/charges/sufficientMTM";
import SufficientTermination from "../components/charges/sufficientTermination";
import { NoticeToVacate } from "../models";
import entvstyles from "../styles";

const styles = combinestyles(entvstyles, globalstyles);

export interface ENTVChargesProps extends WithStyles<typeof styles> {
  onContinue: any;
  noticeInfo?: NoticeToVacate;
}

// interface PropsFromState {
//   categories: ServiceRequestCategory[];
// }

// interface PropsFromDispatch {
//   viewDetails: (item: ServiceRequest) => any;
// }

type State = {
  feeOption: number;
};

type AllProps = ENTVChargesProps & RouteComponentProps;

class ENTVCharges extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    feeOption: 0
  };

  componentDidMount() {
    setTimeout(() => window.scrollTo(0, 0), 400);
  }

  handleOnClick = (e: number) => {
    if (this.props.noticeInfo!.settlementFeeDisplay.toString() === "N" || e === 0) {
      this.setState({
        feeOption: 2
      });
    } else {
      this.setState({
        feeOption: e
      });
    }
  };

  render() {
    var concession = this.props.noticeInfo!.copyLanguage.find(e => {
      return e.languageId === "A8";
    });
    var settlement = this.props.noticeInfo!.copyLanguage.find(e => {
      return e.languageId === "A9";
    });
    var reRent = this.props.noticeInfo!.copyLanguage.find(e => {
      return e.languageId === "A10";
    });

    return (
      <Paper className={this.props.classes.paper}>
        <Grid container className={this.props.classes.grouping}>
          <Grid xs={12} item>
            {this.props.noticeInfo!.isEasyMove.toString() === "Y" && (
              <Typography variant="caption" className={this.props.classes.tablepadding} gutterBottom>
                STEP 3 OF 5
              </Typography>
            )}
            {this.props.noticeInfo!.isEasyMove.toString() === "N" && (
              <Typography variant="caption" className={this.props.classes.tablepadding} gutterBottom>
                STEP 3 OF 4
              </Typography>
            )}
          </Grid>
          <Grid xs={12} item>
            <Divider className={this.props.classes.tablepadding} />
          </Grid>
          <Grid item xs={12}>
            {(() => {
              switch (this.props.noticeInfo!.ntvCode) {
                case "E1":
                  return <SufficientLeaseEnd noticeInfo={this.props.noticeInfo} />;
                case "E2":
                  return <SufficientMTM noticeInfo={this.props.noticeInfo} />;
                case "E3":
                  return (
                    <SufficientTermination
                      onClick={(e: number) => this.handleOnClick(e)}
                      noticeInfo={this.props.noticeInfo}
                    />
                  );
                case "E4":
                  return <InsufficientLeaseEnd noticeInfo={this.props.noticeInfo} />;
                case "E5":
                  return <InsufficientMTM noticeInfo={this.props.noticeInfo} />;
                case "E6":
                  return <InsufficientTermination noticeInfo={this.props.noticeInfo} />;
                default:
                  return null;
              }
            })()}
          </Grid>
          <Grid xs={12} item>
            <Divider className={this.props.classes.tablepadding} />
          </Grid>
          <Grid container className={this.props.classes.grouping}>
            <Grid xs={8} item style={{ textAlign: "left" }}>
              {!(this.props.noticeInfo!.ntvCode === "E1" || this.props.noticeInfo!.ntvCode === "E2") && (
                <Typography
                  variant="body2"
                  className={this.props.classes.tablepadding}
                  style={{ paddingTop: "1.5rem", fontSize: ".875rem" }}
                >
                  By clicking Agree & Continue you accept the terms listed above.
                </Typography>
              )}
            </Grid>
            {this.props.noticeInfo!.ntvCode === "E3" && (
              <Grid xs={4} item style={{ textAlign: "right", paddingBottom: "1rem", paddingTop: "1rem" }}>
                {this.props.noticeInfo!.settlementFeeDisplay.toString() === "Y" && (
                  <Button
                    onClick={this.props.onContinue.bind(this, this.state.feeOption)}
                    color="primary"
                    variant="contained"
                    autoFocus
                    disabled={this.state.feeOption === 0}
                  >
                    Agree & Continue
                  </Button>
                )}
                {this.props.noticeInfo!.settlementFeeDisplay.toString() === "N" && (
                  <Button
                    onClick={this.props.onContinue.bind(this, this.state.feeOption)}
                    color="primary"
                    variant="contained"
                    autoFocus
                  >
                    Agree & Continue
                  </Button>
                )}
              </Grid>
            )}
            {!(this.props.noticeInfo!.ntvCode === "E3") && (
              <Grid xs={4} item style={{ textAlign: "right", paddingBottom: "1rem", paddingTop: "1rem" }}>
                <Button
                  onClick={this.props.onContinue.bind(this, this.state.feeOption)}
                  color="primary"
                  variant="contained"
                  autoFocus
                >
                  Agree & Continue
                </Button>
              </Grid>
            )}
          </Grid>
          {this.props.noticeInfo!.ntvCode === "E3" && (
            <Grid container className={this.props.classes.grouping}>
              <Grid xs={12} item>
                <Divider className={this.props.classes.tablepadding} />
              </Grid>
              <Grid xs={12} item>
                <Typography
                  variant="h4"
                  style={{ display: "inline-block", fontSize: ".875rem", paddingTop: "1rem", fontWeight: "bold" }}
                >
                  Concession Repayment (if any)
                </Typography>
                <Typography
                  component={"pre"}
                  variant="body2"
                  style={{ display: "inline-block", fontSize: ".75rem", paddingBottom: "1rem", whiteSpace: "pre-wrap" }}
                >
                  {concession!.copyLanguage}
                </Typography>
                <div />
                {this.props.noticeInfo!.settlementFeeDisplay.toString() === "Y" && (
                  <Grid>
                    <Typography
                      variant="h4"
                      style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold" }}
                    >
                      Settlement Agreement Terms
                    </Typography>
                    <Typography
                      component={"pre"}
                      variant="body2"
                      style={{
                        display: "inline-block",
                        fontSize: ".75rem",
                        paddingBottom: "1rem",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      {settlement!.copyLanguage}
                    </Typography>
                  </Grid>
                )}
                {this.props.noticeInfo!.settlementFeeDisplay.toString() === "N" && <Grid />}
                <div />
                <Typography variant="h4" style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold" }}>
                  Rent Until Re-Rented Terms
                </Typography>
                <Typography
                  variant="body2"
                  style={{ display: "inline-block", fontSize: ".75rem", whiteSpace: "pre-wrap" }}
                >
                  {reRent!.copyLanguage}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }
}

// const mapStateToProps = ({ servicereqs }: RootState) => ({
//   categories: servicereqs.serviceRequestCategories.flatData
// });

// const mapDispatchToProps = {
//   viewDetails: (item: ServiceRequest) => viewDetails(item)
// };

export default withRouter(
  connect()(withStyles(styles)(ENTVCharges))
  // mapStateToProps,
  // mapDispatchToProps
);
