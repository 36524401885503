// import { Reducer } from "redux";
import { combineReducers } from "redux";
import { ActionType, getType } from "typesafe-actions";

import * as group from "./actionsGroup";
import { DashboardFeedItem, Group } from "./models";

export type SocialGroupAction = ActionType<typeof group>;

export type GroupState = Readonly<{
  groupList: SocialGroupListState;
  groupUserList: SocialGroupUserListState;
  groupFeed: SocialGroupFeedState;
}>;

export type SocialGroupListState = Readonly<{
  loading: boolean;
  dataAll: Group[];
  errors?: string;
}>;

const initialGroupListState: SocialGroupListState = {
  dataAll: [],
  errors: "",
  loading: false,
};

export type SocialGroupUserListState = Readonly<{
  dataUser: Group[];
  errors?: string;
  loading: boolean;
}>;

const initialGroupUserState: SocialGroupUserListState = {
  dataUser: [],
  errors: undefined,
  loading: false,
};

export type SocialGroupFeedState = Readonly<{
  dataGroupFeed: DashboardFeedItem[];
  errors?: string;
  loading: boolean;
}>;

const initialGroupFeedState: SocialGroupFeedState = {
  dataGroupFeed: [],
  errors: undefined,
  loading: false,
};

export default combineReducers<GroupState, SocialGroupAction>({
  groupList: (state = initialGroupListState, action) => {
    switch (action.type) {
      case getType(group.groupFetchRequest): {
        return { ...state, loading: true };
      }
      case getType(group.groupFetchSuccess): {
        return { ...state, loading: false, dataAll: action.payload };
      }
      case getType(group.groupFetchError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default:
        return state;
    }
  },
  groupUserList: (state = initialGroupUserState, action) => {
    switch (action.type) {
      case getType(group.groupUserFetchRequest): {
        return { ...state, loading: true };
      }
      case getType(group.groupUserFetchSuccess): {
        return { ...state, loading: false, dataUser: action.payload };
      }
      case getType(group.groupUserFetchError): {
        return { ...state, loading: false, errors: action.payload };
      }
      case getType(group.leaveGroupPostSuccess): {
        return {
          ...state,
          leavingGroup: undefined,
          dataUser: state.dataUser.filter((x) => x.id !== action.payload.id),
        };
      }

      case getType(group.joinGroupPostSuccess): {
        return { ...state, leavingGroup: undefined, dataUser: [...state.dataUser, action.payload] };
      }
      default:
        return state;
    }
  },
  groupFeed: (state = initialGroupFeedState, action) => {
    switch (action.type) {
      case getType(group.groupFeedFetchRequest): {
        return { ...state, loading: true };
      }
      case getType(group.groupFeedFetchSuccess): {
        return { ...state, loading: false, dataGroupFeed: action.payload };
      }
      case getType(group.groupFeedFetchError): {
        return { ...state, loading: false, errors: action.payload };
      }
      case getType(group.createGroupRequest): {
        return { ...state, loading: true };
      }
      case getType(group.createPostGroupSuccess): {
        return { ...state, loading: false, dataGroupFeed: action.payload };
      }
      case getType(group.createPostGroupError): {
        return { ...state, loading: false, errors: action.payload };
      }
      case getType(group.addCommentGroupRequest): {
        return { ...state, loading: true };
      }
      case getType(group.addCommentGroupSuccess): {
        return { ...state, loading: false, dataGroupFeed: action.payload };
      }
      case getType(group.addCommentGroupError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default:
        return state;
    }
  },
});
