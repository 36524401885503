import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";
import { AccountActivity } from "./models";
import * as actions from "./actions";

export type FormerResidentAction = ActionType<typeof actions>;

export type FormerResidentState = Readonly<{
  loading: boolean;
  accountActivity: AccountActivity[];
  errors?: string;
  downloadError: boolean;
}>;

const initialState: FormerResidentState = {
  accountActivity: [],
  errors: undefined,
  loading: false,
  downloadError: false
};

const reducer: Reducer<FormerResidentState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.accountActivityRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.accountActivitySuccess): {
      return { ...state, loading: false, accountActivity: action.payload };
    }
    case getType(actions.accountActivityError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.downloadDocumentRequest): {
      return { ...state, loading: true, downloadError: false };
    }
    case getType(actions.downloadDocumentSuccess): {
      return { ...state, loading: false };
    }
    case getType(actions.downloadDocumentError): {
      return { ...state, loading: false, errors: action.payload, downloadError: true };
    }
    case getType(actions.resetDownloadError): {
      return { ...state, downloadError: false };
    }
    case getType(actions.paymentPlanRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.paymentPlanSuccess): {
      return { ...state, loading: false };
    }
    case getType(actions.paymentPlanError): {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
