import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { InjectedProps } from "@material-ui/core/withMobileDialog";
import * as React from "react";

interface Props {
  onContinue: any;
  open: boolean;
  officePhone: string;
}
type AllProps = Props & InjectedProps;

class ServiceReqFormEmergency extends React.Component<AllProps> {
  render() {
    const { fullScreen, open, officePhone } = this.props;

    return (
      <Dialog fullScreen={fullScreen} open={open}>
        <DialogTitle>Health and Safety</DialogTitle>
        <DialogContent>
          <Typography>
            Ensuring the health and safety of our employees and residents remains our top priority. Please call the
            office at {officePhone} to talk with our team about how we can assist with your service request.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onContinue.bind(this, this.state)} color="primary" variant="contained" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ServiceReqFormEmergency;
