import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Paper, Typography, withStyles, WithStyles } from "@material-ui/core";
import { UserInfoItem } from "../features/userInfo/models";

import uploadsstyles from "../features/uploads/styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";
import UploadsList from "../features/uploads/components/UploadsList";
import UploadForm from "../features/uploads/components/UploadForm";

const styles = combinestyles(uploadsstyles, globalstyles);

interface PropsFromState extends WithStyles<typeof styles> {
  user?: UserInfoItem;
}

type MyState = {
  cta?: string;
};

type AllProps = PropsFromState & RouteComponentProps<{ startDateIndex: string }>;

class UploadsPage extends React.Component<AllProps> {
  state: Readonly<MyState> = {
    cta: "App_Documents",
  };

  public componentDidMount() {
    window.scrollTo(0, 0);
  }

  public render() {
    var ctaState = this.state.cta;
    /*
    if (this.props.location.state && this.props.location.state.cta) {
      cta = this.props.location.state.cta;
    }*/

    const params = new URLSearchParams(this.props.location.search);
    const cta = params.get("cta") === null ? ctaState : params.get("cta");

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Typography variant="h1" className={this.props.classes.header}>
            Document Uploads
          </Typography>

          <Paper className={this.props.classes.paper}>
            <UploadForm callToAction={cta} />
            <UploadsList />
          </Paper>
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(UploadsPage);
