import { Avatar } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { RootState } from "../../../store/root-reducer";
import { GenerateCloudinaryThumbnailUrl } from "../../../utils/cloudinaryImageFormatter";
import { getActiveUserInfo } from "../../selectAccount/selector";
import SharedImagesConstants from "../../shared/constants/sharedImages";
import { fetchRequest } from "../actions";
import { UserInfoItem } from "../models";

interface Props {}

interface PropsFromState {
  user?: UserInfoItem;
}

interface PropsFromDispatch {
  fetchRequest: typeof fetchRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

class UserAvatar extends React.Component<AllProps> {
  render() {
    const { user } = this.props;
    if (!user) {
      return null;
    }
    const avatarUrl = user.profilePhotoUrl
      ? GenerateCloudinaryThumbnailUrl(user.profilePhotoUrl)
      : SharedImagesConstants.EMPTYAVATAR;
    return <Avatar src={avatarUrl}>{user.firstName}</Avatar>;
  }
}

const mapStateToProps = ({ userInfo, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount)
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  fetchRequest: (nameid: string) => dispatch(fetchRequest(nameid))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAvatar);
