import { Link, Card, CardContent, Typography, WithStyles, withStyles, Grid } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
// import ListAltIcon from "@material-ui/icons/ListAlt";
import EventIcon from "@material-ui/icons/Event";
import * as React from "react";

import styles from "./styles";

type AllProps = WithStyles<typeof styles>;

class WelcomeTile extends React.Component<AllProps> {
  render() {
    const { classes } = this.props;
    return (
      <Card style={{ width: "100%" }}>
        <CardContent>
          <Grid container>
            <Grid item xs={1}>
              <EventIcon className={classes.CheckListIcon} />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="h4" className={classes.WelcomeTileHeading}>
                Your move-in day is approaching
              </Typography>
              <Typography variant="body1" className={classes.WelcomeTileBody}>
                Review your Move-In Day Guide for arrival instruction, what to expect, and more.
              </Typography>
              <Link href="/communityguide" variant="body1" className={classes.WelcomeTileLink}>
                Move-in Day Guide
                <ArrowRightAltIcon className={classes.arrowRightAltIcon} />
              </Link>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(WelcomeTile);
