import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { getNotifications } from "../../utils/api";
import * as actions from "./actions";

import { showModalAction } from "../errorhandling/actions";

function* handleFetchNotifications(action: ReturnType<typeof actions.fetchRequestAsync.request>): Generator {
  try {
    const nameid = action.payload;

    let response: any = yield call(getNotifications, nameid);

    if (response.error) {
      yield put(actions.fetchRequestAsync.failure(response.error));
    } else {
      yield put(actions.fetchRequestAsync.success(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.fetchRequestAsync.failure(err));
    } else {
      yield put(actions.fetchRequestAsync.failure(new Error("An unknown error occured.")));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* watchFetchRequest() {
  yield takeEvery(getType(actions.fetchRequestAsync.request), handleFetchNotifications);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* amenitySaga() {
  yield all([fork(watchFetchRequest)]);
}

export default amenitySaga;
