import * as React from "react";

import { Button, TextField, Grid } from "@material-ui/core";
import { FormikProps } from "formik";

import { InputFormState } from "./ReferFriendRequest";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import referfriendstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(referfriendstyles, globalstyles);

interface MyProps {}

type AllProps = MyProps & WithStyles<typeof styles> & FormikProps<InputFormState>;

const ReferFriendRequestNewForm = (props: AllProps) => {
  const {
    values: {
      prospectFirstName,
      prospectLastName,
      prospectPhone,
      prospectEmail,
      prospectMessage,
      prospectMessageToFriend
    },
    errors,
    touched,
    handleSubmit,
    handleChange,
    // setFieldValue,
    isValid,
    setFieldTouched,
    classes
  } = props;

  const change = (field: any, e: any) => {
    e.persist();
    handleChange(e);
    // tslint:disable-next-line:no-console
    setFieldTouched(field, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs>
        <TextField
          id="prospectFirstName"
          name="prospectFirstName"
          label="First Name*"
          fullWidth
          value={prospectFirstName}
          helperText={touched.prospectFirstName ? errors.prospectFirstName : ""}
          error={touched.prospectFirstName && Boolean(errors.prospectFirstName)}
          onChange={change.bind(null, "prospectFirstName")}
          className={classes.textField}
          inputProps={{ maxLength: 20 }}
          style={{ marginBottom: "1rem" }}
        />
      </Grid>
      <Grid item xs>
        <TextField
          id="prospectLastName"
          name="prospectLastName"
          label="Last Name*"
          fullWidth
          value={prospectLastName}
          helperText={touched.prospectLastName ? errors.prospectLastName : ""}
          error={touched.prospectLastName && Boolean(errors.prospectLastName)}
          onChange={change.bind(null, "prospectLastName")}
          className={classes.textField}
          inputProps={{ maxLength: 25 }}
          style={{ marginBottom: "1rem" }}
        />
      </Grid>
      <Grid item xs>
        <TextField
          id="prospectPhone"
          name="prospectPhone"
          label="Phone*"
          fullWidth
          value={prospectPhone}
          helperText={touched.prospectPhone ? errors.prospectPhone : ""}
          error={touched.prospectPhone && Boolean(errors.prospectPhone)}
          onChange={change.bind(null, "prospectPhone")}
          className={classes.textField}
          inputProps={{ maxLength: 15 }}
          style={{ marginBottom: "1rem" }}
        />
      </Grid>
      <Grid item xs>
        <TextField
          id="prospectEmail"
          name="prospectEmail"
          label="Email*"
          fullWidth
          value={prospectEmail}
          helperText={touched.prospectEmail ? errors.prospectEmail : ""}
          error={touched.prospectEmail && Boolean(errors.prospectEmail)}
          onChange={change.bind(null, "prospectEmail")}
          className={classes.textField}
          inputProps={{ maxLength: 100 }}
          style={{ marginBottom: "1rem" }}
        />
      </Grid>
      <Grid item xs>
        <TextField
          id="prospectMessage"
          name="prospectMessage"
          label="What type of home might your friend be looking for?"
          fullWidth
          value={prospectMessage}
          helperText={touched.prospectMessage ? errors.prospectMessage : ""}
          error={touched.prospectMessage && Boolean(errors.prospectMessage)}
          onChange={change.bind(null, "prospectMessage")}
          className={classes.textField}
          multiline
          inputProps={{
            maxLength: 1000,
            rows: 2
          }}
          style={{ marginBottom: "1rem" }}
        />
      </Grid>
      <Grid item xs>
        <TextField
          id="prospectMessageToFriend"
          name="prospectMessageToFriend"
          label="Add a message to your friend"
          fullWidth
          value={prospectMessageToFriend}
          helperText={touched.prospectMessageToFriend ? errors.prospectMessageToFriend : ""}
          error={touched.prospectMessageToFriend && Boolean(errors.prospectMessageToFriend)}
          onChange={change.bind(null, "prospectMessageToFriend")}
          className={classes.textField}
          multiline
          inputProps={{
            maxLength: 1000,
            rows: 2
          }}
          style={{ marginBottom: "1rem" }}
        />
      </Grid>
      <Grid item />
      <Grid item xs style={{ textAlign: "right" }}>
        <Button type="submit" color="primary" variant="contained" disabled={!isValid}>
          Refer Friend
        </Button>
      </Grid>
    </form>
  );
};

export default withStyles(styles)(ReferFriendRequestNewForm);
