import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown/style.css";

import { Button, Grid, Typography } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import EventIcon from "@material-ui/icons/Event";
import { FormikProps } from "formik";
import * as React from "react";
import DatePicker from "react-datepicker";
import Dropdown from "react-dropdown";

import globalstyles from "../../../global-styles";
import { loadAvailableDurationSlots, loadAvailableSlotsByDateAndGroup } from "../../../utils/api";
import combinestyles from "../../../utils/combinestyles";
import { AmenityAvailableSlots, AmenityDurationSlots } from "../models";
import rentableitemstyles from "../styles";
import { InputFormState } from "./AmenityNewForm";

const styles = combinestyles(rentableitemstyles, globalstyles);

interface MyProps {}

type AllProps = MyProps & WithStyles<typeof styles> & FormikProps<InputFormState>;

type MyState = {
  rmPropId?: string;
  canSubmit: boolean;
  hideGroup: boolean;
  selectedDate: Date;
  reservationDate?: Date;
  reservationStartTime?: string;
  reservationEndTime?: Date;
  reservationEndDate?: Date;
  reservationDuration?: string;
  includedDates: Date[];
  groupSize: string;
  group: string[];
  startTimes: AmenityAvailableSlots[];
  durationTimes: AmenityDurationSlots[];
  startTime?: string;
};

type State = MyState;

class AmenityNewSelector extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      rmPropId: undefined,
      canSubmit: false,
      hideGroup: true,
      selectedDate: new Date(),
      reservationDate: undefined,
      reservationStartTime: undefined,
      reservationEndTime: undefined,
      reservationEndDate: undefined,
      reservationDuration: undefined,
      includedDates: [],
      groupSize: "",
      group: [],
      startTimes: [],
      durationTimes: [],
      startTime: undefined,
    };
  }

  componentDidMount = () => {
    let calendarDates: Date[] = [];
    if (this.props.values.maxGroupSize === 1) {
      this.setState({ hideGroup: false });
    }
    this.props.values.availableReservationDates.forEach((date) => {
      calendarDates.push(new Date(date.availableDate));
    });
    this.setState({ includedDates: calendarDates });
  };

  handleDateChange = (date: any) => {
    this.setState({
      reservationDate: date,
      selectedDate: date,
      groupSize: "",
      startTime: undefined,
      reservationDuration: undefined,
    });
    var mGroupSize = this.props.values.maxGroupSize;
    if (mGroupSize !== 1) {
      this.setMaxGuestSize();
    } else {
      this.handleSingleGroupSize("1", date);
    }
  };

  setMaxGuestSize = () => {
    let group = [];
    for (let i = 1; i <= this.props.values.maxGroupSize; i++) {
      group.push(i.toString());
    }
    this.setState({ group: group, groupSize: "" });
  };

  handleSingleGroupSize = (groupSize: string, date: any) => {
    var formattedDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    loadAvailableSlotsByDateAndGroup(
      this.props.values.rmPropId,
      this.props.values.amenityId.toString(),
      formattedDate,
      groupSize
    ).then((response) => {
      this.handleAPIResponse(response, groupSize);
    });
  };

  handleGroupSizeChange = (groupSize: string) => {
    var date = this.state.selectedDate;
    var formattedDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    loadAvailableSlotsByDateAndGroup(
      this.props.values.rmPropId,
      this.props.values.amenityId.toString(),
      formattedDate,
      groupSize
    ).then((response) => {
      this.handleAPIResponse(response, groupSize);
    });
  };

  handleAPIResponse = (response: any, groupSize: string) => {
    if (response.length !== 0) {
      this.setState({
        groupSize: groupSize,
        startTimes: response,
        startTime: undefined,
        reservationDuration: undefined,
      });
    } else {
      alert("There are no times available for your date and group size.");
      this.setState({
        reservationDate: undefined,
        selectedDate: new Date(),
        groupSize: "",
        startTime: undefined,
        reservationDuration: undefined,
      });
    }
  };

  handleStartTimeChange = (startTime: string) => {
    const startDate = this.state.startTimes.find((timeSlot) => timeSlot.timeStart === startTime);
    if (startDate) {
      var date = this.state.selectedDate;
      var formattedDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      var formattedStartTime = startTime.replace(":", "").replace(/\s/g, "");
      loadAvailableDurationSlots(
        this.props.values.rmPropId,
        this.props.values.amenityId.toString(),
        formattedDate,
        this.state.groupSize,
        formattedStartTime
      ).then((response) => {
        this.setState({
          durationTimes: response,
          reservationStartTime: startDate.reserveDate,
          startTime: startDate.timeStart,
        });
      });
    }
  };

  handleEndTimeChange = (duration: string) => {
    const durationAmount = this.state.durationTimes.find((durationSlot) => durationSlot.timeStart === duration);
    if (durationAmount) {
      this.setState({ reservationDuration: durationAmount.timeStart });
    }
    let durationTime = undefined;

    if (this.state.reservationDate !== undefined && this.state.startTime !== undefined) {
      let sDate = new Date(this.state.reservationDate.toDateString() + " " + this.parseEventTime(this.state.startTime));
      let newDuration = duration.replace("h", "").replace("m", "");
      durationTime = this.handleDuration(sDate, newDuration);
    }
    if (this.state.reservationDate !== undefined && this.state.startTime !== undefined && durationTime !== undefined) {
      let options = this.getTimeFormatOptions();
      this.addToCalendar(
        this.state.reservationDate,
        this.state.startTime,
        durationTime.toLocaleString("en-US", options),
        durationTime
      );
    }
  };

  handleDuration = (reservationDate: Date, duration: string) => {
    let sHours = reservationDate.getHours();
    let sMinutes = reservationDate.getMinutes();
    let [hours, minutes] = duration.split(":");
    let resStartDateTime = new Date(reservationDate);
    resStartDateTime.setHours(Number(sHours));
    resStartDateTime.setMinutes(Number(sMinutes));
    let durationTime = resStartDateTime;
    durationTime.setHours(resStartDateTime.getHours() + Number(hours));
    durationTime.setMinutes(resStartDateTime.getMinutes() + Number(minutes));
    return durationTime;
  };

  addToCalendar = (reservationDate: Date, startTime: string, endTime: string, durationDate: Date) => {
    this.props.values.reserveDate = reservationDate;
    this.props.values.timeStart = startTime.replace(/\?/g, "");
    this.props.values.timeEnd = endTime.replace(/\?/g, "");
    this.props.values.groupSize = Number(this.state.groupSize);
    this.props.values.reserveEndDate = new Date(durationDate.toDateString());
    this.setState({
      //dbEvents: this.state.dbEvents.filter(e => !e.canDelete),
      canSubmit: true,
    });
  };

  parseEventTime = (time: string): string => {
    let timeArr = time.split("");
    timeArr.splice(5, 0, " ");
    return timeArr.join("");
  };

  handleDateChangeRaw = (e: any) => {
    //prevent keyboard entry.
    e.preventDefault();
  };

  getTimeFormatOptions = () => {
    return {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
  };

  render() {
    const {
      values: { reserveDate, timeStart, timeEnd, groupSize },
      handleSubmit,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <React.Fragment>
          {false && (
            <div>
              {reserveDate},{timeStart},{timeEnd},{groupSize}{" "}
            </div>
          )}
          <Grid container direction="row" spacing={24} alignItems="center" style={{ marginBottom: "1rem" }}>
            <Grid item xs={12}>
              <Typography>Day of Reservation</Typography>
              <Grid container direction="row" alignItems="center" spacing={8}>
                <Grid item>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Click to select a date"
                    selected={this.state.reservationDate}
                    onChange={this.handleDateChange}
                    onChangeRaw={this.handleDateChangeRaw}
                    includeDates={this.state.includedDates}
                    //minDate={new Date()}
                    name="reservationdate"
                    autoComplete="off"
                    className={this.props.classes.datepicker}
                  />
                </Grid>
                <Grid item>
                  <EventIcon style={{ color: "rgba(0, 0, 0, 0.42)" }} />
                </Grid>
              </Grid>
            </Grid>
            {this.state.hideGroup && (
              <Grid item xs={12}>
                <Typography>Number of People (Including Yourself)</Typography>
                <Dropdown
                  options={this.state.group}
                  onChange={(option) => this.handleGroupSizeChange(option.value)}
                  value={this.state.groupSize}
                  placeholder="Select Amount"
                  className={this.props.classes.dropdown}
                  controlClassName={this.props.classes.dropdowncomponent}
                  placeholderClassName={this.props.classes.dropdownplaceholder}
                  menuClassName={this.props.classes.dropdownmenu}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography>Start Time of Reservation</Typography>
              <Dropdown
                options={this.state.startTimes.map((timeSlot) => timeSlot.timeStart)}
                onChange={(option) => this.handleStartTimeChange(option.value)}
                value={this.state.startTime}
                placeholder="Select Start Time"
                className={this.props.classes.dropdown}
                controlClassName={this.props.classes.dropdowncomponent}
                placeholderClassName={this.props.classes.dropdownplaceholder}
                menuClassName={this.props.classes.dropdownmenu}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Duration:</Typography>
              <Dropdown
                options={this.state.durationTimes.map((durationSlot) => durationSlot.timeStart)}
                onChange={(option) => this.handleEndTimeChange(option.value)}
                value={this.state.reservationDuration}
                placeholder="Select a duration"
                className={this.props.classes.dropdown}
                controlClassName={this.props.classes.dropdowncomponent}
                placeholderClassName={this.props.classes.dropdownplaceholder}
                menuClassName={this.props.classes.dropdownmenu}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            style={{
              marginRight: "10px",
              marginBottom: "30px",
              marginTop: "30px",
            }}
            disabled={!this.state.canSubmit}
          >
            Request it!
          </Button>
        </React.Fragment>
      </form>
    );
  }
}

export default withStyles(styles)(AmenityNewSelector);
