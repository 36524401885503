import { combineReducers } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { MyAccountDashboard } from "./models";
import * as myaccountdashboard from "./actions";

export type MyAccountDashboardAction = ActionType<typeof myaccountdashboard>;

export type MyAccountDashboardState = Readonly<{
  myAccountData: MyAccountDashboardDataState;
}>;

export type MyAccountDashboardDataState = Readonly<{
  loading: boolean;
  data: MyAccountDashboard | undefined;
  errors?: string;
}>;

const initialMyAccountDashboardDataState: MyAccountDashboardDataState = {
  data: undefined,
  errors: undefined,
  loading: false,
};

// tslint:disable-next-line:no-unused-expression

export default combineReducers<MyAccountDashboardState, MyAccountDashboardAction>({
  myAccountData: (state = initialMyAccountDashboardDataState, action) => {
    switch (action.type) {
      case getType(myaccountdashboard.myAccountDashboardFetchRequest): {
        return { ...state, loading: true };
      }
      case getType(myaccountdashboard.myAccountDashboardFetchSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(myaccountdashboard.myAccountDashboardFetchError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
});
