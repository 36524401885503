import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  withMobileDialog,
} from "@material-ui/core";
import { InjectedProps } from "@material-ui/core/withMobileDialog";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import * as React from "react";
import { connect } from "react-redux";

import { RootState } from "../../../store/root-reducer";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { guesttypesFetchRequest, hideForm, postRequest } from "../actions";
import { GuestItem, NewGuestAccessUpload } from "../models";
import Form from "./Form";

interface PropsFromState {
  user?: UserInfoItem;
  item: GuestItem | undefined;
  open: boolean;
  guestTypes: Map<string, string>;
  image: File | undefined;
}

type MyState = {
  modalVisibility: boolean;
};

interface PropsFromDispatch {
  hideForm: () => any;
  submitForm: (uploadRequest: NewGuestAccessUpload) => any;
  getGuestTypes: () => any;
}

type AllProps = MyState & PropsFromState & PropsFromDispatch & InjectedProps;

class GuestForm extends React.Component<AllProps> {
  state: Readonly<MyState> = {
    modalVisibility: false,
  };

  componentDidMount = () => {
    this.props.getGuestTypes();
  };

  submit = (upload: NewGuestAccessUpload) => {
    const submission: GuestItem = {
      type: upload.newGuestItem.type,
      startDate: upload.newGuestItem.startDate!,
      endDate: upload.newGuestItem.endDate!,
      notes: upload.newGuestItem.notes,
      guestInformation: upload.newGuestItem.guestInformation,

      permanent: upload.newGuestItem.permanent,
      allowKeys: upload.newGuestItem.allowKeys,
      inactive: upload.newGuestItem.inactive,
      deletePhoto: true, // TODO
      guestTitle: "", // TODO

      id: 0,
      nameId: this.props.user!.nameId,
      userId: "WEBRP",
      authorizedBy: this.props.user!.nameId,
      authorizedDate: new Date(Date.now()),
      lastModifiedDate: new Date(Date.now()),
      guestPhotoUrl: "",
    };

    const uploadRequest: NewGuestAccessUpload = {
      newGuestItem: submission,
      photo: upload.photo,
    };

    this.handleOpenModal();
    this.props.submitForm(uploadRequest);

    this.props.hideForm();
  };

  handleOpenModal = () => {
    this.setState({ modalVisibility: true });
  };
  handleCloseModal = () => {
    this.setState({ modalVisibility: false });
  };

  render() {
    const { fullScreen, open, item } = this.props;

    if (!item) {
      return <div />;
    }

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={this.props.hideForm}
          aria-labelledby="responsive-dialog-title"
          className="test1"
          fullWidth
          maxWidth="md"
        >
          <Grid container alignItems="center" className="test2">
            <Grid item xs={10}>
              <DialogTitle id="responsive-dialog-title">Add a new Guest</DialogTitle>
            </Grid>
            <Grid item xs={2}>
              <DialogActions>
                <Button onClick={this.props.hideForm} color="primary" autoFocus>
                  <CancelIcon />
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container>
              <Grid item sm={12}>
                <Form onSubmit={this.submit} guestTypes={this.props.guestTypes} />
              </Grid>
            </Grid>
          </DialogContent>
          <Dialog open={this.state.modalVisibility} onClose={this.handleCloseModal}>
            <DialogContent>
              <CircularProgress />
            </DialogContent>
          </Dialog>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ selectAccount, guest }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  item: guest.guestForm.selectedItem,
  open: guest.guestForm.formVisible,
  guestTypes: guest.guestForm.guestTypes,
  image: undefined,
});

const mapDispatchToProps: PropsFromDispatch = {
  hideForm: () => hideForm(),
  submitForm: (item: NewGuestAccessUpload) => postRequest(item),
  getGuestTypes: () => guesttypesFetchRequest(),
};

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(GuestForm));
