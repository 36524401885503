import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { Typography, WithStyles, withStyles, Grid, Paper, Button } from "@material-ui/core";

import { RouteComponentProps } from "react-router";
import { RootState } from "../../../store/root-reducer";

import referfriendstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import ReferFriendHeader from "./ReferFriendHeader";

import { referAmountFetchRequest } from "../actions";
import { ReferFriendAmount } from "../models";

const styles = combinestyles(referfriendstyles, globalstyles);

interface ReferFriendResultProps extends WithStyles<typeof styles> {}

interface PropsFromState {
  referFriendDataInfo: ReferFriendAmount;
  loading: boolean;
  errors?: string;
}

interface PropsFromDispatch {
  referAmountFetchRequest: typeof referAmountFetchRequest;
}

type AllProps = ReferFriendResultProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

class ReferFriendResult extends React.Component<AllProps> {
  render() {
    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <ReferFriendHeader />

          <Paper className={this.props.classes.paper} elevation={1}>
            <Grid container spacing={24}>
              <Grid item xs>
                <Grid container alignItems="center" spacing={24}>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <img
                      alt="Payment Complete"
                      src="https://media.equityapartments.com/image/upload/w_100,dpr_auto,f_auto/Content/portal/badges/paymentcomplete.svg"
                    />
                    <Typography variant="h3">Referral Sent</Typography>
                    <Typography variant="h2" style={{ paddingTop: ".5rem", paddingBottom: "1rem" }}>
                      You'll get ${this.props.referFriendDataInfo} when they move in!
                    </Typography>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      style={{ textAlign: "center", marginRight: "10px", marginBottom: "1rem", marginTop: "10px" }}
                      onClick={() => this.props.history.push("/")}
                    >
                      Return to Home
                    </Button>
                    <Typography variant="caption" style={{ textAlign: "center" }}>
                      Referral bonus is not given to residents until the referred resident has been at the community for
                      45 days.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ referFriend }: RootState): PropsFromState => ({
  referFriendDataInfo: referFriend.referFriendAmountData.data!,
  loading: referFriend.referFriendAmountData.loading,
  errors: referFriend.referFriendAmountData.errors,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  referAmountFetchRequest: (rmpropId: string) => dispatch(referAmountFetchRequest(rmpropId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReferFriendResult)));
