import { Grid, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { paymentFetchRequest } from "../actions";
import { PaymentData } from "../models";
import paymentstyles from "../styles";

const styles = combinestyles(paymentstyles, globalstyles);

interface IPaymentDashboardProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
}

interface PropsFromState {
  user?: UserInfoItem;
  loading: boolean;
  paymentDataInfo?: PaymentData;
  errors?: string;
}

interface PropsFromDispatch {
  paymentFetchRequest: typeof paymentFetchRequest;
}

type AllProps = IPaymentDashboardProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

class PaymentOnlineBill extends React.Component<AllProps> {
  render() {
    const { classes } = this.props;
    if (!this.props.paymentDataInfo) {
      return <div />;
    }

    return (
      <div className={classes.root}>
        <main className={classes.contentRightRail}>
          <Typography variant="h1" className={classes.header}>
            Online Banking
          </Typography>

          <Paper className={classes.paper}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="body1">
                  Pay your rent and other charges using your bank’s online bill pay service and schedule your payment to
                  be sent from your bank account to ours when you want it to arrive.
                </Typography>
                <br />
                <Typography variant="body1">
                  All policies set forth by your bank’s bill pay service will apply. Your resident account with us will
                  be credited when the payment is successfully received in our account.
                </Typography>
                <br />
                <Typography variant="body1">
                  On your bank’s bill pay site, select the option to set up a new payee (process varies by bank) and
                  enter the following information:
                </Typography>
                <br />
                <Typography variant="body1">Payee: Equity Residential </Typography>
                <Typography variant="body1">Payment Address: P.O. Box 1406, Augusta, GA 30903</Typography>
                <Typography variant="body1">
                  Customer Acct #: {this.props.paymentDataInfo.resAccount} (your Resident ID - including dashes)
                </Typography>
                <br />
                <Typography variant="body1">
                  Using this number helps to ensure timely delivery and posting to your account. Failure to use it or
                  entering it incorrectly may result in delayed delivery or the payment being returned to your bank,
                  which could result in late fees being assessed.
                </Typography>
                <br />
                <Typography variant="body1">
                  Once you submit a payment, please take notice of the expected delivery date. Two business days or less
                  indicates that your payment will be processed electronically. Five business days indicates that a
                  check will be printed and mailed, and therefore subject to potential postal and processing delays.
                  Setting up the payee correctly with a One or Two day estimated delivery will allow you the most
                  flexibility in terms of when the money is actually debited from your account.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ payment, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  paymentDataInfo: payment.paymentData.data!,
  loading: payment.paymentData.loading,
  errors: payment.paymentData.errors
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  paymentFetchRequest: (nameid: string) => dispatch(paymentFetchRequest(nameid))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentOnlineBill)));
