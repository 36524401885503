import { routerMiddleware } from "connected-react-router";
import { History } from "history";
import { applyMiddleware } from "redux";
import { legacy_createStore as createStore} from 'redux'
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import { loadUser } from "redux-oidc";
import createSagaMiddleware from "redux-saga";

import { RootState } from "./store/root-reducer";
import rootSaga from "./store/root-saga";
import rootReducer from "./store/root-reducer";
import userManager from "./utils/userManager";

//import rootEpic from './root-epic';

// const composeEnhancers = (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export default function configureStore(history: History, initialState?: RootState) {
  //userManager.events.addSilentRenewError((error) => {
  //    console.error('error while renewing the access token', error);
  //});

  //const createStoreWithMiddleware = compose(
  //    applyMiddleware(oidcMiddleware, logger)
  //)(createStore);
  // compose enhancers
  // const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  const composeEnhancers = composeWithDevTools({});
  const sagaMiddleware = createSagaMiddleware();
  // create store
  // return createStore(rootReducer, initialState!, enhancer);
  //return createStoreWithMiddleware(rootReducer, initialState!);

  console.log(process.env.NODE_ENV);
  /* 
    06/28/2023 - Paul Kurapey
    NODE_ENV === 'development' - working only on local machine.
    At all other environments DEV/Test/Production it returns "production"
    At this case during development use "Development Code" for DEV and QA
    
    Change to "Production Code" before last build for Production
    Just comment "Development Code" and uncomment "Production Code"
  */
  //=========================================================
  //           "Development Code"  
  
    const store = createStore(
      rootReducer(history),
      initialState!,
      composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware, logger))
    );
    loadUser(store, userManager);
    sagaMiddleware.run(rootSaga);
  
    return store;
  
  //     End "Development Code"      
  //=========================================================

  //=========================================================
  //           "Production Code"  
  // if ((process.env.NODE_ENV === 'development') || (process.env.NODE_ENV === 'test'))  {
  //   const store = createStore(
  //     rootReducer(history),
  //     initialState!,
  //     composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware, logger))
  //   );
  //   loadUser(store, userManager);
  //   sagaMiddleware.run(rootSaga);
  
  //   return store;
  // } 
  // else {
  //   const store = createStore(
  //     rootReducer(history),
  //     initialState!,
  //     composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  //   );
  //   loadUser(store, userManager);
  //   sagaMiddleware.run(rootSaga);
  
  //   return store;
  // }
  //     End "Production Code"  
  //=========================================================
}