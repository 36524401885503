import { createAction } from "typesafe-actions";

import { MyAccountDashboard } from "./models";

const MYACCOUNT_FETCH_REQUEST = "myaccountdashboard/FETCH_REQUEST";
const MYACCOUNT_FETCH_SUCCESS = "myaccountdashboard/FETCH_SUCCESS";
const MYACCOUNT_FETCH_ERROR = "myaccountdashboard/FETCH_ERROR";
export const myAccountDashboardFetchRequest = createAction(MYACCOUNT_FETCH_REQUEST)<string>();
export const myAccountDashboardFetchSuccess = createAction(MYACCOUNT_FETCH_SUCCESS)<MyAccountDashboard>();
export const myAccountDashboardFetchError = createAction(MYACCOUNT_FETCH_ERROR)<string>();
