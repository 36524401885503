import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";
import { loadPropertyDocumentsByPropertyId } from "../../utils/api";
import { getType } from "typesafe-actions";

import { showModalAction } from "../errorhandling/actions";

function* handleFetchPropertyDocumentsByNameId(action: ReturnType<typeof actions.propertyDocsRequest>): any {
  try {
    const rmpropid = action.payload;

    const response = yield call(loadPropertyDocumentsByPropertyId, rmpropid);

    if (response.error) {
      yield put(actions.propertyDocsError(response.error));
    } else {
      yield put(actions.propertyDocsSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.propertyDocsError(err.stack!));
    } else {
      yield put(actions.propertyDocsError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* watchFetchRequest() {
  yield takeEvery(getType(actions.propertyDocsRequest), handleFetchPropertyDocumentsByNameId);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* propertydocumentsSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default propertydocumentsSaga;
