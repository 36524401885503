import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import * as React from "react";

import { RenewalOfferTerm } from "../models";

import eventstyles from "../styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(eventstyles, globalstyles);

//import Radio from '@material-ui/core';
interface RenewalTermsProps extends WithStyles<typeof styles> {
  handleSubmit: any;
  preSelectedTerm: RenewalOfferTerm;
  termsList: RenewalOfferTerm[];
}

interface MyProps {
  onClose: any;
}

type AllProps = MyProps & RenewalTermsProps;

interface State {
  selectedTerm: RenewalOfferTerm;
}

class RenewalTerms extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    selectedTerm: this.props.preSelectedTerm
  };

  handleChange = (event: any) => {
    const term = this.props.termsList.filter(x => x.term === event.target.value)[0];
    this.setState({ selectedTerm: term });
  };
  submitClicked = () => {
    this.props.handleSubmit(this.state.selectedTerm);
  };

  render() {
    const { termsList, classes, onClose } = this.props;

    return (
      <React.Fragment>
        <DialogContent>
          <FormControl fullWidth>
            <RadioGroup
              aria-label="terms"
              name="leaseterm"
              className={classes.textField}
              value={this.state.selectedTerm.term}
              onChange={e => this.handleChange(e)}
            >
              <Table>
                <TableBody>{termsList.map(RenewalTerm)}</TableBody>
              </Table>
            </RadioGroup>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button type="reset" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained" onClick={this.submitClicked}>
            Select
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}

const RenewalTerm = (x: RenewalOfferTerm) => {
  return (
    <TableRow key={x.term}>
      <TableCell style={{ minWidth: "5rem" }}>
        <Typography>
          <FormControlLabel
            value={x.term}
            control={<Radio color="primary" />}
            label={x.term + " month lease"}
            style={{ marginRight: "0" }}
          />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>{x.amount}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(RenewalTerms);
