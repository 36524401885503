import * as React from "react";
import * as ReactDOM from "react-dom";
import { processSilentRenew } from "redux-oidc";
import WebFont from "webfontloader";
import "babel-polyfill";
import "react-app-polyfill/ie11";
import "url-search-params-polyfill";
import AppContainer from "./AppContainer";
import * as serviceWorker from "./serviceWorker";

var toBlob = require("canvas-to-blob");

toBlob.init();

WebFont.load({
  google: {
    families: ["Roboto:100,300,500", "sans-serif"],
  },
});

if (window.location.pathname === "/silent-renew") {
  processSilentRenew();
} else {
  const rootElement = document.getElementById("root") as HTMLElement;
  ReactDOM.render(<AppContainer />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
