import { Grid, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import { Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";
import * as Yup from "yup";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import RegexConstants from "../../shared/constants/regex";
import { UserInfoItem } from "../../userInfo/models";
import { referAmountFetchRequest, requestReferFriendRequest } from "../actions";
import { NewReferFriend, ReferFriendAmount, RpActivity } from "../models";
import ReferFriendHeader from "./ReferFriendHeader";
import ReferFriendRequestNewForm from "./ReferFriendRequestNewForm";
import referfriendstyles from "./styles";

const styles = combinestyles(referfriendstyles, globalstyles);

const ValidationSchema = Yup.object().shape({
  prospectFirstName: Yup.string()
    .required("First Name is required")
    .max(20, "20 character max"),
  prospectLastName: Yup.string()
    .required("Last Name is required")
    .max(25, "25 character max"),
  prospectPhone: Yup.string()
    .min(10, "Please enter a 10-digit phone number, area code first")
    .matches(RegexConstants.PHONE, "Please enter a 10-digit phone number, area code first"),
  prospectEmail: Yup.string()
    .email("Email is not valid")
    .max(100, "Email is not valid")
    .test("required-If", "Phone or Email is required", function() {
      let someVal = requiredIf(this.parent, "prospectPhone", "prospectEmail");
      return someVal;
    })
});

const requiredIf = (parent: any, dependent1: any, dependent2: any) => {
  let p = parent && parent[dependent1];
  let e = parent && parent[dependent2];

  let vv = true;
  if (p === undefined && e === undefined) {
    vv = false;
  }

  return vv;
};

interface ReferFriendResultProps extends WithStyles<typeof styles> {}

interface PropsFromState {
  user?: UserInfoItem;
  referFriendDataInfo: ReferFriendAmount;
  loading: boolean;
  errors?: string;
}

type MyState = {
  firstNameExist: boolean;
  lastNameExist: boolean;
};

export type InputFormState = {
  prospectFirstName: string;
  prospectLastName: string;
  prospectPhone: string;
  prospectEmail: string;
  prospectMessage: string;
  prospectMessageToFriend: string;
};

interface PropsFromDispatch {
  referAmountFetchRequest: typeof referAmountFetchRequest;
  requestReferFriendRequest: typeof requestReferFriendRequest;
  logActivity: typeof logActivity;
}

type AllProps = ReferFriendResultProps & PropsFromState & PropsFromDispatch & RouteComponentProps;
type State = MyState & InputFormState;

class ReferFriendRequest extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    firstNameExist: false,
    lastNameExist: false,
    prospectFirstName: "",
    prospectLastName: "",
    prospectPhone: "",
    prospectEmail: "",
    prospectMessage: "",
    prospectMessageToFriend: ""
  };

  handleSubmit = (values: any) => {
    const newReferFriend: NewReferFriend = {
      nameId: this.props.user!.nameId,
      prospectFirstName: values.prospectFirstName,
      prospectLastName: values.prospectLastName,
      prospectEmail: values.prospectEmail,
      prospectPhone: values.prospectPhone,
      prospectMessage: values.prospectMessage,
      prospectPropID: this.props.user!.propertyId,
      prospectMessageToFriend: values.prospectMessageToFriend
    };

    this.props.logActivity({
      actcode: "RF",
      note: "Resident referred a friend."
    });

    this.props.requestReferFriendRequest(newReferFriend);

    // TODO: Go to Result Page
    this.props.history.push("/myaccount/referfriend/ReferFriendResult");
  };

  render() {
    const {
      prospectFirstName,
      prospectLastName,
      prospectPhone,
      prospectEmail,
      prospectMessage,
      prospectMessageToFriend
    } = this.state;
    const initialValues = {
      nameId: "",
      prospectFirstName: prospectFirstName,
      prospectLastName: prospectLastName,
      prospectPhone: prospectPhone,
      prospectEmail: prospectEmail,
      prospectMessage: prospectMessage,
      prospectPropID: "",
      prospectMessageToFriend: prospectMessageToFriend
    };
    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <ReferFriendHeader />

          <Paper className={this.props.classes.paper} elevation={1} style={{ marginTop: "0rem" }}>
            <Typography style={{ marginBottom: "2rem" }}>
              Make your friends your neighbors and get credit for it! When you refer a friend and they move in, you'll
              recieve a ${this.props.referFriendDataInfo} rent credit. Simply fill out the form below to get started.
            </Typography>

            <Grid container direction="column" spacing={24}>
              <Grid item xs>
                <Typography variant="h2">Tell us about your friend</Typography>
                <Typography variant="caption">* Required field.</Typography>
              </Grid>
              <Formik
                initialValues={initialValues}
                validationSchema={ValidationSchema}
                onSubmit={values => {
                  this.handleSubmit(values);
                }}>
                  {(props) => <ReferFriendRequestNewForm {...props} /> }
                </Formik>

              <Grid item xs style={{ textAlign: "left" }}>
                <Typography variant="caption">This referral will be sent to your friend and your community.</Typography>

                <Typography variant="caption">
                  <b>
                    Referral bonus is not given to residents until the referred resident has been at the community for
                    45 days.
                  </b>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ referFriend, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  referFriendDataInfo: referFriend.referFriendAmountData.data!,
  loading: referFriend.referFriendAmountData.loading,
  errors: referFriend.referFriendAmountData.errors
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  referAmountFetchRequest: (rmpropId: string) => dispatch(referAmountFetchRequest(rmpropId)),
  logActivity: (activity: RpActivity) => dispatch(logActivity(activity)),
  requestReferFriendRequest: (payload: NewReferFriend) => dispatch(requestReferFriendRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReferFriendRequest));
