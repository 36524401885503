import { combineReducers } from "redux";
import { ActionType, getType } from "typesafe-actions";

import * as servicereqs from "./actions";
import {
  ServiceRequest,
  ServiceRequestCategory,
  ServiceRequestLocation,
  ServiceRequestQuickFix,
  ServiceRequestSubCategory,
  ServiceRequestTopLevelCategory,
  ServiceRequestType
} from "./models";

export type ServiceReqsAction = ActionType<typeof servicereqs>;

export type ServiceReqsState = Readonly<{
  serviceRequestList: ServiceReqListState;
  serviceRequestCategories: ServiceReqCategoryState;
  serviceReqTypes: ServiceReqTypesState;
  serviceReqQuickFixes: ServiceReqQuickFixesState;
  serviceReqLocations: ServiceReqLocationsState;
  serviceRequestDetails: ServiceReqDetailsState;
  serviceRequestForm: ServiceRequestFormState;
}>;

export type ServiceReqListState = Readonly<{
  loading: boolean;
  data: ServiceRequest[];
  errors?: string;
}>;

const initialServiceReqListState: ServiceReqListState = {
  data: [],
  errors: "",
  loading: false
};

export type ServiceReqTypesState = Readonly<{
  loading: boolean;
  data: ServiceRequestType[];
  errors?: string;
}>;

export type ServiceReqQuickFixesState = Readonly<{
  loading: boolean;
  data: ServiceRequestQuickFix[];
  errors?: string;
}>;

const initialServiceReqQuickFixes: ServiceReqQuickFixesState = {
  data: [],
  errors: "",
  loading: false
};

export type ServiceReqLocationsState = Readonly<{
  loading: boolean;
  data: ServiceRequestLocation[];
  errors?: string;
}>;

const initialServiceReqLocations: ServiceReqLocationsState = {
  data: [],
  errors: "",
  loading: false
};

export type ServiceReqCategoryState = Readonly<{
  loading: boolean;
  flatData: ServiceRequestCategory[];
  hierarchicalData: ServiceRequestTopLevelCategory[];
  errors?: string;
}>;

const initialServiceReqCategoryState: ServiceReqCategoryState = {
  flatData: [],
  hierarchicalData: [],
  errors: "",
  loading: false
};

export type ServiceReqDetailsState = Readonly<{
  selectedItem?: ServiceRequest;
  detailsVisible: boolean;
}>;

const initialServiceReqTypesState: ServiceReqTypesState = {
  data: [],
  errors: "",
  loading: false
};

export type ServiceRequestFormState = Readonly<{
  selectedTopLevelCategory?: ServiceRequestTopLevelCategory;
  selectedSubCategory?: ServiceRequestSubCategory;
  selectedType?: ServiceRequestType;
  selectedLocation?: ServiceRequestLocation;
  phoneNumber: string;
  additionalDetails: string;
  havePet: boolean;
  haveAlarm: boolean;
}>;

const initialServiceRequestFormState: ServiceRequestFormState = {
  phoneNumber: "12345",
  additionalDetails: "",
  havePet: false,
  haveAlarm: false
};

export default combineReducers<ServiceReqsState, ServiceReqsAction>({
  serviceRequestList: (state = initialServiceReqListState, action) => {
    switch (action.type) {
      case getType(servicereqs.listFetchRequest): {
        return { ...state, loading: true };
      }
      case getType(servicereqs.listFetchSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(servicereqs.listFetchError): {
        return { ...state, loading: false, errors: action.payload };
      }

      default:
        return state;
    }
  },
  serviceRequestCategories: (state = initialServiceReqCategoryState, action) => {
    switch (action.type) {
      case getType(servicereqs.categoriesFetchRequest): {
        return { ...state, loading: true };
      }
      case getType(servicereqs.categoriesFetchSuccess): {
        const topLevelCategories = [] as ServiceRequestTopLevelCategory[];
        const categories = action.payload.sort((x, y) => x.sortOrder - y.sortOrder); // Sort Service Request by Category
        categories.forEach(cat => {
          if (!topLevelCategories.some(x => x.categoryName === cat.category)) {
            const newtlc = {
              categoryName: cat.category,
              iconImage: cat.iconImage,
              subCategories: [] as ServiceRequestSubCategory[]
            } as ServiceRequestTopLevelCategory;
            topLevelCategories.push(newtlc);
          }
          const tlc = topLevelCategories.find(x => x.categoryName === cat.category)!;
          if (!tlc.subCategories.some(x => x.subCategoryName === cat.subCategory)) {
            // TODO Fix
            const newSubCat = {
              subCategoryName: cat.subCategory,
              categories: [] as ServiceRequestCategory[]
            } as ServiceRequestSubCategory;
            tlc.subCategories.push(newSubCat);
          }
          const subcat = tlc.subCategories.find(x => x.subCategoryName === cat.subCategory)!;
          subcat.categories.push(cat);
        });

        // Sort Service Request by Sub-Category
        topLevelCategories.forEach(cat => {
          cat.subCategories = cat.subCategories.sort((x, y) => {
            if (x.subCategoryName < y.subCategoryName) {
              return -1;
            }
            if (x.subCategoryName > y.subCategoryName) {
              return 1;
            }
            return 0;
          });
        });

        return {
          ...state,
          loading: false,
          flatData: action.payload.sort((x, y) => x.sortOrder - y.sortOrder),
          hierarchicalData: topLevelCategories
        };
      }
      case getType(servicereqs.categoriesFetchError): {
        return { ...state, loading: false, errors: action.payload };
      }

      default:
        return state;
    }
  },
  serviceReqTypes: (state = initialServiceReqTypesState, action) => {
    switch (action.type) {
      case getType(servicereqs.typesFetchRequest): {
        return { ...state, loading: true };
      }
      case getType(servicereqs.typesFetchSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(servicereqs.typesFetchError): {
        return { ...state, loading: false, errors: action.payload };
      }

      default:
        return state;
    }
  },
  serviceReqQuickFixes: (state = initialServiceReqQuickFixes, action) => {
    switch (action.type) {
      case getType(servicereqs.quickFixesFetchRequest): {
        return { ...state, loading: true };
      }
      case getType(servicereqs.quickFixesFetchSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(servicereqs.quickFixesFetchError): {
        return { ...state, loading: false, errors: action.payload };
      }

      default:
        return state;
    }
  },
  serviceReqLocations: (state = initialServiceReqLocations, action) => {
    switch (action.type) {
      case getType(servicereqs.locationsFetchRequest): {
        return { ...state, loading: true };
      }
      case getType(servicereqs.locationsFetchSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(servicereqs.locationsFetchError): {
        return { ...state, loading: false, errors: action.payload };
      }

      default:
        return state;
    }
  },
  serviceRequestDetails: (state = { selectedItem: undefined, detailsVisible: false }, action) => {
    switch (action.type) {
      case getType(servicereqs.viewDetails): {
        return { ...state, selectedItem: action.payload, detailsVisible: true };
      }

      case getType(servicereqs.hideDetails): {
        return { ...state, detailsVisible: false };
      }

      default:
        return state;
    }
  },
  serviceRequestForm: (state = initialServiceRequestFormState, action) => {
    switch (action.type) {
      case getType(servicereqs.selectTopLevelCategory): {
        return { ...state, selectedTopLevelCategory: action.payload };
      }
      case getType(servicereqs.selectSubCategory): {
        return { ...state, selectedSubCategory: action.payload };
      }
      case getType(servicereqs.selectType): {
        return { ...state, selectedType: action.payload };
      }
      case getType(servicereqs.selectLocation): {
        return { ...state, selectedLocation: action.payload };
      }
      case getType(servicereqs.postRequest): {
        return state;
      }
      case getType(servicereqs.postSuccess): {
        return state;
      }
      default:
        return state;
    }
  }
});
