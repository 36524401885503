import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, withMobileDialog } from "@material-ui/core";
import { InjectedProps } from "@material-ui/core/withMobileDialog";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import * as React from "react";
import { connect } from "react-redux";

import { RootState } from "../../../store/root-reducer";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { UserInfoItem } from "../../userInfo/models";
import { guesttypesFetchRequest, hideDetails, putRequest } from "../actions";
import { GuestAccessUpload, GuestItem, RpActivity } from "../models";
import FormEdit from "./FormEdit";

interface PropsFromState {
  user?: UserInfoItem;
  item: GuestItem | undefined;
  open: boolean;
  guestTypes: Map<string, string>;
  image: File | undefined;
}

interface PropsFromDispatch {
  hideDetails: () => any;
  submitForm: (uploadRequest: GuestAccessUpload) => any;
  getGuestTypes: () => any;
  logActivity: typeof logActivity;
}

type AllProps = PropsFromState & PropsFromDispatch & InjectedProps;

class GuestDetails extends React.Component<AllProps> {
  submit = (upload: GuestAccessUpload) => {
    const submission: GuestItem = {
      type: upload.guestItem.type,
      startDate: upload.guestItem.startDate,
      endDate: upload.guestItem.endDate,
      notes: upload.guestItem.notes,
      guestInformation: upload.guestItem.guestInformation,

      permanent: upload.guestItem.permanent,
      allowKeys: upload.guestItem.allowKeys,
      inactive: upload.guestItem.inactive,
      deletePhoto: upload.guestItem.deletePhoto, // TODO
      guestTitle: "", // TODO

      id: upload.guestItem.id,
      nameId: this.props.user!.nameId,
      userId: "WEBRP", //this.props.user.profile.puid,
      authorizedBy: this.props.user!.nameId,
      authorizedDate: new Date(Date.now()),
      lastModifiedDate: new Date(Date.now()),
      guestPhotoUrl: ""
    };

    const uploadRequest: GuestAccessUpload = {
      guestItem: submission,
      photo: upload.photo
    };

    this.props.submitForm(uploadRequest);
    // this.props.submitForm(submission);

    this.props.logActivity({
      actcode: "GU",
      note: `Guest updated: ${submission.guestInformation}`
    });

    this.props.hideDetails();
  };

  render() {
    const { fullScreen, open, item } = this.props;

    if (!item) {
      return <div />;
    }

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={this.props.hideDetails}
          aria-labelledby="responsive-dialog-title"
        >
          <Grid container alignItems="center">
            <Grid item xs={10}>
              <DialogTitle id="responsive-dialog-title">Edit Guest Info</DialogTitle>
            </Grid>
            <Grid item xs={2}>
              <DialogActions>
                <Button onClick={this.props.hideDetails} color="primary" autoFocus>
                  <CancelIcon />
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
          <DialogContent>
            <FormEdit onSubmit={this.submit} guestTypes={this.props.guestTypes} guest={item} />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ selectAccount, guest }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  item: guest.guestDetails.selectedItem,
  open: guest.guestDetails.detailsVisible,
  guestTypes: guest.guestForm.guestTypes,
  image: undefined,
});

const mapDispatchToProps: PropsFromDispatch = {
  hideDetails: () => hideDetails(),
  // submitForm: (item: GuestItem) => putRequest(item),
  submitForm: (item: GuestAccessUpload) => putRequest(item),
  getGuestTypes: () => guesttypesFetchRequest(),
  logActivity: (act: RpActivity) => logActivity(act),
};

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(GuestDetails));
