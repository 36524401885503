import { Theme, createStyles } from "@material-ui/core";
import spacing from "@material-ui/core/styles/spacing";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    content: {
      width: "100%"
    },
    grid: {
      ...theme.mixins.gutters(),
      padding: spacing.unit * 3
    }
  });
export default styles;
