import { all, call, fork, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { store } from "../../AppContainer";
import { postActivity } from "../../utils/api";
import { getActiveUserInfo } from "../selectAccount/selector";
import * as actions from "./actions";

function* handleLogActivity(action: ReturnType<typeof actions.logActivity>) {
  try {
    const activity = action.payload;
    activity.nameid = getActiveUserInfo(store.getState().selectAccount)!.nameId;
    activity.appsource = "WEBRP";
    yield call(postActivity, activity);
  } catch (err) {
    return;
  }
}

function* watchFetchRequest() {
  yield takeEvery(getType(actions.logActivity), handleLogActivity);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* rentableSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default rentableSaga;
