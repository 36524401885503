import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  NativeSelect,
  Switch,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { InjectedProps } from "@material-ui/core/withMobileDialog";
import * as React from "react";

import ImageCropperInput from "../../shared/components/ImageCropperInput";
import { NewGuestAccessUpload, NewGuestItem } from "../models";
import * as constants from "../constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isAfter, isEqual } from "date-fns";
import EventIcon from "@material-ui/icons/Event";

import gueststyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const guestAccessDomain =
  "https://media.equityapartments.com/image/upload/fl_lossy,q_auto,dpr_auto,w_47,h_47,r_max,c_fill/fl_lossy,q_auto,dpr_auto/v1/GuestAccess";

const styles = combinestyles(gueststyles, globalstyles);

interface FormProps extends WithStyles<typeof styles> {
  onSubmit: (uploadRequest: NewGuestAccessUpload) => any;
  guestTypes: Map<string, string>;
}

interface State {
  guestInformation: string;
  guestInformationError: string;
  guestPhotoUrl: string;
  comments: string;
  startDate?: Date;
  startDateError: string;
  endDate?: Date;
  endDateError: string;
  guestType: string;
  guestTypeError: string;
  permanent: boolean;
  allowKeys: boolean;
  inactive: boolean;
  openTerms: boolean;
  image?: File;
  imageUrl?: string;
}

type AllProps = FormProps & InjectedProps;

class Form extends React.Component<AllProps, State> {
  handleImageChanged = (croppedImage: File) => {
    this.setState({ image: croppedImage, imageUrl: window.URL.createObjectURL(croppedImage) });
  };

  handleTermsDialogToggle = () => {
    this.setState({ openTerms: !this.state.openTerms });
  };

  handleContinueSaveGuest = (e: any) => {
    this.setState({ openTerms: false });
    this.onSubmit(e);
  };

  handleTermsClose = () => {
    this.setState({ openTerms: false });
  };

  private GetGuestPhotoUrl = (guestPhotoUrl: string) => {
    let res = "";
    if (guestPhotoUrl !== null && guestPhotoUrl !== undefined) {
      res = `${guestAccessDomain}/${guestPhotoUrl}`;
    } else {
      res = `https://static.thenounproject.com/png/191877-84.png`;
    }
    return res;
  };

  state = {
    guestInformation: "",
    guestPhotoUrl: "",
    guestInformationError: "",
    comments: "",
    startDate: new Date(Date.now()),
    startDateError: "",
    endDate: new Date(Date.now()),
    endDateError: "",
    guestType: "",
    guestTypeError: "",
    permanent: false,
    allowKeys: false,
    inactive: false,
    openTerms: false,
    image: undefined,
    imageUrl: "",
  };

  handleStartDateChange = (date: any) => {
    this.setState({ startDate: date });
    //this.setState({ endDate: undefined });
  };

  handleEndDateChange = (date: any) => {
    this.setState({ endDate: date });
  };

  handleDateChangeRaw = (e: any) => {
    //prevent keyboard entry.
    e.preventDefault();
  };

  change = (e: any) => {
    if (!e.target) {
      return;
    }
    switch (e.target.name) {
      case "guestInformation":
        this.setState({ guestInformation: e.target.value });
        break;
      case "comments":
        this.setState({ comments: e.target.value });
        break;
      case "guestType":
        this.setState({ guestType: e.target.value });
        break;
      case "permanent":
        this.setState({ permanent: e.target.checked });

        if (e.target.checked) {
          // start date will be today
          this.setState({ startDate: new Date(Date.now()) });
        }
        // end date will be null
        this.setState({ endDate: new Date(Date.now()) });

        break;
      case "allowKeys":
        this.setState({ allowKeys: e.target.checked });
        break;
    }
  };

  validate = () => {
    let isError = false;
    const errors = {
      guestInformationError: "",
      startDateError: "",
      endDateError: "",
      guestTypeError: "",
    };

    // validation rules:
    // guestInformation is required
    if (this.state.guestInformation.length === 0) {
      isError = true;
      errors.guestInformationError = "Name is required";
    }

    if (!this.state.permanent) {
      // make sure start date is not in the past
      if (!isDateGreaterThanYesterday(this.state.startDate)) {
        isError = true;
        errors.startDateError = "Start Date cannot be in the past.";
      }

      // make sure end date is not in the past
      if (!isDateGreaterThanYesterday(this.state.endDate)) {
        isError = true;
        errors.endDateError = "End Date cannot be in the past.";
      } else {
        //  make sure start date is less than or equal to the end date
        if (!isStartLessOrEqualToEnd(this.state.startDate, this.state.endDate)) {
          isError = true;
          errors.endDateError = "End Date cannot be less than Start Date. Please enter valid dates.";
        }
      }
    }
    // guest type is required
    if (this.state.guestType === "") {
      isError = true;
      errors.guestTypeError = "Guest Type is required";
    }

    this.setState({
      ...this.state,
      ...errors,
    });

    return isError;
  };

  onSubmit = (e: any) => {
    e.preventDefault();
    //this.props.onSubmit(this.state);
    // validate
    const err = this.validate();
    if (!err) {
      const {
        guestType,
        startDate,
        endDate,
        comments: notes,
        guestInformation,
        permanent,
        allowKeys,
        inactive,
      } = this.state;

      const newItem: NewGuestItem = {
        type: guestType,
        startDate: startDate,
        endDate: endDate,
        notes: notes,
        guestInformation: guestInformation,
        permanent: permanent,
        allowKeys: allowKeys,
        inactive: inactive,
      };

      const uploadRequest: NewGuestAccessUpload = {
        newGuestItem: newItem,
        photo: this.state.image,
      };

      this.props.onSubmit(uploadRequest);

      // clear form
      this.setState({
        guestInformation: "",
        guestInformationError: "",
        comments: "",
        startDate: new Date(Date.now()),
        startDateError: "",
        endDate: new Date(Date.now()),
        endDateError: "",
        guestType: "",
        guestTypeError: "",
        permanent: false,
        allowKeys: false,
        inactive: false,
        openTerms: false,
      });
      this.change({
        guestInformation: "",
        comments: "",
        startDate: new Date(Date.now()),
        endDate: new Date(Date.now()),
        guestType: "",
        permanent: false,
        allowKeys: false,
        inactive: false,
        openTerms: false,
      });
    } else {
      this.setState({ openTerms: false });
    }
  };

  render() {
    const { fullScreen } = this.props;

    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.openTerms}
          onClose={this.handleTermsClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              By submitting this guest access request, you are authorizing us to allow the person(s) listed above to
              enter your apartment during the date(s) shown. Unless otherwise indicated, it is not necessary for us or
              any member of our staff to accompany your guests/invitees to your apartment. By clicking "agree" below
              and, consistent with the provisions of your Lease, you acknowledge that you are responsible for the
              actions of your guests/invitees while they are present in the building and in your apartment. Further, by
              clicking "agree" below, you agree to release us from liability for any injury or loss caused by your
              guests/invitees as a result of our providing them access to the building and to your apartment. You can
              modify or terminate this request for guest access at any time.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => this.handleContinueSaveGuest(e)} color="primary" variant="contained">
              Agree
            </Button>
            <Button onClick={this.handleTermsClose} color="default" variant="contained" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <form>
          {this.state.imageUrl && (
            <Grid item>
              <Avatar
                alt=""
                src={this.state.imageUrl ? this.state.imageUrl : this.GetGuestPhotoUrl(this.state.guestPhotoUrl)}
              />
              <div onClick={() => this.setState({ image: undefined, imageUrl: "" })} style={{ marginLeft: "-2rem" }}>
                <img
                  alt="Remove"
                  src={constants.REMOVE_ICON}
                  style={{ position: "relative", top: "-1rem", right: "-4rem", width: "2rem", height: "2rem" }}
                />
              </div>
            </Grid>
          )}
          <ImageCropperInput
            inputButtonText={this.state.image! ? "Edit Photo" : "Add Photo"}
            dialogTitle="Guest Image"
            onChange={this.handleImageChanged}
            aspectRatio={1}
          />
          <TextField
            name="guestInformation"
            id="standard-required"
            label="Name"
            value={this.state.guestInformation}
            onChange={(e) => this.change(e)}
            error={this.state.guestInformationError !== ""}
            helperText={this.state.guestInformationError === "" ? "" : this.state.guestInformationError}
            margin="normal"
            fullWidth
          />
          <TextField
            name="comments"
            id="standard-textarea"
            label="Comments"
            value={this.state.comments}
            onChange={(e) => this.change(e)}
            helperText="Optional"
            margin="normal"
            multiline
            fullWidth
          />
          <FormControl margin="normal">
            <InputLabel shrink htmlFor="guestType">
              Guest Type
            </InputLabel>
            <NativeSelect
              name="guestType"
              value={this.state.guestType}
              inputProps={{
                name: "guestType",
                id: "guestType",
              }}
              onChange={(e) => this.change(e)}
              error={this.state.guestTypeError !== ""}
              input={<Input id="guestType" />}
            >
              <option value="">Select Guest Type</option>
              {[...this.props.guestTypes].map(([key, value]) => {
                return (
                  <option key={key} value={key}>
                    {value}
                  </option>
                );
              })}
            </NativeSelect>
            <FormHelperText error={!(this.state.guestTypeError === "")}>
              {this.state.guestTypeError === "" ? "" : this.state.guestTypeError}
            </FormHelperText>
          </FormControl>
          <Grid container spacing={24}>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name="permanent"
                      color="primary"
                      checked={this.state.permanent}
                      onChange={(e) => this.change(e)}
                    />
                  }
                  label="Permanent Access?"
                />
              </FormGroup>
            </Grid>
          </Grid>

          {!this.state.permanent && (
            <Grid container spacing={24}>
              <Grid item>
                <Typography>Start Date:</Typography>
                <label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Click to select a start date"
                    selected={this.state.startDate}
                    onChange={this.handleStartDateChange}
                    onChangeRaw={this.handleDateChangeRaw}
                    minDate={new Date(Date.now())}
                    name="startDate"
                    autoComplete="off"
                    popperPlacement="botom-start"
                    popperModifiers={{
                      flip: {
                        enabled: false,
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                      },
                    }}
                    className={this.props.classes.datepicker}
                  />
                  <EventIcon style={{ color: "rgba(0, 0, 0, 0.42)" }} />
                </label>
              </Grid>

              <Grid item>
                <Typography>End Date:</Typography>
                <label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Click to select a end date"
                    selected={new Date(this.state.endDate)}
                    onChange={this.handleEndDateChange}
                    onChangeRaw={this.handleDateChangeRaw}
                    minDate={new Date(Date.now())}
                    name="endDate"
                    autoComplete="off"
                    disabled={this.state.startDate === undefined}
                    popperPlacement="botom-start"
                    popperModifiers={{
                      flip: {
                        enabled: false,
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                      },
                    }}
                    className={this.props.classes.datepicker}
                  />
                  <EventIcon style={{ color: "rgba(0, 0, 0, 0.42)" }} />
                </label>
                <FormHelperText error={!(this.state.endDateError === "")}>
                  {this.state.endDateError === "" ? "" : this.state.endDateError}
                </FormHelperText>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={24}>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name="allowKeys"
                      color="primary"
                      checked={this.state.allowKeys}
                      onChange={(e) => this.change(e)}
                    />
                  }
                  label="Should we give this person a key?"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container justify="flex-end" spacing={24}>
            <Grid item>
              <Button onClick={this.handleTermsDialogToggle} variant="contained" color="primary">
                Add Guest
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(Form);

const isDateGreaterThanYesterday = (dateToCheck: Date) => {
  // date should be valid at this point

  const today = new Date(new Date().toDateString());
  var res = isAfter(dateToCheck, today);

  return res;
};

const isStartLessOrEqualToEnd = (start: Date, end: Date) => {
  // date should be valid at this point
  const startDate = start;
  const endDate = end;

  var res = isEqual(startDate, endDate) || isAfter(endDate, startDate);
  return res;
};
