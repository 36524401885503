import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { NewsItem } from "./models";
import * as news from "./actions";

export type NewsAction = ActionType<typeof news>;

export type NewsState = Readonly<{
  loading: boolean;
  data: NewsItem[];
  errors?: string;
}>;

const initialState: NewsState = {
  data: [],
  errors: undefined,
  loading: false
};

const reducer: Reducer<NewsState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(news.fetchRequest): {
      return { ...state, loading: true };
    }
    case getType(news.fetchSuccess): {
      return { ...state, loading: false, data: action.payload };
    }
    case getType(news.fetchError): {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
