import "react-datepicker/dist/react-datepicker.css";

import { Button, Grid, Tab, Tabs, TextField, Typography, WithStyles, withStyles } from "@material-ui/core";
import { Field, FormikProps } from "formik";
import * as React from "react";

import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import FileUploader from "../components/common/FileUploader";
import GroupSelector from "../components/common/GroupSelector";
import { DISCUSSION_ITEM, EVENT_ITEM, FOR_SALE_ITEM } from "../constants";
import { Group } from "../models";
import EventDateSelect from "./EventsCalendar/EventDateSelect";
import { InputFormState } from "./NewPost";
import socialstyles from "./styles";

const styles = combinestyles(socialstyles, globalstyles);

interface MyProps extends WithStyles<typeof styles> {
  handleFileUpload: (files: File[]) => void;
  handleGroupSelection: (value: string) => void;
  groups: Group[];
  selectedGroup?: number;
}

type AllProps = MyProps & FormikProps<InputFormState>;

const NewPostForm = (props: AllProps) => {
  const {
    values: { activeTab, title, content, price, location },
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
    isValid,
    setFieldTouched,
    classes
  } = props;

  const change = (field: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(field, true, false);
  };

  const postTypes = [DISCUSSION_ITEM, FOR_SALE_ITEM, EVENT_ITEM];

  const changeTab = (event: object, value: number) => {
    setFieldValue("activeTab", value);
    setFieldValue("postType", postTypes[value]);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.newPostCategories}>
        <Typography style={{ marginBottom: "1rem" }}>Category</Typography>
        <Field
          name="activeTab"
          id="activeTab"
          render={() => (
            <Tabs value={activeTab} onChange={changeTab} indicatorColor={"primary"} centered={false}>
              <Tab label="Discussion" classes={{ root: classes.tab, selected: classes.tabSelected }} />
              <Tab label="For Sale" classes={{ root: classes.tab, selected: classes.tabSelected }} />
              <Tab label="Event" classes={{ root: classes.tab, selected: classes.tabSelected }} />
            </Tabs>
          )}
        />
      </div>
      <div className={classes.newPostContainer}>
        <Typography style={{ float: "right" }}>
          <small>*Required fields</small>
        </Typography>
        <Grid container direction="row" spacing={24}>
          <Grid item xs>
            <TextField
              id="title"
              name="title"
              label="Title*"
              value={title}
              helperText={touched.title ? errors.title : ""}
              error={touched.title && Boolean(errors.title)}
              onChange={change.bind(null, "title")}
              fullWidth
              className={classes.textField}
              inputProps={{
                maxLength: 250
              }}
            />
          </Grid>

          {activeTab === 1 && (
            <Grid item xs={4}>
              <TextField
                id="price"
                name="price"
                label="Price*"
                value={price}
                helperText={touched.price ? errors.price : ""}
                error={touched.price && Boolean(errors.price)}
                onChange={change.bind(null, "price")}
                fullWidth
                className={classes.textField}
                inputProps={{
                  maxLength: 25
                }}
              />
            </Grid>
          )}

          {activeTab === 2 && (
            <Grid item xs={12} sm={5}>
              <TextField
                id="location"
                name="location"
                label="Location*"
                value={location}
                helperText={touched.location ? errors.location : ""}
                error={touched.location && Boolean(errors.location)}
                onChange={change.bind(null, "location")}
                fullWidth
                className={classes.textField}
                inputProps={{
                  maxLength: 200
                }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              id="content"
              name="content"
              label="Content*"
              value={content}
              helperText={touched.content ? errors.content : ""}
              error={touched.content && Boolean(errors.content)}
              fullWidth
              onChange={change.bind(null, "content")}
              InputLabelProps={{
                shrink: true
              }}
              className={classes.textField}
              multiline
            />
          </Grid>

          {activeTab === 2 && <EventDateSelect {...props} />}

          <Grid item xs={12}>
            <FileUploader getFilesToUpload={props.handleFileUpload} />
          </Grid>
          <Grid item xs={12}>
            <GroupSelector
              handleGroupSelection={props.handleGroupSelection}
              groups={props.groups}
              selectedGroup={props.selectedGroup}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button type="submit" disabled={!isValid} variant="contained" color="primary" size="large">
              Post
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default withStyles(styles)(NewPostForm);
