import { WithStyles, withStyles } from "@material-ui/core";
import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { loadMoreStatements, maxStatementFetchRequest, statementFetchRequest } from "../features/mystatement/actions";
import StatementDetail from "../features/mystatement/components/StatementDetail";
import StatementHeader from "../features/mystatement/components/StatementHeader";
import { Statement } from "../features/mystatement/models";
import { RootState } from "../store/root-reducer";

import myleasestyles from "../features/mystatement/components/styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(myleasestyles, globalstyles);

interface MyStatementProps extends WithStyles<typeof styles> {}

interface PropsFromState {
  user?: UserInfoItem;
  data?: Statement;
  numStatements: number;
  maxNumStatements: any;
}

// interface State {
//   numStatements: number;
//   maxNumStatements: any;
// }

interface PropsFromDispatch {
  statementFetchRequest: typeof statementFetchRequest;
  maxNumStatementsFetchRequest: typeof maxStatementFetchRequest;
  loadMoreStatements: typeof loadMoreStatements;
}

type AllProps = PropsFromState & PropsFromDispatch & MyStatementProps;

class MyStatement extends React.Component<AllProps> {
  // readonly state: Readonly<State> = {
  //   numStatements: 1,
  //   maxNumStatements: 0
  // };

  componentDidMount() {
    const nameId = this.props.user!.nameId;
    const numStatements = this.props.numStatements;
    this.handleMore = this.handleMore.bind(this);
    this.props.maxNumStatementsFetchRequest({ nameId });
    this.props.statementFetchRequest({ nameId, numStatements });
    window.scrollTo(0, 0);
  }

  handleMore() {
    this.props.loadMoreStatements();
    const nameId = this.props.user!.nameId;
    const { numStatements } = this.props;
    this.props.statementFetchRequest({ nameId, numStatements });
  }

  render() {
    const { data, numStatements, maxNumStatements } = this.props;

    let balance = 0;
    let charges: any[] = [];
    let vacateDate: any;

    if (data !== undefined) {
      balance = data.statementGenerals.openBalance;
      charges = data.statementDetails;
      vacateDate = data.statementGenerals.vacateDate;
    }

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <StatementHeader balance={balance} />
          <StatementDetail
            onMore={this.handleMore}
            charges={charges}
            vacateDate={vacateDate}
            numStatements={numStatements}
            maxNumStatements={maxNumStatements}
          />
        </main>
      </div>
    );
  }
}

//const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
const mapStateToProps = ({ statement, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  data: statement.data,
  numStatements: statement.numStatements,
  maxNumStatements: statement.maxNumStatements
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  statementFetchRequest: (payload: { nameId: string; numStatements: number }) =>
    dispatch(statementFetchRequest(payload)),
  maxNumStatementsFetchRequest: (payload: { nameId: string }) => dispatch(maxStatementFetchRequest(payload)),
  loadMoreStatements: () => dispatch(loadMoreStatements())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyStatement));
