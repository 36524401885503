import * as React from "react";
import {
  Typography,
  withStyles,
  WithStyles,
  Divider,
  DialogContentText,
  DialogActions,
  Grid,
  Button,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";

import uploadsstyles from "../styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import { Field, Form, Formik } from "formik";
import FormikInputError from "../../shared/components/FormikInputError";
import { UploadDocument } from "../models"; //, UploadDocumentResult
import { UserInfoItem } from "../../userInfo/models";
import { uploadsPostRequest } from "../actions";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { RootState } from "../../../store/root-reducer";
import { Dispatch } from "redux";
import { connect } from "react-redux";

const styles = combinestyles(uploadsstyles, globalstyles);

//const FILE_NAME_REGEX = /^[a-zA-Z0-9_- ]{1,50}\.[a-zA-Z0-9]{1,10}$/;

interface Props extends WithStyles<typeof styles> {
  callToAction: string;
}

interface PropsFromState {
  user?: UserInfoItem;
  waiting: boolean;
}

interface PropsFromDispatch {
  uploadsPostRequest: typeof uploadsPostRequest;
  //uploadResult: UploadDocumentResult;
}

type MyState = {
  selectedFileName: string;
  selectedFile: File | null;
  notsubmittable: boolean;
  description: string;
  errorMsg: string;
  acceptableFileType: boolean;
};

type AllProps = Props & PropsFromState & PropsFromDispatch;

class UploadForm extends React.Component<AllProps, MyState> {
  private fileRef: any;

  constructor(props: any) {
    super(props);
    this.fileRef = React.createRef();
  }

  state: Readonly<MyState> = {
    selectedFileName: "",
    selectedFile: null,
    notsubmittable: true,
    description: "",
    errorMsg: "",
    acceptableFileType: false,
  };

  componentDidUpdate(prevProps: any) {
    // reset state after upload complete

    if (!this.props.waiting && prevProps.waiting) {
      this.setState({
        selectedFileName: "",
        selectedFile: null,
        notsubmittable: true,
        description: "",
        errorMsg: "",
        acceptableFileType: false,
      });
      this.fileRef.current.value = "";
    }
  }

  handleselectedFile = (evt: any) => {
    //var pattern = new RegExp(FILE_NAME_REGEX);

    this.setState({
      selectedFile: null,
      selectedFileName: "",
      notsubmittable: true,
      errorMsg: "",
      acceptableFileType: false,
    });

    this.setState({
      selectedFile: evt.target.files[0],
      selectedFileName: evt.target.files[0].name,
      notsubmittable: !(
        evt.target.files[0].type === "application/pdf" ||
        evt.target.files[0].type === "image/png" ||
        evt.target.files[0].type === "image/jpg" ||
        evt.target.files[0].type === "image/jpeg" ||
        evt.target.files[0].type === "image/gif" ||
        evt.target.files[0].type === "image/bmp" ||
        evt.target.files[0].type === "image/tiff" ||
        evt.target.files[0].type === "image/heic"
      ),
      acceptableFileType:
        evt.target.files[0].type === "application/pdf" ||
        evt.target.files[0].type === "image/png" ||
        evt.target.files[0].type === "image/jpg" ||
        evt.target.files[0].type === "image/jpeg" ||
        evt.target.files[0].type === "image/gif" ||
        evt.target.files[0].type === "image/bmp" ||
        evt.target.files[0].type === "image/tiff" ||
        evt.target.files[0].type === "image/heic",
      errorMsg:
        evt.target.files[0].type === "application/pdf" ||
        evt.target.files[0].type === "image/png" ||
        evt.target.files[0].type === "image/jpg" ||
        evt.target.files[0].type === "image/jpeg" ||
        evt.target.files[0].type === "image/gif" ||
        evt.target.files[0].type === "image/bmp" ||
        evt.target.files[0].type === "image/tiff" ||
        evt.target.files[0].type === "image/heic"
          ? ""
          : "Only PDF, JPG, GIF, BMP, PNG, TIFF and HEIC files are allowed.  Please choose a different file.",
    });

    if (this.state.errorMsg === "") {
      this.setState({
        selectedFile: evt.target.files[0],
        selectedFileName: evt.target.files[0].name,
        notsubmittable: !(this.state.description.length > 0),
        errorMsg: this.state.description.length > 0 ? "" : "File Description is required",
      });
    }

    // console.log(evt.target.files[0].name);
    // console.log("errorMsg: " + this.state.errorMsg);
    // console.log(this.state.description.length);
    // console.log("Not Submittable: " + this.state.notsubmittable);
    // console.log("Test Pattern: " + pattern.test(evt.name));

    let nameLength = evt.target.files[0].name.length;
    //console.log("File Name Length: " + nameLength);

    /*   let specCharExist =
      evt.target.files[0].name.includes("&") ||
      evt.target.files[0].name.includes("#") ||
      evt.target.files[0].name.includes("~") ||
      evt.target.files[0].name.includes("`") ||
      evt.target.files[0].name.includes("!") ||
      evt.target.files[0].name.includes("@") ||
      evt.target.files[0].name.includes("$") ||
      evt.target.files[0].name.includes("%") ||
      evt.target.files[0].name.includes("^") ||
      evt.target.files[0].name.includes("*") ||
      evt.target.files[0].name.includes(")") ||
      evt.target.files[0].name.includes("(") ||
      evt.target.files[0].name.includes("+") ||
      evt.target.files[0].name.includes("=") ||
      evt.target.files[0].name.includes("{") ||
      evt.target.files[0].name.includes("}") ||
      evt.target.files[0].name.includes("[") ||
      evt.target.files[0].name.includes("]") ||
      evt.target.files[0].name.includes("/") ||
      evt.target.files[0].name.includes("|") ||
      evt.target.files[0].name.includes("<") ||
      evt.target.files[0].name.includes(">") ||
      evt.target.files[0].name.includes(",") ||
      evt.target.files[0].name.includes("?") ||
      evt.target.files[0].name.includes("<");
*/
    //console.log("Special Char Exist: " + specCharExist);

    /* if (this.state.errorMsg === "") {
      this.setState({
        notsubmittable: specCharExist,
        errorMsg: specCharExist
          ? "File name cannot include special characters or exceed 50 characters. Please correct and re-submit."
          : ""
      });
    }*/

    if (this.state.errorMsg === "") {
      this.setState({
        notsubmittable: nameLength > 50,
        errorMsg: nameLength > 50 ? "File name cannot exceed 50 characters. Please correct and re-submit." : "",
      });
    }

    //console.log("Not Submittable 1: " + this.state.notsubmittable);
    //console.log("errorMsg 1: " + this.state.errorMsg);
  };

  showOpenFileDlg = () => {
    this.fileRef.current.click();
  };

  handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      description: event.target.value,
      notsubmittable: !(this.state.selectedFile && this.state.description.length > 0 && this.state.acceptableFileType),
      errorMsg: "",
    });
  };

  handleSubmit = () => {
    const payload: UploadDocument = {
      calltoaction: this.props.callToAction,
      nameid: this.props.user!.nameId,
      description: this.state.description.replace(/[^a-zA-Z0-9]/g, "_").replace(" ", "_"),
      file: this.state.selectedFile,
      rmpropid: this.props.user!.propertyId,
    };
    if (this.state.description.length < 1) {
      this.setState({
        notsubmittable: true,
        errorMsg: "File Description is required",
      });
    } else {
      this.props.uploadsPostRequest(payload);
    }
  };

  render() {
    return (
      <div>
        <Dialog open={this.props.waiting}>
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog>
        <Typography variant="h2" style={{ marginBottom: "0.3rem" }}>
          Uploads:
        </Typography>
        <Divider />
        <Formik
          initialValues={{
            description: "",
          }}
          onSubmit={this.handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <DialogContentText style={{ marginTop: "1rem" }}>File Description Required:</DialogContentText>
              <Field
                component="textarea"
                value={this.state.description}
                name="description"
                onChange={this.handleDescriptionChange}
                rows="10"
                className={this.props.classes.uploadsTextarea}
              />
              <FormikInputError>
                {errors.description && touched.description ? <div>{errors.description}</div> : null}
              </FormikInputError>
              <DialogActions style={{ marginBottom: "2rem" }}>
                <Grid container>
                  <Grid item xs={7} style={{ textAlign: "left" }}>
                    <label htmlFor="FileInput">
                      <Typography>{this.state.selectedFileName}</Typography>
                      <Button variant="outlined" onClick={this.showOpenFileDlg} color="primary" size="large">
                        Choose File
                      </Button>
                    </label>

                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="FileInput"
                      onChange={this.handleselectedFile}
                      ref={this.fileRef}
                      accept="image/bmp, image/gif, image/jpeg, image/png, image/tiff, application/pdf, image/heic"
                    />
                  </Grid>
                  <Grid item xs={5} style={{ textAlign: "right" }}>
                    <Button
                      variant="contained"
                      disabled={this.state.notsubmittable}
                      color="primary"
                      size="large"
                      type="submit"
                    >
                      Upload Documents
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          )}
        </Formik>
        <Typography variant="h5" style={{ textAlign: "left", color: "red" }}>
          {this.state.errorMsg}
        </Typography>
      </div>
    );
  }
}

const mapStateToProps = ({ uploads, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  waiting: uploads.loading,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  uploadsPostRequest: (payload: UploadDocument) => dispatch(uploadsPostRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UploadForm));
