import { Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import contactstyles from "../../../features/myprofile/components/styles";
import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { vehiclesFetchRequest } from "../actions";
import { VehicleInfo } from "../models";
import ProfileVehicleDetail from "./ProfileVehicleDetail";
import ProfileVehicleModal from "./ProfileVehicleModal";

const styles = combinestyles(contactstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  tileLocked: boolean;
}

interface PropsFromState {
  user?: UserInfoItem;
  vehicles?: VehicleInfo[];
}
interface PropsFromDispatch {
  vehiclesFetchRequest: typeof vehiclesFetchRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

class ProfileVehiclesTile extends Component<AllProps> {
  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.vehiclesFetchRequest({ nameId });
  }
  vehicleSortComparator = (vehicleA: VehicleInfo, vehicleB: VehicleInfo) => {
    if (vehicleA.year < vehicleB.year) {
      return 1;
    } else if (vehicleA.year === vehicleB.year) {
      if (vehicleA.make > vehicleB.make) {
        return 1;
      } else if (vehicleA.make === vehicleB.make) {
        if (vehicleA.model > vehicleB.model) {
          return 1;
        }
      }
    }
    return -1;
  };
  render() {
    const { vehicles, tileLocked, classes } = this.props;
    const lockedCssClass = tileLocked ? classes.lockedTile : "";
    if (vehicles) {
      const sortedVehicles = vehicles
        .sort((a, b) => this.vehicleSortComparator(a, b))
        .map(vehicle => <ProfileVehicleDetail key={vehicle.license} vehicleInfo={vehicle} />);
      return (
        <Paper className={[classes.paper, lockedCssClass].join(" ")}>
          <Typography variant="h3" style={{ marginBottom: "1rem" }}>
            Vehicles
          </Typography>
          <div style={{ marginBottom: "1rem" }}>
            <ProfileVehicleModal modalTitle="Add New Vehicle" editingExistingVehicle={false} />
          </div>
          {sortedVehicles}
        </Paper>
      );
    } else {
      return <React.Fragment />;
    }
  }
}
const mapStateToProps = ({ profile, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  vehicles: profile.vehicles
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  vehiclesFetchRequest: (payload: { nameId: any }) => dispatch(vehiclesFetchRequest(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProfileVehiclesTile));
