import * as React from "react";

import { Typography, WithStyles, withStyles, Grid } from "@material-ui/core";

import paymentstyles from "../styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(paymentstyles, globalstyles);

interface PaymentProps extends WithStyles<typeof styles> {
  displayBalance: number | undefined;
}

type AllProps = PaymentProps; // & PropsFromState;

class PaymentHeader extends React.Component<AllProps> {
  render() {
    let openBalance = "$0";
    if (this.props.displayBalance) {
      openBalance = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
        this.props.displayBalance
      );
    }

    return (
      <Grid container alignItems="center" style={{ width: "100%" }}>
        <Grid item xs>
          <Typography variant="h1" className={this.props.classes.header}>
            Make a Payment
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Typography>Balance</Typography>
          <Typography variant="h2">{openBalance}</Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(PaymentHeader);
