import { createAction } from "typesafe-actions";
import { AccountActivity, RequestPaymentPlan } from "./models";

const ACCOUNT_ACTIVITY_REQUEST = "account/ACCOUNT_ACTIVITY_REQUEST";
const ACCOUNT_ACTIVITY_SUCCESS = "account/ACCOUNT_ACTIVITY_SUCCESS";
const ACCOUNT_ACTIVITY_ERROR = "account/ACCOUNT_ACTIVITY_ERROR";
export const accountActivityRequest = createAction(ACCOUNT_ACTIVITY_REQUEST)<string>();
export const accountActivitySuccess = createAction(ACCOUNT_ACTIVITY_SUCCESS)<AccountActivity[]>();
export const accountActivityError = createAction(ACCOUNT_ACTIVITY_ERROR)<string>();

const DOWNLOAD_DOCUMENT_REQUEST = "account/DOWNLOAD_DOCUMENT_REQUEST";
const DOWNLOAD_DOCUMENT_SUCCESS = "account/DOWNLOAD_DOCUMENT_SUCCESS";
const DOWNLOAD_DOCUMENT_ERROR = "account/DOWNLOAD_DOCUMENT_ERROR";
const RESET_DOWNLOAD_DOCUMENT_ERROR = "account/RESET_DOWNLOAD_DOCUMENT_ERROR";
export const downloadDocumentRequest = createAction(DOWNLOAD_DOCUMENT_REQUEST)<{
  nameId: string;
  documentId: number;
  fileName: string;
  newWindow: Window | null;
}>();
export const downloadDocumentSuccess = createAction(DOWNLOAD_DOCUMENT_SUCCESS)();
export const downloadDocumentError = createAction(DOWNLOAD_DOCUMENT_ERROR)<string>();
export const resetDownloadError = createAction(RESET_DOWNLOAD_DOCUMENT_ERROR)();

const PAYMENT_PLAN_REQUEST = "account/PAYMENT_PLAN_REQUEST";
const PAYMENT_PLAN_SUCCESS = "account/PAYMENT_PLAN_SUCCESS";
const PAYMENT_PLAN_ERROR = "account/PAYMENT_PLAN_ERROR";
export const paymentPlanRequest = createAction(PAYMENT_PLAN_REQUEST)<RequestPaymentPlan>();
export const paymentPlanSuccess = createAction(PAYMENT_PLAN_SUCCESS)();
export const paymentPlanError = createAction(PAYMENT_PLAN_ERROR)<string>();
