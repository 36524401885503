import "react-dropdown/style.css";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  Typography,
  WithStyles,
  withStyles,
  withMobileDialog
} from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import EventIcon from "@material-ui/icons/Event";
import EditIcon from "@material-ui/icons/Edit";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as Yup from "yup";

import { RootState } from "../../../store/root-reducer";
import LabelledEditField from "../../myprofile/components/LabelledEditField";
import UsaStateDropDown from "../../myprofile/components/UsaStateDropDown";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { RpActivity } from "../../shared/models";
import { UserInfoItem } from "../../userInfo/models";
import {
  postAddressRequest,
  postRefundRequest,
  putCancelRequest,
  requestENTVSummaryRequest,
  moPostDateRequest,
} from "../actions";
import { CancelRequestData, ERefundValue, ForwardingAddress, NoticeSummary, SaveExpectedMoveOutDate } from "../models";
import { InjectedProps } from "@material-ui/core/withMobileDialog";

import style from "../styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import moment from "moment";
// import { contactModels } from "../../docusignlease";

const styles = combinestyles(style, globalstyles);

const ValidationSchemaAddress = Yup.object().shape({
  address1: Yup.string()
    .nullable()
    .when("city", {
      is: (value: any) => value && value.length > 0,
      then: Yup.string().required("Address1 is required"),
      otherwise: Yup.string(),
    })
    .when("zip", {
      is: (value: any) => value && value.length > 0,
      then: Yup.string().required("Address1 is required"),
      otherwise: Yup.string(),
    })
    .when("state", {
      is: (value: any) => value && value.length > 0,
      then: Yup.string().required("Address1 is required"),
      otherwise: Yup.string(),
    }),

  city: Yup.string()
    .nullable()
    .test("required-If", "City is required", function () {
      const check = checkCity(this.parent, {
        dependent1: "address1",
        dependent2: "city",
        dependent3: "state",
        dependent4: "zip",
      });
      return check;
    }),
  state: Yup.string()
    .nullable()
    .test("required-If", "State is required", function () {
      const check = checkState(this.parent, {
        dependent1: "address1",
        dependent2: "city",
        dependent3: "state",
        dependent4: "zip",
      });
      return check;
    }),

  zip: Yup.string()
    .nullable()
    .min(5, "5 digits is requried")
    .test("required-If", "Zip is required", function () {
      const check = checkZip(this.parent, {
        dependent1: "address1",
        dependent2: "city",
        dependent3: "state",
        dependent4: "zip",
      });
      return check;
    }),
});

const checkState = (parent: any, { dependent1, dependent2, dependent3, dependent4 }: any) => {
  const address1 = parent && parent[dependent1];
  const city = parent && parent[dependent2];
  const state = parent && parent[dependent3];
  const zip = parent && parent[dependent4];
  let vv = true;
  if (address1 && !state) {
    vv = false;
  } else if (zip && !state) {
    vv = false;
  } else if (city && !state) {
    vv = false;
  }
  return vv;
};

const checkCity = (parent: any, { dependent1, dependent2, dependent3, dependent4 }: any) => {
  const address1 = parent && parent[dependent1];
  const city = parent && parent[dependent2];
  const state = parent && parent[dependent3];
  const zip = parent && parent[dependent4];
  let vv = true;
  if (address1 && !city) {
    vv = false;
  } else if (zip && !city) {
    vv = false;
  } else if (state && !city) {
    vv = false;
  }
  return vv;
};

const checkZip = (parent: any, { dependent1, dependent2, dependent3, dependent4 }: any) => {
  const address1 = parent && parent[dependent1];
  const city = parent && parent[dependent2];
  const state = parent && parent[dependent3];
  const zip = parent && parent[dependent4];
  let vv = true;
  if (address1 && !zip) {
    vv = false;
  } else if (city && !zip) {
    vv = false;
  } else if (state && !zip) {
    vv = false;
  }
  return vv;
};

const ValidationSchemaRefund = Yup.object().shape({
  electronicEmail: Yup.string().max(100, "100 character limit").email("Email is not valid"),
});

export interface ENTVSummaryInfoProps extends WithStyles<typeof styles> {
  //onContinue: any;
  //noticeInfo: NoticeToVacate;
}

// interface Props extends WithStyles<typeof styles> {
//  // noticeSummary?: NoticeSummary;
//   noticeInfo: NoticeToVacate;
// }
interface PropsFromState {
  user?: UserInfoItem;
  myNoticeSummary?: NoticeSummary;
}
interface PropsFromDispatch {
  logActivity: typeof logActivity;
  postRefundRequest: typeof postRefundRequest;
  postAddressRequest: typeof postAddressRequest;
  putCancelRequest: typeof putCancelRequest;
  requestENTVSummaryRequest: typeof requestENTVSummaryRequest;
  moPostDateRequest: typeof moPostDateRequest;
}

type SummaryInfoState = {
  editingAddressTile: boolean;
  editingRefundTile: boolean;
  currentState: string;
  modalVisibility: boolean;
  selectedDate: Date;
  reservationDate?: Date;
};

type AllProps = PropsFromState & PropsFromDispatch & ENTVSummaryInfoProps & InjectedProps;

class ElectronicRefundTile extends Component<AllProps> {
  state: Readonly<SummaryInfoState> = {
    editingAddressTile: false,
    editingRefundTile: false,
    currentState: "",
    modalVisibility: false,
    selectedDate: new Date(),
    reservationDate: undefined
  };

  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.requestENTVSummaryRequest(nameId);

    if (this.props.myNoticeSummary!.forwardAddres) {
      this.setState({ currentState: this.props.myNoticeSummary!.forwardAddres.state });
    }
  }

  handleChange = (event: any) => {
    this.setState({ currentState: event.target.value });
  };

  handleEditAddressTile = () => {
    this.setState({ editingAddressTile: true });
  };

  handleEditRefundTile = () => {
    this.setState({ editingRefundTile: true });
  };

  handleSaveRefundEmailTile = (values: any) => {
    // Make Appropriate RPActivity Logs

    // Make API Call To PUT data
    const payload: ERefundValue = {
      nameID: this.props.user!.nameId,
      email: values.electronicEmail,
      phone: ""
    };
    this.props.postRefundRequest(payload);
    setTimeout(this.handleUpdateValues, 1000);
    this.setState({ editingRefundTile: false });
  };

  handleSaveAddressTile = (values: any) => {
    let address1 = values.address1 ? values.address1 : "";
    let address2 = values.address2 ? values.address2 : "";
    let city = values.city ? values.city : "";
    let state = values.state ? values.state : "";
    let zip = values.zip ? values.zip : "";
    // Make Appropriate RPActivity Logs

    // Make API Call To PUT data
    const payload: ForwardingAddress = {
      nameId: this.props.user!.nameId,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      zip: zip
    };
    this.props.postAddressRequest(payload);
    setTimeout(this.handleUpdateValues, 500);
    this.setState({ editingAddressTile: false });
  };

  handleUpdateValues = () => {
    const nameId = this.props.user!.nameId;
    this.props.requestENTVSummaryRequest(nameId);
  };

  handleOpenModal = () => {
    this.setState({ modalVisibility: true });
  };
  handleCloseModal = () => {
    this.setState({ modalVisibility: false });
    this.setState({
      reservationDate: undefined,
      selectedDate: undefined
    });
  };

  handleRequestToChange = () => {
    this.setState({ modalVisibility: true });
  };

  handleDateChange = (date: any) => {
    this.setState({
      reservationDate: date,
      selectedDate: date
    });
  };

  handleDateChangeRaw = (e: any) => {
    //prevent keyboard entry.
    e.preventDefault();
  };

  hundleRequestClick = () => {
    if (this.state.reservationDate! === undefined) {
      return;
    }

    const payload: SaveExpectedMoveOutDate = {
      nameId: this.props.user!.nameId,
      date: this.state.reservationDate!,
    };
    this.props.moPostDateRequest(payload);
    this.handleCloseModal();
  };

  render() {
    const { fullScreen, myNoticeSummary } = this.props;
    const { editingAddressTile, editingRefundTile } = this.state;
    let forwardingAddress: ForwardingAddress;
    let address1 = "";
    let address2 = "";
    let city = "";
    let state = "";
    let zip = "";

    if (myNoticeSummary!.forwardAddres) {
      forwardingAddress = myNoticeSummary!.forwardAddres;
      address1 = forwardingAddress.address1;
      address2 = forwardingAddress.address2;
      city = forwardingAddress.city;
      state = forwardingAddress.state;
      zip = forwardingAddress.zip;
    }

    if (!this.props.myNoticeSummary) {
      return <div />;
    }

    // RP1.10
    const changeMOHeader = this.props.myNoticeSummary!.copyLanguage.find((e) => {
      return e.languageId === "A34";
    });
    const changeMOCopy = this.props.myNoticeSummary!.copyLanguage.find((e) => {
      return e.languageId === "A35";
    });

    return (
      <div>
        <Paper className={this.props.classes.paper}>
          <form>
            <Grid container className={this.props.classes.grouping}>
              <Grid xs={12} item>
                <Typography gutterBottom variant="h3" style={{ display: "inline-block", marginBottom: "1rem" }}>
                  Information you have provided regarding your move:
                </Typography>
              </Grid>
              <div />
              <Grid xs={12} item style={{ marginBottom: "1rem" }}>
                <Typography gutterBottom variant="h3" style={{ display: "inline-block" }}>
                  Expected Move Date
                </Typography>
                <div />
                <div>
                  <Typography gutterBottom style={{ display: "inline-block" }}>
                    <Moment format="MM/DD/YY" date={this.props.myNoticeSummary!.expectedMoveOut} />
                  </Typography>
                  <Button
                    onClick={this.handleRequestToChange}
                    //color="secondary"
                    variant="text"
                    autoFocus
                    style={{ marginLeft: "2rem", color: "red" }}
                  >
                    <u>Request To Change</u>
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Divider className={this.props.classes.tablepadding} />
              </Grid>
            </Grid>
          </form>
          <div>
            <Grid container className={this.props.classes.grouping}>
              <Grid item xs={12}>
                <Formik
                  initialValues={{
                    address1: address1,
                    address2: address2,
                    city: city,
                    state: state,
                    zip: zip,
                  }}
                  validationSchema={ValidationSchemaAddress}
                  onSubmit={values => {
                    this.handleSaveAddressTile(values);
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <Grid 
                        container
                        item={true}
                        xs={12}
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                        style={{ marginBottom: "1rem" }}
                      >
                        {this.props.myNoticeSummary!.forwardAddres && (
                          <Typography variant="h3" style={{ display: "inline-block", marginTop: "1rem" }}>
                            Forwarding Address
                          </Typography>
                        )}
                        {!this.props.myNoticeSummary!.forwardAddres && (
                          <Typography variant="h3" style={{ display: "inline-block", marginTop: "1rem", color: "red" }}>
                            Missing Forwarding Address
                          </Typography>
                        )}
                        {!editingAddressTile && (
                          <Button onClick={this.handleEditAddressTile} color="primary">
                            <EditIcon />
                            Edit
                          </Button>
                        )}
                        {editingAddressTile && (
                          <Button type="submit" color="primary">
                            <EditIcon />
                            Save
                          </Button>
                        )}
                      </Grid>
                      <Grid container className={this.props.classes.grouping}>
                        <Grid item xs={5} style={{ marginRight: "1rem" }}>
                          <LabelledEditField
                            id="address1"
                            label="Address 1"
                            value={address1 ? address1 : ""}
                            editing={editingAddressTile}
                            maxLength="100"
                          />
                        </Grid>

                        {address2 && (
                          <Grid item xs={6}>
                            <LabelledEditField
                              id="address2"
                              label="Address 2"
                              value={address2}
                              editing={editingAddressTile}
                              maxLength="100"
                            />
                          </Grid>
                        )}
                        {!address2 && !address1 && editingAddressTile === false && (
                          <Grid item xs={6}>
                            <LabelledEditField
                              id="address2"
                              label="Address 2"
                              value={address2}
                              editing={editingAddressTile}
                              maxLength="100"
                            />
                          </Grid>
                        )}
                        {!address2 && address1 && editingAddressTile === false && (
                          <Grid item xs={6} style={{ marginTop: "1.4rem" }}>
                            <LabelledEditField
                              id="address2"
                              label="Address 2"
                              value={address2}
                              editing={editingAddressTile}
                              maxLength="100"
                            />
                          </Grid>
                        )}
                        {!address2 && editingAddressTile === true && (
                          <Grid item xs={6}>
                            <LabelledEditField
                              id="address2"
                              label="Address 2"
                              value={address2}
                              editing={editingAddressTile}
                              maxLength="100"
                            />
                          </Grid>
                        )}
                        <Grid item xs={3} style={{ marginRight: "1rem" }}>
                          <LabelledEditField
                            id="city"
                            label="City"
                            value={city}
                            editing={editingAddressTile}
                            maxLength="50"
                          />
                        </Grid>
                        {!editingAddressTile && (
                          <Grid item xs={3} style={{ marginRight: "1rem" }}>
                            <InputLabel>State</InputLabel>
                            {this.props.myNoticeSummary!.forwardAddres && <Typography>{state}</Typography>}
                            {!this.props.myNoticeSummary!.forwardAddres && <Typography>{""}</Typography>}
                            <hr />
                          </Grid>
                        )}
                        {editingAddressTile && (
                          <Grid item xs={3} style={{ width: "100%", marginBottom: "1rem" }}>
                            <UsaStateDropDown id="state" value={this.state.currentState} onChange={this.handleChange} />
                            <FormHelperText error>
                              {errors.state && touched.state ? <div>{errors.state}</div> : null}
                            </FormHelperText>
                            {/* <FormikInputError>
                            {errors.state && touched.state ? <div>{errors.state}</div> : null}
                          </FormikInputError> */}
                          </Grid>
                        )}
                        <Grid item xs={3} style={{ marginLeft: "1rem" }}>
                          <LabelledEditField
                            id="zip"
                            label="Zip"
                            value={zip}
                            editing={editingAddressTile}
                            maxLength="10"
                          />
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </div>
          {this.props.myNoticeSummary!.isERefund.toString() === "Y" &&
            this.props.myNoticeSummary!.isSingleLeaseHolder.toString() === "Y" && (
              <Grid item xs={12}>
                <Formik
                  initialValues={{
                    electronicEmail: this.props.myNoticeSummary!.electronicRefundEmail,
                  }}
                  validationSchema={ValidationSchemaRefund}
                  onSubmit={(values) => {
                    this.handleSaveRefundEmailTile(values);
                  }}
                >
                  <Form>
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      alignItems="center"
                      justify="space-between"
                      style={{ marginBottom: "1rem" }}
                    >
                      <Typography variant="h3" style={{ display: "inline-block", marginTop: "1rem" }}>
                        Electronic Refund Contact Information
                      </Typography>
                      {!editingRefundTile && (
                        <Button onClick={this.handleEditRefundTile} color="primary">
                          <EditIcon />
                          Edit
                        </Button>
                      )}
                      {editingRefundTile && (
                        <Button type="submit" color="primary">
                          <EditIcon />
                          Save
                        </Button>
                      )}
                    </Grid>
                    <Grid container className={this.props.classes.grouping}>
                      <Grid item xs={6} style={{ marginRight: "1rem" }}>
                        <LabelledEditField
                          id="electronicEmail"
                          label="Contact Email"
                          value={this.props.myNoticeSummary!.electronicRefundEmail}
                          editing={editingRefundTile}
                          maxLength="100"
                        />
                      </Grid>
                    </Grid>
                  </Form>
                </Formik>
              </Grid>
            )}
        </Paper>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.modalVisibility}
          onClose={this.handleCloseModal}
          fullWidth
          maxWidth="md"
          classes={{ paper: this.props.classes.paper }}
        >
          <Grid container spacing={24} alignItems="center">
            <Grid item xs={10}>
              <DialogTitle id="responsive-dialog-title">Request to Change Your Move Date</DialogTitle>
            </Grid>
            <Grid item xs={2}>
              <DialogActions>
                <Button onClick={this.handleCloseModal} color="primary" autoFocus>
                  <CancelIcon />
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <DialogContentText>{changeMOHeader!.copyLanguage}</DialogContentText>
                <Grid container direction="row" alignItems="center" spacing={8} style={{ marginBottom: "1rem" }}>
                  <Grid item style={{ marginTop: "2rem" }}>
                    <label>
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Click to select a date"
                        selected={moment(this.state.reservationDate).toDate()}
                        onChange={this.handleDateChange}
                        onChangeRaw={this.handleDateChangeRaw}
                        // includeDates={this.state.includedDates}

                        minDate={new Date()}
                        name="moveoutdate"
                        autoComplete="off"
                        popperPlacement="botom-start"
                        popperModifiers={{
                          flip: {
                            enabled: false,
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                          },
                        }}
                        className={this.props.classes.datepicker}
                      />
                      <EventIcon style={{ color: "rgba(0, 0, 0, 0.42)" }} />
                    </label>
                  </Grid>
                </Grid>
                <DialogContentText style={{ marginBottom: "1rem" }}>
                  <small>{changeMOCopy!.copyLanguage}</small>
                </DialogContentText>
                <Button variant="contained" color="primary" size="large" onClick={this.hundleRequestClick}>
                  Request
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ selectAccount, noticeToVacate }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  myNoticeSummary: noticeToVacate.myNoticeSummary.data,
  //contactInfo: profile.contactInfo
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  logActivity: (activity: RpActivity) => dispatch(logActivity(activity)),
  postRefundRequest: (refund: ERefundValue) => dispatch(postRefundRequest(refund)),
  postAddressRequest: (address: ForwardingAddress) => dispatch(postAddressRequest(address)),
  putCancelRequest: (cncl: CancelRequestData) => dispatch(putCancelRequest(cncl)),
  requestENTVSummaryRequest: (nameId: string) => dispatch(requestENTVSummaryRequest(nameId)),
  moPostDateRequest: (req: SaveExpectedMoveOutDate) => dispatch(moPostDateRequest(req)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withMobileDialog()(ElectronicRefundTile)));
