import React from "react";
import { Typography } from "@material-ui/core";

const style = {
  color: "red"
};
const FormikInputError = (props: any) => {
  return <Typography style={style}>{props.children}</Typography>;
};

export default FormikInputError;
