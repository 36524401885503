import "react-datepicker/dist/react-datepicker.css";

import { Grid, InputLabel, MenuItem, TextField, WithStyles, withStyles } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";
import DatePicker from "react-datepicker";

import FormikInputError from "../../../shared/components/FormikInputError";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import styles from "../styles";

type AllProps = WithStyles<typeof styles> & FormikProps<any>;

const EventDateSelect = (props: AllProps) => {
  const {
    values: { date, edate, rsvp, starttime, endtime },
    errors,
    touched,
    handleChange,
    setFieldValue,
    setFieldTouched,
    classes
  } = props;

  const dateChange = (field: any, value: any) => {
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
  };
  const handleDateChangeRaw = (e: any) => {
    //prevent keyboard entry.
    e.preventDefault();
  };
  const change = (field: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(field, true, false);
  };
  const rsvpChange = (field: any, e: any) => {
    if (e.target.value === "true") {
      e.target.value = true;
    } else if (e.target.value === "false") {
      e.target.value = false;
    }
    change(field, e);
  };

  return (
    <React.Fragment>
      <Grid item lg={6}>
        <Grid container direction="row" spacing={16}>
          <Grid item style={{ paddingBottom: "2rem" }}>
            <Grid container direction="column">
              <Grid item style={{ paddingBottom: ".75rem" }}>
                <InputLabel>Start Date*</InputLabel>
              </Grid>
              <Grid item>
                <label>
                  <DatePicker
                    selected={date}
                    onChange={dateChange.bind(null, "date")}
                    onChangeRaw={handleDateChangeRaw}
                    minDate={new Date()}
                    placeholderText="Please Select"
                    className={classes.datepicker}
                  />
                  <EventIcon style={{ color: "rgba(0, 0, 0, 0.42)" }} />
                </label>
              </Grid>
              <Grid item>
                <FormikInputError>{errors.date && touched.date ? <div>{errors.date}</div> : null}</FormikInputError>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ paddingBottom: "2rem" }}>
            <Grid container direction="column">
              <Grid item style={{ paddingBottom: ".75rem" }}>
                <InputLabel>Start Time*</InputLabel>
              </Grid>
              <Grid item>
                <label>
                  <DatePicker
                    selected={starttime}
                    onChange={dateChange.bind(null, "starttime")}
                    onChangeRaw={handleDateChangeRaw}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    dateFormat="h:mm aa"
                    timeCaption="Start"
                    placeholderText="-- : --"
                    className={classes.datepicker}
                  />
                  <ScheduleIcon style={{ color: "rgba(0, 0, 0, 0.42)" }} />
                </label>
              </Grid>
              <Grid item>
                <FormikInputError>
                  {errors.starttime && touched.starttime ? <div>{errors.starttime}</div> : null}
                </FormikInputError>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6}>
        <Grid container spacing={16}>
          <Grid item style={{ paddingBottom: "2rem" }}>
            <Grid container direction="column">
              <Grid item style={{ paddingBottom: ".75rem" }}>
                <InputLabel>End Date*</InputLabel>
              </Grid>
              <Grid item>
                <label>
                  <DatePicker
                    selected={edate}
                    onChange={dateChange.bind(null, "edate")}
                    onChangeRaw={handleDateChangeRaw}
                    minDate={new Date()}
                    placeholderText="Please Select"
                    className={classes.datepicker}
                  />
                  <EventIcon style={{ color: "rgba(0, 0, 0, 0.42)" }} />
                </label>
              </Grid>
              <Grid item>
                <FormikInputError>{errors.edate && touched.edate ? <div>{errors.edate}</div> : null}</FormikInputError>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ paddingBottom: "2rem" }}>
            <Grid container direction="column">
              <Grid item style={{ paddingBottom: ".75rem" }}>
                <InputLabel>End Time*</InputLabel>
              </Grid>
              <Grid item>
                <label>
                  <DatePicker
                    selected={endtime}
                    onChange={dateChange.bind(null, "endtime")}
                    onChangeRaw={handleDateChangeRaw}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    dateFormat="h:mm aa"
                    timeCaption="End"
                    placeholderText="-- : --"
                    className={classes.datepicker}
                  />
                  <ScheduleIcon style={{ color: "rgba(0, 0, 0, 0.42)" }} />
                </label>
              </Grid>
              <Grid item>
                <FormikInputError>
                  {errors.endtime && touched.endtime ? <div>{errors.endtime}</div> : null}
                </FormikInputError>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={16}>
          <Grid item style={{ paddingBottom: ".5rem" }}>
            <Grid container direction="column">
              <Grid item style={{ paddingBottom: ".5rem" }}>
                <InputLabel>RSVP*</InputLabel>
              </Grid>
              <Grid item>
                <TextField
                  id="rsvp"
                  name="rsvp"
                  select
                  value={rsvp === undefined ? "empty" : rsvp}
                  helperText={touched.rsvp ? errors.rsvp : ""}
                  error={touched.rsvp && Boolean(errors.rsvp)}
                  onChange={rsvpChange.bind(null, "rsvp")}
                  className={classes.textField}
                >
                  <MenuItem value="empty" disabled>
                    Please Select
                  </MenuItem>
                  <MenuItem value="false">No</MenuItem>
                  <MenuItem value="true">Yes</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles)(EventDateSelect);
