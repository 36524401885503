import DashboardIcon from "@material-ui/icons/Dashboard";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, RouteProps } from "react-router";
import { Route, Switch } from "react-router-dom";
import { UserInfo } from "./features/myprofile/models";
import { getActiveUserInfo } from "./features/selectAccount/selector";
import * as constants from "./features/shared/constants/constants";
import amenity from "./pages/amenity";
import contact from "./pages/contact";
import Dashboard from "./pages/dashboard";
import documents from "./pages/documents";
import entv from "./pages/entv";
import EventsDashboard from "./pages/events";
import FormerResidentDashboard from "./pages/formerresident";
import ApplicantDashboard from "./pages/applicantdashboard";
import CommunityGuide from "./pages/communityguide";
// import AdverseActionLetter from "./pages/adverseactionletter";
import CreditReport from "./pages/creditreport";
import CriminalReport from "./pages/criminalreport";
import DocuSignLease from "./pages/docusignlease";
import groupdetails from "./pages/groupdetails";
import groups from "./pages/groups";
import guest from "./pages/guest";
import MyAccount from "./pages/myaccount";
import mylease from "./pages/mylease";
import profile from "./pages/myprofile";
import mystatement from "./pages/mystatement";
import payment from "./pages/payment";
import propertydocuments from "./pages/propertydocuments";
import referFriend from "./pages/referfriend";
import renewal from "./pages/renewal";
import rentable from "./pages/rentable";
import serviceproviders from "./pages/serviceproviders";
import servicereq from "./pages/servicereq";
import { RootState } from "./store/root-reducer";
import moveinchecklist from "./pages/moveinchecklist";
import uploads from "./pages/uploads";

//import rentwithequity from "./pages/rentwithequity";
export interface MyRouteProps extends RouteProps {
  sidebarName?: string;
  icon: string | any;
  quickLinks?: [
    {
      sidebarName: string;
      path: string;
    }
  ];
}
interface PropsFromState {
  userInfo?: UserInfo;
}
export type RoutesComponentProps = PropsFromState & RouteComponentProps<{}> & { dispatch: any };
class RoutesComponent extends React.Component<RoutesComponentProps> {
  getRoutesArray = (): MyRouteProps[] => {
    let residentStatus =
      this.props.userInfo === null || this.props.userInfo === undefined ? "" : this.props.userInfo.residentStatus;
    let miCheckListStatus =
      this.props.userInfo === null || this.props.userInfo === undefined
        ? ""
        : this.props.userInfo.moveinCheckListActive;
    let displayPaymentMenuForApplicant =
      this.props.userInfo === null || this.props.userInfo === undefined ? "" : this.props.userInfo.displayPaymentMenu;

    if (residentStatus !== "") {
      if (residentStatus === constants.FORMER) {
        return formerResidentRoutesArray;
      } else if (residentStatus === constants.NEW && miCheckListStatus === "N") {
        return newResidentNoMICheckListRoutesArray;
      } else if (residentStatus === constants.NEW) {
        return newResidentRoutesArray;
      } else if (residentStatus === constants.APPLICANT && displayPaymentMenuForApplicant === "Y") {
        return applicantwithPaymentRoutesArray;
      } else if (residentStatus === constants.APPLICANT) {
        return applicantRoutesArray;
      } else {
        if (!this.props.userInfo!.renewalAvailable) {
          return currentResidentRoutesArray.filter((x) => x.sidebarName !== "Renew Lease");
        }
        return currentResidentRoutesArray;
      }
    }
    return [];
  };
  public render() {
    // user is signed in, return all your routes
    return (
      <Switch>
        {this.getRoutesArray().map((route, key) => {
          return <Route key={key} exact={route.exact} path={route.path} component={route.component} />;
        })}
        <Route path="/myaccount/mylease" component={mylease} />
      </Switch>
    );
  }
}
function mapStateToProps(state: RootState) {
  return {
    userInfo: getActiveUserInfo(state.selectAccount),
  };
}
export default connect(mapStateToProps)(RoutesComponent);
export const currentResidentRoutesArray: MyRouteProps[] = [
  {
    exact: true,
    sidebarName: "Home",
    path: "/",
    component: Dashboard,
    icon: DashboardIcon,
  },
  {
    exact: true,
    sidebarName: "My Account",
    path: "/myaccount",
    component: MyAccount,
    icon: DashboardIcon,
    quickLinks: [{ sidebarName: "MAKE A PAYMENT", path: "/payment" }],
  },
  {
    sidebarName: "View Credit Report",
    path: "/creditreport",
    component: CreditReport,
    icon: undefined,
  },
  {
    sidebarName: "View Criminal Report",
    path: "/criminalreport",
    component: CriminalReport,
    icon: undefined,
  },
  {
    sidebarName: "Renew Lease",
    path: "/renewal",
    component: renewal,
    icon: undefined,
  },
  {
    sidebarName: "Service",
    path: "/servicereqs",
    component: servicereq,
    icon: DashboardIcon,
    quickLinks: [{ sidebarName: "REQUEST SERVICE", path: "/servicereqs" }],
  },
  {
    sidebarName: "Groups",
    path: "/groups",
    component: groups,
    icon: DashboardIcon,
  },
  {
    exact: true,
    sidebarName: "Events",
    path: "/events",
    component: EventsDashboard,
    icon: DashboardIcon,
  },
  {
    sidebarName: "Community Guide",
    path: "/communityguide",
    component: CommunityGuide,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/guest",
    component: guest,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/amenity",
    component: amenity,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/entv",
    component: entv,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/rentable",
    component: rentable,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/mystatement",
    component: mystatement,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/groupdetails/:groupId",
    component: groupdetails,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/serviceproviders",
    component: serviceproviders,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/myprofile",
    component: profile,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/payment",
    component: payment,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/propertydocuments",
    component: propertydocuments,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/documents",
    component: documents,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/referfriend",
    component: referFriend,
    icon: undefined,
  },
  {
    // keep this at the bottom of each route collection

    sidebarName: "Get Help",
    path: "/contact",
    component: contact,
    icon: DashboardIcon,
  },
  // ,
  // {
  //   sidebarName: undefined,
  //   path: "/rentwithequity",
  //   component: rentwithequity,
  //   icon: undefined
  // }
];
export const newResidentRoutesArray: MyRouteProps[] = [
  {
    exact: true,
    sidebarName: "Home",
    path: "/",
    component: Dashboard,
    icon: DashboardIcon,
  },
  {
    exact: true,
    sidebarName: "My Account",
    path: "/myaccount",
    component: MyAccount,
    icon: DashboardIcon,
    quickLinks: [{ sidebarName: "MAKE A PAYMENT", path: "/payment" }],
  },
  {
    sidebarName: "Move-in Checklist",
    path: "/moveinchecklist",
    component: moveinchecklist,
    icon: DashboardIcon,
  },
  {
    sidebarName: "View Credit Report",
    path: "/creditreport",
    component: CreditReport,
    icon: undefined,
  },
  {
    sidebarName: "View Criminal Report",
    path: "/criminalreport",
    component: CriminalReport,
    icon: undefined,
  },
  {
    sidebarName: "Community Guide",
    path: "/communityguide",
    component: CommunityGuide,
    icon: undefined,
  },
  {
    sidebarName: "Groups",
    path: "/groups",
    component: groups,
    icon: DashboardIcon,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/guest",
    component: guest,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/amenity",
    component: amenity,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/entv",
    component: entv,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/rentable",
    component: rentable,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/mystatement",
    component: mystatement,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/groupdetails/:groupId",
    component: groupdetails,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/serviceproviders",
    component: serviceproviders,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/renewal",
    component: renewal,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/myprofile",
    component: profile,
    icon: undefined,
  },
  {
    exact: true,
    sidebarName: "Events",
    path: "/events",
    component: EventsDashboard,
    icon: DashboardIcon,
  },
  {
    sidebarName: undefined,
    path: "/payment",
    component: payment,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/propertydocuments",
    component: propertydocuments,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/referfriend",
    component: referFriend,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/uploads",
    component: uploads,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/docusignlease",
    component: DocuSignLease,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/documents",
    component: documents,
    icon: undefined,
  },
  // {
  //   sidebarName: undefined,
  //   path: "/rentwithequity",
  //   component: rentwithequity,
  //   icon: undefined
  // },
  {
    // keep this at the bottom of each route collection
    sidebarName: "Get Help",
    path: "/contact",
    component: contact,
    icon: DashboardIcon,
  },
];
export const newResidentNoSocialRoutesArray: MyRouteProps[] = [
  {
    exact: true,
    sidebarName: "Home",
    path: "/",
    component: Dashboard,
    icon: DashboardIcon,
  },
  {
    exact: true,
    sidebarName: "My Account",
    path: "/myaccount",
    component: MyAccount,
    icon: DashboardIcon,
    quickLinks: [{ sidebarName: "MAKE A PAYMENT", path: "/payment" }],
  },
  {
    sidebarName: "Move-in Checklist",
    path: "/moveinchecklist",
    component: moveinchecklist,
    icon: DashboardIcon,
  },
  {
    sidebarName: "View Credit Report",
    path: "/creditreport",
    component: CreditReport,
    icon: undefined,
  },
  {
    sidebarName: "View Criminal Report",
    path: "/criminalreport",
    component: CriminalReport,
    icon: undefined,
  },
  {
    sidebarName: "Community Guide",
    path: "/communityguide",
    component: CommunityGuide,
    icon: undefined,
  },
  {
    sidebarName: "Groups",
    path: "/groups",
    component: groups,
    icon: DashboardIcon,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/guest",
    component: guest,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/amenity",
    component: amenity,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/entv",
    component: entv,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/rentable",
    component: rentable,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/mystatement",
    component: mystatement,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/groupdetails/:groupId",
    component: groupdetails,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/serviceproviders",
    component: serviceproviders,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/renewal",
    component: renewal,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/myprofile",
    component: profile,
    icon: undefined,
  },
  {
    exact: true,
    sidebarName: "Events",
    path: "/events",
    component: EventsDashboard,
    icon: DashboardIcon,
  },
  {
    sidebarName: undefined,
    path: "/payment",
    component: payment,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/propertydocuments",
    component: propertydocuments,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/referfriend",
    component: referFriend,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/uploads",
    component: uploads,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/docusignlease",
    component: DocuSignLease,
    icon: undefined,
  },
  {
    // keep this at the bottom of each route collection
    sidebarName: "Get Help",
    path: "/contact",
    component: contact,
    icon: DashboardIcon,
  },
];
export const newResidentNoMICheckListRoutesArray: MyRouteProps[] = [
  {
    exact: true,
    sidebarName: "Home",
    path: "/",
    component: Dashboard,
    icon: DashboardIcon,
  },
  {
    exact: true,
    sidebarName: "My Account",
    path: "/myaccount",
    component: MyAccount,
    icon: DashboardIcon,
    quickLinks: [{ sidebarName: "MAKE A PAYMENT", path: "/payment" }],
  },
  {
    sidebarName: "View Credit Report",
    path: "/creditreport",
    component: CreditReport,
    icon: undefined,
  },
  {
    sidebarName: "View Criminal Report",
    path: "/criminalreport",
    component: CriminalReport,
    icon: undefined,
  },
  {
    sidebarName: "Community Guide",
    path: "/communityguide",
    component: CommunityGuide,
    icon: undefined,
  },
  {
    sidebarName: "Groups",
    path: "/groups",
    component: groups,
    icon: DashboardIcon,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/guest",
    component: guest,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/amenity",
    component: amenity,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/entv",
    component: entv,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/rentable",
    component: rentable,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/mystatement",
    component: mystatement,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/groupdetails/:groupId",
    component: groupdetails,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/serviceproviders",
    component: serviceproviders,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/renewal",
    component: renewal,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/myprofile",
    component: profile,
    icon: undefined,
  },
  {
    exact: true,
    sidebarName: "Events",
    path: "/events",
    component: EventsDashboard,
    icon: DashboardIcon,
  },
  {
    sidebarName: undefined,
    path: "/payment",
    component: payment,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/propertydocuments",
    component: propertydocuments,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/referfriend",
    component: referFriend,
    icon: undefined,
  },
  {
    // keep this at the bottom of each route collection

    sidebarName: "Get Help",
    path: "/contact",
    component: contact,
    icon: DashboardIcon,
  },
  // ,
  // {
  //   sidebarName: undefined,
  //   path: "/rentwithequity",
  //   component: rentwithequity,
  //   icon: undefined
  // }
];
export const formerResidentRoutesArray: MyRouteProps[] = [
  {
    exact: true,
    sidebarName: "Home",
    path: "/",
    component: FormerResidentDashboard,
    icon: DashboardIcon,
  },
  {
    sidebarName: undefined,
    path: "/payment",
    component: payment,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/mystatement",
    component: mystatement,
    icon: undefined,
  },
  {
    // keep this at the bottom of each route collection

    sidebarName: "Get Help",
    path: "/contact",
    component: contact,
    icon: DashboardIcon,
  },
  // ,
  // {
  //   sidebarName: undefined,
  //   path: "/rentwithequity",
  //   component: rentwithequity,
  //   icon: undefined
  // }
];
export const bannedResidentRoutesArray: MyRouteProps[] = [
  {
    exact: true,
    sidebarName: "Home",
    path: "/",
    component: Dashboard,
    icon: DashboardIcon,
  },
  {
    exact: true,
    sidebarName: "My Account",
    path: "/myaccount",
    component: MyAccount,
    icon: DashboardIcon,
    quickLinks: [{ sidebarName: "MAKE A PAYMENT", path: "/payment" }],
  },
  {
    sidebarName: "View Credit Report",
    path: "/creditreport",
    component: CreditReport,
    icon: undefined,
  },
  {
    sidebarName: "View Criminal Report",
    path: "/criminalreport",
    component: CriminalReport,
    icon: undefined,
  },
  {
    sidebarName: "Service",
    path: "/servicereqs",
    component: servicereq,
    icon: DashboardIcon,
    quickLinks: [{ sidebarName: "REQUEST SERVICE", path: "/servicereqs" }],
  },
  {
    sidebarName: undefined,
    path: "/myaccount/guest",
    component: guest,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/amenity",
    component: amenity,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/entv",
    component: entv,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/rentable",
    component: rentable,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/mystatement",
    component: mystatement,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/groupdetails/:groupId",
    component: groupdetails,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/serviceproviders",
    component: serviceproviders,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/renewal",
    component: renewal,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/myprofile",
    component: profile,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/payment",
    component: payment,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/propertydocuments",
    component: propertydocuments,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/documents",
    component: documents,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/myaccount/referfriend",
    component: referFriend,
    icon: undefined,
  },
  {
    // keep this at the bottom of each route collection
    sidebarName: "Get Help",
    path: "/contact",
    component: contact,
    icon: DashboardIcon,
  },
];
export const applicantRoutesArray: MyRouteProps[] = [
  {
    exact: true,
    sidebarName: "Home",
    path: "/",
    component: ApplicantDashboard,
    icon: DashboardIcon,
  },
  {
    sidebarName: undefined,
    path: "/payment",
    component: payment,
    icon: undefined,
  },
  {
    sidebarName: "View Credit Report",
    path: "/creditreport",
    component: CreditReport,
    icon: undefined,
  },
  {
    sidebarName: "View Criminal Report",
    path: "/criminalreport",
    component: CriminalReport,
    icon: undefined,
  },
  // {
  //   sidebarName: undefined,
  //   path: "/adverseactionletter",
  //   component: AdverseActionLetter,
  //   icon: undefined
  // },
  {
    sidebarName: undefined,
    path: "/uploads",
    component: uploads,
    icon: undefined,
  },
  {
    // keep this at the bottom of each route collection
    sidebarName: "Get Help",
    path: "/contact",
    component: contact,
    icon: DashboardIcon,
  },
];
export const applicantwithPaymentRoutesArray: MyRouteProps[] = [
  {
    exact: true,
    sidebarName: "Home",
    path: "/",
    component: ApplicantDashboard,
    icon: DashboardIcon,
  },
  // {
  //   sidebarName: "My Account",
  //   path: "/payment",
  //   component: payment,
  //   icon: DashboardIcon,
  //   quickLinks: [{ sidebarName: "MAKE A PAYMENT", path: "/payment" }],
  // },
  {
    sidebarName: undefined,
    path: "/payment",
    component: payment,
    icon: undefined,
  },
  {
    sidebarName: "View Credit Report",
    path: "/creditreport",
    component: CreditReport,
    icon: undefined,
  },
  {
    sidebarName: "View Criminal Report",
    path: "/criminalreport",
    component: CriminalReport,
    icon: undefined,
  },
  {
    sidebarName: undefined,
    path: "/uploads",
    component: uploads,
    icon: undefined,
  },
  {
    // keep this at the bottom of each route collection
    sidebarName: "Get Help",
    path: "/contact",
    component: contact,
    icon: DashboardIcon,
  },
];
