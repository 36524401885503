import { Button, Grid, IconButton, Menu, MenuItem, Typography, WithStyles, withStyles } from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";
import classnames from "classnames";
import * as React from "react";
import { connect } from "react-redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import contactstyles from "../../myprofile/components/styles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { UserInfoItem } from "../../userInfo/models";
import { joinGroupPostRequest, leaveGroupPostRequest } from "../actionsGroup";
import { Group, JoinLeaveGroupDto, RpActivity } from "../models";
import NewPost from "./NewPost";
import ReportAbuseMenuItem from "./ReportAbuseMenuItem";

const styles = combinestyles(contactstyles, globalstyles);

interface PropsFromState {
  user?: UserInfoItem;
}

interface PropsFromDispatch {
  leaveGroup: (request: JoinLeaveGroupDto, group: Group) => any;
  joinGroup: (request: JoinLeaveGroupDto, group: Group) => any;
  logActivity: typeof logActivity;
}
interface Props extends WithStyles<typeof styles> {
  showExpander: boolean;
  expandClickHandler?: any;
  group: Group;
  whoseGroup: string;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

interface State {
  expanded: boolean;
  anchorEl: any;
  group: Group;
  groupToLeave: JoinLeaveGroupDto;
  groupToJoin: JoinLeaveGroupDto;
  groupName: any;
}
class GroupsDetailsHeader extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    expanded: false,
    anchorEl: null,
    group: {
      id: 0,
      rmPropId: "",
      name: this.props.group.name,
      description: "",
      status: "",
      memberCount: 0,
      declineReason: null,
      dateCreated: "",
      lastPostDate: ""
    },
    groupToLeave: {
      groupId: 0,
      userNameId: ""
    },
    groupToJoin: {
      groupId: 0,
      userNameId: ""
    },
    groupName: ""
  };

  public componentDidMount() {
    this.setState({ groupName: this.props.group.name });
  }

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  setCurrentJoinLeaveGroupDto = (groupItem: Group) => {
    const currentJoinLeaveGroupDto: JoinLeaveGroupDto = {
      groupId: groupItem.id,
      userNameId: this.props.user!.nameId
    };
    this.setState({ groupToLeave: currentJoinLeaveGroupDto });
  };

  handleLeaveGroup = (groupToLeave: Group) => {
    this.setCurrentJoinLeaveGroupDto(groupToLeave);

    const leaveGroupRequest: JoinLeaveGroupDto = {
      groupId: groupToLeave.id,
      userNameId: this.props.user!.nameId
    };

    this.props.leaveGroup(leaveGroupRequest, groupToLeave);
    this.props.logActivity({
      actcode: "GL",
      note: `Resident left group - ${groupToLeave.name}`
    });

    this.handleClose();
  };

  handleJoinGroup = (groupToJoin: Group) => {
    const joinGroupRequest: JoinLeaveGroupDto = {
      groupId: groupToJoin.id,
      userNameId: this.props.user!.nameId
    };
    this.props.joinGroup(joinGroupRequest, groupToJoin);
    this.props.logActivity({
      actcode: "GJ",
      note: `Resident joined group - ${groupToJoin.name}`
    });
  };

  handleReportGroupClose = () => {
    this.handleClose();
  };

  public render() {
    const {
      // showExpander,
      // item,
      classes
    } = this.props;
    const { expanded, anchorEl, groupName } = this.state;
    const showLeaveGroupMenuEntry = this.props.whoseGroup === "mine"; // whoseGroup this.props.isMyGroup;
    const groupItem: Group = this.props.group;
    return (
      <React.Fragment>
        <Grid container alignItems="center" className={classes.header}>
          <Grid item xs={11}>
            <Typography variant="h1" noWrap style={{ paddingBottom: "5px" }}>
              {groupName}
            </Typography>
          </Grid>
          <Grid item xs={1} style={{ textAlign: "right" }}>
            <IconButton
              className={classnames(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={this.handleClick}
              aria-owns={anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              aria-label="Show more"
              style={{ padding: ".25rem" }}
            >
              <MoreIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container justify="flex-end" spacing={8}>
          {this.props.whoseGroup === "mine" && (
            <Grid item>
              <NewPost groupId={groupItem.id} />
            </Grid>
          )}
          <Grid item>
            {(this.props.whoseGroup === "other" || this.props.whoseGroup === "invalid") && (
              <Button onClick={() => this.handleJoinGroup(groupItem)} variant="contained" color="primary">
                + Join Group
              </Button>
            )}
          </Grid>
        </Grid>

        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          {/* <MenuItem onClick={this.handleClose}>Block {item.author.name}</MenuItem> */}
          {showLeaveGroupMenuEntry && <MenuItem onClick={() => this.handleLeaveGroup(groupItem)}>Leave Group</MenuItem>}
          <ReportAbuseMenuItem
            reportText="Report Group"
            descriptionText="Please describe this group to help us understand what is going on."
            submitButtonText="Send"
            reportAbuseGroup={this.props.group}
            closeMenu={this.handleReportGroupClose.bind(this)}
          />
        </Menu>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount)
});

const mapDispatchToProps: PropsFromDispatch = {
  leaveGroup: (request: JoinLeaveGroupDto, group: Group) => leaveGroupPostRequest({ request: request, group: group }),
  joinGroup: (request: JoinLeaveGroupDto, group: Group) => joinGroupPostRequest({ request: request, group: group }),
  logActivity: (act: RpActivity) => logActivity(act)
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(GroupsDetailsHeader));
