import { WithStyles, withStyles } from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { categoriesFetchRequest, listFetchRequest } from "../actions";
import { AmenityReservation, ReservableAmenity } from "../models";
import rentableitemstyles from "../styles";
import AmenityHeader from "./AmenityHeader";
import AmenityHistoryList from "./AmenityHistoryList";
import AmenityList from "./AmenityList";

const styles = combinestyles(rentableitemstyles, globalstyles);

interface IAmenityReservationProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
}

interface IPageState {}

interface PropsFromState {
  user?: UserInfoItem;
  loading: boolean;
  amenityReservationList: ReservableAmenity[];
  myAmenityList: AmenityReservation[];
  errors?: string;
}

interface PropsFromDispatch {
  categoriesFetchRequest: typeof categoriesFetchRequest;
  listFetchRequest: typeof listFetchRequest;
}

type AllProps = IAmenityReservationProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

class AmenityPage extends React.Component<AllProps, IPageState> {
  state: IPageState = {};

  render() {
    let todayDate = moment().subtract(1, "days");
    const activeAmenity = this.props.myAmenityList.filter(
      x =>
        todayDate.isBefore(x.reserveDate) && (x.status === "Open" || x.status === "Pending" || x.status === "Approved")
    );

    const pastAmenity = this.props.myAmenityList.filter(
      x => todayDate.isAfter(x.reserveDate) || x.status === "Cancelled" || x.status === "Declined"
    );

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <AmenityHeader />
          <AmenityList />

          <AmenityHistoryList
            title="Upcoming Reservations"
            myAmenityListHistory={activeAmenity}
            collapsible={false}
            amenityStatus="1"
          />
          <AmenityHistoryList
            title="Reservation History"
            myAmenityListHistory={pastAmenity}
            collapsible={true}
            amenityStatus="2"
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ amenity, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  amenityReservationList: amenity.reservableAmenityList.data,
  loading: amenity.reservableAmenityList.loading,
  myAmenityList: amenity.myAmenityReservation.data,
  errors: amenity.myAmenityReservation.errors
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  categoriesFetchRequest: (rmpropid: string) => dispatch(categoriesFetchRequest(rmpropid)),
  listFetchRequest: (nameid: string) => dispatch(listFetchRequest(nameid))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AmenityPage)));
