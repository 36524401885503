import "react-datepicker/dist/react-datepicker.css";

import { Button, Grid, Tab, Tabs, TextField, Typography, WithStyles, withStyles } from "@material-ui/core";
import { Field, FormikProps } from "formik";
import * as React from "react";

import globalstyles from "../../../global-styles";
import { GenerateCloudinaryThumbnailUrl } from "../../../utils/cloudinaryImageFormatter";
import combinestyles from "../../../utils/combinestyles";
import FileUploader from "../components/common/FileUploader";
import GroupSelector from "../components/common/GroupSelector";
import * as constants from "../constants";
import { Group, Image } from "../models";
import { InputFormState } from "./EditPost";
import EventDateSelect from "./EventsCalendar/EventDateSelect";
import socialstyles from "./styles";

const styles = combinestyles(socialstyles, globalstyles);

const thumbsContainer = {
  display: "flex",
};

const thumb = {
  display: "inline-flex",
  marginRight: "1rem",
  width: "5rem",
  height: "5rem",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  justifyContent: "center",
};

const img = {
  display: "block",
  height: "5rem",
};

interface MyProps extends WithStyles<typeof styles> {
  handleFileUpload: (files: File[]) => void;
  handleGroupSelection: (value: string) => void;
  handleRemoveImage: (image: Image) => void;
  groups: Group[];
  selectedGroup?: number;
  existingPhotos: Image[];
}

type AllProps = MyProps & FormikProps<InputFormState>;

const EditPostForm = (props: AllProps) => {
  const {
    values: { activeTab, title, content, price, location },
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleRemoveImage,
    setFieldTouched,
    classes,
  } = props;

  const change = (field: any, e: any) => {
    e.persist();
    handleChange(e);
    setFieldTouched(field, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.newPostCategories}>
        <Typography style={{ marginBottom: "1rem" }}>Category</Typography>
        <Field
          name="activeTab"
          id="activeTab"
          render={() => (
            <Tabs value={activeTab} centered={false}>
              {activeTab === 0 && <Tab label="Discussion" classes={{ root: classes.tab }} />}
              {activeTab === 1 && <Tab label="For Sale" classes={{ root: classes.tab }} />}
              {activeTab === 2 && <Tab label="Event" classes={{ root: classes.tab }} />}
            </Tabs>
          )}
        />
      </div>
      <div className={classes.newPostContainer}>
        <Typography style={{ float: "right" }}>
          <small>*Required fields</small>
        </Typography>
        <Grid container direction="row" spacing={24}>
          <Grid item xs>
            <TextField
              id="title"
              name="title"
              label="Title*"
              value={title}
              helperText={touched.title ? errors.title : ""}
              error={touched.title && Boolean(errors.title)}
              onChange={change.bind(null, "title")}
              fullWidth
              className={classes.textField}
              inputProps={{
                maxLength: 250,
              }}
            />
          </Grid>

          {activeTab === 1 && (
            <Grid item xs={4}>
              <TextField
                id="price"
                name="price"
                label="Price*"
                value={price}
                helperText={touched.price ? errors.price : ""}
                error={touched.price && Boolean(errors.price)}
                onChange={change.bind(null, "price")}
                fullWidth
                className={classes.textField}
                inputProps={{
                  maxLength: 25,
                }}
              />
            </Grid>
          )}

          {activeTab === 2 && (
            <Grid item xs={12} sm={5}>
              <TextField
                id="location"
                name="location"
                label="Location*"
                value={location}
                helperText={touched.location ? errors.location : ""}
                error={touched.location && Boolean(errors.location)}
                onChange={change.bind(null, "location")}
                fullWidth
                className={classes.textField}
                inputProps={{
                  maxLength: 200,
                }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              id="content"
              name="content"
              label="Content*"
              value={content}
              helperText={touched.content ? errors.content : ""}
              error={touched.content && Boolean(errors.content)}
              fullWidth
              onChange={change.bind(null, "content")}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
              multiline
            />
          </Grid>

          {activeTab === 2 && <EventDateSelect {...props} />}

          <Grid item xs={12}>
            <div style={thumbsContainer}>
              {props.existingPhotos.map((x: Image) => (
                <div style={thumb} key={x.id}>
                  <div style={thumbInner}>
                    <img alt="Thumbnail" src={GenerateCloudinaryThumbnailUrl(x.imageFile)} style={img} />
                  </div>
                  <div onClick={() => handleRemoveImage(x)} style={{ marginLeft: "-2rem" }}>
                    <img
                      alt="Remove"
                      src={constants.REMOVE_ICON}
                      style={{ position: "relative", top: "4rem", right: "1.5rem", width: "2rem", height: "2rem" }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </Grid>

          <Grid item xs={12}>
            <FileUploader getFilesToUpload={props.handleFileUpload} />
          </Grid>
          <Grid item xs={12}>
            <GroupSelector
              handleGroupSelection={props.handleGroupSelection}
              groups={props.groups}
              selectedGroup={props.selectedGroup}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button type="submit" variant="contained" color="primary" size="large">
              Post
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default withStyles(styles)(EditPostForm);
