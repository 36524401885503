import { createSelector } from "reselect";

import { UserInfoItem } from "../userInfo/models";
import { SelectAccountState } from "./reducer";

const getActiveNameId = (state: SelectAccountState) => state.selectedNameId;
const getUserInfo = (state: SelectAccountState) => state.userInfoArray;

export const getActiveUserInfo = createSelector([getActiveNameId, getUserInfo], (selectedNameId, userData) => {
  const user = userData.find((item) => item.nameId === selectedNameId);
  if (user) return user;
  const emptyUser: UserInfoItem = {
    nameId: selectedNameId,
    nameGroup: "",
    firstName: "",
    lastName: "",
    fullName: "",
    phoneNumber: "",
    propertyId: "",
    buildingId: "",
    unitId: "",
    lease: "",
    status: "",
    shortDescription: "",
    residentStatus: "",
    moInspectUrl: "",
    propertyInfo: {
      rmPropId: "",
      shortPropertyName: "",
      propertyName: "",
      propertyManager: "",
      propertyEmail: "",
      propertyPhone: "",
      propertyAddress1: "",
      propertyAddress2: "",
      propertyCity: "",
      propertyState: "",
      propertyZipcode: "",
      officeHours: [],
      paymentPlan: false,
      rightRailPosts: [],
      serviceTiles: [],
      renewalTiles: [],
      homeTiles: [],
      affordableUnits: false,
      transUnionPaymentReporting: false,
    },
    profilePhotoUrl: "",
    portalUserId: "",
    midInit: "",
    vacateDate: new Date(),
    socialUserId: 0,
    bannedFromSocial: false,
    renewalAvailable: false,
    residentOffersUrl: "",
    applicantCreditReportUrl: "",
    displayCriminalReport: "",
    moveinCheckListActive: "",
    displayMoveinGuide: "",
    displayPaymentMenu: "",
  };
  return emptyUser;
});
