import * as React from "react";

import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { InjectedProps } from "@material-ui/core/withMobileDialog";

interface Props {
  onContinue: any;
  onCancel: any;
  open: boolean;
}
type AllProps = Props & InjectedProps;

class ServiceReqFormPermissionToEnter extends React.Component<AllProps> {
  render() {
    const { fullScreen, open } = this.props;

    return (
      <Dialog fullScreen={fullScreen} open={open}>
        <DialogTitle>Permission to Enter</DialogTitle>
        <DialogContent>
          <Typography>
            By submitting your service request via this app, you provide us with permission to enter your apartment to
            perform the requested work during normal business hours without additional notice to you. Entry to your home 
            will occur in the next few business days, if you would like to request a specific day or time, please contact the leasing office.{" "}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="default" variant="contained" autoFocus>
            Cancel
          </Button>
          <Button onClick={this.props.onContinue.bind(this, this.state)} color="primary" variant="contained" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ServiceReqFormPermissionToEnter;
