import { createAsyncAction, createAction } from "typesafe-actions";

import { MoveInChecklistModel } from "./models";

const APPLICANT_CHECKLIST_FETCH_REQUEST = "newresident/checklist/FETCH_REQUEST";
const APPLICANT_CHECKLIST_FETCH_SUCCESS = "newresident/checklist/FETCH_SUCCESS";
const APPLICANT_CHECKLIST_FETCH_ERROR = "newresident/checklist/FETCH_ERROR";

export const fetchRequestAsync = createAsyncAction(
  APPLICANT_CHECKLIST_FETCH_REQUEST,
  APPLICANT_CHECKLIST_FETCH_SUCCESS,
  APPLICANT_CHECKLIST_FETCH_ERROR
)<string, MoveInChecklistModel, Error>();

const MARK_COMPLETE_FETCH_REQUEST = "newresident/markcomplete/FETCH_REQUEST";
const MARK_COMPLETE_FETCH_SUCCESS = "newresident/markcomplete/FETCH_SUCCESS";
const MARK_COMPLETE_FETCH_ERROR = "newresident/markcomplete/FETCH_ERROR";

export const markCompleteRequest = createAction(MARK_COMPLETE_FETCH_REQUEST)<{
  nameId: string;
  checkListType: string;
}>();
export const markCompleteSuccess = createAction(MARK_COMPLETE_FETCH_SUCCESS)<{
  nameId: string;
  checkListType: string;
}>();
export const markCompleteError = createAction(MARK_COMPLETE_FETCH_ERROR)<string>();
