import {
  Button,
  CardMedia,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  WithStyles,
  withStyles
} from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { RootState } from "../../../store/root-reducer";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { changeFilter } from "../actions";
import { groupUserFetchRequest } from "../actionsGroup";
import * as constants from "../constants";
import { Group } from "../models";
import styles from "./styles";

interface PropsFromState {
  user?: UserInfoItem;
  userGroups: Group[];
}

interface PropsFromDispatch {
  changeFilter: typeof changeFilter;
  groupUserFetchRequest: typeof groupUserFetchRequest;
}

interface MyGroupsProps extends WithStyles<typeof styles> {}
type State = {
  expanded: boolean;
  filterApplied: boolean;
  anchorEl: any;
};

type AllProps = PropsFromState & MyGroupsProps & PropsFromDispatch;

class FeedFilter extends React.Component<AllProps, State> {
  componentDidMount() {
    const nameId = this.props.user!.nameId;
    if (this.props.userGroups.length === 0) {
      this.props.groupUserFetchRequest(nameId);
    }
  }

  state = { expanded: false, filterApplied: false, anchorEl: null };
  handleFilterFeed = (filter: string) => {
    this.props.changeFilter(filter);
    this.setState({ expanded: false, filterApplied: true });
  };

  removeFilter = () => {
    this.props.changeFilter("all");
    this.setState({ expanded: false, filterApplied: false });
  };

  openFilter = (event: any) => {
    this.setState({ expanded: true, anchorEl: event.currentTarget });
  };

  closeFilter = (event: any) => {
    this.setState({ expanded: false, anchorEl: null });
  };

  public render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div>
          {!this.state.filterApplied && (
            <Button aria-owns={"render-props-menu"} aria-haspopup="true" onClick={this.openFilter}>
              <CardMedia className={classes.eventTimeLocation} image={constants.FILTER_ICON} title="Feed Filter" />
              FILTER
            </Button>
          )}
          {this.state.filterApplied && (
            <Button aria-owns={"render-props-menu"} aria-haspopup="true" onClick={this.removeFilter}>
              <CardMedia className={classes.eventTimeLocation} image={constants.UNFILTER_ICON} title="Feed Filter" />
              UNFILTER
            </Button>
          )}
          {this.state.expanded && (
            <Menu anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.closeFilter}>
              <MenuItem onClick={() => this.handleFilterFeed("Discussions")}>
                <ListItemIcon className={classes.icon}>
                  <CardMedia
                    className={classes.filterIcon}
                    image={constants.FILTER_ICON_DISCUSSION}
                    title="Discussion Filter"
                  />
                </ListItemIcon>
                <ListItemText inset primary="Discussions" />
              </MenuItem>
              <MenuItem onClick={() => this.handleFilterFeed("Events")}>
                <ListItemIcon className={classes.icon}>
                  <CardMedia
                    className={classes.filterIcon}
                    image={constants.FILTER_ICON_EVENTS}
                    title="Events Filter"
                  />
                </ListItemIcon>
                <ListItemText inset primary="Events" />
              </MenuItem>
              <MenuItem onClick={() => this.handleFilterFeed("For Sale")}>
                <ListItemIcon className={classes.icon}>
                  <CardMedia
                    className={classes.filterIcon}
                    image={constants.FILTER_ICON_FORSALE}
                    title="For Sale Filter"
                  />
                </ListItemIcon>
                <ListItemText inset primary="For Sale" />
              </MenuItem>
              <MenuItem onClick={() => this.handleFilterFeed("News")}>
                <ListItemIcon className={classes.icon}>
                  <CardMedia className={classes.filterIcon} image={constants.FILTER_ICON_NEWS} title="News Filter" />
                </ListItemIcon>
                <ListItemText inset primary="News" />
              </MenuItem>
              <MenuItem onClick={() => this.handleFilterFeed("My Groups")}>
                <ListItemIcon className={classes.icon}>
                  <CardMedia
                    className={classes.filterIcon}
                    image={constants.FILTER_ICON_GROUPS}
                    title="My Groups Filter"
                  />
                </ListItemIcon>
                <ListItemText inset primary="My Groups" />
              </MenuItem>
            </Menu>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ selectAccount, socialGroup }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  userGroups: socialGroup.groupUserList.dataUser
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  changeFilter: (filter: string) => dispatch(changeFilter(filter)),
  groupUserFetchRequest: (nameId: string) => dispatch(groupUserFetchRequest(nameId))
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FeedFilter));
