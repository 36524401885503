import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link, withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import { myAccountDashboardFetchRequest } from "../features/myaccount/actions";
import MyAccountStackedRow from "../features/myaccount/components/myaccount-stackedrow";
import { MyAccountDashboard } from "../features/myaccount/models";
import { Statement } from "../features/mystatement/models";
import NotificationsContainer from "../features/notifications/components/NotificationsContainer";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import { UserInfoItem } from "../features/userInfo/models";
import globalstyles from "../global-styles";
import { RootState } from "../store/root-reducer";
import myaccountstyles from "../styles";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(myaccountstyles, globalstyles);

interface IMyAccountProps extends WithStyles<typeof styles> {
  theme?: any;
  data?: Statement;
}

interface IPageState {}

interface PropsFromState {
  user: UserInfoItem;
  loading: boolean;
  myaccountdashboardData: MyAccountDashboard | undefined;
  errors?: string;
}

interface PropsFromDispatch {
  myAccountDashboardFetchRequest: typeof myAccountDashboardFetchRequest;
}

type AllProps = IMyAccountProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

class MyAccount extends React.Component<AllProps, IPageState> {
  state: IPageState = {};

  componentDidMount() {
    const nameId = this.props.user.nameId;
    this.props.myAccountDashboardFetchRequest(nameId);
    window.scrollTo(0, 0);
  }

  public render() {
    if (!this.props.myaccountdashboardData) {
      return <div />;
    }
    const openBalance = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
      this.props.myaccountdashboardData.openBalance
    );
    let residentOfferFlag = true;
    let residentOffer = this.props.myaccountdashboardData.residentOffersUrl;
    if (residentOffer) {
      if (residentOffer.length > 0) {
        residentOfferFlag = true;
      } else {
        residentOfferFlag = false;
      }
    } else {
      residentOfferFlag = false;
    }

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Typography variant="h1" className={this.props.classes.header}>
            My Account
          </Typography>
          {(this.props.myaccountdashboardData.openBalance > 0 ||
            this.props.myaccountdashboardData.renewalDisplay ||
            // this.props.myaccountdashboardData.entvDisplay ||
            this.props.myaccountdashboardData.entvSummaryDisplay) && (
            <div style={{ marginBottom: "1rem" }}>
              <Typography variant="h3" className={this.props.classes.headerGroup} style={{ marginBottom: "0" }}>
                Take Action
              </Typography>
              <NotificationsContainer fromDashboard={false} />
              {this.props.myaccountdashboardData.entvSummaryDisplay && (
                <Card
                  style={{
                    width: "100%",
                    borderLeftWidth: "6px",
                    borderLeftStyle: "solid",
                    borderLeftColor: "#ec5715",
                  }}
                >
                  <CardContent style={{ paddingBottom: "16px" }}>
                    <Grid container direction="row" alignItems="center" justify="space-between">
                      <Grid item>
                        <Grid container alignItems="center" spacing={16}>
                          <Grid item>
                            <Avatar
                              src="https://media.equityapartments.com/image/upload/co_rgb:bdbdbd,e_colorize:100,f_auto/Content/portal/myaccount/moving.svg"
                              style={{ width: "2rem", backgroundColor: "#ec5715", height: "2rem" }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="h4"
                              gutterBottom
                              style={{ overflowWrap: "break-word", fontWeight: 300 }}
                            >
                              Notice to Vacate Submission Summary
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" color="primary" href="/myaccount/entv/entvsummary">
                          View
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
            </div>
          )}
          <Paper className={this.props.classes.paper}>
            <Typography variant="h3" style={{ marginBottom: "1rem" }}>
              My Details
            </Typography>
            <Grid container spacing={24}>
              <MyAccountStackedRow
                icon="https://media.equityapartments.com/image/upload/co_rgb:bdbdbd,e_colorize:100,f_auto/Content/portal/myaccount/profile.svg"
                title="Profile"
                description="Help us get to know you better"
                path="/myaccount/myprofile"
              />
              <Grid item xs={12}>
                <Grid container alignItems="center" style={{ marginBottom: "1rem" }} spacing={24}>
                  <Grid item>
                    <Link to="/payment">
                      <img
                        alt="Make Payment"
                        className={[`${this.props.classes.icon}`, "my-account-icon"].join(" ")}
                        src="https://media.equityapartments.com/image/upload/co_rgb:bdbdbd,e_colorize:100,f_auto/Content/portal/myaccount/makepayment.svg"
                      />
                    </Link>
                  </Grid>
                  <Grid item xs>
                    <Link to="/payment" className={this.props.classes.link}>
                      <Typography variant="h5" className={this.props.classes.link}>
                        Make Payment
                      </Typography>
                      {this.props.myaccountdashboardData.openBalance !== 0 && (
                        <Typography variant="h5" style={{ textDecoration: "none" }}>
                          You have a balance of {openBalance}
                        </Typography>
                      )}
                    </Link>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              {this.props.myaccountdashboardData.entvMoveOut && (
                <MyAccountStackedRow
                  icon="https://media.equityapartments.com/image/upload/co_rgb:bdbdbd,e_colorize:100,f_auto/v1556575192/Content/portal/formerresident/move.svg"
                  title="Notice to Vacate"
                  description="Moving Out? Let us know"
                  path="/myaccount/entv"
                />
              )}
              <MyAccountStackedRow
                icon="https://media.equityapartments.com/image/upload/co_rgb:bdbdbd,e_colorize:100,f_auto/Content/portal/myaccount/statement.svg"
                title="Statement"
                description="See activity related to your balance"
                path="/myaccount/mystatement"
              />
              <MyAccountStackedRow
                icon="https://media.equityapartments.com/image/upload/co_rgb:bdbdbd,e_colorize:100,f_auto/Content/portal/myaccount/leaseinfo.svg"
                title="Lease Information"
                description={"Expires on " + this.props.myaccountdashboardData.leaseExpire}
                path="/myaccount/mylease"
              />
              {this.props.myaccountdashboardData.guestAccessDisplay && (
                <MyAccountStackedRow
                  icon="https://media.equityapartments.com/image/upload/co_rgb:bdbdbd,e_colorize:100,f_auto/Content/portal/myaccount/guestaccess.svg"
                  title="Guest Access"
                  description="Control who has access to your home"
                  path="/myaccount/guest"
                />
              )}
              <MyAccountStackedRow
                icon="https://media.equityapartments.com/image/upload/co_rgb:bdbdbd,e_colorize:100,f_auto/Content/portal/myaccount/leaseinfo.svg"
                title="Digital Docs"
                description="Shared documents and information"
                path="/myaccount/documents"
              />
              {residentOfferFlag === true && (
                <Grid item xs={12}>
                  <Grid container alignItems="center" style={{ marginBottom: "1rem" }} spacing={24}>
                    <Grid item>
                      <a
                        href={this.props.myaccountdashboardData.residentOffersUrl}
                        target="_new"
                        style={{ textDecoration: "none" }}
                      >
                        <img
                          alt="Rewards"
                          className={[`${this.props.classes.icon}`, "my-account-icon"].join(" ")}
                          src="https://media.equityapartments.com/image/upload/co_rgb:bdbdbd,e_colorize:100,f_auto/Content/portal/myaccount/rewards.svg"
                        />
                      </a>
                    </Grid>
                    <Grid item xs>
                      <a
                        href={this.props.myaccountdashboardData.residentOffersUrl}
                        target="_new"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography variant="h5" className={this.props.classes.link}>
                          Resident Offers
                        </Typography>
                        <Typography variant="h5" style={{ textDecoration: "none" }}>
                          Exclusive benefits for you
                        </Typography>
                      </a>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              )}
              <MyAccountStackedRow
                icon="https://media.equityapartments.com/image/upload/co_rgb:bdbdbd,e_colorize:100,f_auto/Content/portal/myaccount/referafriend.svg"
                title="Refer A Friend"
                description="Get rewarded when you refer a friend!"
                path="/myaccount/referfriend"
              />
              {/* <MyAccountStackedRow
                icon="https://media.equityapartments.com/image/upload/co_rgb:bdbdbd,e_colorize:100,f_auto/Content/portal/myaccount/renttoown.svg"
                title="Rent To Own"
                description={"You have " + this.props.myaccountdashboardData.rweTotalPoints + " points to use"}
                path="/rentwithequity"
              /> */}
            </Grid>
          </Paper>
          <Paper className={this.props.classes.paper}>
            <Typography variant="h3" style={{ marginBottom: "1rem" }}>
              My Community
            </Typography>
            <Grid container spacing={24}>
              {this.props.myaccountdashboardData.amenityReservationDisplay && (
                <MyAccountStackedRow
                  icon="https://media.equityapartments.com/image/upload/co_rgb:bdbdbd,e_colorize:100,f_auto/Content/portal/myaccount/amenityreservation.svg"
                  title="Amenity Reservations"
                  description="Reserve an amenity space"
                  path="/myaccount/amenity"
                />
              )}
              {this.props.myaccountdashboardData.rentableItemsDisplay && (
                <MyAccountStackedRow
                  icon="https://media.equityapartments.com/image/upload/co_rgb:bdbdbd,e_colorize:100,f_auto/Content/portal/myaccount/rentableitems.svg"
                  title="Rentable Items"
                  description="See what we have to offer"
                  path="/myaccount/rentable"
                />
              )}
              {
                <MyAccountStackedRow
                  icon="https://media.equityapartments.com/image/upload/co_rgb:bdbdbd,e_colorize:100,f_auto/Content/portal/myaccount/serviceproviders.svg"
                  title="Service Providers"
                  description="Internet, phone, cable, etc..."
                  path="/myaccount/serviceproviders"
                />
              }
              {
                <MyAccountStackedRow
                  icon="https://media.equityapartments.com/image/upload/co_rgb:bdbdbd,e_colorize:100,f_auto/Content/portal/myaccount/propertyinfo.svg"
                  title="Community Documents"
                  description="Docs and more"
                  path="/myaccount/propertydocuments"
                />
              }
            </Grid>
          </Paper>
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ myaccountdashboard, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  myaccountdashboardData: myaccountdashboard.myAccountData.data,
  loading: myaccountdashboard.myAccountData.loading,
  errors: myaccountdashboard.myAccountData.errors,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  myAccountDashboardFetchRequest: (nameid: string) => dispatch(myAccountDashboardFetchRequest(nameid)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyAccount)));
