import { Button, Grid, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";

import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import statementstyles from "./styles";

const styles = combinestyles(statementstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  balance: number;
}

class StatementHeader extends React.Component<Props> {
  render() {
    const { balance } = this.props;

    const balanceMsg =
      Number(balance) === 0
        ? "You currently have no balance due."
        : Number(balance) < 0
        ? "You have a credit of " +
          new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(Number(balance))
        : "You have a balance of " +
          new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(Number(balance));

    return (
      <div>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography variant="h1" className={this.props.classes.header}>
              My Statement
            </Typography>
          </Grid>
          <Grid item xs style={{ textAlign: "right" }}>
            <Typography>Balance</Typography>
            <Typography variant="h2">
              {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(Number(balance))}
            </Typography>
          </Grid>
        </Grid>
        <Paper className={this.props.classes.paper}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography>{balanceMsg}</Typography>
            </Grid>
            <Grid item xs style={{ textAlign: "right" }}>
              <Button href="/payment" variant="contained" color="primary" aria-label="Add">
                Make a Payment
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(StatementHeader);
