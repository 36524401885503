import { ServiceReqsState } from "./reducer";

import { createSelector } from "reselect";
import { ServiceRequestType } from "./models";

export const getSelectedServicereq = (state: ServiceReqsState) => state.serviceRequestDetails.selectedItem;
export const getServiceRequestTypes = (state: ServiceReqsState) => state.serviceReqTypes.data;
export const getServiceRequestCategories = (state: ServiceReqsState) => state.serviceRequestCategories.flatData;

export const getServiceRequestType = createSelector(
  getSelectedServicereq,
  getServiceRequestTypes,
  (serviceReq, types) => {
    if (!serviceReq || !types || types.length === 0) {
      const retval: ServiceRequestType = {
        id: 0,
        categoryId: 0,
        category: { wCategId: 0, categoryId: 0, name: "", iconImage: "", category: "", subCategory: "", sortOrder: 0 },
        description: "",
        quickFixId: 0,
        quickFix: { id: 0, quickFixSuggestions: [] },
        rP_Display: "",
        amount: 0,
        emergency: false
      };
      return retval;
    }
    return types.filter(x => x.id === serviceReq.requestTypeId)[0];
  }
);

export const getServiceRequestCategory = createSelector(
  getSelectedServicereq,
  getServiceRequestCategories,
  (serviceReq, categories) => {
    if (!serviceReq || !categories || categories.length === 0) {
      const retval: ServiceRequestType = {
        id: 0,
        categoryId: 0,
        category: { wCategId: 0, categoryId: 0, name: "", iconImage: "", category: "", subCategory: "", sortOrder: 0 },
        description: "",
        quickFixId: 0,
        quickFix: { id: 0, quickFixSuggestions: [] },
        rP_Display: "",
        amount: 0,
        emergency: false
      };
      return retval;
    }
    return categories.filter(x => x.categoryId === serviceReq.categoryId)[0];
  }
);
