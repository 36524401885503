import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { store } from "../../AppContainer";
import {
  getMyServiceRequests,
  loadServiceRequestCategories,
  loadServiceRequestLocations,
  loadServiceRequestQuickFixes,
  loadServiceRequestTypes,
  postServiceRequest,
  postServiceRequestPhoto
} from "../../utils/api";
import { showModalAction } from "../errorhandling/actions";
import { getActiveUserInfo } from "../selectAccount/selector";
import { logActivity } from "../shared/actions";
import * as actions from "./actions";

function* handleFetchCategories(action: ReturnType<typeof actions.categoriesFetchRequest>) {
  try {
    const response = yield call(loadServiceRequestCategories);

    if (response.error) {
      yield put(actions.categoriesFetchError(response.error));
    } else {
      yield put(actions.categoriesFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.categoriesFetchError(err.stack!));
    } else {
      yield put(actions.categoriesFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchQuickFixes(action: ReturnType<typeof actions.quickFixesFetchRequest>) {
  try {
    const response = yield call(loadServiceRequestQuickFixes);

    if (response.error) {
      yield put(actions.quickFixesFetchError(response.error));
    } else {
      yield put(actions.quickFixesFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.quickFixesFetchError(err.stack!));
    } else {
      yield put(actions.quickFixesFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchLocations(action: ReturnType<typeof actions.locationsFetchRequest>) {
  try {
    const response = yield call(loadServiceRequestLocations);

    if (response.error) {
      yield put(actions.locationsFetchError(response.error));
    } else {
      yield put(actions.locationsFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.locationsFetchError(err.stack!));
    } else {
      yield put(actions.locationsFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchTypes(action: ReturnType<typeof actions.typesFetchRequest>) {
  try {
    const propid = action.payload;
    const response = yield call(loadServiceRequestTypes, propid);

    if (response.error) {
      yield put(actions.typesFetchError(response.error));
    } else {
      yield put(actions.typesFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.typesFetchError(err.stack!));
    } else {
      yield put(actions.typesFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchList(action: ReturnType<typeof actions.listFetchRequest>) {
  try {
    const nameId = action.payload;
    const response = yield call(getMyServiceRequests, nameId);

    if (response.error) {
      yield put(actions.listFetchError(response.error));
    } else {
      yield put(actions.listFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.listFetchError(err.stack!));
    } else {
      yield put(actions.listFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handlePostRequest(action: ReturnType<typeof actions.postRequest>) {
  try {
    const newServiceRequest = action.payload.serviceRequest;
    const response = yield call(postServiceRequest, newServiceRequest);

    if (response.error) {
      yield put(actions.postError(response.error));
    } else {
      yield put(logActivity({ actcode: "SR", note: `Service request WRKOID: ${response.workOrderId} Submitted` }));
      const newServiceRequestPhoto = action.payload.photo;
      if (newServiceRequestPhoto) {
        yield call(postServiceRequestPhoto, response.workOrderId, newServiceRequestPhoto);
      }
      yield put(actions.postSuccess(response));
      const nameId = getActiveUserInfo(store.getState().selectAccount)!.nameId;
      yield put(actions.listFetchRequest(nameId));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.postError(err.stack!));
    } else {
      yield put(actions.postError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(getType(actions.categoriesFetchRequest), handleFetchCategories);
  yield takeEvery(getType(actions.quickFixesFetchRequest), handleFetchQuickFixes);
  yield takeEvery(getType(actions.locationsFetchRequest), handleFetchLocations);
  yield takeEvery(getType(actions.typesFetchRequest), handleFetchTypes);
  yield takeEvery(getType(actions.listFetchRequest), handleFetchList);
  yield takeEvery(getType(actions.postRequest), handlePostRequest);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* servicereqsSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default servicereqsSaga;
