import React, { Component } from "react";
import { Dialog, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";
import DialogHeader from "../../shared/components/DialogHeader";

interface MyProps {
  message: string;
}
interface MyState {
  visible: boolean;
}
class AlertBlock extends Component<MyProps, MyState> {
  state = {
    visible: true
  };
  componentDidMount() {
    setTimeout(this.handleClose, 3000);
  }
  handleClose = () => {
    this.setState({ visible: false });
  };
  render() {
    return (
      <Dialog open={this.state.visible} onClose={this.handleClose} maxWidth="sm">
        <DialogHeader title="Error Occured" handleClose={this.handleClose} />
        <DialogContent>
          <Typography>{this.props.message}</Typography>
          {this.props.children}
          <DialogActions>
            <Button onClick={this.handleClose}>Close</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

export default AlertBlock;
