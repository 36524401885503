import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { loadRentableItemsByNameId, loadRentableItemsByPropertyId, requestRentableItem } from "../../utils/api";
import { showModalAction } from "../errorhandling/actions";
import * as actions from "./actions";

function* handleFetchRentableNameId(action: ReturnType<typeof actions.rentableResidentRequest>): any {
  try {
    const nameid = action.payload;

    const response = yield call(loadRentableItemsByNameId, nameid);

    if (response.error) {
      yield put(actions.rentableResidentError(response.error));
    } else {
      yield put(actions.rentableResidentSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.rentableResidentError(err.stack!));
    } else {
      yield put(actions.rentableResidentError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchRentableByProp(action: ReturnType<typeof actions.rentablePropertyRequest>): any {
  try {
    const rmpropid = action.payload;

    const response = yield call(loadRentableItemsByPropertyId, rmpropid);

    if (response.error) {
      yield put(actions.rentablePropertyError(response.error));
    } else {
      yield put(actions.rentablePropertySuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.rentablePropertyError(err.stack!));
    } else {
      yield put(actions.rentablePropertyError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleRequestRentableItem(action: ReturnType<typeof actions.requestRentableRequest>): any {
  try {
    const payload = action.payload;
    const response = yield call(requestRentableItem, payload);

    if (response.error) {
      yield put(actions.requestRentableError(response.error));
    } else {
      yield put(actions.requestRentableSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.requestRentableError(err.stack!));
    } else {
      yield put(actions.requestRentableError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* watchFetchRequest() {
  yield takeEvery(getType(actions.rentableResidentRequest), handleFetchRentableNameId);
  yield takeEvery(getType(actions.rentablePropertyRequest), handleFetchRentableByProp);
  yield takeEvery(getType(actions.requestRentableRequest), handleRequestRentableItem);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* rentableSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default rentableSaga;
