import { Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { avatarUpdateRequest, userInfoFetchRequest } from "../features/myprofile/actions";
import ProfileContactTile from "../features/myprofile/components/ProfileContactTile";
import ProfileEmergencyContactTile from "../features/myprofile/components/ProfileEmergencyContactTile";
import ProfileRentersInsuranceTile from "../features/myprofile/components/ProfileRentersInsuranceTile";
import ProfileSpecialAssistanceTile from "../features/myprofile/components/ProfileSpecialAssistTile";
import ProfileTransUnionSuppressTile from "../features/myprofile/components/ProfileTransUnionSuppressTile";
import ProfileVehiclesTile from "../features/myprofile/components/ProfileVehiclesTile";
import contactstyles from "../features/myprofile/components/styles";
import UpdatePasswordModal from "../features/myprofile/components/UpdatePasswordModal";
import { UserInfo } from "../features/myprofile/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import ImageCropperInput from "../features/shared/components/ImageCropperInput";
import SharedImagesConstants from "../features/shared/constants/sharedImages";
import { UserInfoItem } from "../features/userInfo/models";
import globalstyles from "../global-styles";
import { RootState } from "../store/root-reducer";
import { GenerateCloudinaryThumbnailUrl } from "../utils/cloudinaryImageFormatter";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(contactstyles, globalstyles);
interface MyProfileProps extends WithStyles<typeof styles> {}

interface PropsFromState {
  user?: UserInfoItem;
  userInfo?: UserInfo;
}

interface PropsFromDispatch {
  userInfoFetchRequest: typeof userInfoFetchRequest;
  avatarUpdateRequest: typeof avatarUpdateRequest;
}

type AllProps = MyProfileProps & PropsFromState & PropsFromDispatch;

class MyProfile extends React.Component<AllProps> {
  state = {
    tileLocked: false,
  };
  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.userInfoFetchRequest({ nameId });
    window.scrollTo(0, 0);
  }
  lockAllTiles = () => {
    this.setState({ tileLocked: true });
  };
  unlockAllTiles = () => {
    this.setState({ tileLocked: false });
  };
  handleProfilePictureChange = (croppedImage: File) => {
    const payload = {
      nameId: this.props.user!.nameId,
      file: croppedImage,
    };
    this.props.avatarUpdateRequest(payload);
  };
  render() {
    const { userInfo } = this.props;
    const avatarUrl =
      userInfo && userInfo.profilePhotoUrl
        ? GenerateCloudinaryThumbnailUrl(userInfo.profilePhotoUrl)
        : SharedImagesConstants.EMPTYAVATAR_FILLED;

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Typography variant="h1" className={this.props.classes.header}>
            My Profile
          </Typography>
          {userInfo !== undefined && (
            <Grid container className={this.props.classes.profilePicContainer} alignItems="center" spacing={16}>
              <Grid item>
                <img alt="Avatar" style={{ borderRadius: "50%", width: "7rem" }} src={avatarUrl} />
              </Grid>
              <Grid item xs>
                <Typography variant="h2">
                  {userInfo.firstName} {userInfo.midInit} {userInfo.lastName}
                </Typography>
                <Grid container>
                  <Grid item>
                    <UpdatePasswordModal />
                  </Grid>
                  <Grid item>
                    <ImageCropperInput
                      inputButtonText="Edit Profile Picture"
                      dialogTitle="Edit Profile Picture"
                      onChange={this.handleProfilePictureChange}
                      aspectRatio={1}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={16}>
            <Grid item sm={6}>
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <ProfileContactTile
                    tileLocked={this.state.tileLocked}
                    lockAllTiles={this.lockAllTiles}
                    unlockAllTiles={this.unlockAllTiles}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ProfileSpecialAssistanceTile tileLocked={this.state.tileLocked} />
                </Grid>
                <Grid item xs={12}>
                  <ProfileRentersInsuranceTile tileLocked={this.state.tileLocked} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <ProfileEmergencyContactTile
                    tileLocked={this.state.tileLocked}
                    lockAllTiles={this.lockAllTiles}
                    unlockAllTiles={this.unlockAllTiles}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ProfileVehiclesTile tileLocked={this.state.tileLocked} />
                </Grid>
                {((this.props.user!.propertyInfo.propertyState === "CA" &&
                  this.props.user!.propertyInfo.affordableUnits === true) ||
                  (this.props.user!.propertyInfo.transUnionPaymentReporting === true)) && (
                    <Grid item xs={12}>
                      <ProfileTransUnionSuppressTile tileLocked={this.state.tileLocked} />
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ profile, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  userInfo: profile.userInfo,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  userInfoFetchRequest: (payload: { nameId: any }) => dispatch(userInfoFetchRequest(payload)),
  avatarUpdateRequest: (payload: { nameId: string; file: File }) => dispatch(avatarUpdateRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyProfile));
