import * as React from "react";
import { ServiceRequestSubCategory } from "../models";

import { Paper, Grid, Divider, Typography } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core";

import servicestyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(servicestyles, globalstyles);

interface Props {
  selectSubCategory: (subCategory: ServiceRequestSubCategory) => void;
  subCategories: ServiceRequestSubCategory[];
}

interface Styles extends WithStyles<typeof styles> {
  theme?: any;
}

type AllProps = Props & Styles;

class ServiceReqFormSelectSubCategory extends React.Component<AllProps> {
  render() {
    const { subCategories, selectSubCategory } = this.props;

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Grid container className={this.props.classes.header}>
            <Grid item xs>
              <Typography variant="h2">New Service Request</Typography>
            </Grid>
          </Grid>
          <Paper className={this.props.classes.paper}>
            <Grid container alignItems="center" spacing={24} className={this.props.classes.listHeader}>
              <Grid item>
                <img
                  alt="Step 2"
                  className="step-icon"
                  src="https://media.equityapartments.com/image/upload/co_rgb:394b76,e_colorize:100,f_auto/v1544721640/Content/portal/uielements/step2.svg"
                />
              </Grid>
              <Grid item>
                <Typography variant="h3">Which item?</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              {subCategories.map((subCategory) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={subCategory.subCategoryName}
                  onClick={selectSubCategory.bind(this, subCategory)}
                >
                  <Typography variant="h4" style={{ color: "#3d90d4", marginBottom: "25px", cursor: "pointer" }}>
                    {subCategory.subCategoryName}
                  </Typography>
                  <Divider />
                </Grid>
              ))}
            </Grid>
            {/* <GridList cellHeight={80}>
              {subCategories.map(subCategory => (
                <GridListTile key={subCategory.subCategoryName} onClick={selectSubCategory.bind(this, subCategory)}>
                  <div style={{ display: "flex" }}>
                    <Typography variant="h4">{subCategory.subCategoryName}</Typography>
                  </div>
                  <Divider />
                </GridListTile>
              ))}
            </GridList> */}
          </Paper>
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(ServiceReqFormSelectSubCategory);
