// https://github.com/ThunderDev1/reactjs-ts-identityserver/blob/master/Spa/ClientApp/components/CallbackPage.tsx
import { push } from "connected-react-router";
import { User, Log } from "oidc-client";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import CallbackPage from "./components/CallbackPage";
import SelectAccountDisplay from "./features/selectAccount/components/SelectAccountDisplay";
import { RootState } from "./store/root-reducer";
import userManager from "./utils/userManager";

interface Props {
  children: React.ReactNode;
}
interface PropsFromState {
  user?: User;
  isLoadingUser: boolean;
  selectedNameId: string;
  userInfoLoading: boolean;
}
interface PropsFromDispatch {
  push: typeof push;
}

type AllProps = RouteComponentProps<{}> & Props & PropsFromState & PropsFromDispatch;

interface State {
  loggingOut: boolean;
}

class LoginThingy extends React.Component<AllProps, State> {
  state = {
    loggingOut: false,
  };
  render() {
    Log.logger = console;
    Log.level = Log.DEBUG;

    // if location is callback page, return only CallbackPage route to allow signin process
    if (this.props.location.pathname === "/callback") {
      return <CallbackPage />;
    }

    // if location is logout then log the user out
    if (this.props.location.pathname === "/logout") {
      if (!this.state.loggingOut) {
        this.setState({ loggingOut: true });

        window.sessionStorage.setItem("alt", "false"); // set the session storage for canary test to be off
        userManager.signoutRedirect();
      }
      return <p />;
    }

    if (this.props.location.pathname === "/logoutcallback") {
      this.setState({ loggingOut: false });
      userManager.signoutRedirectCallback();
      this.props.push("/");
      return <p />;
    }

    // wait for user to be loaded, and location is known
    if (this.props.isLoadingUser || !this.props.location) {
      return <p />;
    }

    // check if user is signed in
    userManager.getUser().then((user: any) => {
      if (!user || user.expired) {
        // if no user found, or token has expired, auto redirect to identity server signin page
        // pass the current path to redirect to the correct page after successfull login
        userManager.signinRedirect({ data: { path: window.location.pathname } });
        return null;
      } else {
        return user;
      }
    });

    // wait for userManager to load the user
    if (!this.props.user) {
      if (!this.props.isLoadingUser) {
        userManager.signinRedirect({ data: { path: window.location.pathname } });
      }
      return <p />;
    }

    if (!this.props.selectedNameId) {
      return <SelectAccountDisplay />;
    }
    if (this.props.userInfoLoading) {
      return <p />;
    }

    return <>{this.props.children}</>;
  }
}

const mapStateToProps = ({ auth, selectAccount }: RootState): PropsFromState => ({
  user: auth.user,
  isLoadingUser: auth.isLoadingUser,
  selectedNameId: selectAccount.selectedNameId,
  userInfoLoading: selectAccount.loading,
});

const mapDispatchToProps: PropsFromDispatch = {
  push: push,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginThingy));
