import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { CommunityGuideItem } from "./models";
import * as actions from "./actions";

export type CommunityGuideAction = ActionType<typeof actions>;

export type CommunityGuideState = Readonly<{
  data: CommunityGuideItem[];
  errors?: string;
  loading: boolean;
}>;

const initialState: CommunityGuideState = {
  data: [],
  errors: undefined,
  loading: false,
};

const reducer: Reducer<CommunityGuideState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.fetchAsyncAction.request): {
      return { ...state, loading: true };
    }
    case getType(actions.fetchAsyncAction.success): {
      return { ...state, loading: false, data: action.payload };
    }
    case getType(actions.fetchAsyncAction.failure): {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
