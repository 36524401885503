import { createAction } from "typesafe-actions";

import { UserInfoItem } from "../userInfo/models";

const FETCH_REQUEST = "selectaccount/userinfo/FETCH_REQUEST";
const FETCH_SUCCESS = "selectaccount/userinfo/FETCH_SUCCESS";
const FETCH_ERROR = "selectaccount/userinfo/FETCH_ERROR";

export const fetchRequest = createAction(FETCH_REQUEST)<string>();
export const fetchSuccess = createAction(FETCH_SUCCESS)<{ nameId: string; data: UserInfoItem }>();
export const fetchError = createAction(FETCH_ERROR)<string>();

const SELECT_ACCOUNT = "selectaccount/SELECT_ACCOUNT";
export const selectAccount = createAction(SELECT_ACCOUNT)<string>();

const OPEN_SELECT_ACCOUNT = "selectaccount/OPEN_SELECT_ACCOUNT";
export const openSelectAccount = createAction(OPEN_SELECT_ACCOUNT)();
