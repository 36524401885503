import { Avatar, Card, CardContent, Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { leaseFetchRequest } from "../../mylease/actions";
import { LeaseDetails } from "../../mylease/models";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { UserInfoItem } from "../../userInfo/models";
import {
  categoriesFetchRequest,
  listFetchRequest,
  locationsFetchRequest,
  postRequest,
  quickFixesFetchRequest,
  selectTopLevelCategory,
  typesFetchRequest
} from "../actions";
import {
  RpActivity,
  ServiceRequest,
  ServiceRequestLocation,
  ServiceRequestTopLevelCategory,
  ServiceRequestType,
  ServiceRequestUpload
} from "../models";
import ServiceReqDetails from "./servicereq-details";
import DetailsDialog, { FormValues } from "./servicereq-form-enterDetails";
import ServicereqFormSelectCategory from "./servicereq-form-selectCategory";
import ServiceReqHeaderTiles from "./servicereq-header-tiles";
import ServiceReqList from "./servicereq-list";
import servicestyles from "./styles";

const styles = combinestyles(servicestyles, globalstyles);

interface State {
  enterCommonAreaRequestDetailsOpen: any;
}

interface IServiceReqProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
  confirmMessageDisplay?: any;
}

interface PropsFromState {
  user?: UserInfoItem;
  topLevelCategories: ServiceRequestTopLevelCategory[];
  requestTypes: ServiceRequestType[];
  locations: ServiceRequestLocation[];
  serviceRequests: ServiceRequest[];
  leaseInfo: LeaseDetails | undefined;
  //loading: boolean,
  //errors: string,
}

interface PropsFromDispatch {
  listFetchRequest: typeof listFetchRequest;
  categoriesFetchRequest: typeof categoriesFetchRequest;
  typesFetchRequest: typeof typesFetchRequest;
  quickFixesFetchRequest: typeof quickFixesFetchRequest;
  locationsFetchRequest: typeof locationsFetchRequest;
  selectTopLevelCategory: typeof selectTopLevelCategory;
  postRequest: typeof postRequest;
  leaseFetchRequest: typeof leaseFetchRequest;
  logActivity: typeof logActivity;
}

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps & IServiceReqProps;

class ServiceReqDashboard extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    enterCommonAreaRequestDetailsOpen: false
  };

  componentDidMount() {
    const nameId = this.props.user!.nameId;
    if (!(this.props.topLevelCategories && this.props.topLevelCategories.length > 0)) {
      this.props.categoriesFetchRequest();
    }
    if (!(this.props.requestTypes && this.props.requestTypes.length > 0)) {
      const propId = this.props.user!.propertyId;
      this.props.typesFetchRequest(propId);
    }
    if (!(this.props.locations && this.props.locations.length > 0)) {
      this.props.locationsFetchRequest();
    }
    this.props.listFetchRequest(nameId);
    this.props.leaseFetchRequest({ nameId: nameId });
  }

  handleSelectCategory = (topLevelCategory: ServiceRequestTopLevelCategory) => {
    if (topLevelCategory.categoryName === "Common Area") {
      this.setState({
        enterCommonAreaRequestDetailsOpen: true
      });
    } else {
      this.props.selectTopLevelCategory(topLevelCategory);
      this.props.history.push("/servicereqs/new/selectSubCategory");
      window.scrollTo(0, 0);
    }
  };

  handleCancelCommonAreaRequest = () => {
    this.setState({
      enterCommonAreaRequestDetailsOpen: false
    });
  };

  handleContinueCommonAreaRequest = (s: FormValues) => {
    this.setState({
      enterCommonAreaRequestDetailsOpen: false
    });

    const submissionData: ServiceRequest = {
      creatorNameId: this.props.user!.nameId,
      creatorPortalUserId: this.props.user!.portalUserId,
      workOrderId: "",
      dateReported: new Date(),
      comments: s.additionalDetails,
      anyPets: s.havePet,
      alarm: s.haveAlarm,
      phoneNumber: s.phoneNumber,
      topLevelCategoryName: "Common Area",
      categoryId: 0,
      requestTypeId: 0,
      commonArea: true,
      contractorName: ""
    };

    const uploadRequest: ServiceRequestUpload = {
      serviceRequest: submissionData,
      photo: s.image
    };

    this.props.postRequest(uploadRequest);
    window.scrollTo(0, 0);
  };

  render() {
    const activeRequests = this.props.serviceRequests.filter(x => x.status === "Open");
    const pastRequests = this.props.serviceRequests.filter(x => x.status !== "Open");
    const { topLevelCategories, leaseInfo } = this.props;

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Typography variant="h1" className={this.props.classes.header}>
            New Service Request
          </Typography>

          {this.props.confirmMessageDisplay === "y" && (
            <Card
              style={{
                width: "100%",
                borderLeftWidth: "6px",
                borderLeftStyle: "solid",
                borderLeftColor: "#ec5715",
                marginBottom: "1rem"
              }}
            >
              <CardContent style={{ paddingBottom: "16px" }}>
                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Grid item>
                    <Grid container alignItems="center" spacing={16}>
                      <Grid item>
                        <Avatar
                          src="https://media.equityapartments.com/image/upload/v1516214930/Content/portal/alerts.png"
                          style={{ width: "2rem", backgroundColor: "#ec5715", height: "2rem" }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="h4" gutterBottom style={{ overflowWrap: "break-word", fontWeight: 300 }}>
                          Your service request has been submitted.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
          {/* <ServiceReqHeaderTips officePhoneNumber={this.GetFormatPhone(this.props.user!.propertyInfo.propertyPhone)} /> */}
          <Grid container spacing={16} style={{ marginBottom: "0.5em" }}>
            <ServiceReqHeaderTiles user={this.props.user!} />
          </Grid>
          <ServicereqFormSelectCategory
            selectCategory={this.handleSelectCategory}
            topLevelCategories={topLevelCategories}
          />
          <ServiceReqList title="Active Service Requests" items={activeRequests} collapsible={false} />
          <ServiceReqList title="Service Request History" items={pastRequests} collapsible={true} />
          <ServiceReqDetails />
          <DetailsDialog
            problemDesc=""
            onCancel={this.handleCancelCommonAreaRequest}
            onContinue={this.handleContinueCommonAreaRequest}
            open={this.state.enterCommonAreaRequestDetailsOpen}
            defaultPhone={this.props.user!.phoneNumber.toString()}
            isCommonArea={true}
            leaseInfo={leaseInfo}
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ servicereqs, selectAccount, lease }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  topLevelCategories: servicereqs.serviceRequestCategories.hierarchicalData,
  requestTypes: servicereqs.serviceReqTypes.data,
  locations: servicereqs.serviceReqLocations.data,
  serviceRequests: servicereqs.serviceRequestList.data,
  leaseInfo: lease.data
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  categoriesFetchRequest: () => dispatch(categoriesFetchRequest()),
  quickFixesFetchRequest: () => dispatch(quickFixesFetchRequest()),
  locationsFetchRequest: () => dispatch(locationsFetchRequest()),
  typesFetchRequest: (propId: string) => dispatch(typesFetchRequest(propId)),
  listFetchRequest: (nameId: string) => dispatch(listFetchRequest(nameId)),
  leaseFetchRequest: ({ nameId: any }) => dispatch(leaseFetchRequest({ nameId: any })),
  selectTopLevelCategory: (category: ServiceRequestTopLevelCategory) => dispatch(selectTopLevelCategory(category)),
  postRequest: (req: ServiceRequestUpload) => dispatch(postRequest(req)),
  logActivity: (act: RpActivity) => dispatch(logActivity(act))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ServiceReqDashboard));
