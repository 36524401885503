import * as React from "react";
import GroupsRequestNew from "./GroupsRequestNew";
import { Paper, Grid, Hidden, Typography, withStyles, WithStyles } from "@material-ui/core";
import socialstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(socialstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {}

class GroupsWelcome extends React.Component<Props> {
  render() {
    return (
      <div>
        <Typography variant="h1" className={this.props.classes.header}>
          Welcome to Groups!
        </Typography>
        <Paper className={this.props.classes.paper}>
          <Grid container spacing={8}>
            <Grid item xs>
              <Typography variant="h3" style={{ marginBottom: "2rem" }}>
                Looking for a running group? Thinking of starting a game night? Build new connections with your
                neighbors by joining and creating groups. New groups are subject to approval.
              </Typography>
              <GroupsRequestNew />
            </Grid>
            <Hidden xsDown>
              <Grid item style={{ textAlign: "right" }}>
                <img
                  src="https://media.equityapartments.com/image/upload/v1554233286/Content/portal/Groups/GroupsDashboard.png"
                  alt="Welcome to Groups"
                />
              </Grid>
            </Hidden>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(GroupsWelcome);
