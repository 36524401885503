import { createAction } from "typesafe-actions";

import { LeaseDetails } from "./models";

const LEASE_FETCH_REQUEST = "lease/FETCH_REQUEST";
const LEASE_FETCH_SUCCESS = "lease/FETCH_SUCCESS";
const LEASE_FETCH_ERROR = "lease/FETCH_ERROR";

export const leaseFetchRequest = createAction(LEASE_FETCH_REQUEST)<{ nameId: any }>();
export const leaseFetchSuccess = createAction(LEASE_FETCH_SUCCESS)<{ data: LeaseDetails }>();
export const leaseFetchError = createAction(LEASE_FETCH_ERROR)<string>();

const LEASEDOC_DOWNLOAD_REQUEST = "leasedoc/DOWNLOAD_REQUEST";
const LEASEDOC_DOWNLOAD_SUCCESS = "leasedoc/DOWNLOAD_SUCCESS";
const LEASEDOC_DOWNLOAD_ERROR = "leasedoc/DOWNLOAD_ERROR";
export const downloadLeaseDocumentRequest = createAction(LEASEDOC_DOWNLOAD_REQUEST)<{
  nameId: string;
  fileName: string;
  newWindow: Window | null;
}>();
export const downloadLeaseDocumentSuccess = createAction(LEASEDOC_DOWNLOAD_SUCCESS)();
export const downloadLeaseDocumentError = createAction(LEASEDOC_DOWNLOAD_ERROR)<string>();
