import * as React from "react";
import { ContactItem } from "../models";

interface Props {
  contact: ContactItem;
}

const missingPhotoIcon =
  "https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_100,o_50/Content/portal/uielements/defaultprofile.png";

const ContactImage = ({ contact }: Props) => {
  let contactPhotoUrl = "";

  if (contact.contactPhotoPath !== null && contact.contactPhotoPath !== undefined && contact.contactPhotoPath !== "") {
    contactPhotoUrl = `${contact.contactPhotoPath}`;
    return <img alt={`${contact.firstName} ${contact.lastName}`} src={contactPhotoUrl} style={{ width: "5.5rem" }} />;
  } else {
    contactPhotoUrl = missingPhotoIcon;
    return <img alt={`${contact.firstName} ${contact.lastName}`} src={contactPhotoUrl} style={{ width: "5.25rem" }} />;
  }
};

export default ContactImage;
