import { Button, Collapse, Grid, Link, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import { Formik, FormikProps } from "formik";
import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import * as Yup from "yup";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { UserInfoItem } from "../../userInfo/models";
import { insertAmenityRequest } from "../actions";
import {
  AmenityReservation,
  AmenityReservationSelector,
  AvailableReservationDates,
  OfficeHours,
  ReservableAmenity,
  RpActivity,
} from "../models";
import rentableitemstyles from "../styles";
import AmenityNewSelector from "./AmenityNewSelector";

const styles = combinestyles(rentableitemstyles, globalstyles);

const validationSchema = Yup.object({
  reserveDate: Yup.date()
    .required("Date is required")
    .min(new Date(new Date().setHours(new Date().getHours() - 24)), "Date must be in the future"),
  timeStart: Yup.string().required("Time is required"),
  timeEnd: Yup.string().required("Time is required"),
});

export interface NewReservationProps extends WithStyles<typeof styles> {
  selectNewReservation: (amenityToReserve: ReservableAmenity) => void;
}

export interface PropsFromState {
  user?: UserInfoItem;
  amenityToReserve?: ReservableAmenity;
}
export interface PropsFromDispatch {
  insertAmenityRequest: typeof insertAmenityRequest;
  logActivity: typeof logActivity;
}

type MyState = {
  step1display: boolean;
  step2display: boolean;
  step3display: boolean;
  openconfirm: boolean;
};

export type InputFormState = {
  id: number;
  nameId: string;
  amenityId: number;
  name: string;
  rmPropId: string;
  reserveDate: Date;
  reserveEndDate: Date;
  timeStart: string;
  timeEnd: string;
  reservationDetails: string;
  status: string;
  groupSize: number;
  timeLimitHours: number;
  timeLimitMinutes: number;
  existingReservations: AmenityReservation[];
  hoursOfOperation: OfficeHours[];
  maxCapacity: number;
  maxGroupSize: number;
  minBookingTimeHours: number;
  minBookingTimeMinutes: number;
  maxDaysInAdvanced: number;
  availableReservationDates: AvailableReservationDates[];
};

type AllProps = NewReservationProps &
  PropsFromState &
  PropsFromDispatch &
  RouteComponentProps &
  FormikProps<AmenityReservationSelector>;
type State = InputFormState & MyState;

class NewAmenityFormPage1 extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    step1display: true,
    step2display: false,
    step3display: false,
    openconfirm: false,
    id: 0,
    nameId: this.props.user!.nameId,
    rmPropId: this.props.user!.propertyId,
    amenityId: this.props.amenityToReserve!.id,
    name: this.props.amenityToReserve!.name, //this.props.amenityToReserve.name,
    reserveDate: new Date(new Date().setHours(new Date().getHours() - 24)),
    reserveEndDate: new Date(new Date().setHours(new Date().getHours() - 24)),
    timeStart: "",
    timeEnd: "",
    reservationDetails: "", //this.props.amenityToReserve.description,
    status: "",
    groupSize: 0,
    timeLimitHours: this.props.amenityToReserve!.timeLimitHours!,
    timeLimitMinutes: this.props.amenityToReserve!.timeLimitMinutes!,
    existingReservations: this.props.amenityToReserve!.existingReservations,
    hoursOfOperation: this.props.amenityToReserve!.hoursOfOperation,
    maxCapacity: this.props.amenityToReserve!.maxCapacity,
    maxGroupSize: this.props.amenityToReserve!.maxGroupSize,
    minBookingTimeHours: this.props.amenityToReserve!.minBookingTimeHours,
    minBookingTimeMinutes: this.props.amenityToReserve!.minBookingTimeMinutes,
    maxDaysInAdvanced: this.props.amenityToReserve!.maxDaysInAdvanced,
    availableReservationDates: this.props.amenityToReserve!.availableReservationDates,
  };

  submitValues = ({
    id,
    nameId,
    amenityId,
    rmPropId,
    name,
    reserveDate,
    reserveEndDate,
    timeStart,
    timeEnd,
    reservationDetails,
    status,
    groupSize,
  }: State) => {
    // tslint:disable-next-line:no-console

    const newAmenity: AmenityReservation = {
      id: 0,
      nameId: nameId,
      amenityId: this.state.amenityId,
      name: name,
      rmPropId: this.state.rmPropId,
      reserveDate: reserveDate,
      reserveEndDate: reserveEndDate,
      timeStart: timeStart.replace(/\?/g, ""),
      timeEnd: timeEnd.replace(/\?/g, ""),
      reservationDetails: reservationDetails,
      status: "",
      groupSize: groupSize,
    };
    this.props.insertAmenityRequest(newAmenity);

    this.setState({
      step1display: false,
      step2display: false,
      step3display: true,
      openconfirm: true,
      name: name,
      reserveDate: reserveDate,
      reserveEndDate: reserveEndDate,
      timeStart: timeStart,
      timeEnd: timeEnd,
    });

    const newActivity: RpActivity = {
      nameid: this.state.nameId,
      actcode: "AS",
      note: "Amenity reservation requested by resident",
      appsource: "WEBRP",
    };
    this.props.logActivity(newActivity);
  };

  handleOpenStep1 = () => {
    this.setState({ step1display: true, step2display: false, step3display: false });
  };

  handleOpenStep2 = () => {
    this.setState({ step1display: false, step2display: true, step3display: false });
  };

  handleOpenStep3 = () => {
    this.setState({ step1display: false, step2display: false, step3display: true });
  };
  handleCLoseConfirm = () => {
    this.setState({ openconfirm: false });
    this.props.history.push("/myaccount/amenity");
  };

  handleOpenAmenityPage = () => {
    this.props.history.push("/myaccount/amenity");
    window.location.reload();
  };

  setupReservationTimeString = (hrs: number, mins: number) => {
    var str = "";

    if (hrs > 0) {
      str += " " + hrs + " hour" + (hrs > 1 ? "s" : "");
    }

    if (mins > 0) {
      // Unlike hours, checking for pluralization seems unnecessary since it's doubtful we'd have only 1 minute
      str += " " + mins + " minutes";
    }

    return str;
  };

  setupOfficeHoursString = (ar: OfficeHours[]) => {
    var weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var strAmenHours = [];

    for (var i = 0, len = ar.length; i < len; i++) {
      var str = "";
      var dayStr = weekdays[ar[i].day];
      var openHour = ar[i].openTime;
      var closeHour = ar[i].closeTime;

      if (openHour[0] === "0") {
        openHour = openHour.substring(1);
      }
      if (closeHour[0] === "0") {
        closeHour = closeHour.substring(1);
      }
      str += dayStr + ": ";

      if (this.state.hoursOfOperation[i].closed === true) {
        str += "Closed";
      } else if (
        this.state.hoursOfOperation[i].openTime === "12:00AM" &&
        this.state.hoursOfOperation[i].closeTime === "12:00AM"
      ) {
        str += "24-Hours";
      } else {
        str += openHour + " - " + closeHour + ", ";

        str = str.replace("AM", " AM").replace("PM", " PM");

        str = str.substring(0, str.length - 2);
      }

      strAmenHours.push(str);
    }

    return strAmenHours;
  };

  render() {
    const { amenityToReserve } = this.props;
    const {
      step1display,
      step2display,
      step3display,
      id,
      amenityId,
      rmPropId,
      name,
      reserveDate,
      reserveEndDate,
      timeStart,
      timeEnd,
      reservationDetails,
      groupSize,
    } = this.state;
    const values = {
      id: id,
      nameId: this.props.user!.nameId,
      amenityId: amenityId,
      rmPropId: rmPropId,
      name: name,
      reserveDate: reserveDate,
      reserveEndDate: reserveEndDate,
      timeStart: timeStart,
      timeEnd: timeEnd,
      reservationDetails: reservationDetails,
      status: "",
      groupSize: groupSize,
      timeLimitHours: this.props.amenityToReserve!.timeLimitHours!,
      timeLimitMinutes: this.props.amenityToReserve!.timeLimitMinutes!,
      existingReservations: this.props.amenityToReserve!.existingReservations,
      hoursOfOperation: this.props.amenityToReserve!.hoursOfOperation,
      maxCapacity: this.props.amenityToReserve!.maxCapacity,
      maxGroupSize: this.props.amenityToReserve!.maxGroupSize,
      minBookingTimeHours: this.props.amenityToReserve!.minBookingTimeHours,
      minBookingTimeMinutes: this.props.amenityToReserve!.minBookingTimeMinutes,
      maxDaysInAdvanced: this.props.amenityToReserve!.maxDaysInAdvanced,
      availableReservationDates: this.props.amenityToReserve!.availableReservationDates,
    };
    if (!amenityToReserve) {
      return <div />;
    }

    var amenHours = this.setupOfficeHoursString(amenityToReserve.hoursOfOperation);
    var amenHoursDisplay = [];

    for (const [index, value] of amenHours.entries()) {
      amenHoursDisplay.push(
        <Typography variant="body2" key={index}>
          {value}
        </Typography>
      );
    }

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Paper className={this.props.classes.paper} elevation={1} style={{ marginTop: "3rem" }}>
            <Typography variant="h2" style={{ marginBottom: "1rem" }}>
              You're on your way to reserving the {name}!
            </Typography>
            <Grid container direction="row" spacing={24}>
              <Grid item>
                <Link onClick={this.handleOpenStep1}>
                  <img
                    className="step-icon"
                    alt="Step 1"
                    src="https://media.equityapartments.com/image/upload/co_rgb:394b76,e_colorize:100,f_auto/v1544721640/Content/portal/uielements/step1.svg"
                  />
                </Link>
              </Grid>
              <Grid item xs>
                <Link onClick={this.handleOpenStep1} style={{ cursor: "hand" }}>
                  <Typography variant="h5" style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}>
                    Please review amenity details
                  </Typography>
                </Link>
                <Collapse in={step1display} timeout="auto">
                  <Typography variant="h5">AMENITY DESCRIPTION</Typography>
                  <Typography variant="body2">{amenityToReserve.description}</Typography>
                  <br />
                  <Typography variant="h5">AMENITY RULES</Typography>
                  <Typography variant="body2">Hours:</Typography>
                  {amenHoursDisplay}
                  <br />
                  {amenityToReserve.maxCapacity !== 1 && (
                    <Typography variant="body2">
                      {"Maximum Occupancy: " + amenityToReserve.maxCapacity}
                      <br />
                    </Typography>
                  )}
                  <Typography variant="body2">
                    {"Maximum Reservation Time: " +
                      this.setupReservationTimeString(
                        amenityToReserve.timeLimitHours!,
                        amenityToReserve.timeLimitMinutes
                      )}
                  </Typography>
                  <br />
                  <Typography variant="body2">{amenityToReserve.rules}</Typography>
                  <br />
                  <Typography variant="h5">CANCELLATION POLICY</Typography>
                  <Typography variant="body2">{amenityToReserve.cancellationPolicy}</Typography>
                  <br />
                  {amenityToReserve.dueAtReserve! > 0 && (
                    <Typography variant="body1">$ {amenityToReserve.dueAtReserve} Due at Reservation</Typography>
                  )}
                  {amenityToReserve.dueAtEvent! > 0 && (
                    <Typography variant="body1">$ {amenityToReserve.dueAtEvent} Due at Event</Typography>
                  )}
                  {amenityToReserve.refundableFee! > 0 && (
                    <Typography variant="body1">$ {amenityToReserve.refundableFee} Deposit Reservation</Typography>
                  )}
                  <Typography variant="body1">
                    {parseFloat(String(amenityToReserve.dueAtReserve)) +
                      parseFloat(String(amenityToReserve.dueAtEvent)) >
                      0 && (
                      <span>
                        ${" "}
                        {parseFloat(String(amenityToReserve.dueAtReserve)) +
                          parseFloat(String(amenityToReserve.dueAtEvent))}{" "}
                        Total Reservation Cost
                      </span>
                    )}
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    style={{ marginRight: "10px", marginBottom: "10px", marginTop: "10px" }}
                    onClick={this.handleOpenStep2}
                  >
                    Pick a day
                  </Button>
                </Collapse>
              </Grid>
            </Grid>

            <Grid container direction="row" spacing={24}>
              <Grid item>
                <img
                  alt="Step 2"
                  className="step-icon"
                  src="https://media.equityapartments.com/image/upload/co_rgb:394b76,e_colorize:100,f_auto/v1544721640/Content/portal/uielements/step2.svg"
                />
              </Grid>
              <Grid item xs>
                <Typography variant="h5" style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}>
                  Select date and time
                </Typography>
                <Collapse in={step2display} timeout="auto">
                  <React.Fragment>
                    <Formik
                      render={(props) => <AmenityNewSelector {...props} />}
                      initialValues={values}
                      validationSchema={validationSchema}
                      onSubmit={this.submitValues}
                    />
                  </React.Fragment>
                </Collapse>
              </Grid>
            </Grid>

            <Grid container direction="row" spacing={24}>
              <Grid item>
                <img
                  alt="Step 3"
                  className="step-icon"
                  src="https://media.equityapartments.com/image/upload/co_rgb:394b76,e_colorize:100,f_auto/v1544721640/Content/portal/uielements/step3.svg"
                />
              </Grid>
              <Grid item xs>
                <Typography variant="h5" style={{ paddingTop: ".5rem", paddingBottom: ".5rem" }}>
                  Summary
                </Typography>
                <Collapse in={step3display} timeout="auto">
                  <Grid container spacing={24}>
                    <Grid item>
                      <Typography variant="h2">Your reservation request has been received</Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={24}>
                    <Grid item>
                      <img
                        alt="Reservation"
                        className="step-icon"
                        src="https://media.equityapartments.com/image/upload/co_rgb:394b76,e_colorize:100,f_auto/v1544721640/Content/portal/myaccount/amenityreservation.svg"
                      />
                    </Grid>
                    <Grid item xs>
                      {values.reserveDate !== values.reserveEndDate && (
                        <Typography
                          variant="body1"
                          style={{ marginRight: "10px", marginBottom: "10px", marginTop: "10px" }}
                        >
                          Your request for the {this.state.name} on{" "}
                          <Moment format="LL">{values.reserveDate.toString()}</Moment> between {this.state.timeStart}{" "}
                          and <Moment format="LL">{values.reserveEndDate.toString()}</Moment> {this.state.timeEnd} is
                          now being reviewed. In the next 24 hours we'll be in touch to confirm your reservation.
                        </Typography>
                      )}
                      {values.reserveDate === values.reserveEndDate && (
                        <Typography
                          variant="body1"
                          style={{ marginRight: "10px", marginBottom: "10px", marginTop: "10px" }}
                        >
                          Your request for the {this.state.name} on{" "}
                          <Moment format="LL">{values.reserveDate.toString()}</Moment> between {this.state.timeStart}{" "}
                          and {this.state.timeEnd} is now being reviewed. In the next 24 hours we'll be in touch to
                          confirm your reservation.
                        </Typography>
                      )}
                      <Typography
                        variant="body2"
                        style={{ marginRight: "10px", marginLeft: "50px", marginBottom: "10px", marginTop: "10px" }}
                      >
                        {amenityToReserve.cancellationPolicy}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* <NavLink to={"../amenity"} style={{ textDecoration: "none" }}>
                    <Button
                      color="primary"
                      autoFocus
                      variant="contained"
                      size="large"
                      style={{ marginRight: "10px", marginBottom: "30px", marginTop: "30px" }}
                    >
                      Got it!
                    </Button>
                  </NavLink> */}
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    style={{ marginRight: "10px", marginBottom: "10px", marginTop: "10px" }}
                    onClick={this.handleOpenAmenityPage}
                  >
                    Got it!
                  </Button>
                </Collapse>
              </Grid>
            </Grid>
          </Paper>
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ amenity, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  amenityToReserve: amenity.newReservation.amenity,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  insertAmenityRequest: (payload: AmenityReservation) => dispatch(insertAmenityRequest(payload)),
  logActivity: (act: RpActivity) => dispatch(logActivity(act)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewAmenityFormPage1));
