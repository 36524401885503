import { createAction } from "typesafe-actions";

import {
  ServiceRequest,
  ServiceRequestCategory,
  ServiceRequestLocation,
  ServiceRequestQuickFix,
  ServiceRequestSubCategory,
  ServiceRequestTopLevelCategory,
  ServiceRequestType,
  ServiceRequestUpload,
} from "./models";

const LIST_FETCH_REQUEST = "servicereqs/list/FETCH_REQUEST";
const LIST_FETCH_SUCCESS = "servicereqs/list/FETCH_SUCCESS";
const LIST_FETCH_ERROR = "servicereqs/list/FETCH_ERROR";
export const listFetchRequest = createAction(LIST_FETCH_REQUEST)<string>();
export const listFetchSuccess = createAction(LIST_FETCH_SUCCESS)<ServiceRequest[]>();
export const listFetchError = createAction(LIST_FETCH_ERROR)<string>();

const CATEGORIES_FETCH_REQUEST = "servicereqs/categories/FETCH_REQUEST";
const CATEGORIES_FETCH_SUCCESS = "servicereqs/categories/FETCH_SUCCESS";
const CATEGORIES_FETCH_ERROR = "servicereqs/categories/FETCH_ERROR";
export const categoriesFetchRequest = createAction(CATEGORIES_FETCH_REQUEST)();
export const categoriesFetchSuccess = createAction(CATEGORIES_FETCH_SUCCESS)<ServiceRequestCategory[]>();
export const categoriesFetchError = createAction(CATEGORIES_FETCH_ERROR)<string>();

const QUICK_FIXES_FETCH_REQUEST = "servicereqs/quickFixes/FETCH_REQUEST";
const QUICK_FIXES_FETCH_SUCCESS = "servicereqs/quickFixes/FETCH_SUCCESS";
const QUICK_FIXES_FETCH_ERROR = "servicereqs/quickFixes/FETCH_ERROR";
export const quickFixesFetchRequest = createAction(QUICK_FIXES_FETCH_REQUEST)();
export const quickFixesFetchSuccess = createAction(QUICK_FIXES_FETCH_SUCCESS)<ServiceRequestQuickFix[]>();
export const quickFixesFetchError = createAction(QUICK_FIXES_FETCH_ERROR)<string>();

const LOCATIONS_FETCH_REQUEST = "servicereqs/locations/FETCH_REQUEST";
const LOCATIONS_FETCH_SUCCESS = "servicereqs/locations/FETCH_SUCCESS";
const LOCATIONS_FETCH_ERROR = "servicereqs/locations/FETCH_ERROR";
export const locationsFetchRequest = createAction(LOCATIONS_FETCH_REQUEST)();
export const locationsFetchSuccess = createAction(LOCATIONS_FETCH_SUCCESS)<ServiceRequestLocation[]>();
export const locationsFetchError = createAction(LOCATIONS_FETCH_ERROR)<string>();

const TYPES_FETCH_REQUEST = "servicereqs/types/FETCH_REQUEST";
const TYPES_FETCH_SUCCESS = "servicereqs/types/FETCH_SUCCESS";
const TYPES_FETCH_ERROR = "servicereqs/types/FETCH_ERROR";
export const typesFetchRequest = createAction(TYPES_FETCH_REQUEST)<string>();
export const typesFetchSuccess = createAction(TYPES_FETCH_SUCCESS)<ServiceRequestType[]>();
export const typesFetchError = createAction(TYPES_FETCH_ERROR)<string>();

const VIEW_DETAILS = "servicereqs/details/VIEW_DETAILS";
const HIDE_DETAILS = "servicereqs/details/HIDE_DETAILS";
export const viewDetails = createAction(VIEW_DETAILS)<ServiceRequest>();
export const hideDetails = createAction(HIDE_DETAILS)();

const SELECT_TOP_LEVEL_CATEGORY = "servicereqs/new/SELECT_TOP_LEVEL_CATEGORY";
const SELECT_SUBCATEGORY = "servicereqs/new/SELECT_SUBCATEGORY";
const SELECT_TYPE = "servicereqs/new/SELECT_TYPE";
const SELECT_LOCATION = "servicereqs/new/SELECT_LOCATION";
export const selectTopLevelCategory = createAction(SELECT_TOP_LEVEL_CATEGORY)<ServiceRequestTopLevelCategory>();
export const selectSubCategory = createAction(SELECT_SUBCATEGORY)<ServiceRequestSubCategory>();
export const selectType = createAction(SELECT_TYPE)<ServiceRequestType>();
export const selectLocation = createAction(SELECT_LOCATION)<ServiceRequestLocation>();

const POST_REQUEST = "servicereqs/new/POST_REQUEST";
const POST_SUCCESS = "servicereqs/new/POST_SUCCESS";
const POST_ERROR = "servicereqs/new/POST_ERROR";
export const postRequest = createAction(POST_REQUEST)<ServiceRequestUpload>();
export const postSuccess = createAction(POST_SUCCESS)<ServiceRequest>();
export const postError = createAction(POST_ERROR)<string>();
