import { Card, CardContent, List, ListItem, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { RootState } from "../../../store/root-reducer";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { groupFeedFetchRequest } from "../actionsGroup";
import { GROUP_FEED } from "../constants";
import { DashboardFeedItem, DiscussionItem, EventItem, ForSaleItem } from "../models";
import Discussion from "./Discussion";
import Event from "./Event";
import ForSaleComponent from "./ForSale";
import NewPost from "./NewPost";
import styles from "./styles";

interface Props extends WithStyles<typeof styles> {
  groupFeed: DashboardFeedItem[];
  groupId: number;
}

interface PropsFromState {
  user?: UserInfoItem;
  items: DashboardFeedItem[];
  loading: boolean;
  errors?: string;
}

interface PropsFromDispatch {
  feedFetchRequest: typeof groupFeedFetchRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

class GroupsFeed extends React.Component<AllProps> {
  render() {
    return (
      <List>
        {this.props.groupFeed.map(x => (
          <ListItem key={x.id} style={{ paddingLeft: "0", paddingRight: "0" }}>
            {(() => {
              switch (x.itemType) {
                case "ForSaleItem":
                  return <ForSaleComponent item={x as ForSaleItem} feedType={GROUP_FEED} />;
                case "DiscussionItem":
                  return <Discussion item={x as DiscussionItem} feedType={GROUP_FEED} />;
                case "EventItem":
                  return <Event item={x as EventItem} feedType={GROUP_FEED} />;
                default:
                  return null;
              }
            })()}
          </ListItem>
        ))}

        {this.props.groupFeed.length === 0 && this.props.loading === false && (
          <Card style={{ width: "100%" }}>
            <CardContent>
              <Typography variant="h1" style={{ marginBottom: "1rem" }}>
                It looks like there aren't any posts yet
              </Typography>
              <NewPost buttonText="Get Involved" groupId={this.props.groupId} />
            </CardContent>
          </Card>
        )}
      </List>
    );
  }
}

const mapStateToProps = ({ social, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  loading: social.loading,
  errors: social.errors,
  items: social.data
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  feedFetchRequest: (payload: { groupId: string; nameId: string }) => dispatch(groupFeedFetchRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupsFeed));
