import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { SvcProvider } from "./models";
import * as actions from "./actions";

export type SvcProviderAction = ActionType<typeof actions>;

export type SvcProviderState = Readonly<{
  loading: boolean;
  data?: SvcProvider[];
  errors?: string;
}>;

const initialState: SvcProviderState = {
  loading: false,
  data: undefined,
  errors: undefined
};

const reducer: Reducer<SvcProviderState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.svcFetchRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.svcFetchSuccess): {
      return { ...state, loading: false, data: action.payload };
    }
    case getType(actions.svcFetchError): {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
