const baseUrl = "https://media.equityapartments.com/image/upload";

export function GenerateCloudinaryUrl(relativePath: string, queryParams: string = "") {
  if (relativePath.startsWith("http")) return relativePath;
  return baseUrl + "/" + queryParams + "/" + relativePath;
}

export function GenerateCloudinaryThumbnailUrl(relativePath: string) {
  if (relativePath.startsWith("http")) return relativePath;
  return baseUrl + "/c_thumb,w_150,h_150/" + relativePath;
}

export function GenerateCloudinaryLargeSquareUrl(relativePath: string) {
  if (relativePath.startsWith("http")) return relativePath;
  return baseUrl + "/w_1000,h_1000/" + relativePath;
}
