import {
  Button,
  Collapse,
  Hidden,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import classNames from "classnames";
import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { viewDetails } from "../actions";
import { ServiceRequest, ServiceRequestCategory } from "../models";
import servicestyles from "./styles";

const styles = combinestyles(servicestyles, globalstyles);

export interface ServiceReqListProps extends WithStyles<typeof styles> {
  title: string;
  items: ServiceRequest[];
  collapsible: boolean;
}

interface PropsFromState {
  categories: ServiceRequestCategory[];
}

interface PropsFromDispatch {
  viewDetails: (item: ServiceRequest) => any;
}

type State = {
  expanded: boolean;
};

type AllProps = ServiceReqListProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

class ServiceReqList extends React.Component<AllProps, State> {
  state: Readonly<State> = { expanded: false };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  renderItem(x: ServiceRequest) {
    const category = this.props.categories.find(y => y.categoryId === x.categoryId);
    let categoryName = "";
    if (category !== undefined && category !== null) {
      categoryName = category.name;
    }

    return (
      <ListItem key={x.workOrderId} divider>
        <Typography>
          <Moment format="MM/DD/YY">{x.dateReported.toString()}</Moment>
        </Typography>
        <ListItemText primary={categoryName} />
        <ListItemSecondaryAction>
          <Button style={{ color: "#3d90d4" }} onClick={() => this.props.viewDetails(x)}>
            <Hidden xsDown>Details</Hidden>
            <LaunchIcon />
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  render() {
    const { title, items } = this.props;
    const { expanded } = this.state;
    this.renderItem = this.renderItem.bind(this);
    return (
      <Paper className={classNames(this.props.classes.paper)}>
        <Typography variant="h3">{title}</Typography>
        {!this.props.collapsible && <List>{items.map(this.renderItem)}</List>}
        {this.props.collapsible && (
          <div>
            <Collapse in={expanded} timeout="auto" collapsedHeight="100px">
              <List>{items.map(this.renderItem)}</List>
            </Collapse>
            <Typography align="center" onClick={this.handleExpandClick} className={this.props.classes.link}>
              {expanded ? "Show Less" : "Show More"}
            </Typography>
          </div>
        )}
      </Paper>
    );
  }
}

const mapStateToProps = ({ servicereqs }: RootState) => ({
  categories: servicereqs.serviceRequestCategories.flatData
});

const mapDispatchToProps = {
  viewDetails: (item: ServiceRequest) => viewDetails(item)
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ServiceReqList)));
