import { WithStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import { categoriesFetchRequest, listFetchRequest } from "../features/amenity/actions";
import ENTVDashboard from "../features/entv/components/entv-dashboard";
import ENTVForm from "../features/entv/components/entv-form";
import ENTVSummary from "../features/entv/components/entvsummary";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import { UserInfoItem } from "../features/userInfo/models";
import { MyAccountDashboard } from "../features/myaccount/models";
import globalstyles from "../global-styles";
import { RootState } from "../store/root-reducer";
import entvmstyles from "../styles";
import combinestyles from "../utils/combinestyles";
import { myAccountDashboardFetchRequest } from "../features/myaccount/actions";
import ENTVContact from "../features/entv/components/entv-contact";

const styles = combinestyles(entvmstyles, globalstyles);

interface IeNTVProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
}

interface IPageState {}

interface PropsFromState {
  user: UserInfoItem;
  myaccountdashboardData: MyAccountDashboard | undefined;
}

interface PropsFromDispatch {
  categoriesFetchRequest: typeof categoriesFetchRequest;
  listFetchRequest: typeof listFetchRequest;
  myAccountDashboardFetchRequest: typeof myAccountDashboardFetchRequest;
}

type AllProps = IeNTVProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

class ENTVPage extends React.Component<AllProps, IPageState> {
  state: IPageState = {};

  componentDidMount() {
    window.scrollTo(0, 0);
    const nameId = this.props.user.nameId;
    this.props.myAccountDashboardFetchRequest(nameId);
  }

  render() {
    if (!this.props.myaccountdashboardData) {
      return <div />;
    }

    return (
      <Switch>
        <Route
          path="/myaccount/entv/entvform"
          component={this.props.myaccountdashboardData.entvSummaryDisplay ? ENTVContact : ENTVForm}
        />
        <Route path="/myaccount/entv/entvsummary" component={ENTVSummary} />
        <Route component={this.props.myaccountdashboardData.entvSummaryDisplay ? ENTVContact : ENTVDashboard} />
      </Switch>
    );
  }
}

const mapStateToProps = ({ myaccountdashboard, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  myaccountdashboardData: myaccountdashboard.myAccountData.data
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  myAccountDashboardFetchRequest: (nameid: string) => dispatch(myAccountDashboardFetchRequest(nameid)),
  categoriesFetchRequest: (rmpropid: string) => dispatch(categoriesFetchRequest(rmpropid)),
  listFetchRequest: (nameid: string) => dispatch(listFetchRequest(nameid))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ENTVPage));
