import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { Formik } from "formik";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as Yup from "yup";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import DialogHeader from "../../shared/components/DialogHeader";
import { UserInfoItem } from "../../userInfo/models";
import { closeSocialModal, createPostRequest, openSocialModal } from "../actions";
import { createPostGroupRequest, groupFetchRequest } from "../actionsGroup";
import { DISCUSSION_ITEM, EVENT_ITEM, FOR_SALE_ITEM } from "../constants";
import { CreatePostDto, Group, RpActivity } from "../models";
import NewPostForm from "./NewPostForm";
import socialstyles from "./styles";

const styles = combinestyles(socialstyles, globalstyles);

const validationSchema = Yup.object({
  // All Forms
  postType: Yup.string(),
  title: Yup.string().required("Title is required").max(100, "Character limit 100"),
  content: Yup.string().required("Content is required"),

  // For Sale Post Only
  price: Yup.string().when("postType", {
    is: (val: string) => val === FOR_SALE_ITEM,
    then: Yup.string().required("Price is required").max(250, "Character limit 250"),
  }),

  // Event Post Only
  location: Yup.string().when("postType", {
    is: (val: string) => val === EVENT_ITEM,
    then: Yup.string().required("Location is required").max(250, "Character limit 250"),
  }),
  date: Yup.date()
    .nullable()
    .when("postType", {
      is: (val: string) => val === EVENT_ITEM,
      then: Yup.date()
        .required("Please enter a valid date")
        .min(moment(new Date()).subtract(1, "days").toDate(), "Date must be in the future"),
    }),

  edate: Yup.date()
    .nullable()
    .when("postType", {
      is: (val: string) => val === EVENT_ITEM,
      then: Yup.date().min(Yup.ref("date"), "End Date must be after the Start Date"),
    }),

  // starttime: Yup.date()
  //   .nullable()
  //   //.when("postType", {
  //   //is: val => val === EVENT_ITEM,
  //   //then: Yup.date()
  //   .required("Start Time is required")
  //   .when("date", {
  //     is: (date: Date) => date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0),
  //     then: Yup.date().min(moment(Date.now()).toDate(), "Start Time must be in the future"),
  //   }), 

  endtime: Yup.date()
    .nullable()
    .test("required-If", "End Time must be after the Start Time", function () {
      const check = checkDates(this.parent, {
        dependent1: "date",
        dependent2: "edate",
        dependent3: "starttime",
        dependent4: "endtime",
      });
      return check;
    }),
  //.when("postType", {
  //  is: val => val === EVENT_ITEM,
  //  then: Yup.date()
  // .when("edate", {
  //   is: edate => edate.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0),
  //   then: Yup.date().min(Yup.ref("starttime"), "End Time must be after the Start Time")
  //   //})
  // }),

  rsvp: Yup.string().when("postType", {
    is: (val: string) => val === EVENT_ITEM,
    then: Yup.string().required("RSVP is required").oneOf(["true", "false"], "RSVP is required"),
  }),
});

const checkDates = (parent: any, { dependent1, dependent2, dependent3, dependent4 }: any) => {
  const startDay = parent && parent[dependent1];
  const endDay = parent && parent[dependent2];
  const startTime = parent && parent[dependent3];
  const endTime = parent && parent[dependent4];

  let vv = true;
  if (moment(startDay).isSame(moment(endDay)) && moment(startTime).isAfter(moment(endTime))) {
    vv = false;
  }

  return vv;
};

interface Props extends WithWidth, WithStyles<typeof styles> {
  groupId?: number;
  buttonText?: string;
}

interface PropsFromState {
  user?: UserInfoItem;
  groups: Group[];
  errors: string | undefined;
  modalOpen: boolean;
  posting: boolean;
}

interface PropsFromDispatch {
  logActivity: typeof logActivity;
  createPostRequest: typeof createPostRequest;
  createPostGroupRequest: typeof createPostGroupRequest;
  groupFetchRequest: typeof groupFetchRequest;
  openModal: typeof openSocialModal;
  closeModal: typeof closeSocialModal;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

type MyState = {
  open: boolean;
  modalVisibility: boolean;
};

export type InputFormState = {
  activeTab: number;
  postType: string;
  title: string;
  content: string;
  photos: File[];
  price: string;
  location: string;
  date: Date | null;
  edate: Date | null;
  rsvp: boolean | undefined;
  starttime: Date | null;
  endtime: Date | null;
  groupId: number | null;
};

type State = MyState & InputFormState;

class NewPost extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    open: false,
    activeTab: 0,
    postType: "Discussion",
    title: "",
    content: "",
    photos: [],
    price: "",
    location: "",
    date: null,
    edate: null,
    rsvp: undefined,
    starttime: null,
    endtime: null,
    groupId: null,
    modalVisibility: false,
  };

  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.groupFetchRequest(nameId);
  }

  attachDateToTime(date: any, time: any) {
    if (!date || !time) {
      return null;
    }
    let combinedDate = new Date(date);
    combinedDate.setUTCHours(time.getHours());
    combinedDate.setUTCMinutes(time.getMinutes());
    return combinedDate;
  }

  submitValues = ({ postType, title, content, price, location, date, edate, rsvp, starttime, endtime }: State) => {
    if (this.props.posting) return;
    const newPost: CreatePostDto = {
      authorNameId: this.props.user!.nameId,
      title: title,
      itemType: postType,
      location: location,
      price: price,
      message: content,
      date: postType === EVENT_ITEM && date ? date : undefined,
      eDate: postType === EVENT_ITEM && edate ? edate : undefined,
      rsvpEnabled: rsvp ? rsvp : false,
      startDate: postType === EVENT_ITEM ? this.attachDateToTime(date, starttime) : null,
      endDate: postType === EVENT_ITEM ? this.attachDateToTime(edate, endtime) : null,
      photos: this.state.photos,
      groupId: this.state.groupId,
      communityPost: false,
      guestsAllowed: false,
      maxAttendees: 0,
      maxGuests: 0,
    };
    if (postType === DISCUSSION_ITEM) {
      this.props.logActivity({
        actcode: "PD",
        note: "Resident submitted a discussion post",
      });
    } else if (postType === FOR_SALE_ITEM) {
      this.props.logActivity({
        actcode: "PS",
        note: "Resident submitted a for sale post",
      });
    } else if (postType === EVENT_ITEM) {
      this.props.logActivity({
        actcode: "PE",
        note: "Resident submitted an event",
      });
    }
    // Creating Post from Group Feed
    if (this.props.groupId) {
      this.handleOpenModal();
      this.props.createPostGroupRequest({
        post: newPost,
        refreshGroupId: this.props.groupId,
      });
    } else {
      this.handleOpenModal();
      this.props.createPostRequest(newPost);
    }
    this.setState({ photos: [] });
    //this.handleClose();
  };

  handleClickOpen = () => {
    this.props.openModal();
  };

  handleClose = () => {
    this.setState({ open: false, photos: [], groupId: null });
    this.props.closeModal();
  };

  handleFileUpload = (files: File[]) => {
    this.setState({ photos: files });
  };
  handleGroupSelection = (value: any) => {
    if (!value || value === "0") {
      this.setState({ groupId: null });
    } else {
      this.setState({ groupId: Number(value) });
    }
  };

  handleOpenModal = () => {
    this.setState({ modalVisibility: true });
  };
  handleCloseModal = () => {
    this.setState({ modalVisibility: false });
  };

  render() {
    const {
      postType,
      title,
      content,
      photos,
      price,
      location,
      activeTab,
      open,
      date,
      edate,
      starttime,
      endtime,
      groupId,
      modalVisibility,
    } = this.state;

    const initialValues = {
      postType: postType,
      open: open,
      activeTab: activeTab,
      title: title,
      content: content,
      price: price,
      location: location,
      date: date,
      edate: edate,
      rsvp: undefined,
      starttime: starttime,
      endtime: endtime,
      photos: photos,
      groupId: groupId,
      modalVisibility: modalVisibility,
    };

    return (
      <React.Fragment>
        <Button onClick={this.handleClickOpen} variant="contained" color="primary">
          {this.props.buttonText || "+ New Post"}
        </Button>
        <Dialog
          open={this.props.modalOpen}
          onClose={this.handleClose}
          fullScreen={this.props.width === "xs" ? true : false}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle style={{ padding: "0", overflow: "hidden" }}>
            <DialogHeader title="New Post" handleClose={this.handleClose} />
          </DialogTitle>
          <DialogContent style={{ padding: "0" }}>
            <Formik
              render={(props) => (
                <NewPostForm
                  groups={this.props.groups.sort((x, y) => {
                    const n1 = x.name.toUpperCase();
                    const n2 = y.name.toUpperCase();
                    if (n1 > n2) return 1;
                    if (n1 < n2) return -1;
                    return 0;
                  })}
                  selectedGroup={this.props.groupId}
                  {...props}
                  handleGroupSelection={this.handleGroupSelection}
                  handleFileUpload={this.handleFileUpload}
                />
              )}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={this.submitValues}
            />

            {this.props.errors && (
              <DialogContent style={{ padding: "0 20px 20px" }}>
                <Typography style={{ textAlign: "center", color: "red" }}>
                  There was an error connecting to our servers
                </Typography>
              </DialogContent>
            )}
            {this.props.posting && (
              <Dialog open={this.state.modalVisibility} onClose={this.handleCloseModal}>
                <DialogContent>
                  <CircularProgress />
                </DialogContent>
              </Dialog>
            )}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ selectAccount, social, socialGroup }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  groups: socialGroup.groupUserList.dataUser,
  errors: social.errors,
  modalOpen: social.modalOpen,
  posting: social.posting,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  logActivity: (activity: RpActivity) => dispatch(logActivity(activity)),
  createPostRequest: (payload: CreatePostDto) => dispatch(createPostRequest(payload)),
  createPostGroupRequest: (payload: { post: CreatePostDto; refreshGroupId: number }) =>
    dispatch(createPostGroupRequest(payload)),
  groupFetchRequest: (nameId: string) => dispatch(groupFetchRequest(nameId)),
  openModal: () => dispatch(openSocialModal()),
  closeModal: () => dispatch(closeSocialModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles)(NewPost)));
