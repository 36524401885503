import * as React from "react";
import { Dialog, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";
import DialogHeader from "./DialogHeader";

interface Props {
  open: boolean;
  handleClose: any;
}

type AllProps = Props;

class SecurityDepositFaq extends React.Component<AllProps> {
  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.handleClose} scroll="paper">
        <DialogHeader
          title="FAQs on your final account statement and security deposit"
          handleClose={this.props.handleClose}
        />
        <DialogContent>
          <Typography variant="h3" gutterBottom style={{ marginBottom: "1rem" }}>
            What is a SODA?
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1.5rem" }}>
            SODA stands for Statement of Deposit Account. This is a document that details all charges that have been
            posted to your account and how your security deposit was applied to any outstanding charges to determine the
            amount of refund that is due to you.
          </Typography>
          <Typography variant="h3" gutterBottom style={{ marginBottom: "1rem" }}>
            When will my SODA be complete?
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1.5rem" }}>
            We generally try to have your SODA completed within 10 business days of the date you move out. In some
            states where regulations apply, we must finalize the SODA sooner. In other cases where we are waiting on
            charges to be finalized, we may take up to 10 business days to finalize your SODA.
          </Typography>
          <Typography variant="h3" gutterBottom style={{ marginBottom: "1rem" }}>
            How will my SODA and security deposit refund be delivered?
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1.5rem" }}>
            Your SODA will be mailed to you via US mail once it is completed. You can also view your SODA and your
            account statement online at www.my.equityapartments.com if you have established an account on our resident
            portal.
          </Typography>
          <Typography variant="h3" gutterBottom style={{ marginBottom: "1rem" }}>
            What other charges should I expect on my statement after I move out?
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1.5rem" }}>
            Most residents will see their final utility bill charges on their final statement, along with any charges
            that we have assessed based on the move-out condition of their apartment. These may include costs for repair
            of damage to the apartment and any cleaning services needed to bring it to the same clean, undamaged, and
            ready-to-rent condition that it was when you moved in.
          </Typography>
          <Typography variant="h3" gutterBottom style={{ marginBottom: "1rem" }}>
            How is my security deposit refund determined?
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1.5rem" }}>
            Once all charges have been assessed on your statement, the amount of your security deposit will be applied
            to any unpaid charges. Your refund or balance due will then be computed as the charges due minus the amount
            of the security deposit.
          </Typography>
          <Typography variant="h3" gutterBottom style={{ marginBottom: "1rem" }}>
            Why are there additional utility charges on my final statement?
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1.5rem" }}>
            Often there are two charges for each utility on your final statement. Because we generally bill utility
            charges in arrears, you will have your normal utility bill charges that you would have paid in during your
            final month (which cover an earlier time period), plus a final bill which covers the time period from the
            end date of your normal utility bill through your move out date.
          </Typography>
          <Typography variant="h3" gutterBottom style={{ marginBottom: "1rem" }}>
            Why haven’t I received a SODA yet?
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1.5rem" }}>
            It could be possible that your SODA did not get delivered or was delayed in the US mail, particularly if
            your forwarding address was not correct or if we did not have one on file. You can check to see if your SODA
            is available online at www.my.equityapartments.com. If after 10 business days your SODA has not been made
            available online, please contact the management office at the community where you resided.
          </Typography>
          <Typography variant="h3" gutterBottom style={{ marginBottom: "1rem" }}>
            What should I do if I believe there is an error on my statement?
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1.5rem" }}>
            We strive to be as accurate as possible with your account statement and SODA. If you believe that something
            is in error please contact the management office at the community where you resided.
          </Typography>
          <Typography variant="h3" gutterBottom style={{ marginBottom: "1rem" }}>
            How can I pay my final balance if I have one?
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1.5rem" }}>
            We encourage you to check with the management office prior to vacating your apartment and pay any
            outstanding charges so that it is more likely your security deposit will be returned in full. If you have a
            balance due once your security deposit has been applied, you may pay online at www.my.equityapartments.com
            via an electronic debit from your checking account, or by credit card.
          </Typography>
          <Typography variant="h3" gutterBottom style={{ marginBottom: "1rem" }}>
            Will there be any additional charges to my account after I receive my SODA?
          </Typography>
          <Typography gutterBottom style={{ marginBottom: "1.5rem" }}>
            Not usually. We always attempt to assess all charges prior to preparing your final SODA. However, in some
            cases we may encounter charges for repairs or utility bills that were not evident within the first few days
            after your move. In these cases, we may assess these charges to your account after the SODA was mailed, and
            will contact you to explain the charges and your payment options.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default SecurityDepositFaq;
