import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import styles from "./styles";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown/style.css";
import { RootState } from "../../../store/root-reducer";
import { getActiveUserInfo } from "../../selectAccount/selector";
import FormikInputError from "../../shared/components/FormikInputError";
import { UserInfoItem } from "../../userInfo/models";

import DatePicker from "react-datepicker";
import ScheduleIcon from "@material-ui/icons/Schedule";
import EventIcon from "@material-ui/icons/Event";
import DialogHeader from "../../shared/components/DialogHeader";
import { saveWelcomeHomeOrientationRequest } from "../../../utils/api";
import Dropdown from "react-dropdown";
import moment from "moment";

interface Props extends WithStyles<typeof styles> {
  leaseDate: Date;
  disabled: boolean;
}

interface PropsFromState {
  user?: UserInfoItem;
}

type MyState = {
  modalVisibility: boolean;
  canSubmit: boolean;
  confirmationShown: boolean;
  requestedDate?: Date;
  requestedTime?: string;
  includedDates: Date[];
  includedTimes: string[];
};

type AllProps = Props & PropsFromState;

class WelcomHomeOrientation extends Component<AllProps, MyState> {
  state: Readonly<MyState> = {
    canSubmit: false,
    confirmationShown: false,
    modalVisibility: false,
    includedDates: [],
    includedTimes: []
  };

  componentDidMount = () => {
    if (this && this.props && this.props.user && this.props.user.propertyInfo) {
      let calendarDates: Date[] = [];
      const currDate: Date = new Date(this.props.leaseDate);
      // add available dates based on office hours for 30 days beyond lease start

      let leaseStart30Days = new Date(this.props.leaseDate);
      leaseStart30Days.setDate(this.props.leaseDate.getDate() + 30);

      while (currDate < leaseStart30Days) {
        let officeDate = this.props.user.propertyInfo.officeHours.find((a) => parseInt(a.day) === currDate.getDay());
        if (officeDate && !officeDate.closed) {
          calendarDates.push(new Date(currDate.valueOf()));
        }

        currDate.setDate(currDate.getDate() + 1);
      }

      this.setState({ includedDates: calendarDates });
    }
  };

  handleOpenModal = () => {
    this.setState({ modalVisibility: true });
  };

  handleCloseModal = () => {
    if (this.state.confirmationShown) {
      window.location.reload();
    }

    this.setState({ modalVisibility: false, confirmationShown: false });
  };

  handleSubmit = (values: any) => {
    // states have to be set, but just to be sure...
    if (this.state.requestedDate && this.state.requestedTime) {
      const payload = {
        nameId: this.props.user!.nameId,
        requestedDate:
          this.state.requestedDate.getFullYear() +
          "-" +
          (this.state.requestedDate.getMonth() + 1) +
          "-" +
          this.state.requestedDate.getDate(),
        requestedTime: this.state.requestedTime
      };

      saveWelcomeHomeOrientationRequest(payload).then(() => {
        this.setState({
          modalVisibility: false,
          confirmationShown: true
        });
      });
    }
  };

  handleDateChange = (date: any) => {
    let timeOptions = [];

    let officeDate = this.props.user!.propertyInfo.officeHours.find((a) => parseInt(a.day) === date.getDay());

    if (officeDate) {
      // helper functions to convert a time to a float ...
      const convertTime12toFloat = (time12h: string) => {
        const [time, modifier] = time12h.split(" ");

        let [hours, minutes] = time.split(":");

        if (hours === "12") {
          hours = "00";
        }

        if (modifier === "PM") {
          hours = (parseInt(hours, 10) + 12).toString();
        }

        let timeFloat: number = parseFloat(hours);
        timeFloat = timeFloat + (minutes === "30" ? 0.5 : 0);

        return timeFloat;
      };

      // ... and from a float.
      const convertFloatto12 = (timeFloat: number) => {
        let timeString: string = "";

        timeString = (((timeFloat + 11) % 12) + 1).toString().replace(".5", ":30");

        // add 0's if it was not at the half mark
        if (timeString.indexOf(":") === -1) {
          timeString += ":00";
        }

        timeString = timeString + (timeFloat >= 12 ? "PM" : "AM");

        return timeString;
      };

      // iterate through all possible times from start to end in half hour increments
      for (
        let f = convertTime12toFloat(officeDate.openTime);
        f < convertTime12toFloat(officeDate.closeTime);
        f += 0.5
      ) {
        timeOptions.push(convertFloatto12(f));
      }
    }

    this.setState({
      requestedDate: date,
      includedTimes: timeOptions,
      requestedTime: undefined
    });
  };

  handleTimeChange = (time: any) => {
    this.setState({
      canSubmit: true,
      requestedTime: time
    });
  };

  render() {
    return (
      <React.Fragment>
        <Button variant="contained" disabled={this.props.disabled} color="primary" onClick={this.handleOpenModal}>
          Request
        </Button>
        <Dialog open={this.state.confirmationShown} onClose={this.handleCloseModal} fullWidth>
          <DialogHeader title="Welcome Home Orientation" handleClose={this.handleCloseModal} />
          <hr />
          <DialogContent style={{ height: "15rem" }}>
            <DialogContentText style={{ marginBottom: "1rem" }}>
              Thank you! Our community will review your request and be in touch to finalize the date and time.
            </DialogContentText>
            <DialogActions style={{ alignItems: "center", justifyContent: "center", marginTop: "10vh" }}>
              <Button variant="contained" color="primary" size="large" onClick={this.handleCloseModal}>
                Close
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Dialog open={this.state.modalVisibility} onClose={this.handleCloseModal} fullWidth>
          <DialogHeader title="Welcome Home Orientation" handleClose={this.handleCloseModal} />
          <hr />
          <DialogContent style={{ height: "24rem" }}>
            <Formik
              initialValues={{
                requestedDate: "",
                requestedTime: ""
              }}
              onSubmit={values => {
                this.handleSubmit(values);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <DialogContentText style={{ marginBottom: "1rem" }}>
                    Your Welcome Home Orientation can be scheduled on your lease start date or at anytime after. Once
                    you request your orientation time, our community team will be in touch to confirm the date and time.
                  </DialogContentText>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Click to select a date"
                        selected={moment(this.state.requestedDate).toDate()}
                        minDate={new Date(this.props.leaseDate)}
                        onChange={this.handleDateChange}
                        name="reservationdate"
                        autoComplete="off"
                        includeDates={this.state.includedDates}
                        className={this.props.classes.datepicker}
                      />
                      <EventIcon style={{ position: "relative", top: "10px", color: "rgba(0, 0, 0, 0.42)" }} />
                      <FormikInputError>
                        {errors.requestedDate && touched.requestedDate ? <div>{errors.requestedDate}</div> : null}
                      </FormikInputError>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Dropdown
                        options={this.state.includedTimes}
                        onChange={option => this.handleTimeChange(option.value)}
                        value={this.state.requestedTime}
                        placeholder="Select Start Time"
                        className={this.props.classes.dropdown}
                        controlClassName={this.props.classes.dropdowncomponent}
                        placeholderClassName={this.props.classes.dropdownplaceholder}
                        menuClassName={this.props.classes.dropdownmenu}
                      />
                      <ScheduleIcon style={{ color: "rgba(0, 0, 0, 0.42)" }} />

                      <FormikInputError>
                        {errors.requestedTime && touched.requestedTime ? <div>{errors.requestedTime}</div> : null}
                      </FormikInputError>
                    </Grid>
                  </Grid>

                  <DialogActions style={{ marginTop: "10vh", alignItems: "center", justifyContent: "center" }}>
                    <Button
                      disabled={!this.state.canSubmit}
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount)
});

export default connect(mapStateToProps)(withStyles(styles)(WelcomHomeOrientation));
