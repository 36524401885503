import * as React from "react";

import {
  Button,
  DialogActions,
  // InputAdornment,
  // Tab,
  // Tabs,
  TextField,
  Typography,
  DialogContent
} from "@material-ui/core";
import { FormikProps } from "formik";

import { InputFormState } from "./GroupsRequestNew";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import styles from "./styles";

interface MyProps {}

type AllProps = MyProps & WithStyles<typeof styles> & FormikProps<InputFormState>;

const GroupsRequestNewForm = (props: AllProps) => {
  const {
    values: { name, description },
    errors,
    touched,
    handleSubmit,
    handleChange,
    // setFieldValue,
    isValid,
    setFieldTouched,
    classes
  } = props;

  const change = (field: any, e: any) => {
    e.persist();
    handleChange(e);
    // tslint:disable-next-line:no-console
    setFieldTouched(field, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Typography>Requests will be reviewed within 1 business day</Typography>
        <TextField
          id="name"
          name="name"
          label="Group Name*"
          fullWidth
          value={name}
          helperText={touched.name ? errors.name : ""}
          error={touched.name && Boolean(errors.name)}
          onChange={change.bind(null, "name")}
          className={classes.textField}
        />

        <TextField
          id="description"
          name="description"
          label="Describe Your Group*"
          multiline
          fullWidth
          value={description}
          helperText={touched.description ? errors.description : ""}
          error={touched.description && Boolean(errors.description)}
          onChange={change.bind(null, "description")}
          className={classes.textField}
        />
      </DialogContent>

      <DialogActions>
        <Button type="submit" color="primary" variant="contained" disabled={!isValid}>
          Request Group
        </Button>
      </DialogActions>
    </form>
  );
};

export default withStyles(styles)(GroupsRequestNewForm);
