import {
  Avatar,
  CardHeader,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import ArrowIcon from "@material-ui/icons/ArrowRight";
import MoreIcon from "@material-ui/icons/MoreVert";
import classnames from "classnames";
import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Dispatch } from "redux";

import { PropertyData } from "../../../features/userInfo/models";
import { RootState } from "../../../store/root-reducer";
import { GenerateCloudinaryThumbnailUrl } from "../../../utils/cloudinaryImageFormatter";
import { getActiveUserInfo } from "../../selectAccount/selector";
import SharedImagesConstants from "../../shared/constants/sharedImages";
import { UserInfoItem } from "../../userInfo/models";
import { blockUserRequest, hidePostRequest } from "../actions";
import { GROUPS_BASE_URL } from "../constants";
import { BlockUserDto, DashboardFeedItem, UserInfo } from "../models";
import EditPost from "./EditPost";
import ReportAbuseMenuItem from "./ReportAbuseMenuItem";
import styles from "./styles";

interface Props extends WithStyles<typeof styles> {
  item: DashboardFeedItem;
  showExpander: boolean;
  expandClickHandler?: any;
  propertyData?: PropertyData;
}
interface PropsFromState {
  user?: UserInfoItem;
  userInfo: UserInfo;
}
interface PropsFromDispatch {
  blockUserRequest: typeof blockUserRequest;
  hidePostRequest: typeof hidePostRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

interface State {
  expanded: boolean;
  anchorEl: any;
}

class FeedItemHeader extends React.Component<AllProps, State> {
  state: Readonly<State> = { expanded: false, anchorEl: null };

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleBlockUser = () => {
    const payload: BlockUserDto = {
      blockerNameId: this.props.user!.nameId,
      blockedUserId: this.props.item.author.id,
      selectedGroupId: this.props.item.groupId
    };
    this.props.blockUserRequest(payload);
    this.handleClose();
  };
  handleHidePost = () => {
    this.props.hidePostRequest({
      postId: this.props.item.id,
      authorNameId: this.props.user!.nameId,
      groupId: this.props.item.groupId
    });
    this.handleClose();
  };

  render() {
    const { showExpander, item, classes, user } = this.props;
    const { expanded, anchorEl } = this.state;
    const avatar = this.props.item.author.avatar ? (
      this.props.item.author.avatar.includes("https") ? (
        <Avatar src={this.props.item.author.avatar} />
      ) : (
        <Avatar src={GenerateCloudinaryThumbnailUrl(this.props.item.author.avatar)} />
      )
    ) : this.props.item.itemType === "MarketingItem" ? (
      <Avatar src={SharedImagesConstants.EQRAVATAR} />
    ) : (
      <Avatar src={SharedImagesConstants.EMPTYAVATAR_FILLED} />
    );
    const expandedContent = showExpander ? (
      <div>
        <IconButton
          className={classnames(classes.expand, {
            [classes.expandOpen]: expanded
          })}
          onClick={this.handleClick}
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          aria-label="Show more"
        >
          <MoreIcon />
        </IconButton>
        <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          {user && item.author.id !== user.socialUserId && !item.author.isEmployee && (
            <MenuItem onClick={this.handleBlockUser}>Block {item.author.name}</MenuItem>
          )}
          {user && item.author.id === user.socialUserId && <MenuItem onClick={this.handleHidePost}>Hide Post</MenuItem>}
          {user && item.author.id === user.socialUserId && (
            <EditPost item={this.props.item} closeMenu={this.handleClose} />
          )}
          <ReportAbuseMenuItem
            reportText="Report Abuse"
            descriptionText="Please describe this post to help us understand what is going on."
            submitButtonText="Send"
            reportAbusePostId={item.id}
            closeMenu={this.handleClose}
          />
        </Menu>
      </div>
    ) : null;
    const groupUrl = item.groupId ? GROUPS_BASE_URL + item.groupId : "#";
    const title =
      item.groupId && item.groupName ? (
        <Grid container direction="row" alignContent="flex-start" alignItems="center" spacing={0}>
          <Grid item>
            <Typography className={classes.poster}>{item.author.name}</Typography>
          </Grid>
          <Grid item style={{ height: "24px" }}>
            <ArrowIcon className={classes.arrowIcon} style={{ marginTop: "0" }} />
          </Grid>
          <Grid item>
            <NavLink className={[classes.poster, classes.groupNavLink].join(" ")} to={groupUrl}>
              {item.groupName}
            </NavLink>
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>
          {item.itemType === "MarketingItem" && (
            <Typography className={classes.poster} style={{ display: "inline-block" }}>
              Equity Residential
            </Typography>
          )}
          {item.itemType !== "MarketingItem" && (
            <Typography className={classes.poster} style={{ display: "inline-block" }}>
              {item.author.name}
            </Typography>
          )}
          {user && item.itemType === "NewsItem" && (
            <Typography style={{ display: "inline-block", fontSize: ".875rem", fontStyle: "italic" }}>
              &nbsp;({user.propertyInfo.propertyName})
            </Typography>
          )}
        </React.Fragment>
      );
    const date = (
      <Moment format="MM/DD/YYYY" className={classes.postDateTime}>
        {item.datePosted.toString()}
      </Moment>
    );
    return <CardHeader avatar={avatar} action={expandedContent} title={title} subheader={date} />;
  }
}

const mapStateToProps = ({ selectAccount, social }: RootState) => ({
  user: getActiveUserInfo(selectAccount),
  userInfo: social.userInfo,
  socialInfo: social.data
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  blockUserRequest: (payload: BlockUserDto) => dispatch(blockUserRequest(payload)),
  hidePostRequest: (payload: { postId: number; authorNameId: string; groupId?: number }) =>
    dispatch(hidePostRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FeedItemHeader));
