import React, { Component } from "react";
import { Field } from "formik";
import { Typography, InputLabel } from "@material-ui/core";
import FormikTextField from "../../shared/components/FormikTextField";

interface Props {
  label: string;
  value?: string;
  id: string;
  editing: boolean;
  maxLength?: string;
}

class LabelledEditField extends Component<Props> {
  render() {
    if (this.props.editing === true) {
      return (
        <div style={{ marginBottom: "1rem" }}>
          <Field
            name={this.props.id}
            label={this.props.label}
            inputProps={{ maxLength: this.props.maxLength }}
            component={FormikTextField}
            style={{ width: "100%" }}
          />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <div style={{ marginBottom: "1rem" }}>
            <InputLabel>{this.props.label}</InputLabel>
            <Typography>{this.props.value}</Typography>
            <hr />
          </div>
        </React.Fragment>
      );
    }
  }
}

export default LabelledEditField;
