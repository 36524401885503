// https://github.com/resir014/react-redux-typescript-example/blob/master/src/main.tsx
import "./App.css";

import {
  AppBar,
  CssBaseline,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Toolbar,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { createBrowserHistory } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import configureStore from "./configureStore";
import LeftNavigation from "./features/shared/components/LeftNavigation";
import RightRail from "./features/shared/components/RightRail";
import UserInfoHeader from "./features/shared/components/UserInfoHeader";
import { getActiveUserInfo } from "./features/selectAccount/selector";
import Footer from "./components/footer";
import RoutesComponent from "./routes";
import { RootState } from "./store/root-reducer";
import { UserInfoItem } from "./features/userInfo/models";
import HomeHeaderTiles from "./features/shared/components/HomeHeaderTiles";

import styles from "./styles";
import ReactGA from "react-ga4";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href")!;
const history = createBrowserHistory({ basename: baseUrl });
const initialState = (window as any).initialReduxState as RootState;
export const store = configureStore(history, initialState);

// Initialize GA4
// https://medium.com/@vergil333/google-analytics-4-in-react-a250005c2455
let ga_ID = process.env.GAFOUR_TRACKING_ID;
console.log(ga_ID);
ReactGA.initialize("G-4DSGV3TCC0"); //Production
//ReactGA.initialize("G-S5HJWCR69C"); //DEV & QA

const routes = <Route path="*" render={(props) => <RoutesComponent {...props} />} />;

interface MyProps extends WithStyles<typeof styles> {}

type AllProps = PropsFromState & MyProps;

export interface State {
  mobileOpen: boolean;
  user?: UserInfoItem;
}

interface PropsFromState {
  user: UserInfoItem;
}

class App extends React.Component<AllProps, State> {
  state = {
    mobileOpen: false,
  };

  public handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  public render() {
    const { user, classes } = this.props;

    // Initialize GA4
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: window.location.href,
    });

    return (
      <div className={classes.root}>
        <CssBaseline />
        <div className={classes.appFrame}>
          <AppBar className={classes.appBar} id="appBar">
            <Toolbar className={classes.toolBar}>
              <Grid container justify="center" style={{ display: "flex", width: "100%" }}>
                <Grid item className={classes.headerGridItem}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={3} md={2}>
                      <Grid container alignItems="center" className={classes.logoContainer}>
                        <Grid item>
                          <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerToggle}
                            className={classes.navIconHide}
                          >
                            <MenuIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <img
                            alt="Equity Apartments Logo"
                            className={classes.logo}
                            // src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto/v1544797811/Content/portal/uielements/myequity-horizontal.svg"
                            src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto/v1562089792/Content/portal/uielements/myequity-horizontal.svg"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm>
                      <UserInfoHeader />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Grid
            container
            direction="row"
            justify="center"
            style={{ display: "flex", width: "100%", paddingTop: "120px" }}
          >
            <Grid item style={{ width: "100%" }}>
              <HomeHeaderTiles user={user!} />
            </Grid>
            <Grid
              item
              className={classes.headerBackground}
              style={{ display: "flex", width: "100%", paddingTop: "20px" }}
            >
              <div style={{ display: "flex", maxWidth: "1320px", width: "100%", margin: "0 auto" }}>
                <Hidden mdUp>
                  <Drawer
                    variant="temporary"
                    anchor={"left"}
                    open={this.state.mobileOpen}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    onClose={this.handleDrawerToggle}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                  >
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={this.handleDrawerToggle}
                      className={classes.navIconHide}
                      style={{ position: "absolute", right: "5px", fontSize: "18px", zIndex: 3 }}
                    >
                      X
                    </IconButton>
                    <LeftNavigation classes={classes} toggleDrawerCallback={this.handleDrawerToggle} />
                  </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                  <Drawer
                    variant="permanent"
                    open
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                  >
                    <Paper elevation={5}>
                      <LeftNavigation classes={classes} toggleDrawerCallback={this.handleDrawerToggle} />
                    </Paper>
                  </Drawer>
                </Hidden>

                {routes}
                <RightRail />
              </div>
            </Grid>
            <Grid item>
              <Footer />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

//export default withStyles(styles)(App);

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
});

export default connect(mapStateToProps)(withStyles(styles)(App));
