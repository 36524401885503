import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { downloadLeaseDocument, loadLeaseDetails } from "../../utils/api";
import * as utils from "../shared/utils/utils";
import {
  downloadLeaseDocumentError,
  downloadLeaseDocumentSuccess,
  leaseFetchError,
  leaseFetchSuccess
} from "./actions";
import * as actions from "./actions";

import { showModalAction } from "../errorhandling/actions";

function* handleFetchLeaseDetails(action: ReturnType<typeof actions.leaseFetchRequest>) {
  try {
    const nameId = action.payload.nameId;

    const response = yield call(loadLeaseDetails, nameId);

    if (response.error) {
      yield put(leaseFetchError(response.error));
    } else {
      yield put(leaseFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(leaseFetchError(err.stack!));
    } else {
      yield put(leaseFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleLeaseDocumentDownload(action: ReturnType<typeof actions.downloadLeaseDocumentRequest>) {
  try {
    const payload = action.payload;
    const response = yield call(downloadLeaseDocument, payload);

    if (response.error) {
      yield put(downloadLeaseDocumentError(response.error));
    } else {
      utils.openDownloadedDocument(response, payload.fileName, payload.newWindow);
      yield put(downloadLeaseDocumentSuccess());
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(downloadLeaseDocumentError(err.stack!));
    } else {
      yield put(downloadLeaseDocumentError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(getType(actions.leaseFetchRequest), handleFetchLeaseDetails);
  yield takeEvery(getType(actions.downloadLeaseDocumentRequest), handleLeaseDocumentDownload);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* leaseSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default leaseSaga;
