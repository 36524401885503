import { Card, CardContent, Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import { LocalShipping, ReportProblem, SettingsBackupRestore } from "@material-ui/icons";
import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import globalstyles from "../../../../global-styles";
import combinestyles from "../../../../utils/combinestyles";
import { thousands_separators } from "../../../shared/utils/utils";
import { NoticeToVacate } from "../../models";
import entvstyles from "../../styles";

const styles = combinestyles(entvstyles, globalstyles);

export interface SufficientTerminationProps extends WithStyles<typeof styles> {
  noticeInfo?: NoticeToVacate;
  onClick: any;
}

type State = {
  feeOption: number;
};

type AllProps = SufficientTerminationProps & RouteComponentProps;

class SufficientTermination extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    feeOption: 0
  };

  handleOptionChange = (changeEvent: any) => {
    if (changeEvent.target.value === "1") {
      this.setState({
        feeOption: 1
      });
    } else {
      this.setState({
        feeOption: 2
      });
    }
  };

  render() {
    const { classes } = this.props;
    const title1 = this.props.noticeInfo!.copyLanguage.find(e => {
      return e.languageId === "A4";
    });
    const title2 = this.props.noticeInfo!.copyLanguage.find(e => {
      return e.languageId === "A5";
    });
    const settlementAgreement = this.props.noticeInfo!.copyLanguage.find(e => {
      return e.languageId === "A6";
    });
    const rentUntil = this.props.noticeInfo!.copyLanguage.find(e => {
      return e.languageId === "A7";
    });
    return (
      <form>
        {this.props.noticeInfo!.settlementFeeDisplay.toString() === "Y" && (
          <Grid container className={this.props.classes.grouping}>
            <Grid xs={12} item>
              <Typography
                className={this.props.classes.tablepadding}
                style={{ paddingTop: "1rem", paddingBottom: "1.5rem" }}
              >
                {title1!.copyLanguage}
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Grid container className={this.props.classes.grouping}>
                <Grid xs={12} item>
                  {" "}
                  <LocalShipping color="disabled" style={{ verticalAlign: "middle" }} />
                  <Typography style={{ display: "inline-block", fontSize: ".875rem", paddingLeft: ".5rem" }}>
                    Vacate Date <Moment format="MM/DD/YY" date={this.props.noticeInfo!.vacateDate} />
                  </Typography>
                  <Grid xs={12} item style={{ paddingLeft: "2rem" }}>
                    <table>
                      <tr>
                        <td>
                          <ReportProblem color="secondary" style={{ fontSize: 15 }} />
                        </td>
                        <td>
                          <Typography
                            style={{ display: "inline-block", fontSize: ".6rem", color: "red", paddingBottom: ".5rem" }}
                          >
                            EARLY TERMINATION
                          </Typography>
                        </td>
                      </tr>
                    </table>
                  </Grid>
                </Grid>
                <Grid xs={12} item>
                  <SettingsBackupRestore color="disabled" style={{ verticalAlign: "middle" }} />
                  <Typography style={{ display: "inline-block", fontSize: ".875rem", paddingLeft: ".5rem" }}>
                    Lease End {this.props.noticeInfo!.leaseEnd}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={8} item>
              <Grid container className={this.props.classes.grouping}>
                <Grid item xs={12}>
                  <Card
                    className={classes.root}
                    style={{ marginBottom: "1rem", border: "1px solid #cccccc", boxShadow: "none" }}
                  >
                    <CardContent>
                      <Grid container className={this.props.classes.grouping}>
                        <Grid xs={7}>
                          <div
                            className="radio"
                            style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold" }}
                          >
                            <input
                              type="radio"
                              value="1"
                              checked={this.state.feeOption === 1}
                              onChange={e => {
                                this.handleOptionChange(e);
                              }}
                              onClick={this.props.onClick.bind(this, 1)}
                              //
                            />
                            <Typography
                              style={{
                                display: "inline-block",
                                fontSize: ".95rem",
                                fontWeight: "bold",
                                paddingLeft: ".5rem"
                              }}
                            >
                              Settlement Agreement
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={5}>
                          <Typography
                            style={{
                              display: "inline-block",
                              fontSize: ".875rem",
                              fontWeight: "bold",
                              color: "green",
                              float: "right"
                            }}
                          >
                            ${thousands_separators(this.props.noticeInfo!.settleOffer.toFixed(2))}
                          </Typography>
                        </Grid>
                        <Grid xs={12}>
                          <Typography style={{ display: "inline-block", fontSize: ".875rem", paddingLeft: "1.75rem" }}>
                            {settlementAgreement!.copyLanguage}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card
                    className={classes.root}
                    style={{ marginBottom: "1rem", border: "1px solid #cccccc", boxShadow: "none" }}
                  >
                    <CardContent>
                      <Grid container className={this.props.classes.grouping}>
                        <Grid xs={6}>
                          <div
                            className="radio"
                            style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold" }}
                          >
                            <input
                              type="radio"
                              value="2"
                              checked={this.state.feeOption === 2}
                              onChange={e => this.handleOptionChange(e)}
                              onClick={this.props.onClick.bind(this, 2)}
                            />
                            <Typography
                              style={{
                                display: "inline-block",
                                fontSize: ".95rem",
                                fontWeight: "bold",
                                paddingLeft: ".5rem"
                              }}
                            >
                              Rent Until Re-Rented
                            </Typography>
                          </div>
                        </Grid>
                        <Grid xs={6}>
                          <Typography
                            style={{
                              display: "inline-block",
                              fontSize: ".875rem",
                              fontWeight: "bold",
                              color: "green",
                              float: "right"
                            }}
                          >
                            ${thousands_separators(this.props.noticeInfo!.rentEarlyTerm.toFixed(2))}
                          </Typography>
                        </Grid>
                        <Grid xs={12}>
                          <Typography style={{ display: "inline-block", fontSize: ".875rem", paddingLeft: "1.75rem" }}>
                            {rentUntil!.copyLanguage}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {this.props.noticeInfo!.settlementFeeDisplay.toString() === "N" && (
          <Grid container className={this.props.classes.grouping}>
            <Grid xs={12} item>
              <Typography className={this.props.classes.tablepadding} gutterBottom>
                {title2!.copyLanguage}
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <LocalShipping color="disabled" style={{ verticalAlign: "middle" }} />
              <Typography style={{ display: "inline-block", fontSize: ".875rem", paddingLeft: ".5rem" }}>
                Vacate Date <Moment format="MM/DD/YY" date={this.props.noticeInfo!.vacateDate} />
              </Typography>
              <Grid xs={12} item style={{ paddingLeft: "2rem" }}>
                <ReportProblem color="secondary" style={{ fontSize: 15 }} />
                <Typography style={{ display: "inline-block", fontSize: ".6rem", color: "red", paddingBottom: "1rem" }}>
                  EARLY TERMINATION
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={4} item>
              <Typography style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold" }}>
                Charges
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold", float: "right" }}>
                Amount
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <SettingsBackupRestore color="disabled" style={{ verticalAlign: "middle" }} />
              <Typography style={{ display: "inline-block", fontSize: ".875rem", paddingLeft: ".5rem" }}>
                Lease End {this.props.noticeInfo!.leaseEnd}
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "normal" }}>
                Rent Until Re-Rented
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography
                style={{
                  display: "inline-block",
                  fontSize: ".875rem",
                  fontWeight: "bold",
                  color: "green",
                  float: "right"
                }}
              >
                ${thousands_separators(this.props.noticeInfo!.rentEarlyTerm.toFixed(2))}
              </Typography>
            </Grid>
            <Grid xs={4} item />
            <Grid xs={6}>
              <Typography gutterBottom style={{ display: "inline-block", fontSize: ".875rem" }}>
                {rentUntil!.copyLanguage}
              </Typography>
            </Grid>
            <Grid xs={2} item />
          </Grid>
        )}
      </form>
    );
  }
}

export default withRouter(connect()(withStyles(styles)(SufficientTermination)));
