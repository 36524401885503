import * as React from "react";
import { Dialog, DialogContent, DialogActions, Button, Grid, Typography, NativeSelect, Input } from "@material-ui/core";
import { currencyFormatter } from "../../shared/utils/utils";
import { Formik, Field, Form} from "formik";
import FormikTextField from "../../shared/components/FormikTextField";
import { RequestPaymentPlan } from "../models";
import DialogHeader from "../../shared/components/DialogHeader";

interface Props {
  balance: number;
  userNameId: string;
  paymentPlanRequest: any;
  open: boolean;
  handleClose: any;
}

type AllProps = Props;

class PaymentPlanDialog extends React.Component<AllProps> {
  state = {
    selectedPaymentPlan: "0"
  };
  handlepaymentSelection = (event: any) => {
    this.setState({ selectedPaymentPlan: event.target.value });
    return;
  };

  handleClose = () => {
    this.setState({ selectedPaymentPlan: "0" });
    this.props.handleClose();
  };

  handleSubmit = (values: any) => {
    let payload: RequestPaymentPlan = {
      nameId: this.props.userNameId,
      paymentPlan: this.state.selectedPaymentPlan,
      reasoning: values.reasoning
    };

    this.props.paymentPlanRequest(payload);
    this.props.handleClose();
  };
  render() {
    return (
      <Dialog open={this.props.open} onClose={this.handleClose} scroll="paper" aria-labelledby="scroll-dialog-title">
        <DialogHeader title="Payment Plan Request" handleClose={this.handleClose} />
        <DialogContent>
          <Formik initialValues={{ reasoning: "" }} onSubmit={(values: any) => this.handleSubmit(values)}>
            <Form>
              <Typography>
                In special circumstances, we may accept payment plans on balances due. Please let us know what you are
                able pay today and the number payments and time frame in which you can pay the remaining balance due on
                your account. Generally, we limit payment plans to no more than three payments over a period of 3
                months, but we occasionally make exceptions if you have a high balance due but are able to make regular
                payments.
              </Typography>
              <br />
              <Typography>
                For example, if your balance were $1,500, you may elect to pay $750 today, $375 two weeks from today,
                and the remaining $375 four weeks from today.
              </Typography>
              <br />
              <Typography>
                Please note that your outstanding balance will accrue interest at the rate of 1.5% per month once a
                payment plan is approved.
              </Typography>
              <br />
              <Grid container>
                <Grid item xs={4}>
                  <Typography>Remaining Balance</Typography>
                  <strong>{currencyFormatter(this.props.balance)}</strong>
                </Grid>
                <Grid item xs={8}>
                  <Typography>Number of Months</Typography>
                  <NativeSelect
                    name="paymentPlanSelect"
                    id="paymentPlanSelect"
                    defaultValue={0}
                    input={<Input name="name" id="uncontrolled-native" />}
                    onChange={this.handlepaymentSelection}
                  >
                    <option value={0} disabled>
                      Select up to 3 Months
                    </option>
                    <option value={1}>1 Month (1 payment of {currencyFormatter(this.props.balance)})</option>
                    <option value={2}>2 Months (2 payments of {currencyFormatter(this.props.balance / 2)})</option>
                    <option value={3}>3 Months (3 payments of {currencyFormatter(this.props.balance / 3)})</option>
                  </NativeSelect>
                </Grid>
              </Grid>
              <Field
                name="reasoning"
                label="Reasoning for Request"
                inputProps={{ maxLength: 100 }}
                component={FormikTextField}
                style={{ marginBottom: "1rem" }}
                fullWidth
              />
              <DialogActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={this.state.selectedPaymentPlan === "0"}
                >
                  Submit
                </Button>
              </DialogActions>
            </Form>
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

export default PaymentPlanDialog;
