import { Button, Dialog, DialogActions, DialogContent, WithStyles, withStyles } from "@material-ui/core";
import { Field, Form,  Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as Yup from "yup";

import { RootState } from "../../../store/root-reducer";
import { getActiveUserInfo } from "../../selectAccount/selector";
import AlertBlock from "../../shared/components/AlertBlock";
import FormikTextField from "../../shared/components/FormikTextField";
import RegexConstants from "../../shared/constants/regex";
import { UserInfoItem } from "../../userInfo/models";
import { passwordUpdateRequest } from "../actions";
import styles from "./styles";
import DialogHeader from "../../shared/components/DialogHeader";

const ValidationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Current Password is required"),
  newPassword: Yup.string()
    .required("New Password is required")
    .matches(
      RegexConstants.PASSWORD,
      "Please make sure your password is at least 8 characters containing at least 1 number, 1 lower case letter and 1 capital letter."
    ),
  verifyPassword: Yup.string()
    .required("Verify Password is required")
    .oneOf([Yup.ref("newPassword"), null], "The new and verify passwords  must match") 
});

interface Props extends WithStyles<typeof styles> {}
interface PropsFromState {
  user?: UserInfoItem;
  passwordError?: string;
}
interface PropsFromDispatch {
  passwordUpdateRequest: typeof passwordUpdateRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

class UpdatePasswordModal extends Component<AllProps> {
  state = {
    modalVisibility: false
  };
  handleOpenModal = () => {
    this.setState({ modalVisibility: true });
  };
  handleCloseModal = () => {
    this.setState({ modalVisibility: false });
  };
  handleSubmit = (values: any) => {
    this.props.passwordUpdateRequest({
      nameId: this.props.user!.nameId,
      oldPassword: values.oldPassword,
      newPassword: values.newPassword
    });
    this.handleCloseModal();
  };

  render() {
    // TODO: Change To A Global Error Element
    const passwordError = this.props.passwordError ? (
      <AlertBlock message="Unable to change password. Current password is invalid." />
    ) : null;
    return (
      <React.Fragment>
        {passwordError}
        <Button onClick={this.handleOpenModal} color="primary">
          Update Password
        </Button>
        <Dialog open={this.state.modalVisibility} onClose={this.handleCloseModal}>
          <DialogHeader title="Change Password" handleClose={this.handleCloseModal} />
          <hr />
          <DialogContent>
            <Formik
              initialValues={{ oldPassword: "", newPassword: "", verifyPassword: "" }}
              onSubmit={(values: any) => this.handleSubmit(values)}
              validationSchema={ValidationSchema}
            >
              <Form>
                <Field
                  name="oldPassword"
                  label="Current Password"
                  inputProps={{ type: "password" }}
                  component={FormikTextField}
                  style={{ width: "100%", marginBottom: "1rem" }}
                />
                <Field
                  name="newPassword"
                  label="New Password"
                  inputProps={{ type: "password" }}
                  component={FormikTextField}
                  style={{ width: "100%", marginBottom: "1rem" }}
                />
                <Field
                  name="verifyPassword"
                  label="Verify Password"
                  inputProps={{ type: "password" }}
                  component={FormikTextField}
                  style={{ width: "100%", marginBottom: "1rem" }}
                />
                <DialogActions>
                  <Button type="submit">Submit</Button>
                </DialogActions>
              </Form>
            </Formik>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ selectAccount, profile }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  passwordError: profile.passwordError
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  passwordUpdateRequest: (payload: { nameId: string; oldPassword: string; newPassword: string }) => dispatch(passwordUpdateRequest(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UpdatePasswordModal));
