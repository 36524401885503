import * as React from "react";
import { WithStyles, withStyles, Grid, Typography } from "@material-ui/core";
import { resetDownloadError } from "../features/formerresident/actions";
import { RootState } from "../store/root-reducer";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { UserInfo } from "../features/myprofile/models";
import statementstyles from "../features/applicant/components/styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import { UserInfoItem } from "../features/userInfo/models";
import ApplicantChecklist from "../features/applicant/components/ApplicantChecklist";
import NotificationsContainer from "../features/notifications/components/NotificationsContainer";

const styles = combinestyles(statementstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {}

interface PropsFromDispatch {
  resetDownloadError: typeof resetDownloadError;
}

interface PropsFromState {
  user: UserInfoItem;
  userInfo?: UserInfo;
  downloadError: boolean;
}

type AllProps = Props & PropsFromDispatch & PropsFromState & RouteComponentProps;

class ApplicantDashboard extends React.Component<AllProps> {
  componentDidMount() {
    this.props.resetDownloadError();
    window.scrollTo(0, 0);
  }

  render() {
    let propName = this.props.user.propertyInfo.propertyName;
    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Grid container alignItems="center" style={{ width: "100%" }}>
            <Grid item xs>
              <Typography variant="h1" className={this.props.classes.header}>
                Dashboard
              </Typography>
            </Grid>
          </Grid>
          <NotificationsContainer fromDashboard={false} />
          <ApplicantChecklist propertyName={propName} />
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ selectAccount, profile, formerresident }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  userInfo: profile.userInfo,
  downloadError: formerresident.downloadError
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetDownloadError: () => resetDownloadError()
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ApplicantDashboard))
);
