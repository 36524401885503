import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { loadRenewalByNameId, postRenewalCreate } from "../../utils/api";
import { showModalAction } from "../errorhandling/actions";
import * as actions from "./actions";

function* handleFetchRenewalByResident(action: ReturnType<typeof actions.renewalFetchRequest>): any {
  try {
    const nameid = action.payload;

    const response = yield call(loadRenewalByNameId, nameid);

    if (response.error) {
      yield put(actions.renewalFetchError(response.error));
    } else {
      yield put(actions.renewalFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.renewalFetchError(err.stack!));
    } else {
      yield put(actions.renewalFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleInsertRenewalAccept(action: ReturnType<typeof actions.insertRenewalRequest>): any {
  try {
    const payload = action.payload;
    const response = yield call(postRenewalCreate, payload);

    if (response.error) {
      yield put(actions.insertRenewalError(response.error));
    } else {
      yield put(actions.insertRenewalSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.insertRenewalError(err.stack!));
    } else {
      yield put(actions.insertRenewalError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* watchFetchRequest() {
  yield takeEvery(getType(actions.renewalFetchRequest), handleFetchRenewalByResident);
  yield takeEvery(getType(actions.insertRenewalRequest), handleInsertRenewalAccept);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* renewalSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default renewalSaga;
