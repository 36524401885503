import * as React from "react";
import { ServiceRequestTopLevelCategory } from "../models";
import { Paper, Grid, Divider, Typography, WithStyles, withStyles } from "@material-ui/core";

import servicestyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(servicestyles, globalstyles);
interface Props extends WithStyles<typeof styles> {
  selectCategory: (topLevelCategory: ServiceRequestTopLevelCategory) => void;
  topLevelCategories: ServiceRequestTopLevelCategory[];
}

class ServiceReqFormSelectCategory extends React.Component<Props> {
  render() {
    const { topLevelCategories, selectCategory } = this.props;
    const iconUrlBase = "https://media.equityapartments.com/image/upload/f_auto,q_auto/Content/portal/servicerequest/";
    return (
      <Paper className={this.props.classes.paper} elevation={1}>
        <Grid container alignItems="center" spacing={24} className={this.props.classes.listHeader}>
          <Grid item>
            <img
              alt="Step 1"
              className="step-icon"
              src="https://media.equityapartments.com/image/upload/co_rgb:394b76,e_colorize:100,f_auto/v1544721640/Content/portal/uielements/step1.svg"
            />
          </Grid>
          <Grid item>
            <Typography variant="h2">What can we help you with?</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          {topLevelCategories.map((topLevelCategory) => (
            <Grid
              item
              xs={12}
              sm={6}
              key={topLevelCategory.categoryName}
              onClick={selectCategory.bind(this, topLevelCategory)}
            >
              <Grid container alignItems="center" spacing={24} style={{ marginBottom: "10px" }}>
                <Grid item>
                  <img
                    className="list-icon"
                    src={`${iconUrlBase}${topLevelCategory.iconImage}.png`}
                    alt={topLevelCategory.iconImage}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h4" style={{ color: "#3d90d4", cursor: "pointer" }}>
                    {topLevelCategory.categoryName}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(ServiceReqFormSelectCategory);
