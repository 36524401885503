import * as React from "react";
import { ContactItem } from "../models";
import ContactLeasingTeam from "./ContactLeasingTeam";
import ContactServiceTeam from "./ContactServiceTeam";
import { Typography, Paper, Grid, withStyles, WithStyles } from "@material-ui/core";

import contactstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(contactstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  leasingTeam: ContactItem[];
  serviceTeam: ContactItem[];
}

const ContactAllteamMembers: React.SFC<Props> = props => {
  return (
    <Paper className={props.classes.paper}>
      <Typography variant="h2" style={{ marginBottom: "1.5rem" }}>
        All Team Members
      </Typography>
      <Grid container spacing={24}>
        <Grid item sm={6} hidden={!(props.leasingTeam.length > 0)}>
          <ContactLeasingTeam leasingTeam={props.leasingTeam} />
        </Grid>
        <Grid item sm={6} hidden={!(props.serviceTeam.length > 0)}>
          <ContactServiceTeam serviceTeam={props.serviceTeam} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(ContactAllteamMembers);
