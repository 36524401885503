import { Dialog, DialogContent, DialogTitle, MenuItem, Typography, WithStyles, withStyles } from "@material-ui/core";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { Formik } from "formik";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as Yup from "yup";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import DialogHeader from "../../shared/components/DialogHeader";
import { UserInfoItem } from "../../userInfo/models";
import { closeEditModal, openEditModal, postUpdateRequest } from "../actions";
import { EDIT_DISCUSSION_ITEM, EDIT_EVENT_ITEM, EDIT_FOR_SALE_ITEM } from "../constants";
import { DashboardFeedItem, EventItem, ForSaleItem, Group, Image, RpActivity, UpdatePostDto } from "../models";
import EditPostForm from "./EditPostForm";
import socialstyles from "./styles";

const styles = combinestyles(socialstyles, globalstyles);

const validationSchema = Yup.object({
  // All Forms
  itemType: Yup.string(),
  activeTab: Yup.number(),

  title: Yup.string().required("Title is required").max(100, "Character limit 100"),
  content: Yup.string().required("Content is required"),

  // // For Sale Post Only
  // price: Yup.string()
  // .test("required-If", "Price is required", function() {
  //   const check = checkPrice(this.parent, "price", "activeTab");
  //   return check;
  // }),

  // Event Post Only
  location: Yup.string().test("required-If", "Location is required", function () {
    const check = checkLocation(this.parent, "location", "activeTab");
    return check;
  }),

  rsvp: Yup.string().test("required-If", "RSVP is required", function () {
    const check = checkRsvp(this.parent, "rsvp", "activeTab");
    return check;
  }),

  date: Yup.date()
    .nullable()
    .when("itemType", {
      is: (val: any) => val === EDIT_EVENT_ITEM,
      then: Yup.date()
        .required("Please enter a valid date")
        .min(moment(new Date()).subtract(1, "days").toDate(), "Date must be in the future"),
    }),

  edate: Yup.date()
    .nullable()
    .test("required-If", "End Date must be after Start Date", function () {
      const check = checkEndDate(this.parent, "date", "edate");
      return check;
      //})
    }),

  starttime: Yup.date()
    .nullable()
    .test("required-If", "Start Time must be in the future", function () {
      const check = checkStartTime(this.parent, "date", "starttime");
      return check;
    }),

  endtime: Yup.date()
    .nullable()
    .test("required-If", "End Time must be after the Start Time", function () {
      const check = checkEndTime(this.parent, {
        dependent1: "date",
        dependent2: "edate",
        dependent3: "starttime",
        dependent4: "endtime",
      });
      return check;
    }),
});

const checkEndDate = (parent: any, dependent1: any, dependent2: any) => {
  const startDay = parent && parent[dependent1];
  const endDay = parent && parent[dependent2];

  let vv = true;
  if (moment(startDay.getDate()).isAfter(moment(endDay.getDate()), "day")) {
    vv = false;
  }
  return vv;
};

const checkStartTime = (parent: any, dependent1: any, dependent2: any) => {
  const startDay = parent && parent[dependent1];
  const startTime = parent && parent[dependent2];
  const nowDay = new Date().setHours(0, 0, 0, 0);

  let vv = true;
  if (
    moment(startDay.setHours(0, 0, 0, 0)).isSame(moment(nowDay)) &&
    moment(startTime.getHours()).isBefore(moment(new Date().getHours()))
  ) {
    vv = false;
  }
  return vv;
};

const checkEndTime = (parent: any, { dependent1, dependent2, dependent3, dependent4 }: any) => {
  const startDay = parent && parent[dependent1];
  const endDay = parent && parent[dependent2];
  const startTime = parent && parent[dependent3];
  const endTime = parent && parent[dependent4];

  let vv = true;
  if (
    moment(startDay.getDate()).isSame(moment(endDay.getDate())) &&
    moment(startTime.getTime()).isAfter(moment(endTime.getTime())) &&
    moment(startTime.getTime()).diff(moment(endTime.getTime()))
  ) {
    vv = false;
  }

  return vv;
};

// const checkPrice = (parent: any, dependent1: any, dependent2: any) => {
//   const price = parent && parent[dependent1];
//   const itemType = parent && parent[dependent2];

//   let vv = true;
//   if (itemType === 1 && price === null) {
//     vv = false;
//   }
//   return vv;
// };

const checkLocation = (parent: any, dependent1: any, dependent2: any) => {
  const location = parent && parent[dependent1];
  const itemType = parent && parent[dependent2];

  let vv = true;
  if (itemType === 2 && location === null) {
    vv = false;
  }
  return vv;
};

const checkRsvp = (parent: any, dependent1: any, dependent2: any) => {
  const rsvp = parent && parent[dependent1];
  const itemType = parent && parent[dependent2];

  let vv = true;
  if (itemType === 2 && rsvp === null) {
    vv = false;
  }
  return vv;
};

interface Props extends WithWidth, WithStyles<typeof styles> {
  groupId?: number;
  buttonText?: string;
  item: DashboardFeedItem;
  closeMenu?: () => void;
}

interface PropsFromState {
  user?: UserInfoItem;
  groups: Group[];
  errors: string | undefined;
  modalOpen: boolean;
  posting: boolean;
}

interface PropsFromDispatch {
  logActivity: typeof logActivity;
  postUpdateRequest: typeof postUpdateRequest;
  openModal: typeof openEditModal;
  closeModal: typeof closeEditModal;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

type MyState = {
  open: boolean;
};

export type InputFormState = {
  activeTab: number;
  postType: string;
  title: string;
  content: string;
  photos: File[];
  photosToRemove: number[];
  existingPhotos: Image[];
  price: string;
  location: string;
  date: Date | null;
  edate: Date | null;
  rsvp: boolean | undefined;
  starttime: Date | null;
  endtime: Date | null;
  groupId: number | null;
};

type State = MyState & InputFormState;

class EditPost extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    open: false,
    activeTab: this.findActiveTab(),
    postType: this.props.item.itemType,
    title: this.props.item.title,
    content: this.props.item.message,
    photos: [],
    photosToRemove: [],
    existingPhotos: this.props.item.images,
    price: (this.props.item as ForSaleItem).price,
    location: (this.props.item as EventItem).location,
    date: new Date((this.props.item as EventItem).startDate),
    edate: new Date((this.props.item as EventItem).endDate),
    rsvp: (this.props.item as EventItem).rsvpEnabled,
    starttime: new Date((this.props.item as EventItem).startDate),
    endtime: new Date((this.props.item as EventItem).endDate),
    groupId: this.props.item.groupId,
  };

  findActiveTab() {
    switch (this.props.item.itemType) {
      case "DiscussionItem":
        return 0;

      case "ForSaleItem":
        return 1;

      case "EventItem":
        return 2;
    }
    return 0;
  }

  componentDidMount() {
    //const postId = this.props.item!.id;
  }

  attachDateToTime(date: any, time: any) {
    if (!date || !time) {
      return null;
    }
    let combinedDate = new Date(date);
    combinedDate.setUTCHours(time.getHours());
    combinedDate.setUTCMinutes(time.getMinutes());
    return combinedDate;
  }

  submitValues = ({ postType, title, content, price, location, date, edate, rsvp, starttime, endtime }: State) => {
    if (this.props.posting) return;
    const editPost: UpdatePostDto = {
      authorNameId: this.props.user!.nameId,
      title: title,
      itemType: postType,
      location: location,
      price: price,
      message: content,
      date: postType === EDIT_EVENT_ITEM && date ? date : undefined,
      eDate: postType === EDIT_EVENT_ITEM && edate ? edate : undefined,
      rsvpEnabled: rsvp ? rsvp : false,
      startDate: postType === EDIT_EVENT_ITEM ? this.attachDateToTime(date, starttime) : null,
      endDate: postType === EDIT_EVENT_ITEM ? this.attachDateToTime(edate, endtime) : null,
      photos: this.state.photos,
      photosToRemove: this.state.photosToRemove,
      groupId: this.state.groupId,
    };
    if (postType === EDIT_DISCUSSION_ITEM) {
      this.props.logActivity({
        actcode: "PI",
        note: "Resident edited a post",
      });
    } else if (postType === EDIT_FOR_SALE_ITEM) {
      this.props.logActivity({
        actcode: "PI",
        note: "Resident edited a post",
      });
    } else if (postType === EDIT_EVENT_ITEM) {
      this.props.logActivity({
        actcode: "PI",
        note: "Resident edited a post",
      });
    }
    if (this.props.item) {
      this.props.postUpdateRequest({
        postId: this.props.item.id,
        UpdatePostDto: editPost,
      });
    }
    this.setState({ photos: [] });
    this.handleClose();
    if (this.props.closeMenu !== undefined) {
      this.props.closeMenu();
    }
  };

  handleClickOpen = () => {
    this.props.openModal();
  };

  handleClose = () => {
    this.setState({ open: false, photos: [], groupId: null });
    this.props.closeModal();
  };

  handleFileUpload = (files: File[]) => {
    this.setState({ photos: files });
  };
  handleGroupSelection = (value: any) => {
    if (!value || value === "0") {
      this.setState({ groupId: null });
    } else {
      this.setState({ groupId: Number(value) });
    }
  };
  handleRemoveImage = (image: Image) => {
    const id = image.id;
    const newImageArray = this.state.existingPhotos.filter((v: Image) => v.id !== id);
    this.setState({ existingPhotos: newImageArray });
    const newImagesToRemoveArray = this.state.photosToRemove.concat(id);
    this.setState({ photosToRemove: newImagesToRemoveArray });
  };

  render() {
    const {
      postType,
      title,
      content,
      photos,
      photosToRemove,
      existingPhotos,
      price,
      location,
      activeTab,
      open,
      date,
      edate,
      rsvp,
      starttime,
      endtime,
      groupId,
    } = this.state;

    const initialValues = {
      postType: postType,
      open: open,
      activeTab: activeTab,
      title: title,
      content: content,
      price: price,
      location: location,
      date: date,
      edate: edate,
      rsvp: rsvp,
      starttime: starttime,
      endtime: endtime,
      photos: photos,
      photosToRemove: photosToRemove,
      existingPhotos: existingPhotos,
      groupId: groupId,
    };

    return (
      <React.Fragment>
        <MenuItem onClick={this.handleClickOpen}>Edit Post</MenuItem>
        <Dialog
          open={this.props.modalOpen}
          onClose={this.handleClose}
          fullScreen={this.props.width === "xs" ? true : false}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle style={{ padding: "0", overflow: "hidden" }}>
            <DialogHeader title="Edit Post" handleClose={this.handleClose} />
          </DialogTitle>
          <DialogContent style={{ padding: "0" }}>
            <Formik
              render={(props) => (
                <EditPostForm
                  groups={this.props.groups.sort((x, y) => {
                    const n1 = x.name.toUpperCase();
                    const n2 = y.name.toUpperCase();
                    if (n1 > n2) return 1;
                    if (n1 < n2) return -1;
                    return 0;
                  })}
                  selectedGroup={this.props.item.groupId}
                  {...props}
                  handleGroupSelection={this.handleGroupSelection}
                  handleFileUpload={this.handleFileUpload}
                  handleRemoveImage={this.handleRemoveImage}
                  existingPhotos={this.state.existingPhotos}
                />
              )}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={this.submitValues}
            />
            {this.props.errors && (
              <DialogContent style={{ padding: "0 20px 20px" }}>
                <Typography style={{ textAlign: "center", color: "red" }}>
                  There was an error connecting to our servers
                </Typography>
              </DialogContent>
            )}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ selectAccount, social, socialGroup }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  groups: socialGroup.groupUserList.dataUser,
  errors: social.errors,
  modalOpen: social.editingModalOpen,
  posting: social.posting,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  logActivity: (activity: RpActivity) => dispatch(logActivity(activity)),
  postUpdateRequest: (payload: { postId: number; UpdatePostDto: UpdatePostDto }) =>
    dispatch(postUpdateRequest(payload)),
  openModal: () => dispatch(openEditModal()),
  closeModal: () => dispatch(closeEditModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles)(EditPost)));
