import { Theme, createStyles } from "@material-ui/core";

const styles = ({ spacing, breakpoints, mixins, transitions }: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginBottom: 24,
      //paddingTop: spacing.unit * 7
    },
    content: {
      width: "100%",
      height: `calc(100% - ${spacing.unit * 8})`,
      marginTop: spacing.unit * 7,
      [breakpoints.up("sm")]: {
        height: "calc(100% - 120px)",
        marginTop: spacing.unit * 15,
      },
    },
    paper: {
      ...mixins.gutters(),
      marginLeft: spacing.unit * 1,
      marginRight: spacing.unit * 1,
      marginTop: spacing.unit * 2,
      [breakpoints.up("sm")]: {
        // marginLeft: spacing.unit * 3,
        // marginRight: spacing.unit * 3,
        // marginTop: spacing.unit * 5,
        paddingLeft: spacing.unit * 5,
        paddingRight: spacing.unit * 5,
        paddingTop: spacing.unit * 5,
        paddingBottom: spacing.unit * 5,
      },
    },
    header: {
      marginLeft: spacing.unit * 1,
      marginRight: spacing.unit * 1,
      [breakpoints.up("sm")]: {
        paddingLeft: spacing.unit * 4,
        paddingRight: spacing.unit * 4,
        marginBottom: spacing.unit * 3,
        paddingTop: spacing.unit * 5,
      },
    },
    headerlist: {
      [breakpoints.up("sm")]: {
        marginBottom: spacing.unit * 3,
      },
    },
    link: {
      color: "#3892d1",
      textDecoration: "none",
    },
    icon: {
      [breakpoints.up("sm")]: {
        marginRight: spacing.unit * 3.125,
      },
    },
    row: {
      [breakpoints.up("sm")]: {
        marginBottom: spacing.unit * 2.25,
      },
    },
    rowcontents: {
      [breakpoints.up("sm")]: {
        paddingBottom: spacing.unit * 2.25,
      },
    },
    residentRelationsTextArea: {
      width: "100%",
    },
  });
export default styles;
