import * as React from "react";
import { withStyles, WithStyles } from "@material-ui/core";
import { paymentFetchRequest } from "../features/payment/actions";

import PaymentDashboard from "../features/payment/components/PaymentDashboard";
import PaymentMake from "../features/payment/components/PaymentMake";
import PaymentAutoPay from "../features/payment/components/PaymentAutoPay";
import PaymentOnlineBill from "../features/payment/components/PaymentOnlineBill";
import PaymentResult from "../features/payment/components/PaymentResult";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps, Route, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import { RootState } from "../store/root-reducer";
import { PaymentData } from "../features/payment/models";
import styles from "../features/payment/styles";

interface IPaymentProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
}

interface IPageState {}

interface PropsFromState {
  user?: UserInfoItem;
  loading: boolean;
  paymentDataInfo: PaymentData;
  errors?: string;
}

interface PropsFromDispatch {
  paymentFetchRequest: typeof paymentFetchRequest;
}

type AllProps = IPaymentProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

class PaymentPage extends React.Component<AllProps, IPageState> {
  state: IPageState = {};

  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.paymentFetchRequest(nameId);
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Switch>
        <Route path="/payment/PaymentMake" component={PaymentMake} />
        <Route path="/payment/PaymentAutoPay" component={PaymentAutoPay} />
        <Route path="/payment/PaymentOnlineBill" component={PaymentOnlineBill} />
        <Route path="/payment/PaymentResult" component={PaymentResult} />
        <Route component={PaymentDashboard} />
      </Switch>
    );
  }
}

const mapStateToProps = ({ payment, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  paymentDataInfo: payment.paymentData.data!,
  loading: payment.paymentData.loading,
  errors: payment.paymentData.errors
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  paymentFetchRequest: (nameid: string) => dispatch(paymentFetchRequest(nameid))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentPage)));
