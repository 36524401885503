import { createAsyncAction } from "typesafe-actions";

import { DashboardNotification } from "./models";

const NOTIFICATIONS_FETCH_REQUEST = "notifications/FETCH_REQUEST";
const NOTIFICATIONS_FETCH_SUCCESS = "notifications/FETCH_SUCCESS";
const NOTIFICATIONS_FETCH_ERROR = "notifications/FETCH_ERROR";

// export const fetchRequest = createAction(NOTIFICATIONS_FETCH_REQUEST)<string>();
// export const fetchSuccess = createAction(NOTIFICATIONS_FETCH_SUCCESS)<DashboardNotification[]>();
// export const fetchError = createAction(NOTIFICATIONS_FETCH_ERROR)<string>();

export const fetchRequestAsync = createAsyncAction(
  NOTIFICATIONS_FETCH_REQUEST,
  NOTIFICATIONS_FETCH_SUCCESS,
  NOTIFICATIONS_FETCH_ERROR
)<string, DashboardNotification[], Error>();
