import { createAction } from "typesafe-actions";

import {
  AddCommentDto,
  CreateGroupDto,
  CreatePostDto,
  DashboardFeedItem,
  Group,
  JoinLeaveGroupDto,
  ReportAbuseRequest,
} from "./models";

const GROUP_FETCH_REQUEST = "group/FETCH_REQUEST";
const GROUP_FETCH_SUCCESS = "group/FETCH_SUCCESS";
const GROUP_FETCH_ERROR = "group/FETCH_ERROR";
export const groupFetchRequest = createAction(GROUP_FETCH_REQUEST)<string>();
export const groupFetchSuccess = createAction(GROUP_FETCH_SUCCESS)<Group[]>();
export const groupFetchError = createAction(GROUP_FETCH_ERROR)<string>();

const GROUPUSER_FETCH_REQUEST = "groupUser/FETCH_REQUEST";
const GROUPUSER_FETCH_SUCCESS = "groupUser/FETCH_SUCCESS";
const GROUPUSER_FETCH_ERROR = "groupUser/FETCH_ERROR";
export const groupUserFetchRequest = createAction(GROUPUSER_FETCH_REQUEST)<string>();
export const groupUserFetchSuccess = createAction(GROUPUSER_FETCH_SUCCESS)<Group[]>();
export const groupUserFetchError = createAction(GROUPUSER_FETCH_ERROR)<string>();

const JOIN_GROUP_REQUEST = "group/JOIN_GROUP_REQUEST";
const JOIN_GROUP_SUCCESS = "group/JOIN_GROUP_SUCCESS";
const JOIN_GROUP_ERROR = "group/JOIN_GROUP_ERROR";
export const joinGroupPostRequest = createAction(JOIN_GROUP_REQUEST)<{
  request: JoinLeaveGroupDto;
  group: Group;
}>();
export const joinGroupPostSuccess = createAction(JOIN_GROUP_SUCCESS)<Group>();
export const joinGroupPostError = createAction(JOIN_GROUP_ERROR)<string>();

const LEAVE_GROUP_REQUEST = "group/LEAVE_GROUP_REQUEST";
const LEAVE_GROUP_SUCCESS = "group/LEAVE_GROUP_SUCCESS";
const LEAVE_GROUP_ERROR = "group/LEAVE_GROUP_ERROR";
export const leaveGroupPostRequest = createAction(LEAVE_GROUP_REQUEST)<{
  request: JoinLeaveGroupDto;
  group: Group;
}>();
export const leaveGroupPostSuccess = createAction(LEAVE_GROUP_SUCCESS)<Group>();
export const leaveGroupPostError = createAction(LEAVE_GROUP_ERROR)<string>();

const CREATE_GROUP_REQUEST = "group/CREATE_GROUP_REQUEST";
const CREATE_GROUP_SUCCESS = "group/CREATE_GROUP_SUCCESS";
const CREATE_GROUP_ERROR = "group/CREATE_GROUP_ERROR";
export const createGroupRequest = createAction(CREATE_GROUP_REQUEST)<CreateGroupDto>();
export const createGroupSuccess = createAction(CREATE_GROUP_SUCCESS)<CreateGroupDto>();
export const createGroupError = createAction(CREATE_GROUP_ERROR)<string>();

const GROUP_FEED_FETCH_REQUEST = "groupFeed/FETCH_REQUEST";
const GROUP_FEED_FETCH_SUCCESS = "groupFeed/FETCH_SUCCESS";
const GROUP_FEED_FETCH_ERROR = "groupFeed/FETCH_ERROR";
export const groupFeedFetchRequest = createAction(GROUP_FEED_FETCH_REQUEST)<{
  groupId: string;
  nameId: string;
}>();
export const groupFeedFetchSuccess = createAction(GROUP_FEED_FETCH_SUCCESS)<DashboardFeedItem[]>();
export const groupFeedFetchError = createAction(GROUP_FEED_FETCH_ERROR)<string>();

const REPORT_ABUSE_REQUEST = "group/REPORT_ABUSE_REQUEST";
const REPORT_ABUSE_SUCCESS = "group/REPORT_ABUSE_SUCCESS";
const REPORT_ABUSE_ERROR = "group/REPORT_ABUSE_ERROR";
export const reportAbuseRequest = createAction(REPORT_ABUSE_REQUEST)<ReportAbuseRequest>();
export const reportAbuseSuccess = createAction(REPORT_ABUSE_SUCCESS)<ReportAbuseRequest>();
export const reportAbuseError = createAction(REPORT_ABUSE_ERROR)<string>();

const ADD_COMMENT_GROUP_REQUEST = "social/post/ADD_COMMENT_GROUP_REQUEST";
const ADD_COMMENT_GROUP_SUCCESS = "social/post/ADD_COMMENT_GROUP_SUCCESS";
const ADD_COMMENT_GROUP_ERROR = "social/post/ADD_COMMENT_GROUP_ERROR";
export const addCommentGroupRequest = createAction(ADD_COMMENT_GROUP_REQUEST)<AddCommentDto>();
export const addCommentGroupSuccess = createAction(ADD_COMMENT_GROUP_SUCCESS)<DashboardFeedItem[]>();
export const addCommentGroupError = createAction(ADD_COMMENT_GROUP_ERROR)<string>();

const CREATE_POST_GROUP_REQUEST = "social/post/CREATE_POST_GROUP_REQUEST";
const CREATE_POST_GROUP_SUCCESS = "social/post/CREATE_POST_GROUP_SUCCESS";
const CREATE_POST_GROUP_ERROR = "social/post/CREATE_POST_GROUP_ERROR";
export const createPostGroupRequest = createAction(CREATE_POST_GROUP_REQUEST)<{
  post: CreatePostDto;
  refreshGroupId: number;
}>();
export const createPostGroupSuccess = createAction(CREATE_POST_GROUP_SUCCESS)<DashboardFeedItem[]>();
export const createPostGroupError = createAction(CREATE_POST_GROUP_ERROR)<string>();
