import { createStyles, Theme } from "@material-ui/core";

const styles = ({ spacing, breakpoints, mixins, transitions }: Theme) =>
  createStyles({
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: transitions.create("transform", {
        duration: transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    leftIconGutter: {
      width: "40px",
      flex: "0 0 auto",
      marginRight: 16
    },
    feedItemContent: {
      paddingTop: "0px"
    },
    textField: {
      marginBottom: "2rem",
      marginRight: "1rem"
    },
    poster: {
      fontSize: "0.875rem",
      fontWeight: 500
    },
    posterGrid: {
      paddingBottom: 0
    },
    postDateTime: {
      fontSize: "0.875rem",
      fontWeight: 300
    },
    newPostContainer: {
      padding: "1rem 2.5rem 2.5rem 2.5rem"
    },
    newPostCategories: {
      padding: "1rem 2rem 0 2rem",
      backgroundColor: "#f5f5f5",
      boxShadow: "inset 0px 0px 5px 1px rgba(0,0,0,0.05)"
    },
    tab: {
      backgroundColor: "#4aa5ef",
      color: "white",
      opacity: 1.0,
      borderBottom: "none",
      marginRight: ".5rem",
      textTransform: "unset"
      //boxShadow: "5px -4px 3px -3px rgba(0,0,0,0.25)"
    },
    tabSelected: {
      backgroundColor: "white",
      color: "#394b76",
      opacity: 1.0,
      borderBottom: "none"
    },
    title: {
      fontWeight: 300
    },
    icon: {
      // display: "flex",
      // justifyContent: "center",
      // alignItems: "center"
      textAlign: "center"
    },
    arrowIcon: {
      marginTop: 0.625 * spacing.unit
    },
    hrDiv: {
      width: "100%",
      height: "20px",
      borderBottom: "1px solid #757575",
      textAlign: "center",
      color: "#818078",
      padding: "8px",
      marginBottom: "20px"
    },
    hrSpan: {
      fontSize: ".875rem",
      fontFamily: "Roboto, sans serif",
      backgroundColor: "#bfc4c5",
      padding: "5px 15px",
      color: "white"
    },
    timeSpan: {
      marginRight: "5px"
    },
    postTypeIcon: {
      height: "40px",
      width: "40px",
      display: "inline-block",
      marginTop: "-5px"
    },
    eventTimeLocation: {
      height: "20px",
      width: "20px",
      display: "inline-block",
      marginRight: "5px"
    },
    eventAttendance: {
      height: "24px",
      width: "24px",
      display: "inline-block",
      marginRight: "5px"
    },
    eventHeaderText: {
      float: "right"
    },
    eventmonth: {
      color: "white",
      fontSize: ".875rem",
      fontWeight: 500,
      marginBottom: "0"
    },
    eventday: {
      color: "white",
      fontSize: "2.125rem",
      fontWeight: 100
    },
    eventDate: {
      margin: "-1rem",
      marginRight: ".25rem",
      paddingTop: "2.25rem !important",
      paddingLeft: "1rem !important",
      backgroundColor: "#133951",
      [breakpoints.up("sm")]: {
        paddingTop: "1.5rem !important",
        paddingLeft: "1rem !important"
      }
    },
    eventDetails: {
      paddingTop: "1.125rem !important",
      [breakpoints.up("sm")]: {
        paddingTop: ".35rem !important"
      }
    },
    myGroupSubHead: {
      fontSize: "0.875rem",
      fontStyle: "italic"
    },
    commentForm: {
      display: "inline-flex",
      width: "100%"
    },
    datepicker: {
      fontSize: "1rem",
      border: 0,
      borderBottom: "solid 1px rgba(0, 0, 0, 0.42)",
      paddingBottom: ".5rem"
    },
    groupItem: {
      display: "none"
    },
    groupSelected: {
      display: "inline-block"
    },
    newsAttachmentContainer: {
      paddingTop: "20px"
    },
    filterIcon: {
      height: "35px",
      width: "35px",
      display: "inline-block"
    },
    groupNavLink: {
      color: "#3d90d4",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    arrowRightAltIcon: {
      verticalAlign: "middle",
      marginLeft: "5px"
    },
    WelcomeTileHeading: {
      marginBottom: "1rem",
      color: "#636a6b"
    },
    WelcomeTileBody: {
      marginBottom: "1rem"
    },
    WelcomeTileLink: {
      fontWeight: 500
    },
    CheckListIcon: {
      fill: "#636a6b"
    }
  });
export default styles;
