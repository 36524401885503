import { Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import { LocalShipping, SettingsBackupRestore, Today } from "@material-ui/icons";
import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import globalstyles from "../../../../global-styles";
import combinestyles from "../../../../utils/combinestyles";
import { thousands_separators } from "../../../shared/utils/utils";
import { NoticeToVacate } from "../../models";
import entvstyles from "../../styles";

const styles = combinestyles(entvstyles, globalstyles);

export interface InsufficientLeaseEndProps extends WithStyles<typeof styles> {
  noticeInfo?: NoticeToVacate;
}

type State = {};

type AllProps = InsufficientLeaseEndProps & RouteComponentProps;

class InsufficientLeaseEnd extends React.Component<AllProps, State> {
  state: Readonly<State> = {};

  render() {
    const title = this.props.noticeInfo!.copyLanguage.find(e => {
      return e.languageId === "A12";
    });
    const insufficientNotice = this.props.noticeInfo!.copyLanguage.find(e => {
      return e.languageId === "A13";
    });

    return (
      <form>
        <Grid container className={this.props.classes.grouping}>
          <Grid xs={12} item>
            <Typography
              className={this.props.classes.tablepadding}
              style={{ paddingTop: "1rem", paddingBottom: "1.5rem" }}
            >
              {title!.copyLanguage}
            </Typography>
          </Grid>
          <Grid xs={4} item>
            <LocalShipping color="disabled" style={{ verticalAlign: "middle" }} />
            <Typography
              style={{ display: "inline-block", fontSize: ".875rem", paddingBottom: "1rem", paddingLeft: ".5rem" }}
            >
              Vacate Date <Moment format="MM/DD/YY" date={this.props.noticeInfo!.vacateDate} />
            </Typography>
          </Grid>
          <Grid xs={5} item>
            <Typography style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold" }}>
              Charges
            </Typography>
          </Grid>
          <Grid xs={3} item style={{ textAlign: "right" }}>
            <Typography style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold" }}>Amount</Typography>
          </Grid>
          <Grid xs={4} item>
            <SettingsBackupRestore color="disabled" style={{ verticalAlign: "middle" }} />
            <Typography
              variant="body2"
              style={{ display: "inline-block", fontSize: ".875rem", paddingBottom: ".5rem", paddingLeft: ".5rem" }}
            >
              Lease End {this.props.noticeInfo!.leaseEnd}
            </Typography>
            <br />
            <Today color="disabled" style={{ verticalAlign: "middle" }} />
            <Typography
              style={{ display: "inline-block", fontSize: ".875rem", paddingBottom: "1rem", paddingLeft: ".5rem" }}
            >
              Notice Required <Moment format="MM/DD/YY" date={this.props.noticeInfo!.noticeRequired} />
            </Typography>
            <br />
            <Today color="disabled" style={{ verticalAlign: "middle" }} />
            <Typography
              style={{ display: "inline-block", fontSize: ".875rem", paddingBottom: "1rem", paddingLeft: ".5rem" }}
            >
              Notice Provided <Moment format="MM/DD/YY" date={this.props.noticeInfo!.givesNotice} />
            </Typography>
          </Grid>
          <Grid xs={5} item>
            <Typography variant="body2" style={{ display: "inline-block", fontSize: ".9rem" }}>
              Insufficient Notice
            </Typography>
            <Typography variant="body2" style={{ display: "inline-block", fontSize: ".8rem" }}>
              {insufficientNotice!.copyLanguage}
            </Typography>
          </Grid>
          <Grid xs={3} item style={{ textAlign: "right" }}>
            <Typography style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold", color: "green" }}>
              ${thousands_separators(this.props.noticeInfo!.insufficientAmount.toFixed(2))}
            </Typography>
          </Grid>
          <Grid xs={8} item />
        </Grid>
      </form>
    );
  }
}

export default withRouter(connect()(withStyles(styles)(InsufficientLeaseEnd)));
