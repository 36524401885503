import { IconButton, FormControlLabel, Radio, RadioGroup, Typography, WithStyles, withStyles } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/DoneOutline";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import React, { Component } from "react";

import globalstyles from "../../../../global-styles";
import combinestyles from "../../../../utils/combinestyles";
import { Group } from "../../models";
import socialstyles from "../styles";

// import MoreIcon from "@material-ui/icons/MoreHoriz";
const styles = combinestyles(socialstyles, globalstyles);
interface MyProps extends WithStyles<typeof styles> {
  groups: Group[];
  selectedGroup?: number;
  handleGroupSelection: (value: string) => void;
}

class GroupSelector extends Component<MyProps> {
  // Value of Everyone is 0

  state = {
    value: this.props.selectedGroup ? this.props.selectedGroup.toString() : "0",
    myGroups: this.props.groups,
    groupCount: 0,
    groupsExpanded: false
  };

  componentDidMount() {
    this.props.handleGroupSelection(this.props.selectedGroup ? this.props.selectedGroup.toString() : "0");
    this.setState({
      myGroups: this.props.groups.filter((group: Group) => group.id !== this.props.selectedGroup).slice(0, 3),
      groupCount: this.props.groups.length
    });
  }

  handleChange = (event: any) => {
    this.setState({ value: event.target.value });
    this.props.handleGroupSelection(event.target.value);
  };

  expandGroups = () => {
    this.setState({
      myGroups: this.props.groups.filter((group: Group) => group.id !== this.props.selectedGroup),
      groupsExpanded: true
    });
  };

  render() {
    const selectedGroup = this.props.groups
      .filter((group: Group) => group.id === this.props.selectedGroup)
      .map((group: Group) => (
        <FormControlLabel
          key={group.dateCreated}
          value={group.id.toString()}
          control={
            <Radio
              color="primary"
              checkedIcon={<DoneIcon />}
              style={{ paddingLeft: "0" }}
              classes={{ root: this.props.classes.groupItem, checked: this.props.classes.groupSelected }}
            />
          }
          label={group.name}
          style={{
            border: "1px solid #ebebeb",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            minHeight: "3.25rem",
            marginBottom: ".25rem"
          }}
        />
      ));

    return (
      <React.Fragment>
        <Typography variant="body1" style={{ marginBottom: ".5rem" }}>
          Post to
        </Typography>
        <RadioGroup
          aria-label="Groups"
          name="group"
          value={this.state.value}
          onChange={(event: any) => {
            this.handleChange(event);
          }}
          style={{ flexDirection: "row" }}
          className="radioGroup"
        >
          {selectedGroup}
          <FormControlLabel
            value="0"
            checked
            control={
              <Radio
                color="primary"
                checkedIcon={<DoneIcon />}
                style={{ paddingLeft: "0" }}
                classes={{ root: this.props.classes.groupItem, checked: this.props.classes.groupSelected }}
              />
            }
            label="Everyone"
            style={{
              border: "1px solid #ebebeb",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              minHeight: "3.25rem",
              marginBottom: ".25rem"
            }}
          />
          {this.state.myGroups.map((group: Group) => (
            <FormControlLabel
              key={group.id.toString()}
              value={group.id.toString()}
              control={
                <Radio
                  color="primary"
                  checkedIcon={<DoneIcon />}
                  style={{ paddingLeft: "0" }}
                  classes={{ root: this.props.classes.groupItem, checked: this.props.classes.groupSelected }}
                />
              }
              label={group.name}
              style={{
                border: "1px solid #ebebeb",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                minHeight: "3.25rem",
                marginBottom: ".25rem"
              }}
            />
          ))}
          {!this.state.groupsExpanded && this.state.groupCount > 3 && (
            <IconButton onClick={this.expandGroups} aria-label="Show more">
              <MoreIcon />
            </IconButton>
          )}
        </RadioGroup>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(GroupSelector);
