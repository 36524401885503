import { combineReducers } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { ResidentRentableItems, RentableItems } from "./models";
import * as rentable from "./actions";

export type RentableAction = ActionType<typeof rentable>;

export type RentableState = Readonly<{
  rentableResidentList: ResidentRentableState;
  rentablePropertyList: PropertyRentableState;
}>;

export type ResidentRentableState = Readonly<{
  loading: boolean;
  data: ResidentRentableItems[];
  errors?: string;
}>;

const initialResidentRentableState: ResidentRentableState = {
  data: [],
  errors: undefined,
  loading: false,
};

export type PropertyRentableState = Readonly<{
  loading: boolean;
  data: RentableItems[];
  errors?: string;
}>;

const initialPropertyRentableState: PropertyRentableState = {
  data: [],
  errors: undefined,
  loading: false,
};

// tslint:disable-next-line:no-unused-expression

export default combineReducers<RentableState, RentableAction>({
  rentableResidentList: (state = initialResidentRentableState, action) => {
    switch (action.type) {
      case getType(rentable.rentableResidentRequest): {
        return { ...state, loading: true };
      }
      case getType(rentable.rentableResidentSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(rentable.rentableResidentError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  rentablePropertyList: (state = initialPropertyRentableState, action) => {
    switch (action.type) {
      case getType(rentable.rentablePropertyRequest): {
        return { ...state, loading: true };
      }
      case getType(rentable.rentablePropertySuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(rentable.rentablePropertyError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
});
