import { createAsyncAction } from "typesafe-actions";
import { CreditReportData } from "./models";

const FETCH_REQUEST = "creditreport/FETCH_REQUEST";
const FETCH_SUCCESS = "creditreport/FETCH_SUCCESS";
const FETCH_ERROR = "creditreport/FETCH_ERROR";

export const fetchAsyncAction = createAsyncAction(FETCH_REQUEST, FETCH_SUCCESS, FETCH_ERROR)<
  string,
  CreditReportData,
  Error
>();
