import { combineReducers } from "redux";
import { ActionType, getType } from "typesafe-actions";

import * as amenity from "./actions";
import { AmenityReservation, ReservableAmenity } from "./models";

export type AmenityAction = ActionType<typeof amenity>;

export type AmenityState = Readonly<{
  deeplinkAmenity: DeepLinkAmenityState;
  reservableAmenityList: ReservableAmenityState;
  myAmenityReservation: AmenityReservationState;
  newReservation: NewReservationState;
  insertNewAmenity: InsertAmenityState;
  cancelAmenity: CancelAmenityState;
}>;

export type DeepLinkAmenityState = Readonly<{
  loading: boolean;
  data: ReservableAmenity[];
  errors?: string;
}>;

const initialDeepLinkAmenityState: DeepLinkAmenityState = {
  data: [],
  errors: undefined,
  loading: false,
};

export type ReservableAmenityState = Readonly<{
  loading: boolean;
  data: ReservableAmenity[];
  errors?: string;
}>;

const initialReservableAmenityState: ReservableAmenityState = {
  data: [],
  errors: undefined,
  loading: false,
};

export type AmenityReservationState = Readonly<{
  loading: boolean;
  data: AmenityReservation[];
  errors?: string;
}>;

const initialAmenityReservationState: AmenityReservationState = {
  data: [],
  errors: undefined,
  loading: false,
};

export type NewReservationState = Readonly<{
  amenity?: ReservableAmenity;
}>;

const initialNewReservationState: NewReservationState = {
  amenity: undefined,
};

export type InsertAmenityState = Readonly<{
  loading: boolean;
  newReservation?: number;
  errors?: string;
}>;

const initialInsertAmenityState: InsertAmenityState = {
  newReservation: undefined,
  errors: undefined,
  loading: false,
};

export type CancelAmenityState = Readonly<{
  loading: boolean;
  cancelAmenity?: undefined;
  errors?: string;
}>;

const initialCancelAmenityState: CancelAmenityState = {
  cancelAmenity: undefined,
  errors: undefined,
  loading: false,
};

// tslint:disable-next-line:no-unused-expression

export default combineReducers<AmenityState, AmenityAction>({
  deeplinkAmenity: (state = initialDeepLinkAmenityState, action) => {
    switch (action.type) {
      case getType(amenity.deeplinkFetchRequest): {
        return { ...state, loading: true };
      }
      case getType(amenity.deeplinkFetchSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(amenity.deeplinkFetchError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  reservableAmenityList: (state = initialReservableAmenityState, action) => {
    switch (action.type) {
      case getType(amenity.categoriesFetchRequest): {
        return { ...state, loading: true };
      }
      case getType(amenity.categoriesFetchSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(amenity.categoriesFetchError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  myAmenityReservation: (state = initialAmenityReservationState, action) => {
    switch (action.type) {
      case getType(amenity.listFetchRequest): {
        return { ...state, loading: true };
      }
      case getType(amenity.listFetchSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(amenity.listFetchError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  newReservation: (state = initialNewReservationState, action) => {
    switch (action.type) {
      case getType(amenity.selectAmenityToReserve): {
        return { ...state, amenity: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  insertNewAmenity: (state = initialInsertAmenityState, action) => {
    switch (action.type) {
      case getType(amenity.insertAmenityRequest): {
        return { ...state, loading: true };
      }
      case getType(amenity.insertAmenitySuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(amenity.insertAmenityError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  cancelAmenity: (state = initialCancelAmenityState, action) => {
    switch (action.type) {
      case getType(amenity.cancelAmenityRequest): {
        return { ...state, loading: true };
      }
      case getType(amenity.cancelAmenitySuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(amenity.cancelAmenityError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
});
