import { combineReducers } from "redux";
import { ActionType, createReducer } from "typesafe-actions";

import * as notificationActions from "./actions";
import { DashboardNotification } from "./models";

export type NotificationsAction = ActionType<typeof notificationActions>;

export const isLoadingNotifications = createReducer(false as boolean)
  .handleAction([notificationActions.fetchRequestAsync.request], (state: any, action: any) => true)
  .handleAction(
    [notificationActions.fetchRequestAsync.success, notificationActions.fetchRequestAsync.failure],
    (state: any, action: any) => false
  );

export const notifications = createReducer([] as DashboardNotification[]).handleAction(
  notificationActions.fetchRequestAsync.success,
  (state: any, action: any) => action.payload
);

const reducer = combineReducers({
  isLoadingNotifications,
  notifications,
});

export default reducer;

export type NotificationsState = ReturnType<typeof reducer>;
