import { createAction } from "typesafe-actions";

import { NewReferFriend, ReferFriendAmount } from "./models";

const REFERAMOUNT_FETCH_REQUEST = "referamount/FETCH_REQUEST";
const REFERAMOUNT_FETCH_SUCCESS = "referamount/FETCH_SUCCESS";
const REFERAMOUNT_FETCH_ERROR = "referamount/FETCH_ERROR";

export const referAmountFetchRequest = createAction(REFERAMOUNT_FETCH_REQUEST)<string>();
export const referAmountFetchSuccess = createAction(REFERAMOUNT_FETCH_SUCCESS)<ReferFriendAmount>();
export const referAmountFetchError = createAction(REFERAMOUNT_FETCH_ERROR)<string>();

const REFERFRIEND_FETCH_REQUEST = "referfriend/FETCH_REQUEST";
const REFERFRIEND_FETCH_SUCCESS = "referfriend/FETCH_SUCCESS";
const REFERFRIEND_FETCH_ERROR = "referfriend/FETCH_ERROR";

export const requestReferFriendRequest = createAction(REFERFRIEND_FETCH_REQUEST)<NewReferFriend>();
export const requestReferFriendSuccess = createAction(REFERFRIEND_FETCH_SUCCESS)<string>();
export const requestReferFriendError = createAction(REFERFRIEND_FETCH_ERROR)<string>();
