import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import { InjectedProps } from "@material-ui/core/withMobileDialog";
import * as React from "react";

interface Props {
  onContinue: any;
  open: boolean;
}

type AllProps = Props & InjectedProps;

class CancelModal extends React.Component<AllProps> {
  state = {
    covidYes: false
  };

  change = (e: any) => {
    if (!e.target) {
      return;
    }

    this.setState({ covidYes: true });
  };

  render() {
    const { fullScreen, open } = this.props;

    return (
      <Dialog fullScreen={fullScreen} open={open}>
        <DialogTitle>Cancel or Change Request</DialogTitle>
        <DialogContent>
          <Typography>
            Your request to cancel or change your move-out date has been sent. A member of the leasing team will be in
            touch to confirm details.
          </Typography>
          <br />
        </DialogContent>
        <Grid container alignItems="flex-end" direction="column">
          <Grid item>
            <DialogActions>
              <Button
                onClick={this.props.onContinue.bind(this, this.state)}
                color="primary"
                variant="contained"
                autoFocus
              >
                OK
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

export default CancelModal;
