import { Theme, createStyles } from "@material-ui/core";

const styles = ({ spacing, breakpoints, mixins, transitions }: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1,
      // marginBottom: 24
      //paddingTop: spacing.unit * 7
    },
    textStyle: {
      fontFamily: `"Roboto", "Helvetica", "Arial"`,
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: "0.875rem",
      fontWeight: 300,
      lineHeight: 1.5,
      letterSpacing: "0.01071em",
    },
    container: {
      display: "flex",
    },
    content: {
      width: "100%",
      height: `calc(100% - ${spacing.unit * 8})`,
      marginTop: spacing.unit * 7,
      paddingLeft: spacing.unit * 1,
      paddingRight: spacing.unit * 1,
      [breakpoints.up("sm")]: {
        height: "calc(100% - 120px)",
        marginTop: spacing.unit * 15,
        paddingLeft: ".5rem",
        paddingRight: "1rem",
      },
    },
    contentRightRail: {
      width: "100%",
      height: `calc(100% - ${spacing.unit * 8})`,
      // marginTop: "8rem",
      paddingLeft: spacing.unit * 1,
      paddingRight: spacing.unit * 1,
      [breakpoints.up("sm")]: {
        height: "calc(100% - 120px)",
        // marginTop: "7.5rem",
        paddingLeft: ".75rem",
        paddingRight: "1.25rem",
      },
      [breakpoints.up("md")]: {
        maxWidth: "760px",
      },
    },
    // contentElement: {
    //   marginLeft: spacing.unit * 1,
    //   marginRight: spacing.unit * 1
    // },
    gridContainer: {
      marginBottom: "1rem",
    },
    // gridGroupHeader: {
    //   marginBottom: "1rem",
    //   marginLeft: "1rem"
    // },
    paper: {
      ...mixins.gutters(),
      padding: "1rem",
      marginBottom: "1rem",
      [breakpoints.up("sm")]: {
        padding: "1.5rem",
      },
    },
    card: {
      // [breakpoints.up("sm")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      // marginBottom: "1rem"
      // }
    },
    header: {
      paddingLeft: "0",
      paddingRight: "0",
      marginBottom: "2rem",
      paddingTop: "1.5rem",
      fontSize: "1.5rem",
      [breakpoints.up("sm")]: {
        paddingLeft: "0",
        paddingRight: "0",
        marginBottom: "2.5rem",
        paddingTop: "1.5rem",
        fontSize: "2rem",
      },
      [breakpoints.up("md")]: {
        paddingLeft: "0",
        paddingRight: "0",
        marginBottom: "2.25rem",
        paddingTop: "3rem",
      },
    },
    headerGroup: {
      // [breakpoints.up("sm")]: {
      marginBottom: ".5rem",
      // }
    },
    link: {
      textDecoration: "none",
      color: "#3d90d4",
      cursor: "pointer",
    },
    morelink: {
      fontFamily: "'Roboto',sans-serif",
      fontWeight: 500,
      color: "#2e79b6",
      fontSize: "0.875rem",
      textDecoration: "none",
      textTransform: "uppercase",
    },
    badge: {
      fontSize: ".875rem",
      padding: ".25rem",
      marginLeft: ".5rem",
      color: "white",
      backgroundColor: "#BFC4C5",
    },
    divider: {
      marginTop: spacing.unit * 1,
      marginBottom: spacing.unit * 1,
      [breakpoints.up("sm")]: {
        marginTop: spacing.unit * 2,
        marginBottom: spacing.unit * 2,
      },
    },
    listitem: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    commentItem: {
      [breakpoints.up("sm")]: {
        paddingLeft: "0",
      },
    },
    commentDate: {
      fontSize: "0.875rem",
      color: "#9d9d9d",
    },
    dropZone: {
      textAlign: "center",
      width: "100%",
      height: "300px",
      border: "dotted",
    },
    dropZoneImagePreview: {
      maxWidth: "100%",
      height: "auto",
      maxHeight: "300px",
      objectFit: "fill",
    },
    profilePicContainer: {
      marginBottom: "1rem",
      [breakpoints.up("sm")]: {
        paddingLeft: "1rem",
      },
    },
  });
export default styles;
