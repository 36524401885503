import * as React from "react";
import {
  Typography,
  withStyles,
  WithStyles,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from "@material-ui/core";

import contactstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import { ContactUsItem, ContactUsSubItem } from "../models";
import { ChevronLeft } from "@material-ui/icons";
import ContactUsRow from "./ContactUsRow";

const styles = combinestyles(contactstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  items: ContactUsItem[];
}

type MyState = {
  selectedItems: ContactUsSubItem[];
  openEmailDialog: boolean;
  dialogText: string;
  emailToSend: string;
  emailSubject: string;
};

class ContactUsList extends React.Component<Props, MyState> {
  state: Readonly<MyState> = {
    selectedItems: [],
    openEmailDialog: false,
    dialogText: "",
    emailToSend: "",
    emailSubject: ""
  };

  backToMain = () => {
    this.setState({
      selectedItems: []
    });
  };

  handleClick = (
    destination: string,
    contactType: string,
    email: string,
    description: string,
    subData?: ContactUsSubItem[]
  ) => {
    if (subData && subData.length > 0) {
      this.setState({
        selectedItems: subData
      });
    } else if (destination) {
      switch (contactType) {
        case "Popup":
          //window.open("mailto:" + destination);
          this.setState({ openEmailDialog: true });
          this.setState({ dialogText: destination });
          this.setState({ emailToSend: email });
          this.setState({ emailSubject: description });
          break;
        case "Internal":
          window.location.href = destination;
          break;
        case "External":
          window.open(destination);
          break;
      }
    }
  };

  closeEmailDialog = () => {
    this.setState({ openEmailDialog: false });
  };

  SendEmail = () => {
    window.open("mailto:" + this.state.emailToSend + "?subject=" + this.state.emailSubject);
    this.setState({ openEmailDialog: false });
  };

  render() {
    const { items } = this.props;

    let list;

    if (this.state.selectedItems && this.state.selectedItems.length > 0) {
      list = (
        <div>
          {this.state.selectedItems.map((contactUsItem, index) => (
            <ContactUsRow
              index={index}
              contactType={contactUsItem.contactType}
              description={contactUsItem.subDescription}
              destination={contactUsItem.destination}
              subItems={[]}
              icon={contactUsItem.subIcon}
              email={contactUsItem.email}
              clickHandler={this.handleClick}
            />
          ))}
          <ListItem style={{ padding: "1rem 0", cursor: "pointer" }} onClick={this.backToMain}>
            <ListItemIcon style={{ color: "#2978b8" }}>
              <ChevronLeft />
            </ListItemIcon>
            <Typography style={{ lineHeight: "1.2rem" }}>Back to main list</Typography>
          </ListItem>
        </div>
      );
    } else {
      list = items.map((contactUsItem, index) => (
        <ContactUsRow
          index={index}
          contactType={contactUsItem.contactType}
          description={contactUsItem.mainDescription}
          destination={contactUsItem.destination}
          subItems={contactUsItem.subData}
          icon={contactUsItem.mainIcon}
          email={contactUsItem.email}
          clickHandler={this.handleClick}
        />
      ));
    }

    return (
      items.length > 0 && (
        <Paper className={this.props.classes.paper}>
          <Typography variant="h3" style={{ marginBottom: "1rem" }}>
            How can we help?
          </Typography>
          <Divider />
          <List style={{ width: "100%" }}>{list}</List>
          <div>
            <Dialog open={this.state.openEmailDialog} onClose={this.closeEmailDialog} scroll="paper">
              <DialogContent>
                <Typography
                  component="div"
                  style={{ whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{ __html: this.state.dialogText }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeEmailDialog} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Paper>
      )
    );
  }
}

export default withStyles(styles)(ContactUsList);
