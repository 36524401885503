import React, { Component } from "react";

interface Props {
  startDate: string;
  endDate: string;
}

class BillingCycle extends Component<Props> {
  render() {
    const { startDate, endDate } = this.props;

    return (
      <React.Fragment>
        <br />
        {startDate} - {endDate}
      </React.Fragment>
    );
  }
}

export default BillingCycle;
