import { Paper, Typography, Grid, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";

import { UserInfoItem } from "../../userInfo/models";

import { connect } from "react-redux";
import { RootState } from "../../../store/root-reducer";
import { getActiveUserInfo } from "../../../features/selectAccount/selector";

import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import servicestyles from "./styles";

const styles = combinestyles(servicestyles, globalstyles);

export interface ServiceReqHeaderTilesProps extends WithStyles<typeof styles> {
  user?: UserInfoItem;
}

interface PropsFromState {
  userData: UserInfoItem;
}

type AllProps = PropsFromState & ServiceReqHeaderTilesProps;

class ServiceReqHeaderTiles extends React.Component<AllProps> {
  render() {
    const headerTiles = this.props.user!.propertyInfo.serviceTiles;
    const officePhoneNumber = this.GetFormatPhone(this.props.user!.propertyInfo.propertyPhone);
    let headerTilesArray = [];
    for (let i = 0; i < headerTiles.length; i++) {
      headerTilesArray.push(
        `${headerTiles[i].title.toString()} ${headerTiles[i].copy} ${headerTiles[i].iconFile} 
        ${headerTiles[i].copyColor} ${headerTiles[i].titleColor}`
      );
    }

    return headerTiles.map((ht) => (
      <Grid item sm={12} md={4} spacing={8}>
        <Paper className={this.props.classes.paper} style={{ marginBottom: 0 }}>
          <Grid container direction="row" justify="center" alignItems="center" spacing={8}>
            <Grid item>
              <img alt="null" className="list-icon" src={ht.iconFile} />
              {/* https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/badges/emergency.svg */}
            </Grid>
            <Grid item xs>
              <Typography variant="h5" gutterBottom style={{ color: ht.titleColor }}>
                {ht.title}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" style={{ color: ht.copyColor }}>
            {ht.copy.replace("[PHONE]", officePhoneNumber)}
          </Typography>
        </Paper>
      </Grid>

      // <Paper className={this.props.classes.paper}>
      //   <Grid container spacing={24}>
      //     <Grid item sm={6}>
      //       <Grid container alignItems="center" spacing={24}>
      //         <Grid item xs={2}>
      //           <img className="header-icon" src={ht.iconFile} />
      //           {/* https://media.equityapartments.com/image/upload/dpr_auto,f_auto/Content/portal/badges/emergency.svg */}
      //         </Grid>
      //         <Grid item xs={10}>
      //           <Typography variant="h5" gutterBottom>
      //             {ht.title}
      //           </Typography>
      //           <Typography variant="body2">{ht.copy.replace("[PHONE]", officePhoneNumber)}</Typography>
      //         </Grid>
      //       </Grid>
      //     </Grid>
      //   </Grid>
      // </Paper>
    ));
  }

  private GetFormatPhone = (phone: string) => {
    let res = "";

    if (phone.length < 10) {
      res = "";
    } else {
      var prefix = phone.substring(0, 3);
      var dns = phone.substring(3, 6);
      var suffix = phone.substring(6, 10);

      var ext = "";
      if (phone.length > 10) {
        ext = " x" + phone.substring(10, phone.length - 10);
        res = "(" + prefix + ") " + dns + "-" + suffix + ext; // String.Format("({0}) {1}-{2} x{3}", prefix, dns, suffix, ext);
      } else {
        res = "(" + prefix + ") " + dns + "-" + suffix;
      }
    }

    return res;
  };
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  userData: getActiveUserInfo(selectAccount),
});

export default connect(mapStateToProps)(withStyles(styles)(ServiceReqHeaderTiles));
