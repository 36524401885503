import { call, fork, all, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { getMoveInChecklist, saveMoveInChecklistComplete } from "../../utils/api";
import { showModalAction } from "../errorhandling/actions";
import * as actions from "./actions";

function* handleFetchMoveinChecklist(action: ReturnType<typeof actions.fetchRequestAsync.request>): any {
  try {
    const nameId = action.payload;

    let response = yield call(getMoveInChecklist, nameId);

    if (response.error) {
      yield put(actions.fetchRequestAsync.failure(response.error));
    } else {
      yield put(actions.fetchRequestAsync.success(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.fetchRequestAsync.failure(err));
    } else {
      yield put(actions.fetchRequestAsync.failure(new Error("An unknown error occured.")));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleMarkComplete(action: ReturnType<typeof actions.markCompleteRequest>): any {
  try {
    const response = yield call(saveMoveInChecklistComplete, action.payload);

    if (response.error) {
      yield put(actions.fetchRequestAsync.failure(response.error));
    } else {
      yield put(actions.fetchRequestAsync.success(response));
      let fetchResp = yield call(getMoveInChecklist, action.payload.nameId);
      if (fetchResp.error) {
        yield put(actions.fetchRequestAsync.failure(fetchResp.error));
      } else {
        yield put(actions.fetchRequestAsync.success(fetchResp));
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.fetchRequestAsync.failure(err));
    } else {
      yield put(actions.fetchRequestAsync.failure(new Error("An unknown error occured.")));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(getType(actions.fetchRequestAsync.request), handleFetchMoveinChecklist);
  yield takeEvery(getType(actions.markCompleteRequest), handleMarkComplete);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* moveinChecklistSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default moveinChecklistSaga; // must be registered in ../../store/root-saga.ts
