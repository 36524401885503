import { Card, CardContent, List, ListItem, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { RootState } from "../../../store/root-reducer";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { dashboardFetchMoreRequest, dashboardFetchRequest, feedUserInfoFetchRequest } from "../actions";
import { DashboardFeedItem, DiscussionItem, EventItem, ForSaleItem, MarketingItem, NewsItem } from "../models";
import * as selectors from "../selectors";
import Discussion from "./Discussion";
import Event from "./Event";
import ForSaleComponent from "./ForSale";
import Marketing from "./Marketing";
import NewPost from "./NewPost";
import News from "./News";
import styles from "./styles";

//import HomeHeaderTiles from "./HomeHeaderTiles";
interface Props extends WithStyles<typeof styles> {}

interface PropsFromState {
  user?: UserInfoItem;
  noposts: boolean;
  errors?: string;
  filterBy: string;
  hasMore: boolean;
  unFilteredFeed: DashboardFeedItem[];
  filteredFeed: DashboardFeedItem[];
}

interface PropsFromDispatch {
  feedFetchRequest: typeof dashboardFetchRequest;
  feedFetchMoreRequest: typeof dashboardFetchMoreRequest;
  feedUserInfoFetchRequest: typeof feedUserInfoFetchRequest;
}

interface State {
  hasMore: boolean;
  postId: number;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

class Feed extends React.Component<AllProps, State> {
  readonly state: State = {
    hasMore: true,
    postId: 0,
  };

  componentDidMount() {
    const nameId = this.props.user!.nameId;

    this.props.feedFetchRequest(nameId);
    this.props.feedUserInfoFetchRequest(nameId);
  }

  infiniteScroll() {
    //check if list is there
    if (this.props.filteredFeed.length > 0) {
      // get last element and verify it's  a 'PlaceholderItem' and grab
      const nameId = this.props.user!.nameId;
      const lastItemIndex = this.props.filteredFeed.length - 1;
      const lastItem = this.props.filteredFeed[lastItemIndex];
      // then pop from array
      //get 'more exists' arrray item
      if (lastItem.itemType === "PlaceholderItem") {
        this.props.feedFetchMoreRequest(nameId, lastItem.id);
      }
    }
  }

  render() {
    const { filteredFeed, unFilteredFeed, noposts, user } = this.props;
    const loader = (
      <div className="loader" key={0}>
        Loading ...
      </div>
    );
    const bannedElements = ["EventItem", "DiscussionItem", "ForSaleItem"];

    var items: any[] = [];

    filteredFeed
      .filter((x) => x.itemType !== "PlaceholderItem")
      .filter((x) => {
        if (user!.bannedFromSocial) {
          return bannedElements.indexOf(x.itemType) === -1;
        } else {
          return true;
        }
      })
      .map((x) =>
        items.push(
          <ListItem key={x.id} style={{ paddingLeft: "0", paddingRight: "0" }}>
            {(() => {
              switch (x.itemType) {
                case "ForSaleItem":
                  return <ForSaleComponent item={x as ForSaleItem} />;
                case "NewsItem":
                  return <News item={x as NewsItem} />;
                case "DiscussionItem":
                  return <Discussion item={x as DiscussionItem} />;
                case "EventItem":
                  return <Event item={x as EventItem} />;
                case "MarketingItem":
                  return <Marketing item={x as MarketingItem} />;
                default:
                  return "";
              }
            })()}
          </ListItem>
        )
      );

    return (
      <div>
        {unFilteredFeed.length > 0 && (
          <List>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.infiniteScroll.bind(this)}
              hasMore={this.props.hasMore}
              loader={loader}
            >
              {items}
            </InfiniteScroll>
          </List>
        )}
        {noposts && (
          <Card style={{ width: "100%", marginTop: "1rem" }}>
            <CardContent>
              <Typography variant="h4" style={{ marginBottom: "1rem" }}>
                It looks like there aren't any posts yet
              </Typography>
              {!user!.bannedFromSocial && <NewPost buttonText="Get Involved" />}
            </CardContent>
          </Card>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ social, selectAccount, socialGroup }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  noposts: social.noposts,
  errors: social.errors,
  unFilteredFeed: social.data,
  filterBy: social.filterBy,
  hasMore: social.hasMore,
  filteredFeed: selectors.getFilteredFeed(social, socialGroup.groupUserList),
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  feedFetchRequest: (nameid: string) => dispatch(dashboardFetchRequest(nameid)),
  feedFetchMoreRequest: (nameid: string, postId: number) => dispatch(dashboardFetchMoreRequest(nameid, postId)),
  feedUserInfoFetchRequest: (nameid: string) => dispatch(feedUserInfoFetchRequest(nameid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Feed));
