import * as React from "react";
import { withStyles, WithStyles, Typography } from "@material-ui/core";
import { rentableResidentRequest, rentablePropertyRequest } from "../features/rentable/actions";

import RentableHeader from "../features/rentable/components/RentableHeader";
//import MyRentableList from "../features/rentable/components/MyRentableList";
import RentableList from "../features/rentable/components/RentableList";
import ResidentList from "../features/rentable/components/ResidentList";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import { RootState } from "../store/root-reducer";
import { RentableItems, ResidentRentableItems } from "../features/rentable/models";

import rentableitemstyles from "../features/rentable/styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(rentableitemstyles, globalstyles);

interface IRentableProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
}

interface IPageState {}

interface PropsFromState {
  user?: UserInfoItem;
  loading: boolean;
  residentRentableList: ResidentRentableItems[];
  propertyRentableList: RentableItems[];
  errors?: string;
}

interface PropsFromDispatch {
  rentableResidentRequest: typeof rentableResidentRequest;
  rentablePropertyRequest: typeof rentablePropertyRequest;
}

type AllProps = IRentableProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

class RentablePage extends React.Component<AllProps, IPageState> {
  state: IPageState = {};

  componentDidMount() {
    const rmpropid = this.props.user!.propertyId;
    const nameId = this.props.user!.nameId;
    this.props.rentableResidentRequest(nameId);
    this.props.rentablePropertyRequest(rmpropid);
    window.scrollTo(0, 0);
  }

  render() {
    //const activeAmenity = this.props.residentRentableList;

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <RentableHeader />

          {this.props.residentRentableList.length > 0 && (
            <Typography variant="h3" className={this.props.classes.headerGroup}>
              Current Items
            </Typography>
          )}
          {this.props.residentRentableList.length > 0 && <ResidentList />}
          {/* <MyRentableList propResidentRentableList={this.props.residentRentableList} /> */}

          <Typography variant="h3" className={this.props.classes.headerGroup}>
            Available Items
          </Typography>
          <RentableList />
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ rentable, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  residentRentableList: rentable.rentableResidentList.data,
  loading: rentable.rentablePropertyList.loading,
  propertyRentableList: rentable.rentablePropertyList.data,
  errors: rentable.rentablePropertyList.errors
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  rentableResidentRequest: (nameid: string) => dispatch(rentableResidentRequest(nameid)),
  rentablePropertyRequest: (rmpropid: string) => dispatch(rentablePropertyRequest(rmpropid))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RentablePage)));
