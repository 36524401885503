import * as React from "react";
import { Typography, withStyles, WithStyles, Divider, List, ListItem, DialogContentText } from "@material-ui/core";

import uploadsstyles from "../styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import { UploadDocument } from "../models";
import { uploadsListGetRequest } from "../actions";
import { UserInfoItem } from "../../userInfo/models";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../store/root-reducer";

const styles = combinestyles(uploadsstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {}

interface PropsFromState extends WithStyles<typeof styles> {
  user?: UserInfoItem;
}

interface PropsFromDispatch {
  uploadsListGetRequest: typeof uploadsListGetRequest;
  uploadItems: UploadDocument[];
}

type AllProps = Props & PropsFromState & PropsFromDispatch;

type MyState = {};

class UploadsList extends React.Component<AllProps, MyState> {
  state: Readonly<MyState> = {};

  public componentDidMount(): void {
    const user = this.props.user;
    const nameId = user!.nameId;

    this.props.uploadsListGetRequest(nameId);
  }

  render() {
    const { uploadItems } = this.props;

    let list = <Typography style={{ marginTop: "1rem" }}>No uploads found.</Typography>;

    if (uploadItems && uploadItems.length > 0) {
      list = (
        <List style={{ width: "100%" }}>
          {uploadItems.map((uploadDoc, index) => (
            <ListItem key={index} style={{ padding: "0.5rem 0", marginBottom: "0.3rem", display: "block" }}>
              <Typography style={{ lineHeight: "1.2rem", marginBottom: "0.2rem", fontWeight: 500 }}>
                {uploadDoc.filename}
              </Typography>
              <Typography style={{ lineHeight: "1.2rem", marginLeft: "1rem", fontStyle: "italic" }}>
                {uploadDoc.description}
              </Typography>
            </ListItem>
          ))}
        </List>
      );
    }

    return (
      <div>
        <DialogContentText style={{ marginTop: "1rem" }}>List of files uploaded:</DialogContentText>
        <Divider />
        {list}
      </div>
    );
  }
}

const mapStateToProps = ({ uploads, selectAccount }: RootState) => ({
  user: getActiveUserInfo(selectAccount),
  uploadItems: uploads.data
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  uploadsListGetRequest: (nameid: string) => dispatch(uploadsListGetRequest(nameid))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UploadsList));
