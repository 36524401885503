import { WithStyles, withStyles, CircularProgress, Dialog, DialogContent } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { paymentFetchRequest } from "../actions";
import { insertPaymentRequest, insertAutoPaymentRequest } from "../actions";
import { PaymentData } from "../models";
import { PaymentInputMppData, PaymentUrlDataResult } from "../models";
import paymentstyles from "../styles";
import PaymentButtonBoard from "./PaymentButtonBoard";
import PaymentHeader from "./PaymentHeader";
import PaymentNotification from "./PaymentNotification";

//import PaymentMake from "./PaymentMake";
//import PaymentAutoPay from "./PaymentAutoPay";

const styles = combinestyles(paymentstyles, globalstyles);

interface IPaymentDashboardProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
}

type MyState = {
  modalVisibility: boolean;
  paymentClicked: boolean;
};

interface PropsFromState {
  user?: UserInfoItem;
  loading: boolean;
  paymentDataInfo?: PaymentData;
  paymentUrlDataResult: PaymentUrlDataResult;
  errors?: string;
}

interface PropsFromDispatch {
  paymentFetchRequest: typeof paymentFetchRequest;
  insertPayment: typeof insertPaymentRequest;
  insertAutoPayment: typeof insertAutoPaymentRequest;
}

type AllProps = IPaymentDashboardProps & MyState & PropsFromState & PropsFromDispatch & RouteComponentProps;

class RenewalDashboardPage extends React.Component<AllProps> {
  state: Readonly<MyState> = {
    modalVisibility: false,
    paymentClicked: false
  };

  handlePaymentMake = () => {
    const paymentInput: PaymentInputMppData = {
      nameId: this.props.user!.nameId,
      paymentType: "singlepay",
      applicationSource: "WEBRP"
    };

    if (this.state.paymentClicked === false) {
      this.setState({ paymentClicked: true });

      this.handleOpenModal();
      this.props.insertPayment(paymentInput);
    }
  };
  handleAutoPaySetup = () => {
    const paymentInput: PaymentInputMppData = {
      nameId: this.props.user!.nameId,
      paymentType: "autopay",
      applicationSource: "WEBRP"
    };

    if (this.state.paymentClicked === false) {
      this.setState({ paymentClicked: true });

      this.handleOpenModal();
      this.props.insertAutoPayment(paymentInput);
    }
  };
  handleOnlineBil = () => {
    this.props.history.push("/payment/PaymentOnlineBill");
  };

  handleOpenModal = () => {
    this.setState({ modalVisibility: true });
  };
  handleCloseModal = () => {
    this.setState({ modalVisibility: false });
  };

  render() {
    if (!this.props.paymentDataInfo) {
      return <div />;
    }

    if (this.props.paymentUrlDataResult) {
      window.location.href = this.props.paymentUrlDataResult.mppUrl.toString();
    }

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <PaymentHeader displayBalance={this.props.paymentDataInfo.openBalance!} />

          {(this.props.paymentDataInfo.portalPaymentActive === "N" ||
            this.props.paymentDataInfo.hidePayment === "Y" ||
            this.props.paymentDataInfo.notifPendingTransactions! > 0 ||
            this.props.paymentDataInfo.notifCetifiedFundAndFullPayemnt === "Y" ||
            this.props.paymentDataInfo.notifCertifiedFunds === "Y" ||
            this.props.paymentDataInfo.notifRequireFullPayment === "Y" ||
            this.props.paymentDataInfo.applicantPaymentNotification === "Y") && (
            <PaymentNotification
              notifMantenance={this.props.paymentDataInfo.portalPaymentActive}
              notifHidePayment={this.props.paymentDataInfo.hidePayment}
              notifPendingPayment={this.props.paymentDataInfo.notifPendingTransactions!}
              notifCertifiesAndFull={this.props.paymentDataInfo.notifCetifiedFundAndFullPayemnt}
              notifCertifiedFund={this.props.paymentDataInfo.notifCertifiedFunds}
              notifRequiredFullPayment={this.props.paymentDataInfo.notifRequireFullPayment}
              applicantPaymentNotification={this.props.paymentDataInfo.applicantPaymentNotification}
            />
          )}
          {this.props.paymentDataInfo.portalPaymentActive === "Y" && this.props.paymentDataInfo.hidePayment === "N" && (
            <PaymentButtonBoard
              residentAccount={this.props.paymentDataInfo.resAccount}
              displayPaymentBox={this.props.paymentDataInfo.displayPaymentBox}
              displayAutoPayBox={this.props.paymentDataInfo.displayAutoPayBox}
              displayBILTBox={this.props.paymentDataInfo.displayBILTBox}
              displayOnlinePay={this.props.paymentDataInfo.displayOnlinePay}
              rentPayLogoURL={this.props.paymentDataInfo.rentPayLogoURL}
              rentPayTitle={this.props.paymentDataInfo.rentPayTitle}
              rentPayDescription={this.props.paymentDataInfo.rentPayDescription}
              rentPaySortOrder={this.props.paymentDataInfo.rentPaySortOrder}
              biltLinkURL={this.props.paymentDataInfo.biltLinkURL}
              biltLogoURL={this.props.paymentDataInfo.biltLogoURL}
              biltTitle={this.props.paymentDataInfo.biltTitle}
              biltDescription={this.props.paymentDataInfo.biltDescription}
              biltSortOrder={this.props.paymentDataInfo.biltSortOrder}
              onlinePayLogoURL={this.props.paymentDataInfo.onlinePayLogoURL}
              onlinePayTitle={this.props.paymentDataInfo.onlinePayTitle}
              onlinePayDescription={this.props.paymentDataInfo.onlinePayDescription}
              handlePaymentRentPayment={this.handlePaymentMake}
              handleAutoPay={this.handleAutoPaySetup}
              handleOnlinePay={this.handleOnlineBil}
              onlineBillSortOrder={this.props.paymentDataInfo.onlineBillSortOrder}
            />
          )}
          <Dialog open={this.state.modalVisibility} onClose={this.handleCloseModal}>
            <DialogContent>
              <CircularProgress />
            </DialogContent>
          </Dialog>
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ payment, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  paymentDataInfo: payment.paymentData.data!,
  paymentUrlDataResult: payment.paymentUrl.data!,
  loading: payment.paymentData.loading,
  errors: payment.paymentData.errors
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  paymentFetchRequest: (nameid: string) => dispatch(paymentFetchRequest(nameid)),
  insertPayment: (payment: PaymentInputMppData) => dispatch(insertPaymentRequest(payment)),
  insertAutoPayment: (payment: PaymentInputMppData) => dispatch(insertAutoPaymentRequest(payment))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RenewalDashboardPage)));
