import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import { NoticeToVacate } from "../models";
import entvstyles from "../styles";

const styles = combinestyles(entvstyles, globalstyles);

export interface ENTVReasonsProps extends WithStyles<typeof styles> {
  onContinue: any;
  noticeInfo?: NoticeToVacate;
  handleOtherResponse: (value: string) => void;
  //questions: ServiceQuestions[];
}

// interface PropsFromState {
//   categories: ServiceRequestCategory[];
// }

// interface PropsFromDispatch {
//   viewDetails: (item: ServiceRequest) => any;
// }

type State = {
  selectedReason: number;
  otherReason: string;
  otherCheck: boolean;
};

type AllProps = ENTVReasonsProps & RouteComponentProps;

class ENTVReasons extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    selectedReason: 0,
    otherReason: "",
    otherCheck: false
  };

  handleOptionChange = (changeEvent: any) => {
    this.setState({
      selectedReason: Number(changeEvent.target.value),
      otherReason: "",
      otherCheck: false
    });
  };

  handleOtherChange = (changeEvent: any) => {
    this.props.handleOtherResponse(changeEvent.target.value);
    this.setState({
      otherReason: changeEvent.target.value,
      selectedReason: this.props.noticeInfo!.leavingReasons.find(x => x.id === 4)!.reasonId,
      otherCheck: true
    });
  };

  render() {
    const relocationReasonsList = this.props.noticeInfo!.leavingReasons.filter(x => x.id === 3);
    const apartmentReasonsList = this.props.noticeInfo!.leavingReasons.filter(x => x.id === 1);
    const financialReasonsList = this.props.noticeInfo!.leavingReasons.filter(x => x.id === 2);

    const { classes } = this.props;
    return (
      <Paper className={this.props.classes.paper}>
        <Grid container className={this.props.classes.grouping}>
          <Grid xs={12} item>
            {this.props.noticeInfo!.isEasyMove.toString() === "Y" && (
              <Typography variant="caption" className={this.props.classes.tablepadding} gutterBottom>
                STEP 2 OF 5
              </Typography>
            )}
            {this.props.noticeInfo!.isEasyMove.toString() === "N" && (
              <Typography variant="caption" className={this.props.classes.tablepadding} gutterBottom>
                STEP 2 OF 4
              </Typography>
            )}
          </Grid>
          <Grid xs={12} item>
            <Divider className={this.props.classes.tablepadding} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" style={{ display: "inline-block", paddingBottom: "1rem", paddingTop: "1rem" }}>
              We're sorry to see you go. Please let us know why you're deciding to leave.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              style={{ display: "inline-block", fontSize: ".75rem", fontStyle: "italic", paddingBottom: "1rem" }}
            >
              Please select 1 reason
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body2"
              style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold" }}
              gutterBottom
            >
              Relocation
            </Typography>
            <Grid item xs={12}>
              <Divider className={this.props.classes.tablepadding} />
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: "1rem" }}>
              <RadioGroup
                name="reasonSelect"
                value={this.state.selectedReason.toString()}
                className="radioGroup"
                onChange={this.handleOptionChange}
                style={{ display: "inline-block", width: "100%" }}
              >
                {relocationReasonsList.map(item => {
                  return (
                    <FormControlLabel
                      key={item.reasonId}
                      value={item.reasonId.toString()}
                      control={<Radio color="primary" />}
                      label={item.description}
                      color="primary"
                      classes={{ root: this.props.classes.groupItem }}
                      style={{ width: "48%", paddingBottom: ".5rem", paddingTop: ".5rem" }}
                    />
                  );
                })}
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body2"
              style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold" }}
              gutterBottom
            >
              Apartment Needs
            </Typography>
            <Grid item xs={12}>
              <Divider className={this.props.classes.tablepadding} />
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: "1rem" }}>
              <RadioGroup
                name="reasonSelect"
                value={this.state.selectedReason.toString()}
                className="radioGroup"
                onChange={this.handleOptionChange}
                style={{ display: "inline-block", width: "100%" }}
              >
                {apartmentReasonsList.map(item => {
                  return (
                    <FormControlLabel
                      key={item.reasonId}
                      value={item.reasonId.toString()}
                      control={<Radio color="primary" />}
                      label={item.description}
                      color="primary"
                      classes={{ root: this.props.classes.groupItem }}
                      style={{ width: "48%", paddingBottom: ".5rem", paddingTop: ".5rem" }}
                    />
                  );
                })}
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body2"
              style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold" }}
              gutterBottom
            >
              Financial Reasons
            </Typography>
            <Grid item xs={12}>
              <Divider className={this.props.classes.tablepadding} />
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: "1rem" }}>
              <RadioGroup
                name="reasonSelect"
                value={this.state.selectedReason.toString()}
                className="radioGroup"
                onChange={this.handleOptionChange}
                style={{ display: "inline-block", width: "100%" }}
              >
                {financialReasonsList.map(item => {
                  return (
                    <FormControlLabel
                      key={item.reasonId}
                      value={item.reasonId.toString()}
                      control={<Radio color="primary" />}
                      label={item.description}
                      color="primary"
                      classes={{ root: this.props.classes.groupItem }}
                      style={{ width: "48%", paddingRight: "2.5rem", paddingBottom: ".5rem", paddingTop: ".5rem" }}
                    />
                  );
                })}
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold" }} gutterBottom>
              Other
            </Typography>
            <Grid item xs={12}>
              <Divider className={this.props.classes.tablepadding} />
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={"auto"} style={{ marginTop: "1rem" }}>
                <div style={{ marginLeft: "-14px" }}>
                  <Radio
                    value={this.state.otherReason}
                    checked={this.state.otherCheck === true}
                    onChange={e => this.handleOtherChange(e)}
                    color="primary"
                  />
                </div>
              </Grid>
              <Grid item xs={11} style={{ marginTop: "1rem" }}>
                <TextField
                  id="content"
                  name="content"
                  value={this.state.otherReason}
                  //helperText={touched.content ? errors.content : ""}
                  //error={touched.content && Boolean(errors.content)}
                  fullWidth
                  onChange={e => this.handleOtherChange(e)}
                  className={classes.textField}
                  multiline
                  inputProps={{ maxLength: 500 }}
                  disabled={this.state.otherCheck === false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          {this.state.otherCheck && (
            <Grid style={{ textAlign: "right", marginTop: "1rem" }}>
              <Button
                onClick={this.props.onContinue.bind(this, this.state.selectedReason)}
                color="primary"
                variant="contained"
                autoFocus
                disabled={this.state.otherReason === ""}
              >
                Continue
              </Button>
            </Grid>
          )}
          {!this.state.otherCheck && (
            <Grid style={{ textAlign: "right", marginTop: "1rem" }}>
              <Button
                onClick={this.props.onContinue.bind(this, this.state.selectedReason)}
                color="primary"
                variant="contained"
                autoFocus
                disabled={this.state.selectedReason === 0}
              >
                Continue
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }
}

// const mapStateToProps = ({ servicereqs }: RootState) => ({
//   categories: servicereqs.serviceRequestCategories.flatData
// });

// const mapDispatchToProps = {
//   viewDetails: (item: ServiceRequest) => viewDetails(item)
// };

export default withRouter(
  connect()(withStyles(styles)(ENTVReasons))
  // mapStateToProps,
  // mapDispatchToProps
);
