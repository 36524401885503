import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  Paper,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { InjectedProps } from "@material-ui/core/withMobileDialog";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { UserInfoItem } from "../../userInfo/models";
import { leaveGroupPostRequest } from "../actionsGroup";
import { Group, JoinLeaveGroupDto, RpActivity } from "../models";
import socialstyles from "./styles";

// import { withRouter } from "react-router-dom";
interface PropsFromState {
  user?: UserInfoItem;
}

interface PropsFromDispatch {
  leaveGroup: (request: JoinLeaveGroupDto, group: Group) => any;
  logActivity: typeof logActivity;
}

const styles = combinestyles(socialstyles, globalstyles);

interface MyGroupsProps extends WithStyles<typeof styles> {
  userGroup: Group[];
}

type State = {
  groupToLeave?: Group;
  openRemove: boolean;
};

type AllProps = PropsFromState & PropsFromDispatch & MyGroupsProps & InjectedProps;

class GroupsMy extends React.Component<AllProps, State> {
  state: State = {
    groupToLeave: undefined,
    openRemove: false,
  };

  setCurrentJoinLeaveGroupDto = (groupItem: Group) => {
    this.setState({ groupToLeave: groupItem });
  };

  handleLeaveGroupDialogToggle = (groupItem: Group) => {
    this.setCurrentJoinLeaveGroupDto(groupItem);
    this.setState({ openRemove: !this.state.openRemove });
  };

  handleContinueLeaveGroup = () => {
    const { groupToLeave } = this.state;
    if (!groupToLeave) {
      return;
    }
    const leaveGroupRequest: JoinLeaveGroupDto = {
      groupId: groupToLeave.id,
      userNameId: this.props.user!.nameId,
    };

    this.props.leaveGroup(leaveGroupRequest, groupToLeave);
    this.props.logActivity({
      actcode: "GL",
      note: `Resident left group - ${groupToLeave.name}`,
    });
    this.setState({ openRemove: false });
  };

  handleRemoveClose = () => {
    this.setState({ openRemove: false });
  };

  renderItem(groupItem: Group) {
    return (
      <Grid item xs={12} lg={6} key={groupItem.id}>
        <Grid container spacing={8}>
          <Grid item xs={8}>
            <Typography variant="h4">
              <NavLink className={this.props.classes.link} to={"/groupdetails/" + groupItem.id}>
                {groupItem.name}
              </NavLink>
            </Typography>
            <Typography className={this.props.classes.myGroupSubHead}>{`Last post - ${getLastPost(
              groupItem.lastPostDate
            )}`}</Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Button
              onClick={() => this.handleLeaveGroupDialogToggle(groupItem)}
              variant="outlined"
              size="large"
              color="primary"
              style={{ marginRight: "10px" }}
            >
              Leave
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    this.renderItem = this.renderItem.bind(this);

    return (
      <Paper className={this.props.classes.paper}>
        <Typography variant="h3" style={{ marginBottom: "1rem" }}>
          My Groups
        </Typography>
        <Divider style={{ marginBottom: "1rem" }} />
        <Grid container spacing={24}>
          {this.props.userGroup.map(this.renderItem)}
        </Grid>
        <Dialog
          open={this.state.openRemove}
          onClose={this.handleRemoveClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to leave this group?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleContinueLeaveGroup} color="primary" variant="contained">
              Yes
            </Button>
            <Button onClick={this.handleRemoveClose} color="default" variant="contained" autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
}

const getLastPost = (rawLastPostDate: string | null) => {
  let formattedLastPost = "None";

  if (rawLastPostDate !== undefined && rawLastPostDate !== null) {
    const lastPost = moment(rawLastPostDate).format("LL");
    const lastPostTime = moment(rawLastPostDate).format("LT");

    const today = moment().format("LL");
    const yesterday = moment().subtract(1, "days").format("LL");

    if (lastPost === today) {
      formattedLastPost = "Today " + lastPostTime;
    } else if (lastPost === yesterday) {
      formattedLastPost = "Yesterday " + lastPostTime;
    } else {
      formattedLastPost = lastPost;
    }
  }
  return formattedLastPost;
};

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
});

const mapDispatchToProps: PropsFromDispatch = {
  leaveGroup: (request: JoinLeaveGroupDto, group: Group) => leaveGroupPostRequest({ request: request, group: group }),
  logActivity: (act: RpActivity) => logActivity(act),
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(GroupsMy));
