import { Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";

import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import entvstyles from "../styles";
import ENTVHeader from "../components/entv-header";

const styles = combinestyles(entvstyles, globalstyles);

export interface IENTVContactProps extends WithStyles<typeof styles> {}
type State = {};

class ENTVContact extends React.Component<IENTVContactProps> {
  readonly state: State = {};

  render() {
    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <ENTVHeader />
          <Paper className={this.props.classes.paper}>
            <Typography>
              We have record of your request to vacate. Feel free to reach out to the Community team with any questions.
            </Typography>
          </Paper>
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(ENTVContact);
