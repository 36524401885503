import { createAsyncAction } from "typesafe-actions";

import { ApplicationCheckList } from "./models";

const APPLICANT_CHECKLIST_FETCH_REQUEST = "applicant/checklist/FETCH_REQUEST";
const APPLICANT_CHECKLIST_FETCH_SUCCESS = "applicant/checklist/FETCH_SUCCESS";
const APPLICANT_CHECKLIST_FETCH_ERROR = "applicant/checklist/FETCH_ERROR";

export const fetchRequestAsync = createAsyncAction(
  APPLICANT_CHECKLIST_FETCH_REQUEST,
  APPLICANT_CHECKLIST_FETCH_SUCCESS,
  APPLICANT_CHECKLIST_FETCH_ERROR
)<string, ApplicationCheckList, Error>();
