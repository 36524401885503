import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    background: {
      default: "transparent",
    },
    primary: {
      main: "#3d90d4",
    },
  },
  typography: {
    useNextVariants: true,
    h1: {
      fontSize: "2rem" /*32px*/,
      fontWeight: 100,
    },
    h2: {
      fontSize: "1.5rem" /*24px*/,
      fontWeight: 300,
    },
    h3: {
      fontSize: "1.25rem" /*20px*/,
      fontWeight: 300,
      lineHeight: 1.4,
      color: "rgba(57, 75, 118, 1)",
    },
    h4: {
      fontSize: "1.125rem" /*18px*/,
      fontWeight: 500,
    },
    h5: {
      fontSize: "1rem" /*16px*/,
      fontWeight: 500,
    },
    h6: {
      fontSize: "0.75rem" /*12px*/,
      fontWeight: 300,
      textTransform: "uppercase",
    },
    body1: {
      fontSize: "1rem" /*16px*/,
      fontWeight: 300,
    },
    body2: {
      fontSize: "0.875rem" /*14px*/,
      fontWeight: 300,
    },
    title: {
      fontSize: "2rem" /*32px*/,
      fontWeight: 300,
      color: "rgba(57, 75, 118, 1)",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 2,
        textTransform: "none",
      },
    },
    MuiDialogTitle: {
      root: {
        fontWeight: 100,
      },
    },
    // By default mui adds some tb margin to the expanded panel,
    // but we want them to stay connected.
    MuiExpansionPanel: {
      root: {
        "&$expanded + &": {
          "&:before": {
            display: "block",
          },
        },
      },
      expanded: {
        margin: 0,
        "&:before": {
          opacity: 1,
        },
      },
    },
  },
});

export default theme;
