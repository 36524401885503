import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { loadPaymentByNameId, postAutoPaymentCreate, postPaymentCreate } from "../../utils/api";
import { showModalAction } from "../errorhandling/actions";
import * as actions from "./actions";

function* handleFetchPaymentByResident(action: ReturnType<typeof actions.paymentFetchRequest>): any {
  try {
    const nameid = action.payload;

    const response = yield call(loadPaymentByNameId, nameid);

    if (response.error) {
      yield put(actions.paymentFetchError(response.error));
    } else {
      yield put(actions.paymentFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.paymentFetchError(err.stack!));
    } else {
      yield put(actions.paymentFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleInsertPaymentProcess(action: ReturnType<typeof actions.insertPaymentRequest>): any {
  try {
    const payload = action.payload;
    const response = yield call(postPaymentCreate, payload);

    if (response.error) {
      yield put(actions.insertPaymentError(response.error));
    } else {
      yield put(actions.insertPaymentSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.insertPaymentError(err.stack!));
    } else {
      yield put(actions.insertPaymentError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleInsertAutoPaymentProcess(action: ReturnType<typeof actions.insertPaymentRequest>): any {
  try {
    const payload = action.payload;
    const response = yield call(postAutoPaymentCreate, payload);

    if (response.error) {
      yield put(actions.insertPaymentError(response.error));
    } else {
      yield put(actions.insertPaymentSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.insertPaymentError(err.stack!));
    } else {
      yield put(actions.insertPaymentError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* watchFetchRequest() {
  yield takeEvery(getType(actions.paymentFetchRequest), handleFetchPaymentByResident);
  yield takeEvery(getType(actions.insertPaymentRequest), handleInsertPaymentProcess);
  yield takeEvery(getType(actions.insertAutoPaymentRequest), handleInsertAutoPaymentProcess);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* renewalSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default renewalSaga;
