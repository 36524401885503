import * as React from "react";
import { Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import ResidentRelationsForm from "./ResidentRelationsForm";
import contactstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(contactstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {}

const ContactResidentRelations: React.SFC<Props> = props => {
  return (
    <Paper className={props.classes.paper}>
      <Typography variant="h2" gutterBottom>
        Resident Relations
      </Typography>
      <Typography gutterBottom>
        Need to contact our corporate offices? We can assist current residents by directing your kudos or concerns to
        the appropriate management team. Our management teams will work to respond to you within two business days.
      </Typography>
      <Typography gutterBottom style={{ marginBottom: "1rem" }}>
        If you have an emergency maintenance request, please call your leasing office, and follow the prompts to page
        the emergency maintenance team.
      </Typography>
      <ResidentRelationsForm />
    </Paper>
  );
};

export default withStyles(styles)(ContactResidentRelations);
