import * as React from "react";
import { WithStyles, withStyles, Grid, Typography, Hidden, Card } from "@material-ui/core";
import { AccountActivity, RequestPaymentPlan } from "../features/formerresident/models";
import {
  accountActivityRequest,
  downloadDocumentRequest,
  resetDownloadError,
  paymentPlanRequest
} from "../features/formerresident/actions";
import { RootState } from "../store/root-reducer";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { compareDates } from "../features/shared/utils/utils";
import * as constants from "../features/formerresident/constants";
import { UserInfo } from "../features/myprofile/models";
import statementstyles from "../features/mystatement/components/styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";
import { statementFetchRequest } from "../features/mystatement/actions";
import { Statement } from "../features/mystatement/models";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BalanceDueTile from "../features/formerresident/components/BalanceDueTile";
import AccountActivityTile from "../features/formerresident/components/AccountActivityTile";
import SodaTile from "../features/formerresident/components/SodaTile";
import FinalStatementTile from "../features/formerresident/components/FinalStatementTile";
import MoveOutInspectionTile from "../features/formerresident/components/MoveOutInspectionTile";
import OfficeHours from "../features/shared/components/OfficeHours";
import MoveoutResources from "../features/formerresident/components/MoveoutResources";
import PaymentPlanTile from "../features/formerresident/components/PaymentPlanTile";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import { UserInfoItem } from "../features/userInfo/models";
import moment from "moment";

const styles = combinestyles(statementstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {}

interface PropsFromDispatch {
  statementFetchRequest: typeof statementFetchRequest;
  fetchAccountActivity: typeof accountActivityRequest;
  downloadDocumentRequest: typeof downloadDocumentRequest;
  resetDownloadError: typeof resetDownloadError;
  paymentPlanRequest: typeof paymentPlanRequest;
}

interface PropsFromState {
  user: UserInfoItem;
  userInfo?: UserInfo;
  activityList: AccountActivity[];
  data?: Statement;
  downloadError: boolean;
}

type AllProps = Props & PropsFromDispatch & PropsFromState & RouteComponentProps;

class FormerResidentDashboard extends React.Component<AllProps> {
  componentDidMount() {
    this.props.resetDownloadError();
    let nameId = this.props.user.nameId;
    this.props.statementFetchRequest({ nameId: nameId, numStatements: 1 });
    this.props.fetchAccountActivity(nameId);
    window.scrollTo(0, 0);
  }

  downloadDocument = (documentId: number, fileName: string) => {
    let nameId = this.props.user.nameId;

    var ua = navigator.userAgent; // /*@cc_on!@*/false || !!document.documentMode;
    var isIE = ua.indexOf("MSIE") > -1 || ua.indexOf("Trident/") > -1;
    let newWindow = null;
    if (!isIE) {
      newWindow = window.open();
    }

    this.props.downloadDocumentRequest({
      nameId: nameId,
      documentId: documentId,
      fileName: fileName,
      newWindow: newWindow
    });
  };

  getActivtiyList = (): AccountActivity[] => {
    let activityList = this.props.activityList;
    if (this.props.user) {
      let vacateDate = this.props.user.vacateDate;
      activityList = activityList.filter(a => a.uploadDate >= vacateDate);
      let moveoutActivity: AccountActivity = {
        id: -1,
        uploadDate: vacateDate,
        description: "Move Out",
        name: "Move Out"
      };
      activityList.push(moveoutActivity);
    }

    activityList = activityList.sort(function(a, b) {
      return compareDates(a.uploadDate, b.uploadDate);
    });
    let soda = activityList.filter(a => a.description === "Statement of Deposit Account" || a.description === "SODA");

    if (soda.length > 0) {
      for (let i = 0; i < activityList.length; i++) {
        if (activityList[i].description.indexOf("(current)") > -1) {
          break;
        } else if (activityList[i].description === "Statement of Deposit Account") {
          activityList[i].description = "Statement of Deposit Account (current)";
          break;
        } else if (activityList[i].description === "SODA") {
          activityList[i].description = "SODA (current)";
          break;
        }
      }
    }

    return activityList;
  };

  getActivityIcon = (activity: string): string => {
    if (activity.toLowerCase().indexOf("letter") !== -1) {
      return constants.MAIL;
    }

    return constants.DOC;
  };

  goToPayments = () => {
    this.props.history.push("/payment");
  };

  downloadSoda = () => {
    let nameId = this.props.user.nameId;
    let activityList = this.props.activityList;

    activityList = activityList.sort(function(a, b) {
      return compareDates(a.uploadDate, b.uploadDate);
    });

    var ua = navigator.userAgent; // /*@cc_on!@*/false || !!document.documentMode;
    var isIE = ua.indexOf("MSIE") > -1 || ua.indexOf("Trident/") > -1;
    let newWindow = null;
    if (!isIE) {
      newWindow = window.open();
    }

    for (let i = 0; i < activityList.length; i++) {
      if (activityList[i].description === "Statement of Deposit Account (current)") {
        this.props.downloadDocumentRequest({
          nameId: nameId,
          documentId: activityList[i].id,
          fileName: activityList[i].name,
          newWindow: newWindow
        });
        break;
      } else if (activityList[i].description === "Statement of Deposit Account") {
        this.props.downloadDocumentRequest({
          nameId: nameId,
          documentId: activityList[i].id,
          fileName: activityList[i].name,
          newWindow: newWindow
        });
        break;
      } else if (activityList[i].description === "SODA (current)") {
        this.props.downloadDocumentRequest({
          nameId: nameId,
          documentId: activityList[i].id,
          fileName: activityList[i].name,
          newWindow: newWindow
        });
        break;
      }
    }
  };

  goToStatements = () => {
    this.props.history.push("/myaccount/mystatement");
  };

  goToRentWithEquity = () => {
    this.props.history.push("/rentwithequity");
  };

  goToInspection = (url: string) => {
    window.open(url, "_blank");
  };

  render() {
    let activityList: AccountActivity[] = this.getActivtiyList();
    const paymentPlan = this.props.user.propertyInfo.paymentPlan;
    let balance = 0;
    let sodaPrelim = "";
    let vacateDate = undefined;
    let vacateDate25 = undefined;
    let now = moment();
    let moInspectUrl = this.props.user.moInspectUrl;
    if (this.props.data !== undefined) {
      balance = this.props.data.statementGenerals.openBalance;
      sodaPrelim = this.props.data.statementGenerals.sodaPrelim;
      vacateDate = this.props.data.statementGenerals.vacateDate;
      vacateDate25 = moment(this.props.data.statementGenerals.vacateDate).add(25, "days");
    }

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Grid container alignItems="center" style={{ width: "100%" }}>
            <Grid item xs>
              <Typography variant="h1" className={this.props.classes.header}>
                My Dashboard
              </Typography>
            </Grid>
          </Grid>
          <BalanceDueTile
            balance={balance}
            sodaPrelim={sodaPrelim}
            vacateDate={vacateDate}
            classes={this.props.classes}
            goToPayments={this.goToPayments}
          />

          {sodaPrelim === "F" && <SodaTile downloadSoda={this.downloadSoda} classes={this.props.classes} />}
          {sodaPrelim === "F" && (
            <FinalStatementTile goToStatements={this.goToStatements} classes={this.props.classes} />
          )}

          {paymentPlan && balance > 500 && vacateDate25 !== undefined && now > vacateDate25 && (
            <PaymentPlanTile
              balance={balance}
              classes={this.props.classes}
              userNameId={this.props.user.nameId}
              paymentPlanRequest={this.props.paymentPlanRequest}
            />
          )}
          {moInspectUrl.length > 5 && (
            <MoveOutInspectionTile
              moInspectionUrl={moInspectUrl}
              classes={this.props.classes}
              goToInspection={this.goToInspection}
            />
          )}

          <AccountActivityTile
            activityList={activityList}
            downloadDocument={this.downloadDocument}
            getActivityIcon={this.getActivityIcon}
            classes={this.props.classes}
            user={this.props.user}
          />
          <Hidden mdUp>
            <MoveoutResources goToRentWithEquity={this.goToRentWithEquity} />
          </Hidden>
        </main>
        <Hidden smDown>
          <div style={{ width: "320px", marginTop: "240px" }}>
            <Card style={{ padding: "1rem" }}>
              <MoveoutResources goToRentWithEquity={this.goToRentWithEquity} />
              <br />
              <hr />
              <br />
              <OfficeHours />
            </Card>
          </div>
        </Hidden>
      </div>
    );
  }
}

const mapStateToProps = ({ selectAccount, profile, formerresident, statement }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  userInfo: profile.userInfo,
  activityList: formerresident.accountActivity,
  data: statement.data,
  downloadError: formerresident.downloadError
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  statementFetchRequest: (payload: { nameId: string; numStatements: number }) =>
    dispatch(statementFetchRequest(payload)),
  fetchAccountActivity: (payload: string) => dispatch(accountActivityRequest(payload)),
  downloadDocumentRequest: (payload: {
    nameId: string;
    documentId: number;
    fileName: string;
    newWindow: Window | null;
  }) => dispatch(downloadDocumentRequest(payload)),
  resetDownloadError: () => dispatch(resetDownloadError()),
  paymentPlanRequest: (payload: RequestPaymentPlan) => dispatch(paymentPlanRequest(payload))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(FormerResidentDashboard))
);
