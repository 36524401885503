import { Avatar, Button, Grid, ListItem, Typography, WithStyles, withStyles } from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import { GenerateCloudinaryThumbnailUrl } from "../../../utils/cloudinaryImageFormatter";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import SharedImagesConstants from "../../shared/constants/sharedImages";
import { UserInfoItem } from "../../userInfo/models";
import { hideCommentRequest } from "../actions";
import { Comment, UserInfo } from "../models";
import socialstyles from "./styles";

const styles = combinestyles(socialstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  comment: Comment;
  groupId: number;
}

interface PropsFromState {
  user?: UserInfoItem;
  userInfo: UserInfo;
}

interface PropsFromDispatch {
  hideCommentRequest: typeof hideCommentRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

moment.updateLocale("en", {
  relativeTime: {
    past: "%s ago",
    s: "A few seconds",
    m: "1 Minute",
    mm: "%d Minutes",
    h: "1 Hour",
    hh: "%d Hours"
  }
});
moment.relativeTimeThreshold("s", 60);
moment.relativeTimeThreshold("m", 60);
moment.relativeTimeThreshold("h", 24);

class CommentComponent extends React.Component<AllProps> {
  handleHideComment = (commentId: number) => {
    const payload = {
      authorNameId: this.props.user!.nameId,
      commentId: commentId,
      groupId: this.props.groupId
    };
    this.props.hideCommentRequest(payload);
  };

  render() {
    const { comment, classes } = this.props;
    const avatar = this.props.comment.author.avatar ? (
      <Avatar src={GenerateCloudinaryThumbnailUrl(this.props.comment.author.avatar)} />
    ) : (
      <Avatar src={SharedImagesConstants.EMPTYAVATAR} />
    );
    const datePosted =
      moment().diff(comment.datePosted, "hours") < 23 ? (
        <Moment fromNow>{moment.utc(comment.datePosted).toString()}</Moment>
      ) : (
        <Moment format="MM/DD/YYYY">{comment.datePosted.toString()}</Moment>
      );
    return (
      <ListItem className={classes.commentItem}>
        <Grid container spacing={8} justify="space-between" alignItems="center" wrap="nowrap">
          <Grid item>{avatar}</Grid>
          <Grid item xs>
            <Typography style={{ fontSize: "0.875rem", overflowWrap: "break-word", fontWeight: 500 }}>
              {comment.author.name}
            </Typography>
            <Typography
              variant="body1"
              style={
                comment.isItalic ? { overflowWrap: "break-word", fontStyle: "italic" } : { overflowWrap: "break-word" }
              }
            >
              {comment.message}
            </Typography>
          </Grid>

          {this.props.user && this.props.comment.author.id === this.props.user.socialUserId && !comment.isItalic && (
            <Grid item>
              <Button onClick={() => this.handleHideComment(comment.id)} color="secondary" className={classes.button}>
                Remove
              </Button>
            </Grid>
          )}
          <Grid item>
            <Typography className={classes.commentDate}>{datePosted}</Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  }
}

const mapStateToProps = ({ selectAccount, social }: RootState) => ({
  user: getActiveUserInfo(selectAccount),
  userInfo: social.userInfo
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  hideCommentRequest: (payload: { commentId: number; authorNameId: string }) => dispatch(hideCommentRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CommentComponent));
