import { Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import { LocalShipping, MonetizationOnOutlined, SettingsBackupRestore } from "@material-ui/icons";
import moment from "moment";
import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import globalstyles from "../../../../global-styles";
import combinestyles from "../../../../utils/combinestyles";
import { thousands_separators } from "../../../shared/utils/utils";
import { NoticeToVacate } from "../../models";
import entvstyles from "../../styles";

const styles = combinestyles(entvstyles, globalstyles);

export interface SufficientMTMProps extends WithStyles<typeof styles> {
  noticeInfo?: NoticeToVacate;
}

type State = {};

type AllProps = SufficientMTMProps & RouteComponentProps;

class SufficientMTM extends React.Component<AllProps, State> {
  state: Readonly<State> = {};

  render() {
    var leaseEnd = moment(new Date(this.props.noticeInfo!.leaseEnd)).add(1, "days");
    const title = this.props.noticeInfo!.copyLanguage.find(e => {
      return e.languageId === "A3";
    });
    const monthCharges = this.props.noticeInfo!.leaseCharges;
    const mtmCharges = this.props.noticeInfo!.leaseChargesMTM;
    let monthlyCharges = [];
    let MTMCharges = [];
    if (monthCharges) {
      for (let i = 0; i < monthCharges.length; i++) {
        monthlyCharges.push(
          <Grid container className={this.props.classes.grouping}>
            <Grid xs={6} item style={{ marginBottom: "1rem" }}>
              <Typography gutterBottom style={{ display: "inline-block" }}>
                {monthCharges[i].description}
              </Typography>
            </Grid>
            <Grid xs={6} item style={{ textAlign: "right", marginBottom: "1rem" }}>
              <Typography className={this.props.classes.chargeslabel}>
                ${thousands_separators(monthCharges[i].amount.toFixed(2))}
              </Typography>
            </Grid>
          </Grid>
        );
      }
    }
    if (mtmCharges) {
      for (let i = 0; i < mtmCharges.length; i++) {
        MTMCharges.push(
          <Grid container className={this.props.classes.grouping}>
            <Grid xs={6} item style={{ marginBottom: "1rem" }}>
              <Typography gutterBottom style={{ display: "inline-block" }}>
                {mtmCharges[i].description}
              </Typography>
            </Grid>
            <Grid xs={6} item style={{ textAlign: "right", marginBottom: "1rem" }}>
              <Typography className={this.props.classes.chargeslabel}>
                ${thousands_separators(mtmCharges[i].amount.toFixed(2))}
              </Typography>
            </Grid>
          </Grid>
        );
      }
    }
    return (
      <form>
        <Grid container className={this.props.classes.grouping}>
          <Grid xs={12} item>
            <Typography
              className={this.props.classes.tablepadding}
              style={{ paddingTop: "1rem", paddingBottom: "1.5rem" }}
            >
              {title!.copyLanguage}
            </Typography>
          </Grid>
          <Grid container className={this.props.classes.grouping}>
            <Grid xs={4} item>
              <Grid container className={this.props.classes.grouping}>
                <Grid xs={12} item>
                  {" "}
                  <LocalShipping color="disabled" style={{ verticalAlign: "middle" }} />
                  <Typography
                    style={{
                      display: "inline-block",
                      fontSize: ".875rem",
                      paddingBottom: "1rem",
                      paddingLeft: ".5rem"
                    }}
                  >
                    Vacate Date <Moment format="MM/DD/YY" date={this.props.noticeInfo!.vacateDate} />
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <SettingsBackupRestore color="disabled" style={{ verticalAlign: "middle" }} />
                  <Typography
                    style={{
                      display: "inline-block",
                      fontSize: ".875rem",
                      paddingBottom: ".5rem",
                      paddingLeft: ".5rem"
                    }}
                  >
                    Lease End {this.props.noticeInfo!.leaseEnd}
                  </Typography>
                </Grid>
                {MTMCharges.length > 0 && (
                  <Grid xs={12} item>
                    <MonetizationOnOutlined color="disabled" style={{ verticalAlign: "top" }} />
                    <Typography
                      style={{
                        paddingBottom: ".5rem",
                        paddingLeft: ".5rem"
                      }}
                      className={this.props.classes.chargesdesclabel}
                    >
                      Month-to-Month Rent:
                      <br /> ${thousands_separators(this.props.noticeInfo!.mtmProratedRent.toFixed(2))}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid xs={8} item>
              <Grid container className={this.props.classes.grouping}>
                <Grid xs={6} item>
                  <Typography
                    style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold", marginBottom: "1rem" }}
                  >
                    Charges
                  </Typography>
                </Grid>
                <Grid xs={6} item style={{ textAlign: "right" }}>
                  <Typography
                    style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold", marginBottom: "1rem" }}
                  >
                    Amount
                  </Typography>
                </Grid>
                <Grid xs={9} item>
                  <Typography style={{ marginBottom: "1rem" }} className={this.props.classes.chargesdesclabel}>
                    Monthly Apartment Rent{" "}
                    <Moment
                      format="MM/DD/YY"
                      date={
                        new Date(
                          new Date(this.props.noticeInfo!.leaseEnd).getFullYear(),
                          new Date(this.props.noticeInfo!.leaseEnd).getMonth(),
                          1
                        )
                      }
                    />{" "}
                    - <Moment format="MM/DD/YY" date={this.props.noticeInfo!.leaseEnd} />
                  </Typography>
                </Grid>
                <Grid xs={3} item style={{ textAlign: "right" }}>
                  <Typography
                    style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold", color: "green" }}
                  >
                    ${thousands_separators(this.props.noticeInfo!.proratedRent.toFixed(2))}
                  </Typography>
                </Grid>

                {monthlyCharges.length > 0 && (
                  <Grid container className={this.props.classes.grouping} style={{ marginBottom: "1rem" }}>
                    <Grid xs={12} item>
                      <Typography
                        gutterBottom
                        className={this.props.classes.chargesheadinglabel}
                        style={{ display: "inline-block", marginBottom: "1rem" }}
                      >
                        Recurring Monthly Charges{" "}
                        <Moment
                          format="MM/DD/YY"
                          date={
                            new Date(
                              new Date(this.props.noticeInfo!.leaseEnd).getFullYear(),
                              new Date(this.props.noticeInfo!.leaseEnd).getMonth(),
                              1
                            )
                          }
                        />{" "}
                        - <Moment format="MM/DD/YY" date={this.props.noticeInfo!.leaseEnd} />
                      </Typography>
                    </Grid>
                    <Grid container className={this.props.classes.grouping}>
                      {monthlyCharges}
                    </Grid>
                  </Grid>
                )}

                <Grid xs={9} item>
                  <Typography style={{ marginBottom: "1rem" }} className={this.props.classes.chargesheadinglabel}>
                    Month-to-Month Rent <Moment format="MM/DD/YY" date={leaseEnd} /> -{" "}
                    <Moment format="MM/DD/YY" date={this.props.noticeInfo!.vacateDate} />
                  </Typography>
                </Grid>
                <Grid xs={3} item style={{ textAlign: "right" }}>
                  {MTMCharges.length > 0 && (
                    <Typography
                      style={{
                        display: "inline-block",
                        fontSize: ".875rem",
                        fontWeight: "bold",
                        marginBottom: "1rem",
                        color: "green"
                      }}
                    >
                      ${thousands_separators(this.props.noticeInfo!.mtmProratedRent.toFixed(2))}
                    </Typography>
                  )}
                </Grid>
                {MTMCharges.length === 0 && (
                  <Grid container className={this.props.classes.grouping}>
                    <Grid xs={12} item>
                      <Typography gutterBottom style={{ display: "inline-block", marginBottom: "1rem" }}>
                        Your Month-to-Month rent is unavailable at this time. It's possible that you will be required to
                        pay rent at a higher rate for the days you stay beyond your lease end date.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {MTMCharges.length > 0 && (
                  <Grid container className={this.props.classes.grouping}>
                    <Grid xs={12} item>
                      <Typography gutterBottom style={{ display: "inline-block", marginBottom: "1rem" }}>
                        Recurring Month-to-Month Charges <Moment format="MM/DD/YY" date={leaseEnd} /> -{" "}
                        <Moment format="MM/DD/YY" date={this.props.noticeInfo!.vacateDate} />
                      </Typography>
                    </Grid>
                    <Grid container className={this.props.classes.grouping}>
                      {MTMCharges}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withRouter(connect()(withStyles(styles)(SufficientMTM)));
