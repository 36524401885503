import * as React from "react";

import { Grid, Typography, withStyles, WithStyles } from "@material-ui/core";
import styles from "./styles";
import Moment from "react-moment";

export interface FooterProps extends WithStyles<typeof styles> {}

const privacyUrl = "https://www.equityapartments.com/privacy";
const termsUrl = "https://www.equityapartments.com/terms";
const year = Date.now();

const Footer: React.SFC<FooterProps> = props => {
  return (
    <div className={props.classes.root}>
      <main className={props.classes.content}>
        <Grid
          alignContent="center"
          alignItems="stretch"
          justify="space-between"
          container
          className={props.classes.grid}
          spacing={16}
        >
          <Grid item>
            <img
              alt="Equity Residential Logo"
              src="https://media.equityapartments.com/image/upload/v1447271152/Content/logo.png"
              style={{ maxHeight: "1.25rem" }}
            />
          </Grid>
          <Grid item>
            <Typography inline>
              &copy;
              <Moment date={year} format="YYYY" /> Equity Residential. All Rights Reserved.&nbsp;
            </Typography>
            <a href={privacyUrl} target="_blank" rel="noreferrer">
              <Typography inline>Privacy Policy</Typography>
            </a>
            <Typography inline>&nbsp;|&nbsp;</Typography>
            <a href={termsUrl} target="_blank" rel="noreferrer">
              <Typography inline>Terms and Conditions</Typography>
            </a>
          </Grid>
          <Grid item>
            <img
              alt="house icon"
              src="https://media.equityapartments.com/image/upload/v1452188170/Content/icon-eho.png"
            />
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default withStyles(styles)(Footer);
