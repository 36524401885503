import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";
import * as actions from "./actions";

import { ModalModel } from "./models";

export type ModalActions = ActionType<typeof actions>;

export type ModalState = Readonly<{
  data?: ModalModel;
  errors: boolean;
}>;

const initialState: ModalState = {
  data: undefined,
  errors: false
};

const reducer: Reducer<ModalState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.showModalAction): {
      return {
        ...state,
        data: action.payload.data,
        errors: true
      };
    }
    case getType(actions.hideModalAction): {
      return { ...state, errors: false };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
