import * as React from "react";
import { withStyles, WithStyles } from "@material-ui/core";
import { propertyDocsRequest } from "../features/propertydocuments/actions";

import PropertyDocHeader from "../features/propertydocuments/components/PropertyDocHeader";
import PropertyDocList from "../features/propertydocuments/components/PropertyDocList";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import { RootState } from "../store/root-reducer";
import { PropertyDocumentsItems } from "../features/propertydocuments/models";

import propertydocumentsstyles from "../features/propertydocuments/styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(propertydocumentsstyles, globalstyles);

interface IPropertyDocsProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
}

interface IPageState {}

interface PropsFromState {
  user?: UserInfoItem;
  loading: boolean;
  propertyDocumentsList: PropertyDocumentsItems[];
  errors?: string;
}

interface PropsFromDispatch {
  propertyDocsRequest: typeof propertyDocsRequest;
}

type AllProps = IPropertyDocsProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

class PropertyDocumentsPage extends React.Component<AllProps, IPageState> {
  state: IPageState = {};

  componentDidMount() {
    const rmpropid = this.props.user!.propertyId;
    this.props.propertyDocsRequest(rmpropid);
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <PropertyDocHeader />
          <PropertyDocList />
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ propertydocuments, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  propertyDocumentsList: propertydocuments.propertyDocumentsList.data,
  loading: propertydocuments.propertyDocumentsList.loading,
  errors: propertydocuments.propertyDocumentsList.errors
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  propertyDocsRequest: (rmpropid: string) => dispatch(propertyDocsRequest(rmpropid))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PropertyDocumentsPage)));
