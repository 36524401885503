import { createSelector } from "reselect";
import { SocialGroupUserListState } from "./reducerGroup";
import { SocialState } from "./reducer";
import { DashboardFeedItem, FeedDataFilter, Group } from "./models";

export const getUserGroups = (state: SocialGroupUserListState) => state.dataUser;

export const getSortedUserGroups = createSelector(getUserGroups, (userGroups) => {
  // simple sort
  // return userGroups.sort((n1,n2) => n1 - n2)
  // return userGroups.sort((a, b) => a.description < b.description ? -1 : a.description > b.description ? 1 : 0)

  // dates sort including string to date conversion
  // return userGroups.sort((a, b) => {
  //   return getTime(b.lastPostDate) - getTime(a.lastPostDate); // asc a b  desc b a
  // });

  // dates sort including string to date conversion and secondary sort of strings (names)
  // https://www.sitepoint.com/sophisticated-sorting-in-javascript/
  return userGroups.sort(function (a, b) {
    if (getTime(a.lastPostDate) === getTime(b.lastPostDate)) {
      var x = a.name.toLowerCase(),
        y = b.name.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    }
    return getTime(b.lastPostDate) - getTime(a.lastPostDate); // desc
  });
});

const getTime = (lastPostString: string | null) => {
  if (lastPostString === null) {
    return 0;
  }
  let lastPostDate: any = new Date(lastPostString);
  return lastPostDate;
};

export const getItems = (state: SocialState) => state.data;
export const filterBy = (state: SocialState) => state.filterBy;
export const userGroups = (state: SocialState, socialGroupState: SocialGroupUserListState) => socialGroupState.dataUser;
export const getFilteredFeed = createSelector(
  [getItems, filterBy, userGroups],
  (data: DashboardFeedItem[], filterBy: string, userGroups: Group[]) => {
    switch (filterBy) {
      case "Events":
        return data.filter((t) => t.itemType === FeedDataFilter.EventItem);
      case "For Sale":
        return data.filter((t) => t.itemType === FeedDataFilter.ForSaleItem);
      case "Discussions":
        return data.filter((t) => t.itemType === FeedDataFilter.DiscussionItem);
      case "News":
        return data.filter((t) => t.itemType === FeedDataFilter.NewsItem);
      case "My Groups":
        return data.filter(
          (t) =>
            t.groupId !== undefined && t.groupId !== null && userGroups.filter((g) => g.id === t.groupId).length > 0
        );
      default:
        return data;
    }
  }
);
