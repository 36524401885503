import * as React from "react";
//import { WithStyles, withStyles, TableBody, TableRow, TableCell } from "@material-ui/core";
import { WithStyles, withStyles } from "@material-ui/core";
import styles from "./styles";
import { SvcProvider } from "../models";
import SvcProviderCat from "./SvcProviderCat";

interface Props extends WithStyles<typeof styles> {
  serviceProviders: SvcProvider[];
}

class SvcProviderList extends React.Component<Props> {
  render() {
    const { serviceProviders } = this.props;

    return <SvcProviderCat serviceProviders={serviceProviders} />;
  }
}

export default withStyles(styles)(SvcProviderList);
