import { Button, Card, Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { categoriesFetchRequest, selectAmenityToReserve } from "../actions";
import { AmenityReservation, OfficeHours, PropertyAmenityInfo, ReservableAmenity } from "../models";
import rentableitemstyles from "../styles";

const styles = combinestyles(rentableitemstyles, globalstyles);

export interface PropsFromState {
  user?: UserInfoItem;
  propAmenityList: ReservableAmenity[];
}

interface PropsFromDispatch {
  categoriesFetchRequest: typeof categoriesFetchRequest;
  selectAmenity: typeof selectAmenityToReserve;
}

interface AmenityReservationListProps extends WithStyles<typeof styles> {}

type State = {
  Id: number;
  AmenityGroup?: number;
  Icon: string;
  Name: string;
  Description: string;
  Rules: string;
  DueAtEvent?: number;
  DueAtReserve?: number;
  RefundableFee?: number;
  DaysInAdvance?: number;
  CancellationPolicy: string;
  TimeLimitHours?: number;
  TimeLimitMinutes: number;
  ExistingReservations: AmenityReservation[];
  HoursOfOperation: OfficeHours[];
};

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps & AmenityReservationListProps;

class AmenityList extends React.Component<AllProps, State> {
  state: State = {
    Id: 0,
    AmenityGroup: 0,
    Icon: "",
    Name: "",
    Description: "",
    Rules: "",
    DueAtEvent: 0,
    DueAtReserve: 0,
    RefundableFee: 0,
    DaysInAdvance: 0,
    CancellationPolicy: "",
    TimeLimitHours: 0,
    TimeLimitMinutes: 0,
    ExistingReservations: [],
    HoursOfOperation: []
  };

  handleSelectNewReservation = (amenity: ReservableAmenity) => {
    this.props.selectAmenity(amenity);
    this.props.history.push("/myaccount/amenity/AmenityNewForm");
  };

  handleFilter = (sharedAmenityArray: PropertyAmenityInfo[]) => {
    let filteredArray = sharedAmenityArray.filter(
      (ele, ind) => ind === sharedAmenityArray.findIndex(elem => elem.rmPropId === ele.rmPropId)
    );
    return filteredArray;
  };

  renderItem(amenity: ReservableAmenity) {
    return (
      <Grid item xs={6} sm={4} key={amenity.id}>
        <Card className={this.props.classes.card} style={{ minHeight: "14rem" }}>
          <Grid container direction="row" justify="space-around" spacing={16}>
            <Grid item className={this.props.classes.iconRow}>
              <img
                alt={`${amenity.name}`}
                src={this.GetAmenityImageUrl(amenity.amenityGroup)}
                className={this.props.classes.centerElement}
              />
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item className={this.props.classes.amenityRow}>
              <Typography variant="h5" style={{ textAlign: "center" }}>
                {amenity.name}
              </Typography>
              {Number(amenity.dueAtReserve) > 0 && (
                <Typography style={{ textAlign: "center" }}>${amenity.dueAtReserve} Due at Reservation </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container direction="row" justify="center" spacing={16}>
            <Grid item className={this.props.classes.buttonRow}>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                style={{ marginRight: "10px", marginBottom: "10px", marginTop: "10px" }}
                onClick={() => this.handleSelectNewReservation(amenity)}
              >
                Reserve
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }

  render() {
    const { propAmenityList } = this.props;
    let icon = "https://media.equityapartments.com/image/upload/Content/portal/myaccount/map.svg";
    let sharedAmenityArray: PropertyAmenityInfo[] = [];
    for (let i = 0; i < propAmenityList.length; i++) {
      sharedAmenityArray.push(propAmenityList[i].sharedAmenityAddress);
    }
    let filteredSharedAmenityArray = this.handleFilter(sharedAmenityArray);
    this.renderItem = this.renderItem.bind(this);
    let showMyProperty = propAmenityList.filter(x => x.rmPropId === this.props.user!.propertyId).length;
    return (
      <Grid>
        <Grid container style={{ marginBottom: "1rem" }}>
          {showMyProperty !== 0 && (
            <Card className={this.props.classes.card} style={{ minHeight: "14rem", minWidth: "44rem" }}>
              <Typography variant="h3" style={{ textAlign: "left", marginBottom: "1rem" }}>
                In My Building
              </Typography>
              <Grid container spacing={16} className={this.props.classes.gridContainer}>
                {propAmenityList.filter(x => x.rmPropId === this.props.user!.propertyId).map(this.renderItem)}
              </Grid>
            </Card>
          )}
        </Grid>
        {filteredSharedAmenityArray
          .filter(x => Number(x.propertyMilesAway) > 0)
          .sort((a, b) => (Number(a.propertyMilesAway) > Number(b.propertyMilesAway) ? 1 : -1))
          .map(sharedProperty => (
            <Grid container style={{ marginBottom: "1rem" }}>
              <Card className={this.props.classes.card} style={{ minHeight: "14rem", minWidth: "44rem" }}>
                <Grid container spacing={16} className={this.props.classes.gridContainer}>
                  <Grid
                    item
                    xs={8}
                    style={{
                      maxHeight: "2rem",
                      maxWidth: "12rem",
                      paddingTop: ".5rem",
                      paddingLeft: ".5rem",
                      paddingRight: ".5rem",
                      paddingBottom: "0px"
                    }}
                  >
                    <Typography variant="h3" style={{ textAlign: "left" }}>
                      {sharedProperty.propertyName}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ padding: 0, maxHeight: "2rem" }}>
                    <Typography
                      variant="h6"
                      style={{ textAlign: "left", fontStyle: "italic", marginTop: "15px", fontWeight: 300 }}
                    >
                      {sharedProperty.propertyMilesAway} Miles Away
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "right", maxHeight: "2rem" }}>
                    <Button
                      variant="outlined"
                      size="medium"
                      color="primary"
                      style={{
                        marginRight: "-16rem",
                        paddingTop: ".5px",
                        paddingBottom: ".5px",
                        paddingRight: "1.5rem"
                      }}
                      onClick={() => {
                        window.open(sharedProperty.propertyGoogleMapUrl, "_blank");
                      }}
                    >
                      <img
                        style={{ maxWidth: "2rem", marginRight: "1px" }}
                        alt={`${sharedProperty.shortPropertyName}`}
                        src={icon}
                      />{" "}
                      Map
                    </Button>
                  </Grid>
                  <Grid item xs={12} style={{ maxHeight: "2rem" }}>
                    <Typography
                      variant="h5"
                      style={{ textAlign: "left", marginBottom: "1rem", fontSize: "15px", fontWeight: 300 }}
                    >
                      {sharedProperty.propertyAddress1} {sharedProperty.propertyCity} {sharedProperty.propertyState}{" "}
                      {sharedProperty.propertyZipcode}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={16} className={this.props.classes.gridContainer}>
                  {propAmenityList.filter(x => x.rmPropId === sharedProperty.rmPropId).map(this.renderItem)}
                </Grid>
              </Card>
            </Grid>
          ))}
      </Grid>
    );
  }

  private GetAmenityImageUrl = (amenityGrpId?: number) => {
    let res = "";
    if (amenityGrpId === 1) {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/amenityreservation/businesscenter.svg`;
    } else if (amenityGrpId === 2) {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/amenityreservation/residentlounge.svg`;
    } else if (amenityGrpId === 3) {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/amenityreservation/outdoorlounge.svg`;
    } else if (amenityGrpId === 4) {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/amenityreservation/grillkitchen.svg`;
    } else if (amenityGrpId === 5) {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/amenityreservation/elevator.svg`;
    } else if (amenityGrpId === 6) {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/amenityreservation/sportscourt.svg`;
    } else if (amenityGrpId === 7) {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/amenityreservation/guestparking.svg`;
    } else if (amenityGrpId === 8) {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/amenityreservation/guestsuite.svg`;
    } else if (amenityGrpId === 9) {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/amenityreservation/tanningbed.svg`;
    } else if (amenityGrpId === 10) {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/amenityreservation/gaming.svg`;
    } else if (amenityGrpId === 11) {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/amenityreservation/pool.svg`;
    } else if (amenityGrpId === 12) {
      res = `https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/amenityreservation/mediaroom.svg`;
    }

    return res;
  };
}

const mapStateToProps = ({ amenity, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  propAmenityList: amenity.reservableAmenityList.data
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  categoriesFetchRequest: (propid: string) => dispatch(categoriesFetchRequest(propid)),
  selectAmenity: (amenity: ReservableAmenity) => dispatch(selectAmenityToReserve(amenity))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AmenityList)));
