import { Grid, Paper, Typography } from "@material-ui/core";
import * as React from "react";

import * as constants from "../constants";
import { DigitalDocument } from "../models";
import Moment from "react-moment";

interface Props {
  documentList: DigitalDocument[];
  downloadDocument: any;
  getDocumentIcon: any;
  classes: any;
}

type AllProps = Props;

class DigitalDocumentTile extends React.Component<AllProps> {
  getStyle = (document: string) => {
    if (document === "Statement of Deposit Account" || document === "SODA") {
      return { color: "gray" };
    }
    return {};
  };

  render() {
    return (
      <Paper className={this.props.classes.paper}>
        <Grid container direction="row" spacing={16}>
          {this.props.documentList.map((a) => (
            <Grid item key={a.id} style={{ width: "100%" }}>
              <Grid container direction="row" alignItems="stretch" wrap="nowrap" spacing={16}>
                <Grid item>
                  <Typography gutterBottom>
                    <Moment format="MM/DD/YYYY">{new Date(a.uploadDate).toString()}</Moment>
                  </Typography>
                </Grid>
                <Grid item>
                  {a.description === "Move Out" || a.name === "" ? (
                    <img
                      alt={a.description === "Move Out" ? "Move out" : "Mail"}
                      src={a.description === "Move Out" ? constants.MOVE_OUT : constants.MAIL}
                      style={{ width: "20px", height: "20px" }}
                    />
                  ) : a.description.includes("Renters Insurance:") ? (
                    <div style={{ cursor: "pointer" }}>
                      <a href={a.name} target="_new" style={{ textDecoration: "none" }}>
                        <img
                          alt="Document Icon"
                          style={{ width: "20px", height: "20px", cursor: "pointer" }}
                          src={this.props.getDocumentIcon(a.description)}
                        />
                      </a>
                    </div>
                  ) : (
                    <img
                      alt="Document Icon"
                      onClick={() => this.props.downloadDocument(a.id, a.name)}
                      style={{ width: "20px", height: "20px", cursor: "pointer" }}
                      src={this.props.getDocumentIcon(a.description)}
                    />
                  )}
                </Grid>
                <Grid item xs>
                  {a.description.includes("Renters Insurance:") ? (
                    <div style={{ cursor: "pointer" }}>
                      <a href={a.name} target="_new" style={{ textDecoration: "none" }}>
                        <Typography gutterBottom>{a.description.length > 0 ? a.description : a.name}</Typography>
                      </a>
                    </div>
                  ) : (
                    <div style={{ cursor: "pointer" }} onClick={() => this.props.downloadDocument(a.id, a.name)}>
                      <Typography gutterBottom>{a.description.length > 0 ? a.description : a.name}</Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Paper>
    );
  }
}

export default DigitalDocumentTile;
