import * as React from "react";
import { Typography, WithStyles, withStyles } from "@material-ui/core";

import rentableitemstyles from "../styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(rentableitemstyles, globalstyles);

export interface IRentableHeaderProps extends WithStyles<typeof styles> {}

type State = {
  mobileOpen: boolean;
};

class RentableHeader extends React.Component<IRentableHeaderProps> {
  readonly state: State = { mobileOpen: false };

  handleClose = () => {
    this.setState({ openRemove: false });
  };

  render() {
    return (
      <Typography variant="h1" className={this.props.classes.header}>
        Rentable Items
      </Typography>
    );
  }
}

export default withStyles(styles)(RentableHeader);
