import * as React from "react";

import { Typography, WithStyles, withStyles } from "@material-ui/core";

import { RenewalOfferTerm } from "../models";

import eventstyles from "../styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(eventstyles, globalstyles);

interface RenewalLeaseStartProps extends WithStyles<typeof styles> {
  selectedTerm: RenewalOfferTerm;
}

type AllProps = RenewalLeaseStartProps; // & PropsFromState;

class RenewalLeaseStart extends React.Component<AllProps> {
  render() {
    return (
      <Typography variant="h3" style={{ paddingTop: ".5rem" }}>
        Lease Start Date {this.props.selectedTerm.renEffDate}
      </Typography>
    );
  }
}

export default withStyles(styles)(RenewalLeaseStart);
