import * as React from "react";
import { ServiceRequestLocation } from "../models";

import { Paper, Grid, Divider, Typography } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core";

import servicestyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(servicestyles, globalstyles);

interface Props {
  selectLocation: (location: ServiceRequestLocation) => void;
  locations: ServiceRequestLocation[];
}

interface Styles extends WithStyles<typeof styles> {
  theme?: any;
}

type AllProps = Props & Styles;

class ServiceReqFormSelectLocation extends React.Component<AllProps> {
  render() {
    const { locations, selectLocation } = this.props;

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Grid container className={this.props.classes.header}>
            <Grid item xs>
              <Typography variant="h2">New Service Request</Typography>
            </Grid>
          </Grid>
          <Paper className={this.props.classes.paper}>
            <Grid container alignItems="center" spacing={24} className={this.props.classes.listHeader}>
              <Grid item>
                <img
                  alt="Step 4"
                  className="step-icon"
                  src="https://media.equityapartments.com/image/upload/co_rgb:394b76,e_colorize:100,f_auto/v1544721640/Content/portal/uielements/step4.svg"
                />
              </Grid>
              <Grid item>
                <Typography variant="h3">Select a Location</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              {locations.map((loc) => (
                <Grid item xs={12} sm={6} key={loc.roomDescription} onClick={selectLocation.bind(this, loc)}>
                  <Typography variant="h4" style={{ color: "#3d90d4", marginBottom: "25px", cursor: "pointer" }}>
                    {loc.roomDescription}
                  </Typography>
                  <Divider />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(ServiceReqFormSelectLocation);
