import { createAction } from "typesafe-actions";

import { RentableItemRequest, RentableItems, ResidentRentableItems } from "./models";

const RENTABLE_RESIDENT_REQUEST = "rentable/residentlist/FETCH_REQUEST";
const RENTABLE_RESIDENT_SUCCESS = "rentable/residentlist/FETCH_SUCCESS";
const RENTABLE_RESIDENT_ERROR = "rentable/residentlist/FETCH_ERROR";

export const rentableResidentRequest = createAction(RENTABLE_RESIDENT_REQUEST)<string>();
export const rentableResidentSuccess = createAction(RENTABLE_RESIDENT_SUCCESS)<ResidentRentableItems[]>();
export const rentableResidentError = createAction(RENTABLE_RESIDENT_ERROR)<string>();

const RENTABLE_PROPERTY_REQUEST = "rentable/propertylist/FETCH_REQUEST";
const RENTABLE_PROPERTY_SUCCESS = "rentable/propertylist/FETCH_SUCCESS";
const RENTABLE_PROPERTY_ERROR = "rentable/propertylist/FETCH_ERROR";

export const rentablePropertyRequest = createAction(RENTABLE_PROPERTY_REQUEST)<string>();
export const rentablePropertySuccess = createAction(RENTABLE_PROPERTY_SUCCESS)<RentableItems[]>();
export const rentablePropertyError = createAction(RENTABLE_PROPERTY_ERROR)<string>();

const RENTABLE_REQUEST_REQUEST = "rentable/request/RENTABLE_REQUEST_REQUEST";
const RENTABLE_REQUEST_SUCCESS = "rentable/request/RENTABLE_REQUEST_SUCCESS";
const RENTABLE_REQUEST_ERROR = "rentable/request/RENTABLE_REQUEST_ERROR";
export const requestRentableRequest = createAction(RENTABLE_REQUEST_REQUEST)<RentableItemRequest>();
export const requestRentableSuccess = createAction(RENTABLE_REQUEST_SUCCESS)<string>();
export const requestRentableError = createAction(RENTABLE_REQUEST_ERROR)<string>();
