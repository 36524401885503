import { createAction } from "typesafe-actions";

import {
  ERefundValue,
  ForwardingAddress,
  NewNoticeToVacate,
  NoticeSummary,
  NoticeToVacate,
  SaveExpectedMoveOutDate,
  CancelRequestData,
} from "./models";

//import { MyAccountDashboard } from "./models";

const POST_REQUEST = "noticetovacate/POST_DATE_REQUEST";
const POST_SUCCESS = "noticetovacate/POST_DATE_SUCCESS";
const POST_ERROR = "noticetovacate/POST_DATE_ERROR";
export const postDateRequest = createAction(POST_REQUEST)<SaveExpectedMoveOutDate>();
export const postDateSuccess = createAction(POST_SUCCESS)<string>();
export const postDateError = createAction(POST_ERROR)<string>();

const ENTV_FETCH_REQUEST = "entv/FETCH_REQUEST";
const ENVT_FETCH_SUCCESS = "entv/FETCH_SUCCESS";
const ENTV_FETCH_ERROR = "entv/FETCH_ERROR";
export const requestENTVRequest = createAction(ENTV_FETCH_REQUEST)<string>();
export const requestENTVSuccess = createAction(ENVT_FETCH_SUCCESS)<NoticeToVacate>();
export const requestENTVError = createAction(ENTV_FETCH_ERROR)<string>();

const POST_NOTICE_REQUEST = "noticetovacate/POST_NOTICE_REQUEST";
const POST_NOTICE_SUCCESS = "noticetovacate/POST_NOTICE_SUCCESS";
const POST_NOTICE_ERROR = "noticetovacate/POST_NOTICE_ERROR";
export const postNoticeRequest = createAction(POST_NOTICE_REQUEST)<NewNoticeToVacate>();
export const postNoticeSuccess = createAction(POST_NOTICE_SUCCESS)<string>();
export const postNoticeError = createAction(POST_NOTICE_ERROR)<string>();

const POST_ADDRESS_REQUEST = "noticetovacate/POST_ADDRESS_REQUEST";
const POST_ADDRESS_SUCCESS = "noticetovacate/POST_ADDRESS_SUCCESS";
const POST_ADDRESS_ERROR = "noticetovacate/POST_ADDRESS_ERROR";
export const postAddressRequest = createAction(POST_ADDRESS_REQUEST)<ForwardingAddress>();
export const postAddressSuccess = createAction(POST_ADDRESS_SUCCESS)<string>();
export const postAddressError = createAction(POST_ADDRESS_ERROR)<string>();

const POST_REFUND_REQUEST = "noticetovacate/POST_REFUND_REQUEST";
const POST_REFUND_SUCCESS = "noticetovacate/POST_REFUND_SUCCESS";
const POST_REFUND_ERROR = "noticetovacate/POST_REFUND_ERROR";
export const postRefundRequest = createAction(POST_REFUND_REQUEST)<ERefundValue>();
export const postRefundSuccess = createAction(POST_REFUND_SUCCESS)<string>();
export const postRefundError = createAction(POST_REFUND_ERROR)<string>();

const ENTV_SUMMARY_FETCH_REQUEST = "entv/SUMMARY_FETCH_REQUEST";
const ENVT_SUMMARY_FETCH_SUCCESS = "entv/SUMMARY_FETCH_SUCCESS";
const ENTV_SUMMARY_FETCH_ERROR = "entv/SUMMARY_FETCH_ERROR";
export const requestENTVSummaryRequest = createAction(ENTV_SUMMARY_FETCH_REQUEST)<string>();
export const requestENTVSummarySuccess = createAction(ENVT_SUMMARY_FETCH_SUCCESS)<NoticeSummary>();
export const requestENTVSummaryError = createAction(ENTV_SUMMARY_FETCH_ERROR)<string>();

const PUT_CANCEL_REQUEST = "entv/PUT_CANCEL_REQUEST";
const PUT_CANCEL_SUCCESS = "entv/PUT_CANCEL_SUCCESS";
const PUT_CANCEL_ERROR = "entv/PUT_CANCEL_ERROR";
export const putCancelRequest = createAction(PUT_CANCEL_REQUEST)<CancelRequestData>();
export const putCancelSuccess = createAction(PUT_CANCEL_SUCCESS)<string>();
export const putCancelError = createAction(PUT_CANCEL_ERROR)<string>();

const MOPOST_REQUEST = "noticetovacate/MOPOST_DATE_REQUEST";
const MOPOST_SUCCESS = "noticetovacate/MOPOST_DATE_SUCCESS";
const MOPOST_ERROR = "noticetovacate/MOPOST_DATE_ERROR";
export const moPostDateRequest = createAction(MOPOST_REQUEST)<SaveExpectedMoveOutDate>();
export const moPostDateSuccess = createAction(MOPOST_SUCCESS)<string>();
export const moPostDateError = createAction(MOPOST_ERROR)<string>();
