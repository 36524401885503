export function openDownloadedDocument(response: any, fileName: string, newWindow: Window | null) {
  const blob = new Blob([response.data], { type: response.data.type });

  // Internet Explorer 6-11
  var ua = navigator.userAgent; // /*@cc_on!@*/false || !!document.documentMode;
  var isIE = ua.indexOf("MSIE") > -1 || ua.indexOf("Trident/") > -1;

  // var isSafari = false;
  // var isChrome = false;

  // if (ua.toLowerCase().indexOf('safari') !== -1)
  // {
  //   if (ua.toLowerCase().indexOf('chrone') > -1)
  //   {
  //     isChrome = true;
  //   } s
  //   else{
  //     isSafari = true;
  //   }
  // }

  if (isIE) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const fileURL = URL.createObjectURL(blob);
    if (newWindow) newWindow.location.assign(fileURL);
    else window.open(fileURL);
  }
}

export function compareDates(date1: any, date2: any) {
  return date2 - date1;
}

export function currencyFormatter(balance: number) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  return formatter.format(balance);
}

// Escape HTML chars

export function escapeHtml(str: string) {
  return String(str)
    .replace("&", "&amp;")
    .replace("<", "&lt;")
    .replace(">", "&gt;")
    .replace('"', "&quot;")
    .replace("'", "&#39;")
    .replace("/", "&#x2F;");
}

export function thousands_separators(num: string) {
  var num_parts = num.split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
}
