import { createAsyncAction } from "typesafe-actions";

import { CommunityGuideItem } from "./models";

const FETCH_REQUEST = "communityguide/FETCH_REQUEST";
const FETCH_SUCCESS = "communityguide/FETCH_SUCCESS";
const FETCH_ERROR = "communityguide/FETCH_ERROR";

export const fetchAsyncAction = createAsyncAction(FETCH_REQUEST, FETCH_SUCCESS, FETCH_ERROR)<
  string,
  CommunityGuideItem[],
  Error
>();
