import { createAsyncAction } from "typesafe-actions";
import { CriminalReportData } from "./models";

const FETCH_REQUEST = "criminalreport/FETCH_REQUEST";
const FETCH_SUCCESS = "criminalreport/FETCH_SUCCESS";
const FETCH_ERROR = "criminalreport/FETCH_ERROR";

export const fetchAsyncAction = createAsyncAction(FETCH_REQUEST, FETCH_SUCCESS, FETCH_ERROR)<
  string,
  CriminalReportData,
  Error
>();
