import { Button, withStyles } from "@material-ui/core";
import React, { Component } from "react";

const FormatText = require("react-format-text");

interface MyProps {
  message: string;
  characterCount: number;
}
const StyledButton = withStyles({
  root: {
    borderRadius: 3,
    border: 0,
    color: "#3d90d4",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
})(Button);

class TruncateMessage extends Component<MyProps> {
  state = {
    fullMessageVisible: false,
  };

  render() {
    if (!this.props.message) {
      return null;
    }
    const message =
      this.props.message.length > this.props.characterCount ? (
        this.state.fullMessageVisible ? (
          <React.Fragment>
            {this.props.message}
            <StyledButton onClick={() => this.setState({ fullMessageVisible: false })}> Hide</StyledButton>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {this.props.message.substring(0, this.props.characterCount)}...
            <StyledButton onClick={() => this.setState({ fullMessageVisible: true })}> More</StyledButton>
          </React.Fragment>
        )
      ) : (
        <React.Fragment>
          <FormatText>{this.props.message}</FormatText>
        </React.Fragment>
      );
    return <React.Fragment>{message}</React.Fragment>;
  }
}

export default TruncateMessage;
