import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../store/root-reducer";
import {
  FormControlLabel,
  Radio,
  Typography,
  WithStyles,
  withStyles,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Paper,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import applicantstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { ApplicationCheckList } from "../models";
import { fetchRequestAsync } from "../actions";
import { saveRentGuaranteeOptions } from "../../../utils/api";
import { Link } from "react-router-dom";
import Parser from "html-react-parser";

const styles = combinestyles(applicantstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  propertyName: string;
}

interface PropsFromState {
  user?: UserInfoItem;
  applicantChecklist?: ApplicationCheckList;
  loading: boolean;
}

interface PropsFromDispatch {
  fetchRequest: typeof fetchRequestAsync.request;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

class ApplicantChecklist extends React.Component<AllProps> {
  state = {
    selectedOptionFlag: "",
  };

  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.fetchRequest(nameId);
  }

  async handleClickSubmitGuarantee() {
    await saveRentGuaranteeOptions({
      nameId: this.props.user!.nameId,
      guaranteeOption: this.state.selectedOptionFlag,
    });

    window.location.reload();
  }

  render() {
    if (this.props.loading || !this.props.applicantChecklist) {
      return null;
    }

    const rentGuaranteeHeader = (
      <ExpansionPanel expanded={false}>
        <ExpansionPanelSummary>
          <Typography variant="h4">
            Rent Guarantee Options <em>(Select one)</em>
          </Typography>
        </ExpansionPanelSummary>
      </ExpansionPanel>
    );

    const rentGuarantee =
      this.props.applicantChecklist.autoApproved === "Y" ? null : (
        <div>
          {this.props.applicantChecklist.displayRentGuaranteeOptions === "Y" ? (
            <>
              {rentGuaranteeHeader}
              {this.props.applicantChecklist.guaranteeOptionsList.map((x) => (
                <ExpansionPanel key={x.optionFlag}>
                  <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                    <FormControlLabel
                      label={x.title}
                      value={x.optionFlag}
                      control={
                        <Radio
                          color="primary"
                          checked={x.optionFlag === this.state.selectedOptionFlag}
                          onChange={() => this.setState({ selectedOptionFlag: x.optionFlag })}
                        />
                      }
                    />
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>{Parser(x.description)}</Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
              <ExpansionPanel expanded style={{ marginBottom: "1rem" }}>
                <ExpansionPanelSummary>
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!this.state.selectedOptionFlag}
                      onClick={this.handleClickSubmitGuarantee.bind(this)}
                    >
                      Submit
                    </Button>
                  </div>
                </ExpansionPanelSummary>
              </ExpansionPanel>
            </>
          ) : (
            <>
              {rentGuaranteeHeader}
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                  <FormControlLabel
                    control={<Radio checked color="primary" />}
                    label={this.props.applicantChecklist.checkListSelectedOption}
                  />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    {Parser(
                      this.props.applicantChecklist.guaranteeOptionsList.find(
                        (x) => x.title === this.props.applicantChecklist!.checkListSelectedOption
                      )!.description
                    )}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel expanded style={{ marginBottom: "1rem" }}>
                <ExpansionPanelSummary>
                  <Typography>
                    <strong>Thank you!</strong> Your response has been recorded.
                  </Typography>
                </ExpansionPanelSummary>
              </ExpansionPanel>
            </>
          )}
        </div>
      );

    const necessaryDocumentation = (
      <div>
        <ExpansionPanel expanded={false}>
          <ExpansionPanelSummary>
            <Typography variant="h4">Necessary Documentation</Typography>
          </ExpansionPanelSummary>
        </ExpansionPanel>
        {this.props.applicantChecklist.necessaryDocumentationList.map((x) => (
          <ExpansionPanel key={x.title}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <Typography variant="h4">{`${x.sortOrder}. ${x.title}`}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography style={{ whiteSpace: "pre-wrap" }}>{Parser(x.copy)}</Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
        <ExpansionPanel expanded style={{ marginBottom: "1rem" }}>
          <ExpansionPanelSummary>
            <Link to="/uploads" style={{ textDecoration: "none" }}>
              <Button variant="contained" color="primary" size="large">
                Upload Documents
              </Button>
            </Link>
          </ExpansionPanelSummary>
        </ExpansionPanel>
      </div>
    );

    return (
      <>
        <Paper className={this.props.classes.paper}>
          <Typography variant="h2" style={{ marginBottom: "1rem" }}>
            Thank you for applying at {this.props.propertyName}
          </Typography>

          {this.props.applicantChecklist.displayPayment ==="Y" ? (
            <Typography style={{ marginBottom: "1rem" }}>
            Thank you for your interest in our community. Please submit payment and hang tight while our application
            team processes it. If you have any questions, please contact &nbsp;
            <a href="mailto:ApplicationProcessing@eqr.com" target="_blank" rel="noreferrer">
              ApplicationProcessing@eqr.com
            </a>
          </Typography>
          ) : (
            this.props.applicantChecklist.autoApproved === "Y" ||
            (this.props.applicantChecklist.displayRentGuaranteeOptions !== "Y" &&
              this.props.applicantChecklist.necessaryDocumentationList.length === 0) ? (
              <Typography style={{ marginBottom: "1rem" }}>
                Thank you for your interest in our community. Please hang tight while our application 
                team processes it. If you have any questions, please contact &nbsp;
                <a href="mailto:ApplicationProcessing@eqr.com" target="_blank" rel="noreferrer">
                  ApplicationProcessing@eqr.com
                </a>
              </Typography>
            ) : (
              <div>
                <Typography style={{ marginBottom: "1rem" }}>
                  Thank you for applying at {this.props.propertyName}! We need a little more information before fully
                  processing your application. Please review and complete the steps below.
                </Typography>
                {/* {this.props.applicantChecklist.displayAdverseLetterLink === "Y" && (
                  <Typography style={{ marginBottom: "1rem" }}>
                    <Link to="/adverseactionletter" style={{ textDecoration: "none" }}>
                      Review Your Application Results
                    </Link>
                  </Typography>
                )} */}
              </div>
            )
          )}
        </Paper>
        {rentGuarantee}
        {this.props.applicantChecklist.necessaryDocumentationList.length > 0 && necessaryDocumentation}
      </>
    );
  }
}

const mapStateToProps = ({ selectAccount, applicant }: RootState): PropsFromState => ({
  loading: selectAccount.loading,
  user: getActiveUserInfo(selectAccount),
  applicantChecklist: applicant.checkList,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  fetchRequest: (nameid: string) => dispatch(fetchRequestAsync.request(nameid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApplicantChecklist));
