import {
  Card,
  CardContent,
  Typography,
  WithStyles,
  withStyles,
  Grid,
  Link,
  CardMedia,
  Hidden
} from "@material-ui/core";
import * as React from "react";
import PhotoGallery from "./common/PhotoGallery";
import { NewsItem } from "../models";
import FeedItemHeader from "./FeedItemHeader";
import styles from "./styles";
import AttachFile from "@material-ui/icons/AttachFile";
import * as constants from "../constants";

interface Props extends WithStyles<typeof styles> {
  item: NewsItem;
}
type AllProps = Props;

class NewsComponent extends React.Component<AllProps> {
  render() {
    const { item, classes } = this.props;

    return (
      <Card
        style={{
          width: "100%",
          borderLeftWidth: item.pinned ? "6px" : "0",
          borderLeftStyle: item.pinned ? "solid" : "none",
          borderLeftColor: "#FFCC00"
        }}
      >
        <FeedItemHeader item={item} showExpander={false} />
        <CardContent className={classes.feedItemContent}>
          <Grid container direction="row" style={{ marginBottom: "1.25rem" }}>
            <Grid item className={classes.leftIconGutter}>
              <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                <CardMedia className={classes.postTypeIcon} image={constants.NEWS_ICON} title="News" />
              </div>
            </Grid>
            <Grid item xs style={{ paddingRight: "1rem" }}>
              <Typography variant="h4" gutterBottom>
                {item.title}
              </Typography>
              <Typography>{item.message}</Typography>
            </Grid>
            <Hidden xsDown>
              <Grid item>{item.images.length > 0 && <PhotoGallery images={item.images} />}</Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item style={{ backgroundColor: "#d7d7d7", marginTop: "1rem" }}>
                {item.images.length > 0 && <PhotoGallery images={item.images} />}
              </Grid>
            </Hidden>
          </Grid>
          {item.attachments.length > 0 && (
            <Grid container direction="row" className={classes.newsAttachmentContainer}>
              <Grid item className={classes.leftIconGutter} />
              <Grid item>
                <Link
                  href={
                    "https://media.equityapartments.com/image/upload/v1557240317/CommunityNews/" + item.attachments[0]
                  }
                  target="_blank"
                >
                  <Typography>
                    <AttachFile />
                    View Attachment
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(NewsComponent);
