import { Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";

import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import rentableitemstyles from "../styles";

const styles = combinestyles(rentableitemstyles, globalstyles);

export interface IENTVHeaderProps extends WithStyles<typeof styles> {}

type State = {};

class ENTVHeader extends React.Component<IENTVHeaderProps> {
  readonly state: State = {};

  render() {
    return (
      <div>
        <Typography variant="h1" className={this.props.classes.header}>
          Notice to Vacate
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(ENTVHeader);
