import * as React from "react";
import { withStyles, WithStyles } from "@material-ui/core";
import { referAmountFetchRequest } from "../features/referfriend/actions";

//import ReferFriendDashboard from "../features/referfriend/components/ReferFriendDashboard";
import ReferFriendRequest from "../features/referfriend/components/ReferFriendRequest";
import ReferFriendResult from "../features/referfriend/components/ReferFriendResult";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps, Route, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import { RootState } from "../store/root-reducer";
import { ReferFriendAmount } from "../features/referfriend/models";

import referfriendstyles from "../features/mylease/components/styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(referfriendstyles, globalstyles);

interface IReferFriendProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
}

interface IPageState {}

interface PropsFromState {
  user?: UserInfoItem;
  referFriendDataInfo: ReferFriendAmount;
  loading: boolean;
  errors?: string;
}

interface PropsFromDispatch {
  referAmountFetchRequest: typeof referAmountFetchRequest;
}

type AllProps = IReferFriendProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

class ReferFriendPage extends React.Component<AllProps, IPageState> {
  state: IPageState = {};

  componentDidMount() {
    const rmpropId = this.props.user!.propertyId;
    this.props.referAmountFetchRequest(rmpropId);
    window.scrollTo(0, 0);
  }

  render() {
    if (!this.props.referFriendDataInfo) {
      return <div />;
    }
    return (
      <Switch>
        <Route path="/myaccount/referfriend/ReferFriendResult" component={ReferFriendResult} />
        {/* <Route component={ReferFriendDashboard} /> */}
        <Route component={ReferFriendRequest} />
      </Switch>
    );
  }
}

const mapStateToProps = ({ referFriend, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  referFriendDataInfo: referFriend.referFriendAmountData.data!,
  loading: referFriend.referFriendAmountData.loading,
  errors: referFriend.referFriendAmountData.errors
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  referAmountFetchRequest: (rmpropId: string) => dispatch(referAmountFetchRequest(rmpropId))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReferFriendPage)));
