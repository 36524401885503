import { createAction } from "typesafe-actions";

import {
  AddCommentDto,
  BlockUserDto,
  CreatePostDto,
  DashboardFeedItem,
  EventItem,
  RsvpDto,
  RsvpResponse,
  UpdatePostDto,
} from "./models";

const DASHBOARD_FETCH_REQUEST = "dashboard/FETCH_REQUEST";
const DASHBOARD_FETCH_SUCCESS = "dashboard/FETCH_SUCCESS";
const DASHBOARD_FETCH_ERROR = "dashboard/FETCH_ERROR";

export const dashboardFetchRequest = createAction(DASHBOARD_FETCH_REQUEST)<string>();
export const dashboardFetchSuccess = createAction(DASHBOARD_FETCH_SUCCESS)<{ data: DashboardFeedItem[] }>();
export const dashboardFetchError = createAction(DASHBOARD_FETCH_ERROR)<string>();

const DASHBOARD_FETCH_MORE_REQUEST = "dashboard/FETCH_MORE_REQUEST";
const DASHBOARD_FETCH_MORE_SUCCESS = "dashboard/FETCH_MORE_SUCCESS";
const DASHBOARD_FETCH_MORE_ERROR = "dashboard/FETCH_MORE_ERROR";

export const dashboardFetchMoreRequest = createAction(DASHBOARD_FETCH_MORE_REQUEST)<string, number>();
export const dashboardFetchMoreSuccess = createAction(DASHBOARD_FETCH_MORE_SUCCESS)<{
  data: DashboardFeedItem[];
}>();
export const dashboardFetchMoreError = createAction(DASHBOARD_FETCH_MORE_ERROR)<string>();

const CREATE_POST_REQUEST = "social/post/CREATE_POST_REQUEST";
const CREATE_POST_SUCCESS = "social/post/CREATE_POST_SUCCESS";
const CREATE_POST_ERROR = "social/post/CREATE_POST_ERROR";
export const createPostRequest = createAction(CREATE_POST_REQUEST)<CreatePostDto>();
export const createPostSuccess = createAction(CREATE_POST_SUCCESS)<DashboardFeedItem>();
export const createPostError = createAction(CREATE_POST_ERROR)<string>();

const POST_UPDATE_REQUEST = "social/post/POST_UPDATE_REQUEST";
const POST_UPDATE_SUCCESS = "social/post/POST_UPDATE_SUCCESS";
const POST_UPDATE_ERROR = "social/post/POST_UPDATE_ERROR";
export const postUpdateRequest = createAction(POST_UPDATE_REQUEST)<{
  postId: number;
  UpdatePostDto: UpdatePostDto;
}>();
export const postUpdateSuccess = createAction(POST_UPDATE_SUCCESS)<DashboardFeedItem>();
export const postUpdateError = createAction(POST_UPDATE_ERROR)<string>();

const EVENTS_FETCH_REQUEST = "events/FETCH_REQUEST";
const EVENTS_FETCH_SUCCESS = "events/FETCH_SUCCESS";
const EVENTS_FETCH_ERROR = "events/FETCH_ERROR";

export const eventsFetchRequest = createAction(EVENTS_FETCH_REQUEST)<string>();
export const eventsFetchSuccess = createAction(EVENTS_FETCH_SUCCESS)<{ events: EventItem[] }>();
export const eventsFetchError = createAction(EVENTS_FETCH_ERROR)<string>();

const ADD_COMMENT_REQUEST = "social/post/ADD_COMMENT_REQUEST";
const ADD_COMMENT_SUCCESS = "social/post/ADD_COMMENT_SUCCESS";
const ADD_COMMENT_ERROR = "social/post/ADD_COMMENT_ERROR";
export const addCommentRequest = createAction(ADD_COMMENT_REQUEST)<AddCommentDto>();
export const addCommentSuccess = createAction(ADD_COMMENT_SUCCESS)<{ data: DashboardFeedItem[] }>();
export const addCommentError = createAction(ADD_COMMENT_ERROR)<string>();

const EVENT_RSVP_REQUEST = "social/event/EVENT_RSVP_REQUEST";
const EVENT_RSVP_SUCCESS = "social/event/EVENT_RSVP_SUCCESS";
const EVENT_RSVP_ERROR = "social/event/EVENT_RSVP_ERROR";
export const rsvpRequest = createAction(EVENT_RSVP_REQUEST)<RsvpDto>();
export const rsvpSuccess = createAction(EVENT_RSVP_SUCCESS)<RsvpResponse>();
export const rsvpError = createAction(EVENT_RSVP_ERROR)<string>();

const EVENT_CANCEL_RSVP_REQUEST = "social/event/EVENT_CANCEL_RSVP_REQUEST";
const EVENT_CANCEL_RSVP_SUCCESS = "social/event/EVENT_CANCEL_RSVP_SUCCESS";
const EVENT_CANCEL_RSVP_ERROR = "social/event/EVENT_CANCEL_RSVP_ERROR";
export const cancelRsvpRequest = createAction(EVENT_CANCEL_RSVP_REQUEST)<RsvpDto>();
export const cancelRsvpSuccess = createAction(EVENT_CANCEL_RSVP_SUCCESS)<RsvpResponse>();
export const cancelRsvpError = createAction(EVENT_CANCEL_RSVP_ERROR)<string>();
const CHANGE_FILTER = "social/CHANGE_FILTER";
export const changeFilter = createAction(CHANGE_FILTER)<string>();

const BLOCK_USER_REQUEST = "social/BLOCK_USER_REQUEST";
const BLOCK_USER_SUCCESS = "social/BLOCK_USER_SUCCESS";
const BLOCK_USER_ERROR = "social/BLOCK_USER_ERROR";
export const blockUserRequest = createAction(BLOCK_USER_REQUEST)<BlockUserDto>();
export const blockUserSuccess = createAction(BLOCK_USER_SUCCESS)<{ data: DashboardFeedItem[] }>();
export const blockUserError = createAction(BLOCK_USER_ERROR)<string>();

const HIDE_POST_REQUEST = "social/HIDE_POST_REQUEST";
const HIDE_POST_SUCCESS = "social/HIDE_POST_SUCCESS";
const HIDE_POST_ERROR = "social/HIDE_POST_ERROR";
export const hidePostRequest = createAction(HIDE_POST_REQUEST)<{
  postId: number;
  authorNameId: string;
  groupId?: number;
}>();
export const hidePostSuccess = createAction(HIDE_POST_SUCCESS)<{ data: DashboardFeedItem[] }>();
export const hidePostError = createAction(HIDE_POST_ERROR)<string>();

const HIDE_COMMENT_REQUEST = "social/HIDE_COMMENT_REQUEST";
const HIDE_COMMENT_SUCCESS = "social/HIDE_COMMENT_SUCCESS";
const HIDE_COMMENT_ERROR = "social/HIDE_COMMENT_ERROR";
export const hideCommentRequest = createAction(HIDE_COMMENT_REQUEST)<{
  commentId: number;
  authorNameId: string;
  groupId?: number;
}>();
export const hideCommentSuccess = createAction(HIDE_COMMENT_SUCCESS)<{ data: DashboardFeedItem[] }>();
export const hideCommentError = createAction(HIDE_COMMENT_ERROR)<string>();

const FEED_USER_INFO_FETCH_REQUEST = "social/userInfo/FETCH_REQUEST";
const FEED_USER_INFO_FETCH_SUCCESS = "social/userInfo/FETCH_SUCCESS";
const FEED_USER_INFO_FETCH_ERROR = "social/userInfo/FETCH_ERROR";
export const feedUserInfoFetchRequest = createAction(FEED_USER_INFO_FETCH_REQUEST)<string>();
export const feedUserInfoFetchSuccess = createAction(FEED_USER_INFO_FETCH_SUCCESS)<{ socialUserId: number }>();
export const feedUserInfoFetchError = createAction(FEED_USER_INFO_FETCH_ERROR)<string>();

const OPEN_MODAL = "social/post/OPEN_MODAL";
const CLOSE_MODAL = "social/post/CLOSE_MODAL";
export const openSocialModal = createAction(OPEN_MODAL)();
export const closeSocialModal = createAction(CLOSE_MODAL)();

const OPEN_EDIT_MODAL = "social/post/OPEN_EDIT_MODAL";
const CLOSE_EDIT_MODAL = "social/post/CLOSE_EDIT_MODAL";
export const openEditModal = createAction(OPEN_EDIT_MODAL)();
export const closeEditModal = createAction(CLOSE_EDIT_MODAL)();
