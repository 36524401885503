import * as React from "react";

import { Typography, WithStyles, withStyles, Grid, Card, Button, Hidden } from "@material-ui/core";

import paymentstyles from "../styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(paymentstyles, globalstyles);

interface PaymentProps extends WithStyles<typeof styles> {
  residentAccount: string;
  displayPaymentBox: string;
  displayAutoPayBox: string;

  displayBILTBox: string;
  displayOnlinePay: string;
  rentPayLogoURL: string;
  rentPayTitle: string;
  rentPayDescription: string;
  rentPaySortOrder?: number;
  biltLinkURL: string;
  biltLogoURL: string;
  biltTitle: string;
  biltDescription: string;
  biltSortOrder?: number;
  onlinePayLogoURL: string;
  onlinePayTitle: string;
  onlinePayDescription: string;

  handlePaymentRentPayment: any;
  handleAutoPay: any;
  handleOnlinePay: any;

  onlineBillSortOrder?: number;
}

type AllProps = PaymentProps; // & PropsFromState;

class PaymentButtonBoard extends React.Component<AllProps> {
  render() {
    return (
      <Grid container spacing={24} className={this.props.classes.grid}>
        {this.props.displayPaymentBox === "Y" && (
          <Grid item xs={12}>
            <Card>
              <Button
                variant="text"
                onClick={this.props.handlePaymentRentPayment}
                style={{ textDecoration: "none", display: "contents" }}
              >
                <Grid container direction="row" alignItems="center" spacing={24}>
                  <Grid item>
                    <img
                      alt="Payments Icon"
                      src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/paymentoptions/cash.png"
                      className={this.props.classes.centerElement}
                      style={{ maxWidth: "75px", marginLeft: "1em" }}
                    />
                  </Grid>
                  <Grid item xs style={{ paddingTop: "2em", paddingBottom: "2em" }}>
                    {/* <p>Lorem ipsum set dolar nomunny poopy cocka doodie squits</p> */}
                    <Typography variant="h5" style={{ textAlign: "left" }}>
                      {this.props.rentPayTitle}
                    </Typography>

                    <Typography style={{ textAlign: "left" }}>{this.props.rentPayDescription}</Typography>
                  </Grid>
                  <Hidden xsDown>
                    <Grid item>
                      <img
                        alt="MRI payment Logo"
                        src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,h_23/v1646067120/Content/portal/paymentoptions/mri-rent-payment"
                        style={{ marginRight: "1em" }}
                      />
                    </Grid>
                  </Hidden>
                </Grid>
              </Button>
            </Card>
          </Grid>
        )}

        {this.props.displayBILTBox === "Y" && (
          <Grid item xs={12}>
            <Card>
              <a href={this.props.biltLinkURL} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                <Grid container direction="row" alignItems="center" spacing={24}>
                  <Grid item>
                    <img
                      alt="Automate Payments"
                      src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/paymentoptions/credit.png"
                      className={this.props.classes.centerElement}
                      style={{ marginLeft: "1em" }}
                    />
                  </Grid>
                  <Grid item xs style={{ paddingTop: "2em", paddingBottom: "2em" }}>
                    {/* <p>Lorem ipsum set dolar nomunny poopy cocka doodie squits</p> */}
                    <Typography variant="h5" style={{ textAlign: "left" }}>
                      {this.props.biltTitle}
                    </Typography>
                    <Typography style={{ textAlign: "left" }}>{this.props.biltDescription}</Typography>
                  </Grid>
                  <Hidden xsDown>
                    <Grid item>
                      <img
                        alt="BILT Rewards Logo"
                        src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,h_40/v1646067120/Content/portal/paymentoptions/BILT"
                        style={{ marginRight: "1em" }}
                      />
                    </Grid>
                  </Hidden>
                </Grid>
              </a>
            </Card>
          </Grid>
        )}

        

        {this.props.displayOnlinePay === "Y" && (
          <Grid item xs={12}>
            <hr />
            <div style={{ marginTop: "28px" }} />
            <Card>
              <Button
                variant="text"
                onClick={this.props.handleOnlinePay}
                style={{ textDecoration: "none", display: "contents" }}
              >
                <Grid container direction="row" alignItems="center" spacing={24}>
                  <Grid item>
                    <img
                      alt="Online Payments Icon"
                      src="https://media.equityapartments.com/image/upload/dpr_auto,f_auto,c_scale,w_60/Content/portal/paymentoptions/bank.png"
                      className={this.props.classes.centerElement}
                      style={{ maxWidth: "75px", marginLeft: "1em" }}
                    />
                  </Grid>
                  <Grid item xs style={{ paddingTop: "2em", paddingBottom: "2em" }}>
                    <Typography variant="h5" style={{ textAlign: "left" }}>
                      {this.props.onlinePayTitle}
                    </Typography>

                    <Typography style={{ textAlign: "left" }}>{this.props.onlinePayDescription}</Typography>
                  </Grid>
                </Grid>
              </Button>
            </Card>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(PaymentButtonBoard);
