import * as React from "react";
import { fetchAsyncAction } from "../features/communityguide/actions";
import { CommunityGuideItem } from "../features/communityguide/models";
import { RootState } from "../store/root-reducer";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Typography, withStyles, WithStyles, Paper, Divider, Link as MuiLink } from "@material-ui/core";
import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import statementstyles from "../features/communityguide/components/styles";
import globalstyles from "../global-styles";
import { Link } from "react-router-dom";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(statementstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {}

interface PropsFromState {
  user?: UserInfoItem;
  loading: boolean;
  data: CommunityGuideItem[];
  errors: string;
}

interface PropsFromDispatch {
  fetchRequest: typeof fetchAsyncAction.request;
}

type AllProps = Props & PropsFromState & PropsFromDispatch & RouteComponentProps<{ startDateIndex: string }>;

class CommunityGuide extends React.Component<AllProps> {
  public componentDidMount() {
    this.props.fetchRequest(this.props.user!.nameId);
  }

  handleClickOutline(li: CommunityGuideItem) {
    const id = `li-${li.id}`;
    const yOffset = -10;
    const element = document.getElementById(id);
    const y = element!.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  }

  handleClickBackToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  public render() {
    const { data, loading } = this.props;

    let content = null;

    if (!loading) {
      const moveInList = data.filter((contact) => contact.guideSection === "MI");
      const remainingList = data.filter((contact) => contact.guideSection !== "MI");

      content = (
        <>
          <Paper className={this.props.classes.paper}>
            <Typography>
              <strong>On this page</strong> - <em>Jump to section</em>
            </Typography>
            <ul style={{ columns: 2 }}>
              {remainingList.map((li) => (
                <li key={li.id}>
                  <Typography component="span">
                    <MuiLink style={{ cursor: "pointer" }} onClick={() => this.handleClickOutline(li)}>
                      {li.title}
                    </MuiLink>
                  </Typography>
                </li>
              ))}
            </ul>
          </Paper>

          {!!moveInList.length && (
            <Paper className={this.props.classes.paper}>
              <Typography variant="h2">Move-in Day Guide</Typography>
              <Divider style={{ margin: "1rem 0" }} />
              {moveInList.map((li) => (
                <>
                  <Typography variant="h3" style={{ margin: "1rem 0 0.5rem 0" }}>
                    {li.title}
                  </Typography>
                  <Typography
                    component="div"
                    style={{ whiteSpace: "pre-wrap" }}
                    dangerouslySetInnerHTML={{ __html: li.description }}
                  />
                </>
              ))}
            </Paper>
          )}

          {remainingList.map((li) => (
            <Paper id={`li-${li.id}`} key={li.id} className={this.props.classes.paper}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="h2" inline>
                  {li.title}
                </Typography>
                <Typography component="span">
                  <MuiLink
                    style={{ cursor: "pointer", fontSize: "0.75rem", textTransform: "uppercase" }}
                    onClick={this.handleClickBackToTop}
                  >
                    Back to top
                  </MuiLink>
                </Typography>
              </div>
              <Divider style={{ margin: "1rem 0" }} />
              {li.guideSection === "YT" ? (
                <div style={{ position: "relative", paddingBottom: "56.5%" }}>
                  <iframe
                    title="YT"
                    frameBorder="0"
                    src={li.description}
                    style={{ position: "absolute", height: "100%", width: "100%" }}
                  />
                </div>
              ) : (
                <Typography
                  component="div"
                  style={{ whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{ __html: li.description }}
                />
              )}
              {li.ctaText && li.ctaDestination && (
                <Typography style={{ marginTop: "1rem" }}>
                  {li.ctaType === "Internal" ? (
                    <Link to={li.ctaDestination} style={{ textDecoration: "none" }}>
                      {li.ctaText}
                    </Link>
                  ) : (
                    <MuiLink
                      href={li.ctaType === "Email" ? `mailto:${li.ctaDestination}` : li.ctaDestination}
                      target="_blank"
                    >
                      {li.ctaText}
                    </MuiLink>
                  )}
                </Typography>
              )}
            </Paper>
          ))}
        </>
      );
    }

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Typography variant="h1" className={this.props.classes.header}>
            Community Guide
          </Typography>
          {content}
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ communityGuide, selectAccount }: RootState) => ({
  user: getActiveUserInfo(selectAccount),
  loading: communityGuide.loading,
  errors: communityGuide.errors,
  data: communityGuide.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRequest: (payload: string) => dispatch(fetchAsyncAction.request(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CommunityGuide));
