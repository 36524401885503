import * as React from "react";
import ImageGallery from "react-image-gallery";
// import "react-image-gallery/styles/css/image-gallery.css";
import "../../../../features/shared/css/image-gallery.css";
import { PhotoGallaryItem, Image } from "../../models";
import { Dialog, DialogContent, DialogTitle, Grid, Typography, Hidden } from "@material-ui/core";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { GenerateCloudinaryThumbnailUrl, GenerateCloudinaryUrl } from "../../../../utils/cloudinaryImageFormatter";
import DialogHeader from "../../../shared/components/DialogHeader";

interface Props extends WithWidth {
  images: Image[];
}

type AllProps = Props;

class PhotoGalary extends React.Component<AllProps> {
  state = { open: false };
  openPhotoGallery = () => {
    this.setState({ open: true });
  };

  closePhotoGallery = () => {
    this.setState({ open: false });
  };

  parseImages = (): PhotoGallaryItem[] => {
    var images = [];
    for (var i in this.props.images) {
      const image: PhotoGallaryItem = {
        original: GenerateCloudinaryUrl(this.props.images[i].imageFile, "a_exif"),
        thumbnail: GenerateCloudinaryThumbnailUrl(this.props.images[i].imageFile),
      };
      images.push(image);
    }

    return images;
  };

  handleClose = () => {
    this.setState({ open: false, photos: [], groupId: null });
  };

  render() {
    var galleryImages = this.parseImages();

    return (
      <React.Fragment>
        {galleryImages.length > 0 && (
          <div
            onClick={this.openPhotoGallery}
            style={{ display: "inline-block", float: "right", textAlign: "center", position: "relative" }}
          >
            <div>
              <Hidden xsDown>
                <img
                  alt="Thumbnail"
                  src={galleryImages[0].thumbnail}
                  style={{ height: "125px", width: "125px", objectFit: "cover", cursor: "pointer" }}
                />
                {galleryImages.length > 1 && (
                  <div
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                      position: "absolute",
                      bottom: "0",
                      margin: "0px 0px 4px 0px",
                      padding: "5px",
                      right: "0",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{ color: "#FFF", fontSize: ".75rem", fontWeight: 500, textAlign: "right" }}
                    >
                      <span>{galleryImages.length} PHOTOS</span>
                    </Typography>
                  </div>
                )}
              </Hidden>
              <Hidden smUp>
                <img
                  alt="Original"
                  src={galleryImages[0].original}
                  style={{ objectFit: "cover", maxWidth: "95%", cursor: "pointer" }}
                />

                {galleryImages.length > 1 && (
                  <div
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                      position: "absolute",
                      bottom: "0",
                      margin: "0px 0px 4px 0px",
                      padding: "5px",
                      right: "16px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{ color: "#FFF", fontSize: ".75rem", fontWeight: 500, textAlign: "right" }}
                    >
                      {galleryImages.length > 1 && <span>{galleryImages.length} PHOTOS</span>}
                    </Typography>
                  </div>
                )}
              </Hidden>
            </div>
          </div>
        )}
        <Dialog
          open={this.state.open}
          onClose={this.closePhotoGallery}
          fullScreen={this.props.width === "xs" ? true : false}
          fullWidth
          style={{ overflowX: "hidden" }}
        >
          <DialogTitle style={{ padding: "0", overflow: "hidden" }}>
            <DialogHeader title="" handleClose={this.handleClose} />
          </DialogTitle>
          <DialogContent style={{ overflowX: "hidden" }}>
            <Grid container alignItems="center" justify="center">
              <Grid item style={{ width: "100%" }}>
                <ImageGallery items={galleryImages} showPlayButton={false} showFullscreenButton={false} />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withWidth()(PhotoGalary);
