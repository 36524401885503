import { createAction } from "typesafe-actions";

import { NewRenewal, RenewalData, RenewalExistData } from "./models";

const RENEWAL_FETCH_REQUEST = "renewal/FETCH_REQUEST";
const RENEWAL_FETCH_SUCCESS = "renewal/FETCH_SUCCESS";
const RENEWAL_FETCH_ERROR = "renewal/FETCH_ERROR";
export const renewalFetchRequest = createAction(RENEWAL_FETCH_REQUEST)<string>();
export const renewalFetchSuccess = createAction(RENEWAL_FETCH_SUCCESS)<RenewalData>();
export const renewalFetchError = createAction(RENEWAL_FETCH_ERROR)<string>();

const RENEAL_EXIST_FETCH_REQUEST = "renewal/exist/FETCH_REQUEST";
const RENEAL_EXIST_FETCH_SUCCESS = "renewal/exist/FETCH_SUCCESS";
const RENEAL_EXIST_FETCH_ERROR = "renewal/exist/FETCH_ERROR";
export const renewalExistFetchRequest = createAction(RENEAL_EXIST_FETCH_REQUEST)<string>();
export const renewalExistFetchSuccess = createAction(RENEAL_EXIST_FETCH_SUCCESS)<RenewalExistData>();
export const renewalExistFetchError = createAction(RENEAL_EXIST_FETCH_ERROR)<string>();

const INSERT_RENEWAL_REQUEST = "renewal/insert/INSERT_RENEWAL_REQUEST";
const INSERT_RENEWAL_SUCCESS = "renewal/insert/INSERT_RENEWAL_SUCCESS";
const INSERT_RENEWAL_ERROR = "renewal/insert/INSERT_RENEWAL_ERROR";
export const insertRenewalRequest = createAction(INSERT_RENEWAL_REQUEST)<NewRenewal>();
export const insertRenewalSuccess = createAction(INSERT_RENEWAL_SUCCESS)<NewRenewal>();
export const insertRenewalError = createAction(INSERT_RENEWAL_ERROR)<string>();
