import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import {
  addComment,
  attachPhotosToPost,
  blockUser,
  cancelRsvp,
  createSocialPost,
  hideComment,
  hidePost,
  loadGroupFeed,
  loadGroupProperty,
  loadGroupUser,
  loadSocialEvents,
  loadSocialFeed,
  loadUserInfo,
  postGroupsCreate,
  postGroupsJoin,
  postGroupsLeave,
  postReportAbuse,
  postRsvp,
  updatePost,
} from "../../utils/api";
import { showModalAction } from "../errorhandling/actions";
import {
  dashboardFetchError,
  dashboardFetchMoreError,
  dashboardFetchMoreSuccess,
  dashboardFetchSuccess,
  eventsFetchError,
  eventsFetchSuccess,
} from "./actions";
import * as actions from "./actions";
import * as actionsGroup from "./actionsGroup";

function* handleFetch(action: ReturnType<typeof actions.dashboardFetchRequest>): any {
  try {
    const nameid = action.payload;

    const response = yield call(loadSocialFeed, nameid);

    if (response.error) {
      yield put(dashboardFetchError(response.error));
    } else {
      yield put(dashboardFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(dashboardFetchError(err.stack!));
    } else {
      yield put(dashboardFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleMoreFetch(action: ReturnType<typeof actions.dashboardFetchMoreRequest>): any {
  try {
    const nameid = action.payload;

    const nextPostId = action.meta;

    const response = yield call(loadSocialFeed, nameid, nextPostId);

    if (response.error) {
      yield put(dashboardFetchMoreError(response.error));
    } else {
      yield put(dashboardFetchMoreSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(dashboardFetchMoreError(err.stack!));
    } else {
      yield put(dashboardFetchMoreError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleCreatePostRequest(action: ReturnType<typeof actions.createPostRequest>): any {
  try {
    const newPost = action.payload;
    const response = yield call(createSocialPost, newPost);

    if (response.error) {
      yield put(actions.createPostError(response.error));
    } else {
      if (action.payload.photos.length > 0) {
        var formData = new FormData();
        action.payload.photos.map((file) => formData.append("files", file));
        yield call(attachPhotosToPost, { postId: response.id, files: formData });
      }
      let feedResp = yield call(loadSocialFeed, newPost.authorNameId);
      if (feedResp.error) {
        yield put(dashboardFetchError(feedResp.error));
      } else {
        yield put(actions.createPostSuccess(feedResp));
      }
      let responseEventLoad = yield call(loadSocialEvents, newPost.authorNameId);
      if (responseEventLoad.error) {
        yield put(eventsFetchError(responseEventLoad.error));
      } else {
        yield put(eventsFetchSuccess(responseEventLoad));
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.createPostError(err.stack!));
    } else {
      yield put(actions.createPostError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleUpdatePost(action: ReturnType<typeof actions.postUpdateRequest>): any {
  try {
    const postId = action.payload.postId;
    const UpdatePostDto = action.payload.UpdatePostDto;
    // const refreshGroupId = action.payload.UpdatePostDto.groupId;

    const response = yield call(updatePost, postId, UpdatePostDto);

    if (response.error) {
      yield put(actions.postUpdateError(response.error));
    } else {
      if (action.payload.UpdatePostDto.photos.length > 0) {
        var formData = new FormData();
        action.payload.UpdatePostDto.photos.map((file) => formData.append("files", file));
        yield call(attachPhotosToPost, { postId: postId, files: formData });
      }

      //yield put(actions.postUpdateSuccess(response));
      let feedResp = yield call(loadSocialFeed, UpdatePostDto.authorNameId);
      if (feedResp.error) {
        yield put(dashboardFetchError(feedResp.error));
      } else {
        yield put(actions.postUpdateSuccess(feedResp));
      }

      // let groupFeedResp = refreshGroupId
      //   ? yield call(loadGroupFeed, { groupId: refreshGroupId.toString(), nameId: UpdatePostDto.authorNameId })
      //   : null;
      // if (feedResp.error || !refreshGroupId) {
      //   yield put(actionsGroup.groupFeedFetchError(groupFeedResp.error));
      // } else {
      //   yield put(actionsGroup.createPostGroupSuccess(groupFeedResp));
      // }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.postUpdateError(err.stack!));
    } else {
      yield put(actions.postUpdateError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchEvents(action: ReturnType<typeof actions.eventsFetchRequest>): any {
  try {
    const nameid = action.payload;

    const response = yield call(loadSocialEvents, nameid);

    if (response.error) {
      yield put(eventsFetchError(response.error));
    } else {
      yield put(eventsFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(eventsFetchError(err.stack!));
    } else {
      yield put(eventsFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleAddCommentRequest(action: ReturnType<typeof actions.addCommentRequest>): any {
  try {
    const newComment = action.payload;
    const response = yield call(addComment, newComment);

    if (response.error) {
      yield put(actions.addCommentError(response.error));
    } else {
      let feedResp = yield call(loadSocialFeed, newComment.authorNameId);
      if (feedResp.error) {
        yield put(dashboardFetchError(feedResp.error));
      } else {
        yield put(actions.addCommentSuccess(feedResp));
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.addCommentError(err.stack!));
    } else {
      yield put(actions.addCommentError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// groups start

function* handleCreatePostGroupRequest(action: ReturnType<typeof actionsGroup.createPostGroupRequest>): any {
  try {
    const newPost = action.payload.post;
    const refreshGroupId = action.payload.refreshGroupId;
    const response = yield call(createSocialPost, newPost);

    if (response.error) {
      yield put(actionsGroup.createPostGroupError(response.error));
    } else {
      if (action.payload.post.photos.length > 0) {
        var formData = new FormData();
        action.payload.post.photos.map((file) => formData.append("files", file));
        yield call(attachPhotosToPost, { postId: response.id, files: formData });
      }
      let feedResp = refreshGroupId
        ? yield call(loadGroupFeed, { groupId: refreshGroupId.toString(), nameId: newPost.authorNameId })
        : null;
      if (feedResp.error || !refreshGroupId) {
        yield put(actionsGroup.groupFeedFetchError(feedResp.error));
      } else {
        yield put(actionsGroup.createPostGroupSuccess(feedResp));
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actionsGroup.createPostGroupError(err.stack!));
    } else {
      yield put(actionsGroup.createPostGroupError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleAddCommentGroupRequest(action: ReturnType<typeof actionsGroup.addCommentGroupRequest>): any {
  try {
    const newComment = action.payload;
    const response = yield call(addComment, newComment);

    if (response.error || !newComment.groupId) {
      yield put(actionsGroup.addCommentGroupError(response.error));
    } else {
      let feedResp = yield call(loadGroupFeed, {
        groupId: newComment.groupId.toString(),
        nameId: newComment.authorNameId,
      });
      if (feedResp.error) {
        yield put(actionsGroup.groupFeedFetchError(feedResp.error));
      } else {
        yield put(actionsGroup.addCommentGroupSuccess(feedResp));
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actionsGroup.addCommentGroupError(err.stack!));
    } else {
      yield put(actionsGroup.addCommentGroupError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleGroupFetch(action: ReturnType<typeof actionsGroup.groupFetchRequest>): any {
  try {
    const rmpropid = action.payload;

    const response = yield call(loadGroupProperty, rmpropid);

    if (response.error) {
      yield put(actionsGroup.groupFetchError(response.error));
    } else {
      yield put(actionsGroup.groupFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actionsGroup.groupFetchError(err.stack!));
    } else {
      yield put(actionsGroup.groupFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleGroupUserFetch(action: ReturnType<typeof actionsGroup.groupUserFetchRequest>): any {
  try {
    const nameid = action.payload;

    const response = yield call(loadGroupUser, nameid);

    if (response.error) {
      yield put(actionsGroup.groupUserFetchError(response.error));
    } else {
      yield put(actionsGroup.groupUserFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actionsGroup.groupUserFetchError(err.stack!));
    } else {
      yield put(actionsGroup.groupUserFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleJoinGroupPostRequest(action: ReturnType<typeof actionsGroup.joinGroupPostRequest>): any {
  try {
    const joinLeaveGroupDto = action.payload.request;
    const response = yield call(postGroupsJoin, joinLeaveGroupDto);

    if (response.error) {
      yield put(actionsGroup.joinGroupPostError(response.error));
    } else {
      yield put(actionsGroup.joinGroupPostSuccess(action.payload.group));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actionsGroup.joinGroupPostError(err.stack!));
    } else {
      yield put(actionsGroup.joinGroupPostError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleLeaveGroupPostRequest(action: ReturnType<typeof actionsGroup.leaveGroupPostRequest>): any {
  try {
    const joinLeaveGroupDto = action.payload.request;
    const response = yield call(postGroupsLeave, joinLeaveGroupDto);

    if (response.error) {
      yield put(actionsGroup.leaveGroupPostError(response.error));
    } else {
      yield put(actionsGroup.leaveGroupPostSuccess(action.payload.group));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actionsGroup.leaveGroupPostError(err.stack!));
    } else {
      yield put(actionsGroup.leaveGroupPostError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleCreateGroupRequest(action: ReturnType<typeof actionsGroup.createGroupRequest>): any {
  try {
    const createGroupDto = action.payload;
    const response = yield call(postGroupsCreate, createGroupDto);

    if (response.error) {
      yield put(actionsGroup.createGroupError(response.error));
    } else {
      yield put(actionsGroup.createGroupSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actionsGroup.createGroupError(err.stack!));
    } else {
      yield put(actionsGroup.createGroupError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleGroupFeedFetch(action: ReturnType<typeof actionsGroup.groupFeedFetchRequest>): any {
  try {
    const response = yield call(loadGroupFeed, action.payload);

    if (response.error) {
      yield put(actionsGroup.groupFeedFetchError(response.error));
    } else {
      yield put(actionsGroup.groupFeedFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actionsGroup.groupFeedFetchError(err.stack!));
    } else {
      yield put(actionsGroup.groupFeedFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}
// groups end

function* handleReportAbuse(action: ReturnType<typeof actionsGroup.reportAbuseRequest>): any {
  try {
    const reportAbuseRequest = action.payload;
    const response = yield call(postReportAbuse, reportAbuseRequest);

    if (response.error) {
      yield put(actionsGroup.reportAbuseError(response.error));
    } else {
      yield put(actionsGroup.reportAbuseSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actionsGroup.reportAbuseError(err.stack!));
    } else {
      yield put(actionsGroup.reportAbuseError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleRsvpRequest(action: ReturnType<typeof actions.rsvpRequest>): any {
  try {
    const rsvpData = action.payload;
    const response = yield call(postRsvp, rsvpData);

    if (response.error) {
      yield put(actions.rsvpError(response.error));
    } else {
      // after successful rsvp call - request the feed from server, to present latest data to the user.
      let events = yield call(loadSocialEvents, rsvpData.nameId);
      if (events.error) {
        yield put(eventsFetchError(events.error));
      } else {
        yield put(actions.rsvpSuccess(events));
        yield put(actions.dashboardFetchRequest(action.payload.nameId));

        if (action.payload.groupId !== undefined && action.payload.groupId !== null) {
          let groupFeedResp = yield call(loadGroupFeed, {
            groupId: action.payload.groupId.toString(),
            nameId: action.payload.nameId,
          });
          if (groupFeedResp.error) {
            yield put(actionsGroup.groupFeedFetchError(groupFeedResp.error));
          } else {
            yield put(actionsGroup.groupFeedFetchSuccess(groupFeedResp));
          }
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.rsvpError(err.stack!));
    } else {
      yield put(actions.rsvpError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleCancelRsvpRequest(action: ReturnType<typeof actions.cancelRsvpRequest>): any {
  try {
    const rsvpData = action.payload;
    const response = yield call(cancelRsvp, rsvpData);

    if (response.error) {
      yield put(actions.cancelRsvpError(response.error));
    } else {
      // after successful cancel rsvp call - request the feed from server, to present latest data to the user.
      let events = yield call(loadSocialEvents, rsvpData.nameId);
      if (events.error) {
        yield put(eventsFetchError(events.error));
      } else {
        yield put(actions.cancelRsvpSuccess(events));
        yield put(actions.dashboardFetchRequest(action.payload.nameId));

        if (action.payload.groupId !== undefined && action.payload.groupId !== null) {
          let groupFeedResp = yield call(loadGroupFeed, {
            groupId: action.payload.groupId.toString(),
            nameId: action.payload.nameId,
          });
          if (groupFeedResp.error) {
            yield put(actionsGroup.groupFeedFetchError(groupFeedResp.error));
          } else {
            yield put(actionsGroup.groupFeedFetchSuccess(groupFeedResp));
          }
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.cancelRsvpError(err.stack!));
    } else {
      yield put(actions.cancelRsvpError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleBlockUserRequest(action: ReturnType<typeof actions.blockUserRequest>): any {
  try {
    const response = yield call(blockUser, action.payload);
    if (response.error) {
      yield put(actions.blockUserError(response.error));
    } else {
      // after successful block user call - request the feed from server, to present latest data to the user.
      let feedResp = yield call(loadSocialFeed, action.payload.blockerNameId);
      if (feedResp.error) {
        yield put(dashboardFetchError(feedResp.error));
      } else {
        yield put(actions.blockUserSuccess(feedResp));
      }
      // after successful block user call from group feed- request the feed from server, to present latest data to the user.
      if (action.payload.selectedGroupId) {
        let groupFeedResp = yield call(loadGroupFeed, {
          groupId: action.payload.selectedGroupId.toString(),
          nameId: action.payload.blockerNameId,
        });
        if (groupFeedResp.error) {
          yield put(actionsGroup.groupFeedFetchError(feedResp.error));
        } else {
          yield put(actionsGroup.groupFeedFetchSuccess(groupFeedResp));
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.blockUserError(err.stack!));
    } else {
      yield put(actions.blockUserError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleHidePostRequest(action: ReturnType<typeof actions.hidePostRequest>): any {
  try {
    const response = yield call(hidePost, action.payload.postId);
    if (response.error) {
      yield put(actions.hidePostError(response.error));
    } else {
      if (action.payload.groupId) {
        let feedRespGroup = yield call(loadGroupFeed, {
          groupId: action.payload.groupId.toString(),
          nameId: action.payload.authorNameId,
        });
        if (feedRespGroup.error) {
          yield put(actionsGroup.groupFeedFetchError(feedRespGroup.error));
        } else {
          yield put(actionsGroup.groupFeedFetchSuccess(feedRespGroup));
        }
      }

      let feedResp = yield call(loadSocialFeed, action.payload.authorNameId);
      if (feedResp.error) {
        yield put(dashboardFetchError(feedResp.error));
      } else {
        yield put(actions.hidePostSuccess(feedResp));
      }
      let responseEventLoad = yield call(loadSocialEvents, action.payload.authorNameId);
      if (responseEventLoad.error) {
        yield put(eventsFetchError(responseEventLoad.error));
      } else {
        yield put(eventsFetchSuccess(responseEventLoad));
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.hidePostError(err.stack!));
    } else {
      yield put(actions.hidePostError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleHideCommentRequest(action: ReturnType<typeof actions.hideCommentRequest>): any {
  try {
    const response = yield call(hideComment, action.payload.commentId);
    if (response.error) {
      yield put(actions.hideCommentError(response.error));
    } else {
      if (action.payload.groupId) {
        let feedRespGroup = yield call(loadGroupFeed, {
          groupId: action.payload.groupId.toString(),
          nameId: action.payload.authorNameId,
        });
        if (feedRespGroup.error) {
          yield put(actionsGroup.groupFeedFetchError(feedRespGroup.error));
        } else {
          yield put(actionsGroup.groupFeedFetchSuccess(feedRespGroup));
        }
      }

      let feedResp = yield call(loadSocialFeed, action.payload.authorNameId);
      if (feedResp.error) {
        yield put(dashboardFetchError(feedResp.error));
      } else {
        yield put(actions.hideCommentSuccess(feedResp));
      }
      let responseEventLoad = yield call(loadSocialEvents, action.payload.authorNameId);
      if (responseEventLoad.error) {
        yield put(eventsFetchError(responseEventLoad.error));
      } else {
        yield put(eventsFetchSuccess(responseEventLoad));
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.hideCommentError(err.stack!));
    } else {
      yield put(actions.hideCommentError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleUserInfoFetch(action: ReturnType<typeof actions.feedUserInfoFetchRequest>): any {
  try {
    const response = yield call(loadUserInfo, action.payload);
    if (response.error) {
      yield put(actions.feedUserInfoFetchError(response.error));
    } else {
      yield put(actions.feedUserInfoFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.feedUserInfoFetchError(err.stack!));
    } else {
      yield put(actions.feedUserInfoFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchRequests() {
  yield takeEvery(getType(actions.dashboardFetchRequest), handleFetch);
  yield takeEvery(getType(actions.dashboardFetchMoreRequest), handleMoreFetch);
  yield takeEvery(getType(actions.createPostRequest), handleCreatePostRequest);
  yield takeEvery(getType(actions.hidePostRequest), handleHidePostRequest);
  yield takeEvery(getType(actionsGroup.createPostGroupRequest), handleCreatePostGroupRequest);
  yield takeEvery(getType(actions.eventsFetchRequest), handleFetchEvents);
  yield takeEvery(getType(actions.addCommentRequest), handleAddCommentRequest);
  yield takeEvery(getType(actions.hideCommentRequest), handleHideCommentRequest);
  yield takeEvery(getType(actionsGroup.addCommentGroupRequest), handleAddCommentGroupRequest);
  yield takeEvery(getType(actionsGroup.groupFetchRequest), handleGroupFetch);
  yield takeEvery(getType(actionsGroup.groupUserFetchRequest), handleGroupUserFetch);
  yield takeEvery(getType(actionsGroup.joinGroupPostRequest), handleJoinGroupPostRequest);
  yield takeEvery(getType(actionsGroup.leaveGroupPostRequest), handleLeaveGroupPostRequest);
  yield takeEvery(getType(actionsGroup.createGroupRequest), handleCreateGroupRequest);
  yield takeEvery(getType(actionsGroup.reportAbuseRequest), handleReportAbuse);
  yield takeEvery(getType(actionsGroup.groupFeedFetchRequest), handleGroupFeedFetch);
  yield takeEvery(getType(actions.rsvpRequest), handleRsvpRequest);
  yield takeEvery(getType(actions.cancelRsvpRequest), handleCancelRsvpRequest);
  yield takeEvery(getType(actions.blockUserRequest), handleBlockUserRequest);
  yield takeEvery(getType(actions.feedUserInfoFetchRequest), handleUserInfoFetch);
  yield takeEvery(getType(actions.postUpdateRequest), handleUpdatePost);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* socialSaga() {
  yield all([fork(watchRequests)]);
}

export default socialSaga;
