import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Link,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import DialogHeader from "../../shared/components/DialogHeader";
import { UserInfoItem } from "../../userInfo/models";
import { insertRenewalRequest } from "../actions";
import {
  NewRenewal,
  RenewalOfferFeeOrDiscount,
  RenewalOfferTerm,
  RenewalOtherCharge,
  RenewalText,
  RpActivity
} from "../models";
import eventstyles from "../styles";
import RenewalTerms from "./RenewalTerms";

const styles = combinestyles(eventstyles, globalstyles);

interface RenewalBalanceProps extends WithWidth, WithStyles<typeof styles> {
  optionTerm: RenewalOfferTerm[];
  renewalOtherCharges: RenewalOtherCharge[];
  legalText: RenewalText[];
  oneTimeDiscount: RenewalOfferFeeOrDiscount[];
}

interface PropsFromState {
  user?: UserInfoItem;
}

interface PropsFromDispatch {
  insertRenewalRequest: typeof insertRenewalRequest;
  logActivity: typeof logActivity;
}

type AllProps = RenewalBalanceProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

type State = {
  open: boolean;
  openAgreement: boolean;
  selectedTerm: RenewalOfferTerm;
};

class RenewalBalance extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    open: false,
    openAgreement: false,
    selectedTerm: this.props.optionTerm[0]
  };

  handleClickOpen = (terms: RenewalOfferTerm) => {
    this.setState({ open: true });
  };

  handleClickOpenAgreement = () => {
    this.setState({ openAgreement: true });
  };

  handleClose = () => {
    this.setState({ open: false, openAgreement: false });
  };

  submitValues = (term: RenewalOfferTerm) => {
    // tslint:disable-next-line:no-console

    this.setState({ open: false, selectedTerm: term });
  };

  submitRenewal = (term: string, amount: string) => {
    this.setState({ openAgreement: false });

    // Save to Accept Renewal to database
    const obj: NewRenewal = { resNameId: this.props.user!.nameId, term: term, amount: amount };
    this.props.insertRenewalRequest(obj);

    // Save to Activity
    const newActivity: RpActivity = {
      nameid: this.props.user!.nameId,
      actcode: "RN",
      note: "Renewal on Term: " + term + " months and Amount: " + amount + " was accepted by resident",
      appsource: "WEBRP"
    };
    this.props.logActivity(newActivity);

    //Open Renewal Confirm Page
    this.props.history.push("/renewal/RenewalConfirm");
  };

  currencyFormatter(balance: any) {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    });
    return balance < 0 ? "(" + formatter.format(-balance) + ")" : formatter.format(balance);
  }

  currencyToNumber(balance: string) {
    return Number(balance.replace(/[^0-9.-]+/g, ""));
  }

  renderItem(otherCharges: RenewalOtherCharge) {
    return (
      <ListItem key={otherCharges.amount} disableGutters>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body1">{otherCharges.description}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" align="right">
              {this.currencyFormatter(otherCharges.unformattedAmount)}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  }

  renderDiscountItem(discount: RenewalOfferFeeOrDiscount) {
    return (
      <ListItem key={discount.amount} disableGutters>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body1">{discount.description}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" align="right">
              {this.currencyFormatter(discount.unformattedAmount)}
            </Typography>
          </Grid>
        </Grid>
      </ListItem>
    );
  }

  render() {
    const { renewalOtherCharges, oneTimeDiscount } = this.props;
    this.renderItem = this.renderItem.bind(this);
    this.renderDiscountItem = this.renderDiscountItem.bind(this);
    const negotiatedPricedDifferent =
      this.state.selectedTerm.negAmount &&
      this.state.selectedTerm.negAmount !== this.currencyToNumber(this.state.selectedTerm.amount);
    const monthlyPriceStyle = negotiatedPricedDifferent ? { textDecoration: "line-through" } : undefined;

    return (
      <div>
        <Table style={{ marginBottom: "2rem" }}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="body1">{this.state.selectedTerm.term} month lease</Typography>
              </TableCell>
              {this.props.optionTerm.length > 1 ? (
                <TableCell align="right">
                  <Link onClick={(e: any) => this.handleClickOpen(this.state.selectedTerm)}>
                    <Typography variant="subtitle2" className={this.props.classes.link}>
                      View all lease terms
                    </Typography>
                  </Link>
                </TableCell>
              ) : (
                <TableCell align="right" />
              )}
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="body1">Monthly Rent</Typography>
              </TableCell>
              <TableCell>
                <Typography style={monthlyPriceStyle} variant="body1" align="right">
                  {this.state.selectedTerm.amount}
                </Typography>
              </TableCell>
            </TableRow>
            {negotiatedPricedDifferent && (
              <TableRow>
                <TableCell>
                  <Typography variant="body1">Negotiated Monthly Rent</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1">{this.currencyFormatter(this.state.selectedTerm.negAmount)}</Typography>
                </TableCell>
              </TableRow>
            )}
            {renewalOtherCharges && renewalOtherCharges.length > 0 && (
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="subtitle2" style={{ paddingTop: ".5rem" }}>
                    Recurring Monthly Charges
                  </Typography>
                  <List>{renewalOtherCharges.map(this.renderItem)}</List>
                </TableCell>
              </TableRow>
            )}
            {oneTimeDiscount && oneTimeDiscount.length > 0 && (
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="subtitle2" style={{ paddingTop: ".5rem" }}>
                    One-time Renewal Fees and Discounts
                  </Typography>
                  <List>{oneTimeDiscount.map(this.renderDiscountItem)}</List>
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">Total Monthly Charges:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" align="right">
                  {this.state.selectedTerm.recurringCost}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          spacing={16}
          alignItems="center"
          style={{ paddingBottom: "2rem" }}
        >
          <Grid item>
            <Typography align="left">By accepting this offer you agree to the terms below. </Typography>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              autoFocus
              variant="contained"
              size="large"
              onClick={(e: any) => this.submitRenewal(this.state.selectedTerm.term, this.state.selectedTerm.amount)}
            >
              Accept Renewal
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <Grid item style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}>
            <DialogContent className={this.props.classes.scrollbox}>
              <List>
                {this.props.legalText.map(x =>
                  LegalTextData(
                    x,
                    this.state.selectedTerm.term,
                    this.state.selectedTerm.amount,
                    this.state.selectedTerm.recurringCost
                  )
                )}
              </List>
            </DialogContent>
          </Grid>
        </Grid>

        {/* Select Term Dialog */}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullScreen={this.props.width === "xs" ? true : false}
          fullWidth
          maxWidth="sm"
        >
          <DialogHeader title="Lease Terms" handleClose={this.handleClose} />
          <RenewalTerms
            termsList={this.props.optionTerm}
            onClose={this.handleClose}
            handleSubmit={this.submitValues}
            preSelectedTerm={this.state.selectedTerm}
          />
        </Dialog>
      </div>
    );
  }
}

const LegalTextData = (x: RenewalText, term: string, amount: string, totalamount: string) => {
  return (
    <Typography variant="body2" dangerouslySetInnerHTML={{ __html: x.letterText
      .replace('"" + term + ""', term)
      .replace('"" + amount + ""', amount)
      .replace('"" + recuramount +""', totalamount)
      .replace('"" + ', "") }}>
    </Typography>
  );
};

//export default withStyles(styles)(RenewalBalance);

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount)
});

const mapDispatchToProps: PropsFromDispatch = {
  insertRenewalRequest: (act: NewRenewal) => insertRenewalRequest(act),
  logActivity: (act: RpActivity) => logActivity(act)
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles)(RenewalBalance)))
);
