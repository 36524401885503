import { Theme, createStyles } from "@material-ui/core";

const styles = ({ spacing, breakpoints, mixins, transitions }: Theme) =>
  createStyles({
    headerlist: {
      [breakpoints.up("sm")]: {
        marginBottom: spacing.unit * 3
      }
    },
    link: {
      color: "#3892d1",
      textDecoration: "none"
    },
    tablepadding: {
      marginBottom: spacing.unit * 2
    },
    grouping: {
      marginBottom: "1rem"
    }
  });
export default styles;
