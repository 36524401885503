import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { User } from "oidc-client";
import { combineReducers } from "redux";
import { reducer as oidcReducer } from "redux-oidc";

import amenityReducer, { AmenityState } from "../features/amenity/reducer";
import contactReducer, { ContactState } from "../features/contact/reducer";
import documentsReducer, { DocumentsState } from "../features/documents/reducer";
import noticeReducer, { noticeState } from "../features/entv/reducer";
import modalReducer, { ModalState } from "../features/errorhandling/reducer";
import formerResidentReducer, { FormerResidentState } from "../features/formerresident/reducer";
import guestReducer, { GuestState } from "../features/guest/reducer";
import myaccountdashboardReducer, { MyAccountDashboardState } from "../features/myaccount/reducer";
import leaseReducer, { leaseState } from "../features/mylease/reducer";
import profileReducer, { ProfileState } from "../features/myprofile/reducer";
import statementReducer, { StatementState } from "../features/mystatement/reducer";
import newsReducer, { NewsState } from "../features/news/reducer";
import notificationsReducer, { NotificationsState } from "../features/notifications/reducer";
import paymentReducer, { PaymentState } from "../features/payment/reducer";
import propertyDocumentsReducer, { PropertyDocumentState } from "../features/propertydocuments/reducer";
import referFriendReducer, { ReferFriendState } from "../features/referfriend/reducer";
import renewalReducer, { RenewalState } from "../features/renewal/reducer";
import rentableReducer, { RentableState } from "../features/rentable/reducer";
import selectAccountReducer, { SelectAccountState } from "../features/selectAccount/reducer";
import svcProvidersReducer, { SvcProviderState } from "../features/serviceproviders/reducer";
import servicereqsReducer, { ServiceReqsState } from "../features/servicereqs/reducer";
import socialReducer, { SocialState } from "../features/social/reducer";
import socialGroupReducer, { GroupState } from "../features/social/reducerGroup";
import userInfoReducer, { UserInfoState } from "../features/userInfo/reducer";
import newresidentReducer, { NewResidentState } from "../features/newresident/reducer";
import applicantReducer, { ApplicantChecklistState } from "../features/applicant/reducer";
import uploadsReducer, { UploadsState } from "../features/uploads/reducer";
import communityGuideReducer, { CommunityGuideState } from "../features/communityguide/reducer";
import adverseActionLetterReducer, { AdverseActionLetterState } from "../features/adverseactionletter/reducer";
import creditReportReducer, { CreditReportState } from "../features/creditreport/reducer";
import criminalReportReducer, { CriminalReportState } from "../features/criminalreport/reducer";
import docuSignReducer, { DocuSignState } from "../features/docusignlease/reducer";

// Add For Rent With Equity
// import { rentwithequityReducer, RentWithEquityState } from "../features/rentwithequity";
// rentwithequity: rentwithequityReducer,

const rootReducer = (history: History) =>
  combineReducers({
    servicereqs: servicereqsReducer,
    applicant: applicantReducer,
    communityGuide: communityGuideReducer,
    adverseActionLetter: adverseActionLetterReducer,
    auth: oidcReducer,
    news: newsReducer,
    contact: contactReducer,
    guest: guestReducer,
    userInfo: userInfoReducer,
    selectAccount: selectAccountReducer,
    social: socialReducer,
    socialGroup: socialGroupReducer,
    statement: statementReducer,
    serviceproviders: svcProvidersReducer,
    lease: leaseReducer,
    profile: profileReducer,
    amenity: amenityReducer,
    rentable: rentableReducer,
    renewal: renewalReducer,
    payment: paymentReducer,
    propertydocuments: propertyDocumentsReducer,
    formerresident: formerResidentReducer,
    myaccountdashboard: myaccountdashboardReducer,
    notifications: notificationsReducer,
    documents: documentsReducer,
    referFriend: referFriendReducer,
    noticeToVacate: noticeReducer,
    errorHandling: modalReducer,
    router: connectRouter(history),
    newresident: newresidentReducer,
    uploads: uploadsReducer,
    creditReport: creditReportReducer,
    criminalReport: criminalReportReducer,
    docuSignLease: docuSignReducer,
  });

interface OidcState {
  isLoadingUser: boolean;
  user: User;
}

export interface RootState {
  servicereqs: ServiceReqsState;
  communityGuide: CommunityGuideState;
  adverseActionLetter: AdverseActionLetterState;
  auth: OidcState;
  news: NewsState;
  contact: ContactState;
  guest: GuestState;
  userInfo: UserInfoState;
  selectAccount: SelectAccountState;
  social: SocialState;
  socialGroup: GroupState;
  statement: StatementState;
  serviceproviders: SvcProviderState;
  profile: ProfileState;
  amenity: AmenityState;
  rentable: RentableState;
  renewal: RenewalState;
  router: RouterState;
  lease: leaseState;
  payment: PaymentState;
  propertydocuments: PropertyDocumentState;
  //rentwithequity: RentWithEquityState;
  formerresident: FormerResidentState;
  newresident: NewResidentState;
  myaccountdashboard: MyAccountDashboardState;
  notifications: NotificationsState;
  documents: DocumentsState;
  applicant: ApplicantChecklistState;
  referFriend: ReferFriendState;
  noticeToVacate: noticeState;
  errorHandling: ModalState;
  uploads: UploadsState;
  creditReport: CreditReportState;
  criminalReport: CriminalReportState;
  docuSignLease: DocuSignState;
}

//export type RootState = StateType<typeof rootReducer>;

export default rootReducer;
