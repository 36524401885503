import { Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import { insertAutoPaymentRequest } from "../actions";
import { PaymentInputMppData, PaymentUrlDataResult } from "../models";
import paymentstyles from "../styles";

const styles = combinestyles(paymentstyles, globalstyles);
//import { push } from 'connected-react-router';

interface IPaymentProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
}

interface IPageState {}

interface PropsFromState {
  user?: UserInfoItem;
  loading: boolean;
  paymentUrlDataResult: PaymentUrlDataResult;
  errors?: string;
}

interface PropsFromDispatch {
  insertAutoPayment: typeof insertAutoPaymentRequest;
}

type AllProps = IPaymentProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

class PaymentAutoPay extends React.Component<AllProps & { dispatch: any }, {}, IPageState> {
  state: IPageState = {};

  componentDidMount() {
    const paymentInput: PaymentInputMppData = {
      nameId: this.props.user!.nameId,
      paymentType: "autopay",
      applicationSource: "WEBRP"
    };

    this.props.insertAutoPayment(paymentInput);
  }

  render() {
    if (!this.props.paymentUrlDataResult) {
      return <div />;
    }

    window.location.href = this.props.paymentUrlDataResult.mppUrl.toString();
    //window.location.href = 'https://rentpayment.com/pay/menu.html?ta=pay&p=wsh9tx3q44bkuanpugjipu777qcru9r2xajd6u9s';

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Paper className={this.props.classes.paper}>
            <Typography variant="body1">We are securely logging you into the payment website.</Typography>
            <Typography variant="body1">
              Please be patient and do not leave this page, the process might take a minute or two.
            </Typography>
          </Paper>
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ payment, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  paymentUrlDataResult: payment.paymentUrl.data!,
  loading: payment.paymentUrl.loading,
  errors: payment.paymentUrl.errors
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  insertAutoPayment: (payment: PaymentInputMppData) => dispatch(insertAutoPaymentRequest(payment))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentAutoPay)));
