import { Button, CircularProgress, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import { Formik } from "formik";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as Yup from "yup";

import globalstyles from "../../../../global-styles";
import { RootState } from "../../../../store/root-reducer";
import combinestyles from "../../../../utils/combinestyles";
import { getActiveUserInfo } from "../../../selectAccount/selector";
import { logActivity } from "../../../shared/actions";
import DialogHeader from "../../../shared/components/DialogHeader";
import { UserInfoItem } from "../../../userInfo/models";
import { createPostRequest } from "../../actions";
import { groupFetchRequest } from "../../actionsGroup";
import { CreatePostDto, Group, RpActivity } from "../../models";
import eventstyles from "../styles";
import EventForm from "./EventForm";

const styles = combinestyles(eventstyles, globalstyles);

const eventFormValidation = Yup.object({
  postType: Yup.string(),
  title: Yup.string().required("Title is required").max(100, "Character limit 100"),
  content: Yup.string().required("Content is required"),
  date: Yup.date()
    .nullable()
    .required("Please enter a valid date")
    .min(moment(new Date()).subtract(1, "days").toDate(), "Date must be in the future"),
  edate: Yup.date()
    .nullable()
    .required("Please enter a valid date")
    .min(Yup.ref("date"), "End Date must be after the Start Date"),
  location: Yup.string().required("Location is required").max(250, "Character limit 250"),
  starttime: Yup.date()
    .nullable()
    .required("Start Time is required")
    .when("date", {
      is: (date: Date) => date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0),
      then: Yup.date().min(moment(Date.now()).toDate(), "Start Time must be in the future"),
    }),
  endtime: Yup.date()
    .nullable()
    .test("required-If", "End Time must be after the Start Time", function () {
      const check = checkDates(this.parent, {
        dependent1: "date",
        dependent2: "edate",
        dependent3: "starttime",
        dependent4: "endtime",
      });
      return check;
    }),
  rsvp: Yup.string().required("RSVP is required").oneOf(["true", "false"], "RSVP is required"),
});

const checkDates = (parent: any, { dependent1, dependent2, dependent3, dependent4 }: any) => {
  const startDay = parent && parent[dependent1];
  const endDay = parent && parent[dependent2];
  const startTime = parent && parent[dependent3];
  const endTime = parent && parent[dependent4];

  let vv = true;
  if (moment(startDay).isSame(moment(endDay)) && moment(startTime).isAfter(moment(endTime))) {
    vv = false;
  }

  return vv;
};

interface Props extends WithWidth, WithStyles<typeof styles> {}

interface PropsFromState {
  user?: UserInfoItem;
  groups: Group[];
  posting: boolean;
}

interface PropsFromDispatch {
  createPostRequest: typeof createPostRequest;
  groupFetchRequest: typeof groupFetchRequest;
  logActivity: typeof logActivity;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

type MyState = {
  open: boolean;
  filesToUpload: File[];
  modalVisibility: boolean;
};

export type EventFormInputState = {
  postType: string;
  title: string;
  content: string;
  photo: any;
  location: string;
  date: Date | null;
  edate: Date | null;
  rsvp: boolean | undefined;
  starttime: Date | null;
  endtime: Date | null;
  groupId: number | null;
};

type State = MyState & EventFormInputState;

class NewEvent extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    open: false,
    postType: "Event",
    title: "",
    content: "",
    photo: undefined,
    location: "",
    date: null,
    edate: null,
    rsvp: undefined,
    starttime: null,
    endtime: null,
    groupId: null,
    filesToUpload: [],
    modalVisibility: false,
  };

  submitEventForm = ({ postType, title, content, location, date, edate, rsvp, starttime, endtime }: State) => {
    if (this.props.posting) return;
    if (date !== undefined && date !== null && starttime !== undefined && starttime !== null) {
      let startDate = new Date(date);
      startDate.setUTCHours(starttime.getHours());
      startDate.setUTCMinutes(starttime.getMinutes());
      if (edate !== undefined && edate !== null && endtime !== undefined && endtime !== null) {
        let endDate = new Date(edate);
        endDate.setUTCHours(endtime.getHours());
        endDate.setUTCMinutes(endtime.getMinutes());

        this.props.logActivity({
          actcode: "PE",
          note: "Resident submitted an event",
        });

        const newPost: CreatePostDto = {
          authorNameId: this.props.user!.nameId,
          title: title,
          itemType: postType,
          location: location,
          message: content,
          date: date,
          eDate: edate,
          rsvpEnabled: rsvp ? rsvp : false,
          startDate: startDate,
          endDate: endDate,
          groupId: this.state.groupId,
          photos: this.state.filesToUpload,
          communityPost: false,
          guestsAllowed: false,
          maxAttendees: 0,
          maxGuests: 0,
        };
        this.handleOpenModal();
        this.setState({ filesToUpload: [] });
        this.props.createPostRequest(newPost);
      }
    }
    this.closeEventDialog();
  };

  getFilesToUpload = (files: File[]) => {
    this.setState({ filesToUpload: files });
  };

  handleGroupSelection = (value: any) => {
    if (!value || value === "0") {
      this.setState({ groupId: null });
    } else {
      this.setState({ groupId: Number(value) });
    }
  };

  openEventDialog = () => {
    this.setState({ open: true });
  };

  closeEventDialog = () => {
    this.setState({ open: false });
  };

  handleOpenModal = () => {
    this.setState({ modalVisibility: true });
  };
  handleCloseModal = () => {
    this.setState({ modalVisibility: false });
  };

  render() {
    const { postType, title, content, photo, location, open, date, edate, rsvp, starttime, endtime } = this.state;
    const values = {
      postType: postType,
      open: open,
      title: title,
      content: content,
      photo: photo,
      location: location,
      date: date,
      edate: edate,
      rsvp: rsvp,
      starttime: starttime,
      endtime: endtime,
      groupId: this.state.groupId,
    };

    return (
      <React.Fragment>
        <Button variant="contained" color="primary" onClick={this.openEventDialog}>
          + New Event
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.closeEventDialog}
          fullScreen={this.props.width === "xs" ? true : false}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle style={{ padding: "0" }}>
            <DialogHeader title="Create a new event" handleClose={this.closeEventDialog} />
          </DialogTitle>
          <DialogContent>
            <Formik
              render={(props) => (
                <EventForm
                  {...props}
                  groups={this.props.groups}
                  handleGroupSelection={this.handleGroupSelection}
                  getFilesToUpload={this.getFilesToUpload.bind(this)}
                />
              )}
              initialValues={values}
              validationSchema={eventFormValidation}
              onSubmit={this.submitEventForm}
            />
            {this.props.posting && (
              <Dialog open={this.state.modalVisibility} onClose={this.handleCloseModal}>
                <DialogContent>
                  <CircularProgress />
                </DialogContent>
              </Dialog>
            )}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ selectAccount, socialGroup, social }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  groups: socialGroup.groupUserList.dataUser,
  posting: social.posting,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  logActivity: (activity: RpActivity) => dispatch(logActivity(activity)),
  createPostRequest: (payload: CreatePostDto) => dispatch(createPostRequest(payload)),
  groupFetchRequest: (nameId: string) => dispatch(groupFetchRequest(nameId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles)(NewEvent)));
