import * as React from "react";
import { connect } from "react-redux";

import { RootState } from "../../../store/root-reducer";

import { Grid, Typography, Button, withStyles, WithStyles } from "@material-ui/core";
import { GuestItem } from "../models";
import { RouteComponentProps } from "react-router";
import { viewDetails, guesttypesFetchRequest } from "../actions";
import { viewForm } from "../actions";
import { withRouter } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

import rentableitemstyles from "../../../styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(rentableitemstyles, globalstyles);

export interface PropsFromState {
  items: GuestItem[];
  guestTypes: Map<string, string>;
}

interface PropsFromDispatch {
  viewDetails: (item: GuestItem) => any;
  viewForm: (item: GuestItem) => any;
  getGuestTypes: () => any;
}

interface GuestListProps extends WithStyles<typeof styles> {}

type State = {
  expanded: boolean;
  openRemove: boolean;
};

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps & GuestListProps;

class GuestHeader extends React.Component<AllProps, State> {
  // this.setState({ openRemove: false });

  handleClose = () => {
    this.setState({ openRemove: false });
  };

  render() {
    const newGuest: GuestItem = {
      type: "",
      startDate: new Date(Date.now()),
      endDate: new Date(Date.now()),
      notes: "",
      guestInformation: "",
      permanent: false,
      allowKeys: false,
      inactive: false,
      deletePhoto: false,
      guestTitle: "",
      id: 0,
      nameId: "",
      userId: "",
      authorizedBy: "",
      authorizedDate: new Date(Date.now()),
      lastModifiedDate: new Date(Date.now()),
      guestPhotoUrl: ""
    };
    return (
      <Grid container className={this.props.classes.header}>
        <Grid item xs>
          <Typography variant="h1">Guest Access</Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Button onClick={() => this.props.viewForm(newGuest)} variant="contained" color="primary" aria-label="Add">
            <AddIcon />
            Add New Guest
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ guest }: RootState): PropsFromState => ({
  items: guest.guestList.data,
  guestTypes: guest.guestForm.guestTypes
});

const mapDispatchToProps: PropsFromDispatch = {
  viewDetails: (item: GuestItem) => viewDetails(item),
  viewForm: (item: GuestItem) => viewForm(item),
  getGuestTypes: () => guesttypesFetchRequest()
};

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(GuestHeader)));
