import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";

import * as actions from "./actions";
import { DigitalDocument } from "./models";

export type DocumentsAction = ActionType<typeof actions>;

export type DocumentsState = Readonly<{
  loading: boolean;
  documentList: DigitalDocument[];
  errors?: string;
  downloadError: boolean;
}>;

const initialState: DocumentsState = {
  documentList: [],
  errors: undefined,
  loading: false,
  downloadError: false
};

const reducer: Reducer<DocumentsState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.getDocumentsRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.getDocumentsSuccess): {
      return { ...state, loading: false, documentList: action.payload };
    }
    case getType(actions.getDocumentsError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.downloadDocumentRequest): {
      return { ...state, loading: true, downloadError: false };
    }
    case getType(actions.downloadDocumentSuccess): {
      return { ...state, loading: false };
    }
    case getType(actions.downloadDocumentError): {
      return { ...state, loading: false, errors: action.payload, downloadError: true };
    }
    case getType(actions.resetDownloadError): {
      return { ...state, downloadError: false };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
