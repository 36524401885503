import * as React from "react";
import { Typography, WithStyles, withStyles } from "@material-ui/core";

import eventstyles from "../styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(eventstyles, globalstyles);

export interface IRenewalCaptionProps extends WithStyles<typeof styles> {}

type State = {
  mobileOpen: boolean;
};

class RenewalCaption extends React.Component<IRenewalCaptionProps> {
  readonly state: State = { mobileOpen: false };

  handleClose = () => {
    this.setState({ openRemove: false });
  };

  render() {
    return (
      <Typography variant="body1" style={{ marginBottom: "2rem" }}>
        Click Accept Renewal and we will send your new lease to be signed electronically.
      </Typography>
    );
  }
}

export default withStyles(styles)(RenewalCaption);
