import { red } from "@material-ui/core/colors";
import { Theme, createStyles } from "@material-ui/core";
import spacing from "@material-ui/core/styles/spacing";
import transitions from "@material-ui/core/styles/transitions";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginBottom: 24
      //paddingTop: spacing.unit * 7
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: transitions.create("transform", {
        duration: transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    avatar: {
      backgroundColor: red[500]
    },
    leftIconGutter: {
      width: "40px",
      flex: "0 0 auto",
      marginRight: 16
    },
    feedItemContent: {
      paddingTop: "0px"
    },
    textField: {
      marginLeft: spacing.unit,
      marginRight: spacing.unit
    },
    poster: {
      fontSize: "0.875rem",
      fontWeight: 500
    },
    posterGrid: {
      paddingBottom: 0
    },
    postDateTime: {
      fontSize: "0.875rem",
      fontWeight: 300
    },
    title: {
      fontWeight: 300
    },
    icon: {
      // display: "flex",
      // justifyContent: "center",
      // alignItems: "center"
      textAlign: "center",
      width: "2.5rem"
    },
    arrowIcon: {
      marginTop: 0.625 * spacing.unit
    }
  });
export default styles;
