import React, { Component } from "react";
import { SvcProviderSvcType } from "../models";
import { Grid, Typography, withStyles, WithStyles } from "@material-ui/core";

import serviceproviderstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(serviceproviderstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  services: SvcProviderSvcType[];
}

class SvcProviderTileSvcs extends Component<Props> {
  render() {
    const { services } = this.props;

    return (
      <Grid container spacing={8}>
        {services.map((svc) => (
          <Grid item key={svc.id}>
            <Grid container alignItems="center">
              <Grid item>
                <img
                  alt="null"
                  src={`https://media.equityapartments.com/image/upload/Content/portal/serviceproviders/${svc.iconFile}`}
                  className={this.props.classes.icon}
                />
              </Grid>
              <Grid item>
                <Typography>{svc.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }
}
export default withStyles(styles)(SvcProviderTileSvcs);
