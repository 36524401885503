import { Theme, createStyles } from "@material-ui/core";

const styles = ({ spacing, breakpoints, mixins, transitions }: Theme) =>
  createStyles({
    centerElement: {},
    gridItems: {
      minHeight: "10rem",
      backgroundColor: "aqua"
    },
    headerlist: {
      [breakpoints.up("sm")]: {
        marginBottom: spacing.unit * 3
      }
    },
    icon: {
      [breakpoints.up("sm")]: {
        marginRight: spacing.unit * 3.125
      }
    },
    row: {
      [breakpoints.up("sm")]: {
        marginBottom: spacing.unit * 2.25
      }
    },
    rowcontents: {
      [breakpoints.up("sm")]: {
        paddingBottom: spacing.unit * 2.25
      }
    },
    textField: {
      marginLeft: spacing.unit,
      marginRight: spacing.unit
    },
    iconRow: {
      [breakpoints.up("sm")]: {
        minHeight: "3rem"
      }
    },
    amenityRow: {
      [breakpoints.up("sm")]: {
        minHeight: "4rem"
      }
    },
    buttonRow: {
      [breakpoints.up("sm")]: {
        minHeight: "3rem"
      }
    },
    historyList: {
      minHeight: "200px !important",
      [breakpoints.up("sm")]: {
        minHeight: "100px !important"
      }
    },
    datepicker: {
      fontSize: "1rem",
      border: 0,
      borderBottom: "solid 1px rgba(0, 0, 0, 0.42)",
      paddingBottom: ".5rem"
    },
    dropdown: {
      fontSize: "1rem",
      border: 0,
      borderBottom: "solid 1px rgba(0, 0, 0, 0.42)",
      paddingBottom: ".5rem",
      width: "75%"
    },
    dropdowncomponent: {
      border: 0,
      padding: "0 52px 0 0",
      fontFamily: `'Roboto',sans-serif`
    },
    dropdownplaceholder: {
      fontWeight: 300
    },
    dropdownmenu: {
      fontFamily: `'Roboto',sans-serif`,
      fontWeight: 300
    }
  });
export default styles;
