import { combineReducers } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { RenewalData } from "./models";
import * as renewal from "./actions";

export type RenewalAction = ActionType<typeof renewal>;

export type RenewalState = Readonly<{
  renewalData: RenewalDataState;
}>;

export type RenewalDataState = Readonly<{
  loading: boolean;
  data: RenewalData | undefined;
  errors?: string;
}>;

const initialRenewalDataState: RenewalDataState = {
  data: undefined,
  errors: undefined,
  loading: false,
};

// tslint:disable-next-line:no-unused-expression

export default combineReducers<RenewalState, RenewalAction>({
  renewalData: (state = initialRenewalDataState, action) => {
    switch (action.type) {
      case getType(renewal.renewalFetchRequest): {
        return { ...state, loading: true };
      }
      case getType(renewal.renewalFetchSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(renewal.renewalFetchError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
});
