import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import Dropdown from "react-dropdown";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import TruncateMessage from "../../shared/components/TruncateMessage";
import { UserInfoItem } from "../../userInfo/models";
import { cancelRsvpRequest, rsvpRequest } from "../actions";
import * as constants from "../constants";
import { EventItem, RsvpDto } from "../models";
import CommentsContainer from "./CommentsContainer";
import PhotoGallery from "./common/PhotoGallery";
import FeedItemHeader from "./FeedItemHeader";
import socialstyles from "./styles";

const styles = combinestyles(socialstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  item: EventItem;
  feedType?: string;
}

interface PropsFromState {
  user?: UserInfoItem;
}

interface PropsFromDispatch {
  rsvpRequest: typeof rsvpRequest;
  cancelRsvpRequest: typeof cancelRsvpRequest;
}

type AllProps = Props & PropsFromState & PropsFromDispatch;

interface State {
  expanded: boolean;
  modalVisibility: boolean;
  guestListCount: string[];
  groupSize: string;
}

class EventComponent extends React.Component<AllProps, State> {
  state: Readonly<State> = { expanded: true, modalVisibility: false, guestListCount: [], groupSize: "" };

  componentDidMount = () => {
    this.setMaxGuestSize();
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  setMaxGuestSize = () => {
    let count = [];
    for (let i = 1; i <= this.props.item.maxGuests; i++) {
      count.push(i.toString());
    }
    this.setState({ guestListCount: count, groupSize: "1" });
  };

  handleGroupSizeChange = (groupSize: any) => {
    this.setState({ groupSize: groupSize });
  };

  submitRsvp = (item: EventItem) => {
    if (item.attending === true) {
      if (item.guestsAllowed === true) {
        // cancel rsvp
        const requestObj: RsvpDto = {
          nameId: this.props.user!.nameId,
          eventId: item.id,
          groupId: 0,
          totalAttendees: parseInt(this.state.groupSize),
        };
        this.props.cancelRsvpRequest(requestObj);
        this.handleCloseModal();
      } else {
        // cancel rsvp
        const requestObj: RsvpDto = {
          nameId: this.props.user!.nameId,
          eventId: item.id,
          groupId: 0,
          totalAttendees: 1,
        };
        this.props.cancelRsvpRequest(requestObj);
      }
    } else {
      if (item.guestsAllowed === true) {
        //create rsvp
        const requestObj: RsvpDto = {
          nameId: this.props.user!.nameId,
          eventId: item.id,
          groupId: 0,
          totalAttendees: parseInt(this.state.groupSize),
        };

        this.props.rsvpRequest(requestObj);
        this.handleCloseModal();
      } else {
        const requestObj: RsvpDto = {
          nameId: this.props.user!.nameId,
          eventId: item.id,
          groupId: 0,
          totalAttendees: 1,
        };

        this.props.rsvpRequest(requestObj);
      }
    }
  };

  handleOpenModal = () => {
    this.setState({ modalVisibility: true });
  };
  handleCloseModal = () => {
    this.setState({ modalVisibility: false });
  };

  render() {
    const { item, classes } = this.props;
    const { expanded } = this.state;
    const eventStartDate = item.startDate.toString();
    const eventEndDate = item.endDate.toString();
    let eventStartDay = Date.parse(eventStartDate.split("T")[0]);
    let eventEndDay = Date.parse(eventEndDate.split("T")[0]);

    return (
      <React.Fragment>
        <Card style={{ width: "100%" }}>
          <FeedItemHeader item={item} showExpander={true} expandClickHandler={this.handleExpandClick} />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent className={classes.feedItemContent}>
              <Grid container direction="row" style={{ marginBottom: "1.25rem" }}>
                <Grid item className={classes.leftIconGutter}>
                  <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                    <CardMedia className={classes.postTypeIcon} image={constants.CALENDAR_ICON} title="Calendar" />
                  </div>
                </Grid>

                {item.images.length > 0 && (
                  <React.Fragment>
                    <Grid item xs>
                      <Typography
                        variant="h4"
                        gutterBottom
                        style={{ overflowWrap: "break-word", wordBreak: "break-word" }}
                      >
                        {item.title}
                      </Typography>
                      <Grid container direction="row" alignItems="center" style={{ marginBottom: "0.625rem" }}>
                        <Grid item>
                          <Typography
                            variant="h6"
                            style={{
                              overflowWrap: "break-word",
                              wordBreak: "break-word",
                              display: "inline-block",
                              fontWeight: 500,
                              marginRight: "0.3125rem",
                            }}
                          >
                            DATE/TIME:
                          </Typography>
                          <Typography
                            variant="h6"
                            style={{
                              color: "#6c7273",
                              overflowWrap: "break-word",
                              display: "inline-block",
                              wordBreak: "break-word",
                            }}
                          >
                            <Moment format="MM/DD/YYYY" style={{ marginRight: "0.3125rem" }}>
                              {eventStartDate && eventStartDate.toString()}
                            </Moment>
                            <Moment format="LT" style={{ marginRight: "5px" }}>
                              {eventStartDate && eventStartDate.toString()}
                            </Moment>
                            {eventStartDay !== eventEndDay && (
                              <React.Fragment>
                                <span className={classes.timeSpan}>-</span>
                                <Moment format="MM/DD/YYYY" style={{ marginRight: "0.3125rem" }}>
                                  {eventEndDate && eventEndDate.toString()}
                                </Moment>
                                <Moment format="LT" style={{ whiteSpace: "nowrap" }}>
                                  {eventEndDate && eventEndDate.toString()}
                                </Moment>
                              </React.Fragment>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" alignItems="center" style={{ marginBottom: "0.625rem" }}>
                        <Grid item>
                          <Typography
                            variant="h6"
                            style={{
                              overflowWrap: "break-word",
                              wordBreak: "break-word",
                              display: "inline-block",
                              fontWeight: 500,
                              marginRight: "0.3125rem",
                            }}
                          >
                            LOCATION:{" "}
                          </Typography>
                          <Typography
                            variant="h6"
                            style={{
                              color: "#6c7273",
                              overflowWrap: "break-word",
                              display: "inline-block",
                              wordBreak: "break-word",
                            }}
                          >
                            {item.location}
                          </Typography>
                        </Grid>
                      </Grid>
                      {item.rsvpEnabled && (
                        <React.Fragment>
                          {/* Guessed Allowed */}
                          {this.props.item.guestsAllowed === true && (
                            <Grid>
                              {this.props.item.maxAttendees >= 0 &&
                                this.props.item.maxAttendees > this.props.item.rsvpCount && (
                                  <Grid>
                                    {!this.props.item.attending === true && (
                                      <Grid>
                                        <Button
                                          onClick={this.handleOpenModal}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginBottom: "1rem" }}
                                        >
                                          RSVP
                                          {item.attending && (
                                            <img
                                              alt="RSVP"
                                              src={constants.EVENT_RSVP_ICON}
                                              style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                            />
                                          )}
                                        </Button>
                                        <Typography inline variant="h5" style={{ marginLeft: "10px" }}>
                                          {item.rsvpCount} attending
                                        </Typography>
                                      </Grid>
                                    )}
                                    {this.props.item.attending === true && (
                                      <Grid>
                                        <Button
                                          onClick={() => this.submitRsvp(item)}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginBottom: "1rem" }}
                                        >
                                          RSVP
                                          {item.attending && (
                                            <img
                                              alt="RSVP"
                                              src={constants.EVENT_RSVP_ICON}
                                              style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                            />
                                          )}
                                        </Button>
                                        <Typography inline variant="h5" style={{ marginLeft: "10px" }}>
                                          {item.rsvpCount} attending
                                        </Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                )}
                              {this.props.item.maxAttendees > 0 &&
                                this.props.item.maxAttendees <= this.props.item.rsvpCount && (
                                  <Grid>
                                    {!this.props.item.attending === true && (
                                      <Grid>
                                        {/* <Button
                                          //onClick={this.handleOpenModal}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginRight: "0.625rem", marginBottom: "0.625rem" }}
                                        >
                                          RSVP
                                          {item.attending && (
                                            <img
                                              src={constants.EVENT_RSVP_ICON}
                                              style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                            />
                                          )}
                                        </Button> */}
                                        <Typography inline variant="h5" style={{ marginLeft: "10px" }}>
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                    {this.props.item.attending === true && (
                                      <Grid>
                                        <Button
                                          onClick={() => this.submitRsvp(item)}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginRight: "0.625rem", marginBottom: "0.625rem" }}
                                        >
                                          RSVP
                                          {item.attending && (
                                            <img
                                              alt="RSVP"
                                              src={constants.EVENT_RSVP_ICON}
                                              style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                            />
                                          )}
                                        </Button>
                                        <Typography inline variant="h5" style={{ marginLeft: "10px" }}>
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                )}
                            </Grid>
                          )}
                          {/* Guessed Not Allowed */}
                          {!(this.props.item.guestsAllowed === true) && (
                            <Grid>
                              {((this.props.item.maxAttendees >= 0 &&
                                this.props.item.maxAttendees >= this.props.item.rsvpCount) ||
                                (this.props.item.maxAttendees === 0 && this.props.item.maxGuests <= 0)) && (
                                <Grid>
                                  <Button
                                    onClick={() => this.submitRsvp(item)}
                                    variant="outlined"
                                    size="large"
                                    color="primary"
                                    style={{ marginBottom: "1rem" }}
                                  >
                                    RSVP
                                    {item.attending && (
                                      <img
                                        alt="RSVP"
                                        src={constants.EVENT_RSVP_ICON}
                                        style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                      />
                                    )}
                                  </Button>
                                  <Typography inline variant="h5" style={{ marginLeft: "10px" }}>
                                    {item.rsvpCount} attending
                                  </Typography>
                                </Grid>
                              )}
                              {this.props.item.maxAttendees > 0 &&
                                this.props.item.maxAttendees <= this.props.item.rsvpCount && (
                                  <Grid>
                                    {!this.props.item.attending === true && (
                                      <Grid>
                                        {/* <Button
                                          //onClick={this.handleOpenModal}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginRight: "0.625rem", marginBottom: "0.625rem" }}
                                        >
                                          RSVP
                                          {item.attending && (
                                            <img
                                              src={constants.EVENT_RSVP_ICON}
                                              style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                            />
                                          )}
                                        </Button> */}
                                        <Typography inline variant="h5" style={{ marginLeft: "10px" }}>
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                    {this.props.item.attending === true && (
                                      <Grid>
                                        <Button
                                          onClick={() => this.submitRsvp(item)}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginRight: "0.625rem", marginBottom: "0.625rem" }}
                                        >
                                          RSVP
                                          {item.attending && (
                                            <img
                                              alt="RSVP"
                                              src={constants.EVENT_RSVP_ICON}
                                              style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                            />
                                          )}
                                        </Button>
                                        <Typography inline variant="h5" style={{ marginLeft: "10px" }}>
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                )}
                            </Grid>
                          )}
                        </React.Fragment>
                      )}
                      <Typography style={{ overflowWrap: "break-word", wordBreak: "break-word" }}>
                        <TruncateMessage message={item.message} characterCount={250} />
                      </Typography>
                    </Grid>
                    <Hidden xsDown>
                      <Grid item>{item.images.length > 0 && <PhotoGallery images={item.images} />}</Grid>
                    </Hidden>
                  </React.Fragment>
                )}
                {item.images.length <= 0 && (
                  <React.Fragment>
                    <Grid item xs>
                      <Typography
                        variant="h4"
                        gutterBottom
                        style={{
                          overflowWrap: "break-word",
                          wordBreak: "break-word",
                          marginBottom: "0.625rem",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Grid container direction="row" alignItems="center" style={{ marginBottom: "0.625rem" }}>
                        <Grid item>
                          <Typography
                            variant="h6"
                            style={{
                              overflowWrap: "break-word",
                              wordBreak: "break-word",
                              display: "inline-block",
                              fontWeight: 500,
                              marginRight: "0.3125rem",
                            }}
                          >
                            DATE/TIME:
                          </Typography>
                          <Typography
                            variant="h6"
                            style={{
                              color: "#6c7273",
                              overflowWrap: "break-word",
                              display: "inline-block",
                              wordBreak: "break-word",
                            }}
                          >
                            <Moment format="MM/DD/YYYY" style={{ marginRight: "0.3125rem" }}>
                              {eventStartDate && eventStartDate.toString()}
                            </Moment>
                            <Moment format="LT" style={{ marginRight: "5px" }}>
                              {eventStartDate && eventStartDate.toString()}
                            </Moment>
                            {eventStartDay !== eventEndDay && (
                              <React.Fragment>
                                <span className={classes.timeSpan}>-</span>
                                <Moment format="MM/DD/YYYY" style={{ marginRight: "0.3125rem" }}>
                                  {eventEndDate && eventEndDate.toString()}
                                </Moment>
                                <Moment format="LT" style={{ whiteSpace: "nowrap" }}>
                                  {eventEndDate && eventEndDate.toString()}
                                </Moment>
                              </React.Fragment>
                            )}
                            {eventStartDay === eventEndDay && (
                              <React.Fragment>
                                <span className={classes.timeSpan}>-</span>
                                <Moment format="LT" style={{ whiteSpace: "nowrap" }}>
                                  {eventEndDate && eventEndDate.toString()}
                                </Moment>
                              </React.Fragment>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" alignItems="center" style={{ marginBottom: "0.625rem" }}>
                        <Grid item>
                          <Typography
                            variant="h6"
                            style={{
                              overflowWrap: "break-word",
                              wordBreak: "break-word",
                              display: "inline-block",
                              fontWeight: 500,
                              marginRight: "0.3125rem",
                            }}
                          >
                            LOCATION:{" "}
                          </Typography>
                          <Typography
                            variant="h6"
                            style={{
                              color: "#6c7273",
                              overflowWrap: "break-word",
                              display: "inline-block",
                              wordBreak: "break-word",
                            }}
                          >
                            {item.location}
                          </Typography>
                        </Grid>
                      </Grid>
                      {item.rsvpEnabled && (
                        <React.Fragment>
                          {/* Guessed Allowed */}
                          {this.props.item.guestsAllowed === true && (
                            <Grid>
                              {this.props.item.maxAttendees >= 0 &&
                                this.props.item.maxAttendees > this.props.item.rsvpCount && (
                                  <Grid>
                                    {!this.props.item.attending === true && (
                                      <Grid>
                                        <Button
                                          onClick={this.handleOpenModal}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginBottom: "1rem" }}
                                        >
                                          RSVP
                                          {item.attending && (
                                            <img
                                              alt="RSVP"
                                              src={constants.EVENT_RSVP_ICON}
                                              style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                            />
                                          )}
                                        </Button>
                                        <Typography inline variant="h5" style={{ marginLeft: "10px" }}>
                                          {item.rsvpCount} attending
                                        </Typography>
                                      </Grid>
                                    )}
                                    {this.props.item.attending === true && (
                                      <Grid>
                                        <Button
                                          onClick={() => this.submitRsvp(item)}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginBottom: "1rem" }}
                                        >
                                          RSVP
                                          {item.attending && (
                                            <img
                                              alt="RSVP"
                                              src={constants.EVENT_RSVP_ICON}
                                              style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                            />
                                          )}
                                        </Button>
                                        <Typography inline variant="h5" style={{ marginLeft: "10px" }}>
                                          {item.rsvpCount} attending
                                        </Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                )}
                              {this.props.item.maxAttendees > 0 &&
                                this.props.item.maxAttendees <= this.props.item.rsvpCount && (
                                  <Grid>
                                    {!this.props.item.attending === true && (
                                      <Grid>
                                        <Typography inline variant="h5" style={{ marginLeft: "10px" }}>
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                    {this.props.item.attending === true && (
                                      <Grid>
                                        <Button
                                          onClick={() => this.submitRsvp(item)}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginRight: "0.625rem", marginBottom: "0.625rem" }}
                                        >
                                          RSVP
                                          {item.attending && (
                                            <img
                                              alt="RSVP"
                                              src={constants.EVENT_RSVP_ICON}
                                              style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                            />
                                          )}
                                        </Button>
                                        <Typography inline variant="h5" style={{ marginLeft: "10px" }}>
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                )}
                            </Grid>
                          )}
                          {/* Guessed Not Allowed */}
                          {!(this.props.item.guestsAllowed === true) && (
                            <Grid>
                              {((this.props.item.maxAttendees >= 0 &&
                                this.props.item.maxAttendees >= this.props.item.rsvpCount) ||
                                (this.props.item.maxAttendees === 0 && this.props.item.maxGuests <= 0)) && (
                                <Grid>
                                  <Button
                                    onClick={() => this.submitRsvp(item)}
                                    variant="outlined"
                                    size="large"
                                    color="primary"
                                    style={{ marginBottom: "1rem" }}
                                  >
                                    RSVP
                                    {item.attending && (
                                      <img
                                        alt="RSVP"
                                        src={constants.EVENT_RSVP_ICON}
                                        style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                      />
                                    )}
                                  </Button>
                                  <Typography inline variant="h5" style={{ marginLeft: "10px" }}>
                                    {item.rsvpCount} attending
                                  </Typography>
                                </Grid>
                              )}
                              {this.props.item.maxAttendees > 0 &&
                                this.props.item.maxAttendees <= this.props.item.rsvpCount && (
                                  <Grid>
                                    {!this.props.item.attending === true && (
                                      <Grid>
                                        <Typography inline variant="h5" style={{ marginLeft: "10px" }}>
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                    {this.props.item.attending === true && (
                                      <Grid>
                                        <Button
                                          onClick={() => this.submitRsvp(item)}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginRight: "0.625rem", marginBottom: "0.625rem" }}
                                        >
                                          RSVP
                                          {item.attending && (
                                            <img
                                              alt="RSVP"
                                              src={constants.EVENT_RSVP_ICON}
                                              style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                            />
                                          )}
                                        </Button>
                                        <Typography inline variant="h5" style={{ marginLeft: "10px" }}>
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                )}
                            </Grid>
                          )}
                        </React.Fragment>
                      )}
                      <Typography style={{ overflowWrap: "break-word", wordBreak: "break-word" }}>
                        <TruncateMessage message={item.message} characterCount={250} />
                      </Typography>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Hidden smUp>
                <Grid container justify="center" style={{ backgroundColor: "#d7d7d7", marginTop: "1rem" }}>
                  <Grid item>{item.images.length > 0 && <PhotoGallery images={item.images} />}</Grid>
                </Grid>
              </Hidden>
              <CommentsContainer item={item} feedType={this.props.feedType} groupId={item.groupId} />
            </CardContent>
          </Collapse>
        </Card>
        <Dialog open={this.state.modalVisibility} onClose={this.handleCloseModal} fullScreen={false}>
          <DialogContent style={{ minHeight: "300px" }}>
            <Typography
              variant="h3"
              className={this.props.classes.tablepadding}
              gutterBottom
              style={{ textAlign: "center" }}
            >
              Total Attendees (including yourself)
            </Typography>
            <Grid style={{ width: "100px", marginLeft: "auto", marginRight: "auto" }}>
              <Dropdown
                options={this.state.guestListCount}
                onChange={(option) => this.handleGroupSizeChange(option.value)}
                value={this.state.groupSize}
                placeholder={this.state.groupSize}
                className={this.props.classes.dropdown}
                controlClassName={this.props.classes.dropdowncomponent}
                placeholderClassName={this.props.classes.dropdownplaceholder}
                menuClassName={this.props.classes.dropdownmenu}
              />
            </Grid>
            <Grid style={{ marginLeft: "auto", marginRight: "auto" }}>
              <Button
                onClick={() => this.submitRsvp(item)}
                variant="outlined"
                size="large"
                color="primary"
                style={{
                  marginBottom: "0.625rem",
                  marginTop: "0.625rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
              >
                Continue
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

// export default withStyles(styles)(EventComponent);

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  rsvpRequest: (payload: RsvpDto) => dispatch(rsvpRequest(payload)),
  cancelRsvpRequest: (payload: RsvpDto) => dispatch(cancelRsvpRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventComponent));
