import { combineReducers } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { PropertyDocumentsItems } from "./models";
import * as propertydocuments from "./actions";

export type PropertyDocumentAction = ActionType<typeof propertydocuments>;

export type PropertyDocumentState = Readonly<{
  propertyDocumentsList: PropertyDocState;
}>;

export type PropertyDocState = Readonly<{
  loading: boolean;
  data: PropertyDocumentsItems[];
  errors?: string;
}>;

const initialPropertyDocState: PropertyDocState = {
  data: [],
  errors: undefined,
  loading: false,
};

// tslint:disable-next-line:no-unused-expression

export default combineReducers<PropertyDocumentState, PropertyDocumentAction>({
  propertyDocumentsList: (state = initialPropertyDocState, action) => {
    switch (action.type) {
      case getType(propertydocuments.propertyDocsRequest): {
        return { ...state, loading: true };
      }
      case getType(propertydocuments.propertyDocsSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(propertydocuments.propertyDocsError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
});
