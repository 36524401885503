import { Grid, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";

import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import { NoticeSummary } from "../models";
import rentableitemstyles from "../styles";

const styles = combinestyles(rentableitemstyles, globalstyles);

export interface SummaryTextProps extends WithStyles<typeof styles> {
  noticeSummary?: NoticeSummary;
}

class SummaryText extends React.Component<SummaryTextProps> {
  render() {
    const { classes } = this.props;
    const SummaryLanguage = this.props.noticeSummary!.copyLanguage.find(e => {
      return e.languageId === "A31";
    });
    return (
      <div>
        <Paper className={classes.paper}>
          <Grid container className={this.props.classes.grouping}>
            <Grid xs={12} item>
              <Typography
                component={"pre"}
                variant="body2"
                style={{ display: "inline-block", fontSize: "1rem", paddingBottom: "1rem", whiteSpace: "pre-wrap" }}
              >
                {SummaryLanguage!.copyLanguage}
              </Typography>
            </Grid>
          </Grid>
          {/* <Typography gutterBottom variant="h3" style={{ display: "inline-block", marginBottom: "1rem" }}>
            Thank you for informing us of your intent to vacate. By providing this notice to vacate, you understand that
            we will put your apartment on the market.
          </Typography>
          <div />
          <Typography gutterBottom variant="h3" style={{ display: "inline-block" }}>
            To summarize, next steps are:
          </Typography>
          <div />
          <Typography gutterBottom style={{ display: "inline-block" }}>
            1. Someone from our leasing office will be in touch to follow up.
          </Typography>
          <Typography gutterBottom style={{ display: "inline-block" }}>
            2. We will be setting up a time to inspect your apartment.
          </Typography>
          <Typography gutterBottom style={{ display: "inline-block" }}>
            3. We will reach out to you with any additional charges you may owe.
          </Typography>
          <div />
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <Typography style={{ display: "inline-block", marginRight: "10px", marginLeft: "25px" }}>•</Typography>
            <Typography style={{ display: "inline-block" }}>Prorated Rent and Other Charges</Typography>
          </div>
          <Typography gutterBottom style={{ display: "inline-block", fontSize: ".875rem", marginLeft: "40px" }}>
            Calculated based on your move out date.
          </Typography>
          <div />
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <Typography style={{ display: "inline-block", marginRight: "10px", marginLeft: "25px" }}>•</Typography>
            <Typography style={{ display: "inline-block" }}>Prorated Utilities (RUBS)</Typography>
          </div>

          <Typography gutterBottom style={{ display: "inline-block", fontSize: ".875rem", marginLeft: "40px" }}>
            Utilities are billed in arrears so you will likely see what looks like a double bill for your last month.
            Your final bill amount is usually available to view and pay on the resident portal a few days before your
            move-out date
          </Typography>
          <Typography gutterBottom style={{ display: "inline-block" }}>
            4. Your Statement of Deposit Account will be provided after move-out.
          </Typography> */}
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(SummaryText);
