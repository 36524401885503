import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchError, fetchSuccess } from "./actions";
import { loadNews } from "../../utils/api";
import { getType } from "typesafe-actions";
import * as news from "./actions";

import { showModalAction } from "../errorhandling/actions";

function* handleFetch(action: ReturnType<typeof news.fetchRequest>) {
  try {
    const rmpropid = action.payload;

    const response = yield call(loadNews, rmpropid);

    if (response.error) {
      yield put(fetchError(response.error));
    } else {
      yield put(fetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(fetchError(err.stack!));
    } else {
      yield put(fetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(getType(news.fetchRequest), handleFetch);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* newsSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default newsSaga;
