import * as React from "react";
import { ContactItem } from "../models";
import ContactImage from "./ContactImage";
import { Typography, Grid, withStyles, WithStyles } from "@material-ui/core";

import contactstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(contactstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  leasingTeam: ContactItem[];
}

const ContactLeasingTeam: React.SFC<Props> = props => {
  return (
    <div>
      <Typography variant="h3" style={{ marginBottom: "1rem" }}>
        Leasing Team
      </Typography>
      <Grid container spacing={24}>
        {props.leasingTeam.map(leasingContact => (
          <Grid item xs={12}>
            <Grid container alignItems="center" alignContent="flex-start" spacing={16}>
              <Grid item>
                <ContactImage contact={leasingContact} />
              </Grid>
              <Grid item xs>
                <Typography variant="h4" gutterBottom>
                  {leasingContact.firstName}&nbsp;{leasingContact.lastName}
                </Typography>
                <Typography variant="h4" style={{ fontWeight: 300 }}>
                  {leasingContact.contactTitle}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(ContactLeasingTeam);
