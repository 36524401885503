import { createAction } from "typesafe-actions";

import { GuestAccessUpload, GuestItem, NewGuestAccessUpload, NewGuestItem } from "./models";

const FETCH_REQUEST = "guest/all/FETCH_REQUEST";
const FETCH_SUCCESS = "guest/all/FETCH_SUCCESS";
const FETCH_ERROR = "guest/all/ETCH_ERROR";

export const fetchRequest = createAction(FETCH_REQUEST)<string>();
export const fetchSuccess = createAction(FETCH_SUCCESS)<GuestItem[]>();
export const fetchError = createAction(FETCH_ERROR)<string>();

const VIEW_DETAILS = "guest/details/VIEW_DETAILS";
const HIDE_DETAILS = "guest/details/HIDE_DETAILS";

export const viewDetails = createAction(VIEW_DETAILS)<GuestItem>();
export const hideDetails = createAction(HIDE_DETAILS)();

const VIEW_FORM = "guest/form/VIEW_FORM";
const HIDE_FORM = "guest/form/HIDE_FORM";

export const viewForm = createAction(VIEW_FORM)<GuestItem>();
export const hideForm = createAction(HIDE_FORM)();

const POST_REQUEST = "guest/new/POST_REQUEST";
const POST_SUCCESS = "guest/new/POST_SUCCESS";
const POST_ERROR = "guest/new/POST_ERROR";
export const postRequest = createAction(POST_REQUEST)<NewGuestAccessUpload>();
export const postSuccess = createAction(POST_SUCCESS)<NewGuestItem>();
export const postError = createAction(POST_ERROR)<string>();

const GUESTTYPES_FETCH_REQUEST = "guest/guesttypes/FETCH_REQUEST";
const GUESTTYPES_FETCH_SUCCESS = "guest/guesttypes/FETCH_SUCCESS";
const GUESTTYPES_FETCH_ERROR = "guest/guesttypes/FETCH_ERROR";
export const guesttypesFetchRequest = createAction(GUESTTYPES_FETCH_REQUEST)();
export const guesttypesFetchSuccess = createAction(GUESTTYPES_FETCH_SUCCESS)<Map<string, string>>();
export const guesttypesFetchError = createAction(GUESTTYPES_FETCH_ERROR)<string>();

const PUT_REQUEST = "guest/update/PUT_REQUEST";
const PUT_SUCCESS = "guest/update/PUT_SUCCESS";
const PUT_ERROR = "guest/update/PUT_ERROR";
export const putRequest = createAction(PUT_REQUEST)<GuestAccessUpload>();
export const putSuccess = createAction(PUT_SUCCESS)<GuestItem>();
export const putError = createAction(PUT_ERROR)<string>();

const PUT_INACTIVATE_REQUEST = "guest/updateInactivate/PUT_INACTIVATE_REQUEST";
const PUT_INACTIVATE_SUCCESS = "guest/updateInactivate/PUT_INACTIVATE_SUCCESS";
const PUT_INACTIVATE_ERROR = "guest/updateInactivate/PUT_INACTIVATE_ERROR";
export const putInactivateRequest = createAction(PUT_INACTIVATE_REQUEST)<GuestItem>();
export const putInactivateSuccess = createAction(PUT_INACTIVATE_SUCCESS)<GuestItem>();
export const putInactivateError = createAction(PUT_INACTIVATE_ERROR)<string>();
