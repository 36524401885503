import { Button, Grid, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";

import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import eventstyles from "../styles";
import RenewalHeader from "./RenewalHeader";

const styles = combinestyles(eventstyles, globalstyles);

interface IRenewalConfirmationProps extends WithStyles<typeof styles> {}

class RenewalConfirmationPage extends React.Component<IRenewalConfirmationProps> {
  setRedirect = () => {
    window.location.href = "../";
    window.location.replace("../");
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <main className={classes.contentRightRail}>
          <RenewalHeader />
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h3" style={{ marginBottom: "1rem" }}>
                  We’re glad to have you stay with us!
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <img
                      alt="Check Icon"
                      src="https://media.equityapartments.com/image/upload/c_scale,w_30/Content/portal/uielements/check.png"
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h4" gutterBottom>
                      Acceptance Confirmed
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="body2" gutterBottom>
                  Your renewal request has been submitted. Your lease will be emailed to you for signature, or you can
                  check back here for the link within the next 48 hours.
                </Typography>
              </Grid>
              <Grid item style={{ width: "100%", textAlign: "right" }}>
                <Button onClick={this.setRedirect} color="primary" autoFocus variant="contained" size="large">
                  Home
                </Button>
                {/* <NavLink to={"../"} style={{ textDecoration: "none" }}>
                  <Button color="primary" autoFocus variant="contained" size="large">
                    Home
                  </Button>
                </NavLink> */}
              </Grid>
            </Grid>
          </Paper>
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(RenewalConfirmationPage);
