import { createAction } from "typesafe-actions";

import {
  AmenityAvailableSlots,
  AmenityGetOpenSlots,
  AmenityReservation,
  AmenityReservationCancel,
  ReservableAmenity,
} from "./models";

const DEEPLINK_FETCH_REQUEST = "amenity/deeplink/FETCH_REQUEST";
const DEEPLINK_FETCH_SUCCESS = "amenity/deeplink/FETCH_SUCCESS";
const DEEPLINK_FETCH_ERROR = "amenity/deeplink/FETCH_ERROR";

export const deeplinkFetchRequest = createAction(DEEPLINK_FETCH_REQUEST)<string, string>();
export const deeplinkFetchSuccess = createAction(DEEPLINK_FETCH_SUCCESS)<ReservableAmenity[]>();
export const deeplinkFetchError = createAction(DEEPLINK_FETCH_ERROR)<string>();

const CATEGORIES_FETCH_REQUEST = "amenity/categories/FETCH_REQUEST";
const CATEGORIES_FETCH_SUCCESS = "amenity/categories/FETCH_SUCCESS";
const CATEGORIES_FETCH_ERROR = "amenity/categories/FETCH_ERROR";

export const categoriesFetchRequest = createAction(CATEGORIES_FETCH_REQUEST)<string>();
export const categoriesFetchSuccess = createAction(CATEGORIES_FETCH_SUCCESS)<ReservableAmenity[]>();
export const categoriesFetchError = createAction(CATEGORIES_FETCH_ERROR)<string>();

const LIST_FETCH_REQUEST = "amenity/list/FETCH_REQUEST";
const LIST_FETCH_SUCCESS = "amenity/list/FETCH_SUCCESS";
const LIST_FETCH_ERROR = "amenity/list/FETCH_ERROR";

export const listFetchRequest = createAction(LIST_FETCH_REQUEST)<string>();
export const listFetchSuccess = createAction(LIST_FETCH_SUCCESS)<AmenityReservation[]>();
export const listFetchError = createAction(LIST_FETCH_ERROR)<string>();

const VIEW_DETAILS = "amenity/details/VIEW_DETAILS";
const HIDE_DETAILS = "amenity/details/HIDE_DETAILS";
export const viewDetails = createAction(VIEW_DETAILS)<AmenityReservation>();
export const hideDetails = createAction(HIDE_DETAILS)();

const CANCEL_AMENITY_REQUEST = "amenity/update/CANCEL_AMENITY_REQUEST";
const CANCEL_AMENITY_SUCCESS = "amenity/update/CANCEL_AMENITY_SUCCESS";
const CANCEL_AMENITY_ERROR = "amenity/update/CANCEL_AMENITY_ERROR";
export const cancelAmenityRequest = createAction(CANCEL_AMENITY_REQUEST)<AmenityReservationCancel>();
export const cancelAmenitySuccess = createAction(CANCEL_AMENITY_SUCCESS)<number>();
export const cancelAmenityError = createAction(CANCEL_AMENITY_ERROR)<string>();

const INSERT_AMENITY_REQUEST = "amenity/insert/INSERT_AMENITY_REQUEST";
const INSERT_AMENITY_SUCCESS = "amenity/insert/INSERT_AMENITY_SUCCESS";
const INSERT_AMENITY_ERROR = "amenity/insert/INSERT_AMENITY_ERROR";
export const insertAmenityRequest = createAction(INSERT_AMENITY_REQUEST)<AmenityReservation>();
export const insertAmenitySuccess = createAction(INSERT_AMENITY_SUCCESS)<number>();
export const insertAmenityError = createAction(INSERT_AMENITY_ERROR)<string>();

const OPENSLOT_AMENITY_REQUEST = "amenity/slots/OPENSLOT_AMENITY_REQUEST";
const OPENSLOT_AMENITY_SUCCESS = "amenity/slots/OPENSLOT_AMENITY_SUCCESS";
const OPENSLOT_AMENITY_ERROR = "amenity/slots/OPENSLOT_AMENITY_ERROR";
export const amenityOpenSlotRequest = createAction(OPENSLOT_AMENITY_REQUEST)<AmenityGetOpenSlots[]>();
export const amenityOpenSlotSuccess = createAction(OPENSLOT_AMENITY_SUCCESS)<AmenityAvailableSlots[]>();
export const amenityOpenSlotError = createAction(OPENSLOT_AMENITY_ERROR)<string>();

const SELECT_AMENITY_TO_RESERVE = "amenity/reserve/SELECT_AMENITY_TO_RESERVE";
export const selectAmenityToReserve = createAction(SELECT_AMENITY_TO_RESERVE)<ReservableAmenity>();
