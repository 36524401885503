import * as React from "react";
import { groupFetchRequest, groupUserFetchRequest } from "../features/social/actionsGroup";
import { Group } from "../features/social/models";
import { RootState } from "../store/root-reducer";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { WithStyles, withStyles } from "@material-ui/core";
import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import GroupsWelcome from "../features/social/components/GroupsWelcome";
import GroupsMy from "../features/social/components/GroupsMy";
import GroupsOther from "../features/social/components/GroupsOther";
import * as groupsSelectors from "../features/social/selectors";

import socialstyles from "../features/social/components/styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(socialstyles, globalstyles);

interface PropsFromState extends WithStyles<typeof styles> {
  user?: UserInfoItem;
  dataAll: Group[];
  dataUser: Group[];
  sortedUserGroups: Group[];
}

interface PropsFromDispatch {
  groupFetchRequest: typeof groupFetchRequest;
  groupUserFetchRequest: typeof groupUserFetchRequest;
}

// interface IServiceReqProps extends WithStyles<typeof styles> {
//   theme?: any;
//   children?: any;
// }

type AllProps = PropsFromState & PropsFromDispatch;

class Groups extends React.Component<AllProps> {
  public componentDidMount() {
    const user = this.props.user;
    const rmpropId = user!.propertyId;
    this.props.groupFetchRequest(rmpropId);
    const nameId = user!.nameId;
    this.props.groupUserFetchRequest(nameId);
    window.scrollTo(0, 0);
  }

  public render() {
    // all groups
    const { dataAll } = this.props;
    const { dataUser } = this.props;
    const dataOther = getOtherData(dataAll, dataUser);
    const { sortedUserGroups } = this.props;

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <GroupsWelcome />
          {dataUser.length !== 0 && (
            <div>
              <GroupsMy userGroup={sortedUserGroups} />
            </div>
          )}
          {dataOther.length !== 0 && (
            <div>
              <GroupsOther propertyGroup={dataOther} />
            </div>
          )}
        </main>
      </div>
    );
  }
}

const getOtherData = (dataAll: Group[], dataUser: Group[]) => {
  // date should be valid at this point
  const otherData: Group[] = [];

  let notUserGroup: Group = {
    id: 0,
    rmPropId: "",
    name: "",
    description: "",
    status: "",
    memberCount: 0,
    declineReason: null,
    dateCreated: "",
    lastPostDate: "",
  };

  // get a groupProperty from all
  for (const groupProperty of dataAll) {
    let member = false;

    for (const groupUser of dataUser) {
      // check if the group is in mine
      if (groupProperty.id === groupUser.id) {
        member = true;
        break;
      }
    }
    // groupProperty is not in mine so add to other
    if (!member) {
      notUserGroup = groupProperty;
      otherData.push(notUserGroup);
    }
  }
  return otherData;
};
const mapStateToProps = ({ socialGroup, selectAccount }: RootState) => ({
  user: getActiveUserInfo(selectAccount),
  dataAll: socialGroup.groupList.dataAll,
  dataUser: socialGroup.groupUserList.dataUser,
  sortedUserGroups: groupsSelectors.getSortedUserGroups(socialGroup.groupUserList),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  groupFetchRequest: (rmpropid: string) => dispatch(groupFetchRequest(rmpropid)),
  groupUserFetchRequest: (nameId: string) => dispatch(groupUserFetchRequest(nameId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Groups));
