import { createAction } from "typesafe-actions";

import { DigitalDocument } from "./models";

const GET_DOCUMENTS_REQUEST = "account/documents/GET_DOCUMENTS_REQUEST";
const GET_DOCUMENTS_SUCCESS = "account/documents/GET_DOCUMENTS_SUCCESS";
const GET_DOCUMENTS_ERROR = "account/documents/GET_DOCUMENTS_ERROR";
export const getDocumentsRequest = createAction(GET_DOCUMENTS_REQUEST)<string>();
export const getDocumentsSuccess = createAction(GET_DOCUMENTS_SUCCESS)<DigitalDocument[]>();
export const getDocumentsError = createAction(GET_DOCUMENTS_ERROR)<string>();

const DOWNLOAD_DOCUMENT_REQUEST = "account/documents/DOWNLOAD_DOCUMENT_REQUEST";
const DOWNLOAD_DOCUMENT_SUCCESS = "account/documents/DOWNLOAD_DOCUMENT_SUCCESS";
const DOWNLOAD_DOCUMENT_ERROR = "account/documents/DOWNLOAD_DOCUMENT_ERROR";
const RESET_DOWNLOAD_DOCUMENT_ERROR = "account/documents/RESET_DOWNLOAD_DOCUMENT_ERROR";
export const downloadDocumentRequest = createAction(DOWNLOAD_DOCUMENT_REQUEST)<{
  nameId: string;
  documentId: number;
  fileName: string;
  newWindow: Window | null;
}>();
export const downloadDocumentSuccess = createAction(DOWNLOAD_DOCUMENT_SUCCESS)();
export const downloadDocumentError = createAction(DOWNLOAD_DOCUMENT_ERROR)<string>();
export const resetDownloadError = createAction(RESET_DOWNLOAD_DOCUMENT_ERROR)();
