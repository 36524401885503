import { WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import contactstyles from "../features/myprofile/components/styles";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import { groupFeedFetchRequest, groupFetchRequest, groupUserFetchRequest } from "../features/social/actionsGroup";
import GroupsDetailsHeader from "../features/social/components/GroupsDetailsHeader";
import GroupsFeed from "../features/social/components/GroupsFeed";
import { DashboardFeedItem, Group } from "../features/social/models";
import { UserInfoItem } from "../features/userInfo/models";
import globalstyles from "../global-styles";
import { RootState } from "../store/root-reducer";
import combinestyles from "../utils/combinestyles";

const styles = combinestyles(contactstyles, globalstyles);

let whoseGroup: string;
let groupName: string;
interface State {
  expanded: boolean;
  // whoseGroup: string;
}

interface MatchParams {
  groupId: string;
}

interface PropsFromState extends WithStyles<typeof styles> {
  user?: UserInfoItem;
  dataAll: Group[];
  dataUser: Group[];
  dataGroupFeed: DashboardFeedItem[];
}

interface PropsFromDispatch {
  groupFetchRequest: typeof groupFetchRequest;
  groupUserFetchRequest: typeof groupUserFetchRequest;
  groupFeedFetchRequest: typeof groupFeedFetchRequest;
}

interface Props extends RouteComponentProps<MatchParams> {}

export interface RouteComponentProps<P> {
  match: Match<P>;
  // location: H.Location;
  // history: H.History;
  staticContext?: any;
}

export interface Match<P> {
  params: P;
  isExact: boolean;
  path: string;
  url: string;
}

type AllProps = Props & PropsFromState & PropsFromDispatch;

class GroupDetails extends React.Component<AllProps> {
  public componentDidMount() {
    const user = this.props.user;
    // const rmpropid = this.props.user!.propertyId;
    const nameId = user!.nameId;
    // this.props.groupFetchRequest(rmpropid);
    this.props.groupUserFetchRequest(nameId);
    this.props.groupFeedFetchRequest({ groupId: this.props.match.params.groupId, nameId: nameId });
    window.scrollTo(0, 0);
  }

  state: Readonly<State> = {
    expanded: true
    // whoseGroup: "invalid"
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  public render() {
    const { dataUser } = this.props;
    const { dataAll } = this.props;
    const { dataGroupFeed } = this.props;
    const thisGroupId = this.props.match.params.groupId;
    const thisGroup: Group = getThisGroup(dataUser, dataAll, +thisGroupId);

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <GroupsDetailsHeader
            showExpander={true}
            expandClickHandler={this.handleExpandClick}
            group={thisGroup}
            whoseGroup={whoseGroup}
          />
          <GroupsFeed groupFeed={dataGroupFeed} groupId={thisGroup.id} />
        </main>
      </div>
    );
  }
}

const getThisGroup = (userGroups: Group[], propertyGroups: Group[], thisGroupId: number) => {
  const notMyGroup: Group = {
    id: thisGroupId,
    rmPropId: "",
    name: groupName,
    description: "",
    status: "",
    memberCount: 0,
    declineReason: null,
    dateCreated: "",
    lastPostDate: ""
  };

  // is group in my groups?
  for (const userGroup of userGroups) {
    if (userGroup.id === thisGroupId) {
      whoseGroup = "mine";
      return userGroup;
    }
  }

  // is group in other groups?
  for (const propertyGroup of propertyGroups) {
    if (propertyGroup.id === thisGroupId) {
      whoseGroup = "other";
      groupName = propertyGroup.name;
      return propertyGroup;
    }
  }

  whoseGroup = "invalid";
  return notMyGroup;
};

const mapStateToProps = ({ socialGroup, selectAccount }: RootState) => ({
  user: getActiveUserInfo(selectAccount),
  dataAll: socialGroup.groupList.dataAll,
  dataUser: socialGroup.groupUserList.dataUser,
  dataGroupFeed: socialGroup.groupFeed.dataGroupFeed
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  groupUserFetchRequest: (nameId: string) => dispatch(groupUserFetchRequest(nameId)),
  groupFeedFetchRequest: (payload: { groupId: string; nameId: string }) => dispatch(groupFeedFetchRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupDetails));
