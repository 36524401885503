import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import * as actions from "./actions";
import { loadMyAccountDashboard } from "../../utils/api";
import { getType } from "typesafe-actions";

import { showModalAction } from "../errorhandling/actions";

function* handleFetchMyAccountDashboardByResident(
  action: ReturnType<typeof actions.myAccountDashboardFetchRequest>
): any {
  try {
    const nameid = action.payload;

    const response = yield call(loadMyAccountDashboard, nameid);

    if (response.error) {
      yield put(actions.myAccountDashboardFetchError(response.error));
    } else {
      yield put(actions.myAccountDashboardFetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.myAccountDashboardFetchError(err.stack!));
    } else {
      yield put(actions.myAccountDashboardFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* watchFetchRequest() {
  yield takeEvery(getType(actions.myAccountDashboardFetchRequest), handleFetchMyAccountDashboardByResident);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* myaccountdashboardSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default myaccountdashboardSaga;
