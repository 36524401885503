import * as React from "react";
import { Typography, Grid, Divider, withStyles, WithStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

import serviceproviderstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(serviceproviderstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  icon: string;
  title: string;
  description: string;
  path: string;
}

class MyAccountStackedRow extends React.Component<Props> {
  render() {
    const { icon, title, description, path } = this.props;

    return (
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="center" style={{ marginBottom: "1rem" }} spacing={24}>
          <Grid item>
            <Link to={path}>
              <img alt="null" className={this.props.classes.icon} src={icon} />
            </Link>
          </Grid>
          <Grid item xs>
            <Link to={path} className={this.props.classes.link}>
              <Typography variant="h5" className={this.props.classes.link}>
                {title}
              </Typography>
              <Typography variant="h5" style={{ textDecoration: "none" }}>
                {description}
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Divider />
      </Grid>
    );
  }
}

export default withStyles(styles)(MyAccountStackedRow);
