import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";

import * as actions from "./actions";
import { LeaseDetails } from "./models";

export type LeaseAction = ActionType<typeof actions>;

export type leaseState = Readonly<{
  loading: boolean;
  data?: LeaseDetails;
  errors?: string;
  leaseDocPDFURL?: string;
  downloadError: boolean;
}>;

const initialState: leaseState = {
  loading: false,
  data: undefined,
  errors: undefined,
  leaseDocPDFURL: "",
  downloadError: false
};

const reducer: Reducer<leaseState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.leaseFetchRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.leaseFetchSuccess): {
      return { ...state, loading: false, data: action.payload };
    }
    case getType(actions.leaseFetchError): {
      return { ...state, loading: false, errors: action.payload };
    }

    case getType(actions.downloadLeaseDocumentRequest): {
      return { ...state, loading: true, downloadError: false };
    }
    case getType(actions.downloadLeaseDocumentSuccess): {
      return { ...state, loading: false };
    }
    case getType(actions.downloadLeaseDocumentError): {
      return { ...state, loading: false, errors: action.payload, downloadError: true };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
