import { createAction } from "typesafe-actions";

import { PropertyDocumentsItems } from "./models";

const PROPERTY_DOCUMETS_REQUEST = "propertydocuments/propertylist/FETCH_REQUEST";
const PROPERTY_DOCUMETS_SUCCESS = "propertydocuments/propertylist/FETCH_SUCCESS";
const PROPERTY_DOCUMETS_ERROR = "propertydocuments/propertylist/FETCH_ERROR";

export const propertyDocsRequest = createAction(PROPERTY_DOCUMETS_REQUEST)<string>();
export const propertyDocsSuccess = createAction(PROPERTY_DOCUMETS_SUCCESS)<PropertyDocumentsItems[]>();
export const propertyDocsError = createAction(PROPERTY_DOCUMETS_ERROR)<string>();
