import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardMedia,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Hidden,
  IconButton,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classnames from "classnames";
import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Dropdown from "react-dropdown";

import globalstyles from "../../../../global-styles";
import { RootState } from "../../../../store/root-reducer";
import { GenerateCloudinaryThumbnailUrl } from "../../../../utils/cloudinaryImageFormatter";
import combinestyles from "../../../../utils/combinestyles";
import { getActiveUserInfo } from "../../../selectAccount/selector";
import { logActivity } from "../../../shared/actions";
import DialogHeader from "../../../shared/components/DialogHeader";
import SharedImagesConstants from "../../../shared/constants/sharedImages";
import { UserInfoItem } from "../../../userInfo/models";
import { cancelRsvpRequest, rsvpRequest } from "../../actions";
import { joinGroupPostRequest } from "../../actionsGroup";
import * as constants from "../../constants";
import { EventItem, Group, JoinLeaveGroupDto, RpActivity, RsvpDto } from "../../models";
import PhotoGallery from "../common/PhotoGallery";
import eventstyles from "../styles";

const styles = combinestyles(eventstyles, globalstyles);
const FormatText = require("react-format-text");

interface Props extends WithStyles<typeof styles> {
  item: EventItem;
  userGroups: Group[];
}

interface PropsFromState {
  user?: UserInfoItem;
}

interface PropsFromDispatch {
  rsvpRequest: typeof rsvpRequest;
  cancelRsvpRequest: typeof cancelRsvpRequest;
  joinGroup: (request: JoinLeaveGroupDto, group: Group) => any;
  logActivity: typeof logActivity;
}

type AllProps = Props & PropsFromState & PropsFromDispatch;

interface State {
  expanded: boolean;
  displayJoinGroupDialog: boolean;
  displayGroupJoinedDialog: boolean;
  modalVisibility: boolean;
  guestListCount: string[];
  groupSize: string;
}

class EventTile extends React.Component<AllProps, State> {
  state = {
    expanded: false,
    displayJoinGroupDialog: false,
    displayGroupJoinedDialog: false,
    modalVisibility: false,
    guestListCount: [],
    groupSize: "",
  };

  componentDidMount = () => {
    this.setMaxGuestSize();
  };

  setMaxGuestSize = () => {
    let count = [];
    for (let i = 1; i <= this.props.item.maxGuests; i++) {
      count.push(i.toString());
    }
    this.setState({ guestListCount: count, groupSize: "1" });
  };

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !this.state.expanded }));
  };

  closeJoinGroupDialog = () => {
    this.setState({ displayJoinGroupDialog: false });
  };

  handleJoinGroup = () => {
    const joinGroupRequest: JoinLeaveGroupDto = {
      groupId: this.props.item.groupId,
      userNameId: this.props.user!.nameId,
    };

    this.props.joinGroup(joinGroupRequest, {} as Group);
    this.props.logActivity({
      actcode: "GJ",
      note: `Resident joined group - ${this.props.item.groupName}`,
    });
    this.setState({
      displayGroupJoinedDialog: true,
      displayJoinGroupDialog: false,
    });
  };

  closegroupJoinedDialog = () => {
    this.setState({ displayGroupJoinedDialog: false });
  };

  submitRsvp = (item: EventItem) => {
    if (item.attending === true) {
      if (item.guestsAllowed === true) {
        // cancel rsvp
        const requestObj: RsvpDto = {
          nameId: this.props.user!.nameId,
          eventId: item.id,
          groupId: 0,
          totalAttendees: parseInt(this.state.groupSize),
        };
        this.props.cancelRsvpRequest(requestObj);
        this.handleCloseModal();
      } else {
        // cancel rsvp
        const requestObj: RsvpDto = {
          nameId: this.props.user!.nameId,
          eventId: item.id,
          groupId: 0,
          totalAttendees: 1,
        };
        this.props.cancelRsvpRequest(requestObj);
      }
    } else {
      if (item.guestsAllowed === true) {
        //create rsvp
        const requestObj: RsvpDto = {
          nameId: this.props.user!.nameId,
          eventId: item.id,
          groupId: 0,
          totalAttendees: parseInt(this.state.groupSize),
        };

        this.props.rsvpRequest(requestObj);
        this.handleCloseModal();
      } else {
        const requestObj: RsvpDto = {
          nameId: this.props.user!.nameId,
          eventId: item.id,
          groupId: 0,
          totalAttendees: 1,
        };

        this.props.rsvpRequest(requestObj);
      }
      if (item.groupId !== undefined && item.groupId !== null && item.groupId > 0) {
        let matchedGroup = this.props.userGroups.filter((obj) => obj.id === item.groupId);
        if (matchedGroup.length === 0) {
          this.setState({ displayJoinGroupDialog: true });
        }
      }
    }
  };

  handleGroupSizeChange = (groupSize: any) => {
    this.setState({ groupSize: groupSize });
  };

  handleOpenModal = () => {
    this.setState({ modalVisibility: true });
  };
  handleCloseModal = () => {
    this.setState({ modalVisibility: false });
  };

  render() {
    const { item, classes } = this.props;
    const eventStartDate = item.startDate.toString();
    const eventEndDate = item.endDate.toString();
    let eventStartDay = Date.parse(eventStartDate.split("T")[0]);
    let eventEndDay = Date.parse(eventEndDate.split("T")[0]);

    return (
      <React.Fragment>
        <Card style={{ width: "100%" }} className={classes.card}>
          <Grid container direction="row" spacing={16}>
            <Grid item className={this.props.classes.eventDate}>
              <Typography align="center" className={classes.eventmonth} gutterBottom>
                <Moment format="MMM">{eventStartDate && eventStartDate.toString()}</Moment>
              </Typography>
              <Typography align="center" className={classes.eventday} gutterBottom>
                <Moment format="DD">{eventStartDate && eventStartDate.toString()}</Moment>
              </Typography>
            </Grid>
            <Grid item xs className={this.props.classes.eventDetails}>
              <Grid container spacing={16}>
                <Grid item xs sm={7} lg={8}>
                  <Typography variant="h5" style={{ marginBottom: ".5rem" }}>
                    {item.title}
                  </Typography>
                  <Grid container alignItems="center" style={{ marginBottom: "1rem" }}>
                    <Grid item style={{ display: "flex", marginRight: ".5rem" }}>
                      <CardMedia
                        className={classes.eventTimeLocation}
                        image={constants.EVENT_TIME_ICON}
                        style={{ marginLeft: "0" }}
                        title="Event time"
                      />
                      <Typography variant="h6" gutterBottom>
                        <Moment format="dddd" style={{ marginRight: "5px" }}>
                          {eventStartDate && eventStartDate.toString()}
                        </Moment>
                        <Moment format="LT" style={{ whiteSpace: "nowrap", marginRight: "5px" }}>
                          {eventStartDate && eventStartDate.toString()}
                        </Moment>
                        {eventStartDay !== eventEndDay && (
                          <React.Fragment>
                            <span className={classes.timeSpan}>-</span>
                            <Moment format="dddd" style={{ marginRight: "5px" }}>
                              {eventEndDate && eventEndDate.toString()}
                            </Moment>
                            <Moment format="LT" style={{ whiteSpace: "nowrap" }}>
                              {eventEndDate && eventEndDate.toString()}
                            </Moment>
                          </React.Fragment>
                        )}
                        {eventStartDay === eventEndDay && (
                          <React.Fragment>
                            <span className={classes.timeSpan}>-</span>
                            <Moment format="LT" style={{ whiteSpace: "nowrap" }}>
                              {eventEndDate && eventEndDate.toString()}
                            </Moment>
                          </React.Fragment>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item style={{ display: "flex" }}>
                      <CardMedia
                        className={classes.eventTimeLocation}
                        image={constants.EVENT_LOCATION_ICON}
                        style={{ marginLeft: "0" }}
                        title="Event location"
                      />
                      <Typography variant="h6" gutterBottom style={{ display: "inline-block" }}>
                        {item.location}
                      </Typography>
                    </Grid>
                  </Grid>
                  <hr />
                  <Typography gutterBottom>DESCRIPTION</Typography>
                  <Typography>
                    {!this.state.expanded && <FormatText>{item.message.substring(0, 50)}</FormatText>}
                  </Typography>
                  <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <Typography style={{ marginBottom: "1rem" }}>
                      <FormatText>{item.message}</FormatText>
                    </Typography>
                  </Collapse>
                  {item.message.length > 50 && (
                    <CardActions disableActionSpacing style={{ float: "left" }}>
                      <div onClick={this.handleExpandClick}>
                        {!this.state.expanded && <Typography className={classes.link}>More</Typography>}
                        {this.state.expanded && <Typography className={classes.link}>Less</Typography>}
                      </div>
                      <IconButton
                        className={classnames(classes.expand, {
                          [classes.expandOpen]: this.state.expanded,
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="More"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </CardActions>
                  )}
                </Grid>
                <Grid item xs={12} sm>
                  <Avatar
                    style={{ display: "inline-block" }}
                    alt={item.author.name}
                    src={
                      item.author.avatar
                        ? GenerateCloudinaryThumbnailUrl(item.author.avatar)
                        : SharedImagesConstants.EMPTYAVATAR
                    }
                    className={classes.avatar}
                  />
                  <Typography style={{ display: "inline-block" }}>
                    ORGANIZER <br />
                    {item.author.name}
                  </Typography>
                  {item.rsvpEnabled && (
                    <React.Fragment>
                      <hr />
                      <React.Fragment>
                        <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                          <CardMedia
                            className={classes.eventAttendance}
                            image={constants.EVENT_ATTENDANCE_ICON}
                            title="Event attendance"
                          />
                          <Typography inline variant="h5">
                            {item.rsvpCount} Attending
                          </Typography>
                        </div>
                      </React.Fragment>

                      <Hidden smUp>
                        <Grid>
                          {/* Guests Allowed */}
                          {this.props.item.guestsAllowed === true && (
                            <Grid>
                              {this.props.item.maxAttendees >= 0 &&
                                this.props.item.maxAttendees > this.props.item.rsvpCount && (
                                  <Grid>
                                    {!this.props.item.attending === true && (
                                      <Button
                                        onClick={this.handleOpenModal}
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        style={{ width: "100%" }}
                                      >
                                        RSVP
                                        {item.attending && (
                                          <React.Fragment>
                                            <Hidden xsDown>
                                              <img
                                                alt="RSVP"
                                                src={constants.EVENT_RSVP_ICON}
                                                style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                              />
                                            </Hidden>
                                            <Hidden smUp>
                                              <img
                                                alt="RSVP"
                                                src={constants.EVENT_RSVP_ICON_REVERSED}
                                                style={{ height: "30px", width: "30px", marginLeft: "5px" }}
                                              />
                                            </Hidden>
                                          </React.Fragment>
                                        )}
                                      </Button>
                                    )}
                                    {this.props.item.attending === true && (
                                      <Button
                                        onClick={() => this.submitRsvp(item)}
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        style={{ width: "100%" }}
                                      >
                                        RSVP
                                        {item.attending && (
                                          <React.Fragment>
                                            <Hidden xsDown>
                                              <img
                                                alt="RSVP"
                                                src={constants.EVENT_RSVP_ICON}
                                                style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                              />
                                            </Hidden>
                                            <Hidden smUp>
                                              <img
                                                alt="RSVP"
                                                src={constants.EVENT_RSVP_ICON_REVERSED}
                                                style={{ height: "30px", width: "30px", marginLeft: "5px" }}
                                              />
                                            </Hidden>
                                          </React.Fragment>
                                        )}
                                      </Button>
                                    )}
                                  </Grid>
                                )}
                              {this.props.item.maxAttendees > 0 &&
                                this.props.item.maxAttendees <= this.props.item.rsvpCount && (
                                  <Grid>
                                    {!this.props.item.attending === true && (
                                      <Grid>
                                        {/* <Button
                                          //onClick={this.handleOpenModal}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginRight: "0.625rem", marginBottom: "0.625rem" }}
                                        >
                                          RSVP
                                          {item.attending && (
                                            <React.Fragment>
                                              <Hidden xsDown>
                                                <img
                                                  src={constants.EVENT_RSVP_ICON}
                                                  style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                                />
                                              </Hidden>
                                              <Hidden smUp>
                                                <img
                                                  src={constants.EVENT_RSVP_ICON_REVERSED}
                                                  style={{ height: "30px", width: "30px", marginLeft: "5px" }}
                                                />
                                              </Hidden>
                                            </React.Fragment>
                                          )}
                                        </Button> */}
                                        <Typography inline variant="h5">
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                    {this.props.item.attending === true && (
                                      <Grid>
                                        <Button
                                          onClick={() => this.submitRsvp(item)}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginRight: "0.625rem", marginBottom: "0.625rem" }}
                                        >
                                          RSVP
                                          <React.Fragment>
                                            <Hidden xsDown>
                                              <img
                                                alt="RSVP"
                                                src={constants.EVENT_RSVP_ICON}
                                                style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                              />
                                            </Hidden>
                                            <Hidden smUp>
                                              <img
                                                alt="RSVP"
                                                src={constants.EVENT_RSVP_ICON_REVERSED}
                                                style={{ height: "30px", width: "30px", marginLeft: "5px" }}
                                              />
                                            </Hidden>
                                          </React.Fragment>
                                        </Button>
                                        <Typography inline variant="h5">
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                )}
                            </Grid>
                          )}

                          {/* Guests Not Allowed */}
                          {!(this.props.item.guestsAllowed === true) && (
                            <Grid>
                              {((this.props.item.maxAttendees >= 0 &&
                                this.props.item.maxAttendees >= this.props.item.rsvpCount) ||
                                (this.props.item.maxAttendees === 0 && this.props.item.maxGuests === 0)) && (
                                <Button
                                  onClick={() => this.submitRsvp(item)}
                                  variant="contained"
                                  size="large"
                                  color="primary"
                                  style={{ width: "100%" }}
                                >
                                  RSVP
                                  {item.attending && (
                                    <React.Fragment>
                                      <Hidden xsDown>
                                        <img
                                          alt="RSVP"
                                          src={constants.EVENT_RSVP_ICON}
                                          style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />
                                      </Hidden>
                                      <Hidden smUp>
                                        <img
                                          alt="RSVP"
                                          src={constants.EVENT_RSVP_ICON_REVERSED}
                                          style={{ height: "30px", width: "30px", marginLeft: "5px" }}
                                        />
                                      </Hidden>
                                    </React.Fragment>
                                  )}
                                </Button>
                              )}
                              {this.props.item.maxAttendees > 0 &&
                                this.props.item.maxAttendees <= this.props.item.rsvpCount && (
                                  <Grid>
                                    {!this.props.item.attending === true && (
                                      <Grid>
                                        {/* <Button
                                          //onClick={this.handleOpenModal}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginRight: "0.625rem", marginBottom: "0.625rem" }}
                                        >
                                          RSVP
                                          {item.attending && (
                                            <React.Fragment>
                                              <Hidden xsDown>
                                                <img
                                                  src={constants.EVENT_RSVP_ICON}
                                                  style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                                />
                                              </Hidden>
                                              <Hidden smUp>
                                                <img
                                                  src={constants.EVENT_RSVP_ICON_REVERSED}
                                                  style={{ height: "30px", width: "30px", marginLeft: "5px" }}
                                                />
                                              </Hidden>
                                            </React.Fragment>
                                          )}
                                        </Button> */}
                                        <Typography inline variant="h5">
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                    {this.props.item.attending === true && (
                                      <Grid>
                                        <Button
                                          onClick={() => this.submitRsvp(item)}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginRight: "0.625rem", marginBottom: "0.625rem" }}
                                        >
                                          RSVP
                                          {item.attending && (
                                            <React.Fragment>
                                              <Hidden xsDown>
                                                <img
                                                  alt="RSVP"
                                                  src={constants.EVENT_RSVP_ICON}
                                                  style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                                />
                                              </Hidden>
                                              <Hidden smUp>
                                                <img
                                                  alt="RSVP"
                                                  src={constants.EVENT_RSVP_ICON_REVERSED}
                                                  style={{ height: "30px", width: "30px", marginLeft: "5px" }}
                                                />
                                              </Hidden>
                                            </React.Fragment>
                                          )}
                                        </Button>
                                        <Typography inline variant="h5">
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                )}
                            </Grid>
                          )}
                        </Grid>
                      </Hidden>

                      <Hidden xsDown>
                        <Grid>
                          {/* Guessed Allowed */}
                          {this.props.item.guestsAllowed === true && (
                            <Grid>
                              {this.props.item.maxAttendees >= 0 &&
                                this.props.item.maxAttendees > this.props.item.rsvpCount && (
                                  <Grid>
                                    {!this.props.item.attending === true && (
                                      <Button
                                        onClick={this.handleOpenModal}
                                        variant="outlined"
                                        size="large"
                                        color="primary"
                                        style={{ marginBottom: "1rem" }}
                                      >
                                        RSVP
                                        {item.attending && (
                                          <React.Fragment>
                                            <Hidden xsDown>
                                              <img
                                                alt="RSVP"
                                                src={constants.EVENT_RSVP_ICON}
                                                style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                              />
                                            </Hidden>
                                            <Hidden smUp>
                                              <img
                                                alt="RSVP"
                                                src={constants.EVENT_RSVP_ICON_REVERSED}
                                                style={{ height: "30px", width: "30px", marginLeft: "5px" }}
                                              />
                                            </Hidden>
                                          </React.Fragment>
                                        )}
                                      </Button>
                                    )}
                                    {this.props.item.attending === true && (
                                      <Button
                                        onClick={() => this.submitRsvp(item)}
                                        variant="outlined"
                                        size="large"
                                        color="primary"
                                        style={{ marginBottom: "1rem" }}
                                      >
                                        RSVP
                                        {item.attending && (
                                          <React.Fragment>
                                            <Hidden xsDown>
                                              <img
                                                alt="RSVP"
                                                src={constants.EVENT_RSVP_ICON}
                                                style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                              />
                                            </Hidden>
                                            <Hidden smUp>
                                              <img
                                                alt="RSVP"
                                                src={constants.EVENT_RSVP_ICON_REVERSED}
                                                style={{ height: "30px", width: "30px", marginLeft: "5px" }}
                                              />
                                            </Hidden>
                                          </React.Fragment>
                                        )}
                                      </Button>
                                    )}
                                  </Grid>
                                )}
                              {this.props.item.maxAttendees > 0 &&
                                this.props.item.maxAttendees <= this.props.item.rsvpCount && (
                                  <Grid>
                                    {!this.props.item.attending === true && (
                                      <Grid>
                                        <Typography inline variant="h5">
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                    {this.props.item.attending === true && (
                                      <Grid>
                                        <Button
                                          onClick={() => this.submitRsvp(item)}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginRight: "0.625rem", marginBottom: "0.625rem" }}
                                        >
                                          RSVP
                                          {item.attending && (
                                            <React.Fragment>
                                              <Hidden xsDown>
                                                <img
                                                  alt="RSVP"
                                                  src={constants.EVENT_RSVP_ICON}
                                                  style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                                />
                                              </Hidden>
                                              <Hidden smUp>
                                                <img
                                                  alt="RSVP"
                                                  src={constants.EVENT_RSVP_ICON_REVERSED}
                                                  style={{ height: "30px", width: "30px", marginLeft: "5px" }}
                                                />
                                              </Hidden>
                                            </React.Fragment>
                                          )}
                                        </Button>
                                        <Typography inline variant="h5">
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                )}
                            </Grid>
                          )}
                          {/* Guessed Not Allowed */}
                          {!(this.props.item.guestsAllowed === true) && (
                            <Grid>
                              {((this.props.item.maxAttendees >= 0 &&
                                this.props.item.maxAttendees >= this.props.item.rsvpCount) ||
                                (this.props.item.maxAttendees === 0 && this.props.item.maxGuests === 0)) && (
                                <Button
                                  onClick={() => this.submitRsvp(item)}
                                  variant="outlined"
                                  size="large"
                                  color="primary"
                                  style={{ marginBottom: "1rem" }}
                                >
                                  RSVP
                                  {item.attending && (
                                    <React.Fragment>
                                      <Hidden xsDown>
                                        <img
                                          alt="RSVP"
                                          src={constants.EVENT_RSVP_ICON}
                                          style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                        />
                                      </Hidden>
                                      <Hidden smUp>
                                        <img
                                          alt="RSVP"
                                          src={constants.EVENT_RSVP_ICON_REVERSED}
                                          style={{ height: "30px", width: "30px", marginLeft: "5px" }}
                                        />
                                      </Hidden>
                                    </React.Fragment>
                                  )}
                                </Button>
                              )}
                              {this.props.item.maxAttendees > 0 &&
                                this.props.item.maxAttendees <= this.props.item.rsvpCount && (
                                  <Grid>
                                    {!this.props.item.attending === true && (
                                      <Grid>
                                        <Typography inline variant="h5">
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                    {this.props.item.attending === true && (
                                      <Grid>
                                        <Button
                                          onClick={() => this.submitRsvp(item)}
                                          variant="outlined"
                                          size="large"
                                          color="primary"
                                          style={{ marginRight: "0.625rem", marginBottom: "0.625rem" }}
                                        >
                                          RSVP
                                          {item.attending && (
                                            <React.Fragment>
                                              <Hidden xsDown>
                                                <img
                                                  alt="RSVP"
                                                  src={constants.EVENT_RSVP_ICON}
                                                  style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                                                />
                                              </Hidden>
                                              <Hidden smUp>
                                                <img
                                                  alt="RSVP"
                                                  src={constants.EVENT_RSVP_ICON_REVERSED}
                                                  style={{ height: "30px", width: "30px", marginLeft: "5px" }}
                                                />
                                              </Hidden>
                                            </React.Fragment>
                                          )}
                                        </Button>
                                        <Typography inline variant="h5">
                                          Event is full
                                        </Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                )}
                            </Grid>
                          )}

                          <Grid container justify="flex-start">
                            <Grid item>
                              <PhotoGallery images={item.images} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Hidden>
                    </React.Fragment>
                  )}
                  {!item.rsvpEnabled && item.images.length !== 0 && (
                    <React.Fragment>
                      <hr />
                      <Grid container justify="flex-start">
                        <Grid item>
                          <PhotoGallery images={item.images} />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Dialog open={this.state.modalVisibility} onClose={this.handleCloseModal} fullScreen={false}>
          <DialogContent style={{ width: "39vw", height: "32vh", minWidth: "300px", minHeight: "300px" }}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                className={this.props.classes.tablepadding}
                gutterBottom
                style={{ textAlign: "center" }}
              >
                Total Attendees (including yourself)
              </Typography>
              <Grid style={{ width: "100px", marginLeft: "13vw" }}>
                <Dropdown
                  options={this.state.guestListCount}
                  onChange={(option) => this.handleGroupSizeChange(option.value)}
                  value={this.state.groupSize}
                  placeholder={this.state.groupSize}
                  className={this.props.classes.dropdown}
                  controlClassName={this.props.classes.dropdowncomponent}
                  placeholderClassName={this.props.classes.dropdownplaceholder}
                  menuClassName={this.props.classes.dropdownmenu}
                />
              </Grid>
            </Grid>
            <Button
              onClick={() => this.submitRsvp(item)}
              variant="outlined"
              size="large"
              color="primary"
              style={{ marginRight: "0.625rem", marginBottom: "0.625rem", marginTop: "0.625rem", marginLeft: "12.5vw" }}
            >
              Continue
            </Button>
          </DialogContent>
        </Dialog>
        <Dialog open={this.state.displayJoinGroupDialog} onClose={this.closeJoinGroupDialog}>
          <DialogHeader title="Thanks for your RSVP!" handleClose={this.closeJoinGroupDialog} />
          <DialogContent>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={3}>
                <img alt="Attendance" src={constants.EVENT_ATTENDANCE_ICON} />
              </Grid>
              <Grid item xs={9}>
                <p>
                  By the way, this event was created for the group {item.groupName}. Looks like you're not a member of
                  that group, would you like to join?
                </p>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeJoinGroupDialog} color="primary">
              No Thanks
            </Button>
            <Button onClick={this.handleJoinGroup} color="primary" autoFocus>
              Join Group
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.displayGroupJoinedDialog} onClose={this.closegroupJoinedDialog}>
          <DialogHeader title="Request for new group" handleClose={this.closegroupJoinedDialog} />
          <DialogContent>
            <img alt="RSVP" src={constants.EVENT_RSVP_ICON} />
            <p>Your request has been sent!</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closegroupJoinedDialog} color="primary">
              Got it!
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  rsvpRequest: (payload: RsvpDto) => dispatch(rsvpRequest(payload)),
  cancelRsvpRequest: (payload: RsvpDto) => dispatch(cancelRsvpRequest(payload)),
  joinGroup: (request: JoinLeaveGroupDto, group: Group) =>
    dispatch(joinGroupPostRequest({ request: request, group: group })),
  logActivity: (act: RpActivity) => dispatch(logActivity(act)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventTile));
