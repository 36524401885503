import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";

import * as actions from "./actions";
import * as actionsGroup from "./actionsGroup";
import { DashboardFeedItem, EventItem, UserInfo } from "./models";

export type SocialAction = ActionType<typeof actions>;

export type SocialState = Readonly<{
  loading: boolean;
  posting: boolean;
  noposts: boolean;
  data: DashboardFeedItem[];
  errors?: string;
  filterBy: string;
  hasMore: boolean;
  userInfo?: UserInfo;
  events: EventItem[];
  modalOpen: boolean;
  editingModalOpen: boolean;
}>;

const initialState: SocialState = {
  data: [],
  errors: undefined,
  loading: false,
  posting: false,
  noposts: false,
  filterBy: "all",
  hasMore: true,
  userInfo: undefined,
  events: [],
  modalOpen: false,
  editingModalOpen: false
};

const reducer: Reducer<SocialState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.dashboardFetchRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.dashboardFetchSuccess): {
      return {
        ...state,
        loading: false,
        data: action.payload,
        noposts: action.payload.length === 0,
        hasMore: action.payload.length > 0 && action.payload[action.payload.length - 1].itemType === "PlaceholderItem"
      };
    }
    case getType(actions.dashboardFetchError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.dashboardFetchMoreRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.dashboardFetchMoreSuccess): {
      return {
        ...state,
        loading: false,
        data: state.data.concat(action.payload),
        noposts: action.payload.length === 0,
        hasMore: action.payload.length > 0 && action.payload[action.payload.length - 1].itemType === "PlaceholderItem"
      };
    }
    case getType(actions.dashboardFetchMoreError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.createPostRequest): {
      return { ...state, posting: true };
    }
    case getType(actions.createPostSuccess): {
      return { ...state, posting: false, loading: false, data: action.payload, modalOpen: false };
    }
    case getType(actions.postUpdateSuccess): {
      return { ...state, posting: false, loading: false, data: action.payload, modalOpen: false };
    }
    case getType(actions.hidePostSuccess): {
      return { ...state, posting: false, loading: false, data: action.payload, modalOpen: false };
    }
    case getType(actions.hideCommentSuccess): {
      return { ...state, posting: false, loading: false, data: action.payload, modalOpen: false };
    }
    case getType(actions.createPostError): {
      return { ...state, posting: false, loading: false, errors: action.payload, modalOpen: true };
    }
    case getType(actions.eventsFetchRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.eventsFetchSuccess): {
      return { ...state, loading: false, events: action.payload };
    }
    case getType(actions.eventsFetchError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.rsvpRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.rsvpSuccess): {
      return { ...state, loading: false, events: action.payload };
    }
    case getType(actions.rsvpError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.cancelRsvpRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.cancelRsvpSuccess): {
      return { ...state, loading: false, events: action.payload };
    }
    case getType(actions.cancelRsvpError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.changeFilter): {
      return { ...state, loading: false, filterBy: action.payload };
    }
    case getType(actions.blockUserRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.blockUserSuccess): {
      return { ...state, loading: false, data: action.payload };
    }
    case getType(actions.blockUserError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.feedUserInfoFetchRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.feedUserInfoFetchSuccess): {
      return { ...state, loading: false, userInfo: action.payload };
    }
    case getType(actions.feedUserInfoFetchError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.addCommentRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.addCommentSuccess): {
      return { ...state, loading: false, data: action.payload };
    }
    case getType(actions.addCommentError): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(actions.openSocialModal): {
      return { ...state, modalOpen: true };
    }
    case getType(actions.closeSocialModal): {
      return { ...state, modalOpen: false };
    }
    case getType(actions.openEditModal): {
      return { ...state, editingModalOpen: true };
    }
    case getType(actions.closeEditModal): {
      return { ...state, editingModalOpen: false };
    }
    case getType(actionsGroup.createGroupRequest): {
      return { ...state, loading: true };
    }
    case getType(actionsGroup.createPostGroupRequest): {
      return { ...state, posting: true };
    }
    case getType(actionsGroup.createPostGroupSuccess): {
      return { ...state, posting: false, loading: false, dataGroupFeed: action.payload, modalOpen: false };
    }
    case getType(actionsGroup.createPostGroupError): {
      return { ...state, posting: false, loading: false, errors: action.payload, modalOpen: true };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
