import * as React from "react";

import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { InjectedProps } from "@material-ui/core/withMobileDialog";

interface Props {
  onContinue: any;
  onCancel: any;
  open: boolean;
}
type AllProps = Props & InjectedProps;

class GuestFormPermissionToEnter extends React.Component<AllProps> {
  render() {
    const { fullScreen, open } = this.props;

    return (
      <Dialog fullScreen={fullScreen} open={open}>
        <DialogTitle>Permission to Enter</DialogTitle>
        <DialogContent>
          <Typography>
            By submitting this guest access request, you are authorizing us to allow the person(s) listed above to enter
            your apartment during the date(s) shown. Unless otherwise indicated, it is not necessary for us or any
            member of our staff to accompany your guests/invitees to your apartment. By clicking "agree" below and,
            consistent with the provisions of your Lease, you acknowledge that you are responsible for the actions of
            your guests/invitees while they are present in the building and in your apartment. Further, by clicking
            "agree" below, you agree to release us from liability for any injury or loss caused by your guests/invitees
            as a result of our providing them access to the building and to your apartment. You can modify or terminate
            this request for guest access at any time.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel} color="primary" autoFocus>
            Cancel
          </Button>
          {/* <Button onClick={this.props.onContinue.bind(this, this.state)} color="primary" autoFocus>
            Continue
          </Button> */}
        </DialogActions>
      </Dialog>
    );
  }
}

export default GuestFormPermissionToEnter;
