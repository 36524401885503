import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { store } from "../../AppContainer";
import { loadGuest, loadGuestTypes, postGuestAccess, postGuestAccessPhoto, putGuestAccess } from "../../utils/api";
import { showModalAction } from "../errorhandling/actions";
import { getActiveUserInfo } from "../selectAccount/selector";
import { logActivity } from "../shared/actions";
import * as actions from "./actions";

function* handleFetch(action: ReturnType<typeof actions.fetchRequest>) {
  try {
    const nameid = action.payload;

    const response = yield call(loadGuest, nameid);

    if (response.error) {
      yield put(actions.fetchError(response.error));
    } else {
      yield put(actions.fetchSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.fetchError(err.stack!));
    } else {
      yield put(actions.fetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleFetchGuestTypes(action: ReturnType<typeof actions.guesttypesFetchRequest>) {
  try {
    const response = yield call(loadGuestTypes);

    if (response.error) {
      yield put(actions.guesttypesFetchError(response.error));
    } else {
      const strMap = new Map<string, string>();
      for (const key of Object.keys(response)) {
        strMap.set(key, response[key]);
      }
      yield put(actions.guesttypesFetchSuccess(strMap));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.guesttypesFetchError(err.stack!));
    } else {
      yield put(actions.guesttypesFetchError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handlePostRequest(action: ReturnType<typeof actions.postRequest>) {
  try {
    const newGuestAccess = action.payload.newGuestItem;
    const response = yield call(postGuestAccess, newGuestAccess);

    if (response.error) {
      yield put(actions.postError(response.error));
    } else {
      yield put(logActivity({ actcode: "GA", note: `Guest added by resident: ${newGuestAccess.guestInformation}` }));
      const newGuestAccessPhoto = action.payload.photo;
      if (newGuestAccessPhoto) {
        yield call(postGuestAccessPhoto, response.id, newGuestAccessPhoto);
      }
      yield put(actions.postSuccess(response));
      const nameId = getActiveUserInfo(store.getState().selectAccount)!.nameId;
      yield put(actions.fetchRequest(nameId));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.postError(err.stack!));
    } else {
      yield put(actions.postError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handlePutRequest(action: ReturnType<typeof actions.putRequest>) {
  try {
    const editGuestAccess = action.payload.guestItem;
    const response = yield call(putGuestAccess, editGuestAccess);

    if (response.error) {
      yield put(actions.putError(response.error));
    } else {
      const newGuestAccessPhoto = action.payload.photo;
      if (newGuestAccessPhoto) {
        yield call(postGuestAccessPhoto, response.id, newGuestAccessPhoto);
      }
      yield put(actions.putSuccess(response));
      yield put(actions.fetchRequest(editGuestAccess.nameId));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.putError(err.stack!));
    } else {
      yield put(actions.putError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handlePutInactivateRequest(action: ReturnType<typeof actions.putInactivateRequest>) {
  try {
    const editGuestAccess = action.payload;
    const response = yield call(putGuestAccess, editGuestAccess);

    if (response.error) {
      yield put(actions.putInactivateError(response.error));
    } else {
      yield put(actions.putInactivateSuccess(response));
      yield put(actions.fetchRequest(editGuestAccess.authorizedBy));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(actions.putInactivateError(err.stack!));
    } else {
      yield put(actions.putInactivateError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(getType(actions.fetchRequest), handleFetch);
  yield takeEvery(getType(actions.postRequest), handlePostRequest);
  yield takeEvery(getType(actions.guesttypesFetchRequest), handleFetchGuestTypes);
  yield takeEvery(getType(actions.putRequest), handlePutRequest);
  yield takeEvery(getType(actions.putInactivateRequest), handlePutInactivateRequest);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* guestSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default guestSaga;
