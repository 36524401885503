import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";
import { ApplicationCheckList } from "./models";
import * as applicant from "./actions";

export type ApplicationChecklistAction = ActionType<typeof applicant>;

export type ApplicantChecklistState = Readonly<{
  loading: boolean;
  checkList?: ApplicationCheckList;
  errors?: string;
}>;

const initialState: ApplicantChecklistState = {
  checkList: undefined,
  errors: undefined,
  loading: false
};

const reducer: Reducer<ApplicantChecklistState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(applicant.fetchRequestAsync.request): {
      return { ...state, loading: true };
    }
    case getType(applicant.fetchRequestAsync.success): {
      return { ...state, loading: false, checkList: action.payload };
    }
    case getType(applicant.fetchRequestAsync.failure): {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer; // must be registered in ../../store/root-reducer.ts
