import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import { InjectedProps } from "@material-ui/core/withMobileDialog";
import * as React from "react";

import { ServiceQuestions } from "../models";

interface Props {
  onContinue: any;
  onCancel: any;
  open: boolean;
  questions: ServiceQuestions[];
}

type AllProps = Props & InjectedProps;

class ServiceReqFormQuestionnaire extends React.Component<AllProps> {
  state = {
    covidYes: false
  };

  change = (e: any) => {
    if (!e.target) {
      return;
    }

    this.setState({ covidYes: true });
  };

  render() {
    const { fullScreen, open, questions } = this.props;

    var covidQuestions = [];

    for (const [index, value] of questions.entries()) {
      covidQuestions.push(
        <Typography key={index}>
          {index + 1}) {value.covidQuestion}
        </Typography>
      );
      covidQuestions.push(<br />);
    }

    covidQuestions.pop();

    return (
      <Dialog fullScreen={fullScreen} open={open}>
        <DialogTitle>Health and Safety</DialogTitle>
        <DialogContent>
          <Typography>
            In keeping the health and safety of our residents and employees as a priority, we would like to ask you the
            following questions.
          </Typography>
          <br />
          <Grid container alignItems="center" style={{ marginTop: "1rem" }}>
            <Grid item>{covidQuestions}</Grid>
          </Grid>
          <br />
        </DialogContent>
        <Grid container alignItems="flex-end" direction="column">
          <Grid item>
            <Typography style={{ margin: "0.5rem" }}>Can you answer "yes" to any of these questions?</Typography>
          </Grid>
          <Grid item>
            <DialogActions>
              <Button onClick={this.props.onCancel} color="default" variant="contained" autoFocus>
                Yes
              </Button>
              <Button
                onClick={this.props.onContinue.bind(this, this.state)}
                color="primary"
                variant="contained"
                autoFocus
              >
                No
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

export default ServiceReqFormQuestionnaire;
