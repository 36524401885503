import { Grid, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { UserInfoItem } from "../../userInfo/models";
import homestyles from "../../social/components/styles";

const styles = combinestyles(homestyles, globalstyles);

export interface HomeHeaderTilesProps extends WithStyles<typeof styles> {
  user?: UserInfoItem;
}

interface PropsFromState {
  userData: UserInfoItem;
}

type AllProps = PropsFromState & HomeHeaderTilesProps;

class HomeHeaderTiles extends React.Component<AllProps> {
  render() {
    const headerTiles = this.props.user!.propertyInfo.homeTiles;
    const officePhoneNumber = this.GetFormatPhone(this.props.user!.propertyInfo.propertyPhone);
    let headerTilesArray = [];
    for (let i = 0; i < headerTiles.length; i++) {
      headerTilesArray.push(
        `${headerTiles[i].title.toString()} ${headerTiles[i].copy} ${headerTiles[i].iconFile} 
        ${headerTiles[i].copyColor} ${headerTiles[i].titleColor} ${headerTiles[i].website}`
      );
    }
    return headerTiles.map((ht, i) => (
      <div
        style={{ backgroundColor: ht.backgroundColor, paddingTop: ".5em", paddingBottom: ".5em", width: "100%" }}
        key={i}
      >
        {ht.status !== "I" && (
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={16}
            style={{ display: "flex", maxWidth: "1320px", width: "90%", marginLeft: "auto", marginRight: "auto" }}
          >
            <Grid item xs>
              <Grid container alignItems="center" spacing={16}>
                <Grid item xs>
                  <Typography
                    variant="body2"
                    style={{ overflowWrap: "break-word", fontWeight: 300, textAlign: "center", color: "#ffffff" }}
                  >
                    {ht.copy.replace("[PHONE]", officePhoneNumber)}{" "}
                    {ht.website && (
                      <a href={ht.website} target="_blank" rel="noreferrer" style={{ color: "#ffffff" }}>
                        Learn More
                      </a>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={3}>
                <Button href={ht.website} color="primary" variant="contained">Learn More</Button>
              </Grid> */}
          </Grid>
        )}
      </div>
    ));
  }

  private GetFormatPhone = (phone: string) => {
    let res = "";

    if (phone.length < 10) {
      res = "";
    } else {
      var prefix = phone.substring(0, 3);
      var dns = phone.substring(3, 6);
      var suffix = phone.substring(6, 10);

      var ext = "";
      if (phone.length > 10) {
        ext = " x" + phone.substring(10, phone.length - 10);
        res = "(" + prefix + ") " + dns + "-" + suffix + ext;
      } else {
        res = "(" + prefix + ") " + dns + "-" + suffix;
      }
    }

    return res;
  };
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  userData: getActiveUserInfo(selectAccount),
});

export default connect(mapStateToProps)(withStyles(styles)(HomeHeaderTiles));
