import { Grid, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import { thousands_separators } from "../../shared/utils/utils";
import { NoticeSummary } from "../models";
import entvstyles from "../styles";

const styles = combinestyles(entvstyles, globalstyles);

export interface SummaryElectionsProps extends WithStyles<typeof styles> {
  noticeSummary?: NoticeSummary;
}

type State = {};

type AllProps = SummaryElectionsProps & RouteComponentProps;

class SummaryElections extends React.Component<AllProps, State> {
  state: Readonly<State> = {};

  render() {
    const { classes } = this.props;
    const monthCharges = this.props.noticeSummary!.leaseCharges;
    const mtmCharges = this.props.noticeSummary!.leaseChargesMTM;
    var leaseEnd: string;
    if (this.props.noticeSummary!.leaseEnd === "Month-To-Month") {
      leaseEnd = "Month-To-Month";
    } else {
      leaseEnd = moment(new Date(this.props.noticeSummary!.leaseEnd))
        .add(1, "days")
        .toString();
    }

    const E3DesriptionSettlement = this.props.noticeSummary!.copyLanguage.find(e => {
      return e.languageId === "A6";
    });
    const E3DesriptionRent = this.props.noticeSummary!.copyLanguage.find(e => {
      return e.languageId === "A7";
    });
    const insufficientNotice = this.props.noticeSummary!.copyLanguage.find(e => {
      return e.languageId === "A13";
    });
    let monthlyCharges = [];
    let MTMCharges = [];

    if (monthCharges) {
      for (let i = 0; i < monthCharges.length; i++) {
        monthlyCharges.push(
          <Grid container className={this.props.classes.grouping} key={i}>
            <Grid xs={6} item style={{ marginBottom: "1rem" }}>
              <Typography gutterBottom style={{ display: "inline-block" }}>
                {monthCharges[i].description}
              </Typography>
            </Grid>
            <Grid xs={6} item style={{ textAlign: "right", marginBottom: "1rem" }}>
              <Typography style={{ display: "inline-block", fontWeight: "bold", color: "green" }}>
                ${thousands_separators(monthCharges[i].amount.toFixed(2))}
              </Typography>
            </Grid>
          </Grid>
        );
      }
    }
    if (mtmCharges) {
      for (let i = 0; i < mtmCharges.length; i++) {
        MTMCharges.push(
          <Grid container className={this.props.classes.grouping}>
            <Grid xs={6} item style={{ marginBottom: "1rem" }}>
              <Typography gutterBottom style={{ display: "inline-block" }}>
                {mtmCharges[i].description}
              </Typography>
            </Grid>
            <Grid xs={6} item style={{ textAlign: "right", marginBottom: "1rem" }}>
              <Typography style={{ display: "inline-block", fontWeight: "bold", color: "green" }}>
                ${thousands_separators(mtmCharges[i].amount.toFixed(2))}
              </Typography>
            </Grid>
          </Grid>
        );
      }
    }

    return (
      <Paper className={classes.paper}>
        <Grid container className={this.props.classes.grouping}>
          {/* Header */}
          <Grid xs={12} item>
            <Typography gutterBottom variant="h3" style={{ display: "inline-block", marginBottom: "1rem" }}>
              Summary of Charges
            </Typography>
          </Grid>

          {/* Sufficient and Insufficient Notice Lease End Date and Early Termination */}
          {!(this.props.noticeSummary!.ntvCode === "E2" || this.props.noticeSummary!.ntvCode === "E5") && (
            <Grid container className={this.props.classes.grouping}>
              <Grid xs={8} item style={{ marginBottom: "1rem" }}>
                <Typography gutterBottom style={{ display: "inline-block" }}>
                  Monthly Apartment Rent {this.props.noticeSummary!.proratedLabel}
                  {/* <Moment
                    format="MM/DD/YY"
                    date={
                      new Date(
                        new Date(this.props.noticeSummary!.vacateDate).getFullYear(),
                        new Date(this.props.noticeSummary!.vacateDate).getMonth(),
                        1
                      )
                    }
                  />{" "}
                  - <Moment format="MM/DD/YY" date={this.props.noticeSummary!.vacateDate} /> */}
                </Typography>
              </Grid>
              <Grid xs={4} item style={{ textAlign: "right", marginBottom: "1rem" }}>
                <Typography style={{ display: "inline-block", fontWeight: "bold", color: "green" }}>
                  ${thousands_separators(this.props.noticeSummary!.proratedRent.toFixed(2))}
                </Typography>
              </Grid>
              {monthlyCharges.length > 0 && (
                <Grid container className={this.props.classes.grouping}>
                  <Grid xs={12} item>
                    <Typography gutterBottom variant="h3" style={{ display: "inline-block", marginBottom: "1rem" }}>
                      Recurring Monthly Charges {this.props.noticeSummary!.proratedLabel}
                      {/* <Moment
                        format="MM/DD/YY"
                        date={
                          new Date(
                            new Date(this.props.noticeSummary!.vacateDate).getFullYear(),
                            new Date(this.props.noticeSummary!.vacateDate).getMonth(),
                            1
                          )
                        }
                      />{" "}
                      - <Moment format="MM/DD/YY" date={this.props.noticeSummary!.vacateDate} /> */}
                    </Typography>
                  </Grid>
                  <Grid container className={this.props.classes.grouping}>
                    {monthlyCharges}
                  </Grid>
                </Grid>
              )}
              {(this.props.noticeSummary!.ntvCode === "E3" || this.props.noticeSummary!.ntvCode === "E6") && (
                <Grid container className={this.props.classes.grouping}>
                  <Grid xs={8} item style={{ marginBottom: "1rem" }}>
                    {this.props.noticeSummary!.ntvCode === "E3" && this.props.noticeSummary!.e3Option === 1 && (
                      <Grid>
                        <Typography gutterBottom variant="h3" style={{ display: "inline-block" }}>
                          Settlement Agreement
                        </Typography>
                        <Typography gutterBottom style={{ display: "inline-block" }}>
                          {E3DesriptionSettlement!.copyLanguage}
                        </Typography>
                      </Grid>
                    )}
                    {this.props.noticeSummary!.ntvCode === "E3" && this.props.noticeSummary!.e3Option === 2 && (
                      <Grid>
                        <Typography gutterBottom variant="h3" style={{ display: "inline-block" }}>
                          Rent Until Re-Rented
                        </Typography>
                        <Typography gutterBottom style={{ display: "inline-block" }}>
                          {E3DesriptionRent!.copyLanguage}
                        </Typography>
                      </Grid>
                    )}
                    {this.props.noticeSummary!.ntvCode === "E6" && (
                      <Grid>
                        <Typography gutterBottom variant="h3" style={{ display: "inline-block" }}>
                          Rent Until Re-Rented
                        </Typography>
                        <Typography gutterBottom style={{ display: "inline-block" }}>
                          You are responsible for paying rent through either the termination date of your lease or until
                          the apartment is reoccupied, whichever comes first.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Grid xs={4} item style={{ textAlign: "right", marginBottom: "1rem" }}>
                    {this.props.noticeSummary!.ntvCode === "E3" && this.props.noticeSummary!.e3Option === 1 && (
                      <Typography style={{ display: "inline-block", fontWeight: "bold", color: "green" }}>
                        ${thousands_separators(this.props.noticeSummary!.settleOffer.toFixed(2))}
                      </Typography>
                    )}
                    {this.props.noticeSummary!.ntvCode === "E3" && this.props.noticeSummary!.e3Option === 2 && (
                      <Typography style={{ display: "inline-block", fontWeight: "bold", color: "green" }}>
                        ${thousands_separators(Number(this.props.noticeSummary!.rentEarlyTerm).toFixed(2))}
                      </Typography>
                    )}
                    {this.props.noticeSummary!.ntvCode === "E6" && (
                      <Typography style={{ display: "inline-block", fontWeight: "bold", color: "green" }}>
                        ${thousands_separators(Number(this.props.noticeSummary!.rentEarlyTerm).toFixed(2))}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}

          {/* Sufficient and Insufficient Month-to-Month */}
          {(this.props.noticeSummary!.ntvCode === "E2" || this.props.noticeSummary!.ntvCode === "E5") && (
            <Grid container className={this.props.classes.grouping}>
              <Grid xs={8} item style={{ marginBottom: "1rem" }}>
                <Typography gutterBottom style={{ display: "inline-block" }}>
                  Monthly Apartment Rent {this.props.noticeSummary!.proratedLabel}
                  {/* <Moment
                    format="MM/DD/YY"
                    date={
                      new Date(
                        new Date(this.props.noticeSummary!.vacateDate).getFullYear(),
                        new Date(this.props.noticeSummary!.vacateDate).getMonth(),
                        1
                      )
                    }
                  />{" "}
                  - <Moment format="MM/DD/YY" date={this.props.noticeSummary!.leaseEnd} /> */}
                </Typography>
              </Grid>
              <Grid xs={4} item style={{ textAlign: "right", marginBottom: "1rem" }}>
                <Typography style={{ display: "inline-block", fontWeight: "bold", color: "green" }}>
                  ${thousands_separators(this.props.noticeSummary!.proratedRent.toFixed(2))}
                </Typography>
              </Grid>

              {/* Recurring Monthly Charges */}
              {monthlyCharges.length > 0 && (
                <Grid container className={this.props.classes.grouping} style={{ marginBottom: "1rem" }}>
                  <Grid xs={12} item>
                    <Typography gutterBottom variant="h3" style={{ display: "inline-block", marginBottom: "1rem" }}>
                      Recurring Monthly Charges {this.props.noticeSummary!.proratedLabel}
                      {/* <Moment
                        format="MM/DD/YY"
                        date={
                          new Date(
                            new Date(this.props.noticeSummary!.vacateDate).getFullYear(),
                            new Date(this.props.noticeSummary!.vacateDate).getMonth(),
                            1
                          )
                        }
                      />{" "}
                      - <Moment format="MM/DD/YY" date={this.props.noticeSummary!.leaseEnd} /> */}
                    </Typography>
                  </Grid>
                  <Grid container className={this.props.classes.grouping}>
                    {monthlyCharges}
                  </Grid>
                </Grid>
              )}

              {/* Month-to-Month Apartment Rent */}
              <Grid xs={8} item style={{ marginBottom: "1rem" }}>
                <Typography gutterBottom style={{ display: "inline-block" }}>
                  Month-to-Month Apartment Rent <Moment format="MM/DD/YY" date={leaseEnd} /> -{" "}
                  <Moment format="MM/DD/YY" date={this.props.noticeSummary!.vacateDate} />
                </Typography>
              </Grid>
              <Grid xs={4} item style={{ textAlign: "right", marginBottom: "1rem" }}>
                {MTMCharges.length > 0 && (
                  <Typography style={{ display: "inline-block", fontWeight: "bold", color: "green" }}>
                    ${thousands_separators(this.props.noticeSummary!.mtmProratedRent.toFixed(2))}
                  </Typography>
                )}
              </Grid>
              {MTMCharges.length === 0 && (
                <Grid container className={this.props.classes.grouping}>
                  <Grid xs={12} item>
                    <Typography gutterBottom style={{ display: "inline-block", marginBottom: "1rem" }}>
                      Your Month-to-Month rent is unavailable at this time. It's possible that you will be required to
                      pay rent at a higher rate for the days you stay beyond your lease end date.
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {/* Month-to-Month Lease charges */}
              {MTMCharges.length > 0 && (
                <Grid container className={this.props.classes.grouping}>
                  <Grid xs={12} item>
                    <Typography gutterBottom variant="h3" style={{ display: "inline-block", marginBottom: "1rem" }}>
                      Recurring Month-to-Month Charges <Moment format="MM/DD/YY" date={leaseEnd} /> -{" "}
                      <Moment format="MM/DD/YY" date={this.props.noticeSummary!.vacateDate} />
                    </Typography>
                  </Grid>
                  <Grid container className={this.props.classes.grouping}>
                    {MTMCharges}
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}

          {/* Insuficient Notices */}
          {(this.props.noticeSummary!.ntvCode === "E4" ||
            this.props.noticeSummary!.ntvCode === "E5" ||
            this.props.noticeSummary!.ntvCode === "E6") && (
            <Grid container className={this.props.classes.grouping}>
              <Grid xs={6} item>
                <Typography gutterBottom variant="h3" style={{ display: "inline-block" }}>
                  Insufficient Notice
                </Typography>
              </Grid>
              <Grid xs={6} item style={{ textAlign: "right", marginBottom: "1rem" }}>
                <Typography style={{ display: "inline-block", fontWeight: "bold", color: "green" }}>
                  ${thousands_separators(this.props.noticeSummary!.insufficientAmount.toFixed(2))}
                </Typography>
              </Grid>
              <Grid xs={6} item style={{ marginBottom: "1rem" }}>
                <Typography gutterBottom style={{ display: "inline-block" }}>
                  {insufficientNotice!.copyLanguage}
                </Typography>
              </Grid>
            </Grid>
          )}

          {/* EasyMove */}
          {this.props.noticeSummary!.isEasyMove.toString() === "Y" && (
            <Grid container className={this.props.classes.grouping}>
              {this.props.noticeSummary!.easyMoveLabel !== "" && (
                <Grid container className={this.props.classes.grouping}>
                  <Grid xs={8} item style={{ marginBottom: "1rem" }}>
                    <Typography gutterBottom variant="h3" style={{ display: "inline-block" }}>
                      EasyMove
                    </Typography>
                    <div />
                    <Typography gutterBottom style={{ display: "inline-block" }}>
                      {this.props.noticeSummary!.easyMoveLabel}
                    </Typography>
                  </Grid>
                  <Grid xs={4} item style={{ textAlign: "right", marginBottom: "1rem" }}>
                    <Typography style={{ display: "inline-block", fontWeight: "bold", color: "green" }}>
                      ${thousands_separators(this.props.noticeSummary!.cleaningPrice.toFixed(2))}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }
}

export default withRouter(connect()(withStyles(styles)(SummaryElections)));
