import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Moment from "react-moment";
import { RootState } from "../store/root-reducer";
import { Paper, Typography, Link, Grid, Divider, withStyles, WithStyles, Button } from "@material-ui/core";
import Attachment from "@material-ui/icons/Attachment";
import { LeaseDetails, MonthlyFee, Pet } from "../features/mylease/models";
import { leaseFetchRequest, downloadLeaseDocumentRequest } from "../features/mylease/actions";
import { Occupant } from "./../features/mylease/models";

import myleasestyles from "../features/mylease/components/styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";
import AlertBlock from "../features/shared/components/AlertBlock";

const styles = combinestyles(myleasestyles, globalstyles);

interface PropsFromState {
  user?: UserInfoItem;
  data?: LeaseDetails;
  downloadError: boolean;
}

interface PropsFromDispatch {
  leaseFetchRequest: typeof leaseFetchRequest;
  downloadLeaseDocument: typeof downloadLeaseDocumentRequest;
}

interface IMyLeaseProps extends WithStyles<typeof styles> {
  theme?: any;
}

type AllProps = IMyLeaseProps & PropsFromState & PropsFromDispatch;

class MyLease extends React.Component<AllProps> {
  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.leaseFetchRequest({ nameId });
    window.scrollTo(0, 0);
  }

  downloadFile = () => {
    const fileName = "LeaseDocument.pdf";

    var ua = navigator.userAgent; // /*@cc_on!@*/false || !!document.documentMode;
    var isIE = ua.indexOf("MSIE") > -1 || ua.indexOf("Trident/") > -1;
    let newWindow = null;
    if (!isIE) {
      newWindow = window.open();
    }
    this.props.downloadLeaseDocument({ nameId: this.props.user!.nameId, fileName: fileName, newWindow: newWindow });
  };

  public render() {
    const { data } = this.props;

    let leaseInfo: LeaseDetails;
    let startDate = new Date();
    let endDate = new Date();
    let term = "";
    let monthlyFees: MonthlyFee[];
    let totalCharges = "0.00";
    let occupants: Occupant[];
    let pets: Pet[];
    let leaseDocumentExist = true;

    if (data !== undefined) {
      leaseInfo = data;
      startDate = leaseInfo.startDate;
      endDate = leaseInfo.endDate;
      term = leaseInfo.term;
      monthlyFees = leaseInfo.monthlyFees;
      totalCharges = thousands_separators(monthlyFees.reduce((a, b) => a + (b["amount"] || 0), 0).toFixed(2));
      occupants = leaseInfo.occupants;
      pets = leaseInfo.pets;
      leaseDocumentExist = leaseInfo.leaseDocumentExist;
    } else {
      monthlyFees = [];
      occupants = [];
      pets = [];
    }

    const downloadError = this.props.downloadError ? (
      <AlertBlock message="Failed to download the document. Please try again later." />
    ) : null;

    return (
      <div className={this.props.classes.root}>
        {downloadError}
        <main className={this.props.classes.contentRightRail}>
          <Typography variant="h1" className={this.props.classes.header}>
            My Lease
          </Typography>
          <Paper className={this.props.classes.paper}>
            <Grid container className={this.props.classes.grouping}>
              <Grid xs={6} item>
                <Typography variant="h3" className={this.props.classes.tablepadding} gutterBottom>
                  Rent and Recurring Charges
                </Typography>
              </Grid>
              <Grid xs={6} item>
                <Typography variant="h3" align="right" gutterBottom>
                  ${totalCharges}
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <Divider className={this.props.classes.tablepadding} />
              </Grid>

              {monthlyFees.map(fee => (
                <React.Fragment key={fee.chargeCode}>
                  <Grid xs={6} item>
                    <Typography className={this.props.classes.tablepadding} gutterBottom>
                      {fee.description}
                    </Typography>
                  </Grid>
                  <Grid xs={6} item>
                    <Typography align="right" gutterBottom>
                      ${thousands_separators(fee.amount.toFixed(2))}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}

              <Grid xs={12} item>
                <Divider className={this.props.classes.tablepadding} />
              </Grid>
            </Grid>

            <div className={this.props.classes.grouping}>
              <Typography variant="h3" className={this.props.classes.tablepadding} gutterBottom>
                Lease Details
              </Typography>
              <Typography variant="h5" className={this.props.classes.tablepadding} gutterBottom>
                Occupants
              </Typography>
              <Divider className={this.props.classes.tablepadding} />

              <Grid container>
                {occupants.map(occ => (
                  <React.Fragment>
                    <Grid item xs={occ.guarantor ? 12 : 6} className={this.props.classes.tablepadding}>
                      <Typography inline gutterBottom>
                        {occ.firstName} {occ.lastName}
                      </Typography>
                      {occ.guarantor && (
                        <Typography variant="body2" inline gutterBottom>
                          &nbsp;&nbsp;Guarantor
                        </Typography>
                      )}
                    </Grid>
                    {!occ.guarantor && <Grid item xs={6} />}
                  </React.Fragment>
                ))}
              </Grid>

              <Grid container className={this.props.classes.grouping}>
                <Grid item xs={4}>
                  <Typography variant="body2" gutterBottom>
                    Start Date
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" gutterBottom>
                    End Date
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" gutterBottom>
                    Lease Term
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={this.props.classes.tablepadding} />
                </Grid>
                <Grid item xs={4}>
                  <Typography gutterBottom>
                    <Moment format="MM/DD/YY" date={startDate} />
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography gutterBottom>
                    <Moment format="MM/DD/YY" date={endDate} />
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography gutterBottom>{term} Months</Typography>
                </Grid>
              </Grid>

              <Typography variant="h5" className={this.props.classes.tablepadding} gutterBottom>
                Pets
              </Typography>

              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="body2" gutterBottom>
                    Type
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" gutterBottom>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" gutterBottom>
                    Breed
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider className={this.props.classes.tablepadding} />
                </Grid>

                {pets.map(pet => (
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography gutterBottom>{pet.type}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography gutterBottom>{pet.name}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography gutterBottom>{pet.breed}</Typography>
                    </Grid>
                  </Grid>
                ))}
                {pets.length === 0 && (
                  <Grid item xs={12}>
                    <Typography gutterBottom>No pets</Typography>
                  </Grid>
                )}
              </Grid>
            </div>
            {this.props.user!.nameId && leaseDocumentExist ? (
              <div className={this.props.classes.grouping}>
                <Typography variant="h3" className={this.props.classes.tablepadding} gutterBottom>
                  Lease Documents
                </Typography>
                <Link>
                  <Grid container alignItems="center" direction="row">
                    <Grid item>
                      <Attachment style={{ fontSize: "2rem" }} />
                    </Grid>
                    <Grid item>&nbsp;</Grid>
                    <Grid item>
                      <Button onClick={this.downloadFile}>Download PDF</Button>
                    </Grid>
                  </Grid>
                </Link>
              </div>
            ) : null}
          </Paper>
        </main>
      </div>
    );
  }
}

function thousands_separators(num: string) {
  var num_parts = num.split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
}

const mapStateToProps = ({ lease, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  data: lease.data,
  downloadError: lease.downloadError
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  leaseFetchRequest: (payload: { nameId: any }) => dispatch(leaseFetchRequest(payload)),
  downloadLeaseDocument: (payload: {
    nameId: string;
    documentId: string;
    fileName: string;
    newWindow: Window | null;
  }) => dispatch(downloadLeaseDocumentRequest(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyLease));
