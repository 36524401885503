import { Button, Grid, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Form,  Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as Yup from "yup";

import contactstyles from "../../../features/myprofile/components/styles";
import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import RegexConstants from "../../shared/constants/regex";
import { UserInfoItem } from "../../userInfo/models";
import { emergencyFetchRequest, emergencyUpdateRequest } from "../actions";
import { ContactEmergency, RpActivity } from "../models";
import LabelledEditField from "./LabelledEditField";

const styles = combinestyles(contactstyles, globalstyles);

const ValidationSchema = Yup.object().shape({
  emergencyName: Yup.string() 
    .max(100, "46 character limit") 
    .required("Name is required"), 
  emergencyRelationship: Yup.string()
    .max(10, "10 character limit") 
    .required("Relationship is required"), 
  emergencyPhone: Yup.string()
    .length(10, "Please enter a 10-digit phone number, area code first")
    .matches(RegexConstants.PHONE, "Please enter a 10-digit phone number, area code first")
    .required("Cell Phone is required"),
  emergencyEmail: Yup.string()
    .max(100, "100 character limit")
    .email("Email is not valid")
});
interface Props extends WithStyles<typeof styles> {
  tileLocked: boolean;
  lockAllTiles(): void;
  unlockAllTiles(): void;
}
interface PropsFromState {
  user?: UserInfoItem;
  emergencyContact?: ContactEmergency;
}
interface PropsFromDispatch {
  logActivity: typeof logActivity;
  emergencyFetchRequest: typeof emergencyFetchRequest;
  emergencyUpdateRequest: typeof emergencyUpdateRequest;
}
type EmergencyTileState = {
  editingTile: boolean;
};
type AllProps = PropsFromState & PropsFromDispatch & Props;

class ProfileEmergencyContactTile extends Component<AllProps> {
  state: Readonly<EmergencyTileState> = {
    editingTile: false
  };

  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.emergencyFetchRequest({ nameId });
  }

  handleEditTile = () => {
    this.props.lockAllTiles();
    this.setState({ editingTile: true });
  };

  handleSaveTile = (values: any) => {
    if (this.props.emergencyContact) {
      if (
        values.emergencyName !== this.props.emergencyContact.name ||
        values.emergencyRelationship !== this.props.emergencyContact.relationship ||
        values.emergencyPhone !== this.props.emergencyContact.cellPhone ||
        values.emergencyEmail !== this.props.emergencyContact.email
      ) {
        // Log Activity
        this.props.logActivity({
          actcode: "EC",
          note: "Resident updated emergency contact"
        });
        // Make API Call To PUT data
        const payload: ContactEmergency = {
          nameId: this.props.user!.nameId,
          name: values.emergencyName,
          relationship: values.emergencyRelationship,
          cellPhone: values.emergencyPhone,
          email: values.emergencyEmail
        };
        this.props.emergencyUpdateRequest(payload);
      }
    }
    this.props.unlockAllTiles();
    this.setState({ editingTile: false });
  };

  render() {
    const { emergencyContact, tileLocked, classes } = this.props;
    const { editingTile } = this.state;
    const lockedCssClass = editingTile ? "" : tileLocked ? classes.lockedTile : "";
    if (emergencyContact) {
      return (
        <Paper className={[classes.paper, lockedCssClass].join(" ")}>
          <Formik
            initialValues={{
              emergencyName: emergencyContact.name,
              emergencyRelationship: emergencyContact.relationship,
              emergencyPhone: emergencyContact.cellPhone,
              emergencyEmail: emergencyContact.email
            }}
            validationSchema={ValidationSchema}
            onSubmit={values => {
              this.handleSaveTile(values);
            }}
          >
            <Form>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                style={{ marginBottom: "1rem" }}
              >
                <Grid item>
                  <Typography variant="h3">Emergency Contact</Typography>
                </Grid>
                <Grid item>
                  {!editingTile && (
                    <Button onClick={this.handleEditTile} color="primary">
                      <EditIcon />
                      Edit
                    </Button>
                  )}
                  {editingTile && (
                    <Button type="submit" color="primary">
                      <EditIcon />
                      Save
                    </Button>
                  )}
                </Grid>
              </Grid>
              <LabelledEditField
                id="emergencyName"
                label="Name*"
                value={emergencyContact.name}
                editing={editingTile}
                maxLength="46"
              />
              <LabelledEditField
                id="emergencyRelationship"
                label="Relationship*"
                value={emergencyContact.relationship}
                editing={editingTile}
                maxLength="10"
              />
              <LabelledEditField
                id="emergencyPhone"
                label="Cell Phone*"
                value={emergencyContact.cellPhone}
                editing={editingTile}
                maxLength="10"
              />
              <LabelledEditField
                id="emergencyEmail"
                label="Email"
                value={emergencyContact.email}
                editing={editingTile}
                maxLength="100"
              />
            </Form>
          </Formik>
        </Paper>
      );
    } else {
      return <React.Fragment />;
    }
  }
}

const mapStateToProps = ({ profile, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  emergencyContact: profile.emergencyInfo
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  logActivity: (activity: RpActivity) => dispatch(logActivity(activity)), 
  emergencyFetchRequest: (payload: { nameId: any }) => dispatch(emergencyFetchRequest(payload)), 
  emergencyUpdateRequest: (payload: ContactEmergency) => dispatch(emergencyUpdateRequest(payload)) 
});

export default connect(
  mapStateToProps, 
  mapDispatchToProps 
)(withStyles(styles)(ProfileEmergencyContactTile));
