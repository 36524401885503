import React, { Component } from "react";
import { Button, WithStyles, withStyles, Typography, Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Attachment from "@material-ui/icons/Attachment";
import contactstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(contactstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  handleSaveFile: (file: File | null) => void;
  handleFileInputError: (hasError: boolean) => void;
}

const FILE_NAME_REGEX = /^[a-zA-Z0-9_-]{1,50}\.[a-zA-Z0-9]{1,10}$/;

class ResidentRelationsFileUpload extends Component<Props> {
  state = {
    error: "",
    currentFileValue: "",
    currentFileName: ""
  };

  validateFile = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      var pattern = new RegExp(FILE_NAME_REGEX);

      if (!pattern.test(file.name)) {
        return this.handleFileError(
          "File name cannot include special characters or exceed 50 characters. Please correct and re-submit.",
          file
        );
      }

      if (file.size > 1000000) {
        return this.handleFileError("File must be less than 1 MB.", file);
      }

      if (
        !(
          file.type === "application/pdf" ||
          file.type === "image/png" ||
          file.type === "image/jpg" ||
          file.type === "image/jpeg" ||
          file.type === "image/gif" ||
          file.type === "image/bmp" ||
          file.type === "image/tiff" ||
          file.type === "image/heic"
        )
      ) {
        return this.handleFileError(
          "Only PDF, JPG, GIF, BMP, PNG, TIFF and HEIC files are allowed.  Please choose a different file.",
          file
        );
      }

      // Valid File Selected
      this.setState({ error: "", currentFileValue: file.value, currentFileName: file.name });
      this.props.handleFileInputError(false);
      this.props.handleSaveFile(event.target.files[0]);
    } else {
      // Hit Cancel
      this.setState({ error: "", currentFileValue: "", currentFileName: "" });
      this.props.handleFileInputError(false);
      this.props.handleSaveFile(null);
      return;
    }
  };

  handleFileError = (errorMessage: string, file: any) => {
    this.setState({
      error: errorMessage,
      currentFileValue: file.value,
      currentFileName: file.Name
    });
    this.props.handleFileInputError(true);
  };

  removeFile = () => {
    this.setState({ error: "", currentFileValue: "", currentFileName: "" });
    this.props.handleFileInputError(false);
    this.props.handleSaveFile(null);
  };

  render() {
    const errorStyle = {
      color: "red"
    };

    return (
      <React.Fragment>
        <label style={{ display: "inline-block", padding: "6px 12px", cursor: "pointer" }}>
          <input
            type="file"
            onChange={this.validateFile}
            value={this.state.currentFileValue}
            style={{ display: "none" }}
            accept="image/bmp, image/gif, image/jpeg, image/png, image/tiff, application/pdf, image/heic"
          />
          {this.state.currentFileValue === "" && (
            <Grid container>
              <Grid item style={{ paddingRight: ".5rem" }}>
                <Attachment />
              </Grid>
              <Grid item>
                <Typography variant="body1" className={this.props.classes.link}>
                  Attach File
                </Typography>
              </Grid>
            </Grid>
          )}
        </label>
        {this.state.currentFileValue !== "" && (
          <Grid container>
            <Grid item>
              <Typography>{this.state.currentFileName}</Typography>
            </Grid>
            <Grid item>
              <Button onClick={this.removeFile}>
                <DeleteIcon />
              </Button>
            </Grid>
          </Grid>
        )}
        <Typography style={errorStyle}>{this.state.error}</Typography>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ResidentRelationsFileUpload);
