import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";
import { MoveInChecklistModel } from "./models";
import * as newresident from "./actions";

export type MoveInChecklistAction = ActionType<typeof newresident>;

export type NewResidentState = Readonly<{
  loading: boolean;
  checkList?: MoveInChecklistModel;
  errors?: string;
}>;

const initialState: NewResidentState = {
  checkList: undefined,
  errors: undefined,
  loading: false
};

const reducer: Reducer<NewResidentState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(newresident.fetchRequestAsync.request): {
      return { ...state, loading: true };
    }
    case getType(newresident.fetchRequestAsync.success): {
      return { ...state, loading: false, checkList: action.payload };
    }
    case getType(newresident.fetchRequestAsync.failure): {
      return { ...state, loading: false, errors: action.payload };
    }
    case getType(newresident.markCompleteRequest): {
      return { ...state, loading: true };
    }
    case getType(newresident.markCompleteSuccess): {
      return { ...state, loading: false };
    }
    case getType(newresident.markCompleteError): {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer; // must be registered in ../../store/root-reducer.ts
