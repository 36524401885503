import * as React from "react";
import { withStyles, WithStyles } from "@material-ui/core";
import { categoriesFetchRequest, listFetchRequest, selectAmenityToReserve } from "../features/amenity/actions";

import AmenityDashboard from "../features/amenity/components/AmenityDashboard";
import AmenityNewForm from "../features/amenity/components/AmenityNewForm";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps, Route, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";
import { RootState } from "../store/root-reducer";
import { ReservableAmenity } from "../features/amenity/models";
import { AmenityReservation } from "../features/amenity/models";

import amenitymstyles from "../styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";
import { loadAmenityByDeepLink } from "../utils/api";

const styles = combinestyles(amenitymstyles, globalstyles);

interface IAmenityReservationProps extends WithStyles<typeof styles> {
  theme?: any;
  children?: any;
}

interface IPageState {}

interface MatchParams {
  a: string;
  p: string;
}

export interface Match<P> {
  params: P;
  isExact: boolean;
  path: string;
  url: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

interface PropsFromState {
  user?: UserInfoItem;
  loading: boolean;
  amenityReservationList: ReservableAmenity[];
  myAmenityList: AmenityReservation[];
  errors?: string;
}

interface PropsFromDispatch {
  categoriesFetchRequest: typeof categoriesFetchRequest;
  listFetchRequest: typeof listFetchRequest;
  selectAmenity: typeof selectAmenityToReserve;
}

type AllProps = Props & IAmenityReservationProps & PropsFromState & PropsFromDispatch & RouteComponentProps;

class AmenityPage extends React.Component<AllProps, IPageState> {
  state: IPageState = {};

  async componentDidMount() {
    const user = this.props.user;
    const propId = user!.propertyId;
    const urlString = this.props.location.pathname;
    const urlArray = urlString.split("/");
    const a = urlArray[3];

    if (a && propId) {
      let resp = await loadAmenityByDeepLink(propId, a.toString());
      if (resp) {
        this.props.selectAmenity(resp);
        await this.props.history.push("/myaccount/amenity/AmenityNewForm");
      } else {
        this.props.history.push("/");
      }
    } else {
      const rmpropid = this.props.user!.propertyId;
      const nameId = this.props.user!.nameId;
      this.props.categoriesFetchRequest(rmpropid);
      this.props.listFetchRequest(nameId);
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <Switch>
        <Route path="/myaccount/amenity/AmenityNewForm" component={AmenityNewForm} />
        <Route component={AmenityDashboard} />
      </Switch>
    );
  }
}

const mapStateToProps = ({ amenity, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  amenityReservationList: amenity.reservableAmenityList.data,
  loading: amenity.reservableAmenityList.loading,
  myAmenityList: amenity.myAmenityReservation.data,
  errors: amenity.myAmenityReservation.errors,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  categoriesFetchRequest: (rmpropid: string) => dispatch(categoriesFetchRequest(rmpropid)),
  listFetchRequest: (nameid: string) => dispatch(listFetchRequest(nameid)),
  selectAmenity: (amenity: ReservableAmenity) => dispatch(selectAmenityToReserve(amenity)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AmenityPage)));
