import {
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { RpActivity } from "../../shared/models";
import { UserInfoItem } from "../../userInfo/models";
import { NoticeToVacate } from "../models";
import style from "../styles";

const styles = combinestyles(style, globalstyles);

interface Props extends WithStyles<typeof styles> {
  tileLocked: boolean;
  noticeInfo?: NoticeToVacate;
  handleSodaOptOut: (value: boolean) => void;
  handleERefundFlag: (value: boolean) => void;
  handleForwardAddressChange: (textName: string, value: string) => void;
  handleERefundEmail: (value: string) => void;
  handleERefundClearFlag: (value: boolean) => void;
}
interface PropsFromState {
  user?: UserInfoItem;
}
interface PropsFromDispatch {
  logActivity: typeof logActivity;
  //   contactFetchRequest: typeof contactFetchRequest;
  //   contactUpdateRequest: typeof contactUpdateRequest;
}

type ElectronicRefundState = {
  electronicRefund: boolean;
  eRefundEmail: string;
  sodaOptOut: boolean;
  address1: string;
  address2: string;
  city: string;
  stateFA: string;
  zip: string;
  addressRequired: boolean;
  errors: {
    emailError: string;
    address1Error: string;
    cityError: string;
    stateError: string;
    zipError: string;
  };
};
type AllProps = PropsFromState & PropsFromDispatch & Props;

class ElectronicRefundTile extends Component<AllProps> {
  state: Readonly<ElectronicRefundState> = {
    electronicRefund: false,
    eRefundEmail: "",
    sodaOptOut: false,
    address1: "",
    address2: "",
    city: "",
    stateFA: "",
    zip: "",
    addressRequired: false,
    errors: {
      emailError: "",
      address1Error: "",
      cityError: "",
      stateError: "",
      zipError: "",
    },
  };

  componentDidMount() {
    this.props.handleERefundClearFlag(true);
    // console.log("electronicRefund");
    // console.log(this.props.noticeInfo!.forwardAddres);
    if (this.props.noticeInfo!.forwardAddres !== null) {
      if (
        this.props.noticeInfo!.forwardAddres.address1.length > 0 ||
        this.props.noticeInfo!.forwardAddres.city.length > 0 ||
        this.props.noticeInfo!.forwardAddres.state.length > 0 ||
        this.props.noticeInfo!.forwardAddres.zip.length > 0
      ) {
        this.setState({ addressRequired: true });
      }

      if (this.props.noticeInfo!.forwardAddres.address1.length > 0) {
        this.props.handleForwardAddressChange("address1", this.props.noticeInfo!.forwardAddres.address1);
        this.setState({ address1: this.props.noticeInfo!.forwardAddres.address1 }, () => {
          this.processAddressValidation();
        });
      }
      if (this.props.noticeInfo!.forwardAddres.address2.length > 0) {
        this.props.handleForwardAddressChange("address2", this.props.noticeInfo!.forwardAddres.address2);
        this.setState({ address2: this.props.noticeInfo!.forwardAddres.address2 });
      }
      if (this.props.noticeInfo!.forwardAddres.city.length > 0) {
        this.props.handleForwardAddressChange("city", this.props.noticeInfo!.forwardAddres.city);
        this.setState({ city: this.props.noticeInfo!.forwardAddres.city }, () => {
          this.processAddressValidation();
        });
      }
      if (this.props.noticeInfo!.forwardAddres.state.length > 0) {
        this.props.handleForwardAddressChange("state", this.props.noticeInfo!.forwardAddres.state);
        this.setState({ stateFA: this.props.noticeInfo!.forwardAddres.state }, () => {
          this.processAddressValidation();
        });
      }
      if (this.props.noticeInfo!.forwardAddres.zip.length > 0) {
        this.props.handleForwardAddressChange("zip", this.props.noticeInfo!.forwardAddres.zip);
        this.setState({ zip: this.props.noticeInfo!.forwardAddres.zip }, () => {
          this.processAddressValidation();
        });
      }
    }
    this.props.handleSodaOptOut(false);
    this.props.handleERefundFlag(false);
  }

  handleSODAChange = (e: any) => {
    this.setState({
      sodaOptOut: e.target.checked
    });
    this.props.handleSodaOptOut(e.target.checked);
  };

  handleERefundChange = (e: any) => {
    this.setState({
      electronicRefund: e.target.checked
    });
    this.props.handleERefundFlag(e.target.checked);
  };

  changeText = (e: any) => {
    if (!e.target) {
      return;
    }
    this.setState({ addressRequired: true });
    switch (e.target.name) {
      case "address1":
        this.setState({ address1: e.target.value });
        break;
      case "address2":
        this.setState({ address2: e.target.value });
        break;
      case "city":
        this.setState({ city: e.target.value });
        break;
      case "state":
        this.setState({ stateFA: e.target.value });
        break;
      case "zip":
        this.setState({ zip: e.target.value });
        break;
    }
  };

  handleValidation = (e: any) => {
    let errorList = this.state.errors;

    const validEmailRegex = RegExp(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
      //  updated from (unnecessary escapes): /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    const validEmail = validEmailRegex.test(e.target.value);

    errorList.emailError = validEmail ? "" : "Email is not valid";
    this.setState({ emailError: validEmail ? "" : "Email is not valid" });

    this.checkErrors();

    if (errorList.emailError === "") {
      this.props.handleERefundEmail(e.target.value);
    }
  };

  checkErrors = () => {
    let errors = this.state.errors;
    if (
      this.state.address1.length === 0 &&
      this.state.city.length === 0 &&
      this.state.stateFA.length === 0 &&
      this.state.zip.length === 0 &&
      this.state.address2.length === 0
    ) {
      this.setState({ addressRequired: false }, () => {
        errors.address1Error = "";
        this.setState({ address1Error: "" });
        errors.cityError = "";
        this.setState({ cityError: "" });
        errors.stateError = "";
        this.setState({ stateError: "" });
        errors.zipError = "";
        this.setState({ zipError: "" });
      });
      this.props.handleERefundClearFlag(true);
      this.props.handleForwardAddressChange("address1", this.state.address1);
      this.props.handleForwardAddressChange("address2", this.state.address2);
      this.props.handleForwardAddressChange("city", this.state.city);
      this.props.handleForwardAddressChange("state", this.state.stateFA);
      this.props.handleForwardAddressChange("zip", this.state.zip);
    } else {
      if (
        errors.address1Error === "" &&
        errors.cityError === "" &&
        errors.stateError === "" &&
        errors.zipError === "" &&
        errors.emailError === "" &&
        this.state.address1 !== "" &&
        this.state.city !== "" &&
        this.state.stateFA !== "" &&
        this.state.zip !== ""
      ) {
        this.props.handleERefundClearFlag(true);
        this.props.handleForwardAddressChange("address1", this.state.address1);
        this.props.handleForwardAddressChange("address2", this.state.address2);
        this.props.handleForwardAddressChange("city", this.state.city);
        this.props.handleForwardAddressChange("state", this.state.stateFA);
        this.props.handleForwardAddressChange("zip", this.state.zip);
      } else {
        this.props.handleERefundClearFlag(false);
      }
    }
  };

  processAddressValidation = () => {
    let errors = this.state.errors;

    if (this.state.address1 === "") {
      errors.address1Error = "Address 1 is required";
      this.setState({ address1Error: "Address 1 is required" });
    } else {
      errors.address1Error = "";
      this.setState({ address1Error: "" });
    }
    if (this.state.city === "") {
      errors.cityError = "City is required";
      this.setState({ cityError: "City is required" });
    } else {
      errors.cityError = "";
      this.setState({ cityError: "" });
    }

    if (this.state.stateFA === "") {
      errors.stateError = "State is required";
      this.setState({ stateError: "State is required" });
    } else {
      errors.stateError = "";
      this.setState({ stateError: "" });
    }

    if (this.state.zip === "") {
      errors.zipError = "Zip is required";
      this.setState({ zipError: "Zip is required" });
    } else {
      if (this.state.zip.length !== 5) {
        errors.stateError = "Zip must be 5 digits";
        this.setState({ zipError: "Zip must be 5 digits" });
      } else {
        errors.zipError = "";
        this.setState({ zipError: "" });
      }
    }
    this.checkErrors();
  };

  handleAddressValidation = (e: any) => {
    this.processAddressValidation();
  };
  render() {
    const { classes } = this.props;
    const sodaOptOutText = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A14";
    });

    const eRefundText = this.props.noticeInfo!.copyLanguage.find((e) => {
      return e.languageId === "A15";
    });

    //if (contactInfo) {
    return (
      <Paper className={classes.paper}>
        <form>
          <Grid container className={this.props.classes.grouping}>
            <Grid xs={12} item>
              {this.props.noticeInfo!.isEasyMove.toString() === "Y" && (
                <Typography variant="caption" className={this.props.classes.tablepadding} gutterBottom>
                  STEP 4 OF 5
                </Typography>
              )}
              {this.props.noticeInfo!.isEasyMove.toString() === "N" && (
                <Typography variant="caption" className={this.props.classes.tablepadding} gutterBottom>
                  STEP 4 OF 4
                </Typography>
              )}
            </Grid>
            <Grid xs={12} item>
              <Divider className={this.props.classes.tablepadding} />
            </Grid>
          </Grid>

          <Grid container direction="row" alignItems="center" justify="space-between" style={{ marginBottom: "1rem" }}>
            {String(this.props.noticeInfo!.isCalifornia) === "Y" && (
              <Grid item xs={12}>
                <Typography variant="h3" style={{ marginTop: "1rem" }} gutterBottom>
                  Statement of Deposit Account
                </Typography>
                <Grid item xs={12} style={{ display: "inline-block" }}>
                  <Grid item xs={12}>
                    <Switch id="toggleSODAOptOut" color="primary" onChange={(e) => this.handleSODAChange(e)} />
                    <Typography style={{ display: "inline-block" }}>{sodaOptOutText!.copyLanguage}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <Divider className={this.props.classes.tablepadding} />
            </Grid>
            {String(this.props.noticeInfo!.isERefund) === "Y" &&
              String(this.props.noticeInfo!.isSingleLeaseHolder) === "Y" && (
                <Grid item xs={12}>
                  <Typography variant="h3" style={{ marginTop: "1rem" }} gutterBottom>
                    Electronic Refund
                  </Typography>
                  <Grid item xs={12} style={{ display: "inline-block" }}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch id="toggleERefund" color="primary" onChange={(e) => this.handleERefundChange(e)} />
                        }
                        label={eRefundText!.copyLanguage}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="ERefundEmail"
                        name="ERefundEmail"
                        disabled={!this.state.electronicRefund}
                        className={classes.textField}
                        onBlur={e => this.handleValidation(e)}
                        error={this.state.errors.emailError !== ""}
                        helperText={this.state.errors.emailError !== "" ? this.state.errors.emailError : " "}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            <Grid item xs={12}>
              <Typography variant="h3" style={{ marginTop: "1rem" }} gutterBottom>
                Forwarding Address
              </Typography>
            </Grid>
            <Grid item container sm={12} spacing={8}>
              <Grid item sm={6}>
                <Typography style={{ fontSize: "0.875rem" }}>Address 1</Typography>
                <TextField
                  id="address1"
                  name="address1"
                  inputProps={{ maxLength: 35 }}
                  defaultValue={
                    this.props.noticeInfo!.forwardAddres ? this.props.noticeInfo!.forwardAddres.address1 : ""
                  }
                  onChange={e => this.changeText(e)}
                  onBlur={e => this.handleAddressValidation(e)}
                  error={this.state.errors.address1Error !== ""}
                  helperText={this.state.errors.address1Error !== "" ? this.state.errors.address1Error : " "}
                  margin="normal"
                  style={{ marginRight: "1rem" }}
                  fullWidth
                />
              </Grid>
              <Grid item sm={6}>
                <Typography style={{ fontSize: "0.875rem" }}>Address 2</Typography>
                <TextField
                  id="address2"
                  name="address2"
                  inputProps={{ maxLength: 30 }}
                  defaultValue={
                    this.props.noticeInfo!.forwardAddres
                      ? this.props.noticeInfo!.forwardAddres.address2
                        ? this.props.noticeInfo!.forwardAddres.address2
                        : ""
                      : ""
                  }
                  onChange={e => this.changeText(e)}
                  onBlur={e => this.handleAddressValidation(e)}
                  margin="normal"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item container sm={12} spacing={16}>
              <Grid item sm={4}>
                <Typography style={{ fontSize: "0.875rem" }}>City</Typography>
                <TextField
                  id="city"
                  name="city"
                  inputProps={{ maxLength: 18 }}
                  defaultValue={this.props.noticeInfo!.forwardAddres ? this.props.noticeInfo!.forwardAddres.city : ""}
                  onChange={e => this.changeText(e)}
                  onBlur={e => this.handleAddressValidation(e)}
                  error={this.state.errors.cityError !== ""}
                  helperText={this.state.errors.cityError !== "" ? this.state.errors.cityError : " "}
                  fullWidth
                />
              </Grid>
              <Grid item sm={4}>
                <Typography style={{ fontSize: "0.875rem" }}>State</Typography>
                <Select
                  id="state"
                  name="state"
                  value={this.state.stateFA}
                  onChange={e => this.changeText(e)}
                  onBlur={e => this.handleAddressValidation(e)}
                  fullWidth
                >
                  <MenuItem value="" />
                  <MenuItem value="AL">AL</MenuItem>
                  <MenuItem value="AK">AK</MenuItem>
                  <MenuItem value="AZ">AZ</MenuItem>
                  <MenuItem value="AR">AR</MenuItem>
                  <MenuItem value="CA">CA</MenuItem>
                  <MenuItem value="CO">CO</MenuItem>
                  <MenuItem value="CT">CT</MenuItem>
                  <MenuItem value="DC">DC</MenuItem>
                  <MenuItem value="DE">DE</MenuItem>
                  <MenuItem value="FL">FL</MenuItem>
                  <MenuItem value="GA">GA</MenuItem>
                  <MenuItem value="HI">HI</MenuItem>
                  <MenuItem value="ID">ID</MenuItem>
                  <MenuItem value="IL">IL</MenuItem>
                  <MenuItem value="IN">IN</MenuItem>
                  <MenuItem value="IA">IA</MenuItem>
                  <MenuItem value="KS">KS</MenuItem>
                  <MenuItem value="KY">KY</MenuItem>
                  <MenuItem value="LA">LA</MenuItem>
                  <MenuItem value="ME">ME</MenuItem>
                  <MenuItem value="MD">MD</MenuItem>
                  <MenuItem value="MA">MA</MenuItem>
                  <MenuItem value="MI">MI</MenuItem>
                  <MenuItem value="MN">MN</MenuItem>
                  <MenuItem value="MD">MS</MenuItem>
                  <MenuItem value="MO">MO</MenuItem>
                  <MenuItem value="MT">MT</MenuItem>
                  <MenuItem value="NE">NE</MenuItem>
                  <MenuItem value="NV">NV</MenuItem>
                  <MenuItem value="NH">NH</MenuItem>
                  <MenuItem value="NJ">NJ</MenuItem>
                  <MenuItem value="NM">NM</MenuItem>
                  <MenuItem value="NY">NY</MenuItem>
                  <MenuItem value="NC">NC</MenuItem>
                  <MenuItem value="CD">ND</MenuItem>
                  <MenuItem value="OH">OH</MenuItem>
                  <MenuItem value="OK">OK</MenuItem>
                  <MenuItem value="OR">OR</MenuItem>
                  <MenuItem value="PA">PA</MenuItem>
                  <MenuItem value="RI">RI</MenuItem>
                  <MenuItem value="SC">SC</MenuItem>
                  <MenuItem value="SD">SD</MenuItem>
                  <MenuItem value="TN">TN</MenuItem>
                  <MenuItem value="TX">TX</MenuItem>
                  <MenuItem value="UT">UT</MenuItem>
                  <MenuItem value="VT">VT</MenuItem>
                  <MenuItem value="VA">VA</MenuItem>
                  <MenuItem value="WA">WA</MenuItem>
                  <MenuItem value="WV">WV</MenuItem>
                  <MenuItem value="WI">WI</MenuItem>
                  <MenuItem value="WY">WY</MenuItem>
                </Select>
                <FormHelperText error>{this.state.errors.stateError}</FormHelperText>
              </Grid>
              <Grid item sm={4}>
                <Typography style={{ fontSize: "0.875rem" }}>Zip</Typography>
                <TextField
                  id="zip"
                  name="zip"
                  inputProps={{ maxLength: 10 }}
                  defaultValue={this.props.noticeInfo!.forwardAddres ? this.props.noticeInfo!.forwardAddres.zip : ""}
                  onChange={e => this.changeText(e)}
                  onBlur={e => this.handleAddressValidation(e)}
                  error={this.state.errors.zipError !== ""}
                  helperText={this.state.errors.zipError !== "" ? this.state.errors.zipError : " "}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  logActivity: (activity: RpActivity) => dispatch(logActivity(activity))
  //contactFetchRequest: (payload: { nameId: any }) => dispatch(contactFetchRequest(payload)),
  //contactUpdateRequest: (payload: ContactInfo) => dispatch(contactUpdateRequest(payload))
});

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(withStyles(styles)(ElectronicRefundTile));
