import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Typography, Button, Grid, withStyles, WithStyles, Hidden } from "@material-ui/core";
import styles from "../../../global-styles";

interface Props extends WithStyles<typeof styles> {
  onDrop(droppedFile: File): void;
  name: string;
}

function DragAndDropImage(props: Props) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        props.onDrop(acceptedFiles[0]);
      }
    },
    [props]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    multiple: false,
    accept: "image/*",
  });

  const avatar = acceptedFiles.map((file) =>
    file.type.includes("image") ? (
      <img
        alt="Preview"
        className={props.classes.dropZoneImagePreview}
        key={file.size}
        src={URL.createObjectURL(file)}
      />
    ) : (
      <React.Fragment />
    )
  );

  return (
    <Grid container alignItems="center" justify="center" {...getRootProps()} className={props.classes.dropZone}>
      <Grid item>
        <input {...getInputProps()} type="file" />
        {acceptedFiles.length === 0 && (
          <div>
            <Hidden smDown>
              <Typography>Drag and drop file</Typography>
              <Typography gutterBottom>or</Typography>
            </Hidden>
            <Button variant="outlined">Choose Photo</Button>
          </div>
        )}
        {avatar}
      </Grid>
    </Grid>
  );
}
export default withStyles(styles)(DragAndDropImage);
