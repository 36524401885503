import "react-datepicker/dist/react-datepicker.css";

import { Button, Divider, Grid, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import { PlayCircleOutline, ReportProblem, SettingsBackupRestore, Today, Update } from "@material-ui/icons";
import EventIcon from "@material-ui/icons/Event";
import moment from "moment";
import * as React from "react";
import DatePicker from "react-datepicker";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { leaseFetchRequest } from "../../mylease/actions";
import { LeaseDetails } from "../../mylease/models";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { RpActivity } from "../../shared/models";
import { UserInfoItem } from "../../userInfo/models";
import entvstyles from "../styles";

const styles = combinestyles(entvstyles, globalstyles);

export interface ENTVMoveOutDateProps extends WithStyles<typeof styles> {
  onContinue: any;
  user?: UserInfoItem;
}

interface PropsFromState {
  userData: UserInfoItem;
  data?: LeaseDetails;
}

interface PropsFromDispatch {
  leaseFetchRequest: typeof leaseFetchRequest;
  logActivity: typeof logActivity;
}

type State = {
  moveOutDate?: Date;
  pastDateError: boolean;
  earlyTerminationError: boolean;
  insufficientNoticeError: boolean;
};

type AllProps = PropsFromState & ENTVMoveOutDateProps & PropsFromDispatch;

class ENTVMoveOutDate extends React.Component<AllProps, State> {
  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.leaseFetchRequest({ nameId: nameId });
    window.scrollTo(0, 0);
  }

  state: Readonly<State> = {
    moveOutDate: undefined,
    pastDateError: false,
    earlyTerminationError: false,
    insufficientNoticeError: false,
  };

  differenceInDays = (a: any, b: any) => Math.floor((a.getTime() - b.getTime()) / (1000 * 60 * 60 * 24));

  handleDateChange = (date: any) => {
    if (this.props.data!.term !== "Month to month") {
      if (
        moment(date).isSameOrBefore(this.props.data!.endDate) &&
        this.differenceInDays(new Date(this.props.data!.endDate), new Date()) <= this.props.data!.reqNotify
      ) {
        this.setState({ insufficientNoticeError: true });
      } else if (
        moment(this.state.moveOutDate).isSameOrAfter(this.props.data!.endDate) &&
        this.differenceInDays(date, new Date()) <= this.props.data!.reqNotify
      ) {
        this.setState({ insufficientNoticeError: true });
      } else {
        this.setState({ insufficientNoticeError: false });
      }
    } else if (this.differenceInDays(date, new Date()) + 1 < this.props.data!.reqNotify) {
      this.setState({ insufficientNoticeError: true });
    } else {
      this.setState({ insufficientNoticeError: false });
    }

    if (moment(date).isBefore(moment(this.props.data!.endDate))) {
      this.setState({ moveOutDate: date, earlyTerminationError: true });
    } else {
      this.setState({ moveOutDate: date, earlyTerminationError: false });
    }
  };

  handleDateChangeRaw = (e: any) => {
    //prevent keyboard entry.
    e.preventDefault();
  };

  render() {
    const { data } = this.props;
    let leaseInfo: LeaseDetails;
    let startDate = new Date();
    let endDate = new Date();
    let reqNotify = 0;
    let easyMove = false;
    let entvDisclaimer = "";
    let term = "";

    if (data !== undefined) {
      leaseInfo = data;
      startDate = leaseInfo.startDate;
      endDate = leaseInfo.endDate;
      reqNotify = leaseInfo.reqNotify;
      easyMove = leaseInfo.easyMove;
      entvDisclaimer = leaseInfo.entvDisclaimer;
      term = leaseInfo.term;
    }
    const reqDate = moment(endDate).subtract(reqNotify, "days").toDate();

    return (
      <Paper className={this.props.classes.paper}>
        <Grid container alignItems="center" className={this.props.classes.grouping}>
          {this.state.insufficientNoticeError && (
            <Grid container className={this.props.classes.grouping}>
              <Grid xs={1} item>
                <ReportProblem className={this.props.classes.icon} color="error" style={{ verticalAlign: "middle" }} />
              </Grid>
              <Grid xs={11} item>
                <Typography
                  variant="body2"
                  style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold" }}
                  gutterBottom
                >
                  Notice to Vacate Past Due
                </Typography>
                <Typography style={{ display: "inline-block", fontSize: ".875rem", marginBottom: "1rem" }}>
                  Looks like you are providing your intent to vacate at a later date than required. You may be subject
                  to incurred fees related to the insufficient notice.
                </Typography>
              </Grid>
            </Grid>
          )}
          {this.state.earlyTerminationError && (
            <Grid container className={this.props.classes.grouping}>
              <Grid xs={1} item>
                <ReportProblem className={this.props.classes.icon} color="error" />
              </Grid>
              <Grid xs={11} item>
                <Typography
                  variant="body2"
                  style={{ display: "inline-block", fontSize: ".875rem", fontWeight: "bold" }}
                  gutterBottom
                >
                  Early Termination Notice
                </Typography>
                <Typography style={{ display: "inline-block", fontSize: ".875rem", marginBottom: "1rem" }}>
                  Looks like you are intending to provide notice to vacate prior to your lease-end date.
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid xs={12} item>
            {easyMove && (
              <Typography variant="caption" className={this.props.classes.tablepadding} gutterBottom>
                STEP 1 OF 5
              </Typography>
            )}
            {!easyMove && (
              <Typography variant="caption" className={this.props.classes.tablepadding} gutterBottom>
                STEP 1 OF 4
              </Typography>
            )}
          </Grid>
          <Grid xs={12} item style={{ marginTop: "1rem" }}>
            <Divider className={this.props.classes.tablepadding} />
          </Grid>

          <Grid container className={this.props.classes.grouping}>
            <Grid item sm={7}>
              <Grid item style={{ marginTop: "1rem", display: "inline-block" }}>
                {" "}
                <PlayCircleOutline color="disabled" style={{ verticalAlign: "middle" }} />
                <Typography
                  variant="body1"
                  style={{ paddingTop: ".5rem", paddingBottom: ".5rem", paddingLeft: ".5rem", display: "inline-block" }}
                >
                  Lease Start <Moment format="MM/DD/YY" date={startDate} />
                </Typography>
              </Grid>
              <Grid item style={{ marginTop: "1rem" }}>
                {" "}
                <Today color="disabled" style={{ verticalAlign: "middle" }} />
                <Typography
                  variant="body1"
                  style={{ paddingTop: ".5rem", paddingBottom: ".5rem", paddingLeft: ".5rem", display: "inline-block" }}
                >
                  Today is <Moment format="MM/DD/YY" date={new Date()} />
                </Typography>
              </Grid>
              <Grid item style={{ marginTop: "1rem" }}>
                {" "}
                <Update color="disabled" style={{ verticalAlign: "middle" }} />
                {term === "Month to month" && (
                  <Typography
                    variant="body1"
                    style={{ paddingTop: ".5rem", paddingLeft: ".5rem", display: "inline-block" }}
                  >
                    {reqNotify} Days Notice Required
                  </Typography>
                )}
                {term !== "Month to month" && (
                  <Typography
                    variant="body1"
                    style={{ paddingTop: ".5rem", paddingLeft: ".5rem", display: "inline-block" }}
                  >
                    Notice required by <Moment format="MM/DD/YY" date={reqDate} />
                  </Typography>
                )}
                <br />
                {this.state.insufficientNoticeError && (
                  <Typography style={{ display: "inline-block", fontSize: ".6rem", color: "red", paddingLeft: "2rem" }}>
                    <ReportProblem color="error" style={{ fontSize: 15, verticalAlign: "middle" }} /> PAST DUE
                  </Typography>
                )}
              </Grid>

              {term === "Month to month" && <Grid item xs={7} style={{ marginTop: "1rem" }} />}
              {term !== "Month to month" && (
                <Grid item style={{ marginTop: "1rem" }}>
                  <SettingsBackupRestore color="disabled" style={{ verticalAlign: "middle" }} />
                  <Typography
                    variant="body1"
                    style={{
                      paddingTop: ".5rem",
                      paddingBottom: ".5rem",
                      paddingLeft: ".5rem",
                      display: "inline-block",
                    }}
                  >
                    Lease End <Moment format="MM/DD/YY" date={endDate} />
                  </Typography>
                </Grid>
              )}
            </Grid>
            {/* <Grid item xs={1} style={ borderLeft: '0.1em ridge rgb(0, 0, 0, .12)', padding: '0.5em' }>
            </Grid> */}
            <Grid item xs={12} sm={4}>
              <Grid container style={{ marginTop: "1rem" }}>
                <Grid item xs={12}>
                  <Typography variant="caption" style={{ float: "right" }}>
                    <span style={{ color: "red" }}>*</span> Required
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                    Expected Move-Out Date <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Grid container direction="row" alignItems="center" spacing={8}>
                    <Grid item>
                      <DatePicker
                        id="lookhere"
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Move-Out Date"
                        selected={this.state.moveOutDate}
                        onChange={this.handleDateChange}
                        onChangeRaw={this.handleDateChangeRaw}
                        minDate={new Date(Date.now())}
                        name="moveOutDate"
                        autoComplete="off"
                        popperPlacement="botom-start"
                        popperModifiers={{
                          flip: {
                            enabled: false,
                          },
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                          },
                        }}
                        className={this.props.classes.datepicker}
                      />
                      <EventIcon style={{ color: "rgba(0, 0, 0, 0.42)" }} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider className={this.props.classes.tablepadding} style={{ marginTop: "1rem" }} />
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={8} className={this.props.classes.grouping}>
          <Grid item xs={6} sm={8}>
            <Typography
              variant="body1"
              gutterBottom
              style={{ display: "inline-block", paddingBottom: "1rem", paddingTop: "1rem" }}
            >
              By clicking continue, you agree to the terms below.
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Button
              onClick={this.props.onContinue.bind(this, this.state.moveOutDate)}
              color="primary"
              variant="contained"
              autoFocus
              disabled={!this.state.moveOutDate}
              style={{ float: "right" }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={this.props.classes.tablepadding} />
        </Grid>
        <Grid container alignItems="center" className={this.props.classes.grouping}>
          <Grid item xs={12} style={{ display: "inline-block" }}>
            <Typography style={{ paddingTop: "2rem", whiteSpace: "pre-wrap" }}>{entvDisclaimer}</Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = ({ selectAccount, lease }: RootState): PropsFromState => ({
  userData: getActiveUserInfo(selectAccount),
  data: lease.data,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  leaseFetchRequest: ({ nameId: any }) => dispatch(leaseFetchRequest({ nameId: any })),
  logActivity: (act: RpActivity) => dispatch(logActivity(act)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ENTVMoveOutDate));
