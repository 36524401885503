import {
  Card,
  CardContent,
  Collapse,
  Grid,
  Typography,
  WithStyles,
  withStyles,
  CardMedia,
  Hidden
} from "@material-ui/core";
import * as React from "react";
import PhotoGallery from "./common/PhotoGallery";
import { ForSaleItem } from "../models";
import CommentsContainer from "./CommentsContainer";
import FeedItemHeader from "./FeedItemHeader";
import TruncateMessage from "../../shared/components/TruncateMessage";
import * as constants from "../constants";
import styles from "./styles";

interface Props extends WithStyles<typeof styles> {
  item: ForSaleItem;
  feedType?: string;
}
type AllProps = Props;

interface State {
  expanded: boolean;
}

class ForSaleComponent extends React.Component<AllProps, State> {
  state: Readonly<State> = { expanded: true };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { item, classes } = this.props;
    const { expanded } = this.state;
    return (
      <Card style={{ width: "100%" }}>
        <FeedItemHeader item={item} showExpander={true} expandClickHandler={this.handleExpandClick} />
        <Collapse in={expanded} timeout="auto">
          <CardContent className={classes.feedItemContent}>
            <Grid container direction="row" style={{ marginBottom: "1.25rem" }}>
              <Grid item className={classes.leftIconGutter}>
                <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                  <CardMedia className={classes.postTypeIcon} image={constants.FORSALE_ICON} title="For Sale" />
                </div>
              </Grid>
              <Grid item xs>
                <Typography variant="h4" gutterBottom style={{ overflowWrap: "break-word", wordBreak: "break-word" }}>
                  {item.title}
                </Typography>
                <Typography variant="h4" gutterBottom style={{ overflowWrap: "break-word", wordBreak: "break-word" }}>
                  {item.price}
                </Typography>
                <Typography style={{ overflowWrap: "break-word", wordBreak: "break-word" }}>
                  <TruncateMessage message={item.message} characterCount={250} />
                </Typography>
              </Grid>
              <Hidden xsDown>
                <Grid item>{item.images.length > 0 && <PhotoGallery images={item.images} />}</Grid>
              </Hidden>
            </Grid>
            <Hidden smUp>
              <Grid container justify="center" style={{ backgroundColor: "#d7d7d7", marginTop: "1rem" }}>
                <Grid item>{item.images.length > 0 && <PhotoGallery images={item.images} />}</Grid>
              </Grid>
            </Hidden>
            <CommentsContainer item={item} feedType={this.props.feedType} groupId={item.groupId} />
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

export default withStyles(styles)(ForSaleComponent);
