import { createAction } from "typesafe-actions";
import { UploadDocument } from "./models";

const UPLOADS_POST_REQUEST = "uploads/POST_REQUEST";
const UPLOADS_POST_SUCCESS = "uploads/POST_SUCCESS";
const UPLOADS_POST_ERROR = "uploads/POST_ERROR";

export const uploadsPostRequest = createAction(UPLOADS_POST_REQUEST)<UploadDocument>();
export const uploadsPostSuccess = createAction(UPLOADS_POST_SUCCESS)();
export const uploadsPostError = createAction(UPLOADS_POST_ERROR)<string>();

const UPLOADS_LIST_GET_REQUEST = "uploads_list/GET_REQUEST";
const UPLOADS_LIST_GET_SUCCESS = "uploads_list/GET_SUCCESS";
const UPLOADS_LIST_GET_ERROR = "uploads_list/GET_ERROR";

export const uploadsListGetRequest = createAction(UPLOADS_LIST_GET_REQUEST)<string>();
export const uploadsListGetSuccess = createAction(UPLOADS_LIST_GET_SUCCESS)<{
  uploadDocuments: UploadDocument[];
}>();
export const uploadsListGetError = createAction(UPLOADS_LIST_GET_ERROR)<string>();
