import { Grid, List, ListItem, Typography, WithStyles, withStyles } from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import globalstyles from "../../../../global-styles";
import { RootState } from "../../../../store/root-reducer";
import combinestyles from "../../../../utils/combinestyles";
import { getActiveUserInfo } from "../../../selectAccount/selector";
import { UserInfoItem } from "../../../userInfo/models";
import { eventsFetchRequest } from "../../actions";
import { groupUserFetchRequest } from "../../actionsGroup";
import { EventItem, Group } from "../../models";
import eventstyles from "../styles";
import EventTile from "./EventTile";
import NewEvent from "./NewEvent";

const styles = combinestyles(eventstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {}

interface PropsFromState {
  user?: UserInfoItem;
  items: EventItem[];
  loading: boolean;
  errors?: string;
  userGroups: Group[];
}

interface PropsFromDispatch {
  eventsFetchRequest: typeof eventsFetchRequest;
  groupUserFetchRequest: typeof groupUserFetchRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

class Events extends React.Component<AllProps> {
  componentDidMount() {
    const nameId = this.props.user!.nameId;
    if (this.props.items.length === 0) {
      this.props.eventsFetchRequest(nameId);
    }
    if (this.props.userGroups.length === 0) {
      this.props.groupUserFetchRequest(nameId);
    }
  }

  render() {
    const { classes } = this.props;

    const startDateOfWeek = moment().startOf("isoWeek");
    const endDateOfWeek = moment().endOf("isoWeek");

    const nextWeekStartDate = moment()
      .add(1, "weeks")
      .startOf("isoWeek");
    const nextWeekEndDate = moment()
      .add(1, "weeks")
      .endOf("isoWeek");

    const today = moment();
    var eventItems = (this.props.items.filter(obj => obj.itemType === "EventItem") as EventItem[]).filter(
      item => moment(item.startDate) >= today
    );

    eventItems = eventItems.sort((a, b) => a.startDate.toString().localeCompare(b.startDate.toString()));

    const eventsThisWeek = eventItems.filter(
      obj => moment(obj.startDate) >= startDateOfWeek && moment(obj.startDate) <= endDateOfWeek
    );

    const eventsNextWeek = eventItems.filter(
      obj => moment(obj.startDate) >= nextWeekStartDate && moment(obj.startDate) <= nextWeekEndDate
    );

    const eventsFurtherOut = eventItems.filter(function(el) {
      return eventsThisWeek.indexOf(el) < 0 && eventsNextWeek.indexOf(el) < 0;
    });

    var noevents = eventsThisWeek.length === 0 && eventsNextWeek.length === 0 && eventsFurtherOut.length === 0;

    return (
      <div>
        <Grid container className={classes.header}>
          <Grid item xs>
            <Typography variant="h1">Event Calendar</Typography>
          </Grid>
          <Grid item style={{ textAlign: "right" }}>
            <NewEvent />
          </Grid>
        </Grid>
        {noevents === true && (
          <Typography variant="h5">Get Involved - Create an event and meet-up with your neighbors.</Typography>
        )}

        {eventsThisWeek.length > 0 && (
          <div className={classes.hrDiv}>
            <span className={classes.hrSpan}>THIS WEEK</span>
          </div>
        )}
        <List>
          {eventsThisWeek.map(x => (
            <ListItem key={x.id} className={classes.listitem}>
              {(() => {
                return <EventTile item={x as EventItem} userGroups={this.props.userGroups} />;
              })()}
            </ListItem>
          ))}
        </List>
        {eventsNextWeek.length > 0 && (
          <div className={classes.hrDiv}>
            <span className={classes.hrSpan}>NEXT WEEK</span>
          </div>
        )}
        <List>
          {eventsNextWeek.map(x => (
            <ListItem key={x.id} className={classes.listitem}>
              {(() => {
                return <EventTile item={x as EventItem} userGroups={this.props.userGroups} />;
              })()}
            </ListItem>
          ))}
        </List>
        {eventsFurtherOut.length > 0 && (
          <div className={classes.hrDiv}>
            <span className={classes.hrSpan}>FURTHER OUT</span>
          </div>
        )}
        <List>
          {eventsFurtherOut.map(x => (
            <ListItem key={x.id} className={classes.listitem}>
              {(() => {
                return <EventTile item={x as EventItem} userGroups={this.props.userGroups} />;
              })()}
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

const mapStateToProps = ({ social, socialGroup, selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  loading: social.loading,
  errors: social.errors,
  items: social.events,
  userGroups: socialGroup.groupUserList.dataUser
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  eventsFetchRequest: (nameid: string) => dispatch(eventsFetchRequest(nameid)),
  groupUserFetchRequest: (nameId: string) => dispatch(groupUserFetchRequest(nameId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Events));
