import * as React from "react";
import { Typography, WithStyles, withStyles } from "@material-ui/core";

import propertydocumentsstyles from "../styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(propertydocumentsstyles, globalstyles);

export interface IPropertyDocumentsHeaderProps extends WithStyles<typeof styles> {}

type State = {
  mobileOpen: boolean;
};

class PropertyDocumentsHeader extends React.Component<IPropertyDocumentsHeaderProps> {
  readonly state: State = { mobileOpen: false };

  handleClose = () => {
    this.setState({ openRemove: false });
  };

  render() {
    return (
      <Typography variant="h1" className={this.props.classes.header}>
        Community Documents
      </Typography>
    );
  }
}

export default withStyles(styles)(PropertyDocumentsHeader);
