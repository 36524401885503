import React, { Component } from "react";
import { VehicleInfo } from "../models";
import { WithStyles, withStyles, Typography, Grid } from "@material-ui/core";
import ProfileVehicleModal from "./ProfileVehicleModal";

import contactstyles from "../../../features/myprofile/components/styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(contactstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  vehicleInfo: VehicleInfo;
}

class ProfileVehicleDetail extends Component<Props> {
  state = {
    EditVehicleVisibility: false
  };
  render() {
    return (
      <div style={{ marginBottom: "1rem" }}>
        <Grid container direction="row" alignItems="center" justify="space-between" style={{ marginBottom: "1rem" }}>
          <Grid item>
            <Typography variant="h5">
              {this.props.vehicleInfo.year} - {this.props.vehicleInfo.make} {this.props.vehicleInfo.model}
            </Typography>
            <Typography>
              {this.props.vehicleInfo.state} {this.props.vehicleInfo.license}
            </Typography>
          </Grid>
          <Grid item>
            <ProfileVehicleModal
              modalTitle="Editing Vehicle"
              editingExistingVehicle={true}
              vehicleInfo={this.props.vehicleInfo}
            />
          </Grid>
        </Grid>
        <hr />
      </div>
    );
  }
}

export default withStyles(styles)(ProfileVehicleDetail);
