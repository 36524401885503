import React from "react";
import { Button, DialogActions, DialogTitle, Grid } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/CancelOutlined";

interface MyProps {
  title: string;
  handleClose: any;
  dialogTitleProps?: {
    id?: string;
    name?: string;
    className?: string;
  };
}

const DialogHeader = (props: MyProps) => {
  return (
    <Grid container spacing={0} alignItems="center">
      <Grid item xs={10}>
        <DialogTitle {...props.dialogTitleProps}>{props.title}</DialogTitle>
      </Grid>
      <Grid item xs={2}>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary" autoFocus>
            <CancelIcon />
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

export default DialogHeader;
