import { createAction } from "typesafe-actions";

import { UserInfoItem } from "./models";

const FETCH_REQUEST = "userinfo/FETCH_REQUEST";
const FETCH_SUCCESS = "userinfo/FETCH_SUCCESS";
const FETCH_ERROR = "userinfo/FETCH_ERROR";

export const fetchRequest = createAction(FETCH_REQUEST)<string>();
export const fetchSuccess = createAction(FETCH_SUCCESS)<UserInfoItem>();
export const fetchError = createAction(FETCH_ERROR)<string>();
