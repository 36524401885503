import React from "react";
import TextField from "@material-ui/core/TextField";

const MaterialInput = ({ field: { ...fields }, form: { errors, resetForm, touched, values }, ...props }: any) => {
  return (
    <TextField
      {...props}
      {...fields}
      error={Boolean(touched[fields.name] && errors[fields.name])}
      helperText={touched[fields.name] && errors[fields.name]}
      onKeyPress={(ev) => {
        if (props.childsubmit && ev.key === "Enter" && ev.shiftKey === false && values.comment.trim() !== "") {
          props.childsubmit(values);
          resetForm();
          ev.preventDefault();
        }
      }}
    />
  );
};

export default MaterialInput;
