import { combineReducers } from "redux";
import { ActionType, getType } from "typesafe-actions";

import * as entv from "./actions";
import {
  ERefundValue,
  ForwardingAddress,
  NewNoticeToVacate,
  NoticeSummary,
  NoticeToVacate,
  SaveExpectedMoveOutDate,
  CancelRequestData
} from "./models";

export type NoticeAction = ActionType<typeof entv>;

export type noticeState = Readonly<{
  myNoticeToVacate: NoticeToVacateState;
  newNoticeToVacate: NewNoticeToVacateState;
  insertNewNoticeDate: InsertNoticeDateState;
  insertForwardingAddress: InsertForwardingAddressState;
  myNoticeSummary: NoticeSummaryState;
  insertERefund: InsertERefundState;
  cancelNotice: CancelNoticeState;
  insertNewMORequestDate: InsertMORequestDateState;
}>;

export type NoticeToVacateState = Readonly<{
  loading: boolean;
  data?: NoticeToVacate;
  errors: string;
}>;

const initialNoticeToVacateState: NoticeToVacateState = {
  data: undefined,
  errors: "",
  loading: false
};

export type NewNoticeToVacateState = Readonly<{
  loading: boolean;
  newNotice?: NewNoticeToVacate;
  errors?: string;
}>;

const initialNewNoticeToVacateState: NewNoticeToVacateState = {
  newNotice: undefined,
  errors: undefined,
  loading: false
};

export type InsertNoticeDateState = Readonly<{
  loading: boolean;
  date?: SaveExpectedMoveOutDate;
  errors?: string;
}>;

const initialInsertNoticeDateState: InsertNoticeDateState = {
  date: undefined,
  errors: undefined,
  loading: false
};

export type InsertForwardingAddressState = Readonly<{
  loading: boolean;
  address?: ForwardingAddress;
  errors?: string;
}>;

const initialInsertForwardingAddressState: InsertForwardingAddressState = {
  address: undefined,
  errors: undefined,
  loading: false
};

export type NoticeSummaryState = Readonly<{
  loading: boolean;
  data?: NoticeSummary;
  errors: string;
}>;

const initialNoticeSummaryState: NoticeSummaryState = {
  data: undefined,
  errors: "",
  loading: false
};

export type InsertERefundState = Readonly<{
  loading: boolean;
  erefund?: ERefundValue;
  errors?: string;
}>;

const initialInsertERefundState: InsertERefundState = {
  erefund: undefined,
  errors: undefined,
  loading: false
};

export type CancelNoticeState = Readonly<{
  loading: boolean;
  cancel?: CancelRequestData;
  errors?: string;
}>;

const initialCancelNoticeState: CancelNoticeState = {
  cancel: undefined,
  errors: undefined,
  loading: false
};

export type InsertMORequestDateState = Readonly<{
  loading: boolean;
  date?: SaveExpectedMoveOutDate;
  errors?: string;
}>;

const initialInsertMORequestDateState: InsertMORequestDateState = {
  date: undefined,
  errors: undefined,
  loading: false
};

// tslint:disable-next-line:no-unused-expression
export default combineReducers<noticeState, NoticeAction>({
  myNoticeToVacate: (state = initialNoticeToVacateState, action) => {
    switch (action.type) {
      case getType(entv.requestENTVRequest): {
        return { ...state, loading: true };
      }
      case getType(entv.requestENTVSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(entv.requestENTVError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  newNoticeToVacate: (state = initialNewNoticeToVacateState, action) => {
    switch (action.type) {
      case getType(entv.postNoticeRequest): {
        return { ...state, loading: true };
      }
      case getType(entv.postNoticeSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(entv.postNoticeError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  insertNewNoticeDate: (state = initialInsertNoticeDateState, action) => {
    switch (action.type) {
      case getType(entv.postDateRequest): {
        return { ...state, loading: true };
      }
      case getType(entv.postDateSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(entv.postDateError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  insertForwardingAddress: (state = initialInsertForwardingAddressState, action) => {
    switch (action.type) {
      case getType(entv.postAddressRequest): {
        return { ...state, loading: true };
      }
      case getType(entv.postAddressSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(entv.postAddressError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  myNoticeSummary: (state = initialNoticeSummaryState, action) => {
    switch (action.type) {
      case getType(entv.requestENTVSummaryRequest): {
        return { ...state, loading: true };
      }
      case getType(entv.requestENTVSummarySuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(entv.requestENTVSummaryError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  insertERefund: (state = initialInsertERefundState, action) => {
    switch (action.type) {
      case getType(entv.postRefundRequest): {
        return { ...state, loading: true };
      }
      case getType(entv.postRefundSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(entv.postRefundError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  cancelNotice: (state = initialCancelNoticeState, action) => {
    switch (action.type) {
      case getType(entv.putCancelRequest): {
        return { ...state, loading: true };
      }
      case getType(entv.putCancelSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(entv.putCancelError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  insertNewMORequestDate: (state = initialInsertMORequestDateState, action) => {
    switch (action.type) {
      case getType(entv.moPostDateRequest): {
        return { ...state, loading: true };
      }
      case getType(entv.moPostDateSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(entv.moPostDateError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  }
});
