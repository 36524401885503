import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { User } from "oidc-client";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { RootState } from "../../../store/root-reducer";
import { UserInfoItem } from "../../userInfo/models";
import { fetchRequest, selectAccount } from "../actions";

interface Props {}

interface PropsFromState {
  user: User;
  userData: UserInfoItem[];
  selectedNameId: string;
  dialogOpen: boolean;
  loading: boolean;
  errors?: string;
}

interface PropsFromDispatch {
  fetchRequest: typeof fetchRequest;
  selectAccount: typeof selectAccount;
}

type AllProps = PropsFromState & PropsFromDispatch & Props;

interface State {
  selectedNameId?: string;
}

class UserInfoDisplay extends React.Component<AllProps, State> {
  state: Readonly<State> = { selectedNameId: undefined };

  componentDidMount() {
    if (this.props.loading || this.props.userData.length > 0) {
      return;
    }

    if (Array.isArray(this.props.user.profile.nid) && this.props.user.profile.nid.length > 1) {
      this.props.user.profile.nid.forEach((nameId: string) => this.props.fetchRequest(nameId));
      if (sessionStorage.getItem("selectedNameId")) {
        const selectedNameId = sessionStorage.getItem("selectedNameId")!;
        if (this.props.user.profile.nid.includes(selectedNameId)) {
          this.props.selectAccount(selectedNameId);
          return;
        }
      }
    } else {
      if (Array.isArray(this.props.user.profile.nid)) {
        this.props.fetchRequest(this.props.user.profile.nid[0]);
        this.props.selectAccount(this.props.user.profile.nid[0]);
      } else {
        this.props.fetchRequest(this.props.user.profile.nid);
        this.props.selectAccount(this.props.user.profile.nid);
      }
    }
  }

  handleChange = (event: any, value: any) => {
    this.setState({ selectedNameId: value });
  };

  handleOk = () => {
    this.props.selectAccount(this.state.selectedNameId!);
    window.location.reload();
  };

  render() {
    const { userData, dialogOpen } = this.props;
    if (!userData) {
      return null;
    }
    return (
      <Dialog open={dialogOpen} disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="select-account-dialog-title">You have multiple active leases</DialogTitle>
        <DialogContent>
          <Typography>Please select which lease you wish to login to.</Typography>
          <RadioGroup value={this.state.selectedNameId} onChange={this.handleChange}>
            {userData.map((item) => {
              return (
                <FormControlLabel
                  value={item.nameId}
                  key={item.nameId}
                  control={<Radio />}
                  label={"Unit " + item.unitId + (item.propertyInfo && " - " + item.propertyInfo.propertyName)}
                />
              );
            })}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          {this.state.selectedNameId && (
            <Button onClick={this.handleOk} color="primary" variant="contained">
              Ok
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ selectAccount, auth }: RootState): PropsFromState => ({
  user: auth.user,
  loading: selectAccount.loading,
  errors: selectAccount.errors,
  userData: selectAccount.userInfoArray,
  dialogOpen: selectAccount.dialogOpen,
  selectedNameId: selectAccount.selectedNameId,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  fetchRequest: (nameid: string) => dispatch(fetchRequest(nameid)),
  selectAccount: (nameid: string) => dispatch(selectAccount(nameid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoDisplay);
