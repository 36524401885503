import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { Statement } from "./models";
import * as actions from "./actions";

export type StatementAction = ActionType<typeof actions>;

export type StatementState = Readonly<{
  loading: boolean;
  data?: Statement;
  errors?: string;
  numStatements: number;
  maxNumStatements: number;
}>;

const initialState: StatementState = {
  data: undefined,
  errors: undefined,
  loading: false,

  numStatements: 1,
  maxNumStatements: 0
};

const reducer: Reducer<StatementState> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.statementFetchRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.statementFetchSuccess): {
      return { ...state, loading: false, data: action.payload };
    }
    case getType(actions.statementFetchError): {
      return { ...state, loading: false, errors: action.payload };
    }

    case getType(actions.maxStatementFetchRequest): {
      return { ...state, loading: true };
    }
    case getType(actions.maxStatementFetchSuccess): {
      return { ...state, loading: false, maxNumStatements: action.payload };
    }
    case getType(actions.maxStatementFetchError): {
      return { ...state, loading: false, errors: action.payload };
    }

    case getType(actions.loadMoreStatements): {
      const { numStatements } = state;
      return { ...state, numStatements: numStatements + 1 };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
