import * as React from "react";
import { RootState } from "../store/root-reducer";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Typography, withStyles, WithStyles } from "@material-ui/core";
import { UserInfoItem } from "../features/userInfo/models";
import { getActiveUserInfo } from "../features/selectAccount/selector";

import contactstyles from "../features/contact/components/styles";
import globalstyles from "../global-styles";
import combinestyles from "../utils/combinestyles";
import MoveInChecklist from "../features/newresident/components/MoveInChecklist";

const styles = combinestyles(contactstyles, globalstyles);

interface PropsFromState extends WithStyles<typeof styles> {
  user?: UserInfoItem;
  loading: boolean;
  errors: string;
}

interface PropsFromDispatch {}

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps<{ startDateIndex: string }>;

class MoveInChecklistPage extends React.Component<AllProps> {
  public render() {
    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <Typography variant="h1" className={this.props.classes.header}>
            Let's Get You Move-in Ready!
          </Typography>
          <MoveInChecklist {...this.props} />
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ selectAccount }: RootState) => ({
  user: getActiveUserInfo(selectAccount)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MoveInChecklistPage));
