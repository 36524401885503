import { CircularProgress, Dialog, DialogContent, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Dispatch } from "redux";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import { UserInfoItem } from "../../userInfo/models";
import { postDateRequest, postNoticeRequest, requestENTVRequest } from "../actions";
import ENTVHeader from "../components/entv-header";
import { NewNoticeToVacate, NoticeToVacate, RpActivity, SaveExpectedMoveOutDate } from "../models";
import entvstyles from "../styles";
import ENTVCharges from "./entv-charges";
import ENTVEasyMove from "./entv-easyMove";
import ENTVReasons from "./entv-reasons";
import ENTVWalk from "./entv-walk";

const styles = combinestyles(entvstyles, globalstyles);

interface State {
  eNTVReasonsView: boolean;
  eNTVChargesView: boolean;
  eNTVWalkView: boolean;
  eNTVEasyMoveView: boolean;
  selectedReason: number;
  otherReason: string;
  feeOption: number;
  eRefundFlag: string;
  eRefundEmail: string;
  sodaOptOut: string;
  forwardingAddress1: string;
  forwardingAddress2: string;
  forwardingCity: string;
  forwardingState: string;
  forwardingZip: string;
  presentForInspection: string;
  permissionToEnter: string;
  hasPet: string;
  hasAlarm: string;
  moveOutInspectionDate?: Date;
  waiver48Hour: string;
  alarmDetails: string;
  easyMoveOption: number;
  waiting: boolean;
}

interface IENTVProps extends WithStyles<typeof styles> {
  // theme?: any;
  // children?: any;
  // confirmMessageDisplay?: any;
}

interface PropsFromState {
  user?: UserInfoItem;
  myNoticeToVacate?: NoticeToVacate;
  loading: boolean;
  errors: string;
}

interface PropsFromDispatch {
  postDateRequest: typeof postDateRequest;
  postNoticeRequest: typeof postNoticeRequest;
  requestENTVRequest: typeof requestENTVRequest;
  logActivity: typeof logActivity;
}

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps & IENTVProps;

class ENTVForm extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    eNTVReasonsView: true,
    eNTVChargesView: false,
    eNTVWalkView: false,
    eNTVEasyMoveView: false,
    selectedReason: 0,
    otherReason: "",
    feeOption: 0,
    sodaOptOut: "",
    eRefundFlag: "",
    eRefundEmail: "",
    forwardingAddress1: "",
    forwardingAddress2: "",
    forwardingCity: "",
    forwardingState: "",
    forwardingZip: "",
    presentForInspection: "",
    moveOutInspectionDate: new Date("1990-01-01T00:00:00"),
    waiver48Hour: "",
    permissionToEnter: "",
    hasPet: "",
    hasAlarm: "",
    alarmDetails: "",

    easyMoveOption: 0,
    waiting: false
  };

  componentDidMount() {
    const nameId = this.props.user!.nameId;
    this.props.requestENTVRequest(nameId);
    this.setState({
      eNTVReasonsView: true,
    });
  }

  handleContinueToCharges = (reason: number) => {
    this.setState({
      selectedReason: reason,
      eNTVReasonsView: false,
      eNTVChargesView: true,
    });
  };

  handleContinueToENTVWalk = (e: number) => {
    if (this.props.myNoticeToVacate!.ntvCode === "E6") {
      this.setState({
        eNTVReasonsView: false,
        eNTVChargesView: false,
        eNTVWalkView: true,
        feeOption: 2
      });
    } else if (
      this.props.myNoticeToVacate!.settlementFeeDisplay.toString() === "N" &&
      this.props.myNoticeToVacate!.ntvCode === "E3"
    ) {
      this.setState({
        eNTVReasonsView: false,
        eNTVChargesView: false,
        eNTVWalkView: true,
        feeOption: 2
      });
    } else {
      this.setState({
        eNTVReasonsView: false,
        eNTVChargesView: false,
        eNTVWalkView: true,
        feeOption: e
      });
    }
  };

  handleSodaOptOut = (value: any) => {
    let dbValue = "N";
    if (value === true) {
      dbValue = "Y";
    }
    this.setState({ sodaOptOut: dbValue });
  };

  handleERefundFlag = (value: any) => {
    let dbValue = "N";
    if (value === true) {
      dbValue = "Y";
    }
    this.setState({ eRefundFlag: dbValue });
  };

  handlePresentForInspectionFlag = (value: any) => {
    this.setState({ presentForInspection: value });
  };

  handle48HourWaiverFlag = (value: any) => {
    let dbValue = "N";
    if (value === "Yes") {
      dbValue = "Y";
    }
    this.setState({ waiver48Hour: dbValue });
  };

  handlePetFlag = (value: any) => {
    let dbValue = "N";
    if (value === true) {
      dbValue = "Y";
    }
    this.setState({ hasPet: dbValue });
  };

  handlePTEFlag = (value: any) => {
    let dbValue = "N";
    if (value === true) {
      dbValue = "Y";
    }
    this.setState({ permissionToEnter: dbValue });
  };

  handleAlarmFlag = (value: any) => {
    let dbValue = "N";
    if (value === true) {
      dbValue = "Y";
    }
    this.setState({ hasAlarm: dbValue });
  };

  handleAlarmDetails = (value: string) => {
    this.setState({ alarmDetails: value });
  };

  handleERefundEmail = (value: string) => {
    this.setState({ eRefundEmail: value });
  };

  handleEasyMoveSelection = (value: number) => {
    this.setState({ easyMoveOption: value });
  };

  handleInspectionDate = (value: Date) => {
    this.setState({ moveOutInspectionDate: value });
  };

  handleForwardAddressChange = (textName: string, value: string) => {
    switch (textName) {
      case "address1":
        this.setState({ forwardingAddress1: value });
        break;
      case "address2":
        this.setState({ forwardingAddress2: value });
        break;
      case "city":
        this.setState({ forwardingCity: value });
        break;
      case "state":
        this.setState({ forwardingState: value });
        break;
      case "zip":
        this.setState({ forwardingZip: value });
        break;
    }
  };
  handleOtherResponse = (value: any) => {
    this.setState({ otherReason: value });
  };

  handleContinueToENTVEasyMove = (value: any) => {
    if (this.props.myNoticeToVacate!.isEasyMove.toString() === "Y") {
      this.setState({
        eNTVReasonsView: false,
        eNTVChargesView: false,
        eNTVWalkView: false,
        eNTVEasyMoveView: true,
      });
    } else {
      this.handleInsertNewNotice();
    }
  };

  handleInsertNewNotice = () => {
    const submissionData: NewNoticeToVacate = {
      nameId: this.props.user!.nameId,
      vacateDate: this.props.myNoticeToVacate!.vacateDate,
      vReason: this.state.selectedReason.toString(),
      vReasonDesc: this.state.otherReason,
      ntvType: this.props.myNoticeToVacate!.ntvCode,
      feeOption: this.state.feeOption,
      sodaEmailOptout: this.state.sodaOptOut,
      refundEmail: this.state.eRefundEmail,
      walkPresence: this.state.presentForInspection,
      preferredDate: this.state.moveOutInspectionDate,
      pet: this.state.hasPet,
      alarm: this.state.hasAlarm,
      alarmDetails: this.state.alarmDetails,
      permissiontoEnter: this.state.permissionToEnter,
      waiver48Hour: this.state.waiver48Hour,
      easyMoveId: this.state.easyMoveOption,
      eRefund: this.state.eRefundFlag,
      settleOffer: this.props.myNoticeToVacate!.settleOffer,
      forwardingAddressData: {
        nameId: this.props.user!.nameId,
        address1: this.state.forwardingAddress1,
        address2: this.state.forwardingAddress2,
        city: this.state.forwardingCity,
        state: this.state.forwardingState,
        zip: this.state.forwardingZip
      },
    };

    this.props.postNoticeRequest(submissionData);
    const newActivity: RpActivity = {
      nameid: this.props.user!.nameId,
      actcode: "NV",
      note: "eNTV Submitted",
      appsource: "WEBRP"
    };
    this.props.logActivity(newActivity);
    this.setState({
      waiting: true
    });
    setTimeout(this.handlePageMove, 5000);
  };

  handlePageMove = () => {
    this.props.history.push("/myaccount/entv/entvsummary");
  };

  handleCloseModal = () => {
    this.setState({ waiting: false });
  };

  render() {
    if (!this.props.myNoticeToVacate) {
      return <div />;
    }

    return (
      <div className={this.props.classes.root}>
        <main className={this.props.classes.contentRightRail}>
          <ENTVHeader />
          {this.state.eNTVReasonsView && (
            <ENTVReasons
              onContinue={(e: number) => this.handleContinueToCharges(e)}
              noticeInfo={this.props.myNoticeToVacate}
              handleOtherResponse={this.handleOtherResponse}
            />
          )}
          {this.state.eNTVChargesView && (
            <ENTVCharges
              onContinue={(e: number) => this.handleContinueToENTVWalk(e)}
              noticeInfo={this.props.myNoticeToVacate}
            />
          )}
          {this.state.eNTVWalkView && (
            <ENTVWalk
              onContinue={this.handleContinueToENTVEasyMove}
              noticeInfo={this.props.myNoticeToVacate}
              handleSodaOptOut={this.handleSodaOptOut}
              handleERefundFlag={this.handleERefundFlag}
              handleERefundEmail={this.handleERefundEmail}
              handlePresentForInspectionFlag={this.handlePresentForInspectionFlag}
              handle48HourWaiverFlag={this.handle48HourWaiverFlag}
              handlePetFlag={this.handlePetFlag}
              handlePTEFlag={this.handlePTEFlag}
              handleAlarmFlag={this.handleAlarmFlag}
              handleForwardAddressChange={this.handleForwardAddressChange}
              handleAlarmDetails={this.handleAlarmDetails}
              handleInspectionDate={this.handleInspectionDate}
            />
          )}
          {this.state.eNTVEasyMoveView && (
            <ENTVEasyMove
              onContinue={this.handleInsertNewNotice}
              noticeInfo={this.props.myNoticeToVacate}
              handleEasyMoveSelection={this.handleEasyMoveSelection}
            />
          )}
          <Dialog open={this.state.waiting} onClose={this.handleCloseModal}>
            <DialogContent>
              <CircularProgress />
            </DialogContent>
          </Dialog>
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ selectAccount, noticeToVacate }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount),
  myNoticeToVacate: noticeToVacate.myNoticeToVacate.data,
  loading: noticeToVacate.myNoticeToVacate.loading,
  errors: noticeToVacate.myNoticeToVacate.errors
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
  requestENTVRequest: (nameId: string) => dispatch(requestENTVRequest(nameId)),
  postDateRequest: (req: SaveExpectedMoveOutDate) => dispatch(postDateRequest(req)),
  postNoticeRequest: (notice: NewNoticeToVacate) => dispatch(postNoticeRequest(notice)),
  logActivity: (act: RpActivity) => dispatch(logActivity(act))
});

export default connect(
  mapStateToProps, 
  mapDispatchToProps)(withStyles(styles)(ENTVForm));
