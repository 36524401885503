import { combineReducers } from "redux";
import { ActionType, getType } from "typesafe-actions";

import { PaymentData, PaymentUrlDataResult } from "./models";
import * as payment from "./actions";

export type PaymentAction = ActionType<typeof payment>;

export type PaymentState = Readonly<{
  paymentData: PaymentDataState;
  paymentUrl: PaymentUrlDataResultState;
  paymentAutoUrl: PaymentUrlDataResultState;
}>;

export type PaymentDataState = Readonly<{
  loading: boolean;
  data: PaymentData | undefined;
  errors?: string;
}>;

const initialPaymentDataState: PaymentDataState = {
  data: undefined,
  errors: undefined,
  loading: false,
};

export type PaymentUrlDataResultState = Readonly<{
  loading: boolean;
  data: PaymentUrlDataResult | undefined;
  errors?: string;
}>;

const initialPaymentUrlDataResultState: PaymentUrlDataResultState = {
  data: undefined,
  errors: undefined,
  loading: false,
};

const initialAutoPaymentUrlDataResultState: PaymentUrlDataResultState = {
  data: undefined,
  errors: undefined,
  loading: false,
};

// tslint:disable-next-line:no-unused-expression

export default combineReducers<PaymentState, PaymentAction>({
  paymentData: (state = initialPaymentDataState, action) => {
    switch (action.type) {
      case getType(payment.paymentFetchRequest): {
        return { ...state, loading: true };
      }
      case getType(payment.paymentFetchSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(payment.paymentFetchError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  paymentUrl: (state = initialPaymentUrlDataResultState, action) => {
    switch (action.type) {
      case getType(payment.insertPaymentRequest): {
        return { ...state, loading: true };
      }
      case getType(payment.insertPaymentSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(payment.insertPaymentError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
  paymentAutoUrl: (state = initialAutoPaymentUrlDataResultState, action) => {
    switch (action.type) {
      case getType(payment.insertAutoPaymentRequest): {
        return { ...state, loading: true };
      }
      case getType(payment.insertAutoPaymentSuccess): {
        return { ...state, loading: false, data: action.payload };
      }
      case getType(payment.insertAutoPaymentError): {
        return { ...state, loading: false, errors: action.payload };
      }
      default: {
        return state;
      }
    }
  },
});
