import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
//import { Form } from "formik";

import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";
import { NoticeToVacate } from "../models";
import entvstyles from "../styles";

const styles = combinestyles(entvstyles, globalstyles);

export interface ENTVEasyMoveProps extends WithStyles<typeof styles> {
  onContinue: any;
  noticeInfo?: NoticeToVacate;
  handleEasyMoveSelection: (value: number) => void;
}

type State = {
  selectedEasyMove: string;
  modalVisibility: boolean;
};

type AllProps = ENTVEasyMoveProps & RouteComponentProps;

class ENTVEasyMove extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    selectedEasyMove: "",
    modalVisibility: false,
  };

  handleOptionChange = (changeEvent: any) => {
    this.setState({ selectedEasyMove: changeEvent.target.value });
    this.props.handleEasyMoveSelection(changeEvent.target.value);
  };

  handleOpenModal = () => {
    this.setState({ modalVisibility: true });
  };

  handleCloseModal = () => {
    this.setState({ modalVisibility: false });
  };

  render() {
    const cleaningOptions = this.props.noticeInfo!.cleaningOptions;

    return (
      <div>
        <Dialog
          fullScreen={false}
          open={this.state.modalVisibility}
          onClose={this.handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description" component={"span"}>
              <Typography variant="h3" className={this.props.classes.tablepadding} gutterBottom>
                EasyMove Services
              </Typography>
              <b>Our Move-Out Cleaning Service includes:</b>
              <br />
              <ul>
                <li>Kitchen counters, sinks, and faucets</li>
                <li>Kitchen cabinets and drawers, inside and out</li>
                <li>All appliances, inside and out </li>
                <li>Bathroom faucets, counters, sinks, toilets, tubs or showers</li>
                <li>Vanity, drawers, and under-sink areas</li>
                <li>All mirrors and windows</li>
                <li>All vinyl, tile, and hardwood flooring</li>
                <li>Professional carpet cleaning</li>
                <li>Balcony, patio, and garages</li>
              </ul>

              <b>All you have to do beforehand is: </b>
              <br />
              <ol>
                <li>Remove your personal belongings</li>
                <li>Donate non-perishable food and discard perishable items.</li>
                <li>Dispose of all trash.</li>
              </ol>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <DialogContentText>
              <Typography inline style={{ fontStyle: "italic" }}>
                We'll take care of the rest.
              </Typography>
            </DialogContentText>
            <Button onClick={this.handleCloseModal} color="primary" variant="contained" autoFocus>
              Got it!
            </Button>
          </DialogActions>
        </Dialog>

        <Paper className={this.props.classes.paper}>
          <form>
            <Grid container className={this.props.classes.grouping}>
              <Grid xs={12} item>
                <Typography variant="caption" className={this.props.classes.tablepadding} gutterBottom>
                  STEP 5 OF 5
                </Typography>
              </Grid>
              <Grid container direction="row" alignItems="center" justify="space-between" style={{ marginTop: "1rem" }}>
                <Grid item xs={12} style={{ marginBottom: "1.5rem" }}>
                  <Typography style={{ display: "inline-block" }}>
                    As a convenience to our residents, we offer an Easy Move cleaning service that will save you time
                    and give you peace of mind during this busy time.
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ display: "inline-block", marginBottom: "2em" }}>
                  <Grid item xs={6} style={{ display: "inline-block" }}>
                    <Typography style={{ fontSize: ".7rem", fontWeight: "bolder" }}>PRICING & OPTIONS</Typography>
                    <RadioGroup
                      name="easyMove"
                      value={this.state.selectedEasyMove}
                      className="radioGroup"
                      onChange={this.handleOptionChange}
                      style={{ display: "inline-block" }}
                      row={true}
                    >
                      {cleaningOptions
                        .filter(c => c.cleaningCaption !== "")
                        .map(item => {
                          return (
                            <FormControlLabel
                              key={item.id}
                              value={item.id.toString()}
                              control={<Radio color="primary" />}
                              label={item.cleaningCaption}
                              color="primary"
                              classes={{ root: this.props.classes.groupItem }}
                            />
                          );
                        })}
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={6} style={{ display: "inline-block", marginBottom: "2em", marginTop: "2em" }}>
                    <Typography
                      component={"span"}
                      onClick={this.handleOpenModal}
                      style={{ fontSize: ".85rem", color: "#2e79b6", cursor: "pointer", marginBottom: "1rem" }}
                    >
                      Questions about this service?{" "}
                      <InfoIcon
                        onClick={this.handleOpenModal}
                        fontSize="inherit"
                        style={{ color: "#2e79b6", cursor: "pointer" }}
                      />
                    </Typography>
                    <Typography>
                      <b>Please note:</b> If it is necessary for us to clean significantly, or if it is necessary to
                      repair any damaged items, you may be subject to additional charges. Some damage, such as pet
                      stains, any not be visible during a preliminary walk-through.
                    </Typography>
                    <Typography>We must receive your payment prior to the scheduled move date.</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={6} style={{ display: "inline-block" }}>
                    <Typography style={{ fontSize: ".75rem" }}>
                      By clicking agree and submit, you understand that your apartment will be put on the market.
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ display: "inline-block" }}>
                    <Button
                      onClick={this.props.onContinue.bind(this, this.state.selectedEasyMove)}
                      color="primary"
                      variant="contained"
                      autoFocus
                      disabled={this.state.selectedEasyMove === ""}
                      style={{ float: "right" }}
                    >
                      Agree & Submit Notice to Vacate
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}

// const mapStateToProps = ({ servicereqs }: RootState) => ({
//   categories: servicereqs.serviceRequestCategories.flatData
// });

// const mapDispatchToProps = {
//   viewDetails: (item: ServiceRequest) => viewDetails(item)
// };

export default withRouter(
  connect()(withStyles(styles)(ENTVEasyMove))
  // mapStateToProps,
  // mapDispatchToProps
);
