import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";

import { RootState } from "../../../store/root-reducer";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import DialogHeader from "../../shared/components/DialogHeader";
import { UserInfoItem } from "../../userInfo/models";
import { createGroupRequest } from "../actionsGroup";
import { CreateGroupDto, RpActivity } from "../models";
import GroupsRequestNewForm from "./GroupsRequestNewForm";
import styles from "./styles";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required")
});

interface Props extends WithStyles<typeof styles> {}

interface PropsFromState {
  user?: UserInfoItem;
}

interface PropsFromDispatch {
  createGroupRequest: typeof createGroupRequest;
  logActivity: typeof logActivity;
}
type AllProps = PropsFromState & PropsFromDispatch & Props;

type MyState = {
  open: boolean;
  openRequestSent: boolean;
};

export type InputFormState = {
  name: string;
  description: string;
};

type State = MyState & InputFormState;

class GroupsRequestNew extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    open: false,
    openRequestSent: false,
    name: "",
    description: ""
  };

  submitValues = ({ name, description }: State) => {
    // tslint:disable-next-line:no-console
    const newGroupsRequest: CreateGroupDto = {
      creatorNameId: this.props.user!.nameId,
      rmPropId: this.props.user!.propertyId,
      name: name,
      description: description
    };
    this.props.createGroupRequest(newGroupsRequest);
    this.props.logActivity({
      actcode: "GR",
      note: `Resident requested a new group - ${name}`
    });
    this.setState({ open: false });
    this.setState({ openRequestSent: true });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickOpenRequestSent = () => {
    this.setState({ openRequestSent: true });
  };

  handleCloseRequestSent = () => {
    this.setState({ openRequestSent: false });
  };

  public render() {
    const { open, openRequestSent, name, description } = this.state;
    const values = {
      open: open,
      openRequestSent: openRequestSent,
      name: name,
      description: description
    };

    return (
      <React.Fragment>
        <Button onClick={this.handleClickOpen} variant="contained" color="primary">
          + Request Group
        </Button>
        <Dialog open={open} onClose={this.handleClose}>
          <DialogHeader title="Request for new group" handleClose={this.handleClose} />
          <Formik
            render={props => <GroupsRequestNewForm {...props} />}
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={this.submitValues}
          />
        </Dialog>{" "}
        <Dialog open={openRequestSent} onClose={this.handleClose}>
          <DialogHeader title="Request for new group" handleClose={this.handleClose} />
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Your request has been sent!</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseRequestSent} color="primary" variant="contained">
              Got it!
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount)
});

const mapDispatchToProps: PropsFromDispatch = {
  createGroupRequest: (payload: CreateGroupDto) => createGroupRequest(payload),
  logActivity: (act: RpActivity) => logActivity(act)
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupsRequestNew));
