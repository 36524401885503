import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { getType } from "typesafe-actions";
import * as uploads from "./actions";
import { getUploadDocumentsList, uploadFile } from "../../utils/api";
import { showModalAction } from "../errorhandling/actions";

function* handleUploadsListFetch(action: ReturnType<typeof uploads.uploadsListGetRequest>): any {
  try {
    const nameId = action.payload;
    const response = yield call(getUploadDocumentsList, nameId);

    if (response.error) {
      yield put(uploads.uploadsListGetError(response.error));
    } else {
      yield put(uploads.uploadsListGetSuccess(response));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(uploads.uploadsListGetError(err.stack!));
    } else {
      yield put(uploads.uploadsListGetError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

function* handleUploadsPostRequest(action: ReturnType<typeof uploads.uploadsPostRequest>): any {
  try {
    if (action.payload.nameid) {
      const response: any = yield call(uploadFile, action.payload);
      if (response.error) {
        yield put(uploads.uploadsPostError(response.error));
      } else {
        let fetchResp: any = yield call(getUploadDocumentsList, action.payload.nameid);
        if (fetchResp.error) {
          yield put(uploads.uploadsListGetError(fetchResp.error));
        } else {
          yield put(uploads.uploadsListGetSuccess(fetchResp));
        }
      }
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(uploads.uploadsPostError(err.stack!));
    } else {
      yield put(uploads.uploadsPostError("An unknown error occured."));
    }
    yield put(showModalAction({ data: { modalType: "error", modalProps: { message: "" } } }));
  }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchFetchRequest() {
  yield takeEvery(getType(uploads.uploadsListGetRequest), handleUploadsListFetch);
  yield takeEvery(getType(uploads.uploadsPostRequest), handleUploadsPostRequest);
  //yield takeEvery(getType(contact.contactUsRequest), handleContactUsFetch);
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* contactSaga() {
  yield all([fork(watchFetchRequest)]);
}

export default contactSaga;
