import * as React from "react";
import { Button, Typography, Paper, Grid, withStyles, WithStyles } from "@material-ui/core";
import { ContactItem } from "../models";
import ContactImage from "./ContactImage";

import contactstyles from "./styles";
import globalstyles from "../../../global-styles";
import combinestyles from "../../../utils/combinestyles";

const styles = combinestyles(contactstyles, globalstyles);

interface Props extends WithStyles<typeof styles> {
  yourContact: ContactItem[];
}

const ContactYourContacts: React.SFC<Props> = props => {
  return (
    <Paper className={props.classes.paper}>
      <Typography variant="h2" style={{ marginBottom: "1.5rem" }}>
        Your contacts
      </Typography>
      <Grid container spacing={24}>
        {props.yourContact.map(contactItem => (
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={24}>
              <Grid item>
                <ContactImage contact={contactItem} />
              </Grid>
              <Grid item xs>
                <Typography variant="h4">
                  {contactItem.firstName}&nbsp;{contactItem.lastName}
                </Typography>
                <Typography variant="h4" style={{ fontWeight: 300 }}>
                  {contactItem.contactTitle}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  component="a"
                  href={`mailto:${contactItem.email}`}
                  target="_blank"
                >
                  Contact
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(ContactYourContacts);
