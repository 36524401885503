import {
  Button,
  Collapse,
  Dialog,
  Grid,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import classNames from "classnames";
import { Formik } from "formik";
import * as React from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as Yup from "yup";

import globalstyles from "../../../global-styles";
import { RootState } from "../../../store/root-reducer";
import combinestyles from "../../../utils/combinestyles";
import { getActiveUserInfo } from "../../selectAccount/selector";
import { logActivity } from "../../shared/actions";
import DialogHeader from "../../shared/components/DialogHeader";
import { UserInfoItem } from "../../userInfo/models";
import { cancelAmenityRequest } from "../actions";
import { AmenityReservation, AmenityReservationCancel, RpActivity } from "../models";
import rentableitemstyles from "../styles";
import AmenityCancel from "./AmenityCancel";

const styles = combinestyles(rentableitemstyles, globalstyles);

const validationSchema = Yup.object({
  id: Yup.number(),
  cancelReason: Yup.string().required("Title is required")
});

export interface MyReservationProps extends WithStyles<typeof styles> {
  title: string;
  myAmenityListHistory: AmenityReservation[];
  collapsible: boolean;
  amenityStatus: string;
}

type MyState = {
  expanded: boolean;
  open: boolean;
};

interface PropsFromState {
  user: UserInfoItem;
}

interface PropsFromDispatch {
  addCancelReasonRequest: typeof cancelAmenityRequest;
  logActivity: typeof logActivity;
}

export type InputFormState = {
  id: number;
  nameId: string;
  amenityId: number;
  name: string;
  reserveDate: string;
  reserveEndDate: string;
  timeStart: string;
  timeEnd: string;
  cancelReason: string;
};

type AllProps = MyReservationProps & PropsFromState & PropsFromDispatch & RouteComponentProps;
type State = MyState & InputFormState;

class MyAmenityList extends React.Component<AllProps, State> {
  state: Readonly<State> = {
    expanded: false,
    open: false,
    id: 0,
    nameId: "",
    amenityId: 0,
    name: "",
    reserveDate: "1/1/2019",
    reserveEndDate: "",
    timeStart: "",
    timeEnd: "",
    cancelReason: ""
  };

  submitValues = ({ id, cancelReason }: State) => {
    // tslint:disable-next-line:no-console

    const newActivity: RpActivity = {
      nameid: this.props.user.nameId,
      actcode: "AX",
      note: "Amenity reservation cancelled by resident.  Reason: " + cancelReason,
      appsource: "WEBRP"
    };
    this.props.logActivity(newActivity);

    const obj: AmenityReservationCancel = {
      reservationId: id,
      cancelReason: cancelReason,
      nameId: this.props.user.nameId
    };
    this.props.addCancelReasonRequest(obj);

    this.setState({ open: false });

    this.props.history.push("/myaccount");

    //window.location.reload();
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleClickOpen = (amenity: AmenityReservation) => {
    this.setState({
      open: true,
      id: amenity.id,
      nameId: amenity.nameId,
      name: amenity.name,
      reserveDate: amenity.reserveDate.toString(),
      reserveEndDate: amenity.reserveEndDate.toString(),
      timeStart: amenity.timeStart,
      timeEnd: amenity.timeEnd
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderItem(myamenity: AmenityReservation) {
    return (
      <TableRow key={myamenity.id}>
        <TableCell style={{ paddingLeft: "0", paddingRight: "0" }}>
          <Grid container alignItems="center" justify="space-between" spacing={0}>
            <Grid item xs={7}>
              <Typography style={{ color: "#666", fontWeight: 400 }}>{myamenity.name}</Typography>
            </Grid>
            <Grid item xs={2}>
              {this.props.amenityStatus === "1" && (
                <Button style={{ color: "#3d90d4" }} onClick={(e: any) => this.handleClickOpen(myamenity)}>
                  <DeleteIcon />
                  <Hidden xsDown>Cancel</Hidden>
                </Button>
              )}
            </Grid>
            <Grid item xs={3}>
              {(() => {
                switch (myamenity.status) {
                  case "Approved":
                    return (
                      <Typography
                        className={this.props.classes.badge}
                        style={{ backgroundColor: "#189C2B", textAlign: "center" }}
                      >
                        Approved
                      </Typography>
                    );
                  case "Pending":
                    return (
                      <Typography
                        className={this.props.classes.badge}
                        style={{ backgroundColor: "#FFCC00", textAlign: "center" }}
                      >
                        Pending
                      </Typography>
                    );
                  case "Cancelled":
                    return (
                      <Typography
                        className={this.props.classes.badge}
                        style={{ backgroundColor: "#FE0000", textAlign: "center" }}
                      >
                        {myamenity.status}
                      </Typography>
                    );
                  case "Declined":
                    return (
                      <Typography
                        className={this.props.classes.badge}
                        style={{ backgroundColor: "#FE0000", textAlign: "center" }}
                      >
                        {myamenity.status}
                      </Typography>
                    );
                  default:
                    return null;
                }
              })()}
            </Grid>
            <Grid item xs={12}>
              {myamenity.reserveDate !== myamenity.reserveEndDate && (
                <Grid container direction="row">
                  <Grid item>
                    <Typography>
                      <Moment format="M/DD/YY">{myamenity.reserveDate.toString()}</Moment>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>&nbsp;- {myamenity.timeStart} to&nbsp;</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <Moment format="M/DD/YY">{myamenity.reserveEndDate.toString()}</Moment>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>&nbsp;- {myamenity.timeEnd}</Typography>
                  </Grid>
                </Grid>
              )}
              {myamenity.reserveDate === myamenity.reserveEndDate && (
                <Grid container direction="row">
                  <Grid item>
                    <Typography>
                      <Moment format="M/DD/YY">{myamenity.reserveDate.toString()}</Moment>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      &nbsp;- {myamenity.timeStart} to {myamenity.timeEnd}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const { title, myAmenityListHistory, user } = this.props;
    this.renderItem = this.renderItem.bind(this);
    const { expanded } = this.state;

    const { id, amenityId, name, reserveDate, reserveEndDate, timeStart, timeEnd, cancelReason } = this.state;
    const values = {
      id: id,
      nameId: user.nameId,
      amenityId: amenityId,
      name: name,
      reserveDate: reserveDate,
      reserveEndDate: reserveEndDate,
      timeStart: timeStart,
      timeEnd: timeEnd,
      cancelReason: cancelReason
    };

    return (
      <Paper className={classNames(this.props.classes.paper)} style={{ marginBottom: "1rem" }}>
        <Typography variant="h3">{title}</Typography>
        {!this.props.collapsible && (
          <Table>
            <TableBody>{myAmenityListHistory.map(this.renderItem)}</TableBody>
          </Table>
        )}
        {this.props.collapsible && (
          <div>
            <Collapse in={expanded} timeout="auto" className={this.props.classes.historyList}>
              <Table>
                {this.props.amenityStatus === "1" && (
                  <TableBody>
                    {myAmenityListHistory.sort((a, b) => (a.reserveDate > b.reserveDate ? 1 : -1)).map(this.renderItem)}
                  </TableBody>
                )}
                {this.props.amenityStatus === "2" && <TableBody>{myAmenityListHistory.map(this.renderItem)}</TableBody>}
              </Table>
            </Collapse>
            {myAmenityListHistory.length > 2 && (
              <Typography
                align="center"
                onClick={this.handleExpandClick}
                className={this.props.classes.link}
                style={{ padding: "1rem" }}
              >
                {expanded ? "Show Less" : "Show More"}
              </Typography>
            )}
          </div>
        )}
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogHeader title="Cancel Reservation" handleClose={this.handleClose} />
          <Formik
            render={props => <AmenityCancel onClose={this.handleClose} {...props} />}
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={this.submitValues}
          />
        </Dialog>
      </Paper>
    );
  }
}

const mapStateToProps = ({ selectAccount }: RootState): PropsFromState => ({
  user: getActiveUserInfo(selectAccount)
});

const mapDispatchToProps: PropsFromDispatch = {
  addCancelReasonRequest: (item: { reservationId: number; cancelReason: string; nameId: string }) =>
    cancelAmenityRequest(item),
  logActivity: (act: RpActivity) => logActivity(act)
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyAmenityList)));
